import React from "react";
import { Form, Input, Button, Checkbox } from "antd";
import { useSelector, useDispatch } from "react-redux";

import {
  LoadingIcon,
  LockFilledIcon,
  MailIcon,
} from "../../CustomIcons/CustomIcons.component";

import { startLogin } from "../../../actions/auth.action";
import { selectAuthState } from "../../../reducers/auth.reducer";

const layout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};
const tailLayout = {
  wrapperCol: { offset: 0, span: 24 },
};

const LoginForm = () => {
  const [loginForm] = Form.useForm();

  const { loading: authLoading, error: authError } = useSelector(
    selectAuthState
  );

  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const dispatch = useDispatch();

  const handleChangeForm = (changedValues: any, allValues: any) => {
    setUsername(allValues.username);
    setPassword(allValues.password);
  };

  const onFinish = async (values: {
    username: string;
    password: string;
    remember: boolean;
  }) => {
    const data = {
      email: username,
      password: password,
    };
    dispatch(startLogin(data));
  };

  const onFinishFailed = (errorInfo: {
    errorFields: any[];
    outOfDate: boolean;
    values: {
      username: string;
      password: string;
      remember: boolean;
    };
  }) => {
    console.error("Failed:", errorInfo);
  };

  React.useEffect(() => {
    if (!!authError) {
      loginForm.setFields([
        {
          name: "password",
          errors: ["Username and password does not match!"],
        },
      ]);
    }
  }, [loginForm, authError]);

  return (
    <div className="login-form">
      <div className="login-form__blue-box" />
      <div className="login-form__light-blue-box" />
      <div className="login-form__orange-box" />
      <div className="login-form__container">
        <div className="login-form__title">TEAMMEISTER</div>
        <div className="login-form__header">
          <span>Log in to TeamMeister</span>
          <small>Use your email account.</small>
        </div>
        <Form
          {...layout}
          form={loginForm}
          className="login-form__form"
          name="basic"
          initialValues={{ username, password, remember: true }}
          onValuesChange={handleChangeForm}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: "Email is required" }]}
          >
            <Input
              className="LoginForm__username-input"
              prefix={<MailIcon />}
              placeholder="Email"
              disabled={authLoading}
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: "Password is required" }]}
          >
            <Input.Password
              className="LoginForm__password-input"
              prefix={<LockFilledIcon />}
              placeholder="Password 8+ characters"
              disabled={authLoading}
            />
          </Form.Item>

          <Form.Item {...tailLayout}>
            <Button
              type="primary"
              htmlType="submit"
              className="LoginForm__submit-btn"
              disabled={authLoading}
            >
              {authLoading ? <LoadingIcon /> : "Log in"}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export { LoginForm as default };
