import moment from "moment";
import { isToday, isYesterday } from "../utils/dates.util";

export const dateKeyFormat = "dddd, MMMM D";

export const groupObjectArrayByObjectDateKey = (
  array: any[],
  objectKey: string,
  order: string = "desc"
) => {
  const groups: Record<string, any[]> = {};
  const addToGroup = (dateKey: string, object: any) => {
    if (groups[dateKey] === undefined) groups[dateKey] = [];
    groups[dateKey].push(object);
  };

  const createDateKey = (date: Date) => {
    if (isToday(moment(date))) {
      return "Today";
    } else if (isYesterday(moment(date))) {
      return "Yesterday";
    } else {
      return moment(date).format(dateKeyFormat);
    }
  };

  array.forEach((o) => {
    const objectKeyValue = o[objectKey];

    if (objectKeyValue) {
      // only add to group if the value of the specified key is truthy
      const dateKey = createDateKey(new Date(objectKeyValue));
      addToGroup(dateKey, o);
    }
  });

  // sort by date key
  const clone: Record<string, any[]> = Object.assign({}, { ...groups });
  delete clone["Today"];
  delete clone["Yesterday"];
  const ordered: Record<string, any[]> = Object.keys(clone)
    .sort((a, b) => {
      const keyA = a.replace(/\D/g, ""),
        keyB = b.replace(/\D/g, "");
      if (keyA < keyB) return order === "desc" ? -1 : 1;
      if (keyA > keyB) return order === "desc" ? 1 : -1;
      return 0;
    })
    .reduce((obj: any, key: any) => {
      obj[key] = clone[key];
      return obj;
    }, {});

  const sortedGroups: Record<string, any[]> =
    order === "desc"
      ? {
          ...(groups["Today"] && { Today: groups["Today"] }),
          ...(groups["Yesterday"] && { Yesterday: groups["Yesterday"] }),
          ...ordered,
        }
      : {
          ...ordered,
          ...(groups["Yesterday"] && { Yesterday: groups["Yesterday"] }),
          ...(groups["Today"] && { Today: groups["Today"] }),
        };

  return sortedGroups;
};
