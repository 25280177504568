import React from "react";
import { useSelector } from "react-redux";

import NotificationsItem from "../NotificationsItem/NotificationsItem";

import { selectNotificationsState } from "../../../reducers/notifications.reducer";

const NotificationsList = () => {
  const notificationsState = useSelector(selectNotificationsState);
  const {
    data: notifications,
    loading: notificationsLoading,
  } = notificationsState;

  return (
    <div className="NotificationsList">
      {notifications.map((notification, index) => (
        <NotificationsItem key={index} notification={notification} />
      ))}
    </div>
  );
};

export default NotificationsList;
