import { Dispatch } from "redux";
import moment from "moment";

import { getNextCalendarScheduleService } from "../services/third-party-calendar.service";
import { setError } from "../actions/error.action";

export const setNextCalendarSchedule = (
  nextCalendarSchedule: CalendarScheduleArray
) => ({
  type: "SET_NEXT_CALENDAR_SCHEDULE",
  nextCalendarSchedule,
});

export const requestFetchNextCalendarSchedule = () => ({
  type: "REQUEST_FETCH_NEXT_CALENDAR_SCHEDULE",
});
export const requestFetchNextCalendarScheduleSuccess = (
  nextCalendarSchedule: CalendarScheduleArray
) => ({
  type: "REQUEST_FETCH_NEXT_CALENDAR_SCHEDULE_SUCCESS",
  nextCalendarSchedule,
});
export const requestFetchNextCalendarScheduleFailed = (error: string) => ({
  type: "REQUEST_FETCH_NEXT_CALENDAR_SCHEDULE_FAILED",
  error,
});

export const startSetNextCalendarSchedule = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: "REQUEST_FETCH_NEXT_CALENDAR_SCHEDULE",
      });
      const startDate = moment().format("YYYYMMDD");
      const response = await getNextCalendarScheduleService({
        start_date: startDate,
      });
      if (response.status === 200) {
        dispatch(requestFetchNextCalendarScheduleSuccess(response.data));
      } else {
        const error = "getNextCalendarScheduleService status is not 200";
        dispatch(requestFetchNextCalendarScheduleFailed(error));
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestFetchNextCalendarScheduleFailed(error));
    }
  };
};
