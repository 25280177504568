import React from "react";
import { useSelector } from "react-redux";

import { selectWorkDaysState } from "../reducers/work-day.reducer";
import { selectWorkShiftsState } from "../reducers/work-shift.reducer";

export default function useWorkDateLastShiftEndDateTime(
  workDate?: WorkDayObject["work_date"]
) {
  const workDays = useSelector(selectWorkDaysState).data;
  const workShifts = useSelector(selectWorkShiftsState).data;

  const [
    lastWorkShiftEndDateTime,
    setLastWorkShiftEndDateTime,
  ] = React.useState<WorkShiftObject["end_datetime"]>("");

  React.useEffect(() => {
    if (workDate && workDays.length > 0 && workShifts.length > 0) {
      const workDay =
        workDays.find(({ work_date }) => workDate === work_date) ||
        ({} as WorkDayObject);
      const workDayShifts = workShifts.filter(
        ({ work_day }) => work_day === workDay.id
      );
      const lastWorkShift = workDayShifts[workDayShifts.length - 1];
      if (lastWorkShift)
        setLastWorkShiftEndDateTime(lastWorkShift.end_datetime);
    }
  }, [workDate, JSON.stringify(workDays), JSON.stringify(workShifts)]);

  return lastWorkShiftEndDateTime;
}
