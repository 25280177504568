import React from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { Popover } from "antd";

import {
  ExclamationCircleFilledIcon,
  LoadingIcon,
  SuccessCircleFilledIcon,
} from "../../CustomIcons/CustomIcons.component";
import CheckoutStatusIconPopoverContent from "./CheckoutStatusIconPopoverContent/CheckoutStatusIconPopoverContent";
import {
  selectCheckoutChecksState,
  selectCheckoutDateStatusState,
  selectCheckoutStatusState,
} from "../../../reducers/checkout.reducer";

/**
 * Default Display
 * Displays checkout status icon
 *
 * Hover Display
 * Displays CheckoutStatusIconPopoverContent on hover
 *
 * Functionality
 * Adds modifier class --Hidden to CheckoutStatusIcon__Popover...
 * ...if global state checkoutStatus.issue_json is blank object or undefined
 */

const CheckoutStatusIcon: React.FC<CheckoutStatusIconProps> = ({
  useCheckoutDate = false,
  displayPopover = true,
}) => {
  const currentDateCheckoutStatusState = useSelector(selectCheckoutStatusState);
  const {
    data: currentDateCheckoutStatus,
    loading: currentDateCheckoutStatusLoading,
  } = currentDateCheckoutStatusState;

  const checkoutDateCheckoutStatus = useSelector(selectCheckoutDateStatusState)
    .data;
  const checkoutChecksState = useSelector(selectCheckoutChecksState);
  const {
    data: checkoutChecks,
    loading: checkoutChecksLoading,
  } = checkoutChecksState;

  const checkoutStatusArray = useCheckoutDate
    ? checkoutDateCheckoutStatus
    : currentDateCheckoutStatus;
  const hasCheckoutStatus =
    checkoutStatusArray && checkoutStatusArray.length > 0;
  const allCheckoutChecksPassed = checkoutStatusArray
    ? checkoutStatusArray.every((item) => item.passed_yn === true)
    : false;
  const hasCheckoutChecks = checkoutChecks && checkoutChecks.length > 0;
  const displayPopoverContent =
    displayPopover && hasCheckoutChecks && !allCheckoutChecksPassed;

  const checkoutStatusIcon = !hasCheckoutStatus ? (
    <></>
  ) : allCheckoutChecksPassed ? (
    <SuccessCircleFilledIcon className="CheckoutStatusIcon__Icon" />
  ) : (
    <ExclamationCircleFilledIcon className="CheckoutStatusIcon__Icon" />
  );

  return (
    <div className="CheckoutStatusIcon">
      {(currentDateCheckoutStatusLoading || checkoutChecksLoading) && (
        <LoadingIcon />
      )}
      {!currentDateCheckoutStatusLoading &&
        !checkoutChecksLoading &&
        hasCheckoutStatus && (
          <Popover
            content={
              displayPopoverContent && (
                <CheckoutStatusIconPopoverContent
                  checkoutChecks={checkoutChecks}
                  checkoutStatusArray={checkoutStatusArray}
                />
              )
            }
            trigger="hover"
            placement="top"
            overlayClassName={classNames("CheckoutStatusIcon__Popover", {
              "CheckoutStatusIcon__Popover--Hidden": !displayPopoverContent,
            })}
          >
            {checkoutStatusIcon}
          </Popover>
        )}
    </div>
  );
};

export default CheckoutStatusIcon;
