import React from "react";
import { useSelector } from "react-redux";

import useConsolidateTask from "./useConsolidateTask.hook";

import {
  getTasksInNextShiftService,
  getTasksListService,
} from "../services/tasks.service";
import { dispatchError } from "../utils/error.util";
import { selectUserDetailsState } from "../reducers/user-details.reducer";
import { asyncFetchTaskObject } from "../utils/tasks.util";

import ws from "../sockets/websockets";

export default function useAsyncTasksInNextWorkDay(date: string) {
  const {
    data: { id: userId },
  } = useSelector(selectUserDetailsState);

  const [
    tasksInNextWorkday,
    setTasksInNextWorkday,
  ] = React.useState<TaskArray>();
  const [loading, setLoading] = React.useState(false);

  const [consolidatedTasks, consolidatedTasksLoading] = useConsolidateTask(
    tasksInNextWorkday
  );

  const isLoading = loading || consolidatedTasksLoading;

  const getTasksInNextWorkDay = React.useCallback(async () => {
    try {
      setLoading(true);
      const response = await getTasksInNextShiftService(date);
      if (response.status === 200) {
        const tasksInNextWorkdayIds = response.data.map(({ task }) => task.id);
        const getTasksListServiceResponse = await getTasksListService({
          task_ids: tasksInNextWorkdayIds,
        });
        if (getTasksListServiceResponse.status === 200) {
          setTasksInNextWorkday(getTasksListServiceResponse.data.results);
          setLoading(false);
        } else {
          throw new Error();
        }
      } else {
        throw new Error();
      }
    } catch (e) {
      setTasksInNextWorkday([]);
      setLoading(false);
      dispatchError({
        e,
        title: "Get tasks in next workday error",
      });
    }
  }, [date]);

  React.useEffect(() => {
    getTasksInNextWorkDay();
  }, [getTasksInNextWorkDay]);

  React.useEffect(() => {
    const wsOnMessage = async (event: MessageEvent) => {
      const messageEventData = JSON.parse(event.data);
      const { event: eventType } = messageEventData;
      if (eventType === "removed_task_from_workday") {
        const { user: eventUserId, task: eventTaskId } = messageEventData.meta;
        const isUser = userId === eventUserId;
        const existsInArray = !!tasksInNextWorkday?.find(
          ({ id }) => id === eventTaskId
        );

        if (isUser && existsInArray) {
          // if task exists in list, remove it
          setTasksInNextWorkday((c) =>
            c?.filter(({ id }) => id !== eventTaskId)
          );
        } else if (isUser && !existsInArray) {
          // if task does not exists in list, add it
          const eventTaskObject = await asyncFetchTaskObject(eventTaskId);
          if (eventTaskObject)
            setTasksInNextWorkday((c) =>
              c
                ? [...c, eventTaskObject].sort((a, b) => {
                    return a.title.localeCompare(b.title);
                  })
                : c
            );
        }
      } else if (eventType === "new_join_task_to_user") {
        const eventTaskId = messageEventData.meta.task_id;
        const eventTask = await asyncFetchTaskObject(eventTaskId);

        // synchronize members of task record
        if (!!eventTask) {
          setTasksInNextWorkday((c) =>
            c
              ? c.map((task) => {
                  if (task.id === eventTaskId) {
                    return eventTask;
                  }
                  return task;
                })
              : c
          );
        }
      }
    };

    ws.addEventListener("message", wsOnMessage);

    return () => {
      ws.removeEventListener("message", wsOnMessage);
    };
  }, [userId, tasksInNextWorkday, getTasksInNextWorkDay]);

  return [consolidatedTasks, isLoading] as [
    TaskJoinedToUserAndScheduledArray | undefined,
    boolean
  ];
}
