import { Input } from "antd";
import React from "react";
import { useSelector } from "react-redux";

import Button from "../../Button/Button";
import ColorPicker from "../../ColorPicker/ColorPicker.component";

import { selectProjectsColorsState } from "../../../reducers/projects-colors.reducer";

const LabelEditor: React.FC<LabelEditorProps> = ({
  label,
  isCreate = false,
  onSave,
  onDelete,
}) => {
  const colors = useSelector(selectProjectsColorsState).data;

  const handleSave = () => {
    // TODO: implement this when integrate endpoint
  };

  const handleDelete = () => {
    if (label) {
      onDelete(label.id);
    }
  };

  // TODO: implement this when integrate endpoint
  const handleInputChange = (e: any) => {
    console.log("input", e.target.value);
  };

  // TODO: implement this when integrate endpoint
  const handleColorPick = (value: string) => {
    console.log("pick", value);
  };
  return (
    <div className="LabelEditor">
      <div className="LabelEditor__Content">
        <Input
          className="LabelEditor__Input"
          defaultValue={label?.title}
          onChange={handleInputChange}
        />
        <ColorPicker
          colors={colors.map((item: ProjectColorObject) => item.value)}
          defaultValue={label?.color}
          onChange={handleColorPick}
        />
      </div>
      <div className="LabelEditor__Btns">
        <Button type="default" size="large" onClick={handleDelete}>
          Delete
        </Button>
        <Button type="primary" size="large" onClick={handleSave}>
          {isCreate ? "Create" : "Save"}
        </Button>
      </div>
    </div>
  );
};

export default LabelEditor;
