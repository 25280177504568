import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { isEmpty } from "lodash";
import cn from "classnames";

import useAsyncTasksInNextWorkDay from "../../../hooks/useAsyncTasksInNextWorkDay.hook";

import {
  LoadingIcon,
  ErrorCircleFilledIcon,
} from "../../CustomIcons/CustomIcons.component";
import SummaryOfTheDay from "../../Checkout/SummaryOfTheDay/SummaryOfTheDay";

import { workDayDateFormat } from "../../../constants/constants";
import { selectUserDetailsState } from "../../../reducers/user-details.reducer";
import { getNextCalendarScheduleService } from "../../../services/third-party-calendar.service";
import { formatDuration } from "../../../utils/time-conversion.util";
interface NextWorkDayProps {
  date: string;
  editingAllowed?: boolean;
}

const NextWorkDay: React.FC<NextWorkDayProps> = ({
  date,
  editingAllowed = true,
}) => {
  const { data: userDetails } = useSelector(selectUserDetailsState);

  const [
    tasksInNextWorkDay,
    tasksInNextWorkDayLoading,
  ] = useAsyncTasksInNextWorkDay(date);

  const [
    nextCalendarSchedule,
    setNextCalendarSchedule,
  ] = React.useState<CalendarScheduleObject>();
  const [loading, setLoading] = React.useState(false);

  const hasSchedule = !!nextCalendarSchedule;
  const hasUserDetails = userDetails && !isEmpty(userDetails);
  const isLoading = loading || tasksInNextWorkDayLoading;

  React.useEffect(() => {
    const getNextCalendarSchedule = async () => {
      // temporarily removed to improve performance
      // TODO: remove comment when need to use component, can use global state nextCalendarSchedule instead of re-fetching
      // setLoading(true);
      // try {
      //   const dateString = moment(date, workDayDateFormat).format("YYYYMMDD");
      //   const data = {
      //     start_date: dateString,
      //   };
      //   const response = await getNextCalendarScheduleService(data);
      //   if (response.status === 200) {
      //     setNextCalendarSchedule(response.data as CalendarScheduleObject);
      //   }
      //   setLoading(false);
      // } catch (e) {
      //   setLoading(false);
      // }
    };
    getNextCalendarSchedule();
  }, [date]);

  return (
    <div className="NextWorkDay">
      {isLoading && (
        <div className="NextWorkDay__Loading">
          <LoadingIcon />
        </div>
      )}

      {!isLoading && (
        <>
          <div className="NextWorkDay__Title">
            <span className="NextWorkDay__Title__Text">
              Next Workday&nbsp;
              {hasSchedule &&
                !!nextCalendarSchedule &&
                `(${moment(nextCalendarSchedule.start_datetime).format(
                  "dddd, MMMM D"
                )})`}
            </span>
          </div>
          <div className="NextWorkDay__Schedule">
            <div className="NextWorkDay__Schedule__Title">
              {!hasSchedule && <ErrorCircleFilledIcon />}
              <span className="NextWorkDay__Schedule__Title__Text">
                {hasSchedule ? "Schedule" : "No Schedule"}
              </span>
            </div>

            <div
              className={cn("NextWorkDay__Schedule__List", {
                "NextWorkDay__Schedule__List--Empty": !hasSchedule,
              })}
            >
              {hasSchedule ? (
                <div className="NextWorkDay__Schedule__Content">
                  <div className="NextWorkDay__Schedule__Content__Data">
                    <span>Hours</span>
                    <span>
                      {!!nextCalendarSchedule &&
                      nextCalendarSchedule.work_time_seconds
                        ? formatDuration(
                            nextCalendarSchedule.work_time_seconds,
                            "seconds"
                          )
                        : "0m"}
                    </span>
                  </div>
                  <div className="NextWorkDay__Schedule__Content__Data">
                    <span>Shift</span>
                    {!!nextCalendarSchedule && (
                      <span>
                        {moment(nextCalendarSchedule.start_datetime).format(
                          "HH:mm"
                        )}
                        &ndash;
                        {moment(nextCalendarSchedule.end_datetime).format(
                          "HH:mm"
                        )}
                      </span>
                    )}
                  </div>
                  <div className="NextWorkDay__Schedule__Content__Data">
                    <span>Note</span>
                    <span>
                      {!!nextCalendarSchedule &&
                      nextCalendarSchedule.schedule_notes
                        ? nextCalendarSchedule.schedule_notes
                        : "No Notes"}
                    </span>
                  </div>
                </div>
              ) : (
                <div className="NextWorkDay__Schedule__Content NextWorkDay__Schedule__Content--Empty">
                  <div className="NextWorkDay__Schedule__Content__Data">
                    <span>There are no next workdays scheduled.</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          {hasUserDetails && (
            <SummaryOfTheDay
              collapseState="expand"
              date={
                hasSchedule
                  ? [
                      moment(nextCalendarSchedule!.start_datetime).format(
                        workDayDateFormat
                      ),
                      moment(nextCalendarSchedule!.start_datetime).format(
                        workDayDateFormat
                      ),
                    ]
                  : [
                      moment().format(workDayDateFormat),
                      moment().format(workDayDateFormat),
                    ]
              }
              editingAllowed={editingAllowed}
              tasksInNextWorkDay={tasksInNextWorkDay}
              type="next-workday"
              users={[userDetails]}
            />
          )}
        </>
      )}
    </div>
  );
};

export default NextWorkDay;
