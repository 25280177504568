const taskTime: TaskTimeArray = [
  {
    id: "2ZK0M73IVH",
    user: "9LBX5JWFS1",
    task: "233M5RE2EE",
    seconds_worked: 4885,
    record_start_datetime: "2021-08-09T01:00:00Z",
    recording_yn: false,
    changed_yn: false,
    changed_datetime: null,
    work_day: "DRYVO2YIXA",
  },
  {
    id: "1VXQBPAJVN",
    user: "9LBX5JWFS1",
    task: "4II3DV7OAX",
    seconds_worked: 509,
    record_start_datetime: "2021-08-10T04:57:38.336000Z",
    recording_yn: false,
    changed_yn: false,
    changed_datetime: null,
    work_day: "B4W1M0AQTL",
  },
];

export { taskTime as default };
