// import axios from "../apis/django.instance";
import { teammates } from "../fixtures/teammates.fixture";

export const getTeammatesService = async (): Promise<{
  status: number;
  data: any;
}> => {
  const response = {
    status: 200,
    data: teammates,
  };
  return new Promise((resolve) => setTimeout(() => resolve(response), 1000));
};
