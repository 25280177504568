import React from "react";
import { Collapse } from "antd";
import moment from "moment";
import { useSelector } from "react-redux";

import CheckoutTaskCollapsePanelHeader from "./CheckoutTaskCollapsePanelHeader/CheckoutTaskCollapsePanelHeader";
import CheckoutTaskCollapsePanelContent from "./CheckoutTaskCollapsePanelContent/CheckoutTaskCollapsePanelContent";
import CheckoutTaskCollapsePanelFooter from "./CheckoutTaskCollapsePanelFooter/CheckoutTaskCollapsePanelFooter";
import { LoadingIcon } from "../../CustomIcons/CustomIcons.component";

import {
  CHECKOUT_PANEL_KEY,
  workDayDateFormat,
} from "../../../constants/constants";
import { selectUserDetailsState } from "../../../reducers/user-details.reducer";
import { getWorkDaySummaryService } from "../../../services/work-day.service";
import { getDaysBetweenDates } from "../../../utils/dates.util";
import { groupObjectArrayByObjectDateKey } from "../../../utils/objects-array.util";
import { secondsToMs } from "../../../utils/time-conversion.util";

import ws from "../../../sockets/websockets";

const { Panel } = Collapse;

interface CheckoutTaskCollapsePanelProps {
  activitiesSummaryType: ActivitiesSummaryType;
  dates: string[];
  users: UserArray;
}
const CheckoutTaskCollapsePanel: React.FC<CheckoutTaskCollapsePanelProps> = ({
  activitiesSummaryType,
  dates,
  users,
}) => {
  let interval = React.useRef<number>(0);

  const { id: userDetailsId } = useSelector(selectUserDetailsState).data;

  const [workDaysCheckoutSummary, setWorkDaysCheckoutSummary] = React.useState<
    WorkDayCheckoutSummaryArray
  >([]);
  const [workDaysCheckoutTime, setWorkDaysCheckoutTime] = React.useState(0);
  const [loading, setLoading] = React.useState<LoadingState>("false");

  const getWorkDaysCheckoutSummary = React.useCallback(async () => {
    const daysBetweenDates = getDaysBetweenDates(
      moment(dates[0], workDayDateFormat),
      moment(dates[dates.length - 1], workDayDateFormat),
      workDayDateFormat
    );

    // do not include current user in user ids (user can add self as observable user)
    const userIds = users
      .filter(({ id }) => id !== userDetailsId)
      .map(({ id }) => id);

    if (userIds.length > 0 && daysBetweenDates.length > 0) {
      try {
        setLoading("true");
        const response = await getWorkDaySummaryService({
          user_ids: userIds,
          from_date: daysBetweenDates[0],
          to_date: daysBetweenDates[daysBetweenDates.length - 1],
        });
        if (response.status === 200) {
          setWorkDaysCheckoutSummary(response.data.workdays);
          setWorkDaysCheckoutTime(response.data.total_seconds_worked);
        }
        setLoading("success");
      } catch (e) {
        const error = (e as Error).message;
        setLoading("error");
      }
    } else {
      setWorkDaysCheckoutSummary([]);
      setWorkDaysCheckoutTime(0);
    }
  }, [users, dates]);

  React.useEffect(() => {
    getWorkDaysCheckoutSummary();
  }, [getWorkDaysCheckoutSummary]);

  const workDaySummaryArrayGroupedByDate = React.useMemo(
    () => groupObjectArrayByObjectDateKey(workDaysCheckoutSummary, "date"),
    [workDaysCheckoutSummary]
  );

  const [activeKey, setActiveKey] = React.useState(CHECKOUT_PANEL_KEY);
  const [
    checkoutTaskTotalTimeIncrementing,
    setCheckoutTaskTotalTimeIncrementing,
  ] = React.useState(0);

  React.useEffect(() => {
    setCheckoutTaskTotalTimeIncrementing(workDaysCheckoutTime);
  }, [workDaysCheckoutTime]);

  React.useEffect(() => {
    interval.current = window.setInterval(() => {
      setCheckoutTaskTotalTimeIncrementing((c) => c + 1);
    }, secondsToMs(1));

    return () => {
      window.clearInterval(interval.current);
    };
  }, [checkoutTaskTotalTimeIncrementing, interval]);

  React.useEffect(() => {
    const wsOnMessage = async (event: MessageEvent) => {
      try {
        const messageEventData = JSON.parse(event.data);
        const { event: eventType } = messageEventData;
        switch (eventType.trim()) {
          case "update_checkout_read_logs_read_log_mark_all":
            getWorkDaysCheckoutSummary();
            break;
        }
      } catch (e) {}
    };
    ws.addEventListener("message", wsOnMessage);

    return () => {
      ws.removeEventListener("message", wsOnMessage);
    };
  }, [getWorkDaysCheckoutSummary]);

  return (
    <div className="CheckoutTaskCollapsePanel">
      {loading === "true" ? (
        <LoadingIcon />
      ) : (
        <>
          <Collapse activeKey={activeKey}>
            <Panel
              key={CHECKOUT_PANEL_KEY}
              header={
                <CheckoutTaskCollapsePanelHeader
                  activeKey={activeKey}
                  activitiesSummaryType={activitiesSummaryType}
                  checkoutTaskTotalTime={checkoutTaskTotalTimeIncrementing}
                  setActiveKey={setActiveKey}
                  workDaysCheckoutSummary={workDaysCheckoutSummary}
                />
              }
              showArrow={false}
            >
              <CheckoutTaskCollapsePanelContent
                activitiesSummaryType={activitiesSummaryType}
                users={users}
                workDaysCheckoutSummaryGroupedByDate={
                  workDaySummaryArrayGroupedByDate
                }
              />
            </Panel>
          </Collapse>
          <CheckoutTaskCollapsePanelFooter
            checkoutTaskTotalTime={checkoutTaskTotalTimeIncrementing}
          />
        </>
      )}
    </div>
  );
};

export default CheckoutTaskCollapsePanel;
