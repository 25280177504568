import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import CustomButton from "../../CustomButton/CustomButton";
import {
  LoadingIcon,
  NoCheckoutIcon,
} from "../../CustomIcons/CustomIcons.component";

import { setCheckoutWorkDay } from "../../../actions/work-day.action";
import { dateFormat } from "../../../constants/constants";
import { selectUserDetailsState } from "../../../reducers/user-details.reducer";
import { createWorkDayRecordService } from "../../../services/work-day.service";
import { dispatchError } from "../../../utils/error.util";

export interface CreateCheckoutProps {
  checkoutDate: string;
}
const CreateCheckout: React.FC<CreateCheckoutProps> = ({ checkoutDate }) => {
  const dispatch = useDispatch();

  const userDetails = useSelector(selectUserDetailsState);

  const [loading, setLoading] = React.useState<LoadingState>("false");

  const { id: userDetailsId } = userDetails.data;

  const handleClickCreateCheckout = async () => {
    try {
      setLoading("true");
      const response = await createWorkDayRecordService({
        user: userDetailsId,
        work_date: checkoutDate,
        with_checkout: true,
      });
      if (response.status === 201) {
        dispatch(setCheckoutWorkDay(response.data));
        setLoading("success");
      } else {
        throw new Error();
      }
    } catch (e) {
      setLoading("error");
      dispatchError({
        e,
        title: "Create work day error",
      });
    }
  };

  return (
    <div className="CreateCheckout">
      <NoCheckoutIcon />
      <div className="CreateCheckout__Title">
        <span>No checkout</span>
      </div>
      <div className="CreateCheckout__Message">
        <span>
          "Do you want to create a Checkout on{" "}
          {moment(checkoutDate, dateFormat).format("MMMM D, YYYY")}?
        </span>
      </div>
      <CustomButton type="primary" onClick={handleClickCreateCheckout}>
        {loading === "true" ? <LoadingIcon /> : <span>Create Checkout</span>}
      </CustomButton>
    </div>
  );
};

export default CreateCheckout;
