import React from "react";

import { getProjectsListService } from "../services/projects.service";

export default function useAsyncSearchProjectByTitle(
  search: ProjectObject["title"]
) {
  const [searchedProjects, setSearchedProjects] = React.useState<ProjectArray>(
    []
  );
  const [loading, setLoading] = React.useState<LoadingState>("false");

  const searchProjects = React.useCallback(async () => {
    try {
      setLoading("true");
      const response = await getProjectsListService({ search });
      if (response.status === 200) {
        setLoading("success");
        setSearchedProjects(
          response.data.sort((a, b) => a.title.localeCompare(b.title))
        );
      } else {
        setLoading("error");
      }
    } catch (e) {
      setLoading("error");
    }
  }, [search]);

  React.useEffect(() => {
    if (search) {
      searchProjects();
    } else {
      setSearchedProjects([]);
    }
  }, [search, searchProjects]);

  const returnValue: [ProjectArray, LoadingState] = [searchedProjects, loading];

  return returnValue;
}
