import React from "react";
import { Moment } from "moment";

import TeamHistoryMemberItem from "../TeamHistoryMemberItem/TeamHistoryMemberItem";

export interface TeamHistoryMemberListProps {
  endDate?: Moment;
  shownObservableUserWithTableDataArray: ShownObservableUserWithTableDataArray;
  startDate?: Moment;
}

const TeamHistoryMemberList: React.FC<TeamHistoryMemberListProps> = ({
  endDate,
  shownObservableUserWithTableDataArray,
  startDate,
}) => {
  return (
    <div className="TeamHistoryMemberList">
      {shownObservableUserWithTableDataArray.map(
        (shownObservableUserWithTableDataObject, index) => (
          <TeamHistoryMemberItem
            key={index}
            endDate={endDate}
            shownObservableUserWithTableDataObject={
              shownObservableUserWithTableDataObject
            }
            startDate={startDate}
          />
        )
      )}
    </div>
  );
};

export default TeamHistoryMemberList;
