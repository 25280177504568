import React from "react";
import { useSelector } from "react-redux";
import { Popover } from "antd";
import { selectUserDetailsState } from "../../../reducers/user-details.reducer";
import {
  LoadingIcon,
  LockFilledIcon,
  SettingIcon,
} from "../../CustomIcons/CustomIcons.component";
import AccountSettings from "../../AccountSettings/AccountSettings.component";
import CustomAvatar from "../../CustomAvatar/CustomAvatar";
import { getInitials } from "../../../utils/string.util";

const StartWorkAvatar = ({ handleClick }: StartWorkAvatarProps) => {
  const {
    data: { avatar, display_name },
    loading,
  } = useSelector(selectUserDetailsState);

  const [openSettings, setOpenSettings] = React.useState(false);
  const [showPopover, setShowPopover] = React.useState(false);

  const popoverContent = (
    <div className="StartWorkAvatar__Popover__Content">
      <div
        className="StartWorkAvatar__Popover__Content__Item"
        onClick={() => {
          setShowPopover(false);
          setOpenSettings(true);
        }}
      >
        <SettingIcon />
        <span>Manage account</span>
      </div>
      <div
        className="StartWorkAvatar__Popover__Content__Item StartWorkAvatar__Popover__Content__Item--SignOut"
        onClick={handleClick}
      >
        <LockFilledIcon />
        <span>Sign out</span>
      </div>
    </div>
  );
  return (
    <>
      <AccountSettings
        open={openSettings}
        onClose={() => setOpenSettings(false)}
        onOk={() => setOpenSettings(false)}
      />
      <Popover
        content={popoverContent}
        destroyTooltipOnHide={true}
        trigger="click"
        placement="bottomRight"
        overlayClassName="StartWorkAvatar__Popover"
        getPopupContainer={(trigger: any) => trigger.parentElement}
        visible={showPopover}
        onVisibleChange={(visible: boolean) => setShowPopover(visible)}
      >
        {loading ? (
          <LoadingIcon />
        ) : (
          <CustomAvatar
            size={40}
            src={avatar}
            fallbackText={getInitials(display_name)}
            className="StartWorkAvatar"
          />
        )}
      </Popover>
    </>
  );
};

export { StartWorkAvatar as default };
