import { RootState } from "../store/configureStore";

export interface SectionsState extends BaseReducerState {
  data: SectionArray;
}
export const selectSectionsState = (rootState: RootState) => rootState.sections;
export const sectionsReducerDefaultState: SectionsState = {
  data: [],
  error: "",
  loading: false,
};
const sectionsReducer = (
  state = sectionsReducerDefaultState,
  action: { type: string; sections: SectionArray; error: string }
) => {
  switch (action.type) {
    case "REQUEST_FETCH_SECTIONS":
      return {
        ...state,
        error: "",
        loading: true,
      };
    case "REQUEST_FETCH_SECTIONS_SUCCESS":
      return {
        data: action.sections,
        error: "",
        loading: false,
      };
    case "REQUEST_FETCH_SECTIONS_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export { sectionsReducer as default };
