import moment from "moment";

import { groupWorkShiftByDate } from "../utils/work-shift.util";

export const workShiftArray: WorkShiftArray = [
  {
    id: "N0QJ9O7FFQ",
    work_day: "DRYVO2YIXA",
    start_datetime: "2021-08-10T00:10:48.718825Z",
    end_datetime: "2021-08-10T00:19:18.091987Z",
    work_shift_notes: "",
  },
  {
    id: "V03WWRR8ZJ",
    work_day: "B4W1M0AQTL",
    start_datetime: "2021-08-09T00:26:51.520322Z",
    end_datetime: "2021-08-09T01:14:45.373102Z",
    work_shift_notes: "",
  },
  {
    id: "GHY33RZXWO",
    work_day: "B4W1M0AQTL",
    start_datetime: "2021-08-09T00:04:51.524714Z",
    end_datetime: "2021-08-09T00:26:51.524585Z",
    work_shift_notes: "",
  },
  {
    id: "EPO4VHQRYC",
    work_day: "DVNBCTI68K",
    start_datetime: "2021-08-07T00:05:13.983435Z",
    end_datetime: "2021-08-07T13:46:29.262876Z",
    work_shift_notes: "",
  },
  {
    id: "1TEZ9R1NO8",
    work_day: "56672QCABB",
    start_datetime: "2021-08-06T00:37:06.854978Z",
    end_datetime: "2021-08-06T01:49:42.603140Z",
    work_shift_notes: "",
  },
  {
    id: "9PUJ0LGMDS",
    work_day: "UV68OC6GWR",
    start_datetime: "2021-08-05T00:07:52.665839Z",
    end_datetime: "2021-08-05T03:00:17.289630Z",
    work_shift_notes: "",
  },
  {
    id: "2FU9XK6QZZ",
    work_day: "3U22NGY9V7",
    start_datetime: "2021-08-04T00:33:36.268447Z",
    end_datetime: "2021-08-04T06:51:56.804845Z",
    work_shift_notes: "",
  },
  {
    id: "LQRYI7SJSW",
    work_day: "D5ONJC5J5A",
    start_datetime: "2021-08-03T01:48:40.220429Z",
    end_datetime: "2021-08-04T00:33:36.272800Z",
    work_shift_notes: "",
  },
  {
    id: "SK8XQY09TS",
    work_day: "IZW59FNYUW",
    start_datetime: "2021-08-02T00:40:07.583588Z",
    end_datetime: "2021-08-02T01:42:22.513460Z",
    work_shift_notes: "",
  },
  {
    id: "NIVJZCOV9L",
    work_day: "5RP885KBI6",
    start_datetime: "2021-07-30T00:54:58.368291Z",
    end_datetime: "2021-08-02T00:40:07.591915Z",
    work_shift_notes: "",
  },
  {
    id: "63HK0VU0DD",
    work_day: "9AKEPBQHHG",
    start_datetime: "2021-07-29T00:21:23.589185Z",
    end_datetime: "2021-07-29T01:05:58.694913Z",
    work_shift_notes: "",
  },
];

export const workShiftObject = workShiftArray[0];

export const workShiftFiltered = workShiftArray.filter(
  (ws: WorkShiftObject) => {
    return moment(ws.start_datetime).isSame(moment(), "day");
  }
)[0];

export const dateGroupedWorkShifts = groupWorkShiftByDate(workShiftArray);
