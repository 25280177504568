import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Divider } from "antd";
import { useFeature } from "flagged";
import moment from "moment";

import Teammate from "../../Team/Teammate/Teammate";
import AccountSettings from "../../AccountSettings/AccountSettings.component";
import { selectUserDetailsState } from "../../../reducers/user-details.reducer";
import { selectTeammatesState } from "../../../reducers/teammates.reducer";
import { startPopulateTeammates } from "../../../actions/teammates.action";
import { selectStatusState } from "../../../reducers/status.reducer";
import { selectOfficeState } from "../../../reducers/office.reducer";
import { WorkShiftSchedule } from "../../Checkout/WorkTimes/WorkTimes";
import { getWorkShiftSchedule } from "../../../services/work-shift.service";
import { selectCurrentWorkDayState } from "../../../reducers/work-day.reducer";

/**
 * Displays currently logged in user and list of team members
 * Currently static
 */
const Teammates = () => {
  const isDev = useFeature("under_development");
  const dispatch = useDispatch();
  const {
    data: userDetails,
    loading: userDetailsLoading,
    updatedKeys,
  } = useSelector(selectUserDetailsState);
  const { data: teammates, loading: teammatesLoading } = useSelector(
    selectTeammatesState
  );
  const [openSettings, setOpenSettings] = React.useState(false);
  const status = useSelector(selectStatusState).data;
  const { data: office, loading: officeLoading } = useSelector(
    selectOfficeState
  );
  const { data: currentWorkDay } = useSelector(selectCurrentWorkDayState);
  const [todaySchedule, setTodaySchedule] = React.useState<WorkShiftSchedule[]>(
    []
  );
  const [scheduleLoading, setScheduleLoading] = React.useState(false);
  const loading =
    userDetailsLoading || teammatesLoading || officeLoading || scheduleLoading;
  React.useEffect(() => {
    if (userDetails.id) {
      setScheduleLoading(true);
      getWorkShiftSchedule(
        currentWorkDay.work_date,
        currentWorkDay.work_date,
        userDetails.id
      )
        .then((response) => {
          if (response.status === 200) {
            setTodaySchedule(response.data);
          }
          setScheduleLoading(false);
        })
        .catch((e) => {
          setScheduleLoading(false);
        });
    }
  }, [currentWorkDay.work_date, userDetails.id]);

  /**
   * TODO we generate the current teammate(user) by current user details since
   * the teammates endpoint is not available. we can replace it with the following
   * line when it is ready:
   * const currrentTeammate = teammates.find((t) => t.user === userDetails.id);
   */
  const currrentTeammate: Teammate | undefined = userDetails
    ? {
        user: userDetails.id,
        avatar: userDetails.avatar || "",
        city: userDetails.city,
        country: userDetails.country,
        email: userDetails.email,
        full_name: userDetails.full_name || "",
        display_name: userDetails.display_name,
        timezone: userDetails.timezone,
        role: userDetails.what_i_do,
        status: status.id,
        has_tracker: !!userDetails.external_tracker_config_json,
        today_starttime:
          todaySchedule.length > 0
            ? todaySchedule[0].start_datetime.toString()
            : "",
        today_endtime:
          todaySchedule.length > 0
            ? todaySchedule[todaySchedule.length - 1].end_datetime.toString()
            : "",
        next_workday:
          office.nextWorkdaySessions === 0
            ? ""
            : moment(office.nextWorkdayDate).format("YYYY-MM-DD HH:mm:ss"),
        pinned_yn: false,
        left_the_office_today_yn: false,
      }
    : undefined;

  const excludedTeammates = teammates.filter((t) => t.user !== userDetails.id);
  const pinnedTeamates = excludedTeammates.filter((t) => t.pinned_yn);
  const unpinnedTeammates = excludedTeammates.filter((t) => !t.pinned_yn);

  const renderTeammates = (list: Teammate[]) => {
    // order by status and full name
    const working = list
      .filter((item) => item.status === "working")
      .sort((a, b) => a.display_name.localeCompare(b.display_name));
    const breaking = list
      .filter((item) => item.status === "taking_break")
      .sort((a, b) => a.display_name.localeCompare(b.display_name));
    const outOfOffice = list
      .filter((item) => item.status === "out_of_office")
      .sort((a, b) => a.display_name.localeCompare(b.display_name));
    return [...working, ...breaking, ...outOfOffice].map((item) => (
      <Teammate key={item.user} teammate={item} loading={loading} />
    ));
  };

  React.useEffect(() => {
    dispatch(startPopulateTeammates());
  }, [dispatch]);

  return (
    <div className="Teammates">
      <Teammate
        teammate={currrentTeammate}
        isCurrentUser={true}
        loading={
          (updatedKeys.includes("avatar") && userDetailsLoading) ||
          officeLoading ||
          scheduleLoading
        }
        onSetting={() => setOpenSettings(true)}
      />
      <Divider className="Teammates__Divider" />
      {isDev && renderTeammates(pinnedTeamates)}
      {isDev && <Divider className="Teammates__Divider" />}
      {isDev && renderTeammates(unpinnedTeammates)}
      <AccountSettings
        open={openSettings}
        onClose={() => setOpenSettings(false)}
        onOk={() => setOpenSettings(false)}
      />
    </div>
  );
};

export { Teammates as default };
