import React from "react";
import { useSelector } from "react-redux";
import { selectScheduledTasksState } from "../reducers/tasks.reducer";

import { getTasksListService } from "../services/tasks.service";
import {
  joinTaskArrayToUserTaskArray,
  scheduleTaskJoinedToUser,
} from "../utils/tasks-consolidate.util";

export default function useAsyncConsolidateUserTasks(
  userTasks?: JoinTaskToUserArray
) {
  const { data: scheduledTasks, loading: scheduledTasksLoading } = useSelector(
    selectScheduledTasksState
  );

  const [tasks, setTasks] = React.useState<TaskArray>();

  const loading = tasks === undefined || scheduledTasksLoading;

  const getTasks = React.useCallback(async () => {
    if (!!userTasks) {
      const taskIds = userTasks?.map(({ task }) => task.id);
      if (taskIds.length > 0) {
        try {
          const response = await getTasksListService({
            task_ids: taskIds,
          });
          if (response.status === 200) {
            setTasks(
              response.data.results.map((task: TaskObject) => ({
                ...task,
                loading: false,
              })) as TaskArray
            );
          } else {
            throw new Error();
          }
        } catch (e) {}
      } else {
        setTasks([]);
      }
    }
  }, [userTasks]);

  const consolidatedTasks = React.useMemo(
    () =>
      !!tasks && !!userTasks
        ? scheduleTaskJoinedToUser(
            joinTaskArrayToUserTaskArray(tasks, userTasks),
            scheduledTasks
          )
        : undefined,
    [tasks, userTasks, scheduledTasks]
  );

  React.useEffect(() => {
    getTasks();
  }, [getTasks]);

  return [consolidatedTasks, loading] as [
    TaskJoinedToUserAndScheduledArray | undefined,
    boolean
  ];
}
