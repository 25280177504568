import React from "react";
import moment from "moment";
import cn from "classnames";
import { some } from "lodash";
import { EditorState, convertFromRaw } from "draft-js";

import { store } from "../store/configureStore";

import CommentTooltip from "../modules/General/CommentTooltip/CommentTooltip";
import CustomAvatar from "../modules/CustomAvatar/CustomAvatar";
import { MTagIcon } from "../modules/CustomIcons/CustomIcons.component";
import WorkDayCheckoutStatus from "../modules/Checkout/WorkDayCheckoutStatus/WorkDayCheckoutStatus";

import {
  CHECKOUT_CHECKS,
  CHECKOUT_STATUS,
  workDayDateFormat,
} from "../constants/constants";
import { selectCheckoutChecksState } from "../reducers/checkout.reducer";
import { getTotalDuration } from "../utils/time-conversion.util";
import { getInitials } from "../utils/string.util";

export type TableView = "standard" | "detailed";
export type TableType = "date" | "member";
interface GetColumnsParams {
  view: TableView;
  type: TableType;
  clickWorkHoursCallback: Function;
}

export const dateFormat = "YYYYMMDD";

const getCheckoutStatus = (checkout_status: CheckoutStatusArray) => {
  const failingCritical: CheckoutStatusArray = [];
  const failingNonCritical: CheckoutStatusArray = [];

  const checkoutChecks: CheckoutCheckArray = store.getState().checkoutChecks
    .data;

  checkout_status.forEach((checkoutStatusObject) => {
    const { checkout_check, passed_yn } = checkoutStatusObject;
    const checkoutCheck = checkoutChecks.find(
      ({ id: checkoutCheckid }) => checkout_check === checkoutCheckid
    );
    const { identifier: checkoutCheckIdentifier } = checkoutCheck || {};
    if (
      checkoutCheckIdentifier === CHECKOUT_CHECKS.SCHEDULE_FOR_NEXT_SHIFT &&
      !passed_yn
    ) {
      failingCritical.push(checkoutStatusObject);
    } else if (
      checkoutCheckIdentifier !== CHECKOUT_CHECKS.SCHEDULE_FOR_NEXT_SHIFT &&
      !passed_yn
    ) {
      failingNonCritical.push(checkoutStatusObject);
    }
  });

  if (failingCritical.length > 0) {
    return CHECKOUT_STATUS.CRITICAL;
  } else if (failingCritical.length === 0 && failingNonCritical.length > 0) {
    return CHECKOUT_STATUS.NON_CRITICAL;
  } else if (failingCritical.length === 0 && failingNonCritical.length === 0) {
    return CHECKOUT_STATUS.PASSED;
  } else {
    return CHECKOUT_STATUS.STILL_IN_OFFICE;
  }
};

export const getColumns = ({
  view,
  type,
  clickWorkHoursCallback,
}: GetColumnsParams) => {
  const baseDetailedColumnArray: WorkTimeRecordTableColumnArray = [
    {
      title: "Work hours",
      dataIndex: ["date", "shifts", "work_hours"],
      key: "workHours",
      render: (text: string, row: UserTaskTimeTableDataRecordObject) => {
        const {
          checkout_status,
          date,
          shifts,
          shownObservableUser,
          work_hours,
        } = row;
        const { notes, total_seconds = 0 } = work_hours || {};
        const hasShift = shifts && shifts.length > 0;
        const hasCheckoutStatus = checkout_status && checkout_status.length > 0;
        const hasUnfinishedShift = shifts
          ? shifts.some(({ end_datetime }) => !end_datetime)
          : false;

        const hasNotes =
          notes &&
          EditorState.createWithContent(convertFromRaw(JSON.parse(notes)))
            .getCurrentContent()
            .hasText();

        const notesPlainText = hasNotes
          ? EditorState.createWithContent(convertFromRaw(JSON.parse(notes!)))
              .getCurrentContent()
              .getPlainText()
          : "";

        return (
          <div
            className={cn(
              "WorkTimeRecordTable__Table__Data",
              "WorkTimeRecordTable__Table__Data--WorkHoursData",
              {
                "WorkTimeRecordTable__Table__Data--WorkHoursData--Active":
                  hasShift && hasCheckoutStatus,
              },
              {
                "WorkTimeRecordTable__Table__Data--Inactive": !hasShift,
              }
            )}
            onClick={
              shifts
                ? () => {
                    clickWorkHoursCallback(
                      shownObservableUser?.observed_user,
                      moment(date)
                    );
                  }
                : undefined
            }
          >
            {hasCheckoutStatus && (
              <WorkDayCheckoutStatus
                checkoutStatus={
                  hasUnfinishedShift
                    ? CHECKOUT_STATUS.STILL_IN_OFFICE
                    : getCheckoutStatus(checkout_status!)
                }
                checkoutStatusArray={checkout_status!}
              />
            )}
            <span>
              {getTotalDuration(moment.duration(total_seconds, "seconds"))}
            </span>
            {hasNotes && <CommentTooltip tooltipText={notesPlainText} />}
          </div>
        );
      },
    },
    {
      title: "In the office",
      children: [
        {
          title: "Shifts",
          dataIndex: "shifts",
          key: "inTheOfficeShifts",
          render: (shifts: UserTaskTimeTableDataRecordObject["shifts"]) => (
            <div
              className={cn(
                "WorkTimeRecordTable__Table__Data",
                "WorkTimeRecordTable__Table__Data--InTheOffice",
                "WorkTimeRecordTable__Table__Data--InTheOfficeShiftsData",
                {
                  "WorkTimeRecordTable__Table__Data--Inactive": !(
                    shifts && shifts.length > 0
                  ),
                }
              )}
            >
              {shifts &&
                shifts.map(
                  (
                    {
                      manual_work_shift_yn,
                      end_datetime,
                      notes,
                      start_datetime,
                    },
                    index
                  ) => {
                    return (
                      <div key={index}>
                        <div className="WorkTimeRecordTable__Table__Data--InTheOfficeShiftsData__ShiftSpan">
                          <span>{moment(start_datetime).format("H:mm")}</span>
                          <span>&nbsp;&ndash;&nbsp;</span>
                          <span>
                            {end_datetime
                              ? moment(end_datetime).format("H:mm")
                              : "now"}
                          </span>
                        </div>
                        {manual_work_shift_yn && <MTagIcon />}
                        {notes && <CommentTooltip tooltipText={notes} />}
                      </div>
                    );
                  }
                )}
            </div>
          ),
        },
        {
          title: "Hours",
          dataIndex: "shifts",
          key: "inTheOfficeHours",
          render: (shifts: UserTaskTimeTableDataRecordObject["shifts"]) => (
            <div
              className={cn(
                "WorkTimeRecordTable__Table__Data",
                "WorkTimeRecordTable__Table__Data--InTheOffice",
                "WorkTimeRecordTable__Table__Data--InTheOfficeHoursData",
                {
                  "WorkTimeRecordTable__Table__Data--Inactive": !(
                    shifts && shifts.length > 0
                  ),
                }
              )}
            >
              {shifts
                ? shifts.map(({ office_hours_in_seconds }, index) => {
                    return (
                      <div
                        key={index}
                        className={cn(
                          "WorkTimeRecordTable__Table__Data",
                          "WorkTimeRecordTable__Table__Data--InTheOffice",
                          "WorkTimeRecordTable__Table__Data--InTheOfficeHoursData",
                          {
                            "WorkTimeRecordTable__Table__Data--Inactive":
                              office_hours_in_seconds === 0,
                          }
                        )}
                      >
                        <span>
                          {getTotalDuration(
                            moment.duration(office_hours_in_seconds, "seconds")
                          )}
                        </span>
                      </div>
                    );
                  })
                : "0m"}
            </div>
          ),
        },
        {
          title: "Break",
          dataIndex: "shifts",
          key: "break",
          render: (shifts: UserTaskTimeTableDataRecordObject["shifts"]) => (
            <div
              className={cn(
                "WorkTimeRecordTable__Table__Data",
                "WorkTimeRecordTable__Table__Data--InTheOffice",
                "WorkTimeRecordTable__Table__Data--BreakData",
                {
                  "WorkTimeRecordTable__Table__Data--Inactive": !(
                    shifts && shifts.length > 0
                  ),
                }
              )}
            >
              {shifts
                ? shifts.map(({ break_in_seconds }, index) => {
                    return (
                      <div
                        key={index}
                        className={cn(
                          "WorkTimeRecordTable__Table__Data",
                          "WorkTimeRecordTable__Table__Data--InTheOffice",
                          "WorkTimeRecordTable__Table__Data--BreakData",
                          {
                            "WorkTimeRecordTable__Table__Data--Inactive":
                              break_in_seconds === 0,
                          }
                        )}
                      >
                        <span>
                          {getTotalDuration(
                            moment.duration(break_in_seconds, "seconds")
                          )}
                        </span>
                      </div>
                    );
                  })
                : "0m"}
            </div>
          ),
        },
        {
          title: "Tasks",
          dataIndex: "shifts",
          key: "tasks",
          render: (shifts: UserTaskTimeTableDataRecordObject["shifts"]) => {
            return (
              <div
                className={cn(
                  "WorkTimeRecordTable__Table__Data",
                  "WorkTimeRecordTable__Table__Data--InTheOffice",
                  "WorkTimeRecordTable__Table__Data--BreakData",
                  {
                    "WorkTimeRecordTable__Table__Data--Inactive": !(
                      shifts && shifts.length > 0
                    ),
                  }
                )}
              >
                {shifts
                  ? shifts.map(({ allocated_time }, index) => {
                      return (
                        <div
                          key={index}
                          className={cn(
                            "WorkTimeRecordTable__Table__Data",
                            "WorkTimeRecordTable__Table__Data--InTheOffice",
                            "WorkTimeRecordTable__Table__Data--BreakData",
                            {
                              "WorkTimeRecordTable__Table__Data--Inactive":
                                allocated_time === 0,
                            }
                          )}
                        >
                          <span>
                            {getTotalDuration(
                              moment.duration(allocated_time, "seconds")
                            )}
                          </span>
                        </div>
                      );
                    })
                  : "0m"}
              </div>
            );
          },
        },
      ],
    },
    {
      title: "Tracked",
      children: [
        {
          title: "Hours",
          dataIndex: "shifts",
          key: "trackedHours",
          render: (shifts: UserTaskTimeTableDataRecordObject["shifts"]) => {
            return (
              <div
                className={cn(
                  "WorkTimeRecordTable__Table__Data",
                  "WorkTimeRecordTable__Table__Data--Tracked",
                  "WorkTimeRecordTable__Table__Data--TrackedHoursData",
                  {
                    "WorkTimeRecordTable__Table__Data--Inactive": !(
                      shifts && shifts.length > 0
                    ),
                  }
                )}
              >
                {shifts
                  ? shifts.map(({ tracked }, index) => {
                      const { added_manually, total_seconds } = tracked;
                      return (
                        <div
                          key={index}
                          className={cn(
                            "WorkTimeRecordTable__Table__Data",
                            "WorkTimeRecordTable__Table__Data--Tracked",
                            "WorkTimeRecordTable__Table__Data--TrackedHoursData",
                            {
                              "WorkTimeRecordTable__Table__Data--Inactive":
                                total_seconds === 0,
                            }
                          )}
                        >
                          <span>
                            {getTotalDuration(
                              moment.duration(total_seconds, "seconds")
                            )}
                          </span>
                          {added_manually && <MTagIcon />}
                        </div>
                      );
                    })
                  : "0m"}
              </div>
            );
          },
        },
        {
          title: "Manual",
          dataIndex: "shifts",
          key: "manual",
          render: (shifts: UserTaskTimeTableDataRecordObject["shifts"]) => {
            return (
              <div
                className={cn(
                  "WorkTimeRecordTable__Table__Data",
                  "WorkTimeRecordTable__Table__Data--Tracked",
                  "WorkTimeRecordTable__Table__Data--TrackedManualData",
                  {
                    "WorkTimeRecordTable__Table__Data--Inactive": !(
                      shifts && shifts.length > 0
                    ),
                  }
                )}
              >
                {shifts
                  ? shifts.map(({ tracked }, index) => {
                      const { total_seconds_manually } = tracked;
                      const formattedTime = getTotalDuration(
                        moment.duration(total_seconds_manually, "seconds")
                      );
                      return (
                        <div
                          key={index}
                          className={cn(
                            "WorkTimeRecordTable__Table__Data",
                            "WorkTimeRecordTable__Table__Data--Tracked",
                            "WorkTimeRecordTable__Table__Data--TrackedManualData",
                            {
                              "WorkTimeRecordTable__Table__Data--Inactive":
                                total_seconds_manually === 0,
                            }
                          )}
                        >
                          <span>
                            {total_seconds_manually > 0
                              ? `(${formattedTime})`
                              : "0m"}
                          </span>
                        </div>
                      );
                    })
                  : "0m"}
              </div>
            );
          },
        },
        {
          title: "Level",
          dataIndex: "shifts",
          key: "level",
          render: (shifts: UserTaskTimeTableDataRecordObject["shifts"]) => {
            return (
              <div
                className={cn(
                  "WorkTimeRecordTable__Table__Data",
                  "WorkTimeRecordTable__Table__Data--Tracked",
                  "WorkTimeRecordTable__Table__Data--TrackedLevelData",
                  {
                    "WorkTimeRecordTable__Table__Data--Inactive": !(
                      shifts && shifts.length > 0
                    ),
                  }
                )}
              >
                {shifts
                  ? shifts.map(({ tracked }, index) => {
                      const { tracked_level_percentage } = tracked;
                      return (
                        <div
                          key={index}
                          className={cn(
                            "WorkTimeRecordTable__Table__Data",
                            "WorkTimeRecordTable__Table__Data--Tracked",
                            "WorkTimeRecordTable__Table__Data--TrackedLevelData",
                            {
                              "WorkTimeRecordTable__Table__Data--Inactive":
                                tracked_level_percentage === 0,
                            }
                          )}
                        >
                          <span>
                            {tracked_level_percentage > 0
                              ? `${Math.round(tracked_level_percentage * 100)}%`
                              : 0}
                          </span>
                        </div>
                      );
                    })
                  : 0}
              </div>
            );
          },
        },
      ],
    },
    {
      title: "Schedule",
      children: [
        {
          title: "Shifts",
          dataIndex: "schedules",
          key: "scheduleShifts",
          render: (
            schedules: UserTaskTimeTableDataRecordObject["schedules"]
          ) => {
            return (
              <div
                className={cn(
                  "WorkTimeRecordTable__Table__Data",
                  "WorkTimeRecordTable__Table__Data--Schedule",
                  "WorkTimeRecordTable__Table__Data--ScheduleShiftsData"
                )}
              >
                {schedules &&
                  schedules.map(({ end_datetime, start_datetime }, index) => {
                    return (
                      <div key={index}>
                        <span>{moment(start_datetime).format("H:mm")}</span>
                        <span>&ndash;</span>
                        <span>
                          {end_datetime
                            ? moment(end_datetime).format("H:mm")
                            : "now"}
                        </span>
                      </div>
                    );
                  })}
              </div>
            );
          },
        },
        {
          title: "Hours",
          dataIndex: "schedules",
          key: "scheduleHours",
          render: (
            schedules: UserTaskTimeTableDataRecordObject["schedules"]
          ) => {
            return (
              <div
                className={cn(
                  "WorkTimeRecordTable__Table__Data",
                  "WorkTimeRecordTable__Table__Data--Schedule",
                  "WorkTimeRecordTable__Table__Data--ScheduleHoursData",
                  {
                    "WorkTimeRecordTable__Table__Data--Inactive": !(
                      schedules && schedules.length > 0
                    ),
                  }
                )}
              >
                {schedules
                  ? schedules.map(({ notes, total_seconds }, index) => {
                      return (
                        <div key={index}>
                          <span>
                            {getTotalDuration(
                              moment.duration(total_seconds, "seconds")
                            )}
                          </span>
                          {notes && <CommentTooltip tooltipText={notes} />}
                        </div>
                      );
                    })
                  : "0m"}
              </div>
            );
          },
        },
      ],
    },
  ];

  /**
   * STANDARD
   */
  const baseStandardColumnArray: WorkTimeRecordTableColumnArray = [
    {
      title: "Work hours",
      dataIndex: ["date", "work_hours", "shifts"],
      key: "workHours",
      render: (text: string, row: UserTaskTimeTableDataRecordObject) => {
        const {
          checkout_status,
          date,
          shifts,
          shownObservableUser,
          work_hours,
        } = row;
        const { notes, total_seconds = 0 } = work_hours || {};
        const hasShift = shifts && shifts.length > 0;
        const hasCheckoutStatus = checkout_status && checkout_status.length > 0;
        const hasUnfinishedShift = shifts
          ? shifts.some(({ end_datetime }) => !end_datetime)
          : false;

        const hasNotes =
          notes &&
          EditorState.createWithContent(convertFromRaw(JSON.parse(notes)))
            .getCurrentContent()
            .hasText();

        const notesPlainText = hasNotes
          ? EditorState.createWithContent(convertFromRaw(JSON.parse(notes!)))
              .getCurrentContent()
              .getPlainText()
          : "";

        return (
          <div
            className={cn(
              "WorkTimeRecordTable__Table__Data",
              "WorkTimeRecordTable__Table__Data--WorkHoursData",
              {
                "WorkTimeRecordTable__Table__Data--WorkHoursData--Active":
                  hasShift && hasCheckoutStatus,
              },
              {
                "WorkTimeRecordTable__Table__Data--Inactive": !hasShift,
              }
            )}
            onClick={
              shifts
                ? () => {
                    clickWorkHoursCallback(
                      shownObservableUser?.observed_user,
                      moment(date)
                    );
                  }
                : undefined
            }
          >
            {hasCheckoutStatus && (
              <WorkDayCheckoutStatus
                checkoutStatus={
                  hasUnfinishedShift
                    ? CHECKOUT_STATUS.STILL_IN_OFFICE
                    : getCheckoutStatus(checkout_status!)
                }
                checkoutStatusArray={checkout_status!}
              />
            )}
            <span>
              {getTotalDuration(moment.duration(total_seconds, "seconds"))}
            </span>
            {hasNotes && <CommentTooltip tooltipText={notesPlainText} />}
          </div>
        );
      },
    },
    {
      title: "Shifts",
      dataIndex: "shifts",
      key: "shifts",
      render: (shifts: UserTaskTimeTableDataRecordObject["shifts"]) => {
        const shiftsCount = shifts ? shifts.length : 0;
        const hasManuallyAddedShift = some(shifts, "manual_work_shift_yn");
        const shiftsNotes = shifts
          ? shifts
              .map(({ notes }) => notes)
              .reduce((prev, next) => {
                if (prev && !next) {
                  return prev;
                } else if (!prev && next) {
                  return next;
                } else if (!prev && !next) {
                  return "";
                } else {
                  return prev + `\n\n` + next;
                }
              }, "")
          : "";
        return (
          <div className="WorkTimeRecordTable__Table__Data WorkTimeRecordTable__Table__Data--InTheOffice WorkTimeRecordTable__Table__Data--InTheOfficeShiftsData">
            <div
              className={cn(
                "WorkTimeRecordTable__Table__Data",
                "WorkTimeRecordTable__Table__Data--ShiftData",
                {
                  "WorkTimeRecordTable__Table__Data--Inactive":
                    shiftsCount === 0,
                }
              )}
            >
              <span>{shiftsCount}</span>
              {hasManuallyAddedShift && <MTagIcon />}
              {shiftsNotes && <CommentTooltip tooltipText={shiftsNotes} />}
            </div>
          </div>
        );
      },
    },
    {
      title: "Office hours",
      dataIndex: "shifts",
      key: "officeHours",
      render: (shifts: UserTaskTimeTableDataRecordObject["shifts"]) => {
        const totalOfficeHoursSeconds = shifts
          ? shifts
              .map(({ office_hours_in_seconds }) => office_hours_in_seconds)
              .reduce((prev, current) => prev + current, 0)
          : 0;
        return (
          <div
            className={cn(
              "WorkTimeRecordTable__Table__Data",
              "WorkTimeRecordTable__Table__Data--InTheOffice",
              "WorkTimeRecordTable__Table__Data--InTheOfficeHoursData",
              {
                "WorkTimeRecordTable__Table__Data--Inactive":
                  totalOfficeHoursSeconds === 0,
              }
            )}
          >
            {getTotalDuration(
              moment.duration(totalOfficeHoursSeconds, "seconds")
            )}
          </div>
        );
      },
    },
    {
      title: "Break",
      dataIndex: "shifts",
      key: "breakTime",
      render: (shifts: UserTaskTimeTableDataRecordObject["shifts"]) => {
        const totalBreakSeconds = shifts
          ? shifts
              .map(({ break_in_seconds }) => break_in_seconds)
              .reduce((prev, current) => prev + current, 0)
          : 0;
        return (
          <div
            className={cn(
              "WorkTimeRecordTable__Table__Data",
              "WorkTimeRecordTable__Table__Data--InTheOffice",
              "WorkTimeRecordTable__Table__Data--InTheOfficeHoursData",
              {
                "WorkTimeRecordTable__Table__Data--Inactive":
                  totalBreakSeconds === 0,
              }
            )}
          >
            {getTotalDuration(moment.duration(totalBreakSeconds, "seconds"))}
          </div>
        );
      },
    },
    {
      title: "Tasks",
      dataIndex: "shifts",
      key: "tasks",
      render: (shifts: UserTaskTimeTableDataRecordObject["shifts"]) => {
        const totalAllocatedTime = shifts
          ? shifts
              .map(({ allocated_time }) => allocated_time)
              .reduce((prev, current) => prev + current, 0)
          : 0;
        return (
          <div
            className={cn(
              "WorkTimeRecordTable__Table__Data",
              "WorkTimeRecordTable__Table__Data--ActivityData",
              {
                "WorkTimeRecordTable__Table__Data--Inactive":
                  totalAllocatedTime === 0,
              }
            )}
          >
            <span>
              {getTotalDuration(moment.duration(totalAllocatedTime, "seconds"))}
            </span>
          </div>
        );
      },
    },
    {
      title: "Tracked",
      dataIndex: "shifts",
      key: "tracked",
      render: (shifts: UserTaskTimeTableDataRecordObject["shifts"]) => {
        const totalTrackedSeconds = shifts
          ? shifts
              .map(({ tracked }) => tracked.total_seconds)
              .reduce((prev, current) => prev + current, 0)
          : 0;
        const hasManuallyAddedTrackedTime = some(
          shifts,
          "tracked.added_manually"
        );
        return (
          <div
            className={cn(
              "WorkTimeRecordTable__Table__Data",
              "WorkTimeRecordTable__Table__Data--Tracked",
              "WorkTimeRecordTable__Table__Data--TrackedHoursData",
              {
                "WorkTimeRecordTable__Table__Data--Inactive":
                  totalTrackedSeconds === 0,
              }
            )}
          >
            <span>
              {getTotalDuration(
                moment.duration(totalTrackedSeconds, "seconds")
              )}
            </span>
            {hasManuallyAddedTrackedTime && <MTagIcon />}
          </div>
        );
      },
    },
    {
      title: "Tracked Level",
      dataIndex: "shifts",
      key: "activityLevel",
      render: (shifts: UserTaskTimeTableDataRecordObject["shifts"]) => {
        const totalTrackedLevelPercentage =
          shifts && shifts.length > 0
            ? Math.round(
                shifts
                  .map(({ tracked }) => tracked.tracked_level_percentage)
                  .reduce((prev, current) => prev + current, 0) * 100
              )
            : 0;
        const averageTrackedLevelPercentage =
          shifts && shifts.length > 0
            ? Math.round(totalTrackedLevelPercentage / shifts.length)
            : 0;
        return (
          <div
            className={cn(
              "WorkTimeRecordTable__Table__Data",
              "WorkTimeRecordTable__Table__Data--Tracked",
              "WorkTimeRecordTable__Table__Data--TrackedLevelData",
              {
                "WorkTimeRecordTable__Table__Data--Inactive":
                  averageTrackedLevelPercentage === 0,
              }
            )}
          >
            <span>
              {averageTrackedLevelPercentage}
              {averageTrackedLevelPercentage > 0 && "%"}
            </span>
          </div>
        );
      },
    },
    {
      title: "Schedule",
      dataIndex: "schedules",
      key: "schedule",
      render: (schedules: UserTaskTimeTableDataRecordObject["schedules"]) => {
        const totalScheduleSeconds =
          schedules && schedules.length > 0
            ? schedules
                .map(({ total_seconds }) => total_seconds)
                .reduce((prev, current) => prev + current, 0)
            : 0;
        const scheduleNotes = schedules
          ? schedules
              .map(({ notes }) => notes)
              .reduce((prev, next) => {
                if (prev && !next) {
                  return prev;
                } else if (!prev && next) {
                  return next;
                } else if (!prev && !next) {
                  return "";
                } else {
                  return prev + `\n\n` + next;
                }
              }, "")
          : "";
        return (
          <div
            className={cn(
              "WorkTimeRecordTable__Table__Data",
              "WorkTimeRecordTable__Table__Data--ScheduleData",
              {
                "WorkTimeRecordTable__Table__Data--Inactive":
                  totalScheduleSeconds === 0,
              }
            )}
          >
            <span>
              {getTotalDuration(
                moment.duration(totalScheduleSeconds, "seconds")
              )}
            </span>
            {scheduleNotes && <CommentTooltip tooltipText={scheduleNotes} />}
          </div>
        );
      },
    },
  ];

  const dateColumnObject = {
    title: "Date",
    dataIndex: "date",
    key: "date",
    render: (date: UserTaskTimeTableDataRecordObject["date"]) => {
      return (
        <div className="WorkTimeRecordTable__Table__Data WorkTimeRecordTable__Table__Data--DateData">
          {moment(date, workDayDateFormat).format("ddd, MMM D")}
        </div>
      );
    },
  };

  const memberColumnObject = {
    title: "Member",
    dataIndex: "shownObservableUser",
    key: "member",
    render: ({ observed_user }: ObservableUserObject) => {
      const { avatar, display_name, current_status } = observed_user || {};
      const { status_enum_identifier } = current_status || {};
      return (
        <div
          className={cn(
            "WorkTimeRecordTable__Table__Data WorkTimeRecordTable__Table__Data--MemberData"
          )}
        >
          <CustomAvatar
            size={24}
            src={avatar}
            work_status={status_enum_identifier}
            fallbackText={getInitials(display_name)}
          />
          <span>{display_name}</span>
        </div>
      );
    },
  };

  const historyStandardColumnArray: WorkTimeRecordTableColumnArray = [
    dateColumnObject,
    ...baseStandardColumnArray,
  ];

  const memberStandardColumnArray: WorkTimeRecordTableColumnArray = [
    memberColumnObject,
    ...baseStandardColumnArray,
  ];

  const historyDetailedColumnArray: WorkTimeRecordTableColumnArray = [
    dateColumnObject,
    ...baseDetailedColumnArray,
  ];
  const memberDetailedColumnArray: WorkTimeRecordTableColumnArray = [
    memberColumnObject,
    ...baseDetailedColumnArray,
  ];

  if (type === "date" && view === "standard") {
    return historyStandardColumnArray;
  } else if (type === "date" && view === "detailed") {
    return historyDetailedColumnArray;
  } else if (type === "member" && view === "standard") {
    return memberStandardColumnArray;
  } else {
    return memberDetailedColumnArray;
  }
};
