import React from "react";

import ProjectTimeBar from "../../Projects/ProjectTimeBar/ProjectTimeBar";

/**
 * Container of time bars that display the work time related to a passed project or unallocated time
 */
const ProjectTimeBarContainer: React.FC<ProjectTimeBarContainerProps> = ({
  projects,
  projectsAndUnallocatedTotalTime,
}) => {
  return (
    <div className="ProjectTimeBarContainer">
      {projects.map((project, index) => {
        return (
          <ProjectTimeBar
            key={index}
            project={project}
            totalTime={projectsAndUnallocatedTotalTime}
          />
        );
      })}
      <ProjectTimeBar
        isUnallocated={true}
        totalTime={projectsAndUnallocatedTotalTime}
      />
    </div>
  );
};

export { ProjectTimeBarContainer as default };
