import { AxiosResponse } from "axios";

import axios from "../apis/django.instance";
import { dispatchError } from "../utils/error.util";

export type GetProjectsColorsService = () => Promise<
  AxiosResponse<ProjectColorArray>
>;
export const getProjectsColorsListService: GetProjectsColorsService = () => {
  return axios.get("/api/media/color/"); // must be in this format in order to mock implementation in unit test
};

export const getProjectColorService = async (
  colorId: ProjectColorObject["id"]
) => {
  try {
    const response = await axios.get(`/api/media/color/${colorId}`);
    if (response.status === 200) {
      return response.data as ProjectColorObject;
    } else {
      throw new Error();
    }
  } catch (e) {
    dispatchError({
      e,
      title: "Get project color error",
    });
    return null;
  }
};

export type CreateColorService = (
  payload: Omit<ProjectColorObject, "id">
) => Promise<AxiosResponse<ProjectColorObject>>;
export const createColorService: CreateColorService = (payload) => {
  return axios.post("/api/media/color/", payload);
};
