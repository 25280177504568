import React from "react";

import { InTheOfficeContentContext } from "../../../../views/InTheOffice/InTheOfficeContent";

import Button from "../../../Button/Button";
import TaskContributorSelector from "../../TaskContributorSelector/TaskContributorSelector";

const FilterTasksButtonPopoverContent = () => {
  const inTheOfficeContentContext = React.useContext(InTheOfficeContentContext);

  const {
    setTaskOwnersFilter,
    setTaskMembersFilter,
  } = inTheOfficeContentContext;

  const handleClickClear = () => {
    setTaskOwnersFilter([]);
    setTaskMembersFilter([]);
  };

  return (
    <div className="FilterTasksButtonPopoverContent">
      <div className="FilterTasksButtonPopoverContent__Item">
        <div className="FilterTasksButtonPopoverContent__Item__Title">
          Task owner
        </div>
        <TaskContributorSelector role="owner" />
      </div>
      <div className="FilterTasksButtonPopoverContent__Item">
        <div className="FilterTasksButtonPopoverContent__Item__Title">
          Task members
        </div>
        <TaskContributorSelector role="member" />
      </div>
      <Button onClick={handleClickClear}>Clear filter</Button>
    </div>
  );
};

export default FilterTasksButtonPopoverContent;
