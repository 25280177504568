import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Popover, Tooltip } from "antd";

import {
  PriorityHighIcon,
  PriorityMediumIcon,
  PriorityLowIcon,
  PriorityNoneIcon,
  LoadingIcon,
} from "../../CustomIcons/CustomIcons.component";

import {
  startCreateJoinTaskToUserRecord,
  startPartialUpdateJoinTaskToUserRecord,
} from "../../../actions/tasks.action";
import { selectPrioritiesState } from "../../../reducers/priorities.reducer";
import { selectUserDetailsState } from "../../../reducers/user-details.reducer";

/**
 * Default Display
 * Displays passed join_task_to_user record's priority
 *
 * Clicked Display
 * Displays list of priorities fetched from the server
 *
 * Functionality
 * Allows a user to set passed join_task_to_user record's priority
 */
export interface PriorityProps {
  task: TaskJoinedToUserAndScheduledObject;
}
const Priority: React.FC<PriorityProps> = ({
  task: {
    id: taskId,
    join_task_to_user_id: joinTaskToUserId = "",
    priority,
    loading: taskLoading,
  },
}) => {
  const dispatch = useDispatch();

  const {
    data: { id: userId },
  } = useSelector(selectUserDetailsState);
  const priorities = useSelector(selectPrioritiesState).data;

  const { loading: priorityLoading, value: priorityId = "" } = priority || {};
  const isUpdating = taskLoading && priorityLoading;

  const [visible, setVisible] = React.useState(false);

  const priorityObject = priorities.find(({ id }) => id === priorityId);
  const { level_of_priority: priorityLevel = 0 } = priorityObject || {};

  const handleScroll = () => {
    setVisible(false);
  };

  const handleVisibleChange = (visible: boolean) => {
    setVisible(visible);
  };

  const handleClickSelectPriority = (priorityId: PriorityObject["id"]) => (
    e: React.MouseEvent
  ) => {
    if (e) e.stopPropagation();
    if (joinTaskToUserId) {
      const data = {
        id: joinTaskToUserId,
        priority: priorityId,
        taskId,
      };
      dispatch(startPartialUpdateJoinTaskToUserRecord(data));
      setVisible(false);
    } else {
      dispatch(
        startCreateJoinTaskToUserRecord({
          priority: priorityId,
          task: taskId,
          user: userId,
        })
      );
    }
  };

  React.useEffect(() => {
    const elem = document.getElementsByClassName("ProjectCardList");
    if (elem.length > 0) elem[0].addEventListener("scroll", handleScroll);

    return () => {
      if (elem.length > 0) elem[0].removeEventListener("scroll", handleScroll);
    };
  }, []);

  const priorityComponentBasedOnLevel = (
    priorityLevel: PriorityObject["level_of_priority"]
  ) => {
    switch (priorityLevel) {
      case 3:
        return PriorityHighIcon;
      case 2:
        return PriorityMediumIcon;
      case 1:
        return PriorityLowIcon;
      default:
        return PriorityNoneIcon;
    }
  };

  let PriorityComponent: (
    props: any
  ) => JSX.Element = priorityComponentBasedOnLevel(priorityLevel);

  const content = (
    <div className="Priority__Clicked">
      {priorities &&
        priorities
          .sort((a, b) => {
            const keyA = a.level_of_priority,
              keyB = b.level_of_priority;
            if (keyA < keyB) return 1;
            if (keyA > keyB) return -1;
            return 0;
          })
          .map((p) => {
            const PComponent = priorityComponentBasedOnLevel(
              p.level_of_priority
            );
            return (
              <div
                key={p.id}
                className="Priority__Clicked__Button"
                onClick={handleClickSelectPriority(p.id)}
              >
                <span>{p.title}</span>
                <PComponent />
              </div>
            );
          })}
    </div>
  );

  return (
    <div className="Priority">
      <Tooltip title={"Priority (visible only to you)"}>
        <Popover
          placement="bottom"
          content={content}
          trigger="hover"
          overlayClassName="Priority__Popover"
          visible={visible}
          onVisibleChange={handleVisibleChange}
        >
          {isUpdating ? (
            <LoadingIcon />
          ) : (
            <PriorityComponent
              className="Priority__Component"
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                if (e) e.stopPropagation();
              }}
            />
          )}
        </Popover>
      </Tooltip>
    </div>
  );
};

export { Priority as default };
