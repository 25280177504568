import React from "react";
import { Popover } from "antd";

import {
  InTheOfficeIcon,
  LeaveTheOfficeIcon,
} from "../../CustomIcons/CustomIcons.component";
import InTheOfficeBtnPopoverContent from "./InTheOfficeBtnPopoverContent/InTheOfficeBtnPopoverContent";

/**
 * Default Display
 * Displays in the office dropdown button
 *
 * Clicked Display
 * Displays duration of total work time for the day
 * Displays duration of total break time for the day
 * Displays checkout notification
 * Displays Leave the office button
 *
 * Functionality
 * Updates the duration of the total work time for the day at an interval of one minute
 * Updates the duration of the total break time for the day at an interval of one minute
 */

const InTheOfficeBtn = () => {
  const [displayPopover, setDisplayPopover] = React.useState(false);

  return (
    <div className="InTheOfficeBtn">
      <Popover
        content={
          <InTheOfficeBtnPopoverContent setDisplayPopover={setDisplayPopover} />
        }
        trigger="click"
        placement="bottom"
        overlayClassName="InTheOfficeBtn__Popover"
        getPopupContainer={(trigger: any) => trigger}
        visible={displayPopover}
        onVisibleChange={(visible: boolean) => setDisplayPopover(visible)}
      >
        <div
          className="InTheOfficeBtn__Btn"
          onClick={() => setDisplayPopover((c) => !c)}
        >
          <div>
            <InTheOfficeIcon className="InTheOfficeBtn__Btn__OfficeIcon" />
            <span className="InTheOfficeBtn__Btn__Text">In the office</span>
          </div>
          <LeaveTheOfficeIcon />
        </div>
      </Popover>
    </div>
  );
};

export { InTheOfficeBtn as default };
