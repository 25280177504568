import React from "react";

import CheckoutDateNavigation from "../CheckoutDateNavigation/CheckoutDateNavigation";
import CustomAvatar from "../../CustomAvatar/CustomAvatar";

/**
 * Default Display
 * Header for the checkout panel
 */
export interface CheckoutHeaderProps {
  checkoutUser: UserObject;
  mode: CheckoutPanelMode;
}
const CheckoutHeader: React.FC<CheckoutHeaderProps> = ({
  checkoutUser,
  mode,
}) => {
  const { avatar, current_status, display_name, workspace_role } = checkoutUser;
  const { status_enum_identifier } = current_status || {};

  return (
    <div className="CheckoutHeader">
      <div className="CheckoutHeader__Title">
        {mode === "history" ? (
          <div className="CheckoutHeader__Title__UserDetails">
            <CustomAvatar src={avatar} work_status={status_enum_identifier} />
            <div className="CheckoutHeader__Title__UserDetails__Info">
              <span className="CheckoutHeader__Title__UserDetails__Info__Name">
                {display_name}
              </span>
              <span className="CheckoutHeader__Title__UserDetails__Info__Role">
                {workspace_role}
              </span>
            </div>
          </div>
        ) : (
          <span>Checkout</span>
        )}
      </div>

      <CheckoutDateNavigation />
    </div>
  );
};

export default CheckoutHeader;
