import { RootState } from "../store/configureStore";

export interface ThirdPartyTrackingListState extends BaseReducerState {
  data: ExternalTimeTrackerArray;
}
export const selectThirdPartyTrackingListState = (rootState: RootState) =>
  rootState.thirdPartyTrackingList;
export const thirdPartyTrackingListReducerDefaultState: ThirdPartyTrackingListState = {
  data: [],
  error: "",
  loading: false,
};
export const thirdPartyTrackingListReducer = (
  state = thirdPartyTrackingListReducerDefaultState,
  action: {
    type: string;
    thirdPartyTrackingList: ExternalTimeTrackerArray;
    error: string;
  }
) => {
  switch (action.type) {
    case "REQUEST_FETCH_THIRD_PARTY_TRACKING_LIST":
      return {
        ...state,
        error: "",
        loading: true,
      };
    case "REQUEST_FETCH_THIRD_PARTY_TRACKING_LIST_SUCCESS":
      return {
        data: action.thirdPartyTrackingList,
        error: "",
        loading: false,
      };
    case "REQUEST_FETCH_THIRD_PARTY_TRACKING_LIST_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export interface ThirdPartyTrackerState extends BaseReducerState {
  data: ExternalTimeTrackerObject;
}
export const selectThirdPartyTrackerState = (rootState: RootState) =>
  rootState.thirdPartyTracker;
export const thirdPartyTrackerReducerDefaultState: ThirdPartyTrackerState = {
  data: {} as ExternalTimeTrackerObject,
  error: "",
  loading: false,
};
export const thirdPartyTrackerReducer = (
  state = thirdPartyTrackerReducerDefaultState,
  action: {
    type: string;
    thirdPartyTracker: ExternalTimeTrackerObject;
    error: string;
  }
) => {
  switch (action.type) {
    case "REQUEST_FETCH_THIRD_PARTY_TRACKER":
      return {
        ...state,
        error: "",
        loading: true,
      };
    case "REQUEST_FETCH_THIRD_PARTY_TRACKER_SUCCESS":
    case "SET_THIRD_PARTY_TRACKER":
      return {
        data: action.thirdPartyTracker,
        error: "",
        loading: false,
      };
    case "REQUEST_FETCH_THIRD_PARTY_TRACKER_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
