import React from "react";

import useTaskArrayWithUserTaskAndScheduleTask from "../../../hooks/useTaskArrayWithUserTaskAndScheduleTask.hook";

import { MergeTaskModalContext } from "../MergeTask/MergeTaskModal/MergeTaskModal";
import { TaskSearchBarContext } from "../TaskSearchBar/TaskSearchBar";

import SearchTaskCardItem from "../../Tasks/SearchTaskCardItem/SearchTaskCardItem";

/**
 * Task Card List displayed in the search bar
 *
 * Default Display
 * Displays consolidated task array (task, join_task_to_user, scheduled task)
 *
 * Functionality
 * Task array is filtered depending on props passed
 * Header display contains title depending on filter and task array count
 */
export interface SearchTaskCardListProps {
  filter: string;
  searchTaskArray: TaskArray;
}
const SearchTaskCardList: React.FC<SearchTaskCardListProps> = ({
  filter,
  searchTaskArray,
}) => {
  const { selectedSourceTask, destinationTask } = React.useContext(
    MergeTaskModalContext
  );
  const { id: selectedSourceTaskId } = selectedSourceTask || {};
  const { id: destinationTaskId } = destinationTask || {};

  const { isMerge } = React.useContext(TaskSearchBarContext);

  const [title, setTitle] = React.useState("");
  const [filteredTasks, setFilteredTasks] = React.useState<
    TaskJoinedToUserAndScheduledArray
  >([]);
  const searchTaskJoinedToUserAndScheduledArray = useTaskArrayWithUserTaskAndScheduleTask(
    searchTaskArray
  );

  React.useEffect(() => {
    switch (filter) {
      case "all":
        setTitle("All projects");
        break;
      default:
        if (filter) setTitle(filter.charAt(0).toUpperCase() + filter.slice(1));
    }
  }, [filter]);

  React.useEffect(() => {
    if (filter === "today") {
      setFilteredTasks(
        searchTaskJoinedToUserAndScheduledArray.filter(
          ({ today_yn }) => today_yn
        )
      );
    } else if (isMerge && selectedSourceTaskId) {
      setFilteredTasks(
        searchTaskJoinedToUserAndScheduledArray.filter(
          ({ id }) => id !== selectedSourceTaskId
        )
      );
    } else if (isMerge && destinationTaskId) {
      setFilteredTasks(
        searchTaskJoinedToUserAndScheduledArray.filter(
          ({ id }) => id !== destinationTaskId
        )
      );
    } else {
      setFilteredTasks(searchTaskJoinedToUserAndScheduledArray);
    }
  }, [filter, searchTaskJoinedToUserAndScheduledArray]);

  return (
    <div className="SearchTaskCardList">
      <div className="SearchTaskCardList__Header">
        <span>
          {title}({filteredTasks.length})
        </span>
      </div>
      <div className="SearchTaskCardList__FilteredTasks">
        {filteredTasks.length > 0 ? (
          filteredTasks.map((task, index: number) => {
            return <SearchTaskCardItem key={index} task={task} />;
          })
        ) : (
          <span className="SearchTaskCardList__FilteredTasks__NoMatches">
            No matches
          </span>
        )}
      </div>
    </div>
  );
};

export { SearchTaskCardList as default };
