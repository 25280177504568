import React from "react";
import { Tooltip } from "antd";
import moment, { Moment } from "moment";
import cn from "classnames";

import CheckoutItem from "../../Checkout/CheckoutItem/CheckoutItem";
import {
  LoadingIcon,
  NoteIcon,
  RefreshIcon,
} from "../../CustomIcons/CustomIcons.component";
import TimeCard from "../../Checkout/WorkTimes/TimeCard/TimeCard";

import { dateFormat } from "../../../constants/constants";
import { getNextCalendarScheduleService } from "../../../services/third-party-calendar.service";

interface NextWorkDayWidgetProps {
  date: string;
}
const NextWorkDayWidget: React.FC<NextWorkDayWidgetProps> = ({ date }) => {
  const [dateMoment, setDateMoment] = React.useState<Moment>();
  const [loading, setLoading] = React.useState(false);
  const [
    nextCalendarSchedule,
    setNextCalendarSchedule,
  ] = React.useState<CalendarScheduleObject>();
  const [nextWorkDaySlots, setNextWorkDaySlots] = React.useState<TimeSlot[]>(
    []
  );
  const [refreshing, setRefreshing] = React.useState(false);
  const [updatedAt, setUpdatedAt] = React.useState<Moment>();
  const [loadFailed, setLoadFailed] = React.useState(false);

  React.useEffect(() => {
    setDateMoment(moment(date, dateFormat));
  }, [date]);

  React.useEffect(() => {
    const getNextCalendarSchedule = async () => {
      // temporarily removed to improve performance
      // TODO: remove comment when need to use component, can use global state nextCalendarSchedule instead of re-fetching
      // setLoading(true);
      // setLoadFailed(false);
      // try {
      //   const dateString = moment(date, dateFormat).format("YYYYMMDD");
      //   const data = {
      //     start_date: dateString,
      //   };
      //   const response = await getNextCalendarScheduleService(data);
      //   if (response.status === 200) {
      //     setNextCalendarSchedule(response.data);
      //     setUpdatedAt(moment());
      //   } else {
      //     setLoadFailed(true);
      //   }
      //   setLoading(false);
      // } catch (e) {
      //   setLoading(false);
      //   setLoadFailed(true);
      // }
    };
    getNextCalendarSchedule();
  }, [date]);

  React.useEffect(() => {
    if (nextCalendarSchedule) {
      setNextWorkDaySlots([
        {
          title: "Hours",
          durationInSeconds: nextCalendarSchedule.work_time_seconds,
          color: "#000",
          hasNote: true,
        },
        {
          title: "Shift",
          color: "#000",
          text: `${moment(nextCalendarSchedule.start_datetime).format(
            "HH:mm"
          )} - ${moment(nextCalendarSchedule.end_datetime).format("HH:mm")}`,
        },
        {
          title: "Note",
          color: "#000",
          text: nextCalendarSchedule.schedule_notes,
        },
      ]);
    }
  }, [nextCalendarSchedule]);

  const handleRefresh = async () => {
    // setRefreshing(true);
    // setLoadFailed(false);
    // try {
    //   const dateString = moment(date, dateFormat).format("YYYYMMDD");
    //   const data = {
    //     start_date: dateString,
    //   };
    //   const response = await getNextCalendarScheduleService(data);
    //   if (response.status === 200) {
    //     setNextCalendarSchedule(response.data);
    //     setUpdatedAt(moment());
    //   } else {
    //     setLoadFailed(true);
    //   }
    //   setRefreshing(false);
    // } catch (e) {
    //   setRefreshing(false);
    //   setLoadFailed(true);
    // }
  };

  const action = updatedAt && (
    <Tooltip
      title={`${
        loadFailed ? "Calendar import failed" : "Last import"
      } at ${updatedAt.format("YYYY-MM-DD HH:mm:ss")} - Click to Reload`}
      overlayInnerStyle={{
        fontSize: "12px",
        lineHeight: "17px",
      }}
    >
      <div className="WorkTimes__Action">
        {loadFailed ? (
          <NoteIcon
            onClick={handleRefresh}
            className="WorkTimes__Action__Warning"
          />
        ) : (
          <RefreshIcon
            onClick={handleRefresh}
            className={cn("WorkTimes__Action__Refresh", {
              "WorkTimes__Action__Refresh-loading": refreshing,
            })}
          />
        )}
      </div>
    </Tooltip>
  );

  return (
    <CheckoutItem
      className="WorkTimes__Card"
      title={`Next workday (${dateMoment?.format("ddd, MMMM D")})`}
      action={action}
    >
      {loading && <LoadingIcon />}
      {!loading && nextCalendarSchedule && (
        <TimeCard showTimeBar={false} timeSlots={nextWorkDaySlots} />
      )}
      {!loading && !nextCalendarSchedule && (
        <div className="WorkTimes__Card__Empty">
          <span>There are no next workdays scheduled.</span>
        </div>
      )}
    </CheckoutItem>
  );
};

export default NextWorkDayWidget;
