import React from "react";
import { Popover } from "antd";

import Button from "../../Button/Button";

/**
 * This component is used to pop a confirm widget when user clicks on the button.
 * A common use case is to make a confirmation when user try to delete a record.
 * Ususally this component is triggered by click event, but in some cases that it
 * is rendered by the change of state, we can set defaultVisible to true to open this popover.
 * Please refer to <AddSectionInput /> for more details
 */
const Confirm = ({
  children,
  content,
  okText = "Ok",
  cancelText = "Cancel",
  showCancel = true,
  defaultVisible = false,
  onOk,
  onCancel,
}: ConfirmProps) => {
  const [visible, setVisible] = React.useState(defaultVisible);
  const [loading, setLoading] = React.useState(false);

  const handleCancel = () => {
    setVisible(false);
    if (onCancel) {
      onCancel();
    }
  };

  const handleOk = async () => {
    setLoading(true);
    await onOk();
    setLoading(false);
    setVisible(false);
  };

  const popoverContent = (
    <div className="Confirm__Popover">
      <div className="Confirm__Content">{content}</div>
      <div className="Confirm__Btns">
        {showCancel && (
          <Button
            size="small"
            className="Confirm__Cancel"
            onClick={handleCancel}
          >
            {cancelText}
          </Button>
        )}
        <Button
          size="small"
          type="primary"
          className="Confirm__Ok"
          loading={loading}
          onClick={handleOk}
        >
          {okText}
        </Button>
      </div>
    </div>
  );
  return (
    <Popover
      overlayClassName="Confirm"
      trigger="click"
      placement="top"
      content={popoverContent}
      visible={visible}
      onVisibleChange={(visible: boolean) => setVisible(visible)}
    >
      {children}
    </Popover>
  );
};

export default Confirm;
