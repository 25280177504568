import { Popover } from "antd";
import React from "react";

import { getInitials } from "../../../utils/string.util";
import CustomAvatar from "../../CustomAvatar/CustomAvatar";
import { LoadingIcon } from "../../CustomIcons/CustomIcons.component";
import TeamPopover from "../TeamPopover/TeamPopover";

export interface TeammateProps {
  teammate?: Teammate;
  loading?: boolean;
  isCurrentUser?: boolean;
  onSetting?: () => void;
  onPin?: (teammate: Teammate) => void;
}
const Teammate: React.FC<TeammateProps> = ({
  teammate,
  isCurrentUser = false,
  loading = false,
  onSetting,
  onPin,
}) => {
  const size = isCurrentUser ? 40 : 36;
  const handlePin = () => {
    if (onPin && teammate) {
      onPin(teammate);
    }
  };
  return loading || !teammate ? (
    <div className="Teammate">
      <CustomAvatar size={size} icon={<LoadingIcon />} />
    </div>
  ) : (
    <Popover
      overlayClassName="Teammate__Popover"
      placement="leftTop"
      content={
        <TeamPopover
          teammate={teammate!}
          isCurrentUser={isCurrentUser}
          onSetting={onSetting}
          onPin={handlePin}
        />
      }
      getPopupContainer={(trigger: any) => trigger}
    >
      <div className="Teammate">
        <CustomAvatar
          size={size}
          src={teammate!.avatar}
          fallbackText={getInitials(teammate.display_name)}
          work_status={teammate!.status}
          hasStatusCircle={isCurrentUser}
        />
      </div>
    </Popover>
  );
};

export { Teammate as default };
