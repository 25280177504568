import React from "react";
import cn from "classnames";

const CheckoutItem: React.FC<CheckoutItemProps> = ({
  title,
  children,
  icon,
  action,
  className,
  contentClassName,
}) => {
  return (
    <div className={cn("CheckoutItem", className)}>
      <div className="CheckoutItem__Header">
        <div className="CheckoutItem__Header__Main">
          <div className="CheckoutItem__Title">{title}</div>
          {icon}
        </div>
        {action}
      </div>
      <div className={cn("CheckoutItem__Content", contentClassName)}>
        {children}
      </div>
    </div>
  );
};

export default CheckoutItem;
