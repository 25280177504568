import { useFeature } from "flagged";
import React from "react";
import { useNavigate } from "react-router-dom";

export interface UseRedirectArgs {
  path: string;
  searchParams?: string;
}
export default function useRedirect() {
  const navigate = useNavigate();
  const isBeta = useFeature("under_development");

  return React.useCallback(
    ({ path, searchParams }: UseRedirectArgs) => {
      const url = `${path}${isBeta || searchParams ? "?" : ""}${
        isBeta ? "beta=on" : ""
      }${searchParams ? "&" + searchParams : ""}`;
      navigate(url);
    },
    [navigate, isBeta]
  );
}
