import moment from "moment";

export const dateTimeToCalendar = (
  dateTime: number | Date | string | moment.Moment
) => {
  return moment(dateTime).calendar(null, {
    sameDay: "[Today] h:mm A",
    nextDay: "[Tomorrow] h:mm A",
    nextWeek: "dddd",
    lastDay: "[Yesterday] h:mm A",
    lastWeek: "[Last] dddd",
    sameElse: "MMMM D YYYY",
  });
};

export const dateTimeToWeekMonth = (
  dateTime: number | Date | string | moment.Moment
) => {
  return moment(dateTime).calendar({
    sameDay: "[Today]",
    nextDay: "[Tomorrow]",
    nextWeek: "dddd",
    lastDay: "[Yesterday]",
    lastWeek: "[Last] dddd",
    sameElse: "dddd, MMMM D",
  });
};
