import axios from "../apis/django.instance";

export const getCalendarScheduleService: GetCalendarScheduleService = (
  data
) => {
  return axios.post("/api/work/calendar_schedule/", data);
};

export const getNextCalendarScheduleService: GetCalendarScheduleService = (
  data
) => {
  if (data) {
    const { start_date } = data;
    const params = {
      start_date,
    };
    return axios.get("/api/work/next_calendar_schedule/", { params });
  } else {
    return axios.get("/api/work/next_calendar_schedule/");
  }
};
