import React, { useState } from "react";
import { useDispatch } from "react-redux";
import cn from "classnames";

import { NoteIcon, CloseIcon } from "../../CustomIcons/CustomIcons.component";
import useDeleteAnimation from "../../../hooks/useDeleteAnimation.hook";
import { startUpdateProject } from "../../../actions/projects.action";

const GetStartedMessage: React.FC<GetStartedMessageProps> = ({ project }) => {
  const dispatch = useDispatch();

  // here we use this hook to render the deleting animation
  const { opacity, collapse, ref, handleDelete } = useDeleteAnimation({
    onDelete: () => {
      const payload = {
        id: project.id,
        show_get_started_message_yn: false,
      } as ProjectObject;
      dispatch(startUpdateProject(payload));
    },
    dependencies: [project.id],
  });

  return (
    <div
      ref={ref}
      className={cn("GetStartedMessage", {
        "GetStartedMessage-opacity": opacity,
        "GetStartedMessage-collapse": collapse,
      })}
    >
      <NoteIcon className="GetStartedMessage-note" />
      <div className="GetStartedMessage-info">
        <div className="GetStartedMessage-title">Get Started</div>
        <div className="GetStartedMessage-content">
          Everything is ready to start working on a new project. Tasks are
          organized in one or multiple task lists. Only selected members have
          access to particular task lists. You can manage task lists and members
          on this page. For any questions please visit the{" "}
          <a
            href="/help"
            style={{ pointerEvents: "none", cursor: "not-allowed" }}
          >
            Help Center
          </a>
          .
        </div>
      </div>
      <CloseIcon className="GetStartedMessage-close" onClick={handleDelete} />
    </div>
  );
};

export default GetStartedMessage;
