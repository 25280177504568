const projectsColors: ProjectColorArray = [
  { id: "IMUE28DG8Y", value: "#FDF0F2", title: "#FDF0F2" },
  { id: "QWD2N2HMNA", value: "#9E9CC2", title: "#9E9CC2" },
  { id: "TG8DAHPYO8", value: "#A17724", title: "#A17724" },
  { id: "2TNV9FTF24", value: "#383745", title: "#383745" },
  { id: "DFED7SU0CL", value: "#FAF2EA", title: "#FAF2EA" },
  { id: "9Z1PB78VCM", value: "#9C9EB5", title: "#9C9EB5" },
  { id: "TYNG3K1VDP", value: "#8BF747", title: "#8BF747" },
  { id: "FRM2D3DAXW", value: "#2A38FB", title: "#2A38FB" },
  { id: "X8KB1MGJPF", value: "#F9F4EC", title: "#F9F4EC" },
  { id: "J4RVPPL2IF", value: "#63ACBE", title: "#63ACBE" },
  { id: "KHQH9AT7KG", value: "#EE442F", title: "#EE442F" },
  { id: "AFS8Z51Y8D", value: "#601A4A", title: "#601A4A" },
  { id: "5QBP2RYG59", value: "#342A1F", title: "#342A1F" },
  { id: "LTS559EPK8", value: "#CAB8CB", title: "#CAB8CB" },
  { id: "H2VMFGNLUH", value: "#2E2B21", title: "#2E2B21" },
  { id: "5SZC4YKMP8", value: "#C9BD9E", title: "#C9BD9E" },
  { id: "QL18MIZTO6", value: "#E2DAD1", title: "#E2DAD1" },
  { id: "P5U6ET6W7P", value: "#BEBCC5", title: "#BEBCC5" },
  { id: "XNKIRCQKQM", value: "#382119", title: "#382119" },
  { id: "6JM3UKPYH9", value: "#CCBE9F", title: "#CCBE9F" },
  { id: "P6LBGK0FXN", value: "#E0DCD3", title: "#E0DCD3" },
  { id: "PYHF0BG25B", value: "#ABC3C9", title: "#ABC3C9" },
  { id: "M7C0D56NSH", value: "#5D6E9E", title: "#5D6E9E" },
  { id: "CMMDJ620DN", value: "#DECBE3", title: "#DECBE3" },
  { id: "ADVXG7CT3V", value: "#FADFE2", title: "#FADFE2" },
  { id: "TLFUJUHFQP", value: "#CDB1AD", title: "#CDB1AD" },
  { id: "R6TTBUL0U5", value: "#636D97", title: "#636D97" },
  { id: "ERG0YQYIMW", value: "#D1D0DE", title: "#D1D0DE" },
  { id: "J31M6DM2I4", value: "#EDE6DE", title: "#EDE6DE" },
  { id: "D11F4EIVV0", value: "#BDB6AB", title: "#BDB6AB" },
  { id: "UNV17EK05P", value: "#44749D", title: "#44749D" },
  { id: "IT9G36VBBJ", value: "#C6D4E1", title: "#C6D4E1" },
  { id: "ITQ6J6IOVY", value: "#EBE7E0", title: "#EBE7E0" },
  { id: "5VYN7W1DEJ", value: "#BDB8AD", title: "#BDB8AD" },
  { id: "KUBBZUFL85", value: "#092C48", title: "#092C48" },
  { id: "NIYFI2JFMH", value: "#A3B7F9", title: "#A3B7F9" },
  { id: "I3F1RYOF5A", value: "#67F498", title: "#67F498" },
  { id: "EBDV2V3SRU", value: "#C59434", title: "#C59434" },
  { id: "QPE0G826DP", value: "#052955", title: "#052955" },
  { id: "FM5FZUCFTH", value: "#A7B8F", title: "#A7B8F" },
  { id: "IKCDORHL2P", value: "#6073B1", title: "#6073B1" },
  { id: "8BW4P7NEA3", value: "#AE9C45", title: "#AE9C45" },
  { id: "RIHRGO95S3", value: "#0F2080", title: "#0F2080" },
  { id: "VE4P9R2D9I", value: "#85C0F9", title: "#85C0F9" },
  { id: "9H54XJ4KT4", value: "#A95AA1", title: "#A95AA1" },
  { id: "RWNVU5CC44", value: "#F5793A", title: "#F5793A" },
  { id: "NJ7HMVZ651", value: "#3832a9", title: "#3832a9" },
  { id: "3RJXA84V54", value: "#0f2050", title: "#0f2050" },
];

export { projectsColors as default };
