import React from "react";
import { useSelector } from "react-redux";

import { selectSectionsState } from "../reducers/section.reducer";

export default function useSectionsUnderList(list: ListObject) {
  const { id: listId } = list || {};
  const { data: sections } = useSelector(selectSectionsState);

  const [
    sectionsUnderList,
    setSectionsUnderList,
  ] = React.useState<SectionArray>([]);

  React.useEffect(() => {
    if (listId) {
      setSectionsUnderList(
        sections.filter(({ parent_list }) => listId === parent_list)
      );
    } else {
      setSectionsUnderList([]);
    }
  }, [listId, sections]);

  return sectionsUnderList;
}
