import React from "react";
import moment from "moment";
import cn from "classnames";

import useWorkDateLastShiftEndDateTime from "../../../hooks/useWorkDateLastShiftEndDateTime.hook";

import ActivityCardItem from "../../Activities/ActivityCardItem/ActivityCardItem";
import ListUsersTaskTimeInWorkDayAndTask from "../../WorkTime/ListUsersTaskTimeInWorkDayAndTask/ListUsersTaskTimeInWorkDayAndTask";

import { workDayDateFormat } from "../../../constants/constants";
import { dateKeyFormat } from "../../../utils/activities.util";

interface UserTaskActivityItemGroupedByDateProps {
  dateActivities: ActivityArray;
  dateKey: string;
  displayActivityAvatar?: boolean;
  displayActivityHeader?: boolean;
  displayWorkDayLastShiftEndDateTime?: boolean;
  displayWorkDayTotalTaskTime?: boolean;
  taskId: TaskObject["id"];
  type: ActivitiesSummaryType;
  users: UserArray;
}
const UserTaskActivityItemGroupedByDate: React.FC<UserTaskActivityItemGroupedByDateProps> = ({
  dateActivities,
  dateKey,
  displayActivityAvatar = true,
  displayActivityHeader = true,
  displayWorkDayLastShiftEndDateTime = false,
  displayWorkDayTotalTaskTime = true,
  taskId,
  type,
  users,
}) => {
  const [workDate, setWorkDate] = React.useState<WorkDayObject["work_date"]>();
  const lastShiftEndDateTime = useWorkDateLastShiftEndDateTime(workDate);

  React.useEffect(() => {
    switch (dateKey) {
      case "Today":
        setWorkDate(moment().startOf("day").format(workDayDateFormat));
        break;
      case "Yesterday":
        setWorkDate(
          moment().startOf("day").subtract(1, "day").format(workDayDateFormat)
        );
        break;
      default:
        setWorkDate(moment(dateKey, dateKeyFormat).format(workDayDateFormat));
    }
  }, [dateKey]);

  return (
    <div
      className={cn(
        "UserTaskActivityItemGroupedByDate",
        `UserTaskActivityItemGroupedByDate--${type}`
      )}
    >
      <div className="UserTaskActivityItemGroupedByDate__Header">
        <div className="UserTaskActivityItemGroupedByDate__Header__Title">
          <span>{dateKey}</span>
        </div>
      </div>
      <div className="UserTaskActivityItemGroupedByDate__Body">
        {displayWorkDayTotalTaskTime && users.length > 0 && workDate && (
          <ListUsersTaskTimeInWorkDayAndTask
            taskId={taskId}
            type={type}
            userArray={users}
            workDate={workDate}
          />
        )}
        {displayWorkDayLastShiftEndDateTime && lastShiftEndDateTime && (
          <div className="UserTaskActivityItemGroupedByDate__Body__LastShiftEndDateTime">
            {moment(lastShiftEndDateTime).format("HH:mm A")}
          </div>
        )}
        {dateActivities.map((activity, index) => (
          <ActivityCardItem
            key={index}
            activityObject={activity}
            displayAvatar={displayActivityAvatar}
            displayHeader={displayActivityHeader}
          />
        ))}
      </div>
    </div>
  );
};

export default UserTaskActivityItemGroupedByDate;
