import { RootState } from "../store/configureStore";

export interface WorkspaceUsersState extends BaseReducerState {
  data: WorkspaceUser[];
}
export const workspaceUsersReducerDefaultState: WorkspaceUsersState = {
  data: [],
  error: "",
  loading: false,
};

export const selectWorkspaceUsersState = (rootState: RootState) =>
  rootState.workspaceUsers;
const workspaceUsersReducer = (
  state = workspaceUsersReducerDefaultState,
  action: { type: string; payload: WorkspaceUser[]; error: string }
) => {
  switch (action.type) {
    case "REQUEST_WORKSPACE_USERS":
      return {
        ...state,
        error: "",
        loading: true,
      };
    case "REQUEST_WORKSPACE_USERS_SUCCESS":
      return {
        data: action.payload,
        error: "",
        loading: false,
      };
    case "REQUEST_WORKSPACE_USERS_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export { workspaceUsersReducer as default };
