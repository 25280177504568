import React from "react";
import moment from "moment";
import { union } from "lodash";

import useVisibleOnScroll from "../../../hooks/useVisibleOnScroll.hook";

import { NotificationsContext } from "../Notifications/Notifications";

import {
  BulletPointIcon,
  NotificationBellIcon,
} from "../../CustomIcons/CustomIcons.component";
import MarkNotificationAsRead from "../MarkNotificationAsRead/MarkNotificationAsRead";

import { workDayDateFormat } from "../../../constants/constants";
import { isToday } from "../../../utils/dates.util";

export interface NotificationsItemProps {
  notification: NotificationObject;
}
const NotificationsItem: React.FC<NotificationsItemProps> = ({
  notification,
}) => {
  const { id: notificationId, datetime, list, project, unread } = notification;

  const context = React.useContext(NotificationsContext);
  const { setReadNotifications } = context;

  const [hovered, setHovered] = React.useState(false);

  const notifcationItemRef = React.useRef(null);

  const isVisibleOnScroll = useVisibleOnScroll(
    notifcationItemRef,
    document.getElementsByClassName("NotificationsPopoverContent__Body")[0]
  );

  React.useEffect(() => {
    if (isVisibleOnScroll && unread)
      setReadNotifications((c) => union([...c], [notificationId]));
  }, [isVisibleOnScroll, notificationId, unread]);

  return (
    <div
      className="NotificationsItem"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      ref={notifcationItemRef}
    >
      <div className="NotificationsItem__Display">
        {unread && (
          <BulletPointIcon className="NotificationsItem__Display__UnreadFlag" />
        )}
        <NotificationBellIcon className="NotificationsItem__Display__Icon" />
      </div>
      <div className="NotificationsItem__Content">
        <div className="NotificationsItem__Content__Text">
          {!list && project && (
            <span>
              You have been added to the&nbsp;
              <span className="NotificationsItem__Content__Text__Highlight">
                {project}
              </span>
              &nbsp;project.
            </span>
          )}
          {list && project && (
            <span>
              You have been added to the&nbsp;
              <span className="NotificationsItem__Content__Text__Highlight">
                {list}
              </span>
              &nbsp; task list of the&nbsp;
              <span className="NotificationsItem__Content__Text__Highlight">
                {project}
              </span>
              &nbsp; project.
            </span>
          )}
        </div>
        <div className="NotificationsItem__Content__Timestamp">
          {isToday(moment(datetime))
            ? moment(datetime).fromNow()
            : moment(datetime).format(workDayDateFormat)}
        </div>
      </div>
      {hovered && unread && <MarkNotificationAsRead id={notificationId} />}
    </div>
  );
};

export default NotificationsItem;
