import React from "react";
import { Avatar } from "antd";
import cn from "classnames";

/**
 * Default Display
 * Extends and displays Ant Design avatar component
 * https://ant.design/components/avatar/
 *
 * Optional Display
 * Displays work status of user in avatar, determined by workStatus prop
 *
 * Functionality
 * Takes similar props as Ant Design Avatar
 */
const CustomAvatar: React.FC<CustomAvatarProps> = (props) => {
  const {
    fallbackText,
    size = 16,
    work_status,
    className,
    hasStatusCircle = false,
  } = props;

  return (
    <div
      className={cn("CustomAvatar", className, {
        CustomAvatar__StatusCircle: hasStatusCircle,
        [`CustomAvatar__StatusCircle--${work_status}`]: hasStatusCircle,
      })}
    >
      {fallbackText ? (
        <Avatar {...props} className="CustomAvatar__FallbackText">
          {fallbackText}
        </Avatar>
      ) : (
        <Avatar {...props} size={size}></Avatar>
      )}
      {!!work_status && (
        <div
          className={cn(
            "CustomAvatar__WorkStatus",
            `CustomAvatar__WorkStatus--${work_status}`
          )}
        ></div>
      )}
    </div>
  );
};

export { CustomAvatar as default };
