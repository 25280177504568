import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Avatar, Button } from "antd";
import cn from "classnames";
import { Feature } from "flagged";

import useRedirect from "../../hooks/useRedirect.hook";

import { CaretDownIcon } from "../CustomIcons/CustomIcons.component";
import ProjectModal from "../ProjectModal/ProjectModal.component";
import SignOutModal from "../SignOutModal/SignOutModal";
import CheckoutModal from "../Checkout/CheckoutModal/CheckoutModal";

import { startLogout } from "../../actions/auth.action";
import useOutsideClickCallback from "../../hooks/useOutsideClickCallback.hook";

/**
 * Workspace component is a modal than can be used to create project
 * It can be toggled when user clicks on the workspace header
 */
const Workspace = ({
  open = false,
  avatar,
  name,
  onToggle,
  onClose,
}: WorkspaceProps) => {
  const redirect = useRedirect();

  const dispatch = useDispatch();
  const [createProjectOpen, setCreateProjectOpen] = useState(false);
  const [signOutOpen, setSignOutOpen] = useState(false);
  const [checkoutOpen, setCheckoutOpen] = useState(false);

  const workspaceRef = React.useRef(null);

  // close the workspace modal when user click outside of it
  useOutsideClickCallback(
    () => {
      if (open) {
        onClose();
      }
    },
    [open],
    workspaceRef
  );

  const handleProjectModalOk = () => {
    setCreateProjectOpen(false);
  };
  const handleLeaveTheOffice = () => {
    setSignOutOpen(false);
    setCheckoutOpen(true);
  };
  const handleSignOut = () => {
    setSignOutOpen(false);
    dispatch(startLogout());
    onClose();
    // TODO: end the shifts
  };
  const handleOpenCreateProjectModal = () => {
    setCreateProjectOpen(true);
    onClose();
  };
  const handleOpenSignOutModal = () => {
    setSignOutOpen(true);
    onClose();
  };
  return (
    <div className={cn("Workspace", { open })} ref={workspaceRef}>
      <section className="Workspace__Content">
        <div className="Workspace__Header" onClick={onToggle}>
          <Avatar
            style={{ verticalAlign: "middle" }}
            size="large"
            gap={4}
            className="Workspace__Header__Avatar"
          >
            {avatar}
          </Avatar>
          <span className="Workspace__Header__Text">
            {name}
            <CaretDownIcon />
          </span>
        </div>
        <div className="Workspace__Body">
          <div className="Workspace__List">
            <Feature name="under_development">
              <div
                className="Workspace__List-item"
                onClick={() =>
                  redirect({
                    path: "/projects",
                  })
                }
              >
                Projects
              </div>
              <div
                className="Workspace__List-item"
                onClick={() =>
                  redirect({
                    path: "/members",
                  })
                }
              >
                Members
              </div>
            </Feature>
            <div
              className="Workspace__List-item"
              onClick={handleOpenCreateProjectModal}
            >
              Create a Project
            </div>
            {/* TODO: not implemented yet
             <div className="Workspace__List-item disable">Invite Members</div>
             <div className="Workspace__List-item disable">
              Customize workspace
             </div>
            */}
          </div>
          <Button onClick={handleOpenSignOutModal}>Sign out of {name}</Button>
        </div>
      </section>
      <aside className="Workspace__Sidebar"></aside>
      <ProjectModal
        open={createProjectOpen}
        onOk={handleProjectModalOk}
        onClose={handleProjectModalOk}
      />
      <SignOutModal
        open={signOutOpen}
        onClose={() => setSignOutOpen(false)}
        onSignOut={handleSignOut}
        onLeaveTheOffice={handleLeaveTheOffice}
      />
      <CheckoutModal
        displayModal={checkoutOpen}
        mode="leave_the_office"
        setDisplayModal={setCheckoutOpen}
      />
    </div>
  );
};

export default Workspace;
