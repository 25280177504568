import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Collapse } from "antd";
import cn from "classnames";
import { useFeature } from "flagged";

import PageEmptyState from "../General/PageEmptyState/PageEmptyState";
import ProjectsCollapse from "./ProjectCollapse/ProjectCollapse.component";
import UnreadActivitiesHeader from "./UnreadActivitiesHeader/UnreadActivitiesHeader.component";
import { LoadingIcon } from "../CustomIcons/CustomIcons.component";

import {
  startPopulateUnreadProjects,
  startPopulateUnreadActivities,
} from "../../actions/unread.action";
import { selectUnreadState } from "../../reducers/unread.reducer";

import ws from "../../sockets/websockets";
import PageUnderConstruction from "../General/PageUnderConstruction/PageUnderConstruction";

const { Panel } = Collapse;
const UNREAD_PANEL_KEY = "unread-activities";

/**
 * Top level component for unread activities page.
 * UnreadActivities component contains multiple projects, each project contains multiple tasks,
 * Each task is group by list and section.
 */
const UnreadActivities = () => {
  const isBeta = useFeature("under_development");

  const dispatch = useDispatch();
  const { data: unreadProjects, loading } = useSelector(
    selectUnreadState
  ).projects;

  /*
  const taskIds: string[] = unreadProjects.reduce((acc: string[], cur: UnreadProject) => {
    const ids = getTaskIds(cur);
    return [...acc, ...ids];
  }, []);
  */

  // collapse has 3 state: collapse | expand | normal
  // it will be collapse state if user click collapse-all icon
  // it will be expand state if user click expand-all icon
  // and it will be normal state if user toggle project / task
  const [collapse, setCollapse] = useState<CollapseState>("collapse");
  const [filters, setFilters] = useState<string[]>([]);

  useEffect(() => {
    dispatch(startPopulateUnreadProjects());
  }, [dispatch]);

  useEffect(() => {
    const wsOnMessage = async (event: MessageEvent) => {
      try {
        const messageEventData = JSON.parse(event.data);
        const { event: eventType, meta } = messageEventData;

        // note: we don't need to listen to the marsk as read event here, since:
        // a) mark as read event from other user will not affect the current user's unread activities,
        // b) the event from current user has already been processed by startMarkAdRead action.
        switch (eventType.trim()) {
          case "new_task":
          case "new_join_task_to_user":
          case "new_scheduled_task":
            dispatch(startPopulateUnreadProjects());
            break;
          case "new_activity":
            if (meta.task_id) {
              // we need to update unread projects to reflect new info like activity count
              dispatch(startPopulateUnreadProjects());

              // the activity list for that task should also be updated.
              dispatch(startPopulateUnreadActivities(meta.task_id));
            }
            break;
          default:
        }
      } catch (e) {}
    };
    ws.addEventListener("message", wsOnMessage);

    return () => {
      ws.removeEventListener("message", wsOnMessage);
    };
  }, [dispatch]);

  // Here we map all projects into filter format.
  const options = unreadProjects.map((project: UnreadProject) => ({
    value: project.id,
    label: project.project_name,
    children: project.lists.map((list) => ({
      value: list.id,
      label: list.list_name,
      children: list.sections.map((section) => ({
        value: section.id,
        label: section.section_name,
        children: section.tasks.map((task) => ({
          value: task.id,
          label: task.task_name,
        })),
      })),
    })),
  }));

  // filter projects by selected filter.
  const projects = unreadProjects
    .filter((item: UnreadProject) => {
      if (filters[0]) {
        return item.id === filters[0];
      }
      return true;
    })
    .map((item: UnreadProject) => (
      <ProjectsCollapse
        key={item.id}
        unreadProject={item}
        collapse={collapse}
        filters={filters.slice(1)}
        onToggle={(collapseState) => setCollapse(collapseState)}
      />
    ));

  return (
    <section
      className={cn("UnreadActivities", {
        "UnreadActivities--Empty": loading || projects.length === 0,
      })}
    >
      {isBeta ? (
        <Collapse collapsible="disabled" activeKey={UNREAD_PANEL_KEY}>
          <Panel
            className="UnreadActivities__panel"
            header={
              <UnreadActivitiesHeader
                options={options}
                collapse={collapse}
                onOpen={() => setCollapse("expand")}
                onClose={() => setCollapse("collapse")}
                onFilter={(filters) => setFilters(filters)}
              />
            }
            key={UNREAD_PANEL_KEY}
            showArrow={false}
          >
            {loading || projects.length === 0 ? (
              <PageEmptyState
                title="No unread activities"
                content={
                  <span>
                    Here you can find all unread activities from other team
                    members.
                  </span>
                }
                loading={loading}
              />
            ) : (
              projects
            )}
          </Panel>
        </Collapse>
      ) : (
        <PageUnderConstruction />
      )}
    </section>
  );
};

export default UnreadActivities;
