import { AxiosResponse } from "axios";

import axios from "../apis/django.instance";

export interface GetWorkspaceCheckoutCheckStatusServiceData {
  user_id?: UserDetailsObject["id"];
  work_date: string;
}
export type GetWorkspaceCheckoutCheckStatusService = (
  data?: GetWorkspaceCheckoutCheckStatusServiceData
) => Promise<AxiosResponse<CheckoutStatusArray>>;
export const getWorkspaceCheckoutChecksStatusService: GetWorkspaceCheckoutCheckStatusService = (
  data
) => {
  const { user_id, work_date } = data || {};
  const params = {
    work_date,
  };
  return axios.get(`/api/work/run_workspace_checkout_checks/${user_id}/`, {
    params,
  });
};

export const getPreviousDaysUnconfirmedCheckoutCountService = () => {
  return axios.get("/api/work/unconfirmed_checkout_count/");
};

export const getFailedCheckoutCount = () => {};

export const getCheckoutChecksService = () => {
  return axios.get("/api/work/checkout_check/");
};

export const getCheckoutCheckStatusService = () => {
  return axios.get(
    "/api/work/checkout_check_status/?message_dismissed_yn=False&passed_yn=False"
  );
};

export const dismissCheckoutCheckStatusService = (id: string) => {
  return axios.patch(`/api/work/checkout_check_status/${id}/`, {
    message_dismissed_yn: true,
  });
};

export interface MarkCheckoutStatusAsReadServiceData {
  workday_ids: WorkDayObject["id"][];
}
export type MarkCheckoutStatusAsReadService = (
  data: MarkCheckoutStatusAsReadServiceData
) => Promise<AxiosResponse<any>>;
export const markCheckoutStatusAsReadService = (
  data: MarkCheckoutStatusAsReadServiceData
) => {
  return axios.post(
    `/api/work/checkout_check_status_log/mark_checkoutstatus_read_by_ids/`,
    data
  );
};
