import moment from "moment";

import { dateFormat } from "../constants/constants";

export const groupWorkShiftByDate = (workShiftArray: WorkShiftArray) => {
  if (workShiftArray.length === 0) return {};
  const groups: Record<string, WorkShiftArray> = {};

  const addToGroup = (dateKey: string, workShiftObject: WorkShiftObject) => {
    if (groups[dateKey] === undefined) {
      groups[dateKey] = [];
    }

    groups[dateKey].push(workShiftObject);
  };

  const createDateKey = (date: string) =>
    moment(date).startOf("day").format(dateFormat);

  workShiftArray.forEach((workShiftObject) => {
    const dateCreatedKey = createDateKey(workShiftObject.start_datetime);

    addToGroup(dateCreatedKey, workShiftObject);
  });
  const ordered: Record<string, WorkShiftArray> = Object.keys(groups)
    .sort((a, b) => {
      const keyA = a.replace(/\D/g, ""),
        keyB = b.replace(/\D/g, "");
      if (moment(keyA).isBefore(keyB)) return -1;
      if (moment(keyA).isAfter(keyB)) return 1;
      return 0;
    })
    .reduce((obj: any, key: any) => {
      obj[key] = groups[key];
      return obj;
    }, {});

  return ordered;
};
