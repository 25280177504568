import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "antd";

import usePreviousHook from "../../../hooks/usePrevious.hook";

import CustomButton from "../../CustomButton/CustomButton";
import { LoadingIcon } from "../../CustomIcons/CustomIcons.component";
import { DetailsTabContentContext } from "../TaskDetails/DetailsTabContent/DetailsTabContent";

import { startPartialUpdateTask } from "../../../actions/tasks.action";
import { selectTasksState } from "../../../reducers/tasks.reducer";

const { TextArea } = Input;

const TaskDescriptionInput: React.FC<{ task: TaskObject }> = ({ task }) => {
  const { setEditing } = React.useContext(DetailsTabContentContext);

  const {
    id: taskId,
    description: taskDescription,
    loading: taskLoading,
  } = task;

  const dispatch = useDispatch();

  const inputRef = React.useRef<any>(null);

  const { updatedKeys } = useSelector(selectTasksState);

  const [inputValue, setInputValue] = React.useState<
    TaskObject["description"]
  >();

  const prevTaskLoading = usePreviousHook(taskLoading);

  const isUpdating = taskLoading && updatedKeys?.includes("description");

  const changesMade = inputValue !== taskDescription;

  const handleChangeInput = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(event.target.value);
  };
  const handleClickCancel = (e: React.MouseEvent<HTMLElement>) => {
    setInputValue(taskDescription);
    setEditing(false);
  };
  const handleClickConfirm = async (e: React.MouseEvent<HTMLElement>) => {
    if (changesMade) {
      const payload = {
        id: taskId,
        description: !inputValue ? "''" : inputValue,
      };
      dispatch(startPartialUpdateTask(payload));
    }
  };

  React.useEffect(() => {
    if (prevTaskLoading && !taskLoading) {
      setEditing(false);
    }
  }, [prevTaskLoading, taskLoading, setEditing]);

  React.useEffect(() => {
    setInputValue(taskDescription);
  }, [taskDescription]);

  React.useEffect(() => {
    if (inputRef.current && !!inputValue)
      inputRef.current.focus({ option: { cursor: "end" } });
  }, [inputValue, inputRef]);

  return (
    <div className="TaskDescriptionInput">
      <TextArea
        className="TaskDescriptionInput__TextArea"
        ref={inputRef}
        bordered={false}
        value={inputValue === "" || inputValue === "''" ? "" : inputValue}
        autoSize={{ minRows: 2, maxRows: 6 }}
        onChange={handleChangeInput}
        disabled={isUpdating}
      />
      <div className="TaskDescriptionInput__Actions">
        <CustomButton
          className="TaskDescriptionInput__Actions__CancelBtn"
          onClick={handleClickCancel}
        >
          Cancel
        </CustomButton>
        <CustomButton
          className="TaskDescriptionInput__Actions__ConfirmBtn"
          type="success"
          onClick={handleClickConfirm}
          disabled={!changesMade}
        >
          {isUpdating ? <LoadingIcon /> : "Confirm"}
        </CustomButton>
      </div>
    </div>
  );
};

export default TaskDescriptionInput;
