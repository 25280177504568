import React from "react";

import ItemUsersTaskTimeInWorkDayAndTask from "../ItemUsersTaskTimeInWorkDayAndTask/ItemUsersTaskTimeInWorkDayAndTask";

const ListUsersTaskTimeInWorkDayAndTask: React.FC<ListUsersTaskTimeInWorkDayAndTaskProps> = ({
  taskId,
  type,
  userArray,
  workDate,
}) => {
  return (
    <div className="ListUsersTaskTimeInWorkDayAndTask">
      {userArray.map((userObject) => (
        <ItemUsersTaskTimeInWorkDayAndTask
          taskId={taskId}
          type={type}
          userObject={userObject}
          workDate={workDate}
        />
      ))}
    </div>
  );
};

export default ListUsersTaskTimeInWorkDayAndTask;
