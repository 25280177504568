import React from "react";
import { getObserverUsersService } from "../services/observable_users.service";

export default function useAsyncObserverUsers() {
  const [observers, setObservers] = React.useState<ObserverObject[]>([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const fetchObservers = async () => {
      try {
        setLoading(true);
        const response = await getObserverUsersService();
        if (response.status === 200) {
          setLoading(false);
          setObservers(
            response.data.map(
              ({
                observer_user: {
                  id,
                  avatar,
                  full_name: fullName = "",
                  display_name: displayName = "",
                },
              }: any) => {
                return {
                  id,
                  avatar,
                  fullName,
                  displayName,
                };
              }
            )
          );
        } else {
          throw new Error();
        }
      } catch (e) {
        setLoading(false);
      }
    };

    fetchObservers();
  }, []);

  return [observers, loading] as [ObserverObject[], boolean];
}
