import { Dispatch } from "redux";
import { getPrioritiesListService } from "../services/priorities.service";
import { dispatchError } from "../utils/error.util";

export const requestFetchPriorities = () => ({
  type: "REQUEST_FETCH_PRIORITIES",
});
export const requestFetchPrioritiesSuccess = (priorities: PriorityArray) => ({
  type: "REQUEST_FETCH_PRIORITIES_SUCCESS",
  priorities,
});
export const requestFetchPrioritiesFailed = (error: string) => ({
  type: "REQUEST_FETCH_PRIORITIES_FAILED",
  error,
});
export const startPopulatePriorities = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(requestFetchPriorities());
      const response = await getPrioritiesListService();
      if (response.status === 200) {
        dispatch(requestFetchPrioritiesSuccess(response.data));
      } else {
        const error = "getPrioritiesListService status is not 200";
        dispatch(requestFetchPrioritiesFailed(error));
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestFetchPrioritiesFailed(error));
      dispatchError({
        e,
        title: "Populate priorities error",
      });
    }
  };
};
