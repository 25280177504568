import { store } from "../store/configureStore";

import { StatusTypeState } from "../reducers/status-types.reducer";

const getStatusFromListStatusTypesByIdentifier: (
  statusEnumIdentifier: string
) => StatusTypeObject = (statusEnumIdentifier) => {
  const statusTypes: StatusTypeState = store.getState().statusTypes;
  const filteredStatusType: StatusTypeObject = statusTypes.data.filter(
    (statusType) => {
      return statusType.id === statusEnumIdentifier;
    }
  )[0];
  return filteredStatusType;
};

export { getStatusFromListStatusTypesByIdentifier };
