import { Dispatch } from "redux";
import { getWorktimeSummary } from "../services/work-shift.service";
import { dispatchError } from "../utils/error.util";

export const requestWorkTimes = () => ({
  type: "REQUEST_WORK_TIMES",
});
export const requestWorkTimesSuccess = (workTimes: WorkTimes) => ({
  type: "REQUEST_WORK_TIMES_SUCCESS",
  payload: workTimes,
});
export const requestWorkTimesFailed = (error: string) => ({
  type: "REQUEST_WORK_TIMES_FAILED",
  error,
});
export const startPopulateWorkTimes = (date: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(requestWorkTimes());
      const response = await getWorktimeSummary(date);
      if (response.status === 200) {
        dispatch(
          requestWorkTimesSuccess({
            ...response.data,
            date,
          })
        );
      } else {
        const error = "getWorktimeSummaryService status is not 200";
        dispatch(requestWorkTimesFailed(error));
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestWorkTimesFailed(error));
      dispatchError({
        e,
        title: "Populate work times error",
      });
    }
  };
};

export const updateCurrentWorkTime = (duration: number) => ({
  type: "UPDATE_CURRENT_WORK_TIME",
  duration,
});

export const updateCurrentBreakTime = (duration: number) => ({
  type: "UPDATE_CURRENT_BREAK_TIME",
  duration,
});
