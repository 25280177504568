import React from "react";
import { useSelector } from "react-redux";

import useAsyncTasksInProject from "./useAsyncTasksInProject.hook";

import { selectCurrentWorkDayState } from "../reducers/work-day.reducer";
import useAsyncWorkDayTaskTimes from "./useAsyncWorkDayTaskTimes.hook";

export default function useAsyncProjectTaskTimes(
  projectId?: ProjectObject["id"] | null
) {
  const {
    data: { id: currentWorkDayId },
  } = useSelector(selectCurrentWorkDayState);

  const [tasksInProject, tasksInProjectLoading] = useAsyncTasksInProject(
    projectId
  );
  const currentWorkDayTaskTimes = useAsyncWorkDayTaskTimes(currentWorkDayId);

  const projectTaskTimes = React.useMemo(() => {
    if (!!tasksInProject && !!currentWorkDayTaskTimes) {
      const tasksInProjectIds = tasksInProject.map(({ id }) => id);
      return currentWorkDayTaskTimes.filter(({ task }) =>
        tasksInProjectIds.includes(task)
      );
    }
  }, [tasksInProject, currentWorkDayTaskTimes]);

  const isLoading =
    (tasksInProject === undefined && !tasksInProjectLoading) ||
    currentWorkDayTaskTimes === undefined;

  return [projectTaskTimes, isLoading] as [
    TaskTimeObject[] | undefined,
    boolean
  ];
}
