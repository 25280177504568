import React from "react";
import { useDispatch } from "react-redux";
import { Tooltip } from "antd";

import CustomButton from "../../CustomButton/CustomButton";
import { DeleteIcon } from "../../CustomIcons/CustomIcons.component";
import { startDeleteActivity } from "../../../actions/activities.action";
import DeleteConfirmationModal from "../../General/DeleteConfirmationModal/DeleteConfirmationModal";
import { ActivityCardItemContext } from "../ActivityCardItem/ActivityCardItem";

/**
 * Default display
 * Displays CustomButton with DeleteIcon
 *
 * Functionality
 * Clicking on the delete button will soft delete an activity
 */
const DeleteActivity: React.FC = () => {
  const context = React.useContext(ActivityCardItemContext);
  const { activityObject } = context;
  const dispatch = useDispatch();

  const [displayModal, setDisplayModal] = React.useState(false);

  const handleClickDelete = () => (e: React.MouseEvent<HTMLElement>) => {
    setDisplayModal(true);
  };

  return (
    <div className="DeleteActivity">
      <Tooltip title="Delete">
        <CustomButton
          className="DeleteActivity__Btn"
          onClick={handleClickDelete()}
          icon={<DeleteIcon />}
          size="small"
        />
      </Tooltip>
      <DeleteConfirmationModal
        closeCallback={() => setDisplayModal(false)}
        confirmCallback={() => dispatch(startDeleteActivity(activityObject))}
        displayModal={displayModal}
        objectName="activity"
        setDisplayModal={setDisplayModal}
        text="Do you want to delete this activity?"
      />
    </div>
  );
};

export default DeleteActivity;
