import React from "react";
import { Menu, MenuProps } from "antd";

import { LoadingIcon } from "../../../CustomIcons/CustomIcons.component";
import ProjectInitial from "../../../Projects/ProjectInitial/ProjectInitial";
import { ProjectSelectionProps } from "../ProjectSelection";

export interface ProjectSelectionDropdownMenuProps {
  handleSelectProject: ProjectSelectionProps["handleSelectProject"];
  projects: ProjectSelectionProps["projects"];
  projectsLoading: ProjectSelectionProps["projectsLoading"];
}
const ProjectSelectionDropdownMenu: React.FC<ProjectSelectionDropdownMenuProps> = ({
  handleSelectProject,
  projects,
  projectsLoading,
}) => {
  const hasProject = projects.length > 0;

  const handleClick: MenuProps["onClick"] = ({ key, domEvent }) => {
    handleSelectProject(projects[Number(key)]);
    domEvent.stopPropagation();
  };

  return (
    <Menu className="ProjectSelectionDropdownMenu" onClick={handleClick}>
      {projectsLoading && (
        <Menu.Item
          className="ProjectSelectionDropdownMenu__Item ProjectSelectionDropdownMenu__Item--Loading"
          key={0}
          disabled
        >
          <LoadingIcon />
        </Menu.Item>
      )}
      {!projectsLoading &&
        hasProject &&
        projects.map((projectObject: ProjectObject, index: number) => {
          return (
            <Menu.Item
              className="ProjectSelectionDropdownMenu__Item"
              key={index}
            >
              <ProjectInitial project={projectObject} />
              <span className="ProjectSelectionDropdownMenu__Item__Title">
                {projectObject.title}
              </span>
            </Menu.Item>
          );
        })}
      {!projectsLoading && !hasProject && (
        <Menu.Item className="ProjectSelectionDropdownMenu__Empty" key={0}>
          <span>No project available</span>
        </Menu.Item>
      )}
    </Menu>
  );
};

export default ProjectSelectionDropdownMenu;
