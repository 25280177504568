import { RootState } from "../store/configureStore";

export interface WorkShiftSchedulesState extends BaseReducerState {
  data: WorkShiftScheduleArray;
}
export const selectWorkShiftSchedulesState = (rootState: RootState) =>
  rootState.workShiftSchedules;
export const workShiftScheduleReducerDefaultState: WorkShiftSchedulesState = {
  data: [],
  error: "",
  loading: false,
};
export const workShiftScheduleReducer = (
  state = workShiftScheduleReducerDefaultState,
  action: {
    type: string;
    workShiftSchedules: WorkShiftScheduleArray;
    error: string;
  }
) => {
  switch (action.type) {
    case "REQUEST_FETCH_WORK_SHIFT_SCHEDULES":
      return {
        ...state,
        error: "",
        loading: true,
      };
    case "REQUEST_FETCH_WORK_SHIFT_SCHEDULES_SUCCESS":
      return {
        data: action.workShiftSchedules,
        error: "",
        loading: false,
      };
    case "REQUEST_FETCH_WORK_SHIFT_SCHEDULES_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export { workShiftScheduleReducer as default };
