import React from "react";
import { useSelector } from "react-redux";
import { Collapse } from "antd";
import cn from "classnames";

import { SummaryOfTheDayContext } from "../../Checkout/SummaryOfTheDay/SummaryOfTheDay";

import useAsyncTasksInProjectHook from "../../../hooks/useAsyncTasksInProject.hook";

import { LoadingIcon } from "../../CustomIcons/CustomIcons.component";
import ProjectCollapsePanelHeader from "../ProjectCollapsePanelHeader/ProjectCollapsePanelHeader";
import ProjectCollapsePanelFooter from "../ProjectCollapsePanelFooter/ProjectCollapsePanelFooter";
import TaskCollapsePanelHeader from "../../Tasks/TaskCollapsePanelHeader/TaskCollapsePanelHeader";
import TaskCollapsePanelContent from "../../Tasks/TaskCollapsePanelContent/TaskCollapsePanelContent";

import { selectListsState } from "../../../reducers/list.reducer";
import { selectSectionsState } from "../../../reducers/section.reducer";
import { selectNextCalendarScheduleState } from "../../../reducers/third-party-calendar.reducer";
import { selectListOfTask } from "../../../selectors/list.selector";
import { selectSectionOfTask } from "../../../selectors/section.selector";

const { Panel } = Collapse;
const PROJECT_PANEL_KEY = "project";
const defaultUsersProp: UserArray = [];

/**
 * Default Display
 * ProjectCollapsePanelContent displays all of the tasks under the sections in the project under a collapsible panel
 * Each task will also be a collapsible panel containing all the current user's activities for the current date selected
 */
export interface ProjectCollapsePanelContentProps {
  activitiesFilter?: ActivitiesFilter;
  collapse: CollapseState;
  date: string[];
  onToggle?: (state: CollapseState) => void;
  project: ProjectObject;
  projectActivitySummaryArray: ActivitySummaryArray;
  type: ActivitiesSummaryType;
  users?: UserArray;
}
const ProjectCollapsePanelContent: React.FC<ProjectCollapsePanelContentProps> = ({
  activitiesFilter = "all",
  collapse,
  date,
  onToggle,
  project,
  projectActivitySummaryArray,
  type,
  users = defaultUsersProp,
}) => {
  const { tasksAddedToWorkDay, tasksInNextWorkDay } = React.useContext(
    SummaryOfTheDayContext
  );

  const { data: lists } = useSelector(selectListsState);
  const { data: sections } = useSelector(selectSectionsState);
  const nextCalendarScheduleArray = useSelector(selectNextCalendarScheduleState)
    .data;

  const [
    filteredTasks,
    setFilteredTasks,
  ] = React.useState<TaskJoinedToUserAndScheduledArray>([]);
  const [open, setOpen] = React.useState(false);
  // array of expanded task keys
  const [openedTasks, setOpenedTasks] = React.useState<string[]>([]);

  const [tasksInProject, tasksInProjectLoading] = useAsyncTasksInProjectHook(
    project.id
  );

  const isLoading =
    type !== "next-workday" && type !== "checkout" && tasksInProjectLoading;

  // append or remove the key according to collapse state
  const handleTaskToggle = (key: string) => {
    setOpenedTasks((prev) => {
      if (prev.includes(key)) {
        return prev.filter((item) => item !== key);
      }
      return [...prev, key];
    });
  };

  React.useEffect(() => {
    const tasksInProjectIds = tasksInProject
      ? tasksInProject.map(({ id }) => id)
      : [];
    if (type === "next-workday") {
      setFilteredTasks(
        tasksInNextWorkDay.filter(({ id }) => tasksInProjectIds.includes(id))
      );
    } else if (type === "checkout" && !!tasksAddedToWorkDay) {
      setFilteredTasks(
        tasksAddedToWorkDay.filter(({ id }) => tasksInProjectIds.includes(id))
      );
    } else if (!!tasksInProject) {
      setFilteredTasks(tasksInProject);
    }
  }, [
    tasksInProject,
    tasksAddedToWorkDay,
    tasksInNextWorkDay,
    type,
    sections,
    lists,
    nextCalendarScheduleArray,
  ]);

  // expand or collapse all task if collapse state changed
  React.useEffect(() => {
    const taskIds = filteredTasks.map(({ id }) => id);
    if (collapse === "collapse") {
      setOpen(false);
      setOpenedTasks([]);
    } else if (collapse === "expand") {
      setOpen(true);
      setOpenedTasks([]);
    } else if (collapse === "super-expand") {
      setOpen(true);
      setOpenedTasks(taskIds);
    }
  }, [collapse, filteredTasks]);

  const tasksJsx = filteredTasks.map((task) => {
    const { id: taskId } = task;
    const { title: listTitle } = selectListOfTask(task);
    const { title: sectionTitle = "" } = selectSectionOfTask(task) || {};
    const taskActivitySummaryArray: ActivitySummaryArray = projectActivitySummaryArray.filter(
      ({ task, read }) => {
        const belongsToTask = task === taskId;
        const filtered =
          activitiesFilter === "all" ||
          (activitiesFilter === "unread" && !read);
        return belongsToTask && filtered;
      }
    );
    return (type === "history" || type === "team") &&
      taskActivitySummaryArray.length === 0 ? (
      <></>
    ) : (
      <Panel
        key={taskId}
        className="ProjectCollapsePanelContent__Panel__Content__TaskPanel"
        header={
          <TaskCollapsePanelHeader
            date={date}
            listTitle={listTitle}
            onToggle={() => {
              handleTaskToggle(taskId);
              if (onToggle) onToggle("normal");
            }}
            open={openedTasks.includes(taskId)}
            sectionTitle={sectionTitle}
            task={task}
            taskActivitySummaryArray={taskActivitySummaryArray}
            type={type}
            users={users}
          />
        }
        showArrow={false}
      >
        <TaskCollapsePanelContent
          date={date}
          task={task}
          taskActivitySummaryArray={taskActivitySummaryArray}
          type={type}
          users={users}
        />
      </Panel>
    );
  });

  const activeProjectKey = open ? PROJECT_PANEL_KEY : "";

  return (
    <>
      {/**
       * Don't display project collapse content
       * if no tasks for checkout
       * if history or team page and no activities
       */}
      {!(type === "checkout" && filteredTasks.length === 0) &&
        !(
          (type === "history" || type === "team") &&
          projectActivitySummaryArray.length === 0
        ) && (
          <>
            <Collapse
              className="ProjectCollapsePanelContent"
              activeKey={activeProjectKey}
            >
              <Panel
                className="ProjectCollapsePanelContent__Panel"
                key={PROJECT_PANEL_KEY}
                header={
                  <ProjectCollapsePanelHeader
                    project={project}
                    projectActivitySummaryArray={projectActivitySummaryArray}
                    onToggle={() => {
                      setOpen((currentValue) => !currentValue);
                      if (onToggle) onToggle("normal");
                    }}
                    open={open}
                    type={type}
                  />
                }
                showArrow={false}
              >
                <Collapse
                  className={cn("ProjectCollapsePanelContent__Panel__Content", {
                    "ProjectCollapsePanelContent__Panel__Content--Loading": isLoading,
                  })}
                  activeKey={type === "next-workday" ? undefined : openedTasks}
                >
                  {isLoading ? <LoadingIcon /> : tasksJsx}
                </Collapse>
              </Panel>
            </Collapse>

            {type !== "next-workday" && (
              <ProjectCollapsePanelFooter
                date={date}
                project={project}
                users={users}
              />
            )}
          </>
        )}
    </>
  );
};

export default ProjectCollapsePanelContent;
