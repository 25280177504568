import React from "react";

import { NoTaskIcon } from "../../CustomIcons/CustomIcons.component";

/**
 * Default display
 * Displays when global state tasks today_yn count is 0
 */
const PageUnderConstruction: React.FC = () => {
  return (
    <div className="PageUnderConstruction">
      <div className="PageUnderConstruction__Container">
        <NoTaskIcon className="PageUnderConstruction__Icon" />
        <span className="PageUnderConstruction__Title">
          Page is under construction
        </span>
        <span className="PageUnderConstruction__Content">
          Please check back soon for updates
        </span>
      </div>
    </div>
  );
};

export default PageUnderConstruction;
