import React from "react";

import { ActivityTabContentContext } from "../ActivityTabContent/ActivityTabContent";

import { DraftIcon } from "../../CustomIcons/CustomIcons.component";
import ActivityCardItem from "../../Activities/ActivityCardItem/ActivityCardItem";

/**
 * Default display
 * Displays list of all draft activities from the team of the current user and task that have not been confirmed
 * Displays "You don't have team drafts." if list is empty
 */
const SubmittedUnconfirmedActivities: React.FC = () => {
  const {
    submittedUnconfirmedActivities: {
      data: submittedUnconfirmedActivitiesData,
      loading: submittedUnconfirmedActivitiesLoading,
      creating: submittedUnconfirmedActivitiesCreating,
    },
  } = React.useContext(ActivityTabContentContext);

  return (
    <div className="SubmittedUnconfirmedActivities">
      <div className="SubmittedUnconfirmedActivities__Header">
        <div className="SubmittedUnconfirmedActivities__Header__Title">
          <DraftIcon className="SubmittedUnconfirmedActivities__Header__Title__DraftIcon" />
          <span>Unconfirmed</span>
        </div>
      </div>

      <div className="SubmittedUnconfirmedActivities__Body">
        {!submittedUnconfirmedActivitiesLoading &&
        submittedUnconfirmedActivitiesData.length > 0 ? (
          submittedUnconfirmedActivitiesData.map((activity, index) => {
            return <ActivityCardItem key={index} activityObject={activity} />;
          })
        ) : (
          <div className="SubmittedUnconfirmedActivities__Body__Empty">
            <span>You don't have unconfirmed activities.</span>
          </div>
        )}
      </div>
    </div>
  );
};

export { SubmittedUnconfirmedActivities as default };
