import React from "react";
import { Row, Tooltip } from "antd";
import { NoteIcon } from "../../CustomIcons/CustomIcons.component";

/**
 * ActivityContent represents the message body of a activiy
 * for now, it can be a plain comment or a daily report
 * It will support more message format in the feature.
 */
const ActivityContent = ({ text }: ActivityContentProps) => {
  const textList = text.map(
    ({ display_title, section_text, section_title }) => (
      <div key={section_title} className="ActivityContent__section">
        {display_title && <h2>{section_title}</h2>}
        <p>{section_text}</p>
      </div>
    )
  );
  const isDailyReport = text.length > 1;
  return (
    <div className="ActivityContent">
      {isDailyReport && (
        <Row className="ActivityContent__header" justify="space-between">
          <h1 className="ActivityContent__title">Daily Report</h1>
          <Tooltip title="Template description text. Template description text. Template description text. Template description text.">
            <NoteIcon className="ActivityContent__note" />
          </Tooltip>
        </Row>
      )}
      {textList}
    </div>
  );
};

export default ActivityContent;
