import { Popover } from "antd";
import React from "react";

import { FilterIcon } from "../../../CustomIcons/CustomIcons.component";
import TeamMemberSearchPopoverContent from "./TeamMemberSearchPopoverContent/TeamMemberSearchPopoverContent";

export interface TeamMemberSearchProps {
  setObservableUsers: React.Dispatch<
    React.SetStateAction<ObservableUserArray | undefined>
  >;
  observableUsers?: ObservableUserArray;
}
const TeamMemberSearch: React.FC<TeamMemberSearchProps> = ({
  setObservableUsers,
  observableUsers,
}) => {
  const [visible, setVisible] = React.useState(false);
  const handleVisibleChange = (visible: boolean) => setVisible(visible);

  return (
    <div className="TeamMemberSearch">
      <Popover
        content={
          <TeamMemberSearchPopoverContent
            onClickClose={() => {
              setVisible(false);
            }}
            observableUsers={observableUsers}
            setObservableUsers={setObservableUsers}
          />
        }
        placement="bottomRight"
        trigger="click"
        getPopupContainer={(trigger: any) => trigger}
        visible={visible}
        onVisibleChange={handleVisibleChange}
      >
        <FilterIcon />
      </Popover>
    </div>
  );
};

export default TeamMemberSearch;
