import React, { useState } from "react";
import { Menu, Dropdown, message } from "antd";

import useRedirect from "../../../hooks/useRedirect.hook";

import ProjectInitial from "../../Projects/ProjectInitial/ProjectInitial";
import { MoreActionsIcon } from "../../CustomIcons/CustomIcons.component";
import ProjectModal from "../../ProjectModal/ProjectModal.component";

import { defaultPage } from "../../../constants/constants";

const emptyProject = {
  title: "",
  color: {
    value: "#fff",
  },
} as ProjectObject;
const OverviewHeader: React.FC<OverviewHeaderProps> = ({
  project,
  accessType,
  onArchive,
}) => {
  const redirect = useRedirect();

  const [modalOpen, setModalOpen] = useState(false);

  const handleArchive = async () => {
    await onArchive();
    message.info("Project has been archived");
    redirect({
      path: defaultPage,
    });
  };
  const menu = (
    <Menu className="ProjectOverview__Menu OverviewHeader__Menu">
      <Menu.Item onClick={() => setModalOpen(true)}>Edit</Menu.Item>
      {/* Temporarily removed until unarchive feature is finished (issue 20220121-4)
      {project && !project.archived_yn && (
        <Menu.Item onClick={handleArchive}>Archive</Menu.Item>
      )}
      */}
      <Menu.Item disabled={true}>Delete</Menu.Item>
    </Menu>
  );
  return (
    <header className="OverviewHeader">
      <div className="OverviewHeader__Info">
        <ProjectInitial size={32} project={project || emptyProject} />
        <span className="OverviewHeader__Title">
          {(project || emptyProject).title}
        </span>
      </div>
      {accessType === "owner" && (
        <div className="OverviewHeader__Operations">
          <Dropdown overlay={menu} placement="bottomRight" trigger={["click"]}>
            <div className="OverviewHeader__Operation">
              <MoreActionsIcon style={{ color: "#0000007F" }} />
            </div>
          </Dropdown>
        </div>
      )}
      {accessType === "owner" && (
        <ProjectModal
          open={modalOpen}
          project={project}
          onOk={() => setModalOpen(false)}
          onClose={() => setModalOpen(false)}
        />
      )}
    </header>
  );
};

export default OverviewHeader;
