import React from "react";

import MarkNotificationAsRead from "../../MarkNotificationAsRead/MarkNotificationAsRead";
import NotificationsList from "../../NotificationsList/NotificationsList";

const NotificationsPopoverContent = () => {
  return (
    <div className="NotificationsPopoverContent">
      <div className="NotificationsPopoverContent__Header">
        <div className="NotificationsPopoverContent__Header__Title">
          <span>Notifications</span>
        </div>
        <div className="NotificationsPopoverContent__Header__Actions">
          <MarkNotificationAsRead all={true} />
        </div>
      </div>
      <div className="NotificationsPopoverContent__Body">
        <NotificationsList />
      </div>
    </div>
  );
};

export default NotificationsPopoverContent;
