import moment from "moment";
import React from "react";
import { groupBy } from "lodash";

import TimeBar from "../../TimeBar/TimeBar";
import TimelineTooltip from "./TimelineTooltip";
import { getTimesByHour } from "../../../utils/timeline.util";

/*
 * <ShiftTimelineCard /> is the component that shows the time bars and
 * time axis for given timelines. It will generate the padding timelines automatically
 * if the timeline array is not continuous (which means there is a gap between timelines).
 * This component use <TimeBar /> to implement the timeline widget additional with the tooltip.
 */
const ShiftTimelineCard: React.FC<ShiftTimelineCardProps> = ({
  timelines,
  loading = false,
  min,
  max,
  onStatusAdd,
}) => {
  const now = moment();
  const sortedTimelines = timelines.sort((a, b) =>
    moment(a.start).diff(moment(b.start))
  );
  let timesByHour: HourTime[] = getTimesByHour(sortedTimelines);

  // right-pad the last empty time slot
  const lastTimeByHour = timesByHour[timesByHour.length - 1];
  const diff =
    lastTimeByHour && lastTimeByHour.timeline
      ? moment(lastTimeByHour.timeline.end || now)
          .endOf("hour")
          .diff(
            lastTimeByHour.timeline.end
              ? moment(lastTimeByHour.timeline.end)
              : now
          )
      : 0;
  const rightPadding = {
    hour: lastTimeByHour ? lastTimeByHour.hour : 0,
    duration: moment.duration(diff).asSeconds(),
  };
  timesByHour = [...timesByHour, rightPadding].filter(
    (time) => time.duration !== 0
  );

  const timeGroups = groupBy(timesByHour, "hour");
  const hours = Object.keys(timeGroups).sort((a, b) => Number(a) - Number(b));
  const lastHour = hours[hours.length - 1];
  const lastTimes = timeGroups[lastHour];

  // not need to show hour item if the entire hour is empty
  const isLastHourEmpty =
    lastTimes && lastTimes.length === 1 && !lastTimes[0].timeline;
  const paddedHours = isLastHourEmpty
    ? hours
    : [...hours, Number(lastHour) + 1].filter((h) =>
        Number.isInteger(Number(h))
      );
  const hourList = paddedHours.map((hour) => {
    return (
      <div key={hour} className="ShiftTimelineCard__Hour">
        {String(hour).padStart(2, "0")}:00
      </div>
    );
  });

  // manually set hour list to 0:00-1:00 since we can't know the hour when loading
  const loadingHourList = (
    <>
      <div className="ShiftTimelineCard__Hour">00:00</div>
      <div className="ShiftTimelineCard__Hour">01:00</div>
    </>
  );

  const getTimeSlotByHourTime = (time: HourTime) => {
    const ghostSlot = {
      title: "",
      durationInSeconds: time.duration,
      color: "#fff",
      ghost: true,
    };
    if (!time.timeline) {
      return ghostSlot;
    }
    switch (time.timeline.type) {
      case "work":
        return {
          title: "Work",
          durationInSeconds: time.duration,
          color: "#40BF00",
          tooltip: (
            <TimelineTooltip
              timeline={time.timeline}
              primaryColor="#40BF00"
              secondaryColor="#0079F2"
              min={min}
              max={max}
              onSave={(start, end) => onStatusAdd(start, end, "break")}
            />
          ),
        };
      case "break":
        return {
          title: "Break",
          durationInSeconds: time.duration,
          color: "#CCE6FF",
          borderColor: "#0079F2",
          tooltip: (
            <TimelineTooltip
              timeline={time.timeline}
              primaryColor="#0079F2"
              secondaryColor="#40BF00"
              min={min}
              max={max}
              onSave={(start, end) => onStatusAdd(start, end, "work")}
            />
          ),
        };
      case "active":
        return {
          title: "Active",
          durationInSeconds: time.duration,
          color: "#00BFBF",
          tooltip: (
            <TimelineTooltip timeline={time.timeline} primaryColor="#00BFBF" />
          ),
        };
      case "manual":
        return {
          title: "Manual",
          durationInSeconds: time.duration,
          color: "#FFAA00",
          tooltip: (
            <TimelineTooltip timeline={time.timeline} primaryColor="#FFAA00" />
          ),
        };
      case "inactive":
        return {
          title: "Inactive",
          durationInSeconds: time.duration,
          color: "#FFF",
          borderColor: "#0000003F",
          tooltip: (
            <TimelineTooltip timeline={time.timeline} primaryColor="#FFF" />
          ),
        };
      case "processing":
        return {
          title: "Processing",
          durationInSeconds: time.duration,
          color: "#BFBFBF",
          borderColor: "#0000007F",
          stripe: true,
          tooltip: (
            <TimelineTooltip timeline={time.timeline} primaryColor="#BFBFBF" />
          ),
        };
      default:
        return ghostSlot;
    }
  };

  const barList =
    hours.length === 0 ? (
      <div className="ShiftTimelineCard__Bar ShiftTimelineCard__Text">
        no data
      </div>
    ) : (
      hours.map((hour) => {
        const times = timeGroups[hour];
        const timeSlots: TimeSlot[] = times.map((time) =>
          getTimeSlotByHourTime(time)
        );
        // not need to show the time bar if the entire hour is empty
        if (timeSlots.length === 1 && timeSlots[0].ghost) {
          return null;
        }
        return (
          <div key={hour} className="ShiftTimelineCard__Bar">
            <TimeBar timeSlots={timeSlots} />
          </div>
        );
      })
    );

  const loadingBarList = (
    <div className="ShiftTimelineCard__Bar ShiftTimelineCard__Text">
      loading data...
    </div>
  );
  return (
    <div className="ShiftTimelineCard">
      <div className="ShiftTimelineCard__Hours">
        {loading ? loadingHourList : hourList}
      </div>
      <div className="ShiftTimelineCard__Bars">
        {loading ? loadingBarList : barList}
      </div>
    </div>
  );
};

export default ShiftTimelineCard;
