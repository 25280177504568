import React from "react";

import { RightArrowIcon } from "../../CustomIcons/CustomIcons.component";

import { selectListOfTask } from "../../../selectors/list.selector";
import { selectSectionOfTask } from "../../../selectors/section.selector";

const TaskDestinationDisplay: React.FC<TaskDestinationDisplayProps> = ({
  task,
}) => {
  const { title: listTitle } = selectListOfTask(task) || {};
  const { title: sectionTitle } = selectSectionOfTask(task) || {};

  return (
    <div className="TaskDestinationDisplay">
      <span className="TaskDestinationDisplay__ListTitle">{listTitle}</span>
      <RightArrowIcon />
      <span className="TaskDestinationDisplay__SectionTitle">
        {sectionTitle}
      </span>
    </div>
  );
};

export default TaskDestinationDisplay;
