import React from "react";
import { useSelector } from "react-redux";
import moment, { Moment } from "moment";
import { isEmpty, orderBy } from "lodash";
import { useFeature } from "flagged";

import CheckoutTaskCollapsePanel from "../../Checkout/CheckoutTaskCollapsePanel/CheckoutTaskCollapsePanel";
import HistoryPanelHeader from "../HistoryPanelHeader/HistoryPanelHeader";
import WorkTimeRecordTable from "../../WorkTime/WorkTimeRecordTable/WorkTimeRecordTable";
import SummaryOfTheDay from "../../Checkout/SummaryOfTheDay/SummaryOfTheDay";

import { workDayDateFormat } from "../../../constants/constants";
import { selectThirdPartyTrackerState } from "../../../reducers/third-party-tracking.reducer";
import { selectUserDetailsState } from "../../../reducers/user-details.reducer";
import { selectWorkDaysState } from "../../../reducers/work-day.reducer";
import { getUserTaskTimeTableDataService } from "../../../services/task-time.service";
import { getUserWorkTimeTableDataCompleteRecords } from "../../../utils/task-time.util";
import { dispatchError } from "../../../utils/error.util";
import PageUnderConstruction from "../../General/PageUnderConstruction/PageUnderConstruction";

export const HistoryPanelContext = React.createContext<HistoryPanelContext>(
  {} as HistoryPanelContext
);

export const getWorkDaysInRange = (
  workDays: WorkDayArray,
  startDate: Moment,
  endDate: Moment
) => {
  return orderBy(
    workDays.filter(({ work_date }) => {
      return moment(work_date).isBetween(startDate, endDate, undefined, "[]");
    }),
    ["id"],
    ["asc"]
  );
};

const HistoryPanel: React.FC = () => {
  const isBeta = useFeature("under_development");

  const workDays = useSelector(selectWorkDaysState).data;
  const userDetails = useSelector(selectUserDetailsState).data;
  const thirdPartyTracker = useSelector(selectThirdPartyTrackerState).data;
  const { id: thirdPartyTrackerId } = thirdPartyTracker;

  const [historyStartDate, setHistoryStartDate] = React.useState<Moment>();
  const [historyEndDate, setHistoryEndDate] = React.useState<Moment>();
  const [isLoadingTableData, setIsLoadingTableData] = React.useState(false);
  const [
    tableData,
    setTableData,
  ] = React.useState<UserTaskTimeTableDataResponse>();

  const { id: currentUserId } = userDetails;
  const workDaysInRange: WorkDayArray =
    workDays.length > 0 && historyStartDate && historyEndDate
      ? getWorkDaysInRange(workDays, historyStartDate, historyEndDate)
      : [];

  const handleChangeCalendar = React.useCallback((dates) => {
    setHistoryStartDate(dates[0]);
    setHistoryEndDate(dates[1]);
  }, []);

  const handlePresetClick = React.useCallback((dates: [Moment, Moment]) => {
    setHistoryStartDate(dates[0]);
    setHistoryEndDate(dates[1]);
  }, []);

  const getUserWorkTimeTableData = React.useCallback(async () => {
    if (historyStartDate && historyEndDate && currentUserId) {
      try {
        setIsLoadingTableData(true);
        const data = {
          fromDate: historyStartDate.format(workDayDateFormat),
          timeTrackerId: thirdPartyTrackerId,
          toDate: historyEndDate.format(workDayDateFormat),
          userId: currentUserId,
        };
        const response = await getUserTaskTimeTableDataService(data);
        if (response.status === 200) {
          setTableData(
            getUserWorkTimeTableDataCompleteRecords(
              historyStartDate,
              historyEndDate,
              response.data
            )
          );
          setIsLoadingTableData(false);
        } else {
          throw new Error();
        }
      } catch (e) {
        setTableData(undefined);
        setIsLoadingTableData(false);
        dispatchError({
          e,
          title: "GET user work time table data error",
        });
      }
    }
  }, [currentUserId, historyStartDate, historyEndDate, thirdPartyTrackerId]);

  React.useEffect(() => {
    getUserWorkTimeTableData();
  }, [getUserWorkTimeTableData]);

  return (
    <HistoryPanelContext.Provider
      value={{
        historyStartDate,
        historyEndDate,
        setHistoryStartDate,
        setHistoryEndDate,
        tableData,
      }}
    >
      <div className="HistoryPanel">
        {isBeta ? (
          <>
            <HistoryPanelHeader
              handleChangeCalendar={handleChangeCalendar}
              handlePresetClick={handlePresetClick}
              historyStartDate={historyStartDate}
              historyEndDate={historyEndDate}
              loading={isLoadingTableData}
            />
            <div className="HistoryPanel__Body">
              <div className="HistoryPanel__Body__DateRangeDisplay">
                {moment(historyStartDate).format("dddd, MMMM D")}&ndash;
                {moment(historyEndDate).format("dddd, MMMM D")}
              </div>
              <WorkTimeRecordTable
                isLoading={isLoadingTableData}
                tableData={tableData}
                type="date"
              />
              {historyStartDate &&
                historyEndDate &&
                userDetails &&
                !isEmpty(userDetails) && (
                  <>
                    <SummaryOfTheDay
                      date={[
                        historyStartDate.format(workDayDateFormat),
                        historyEndDate.format(workDayDateFormat),
                      ]}
                      type="history"
                      users={[userDetails]}
                    />
                    // Temporarily removed until checkout task has been
                    implemented
                    <CheckoutTaskCollapsePanel
                      activitiesSummaryType="history"
                      dates={[
                        historyStartDate.format(workDayDateFormat),
                        historyEndDate.format(workDayDateFormat),
                      ]}
                      users={[userDetails]}
                    />
                  </>
                )}
            </div>
          </>
        ) : (
          <PageUnderConstruction />
        )}
      </div>
    </HistoryPanelContext.Provider>
  );
};

export default HistoryPanel;
