import React from "react";
import { Avatar, Menu } from "antd";
import { useLocation } from "react-router-dom";

import { individualWorkspacePrivateUrls } from "../../../routers/AppRouter";

import useRedirect from "../../../hooks/useRedirect.hook";

import {
  MembersIcon,
  ProjectIcon,
  SettingIcon,
  TeamIcon,
} from "../../../modules/CustomIcons/CustomIcons.component";

const re = /(\b[a-z](?!\s))/g;

const urlToText = (string: string) =>
  string
    .replace(/\//g, "")
    .replace(/-/g, " ")
    .replace(re, (x) => x.toUpperCase());

const WorkspaceSideMenu: React.FC = () => {
  const redirect = useRedirect();
  const { pathname } = useLocation();

  const handleClick = ({ key }: { key: string }) => {
    redirect({
      path: key,
    });
  };

  return (
    <Menu
      className="WorkspaceSideMenu"
      onClick={handleClick}
      selectedKeys={[pathname]}
      mode="inline"
    >
      <div className="WorkspaceSideMenu__Title">
        <Avatar
          style={{ verticalAlign: "middle" }}
          size="large"
          gap={4}
          className="WorkspaceSideMenu__Title__WorkspaceAvatar"
        >
          MT
        </Avatar>
        <span className="WorkspaceSideMenu__Title__Text">Meistery Teams</span>
      </div>
      <Menu.Item key={individualWorkspacePrivateUrls[0]}>
        <ProjectIcon />
        <span>{urlToText(individualWorkspacePrivateUrls[0])}</span>
      </Menu.Item>
      <Menu.Item key={individualWorkspacePrivateUrls[1]}>
        <MembersIcon />
        <span>{urlToText(individualWorkspacePrivateUrls[1])}</span>
      </Menu.Item>
      <Menu.Item key={individualWorkspacePrivateUrls[2]}>
        <TeamIcon />
        <span>{urlToText(individualWorkspacePrivateUrls[2])}</span>
      </Menu.Item>
      <Menu.Item key={individualWorkspacePrivateUrls[3]}>
        <SettingIcon />
        <span>{urlToText(individualWorkspacePrivateUrls[3])}</span>
      </Menu.Item>
    </Menu>
  );
};

export default WorkspaceSideMenu;
