import React from "react";

import useConsolidateTask from "./useConsolidateTask.hook";

import { getTasksListService } from "../services/tasks.service";
import { selectListOfTask } from "../selectors/list.selector";
import { asyncFetchTaskObject } from "../utils/tasks.util";

import ws from "../sockets/websockets";

export default function useAsyncTasksInList(listId?: ListObject["id"]) {
  const [tasksInList, setTasksInList] = React.useState<TaskArray>();

  const [consolidatedTasks, consolidatedTasksLoading] = useConsolidateTask(
    tasksInList
  );

  const isLoading = tasksInList === undefined || consolidatedTasksLoading;

  const fetchTasksInList = React.useCallback(async () => {
    if (listId) {
      try {
        const response = await getTasksListService({
          parent_list: listId,
        });
        if (response.status === 200) {
          setTasksInList(response.data.results as TaskArray);
        } else {
          throw new Error();
        }
      } catch (e) {}
    }
  }, [listId]);

  React.useEffect(() => {
    fetchTasksInList();
  }, [fetchTasksInList]);

  React.useEffect(() => {
    const wsOnMessage = async (event: MessageEvent) => {
      try {
        const messageEventData = JSON.parse(event.data);
        const { event: eventType } = messageEventData;

        if (eventType === "new_task") {
          const eventTask = messageEventData.meta.data;
          if (!!selectListOfTask(eventTask)) {
            fetchTasksInList();
          }
        } else if (eventType === "new_join_task_to_user") {
          const eventTaskId = messageEventData.meta.task_id;
          const eventTask = await asyncFetchTaskObject(eventTaskId);

          // synchronize members of task record
          if (!!eventTask) {
            setTasksInList((c) =>
              c
                ? c.map((task) => {
                    if (task.id === eventTaskId) {
                      return eventTask;
                    }
                    return task;
                  })
                : c
            );
          }
        }
      } catch (e) {}
    };

    ws.addEventListener("message", wsOnMessage);

    return () => {
      ws.removeEventListener("message", wsOnMessage);
    };
  }, [fetchTasksInList]);

  return [consolidatedTasks, isLoading] as [
    TaskJoinedToUserAndScheduledArray | undefined,
    boolean
  ];
}
