import React from "react";

import { renderIcon } from "../../WorkDayCheckoutStatus/WorkDayCheckoutStatus";

import { CHECKOUT_STATUS } from "../../../../constants/constants";

export interface WorkDayCheckoutStatusPopoverContentProps {
  checkoutStatus: CheckoutStatus;
  checkoutStatusArray: CheckoutStatusArray;
}

const renderContent = (
  checkoutStatus: CheckoutStatus,
  checkoutStatusArray: CheckoutStatusArray
) => {
  switch (checkoutStatus) {
    case CHECKOUT_STATUS.CRITICAL:
    case CHECKOUT_STATUS.NON_CRITICAL:
      return checkoutStatusArray.map(({ issue_json }, index) => {
        const { checkout_failed_message } = issue_json || {};
        if (checkout_failed_message)
          return (
            <div key={index} className="WorkDayCheckoutStatusPopoverContent__Body__FailedCheckoutItem">
              <span>{checkout_failed_message}</span>
            </div>
          );
      });
    case CHECKOUT_STATUS.PASSED:
      return <span>No issues were found in the checkout report.</span>;
    case 4:
    case CHECKOUT_STATUS.STILL_IN_OFFICE:
      return <span>Checkout report is not sent yet</span>;
  }
};

const WorkDayCheckoutStatusPopoverContent: React.FC<WorkDayCheckoutStatusPopoverContentProps> = ({
  checkoutStatus,
  checkoutStatusArray,
}) => {
  return (
    <div className="WorkDayCheckoutStatusPopoverContent">
      <div className="WorkDayCheckoutStatusPopoverContent__Header">
        {renderIcon(checkoutStatus)}
        <span className="WorkDayCheckoutStatusPopoverContent__Header__Title">
          {checkoutStatus === 4 ? "Working or plan to work" : "Checkout status"}
        </span>
      </div>
      <div className="WorkDayCheckoutStatusPopoverContent__Body">
        {renderContent(checkoutStatus, checkoutStatusArray)}
      </div>
    </div>
  );
};

export default WorkDayCheckoutStatusPopoverContent;
