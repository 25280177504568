import React from "react";
import cn from "classnames";

import Button from "../../Button/Button";
import CustomAvatar from "../../CustomAvatar/CustomAvatar";
import { AddIcon, CloseIcon } from "../../CustomIcons/CustomIcons.component";
import { getInitials } from "../../../utils/string.util";

export interface TaskContributorsDisplayListItemProps
  extends Omit<TaskContributorsDisplayProps, "taskContributors"> {
  taskContributor: ProjectUser;
}
export const TaskContributorsDisplayListItem: React.FC<TaskContributorsDisplayListItemProps> = ({
  handleAdd,
  handleRemove,
  role,
  taskContributor,
  type,
}) => {
  const [hovered, setHovered] = React.useState(false);
  const { avatar, status, fullName = "" } = taskContributor;
  return (
    <div
      className="TaskContributorsDisplay__List__Item"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className="TaskContributorsDisplay__List__Item__UserDetails">
        <CustomAvatar
          fallbackText={getInitials(fullName)}
          size={24}
          src={avatar}
          work_status={status}
        />
        <span>{fullName}</span>
      </div>

      {type === "selected" && hovered && !!handleRemove && (
        <Button
          className="TaskContributorsDisplay__List__Item__RemoveBtn"
          icon={<CloseIcon />}
          onClick={() => handleRemove(taskContributor)}
        />
      )}

      {type === "workspace" && handleAdd && (
        <Button
          className="TaskContributorsDisplay__List__Item__AddBtn"
          icon={<AddIcon />}
          onClick={() => handleAdd(taskContributor)}
        >
          Add&nbsp;{role}
        </Button>
      )}
    </div>
  );
};

export interface TaskContributorsDisplayProps {
  handleAdd?: (taskContributor: ProjectUser) => void;
  handleRemove?: (taskContributor: ProjectUser) => void;
  taskContributors: ProjectUser[];
  type: "selected" | "workspace";
  role: TaskContributorRole;
}
const TaskContributorsDisplay: React.FC<TaskContributorsDisplayProps> = ({
  handleAdd,
  handleRemove,
  taskContributors,
  type,
  role,
}) => {
  return (
    <div className="TaskContributorsDisplay">
      <div className="TaskContributorsDisplay__Title">
        <span>
          {type} {role}s ({taskContributors.length})
        </span>
      </div>
      <div
        className={cn("TaskContributorsDisplay__List", {
          "TaskContributorsDisplay__List--Empty": taskContributors.length <= 0,
        })}
      >
        {taskContributors.length > 0 ? (
          taskContributors.map((taskContributor, index) => (
            <TaskContributorsDisplayListItem
              key={index}
              handleAdd={handleAdd}
              handleRemove={handleRemove}
              role={role}
              taskContributor={taskContributor}
              type={type}
            />
          ))
        ) : (
          <span>
            No {type} {role}s.
          </span>
        )}
      </div>
    </div>
  );
};

export default TaskContributorsDisplay;
