import React, { ReactNode } from "react";
import { DatePicker, ConfigProvider } from "antd";
import moment, { Moment } from "moment";
import en_GB from "antd/lib/locale-provider/en_GB";
import "moment/locale/en-gb";
import cn from "classnames";
import { groupBy } from "lodash";
import { RangePickerProps } from "antd/lib/date-picker";

import Button from "../Button/Button";
import {
  CaretLeftIcon,
  CaretRightIcon,
} from "../CustomIcons/CustomIcons.component";

import { workDayDateFormat } from "../../constants/constants";
import {
  externalCalendarPresetArray,
  internalCalendarPresetArray,
  getPresetText,
} from "../../constants/date-range-picker.constant";
import usePrevious from "../../hooks/usePrevious.hook";
import { getDaysBetweenDates } from "../../utils/dates.util";

export interface CustomDateRangePickerProps {
  defaultPresetValue?: CalendarPreset;
  onCalendarChange: RangePickerProps["onCalendarChange"];
  onPresetClick: (dates: [Moment, Moment]) => any;
  startDateValue?: Moment;
  endDateValue?: Moment;
  loading?: boolean;
}
type CaretOperation = "subtract" | "add";

// create local moment instance and change the locale so it will not affect the global moment instance
// use localLocale instead of moment for this component
export const localLocale = moment;
// set localLocale to en-gb so calendar week will start on Monday
/**
 * Antd Calendar documentation states that:
 * Note: Part of the Calendar's locale is read from value. So, please set the locale of moment correctly.
 */

const { RangePicker } = DatePicker;

const CustomDateRangePicker: React.FC<CustomDateRangePickerProps> = ({
  defaultPresetValue = "week",
  onCalendarChange,
  onPresetClick,
  startDateValue,
  endDateValue,
  loading = false,
}) => {
  const [startDate, setStartDate] = React.useState<
    CustomDateRangePickerProps["startDateValue"]
  >(startDateValue);
  const [endDate, setEndDate] = React.useState<
    CustomDateRangePickerProps["endDateValue"]
  >(endDateValue);
  const [preset, setPreset] = React.useState<CalendarPreset | undefined>(
    defaultPresetValue
  );

  const prevPreset: CalendarPreset | undefined = usePrevious(preset);

  const daysInRange =
    startDate && endDate
      ? getDaysBetweenDates(startDate, endDate, workDayDateFormat)
      : [];
  const groupedByDate = groupBy(daysInRange, (b) =>
    localLocale(b, workDayDateFormat).startOf("month").format(workDayDateFormat)
  );
  const isSame =
    startDate && endDate ? startDate.isSame(endDate, "day") : false;

  const handleClickPreset = (newPreset: CalendarPreset) => (
    e: React.MouseEvent
  ) => {
    setPreset(newPreset);
  };

  const handleCalendarChange: RangePickerProps["onCalendarChange"] = (
    values,
    formatString,
    info
  ) => {
    setPreset(undefined);
    if (values && values[0]) setStartDate(values[0]);
    if (values && values[1]) setEndDate(values[1]);
    if (values && onCalendarChange)
      onCalendarChange(values, formatString, info);
  };

  const handleClickCaret = React.useCallback(
    (operation: CaretOperation) => (e: React.MouseEvent) => {
      if (startDate && endDate) {
        let startDateScope: Moment;
        let endDateScope: Moment;
        switch (preset) {
          case "today":
          case "day":
            startDateScope = startDate.clone()[operation](1, "day");
            endDateScope = startDateScope.clone();
            break;
          case "week":
          case "current_week":
            startDateScope = startDate.clone()[operation](1, "week");
            endDateScope = endDate.clone()[operation](1, "week");
            break;
          case "month":
          case "current_month":
            startDateScope = startDate.clone()[operation](1, "month");
            endDateScope = endDate.clone()[operation](1, "month");
            break;
          default:
            const length = daysInRange.length;
            startDateScope = startDate.clone()[operation](length, "day");
            endDateScope = endDate.clone()[operation](length, "day");
            break;
        }
        setStartDate(startDateScope);
        setEndDate(endDateScope);
        onPresetClick([startDateScope, endDateScope]);
      }
    },
    [daysInRange, endDate, onPresetClick, preset, startDate]
  );

  React.useEffect(() => {
    if (startDate && endDate && preset && preset !== prevPreset) {
      let startDateScope: Moment;
      let endDateScope: Moment;
      const prevIsDay = prevPreset === "day" || prevPreset === "today";
      const prevIsWeek = prevPreset === "week" || prevPreset === "current_week";
      const prevIsMonth =
        prevPreset === "month" || prevPreset === "current_month";
      if (prevPreset) {
        if (prevIsDay && preset === "week") {
          // If the current period is “day”; If the user selects “week”, the week that includes that day will be selected.
          startDateScope = startDate.clone().startOf("week");
          endDateScope = endDate.clone().endOf("week");
        } else if (prevIsDay && preset === "month") {
          // If the current period is “day”; If the user selects “month”, the month that includes that day will be selected
          startDateScope = startDate.clone().startOf("month");
          endDateScope = endDate.clone().endOf("month");
        } else if (prevIsWeek && preset === "day") {
          // If the current period is “week”; If the user selects “day”, the period will become the first day of the currently selected week.
          startDateScope = startDate.clone().startOf("week");
          endDateScope = endDate.clone().startOf("week");
        } else if (prevIsWeek && preset === "month") {
          // If the current period is “week”; If the user selects “month”, the period will become the month that includes the more days in the week will be selected
          const keyWithMostDays = Object.keys(
            groupedByDate
          ).reduce((prev, cur) =>
            groupedByDate[prev].length > groupedByDate[cur].length ? prev : cur
          );
          startDateScope = localLocale(keyWithMostDays, workDayDateFormat);
          endDateScope = startDateScope.clone().endOf("month");
        } else if (prevIsMonth && preset === "day") {
          // If the current period is “month”; If the user selects “day”, the 1st day of the month will be selected
          const date = startDate.clone().startOf("month");
          startDateScope = date;
          endDateScope = date;
        } else if (prevIsMonth && preset === "week") {
          // If the current period is “month”; If the user selects “week”, the 1st week that includes that month is selected
          startDateScope = startDate.clone().startOf("week");
          endDateScope = startDateScope.clone().endOf("week");
        } else if (preset === "today") {
          startDateScope = localLocale().startOf("day");
          endDateScope = localLocale().endOf("day");
        } else if (preset === "current_week") {
          startDateScope = localLocale().startOf("week");
          endDateScope = localLocale().endOf("week");
        } else {
          // if preset === "current_month"
          startDateScope = localLocale().startOf("month");
          endDateScope = localLocale().endOf("month");
        }
      } else {
        // If the current period is “arbitrary” (i.e. no button selected, after selecting a custom period)
        switch (preset) {
          case "day":
            // If the user selects “day”, the 1st day of the selected
            startDateScope = startDate.clone();
            endDateScope = startDate.clone();
            break;
          case "week":
            // If the user selects “week”, the 1st week that includes the first day of the period is selected
            startDateScope = startDate.clone().startOf("week");
            endDateScope = startDateScope.clone().endOf("week");
            break;
          default:
            // If the user selects “month”, the 1st month that includes the first day of the period is selected
            startDateScope = startDate.clone().startOf("month");
            endDateScope = startDate.clone().endOf("month");
            break;
        }
      }
      setStartDate(startDateScope);
      setEndDate(endDateScope);
      onPresetClick([startDateScope, endDateScope]);
    }
  }, [endDate, groupedByDate, onPresetClick, preset, prevPreset, startDate]);

  React.useEffect(() => {
    setStartDate(startDateValue);
  }, [startDateValue]);

  React.useEffect(() => {
    setEndDate(endDateValue);
  }, [endDateValue]);

  React.useEffect(() => {
    let startDateScope: Moment;
    let endDateScope: Moment;
    switch (defaultPresetValue) {
      case "day":
        startDateScope = localLocale().startOf("day");
        endDateScope = localLocale().endOf("day");
        break;
      case "week":
        startDateScope = localLocale().startOf("week");
        endDateScope = localLocale().endOf("week");
        break;
      case "month":
      default:
        startDateScope = localLocale().startOf("month");
        endDateScope = localLocale().endOf("month");
        break;
    }
    onPresetClick([startDateScope, endDateScope]);
  }, [defaultPresetValue, onPresetClick]);

  const getPresetJsx = (
    presetArray: CalendarPreset[],
    isPanel: boolean = false
  ) => {
    const checkIfActive = (index: number) => {
      return preset === internalCalendarPresetArray[index];
    };
    return presetArray.map((presetValue, index) => {
      return (
        <div
          key={index}
          className={cn(
            `CustomDateRangePicker__Presets__Button--${presetValue}`,
            {
              CustomDateRangePicker__Presets__Button: !isPanel,
              "CustomDateRangePicker__Presets__Button--Active":
                (!isPanel && presetValue === preset) ||
                (!isPanel && checkIfActive(index)),
              CustomDateRangePicker__Panel__Presets__Button: isPanel,
              "CustomDateRangePicker__Panel__Presets__Button--Active":
                isPanel && presetValue === preset,
            }
          )}
          onClick={handleClickPreset(presetValue)}
        >
          {getPresetText(presetValue, isPanel)}
        </div>
      );
    });
  };

  return (
    <ConfigProvider locale={en_GB}>
      <div className="CustomDateRangePicker">
        <div className="CustomDateRangePicker__Presets">
          {getPresetJsx(externalCalendarPresetArray)}
        </div>
        <Button
          className="CustomDateRangePicker__LeftCaret"
          icon={<CaretLeftIcon />}
          onClick={handleClickCaret("subtract")}
          disabled={loading}
        />
        <RangePicker
          className={cn("CustomDateRangePicker__RangePicker", {
            "CustomDateRangePicker__RangePicker--SameDate": isSame,
          })}
          inputReadOnly={true}
          value={startDate && endDate ? [startDate, endDate] : undefined}
          separator={
            <div className="CustomDateRangePicker__RangePicker__Separator">
              &ndash;
            </div>
          }
          suffixIcon={undefined}
          format={workDayDateFormat}
          onCalendarChange={handleCalendarChange}
          panelRender={(panelNode: ReactNode) => (
            <div className="CustomDateRangePicker__Panel">
              <div className="CustomDateRangePicker__Panel__Presets">
                {getPresetJsx(internalCalendarPresetArray, true)}
              </div>
              {panelNode}
            </div>
          )}
          getPopupContainer={(trigger: any) => trigger}
        />
        <Button
          className="CustomDateRangePicker__RightCaret"
          icon={<CaretRightIcon />}
          onClick={handleClickCaret("add")}
          disabled={loading}
        />
      </div>
    </ConfigProvider>
  );
};

export default CustomDateRangePicker;
