const users: WorkspaceUser[] = [
  {
    id: "1",
    avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    fullName: "John Williams",
    displayName: "JW",
  },
  {
    id: "2",
    avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    fullName: "Gregory Hunt",
    displayName: "GH",
  },
  {
    id: "3",
    avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    fullName: "Carl Wescott",
    displayName: "CW",
  },
  {
    id: "4",
    avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    fullName: "Marie Rojas",
    displayName: "MR",
  },
  {
    id: "5",
    avatar: "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png",
    fullName: "Jeff Dean",
    displayName: "JD",
  },
  {
    id: "BU5EPP7QOX",
    avatar:
      "http://54.168.72.77/media/media_files/profile_pictures/stan_9d4Xjl9.jpeg",
    fullName: "Kenny Liu",
    displayName: "KL",
  },
];

export const workspaceUsers = [
  {
    id: "PJ7US0PJIT",
    avatar: null,
    full_name: "workspace_a_admin",
    workspace: "IUJ8S4RADP",
    workspace_role: "OWNER",
    current_status: {
      status_enum_identifier: null,
      end_datetime: null,
      deleted_yn: false,
    },
    display_name: "Workspace A Admin",
    phone_number: "",
    country: "",
    city: "",
    team_page_access_granted_yn: true,
  },
  {
    id: "5D5DVWW9BK",
    avatar: null,
    full_name: "User D",
    workspace: "IUJ8S4RADP",
    workspace_role: "MEMBER",
    current_status: {
      status_enum_identifier: null,
      end_datetime: null,
      deleted_yn: false,
    },
    display_name: "User D",
    phone_number: "",
    country: "",
    city: "",
    team_page_access_granted_yn: true,
  },
  {
    id: "B2XTUPO0DH",
    avatar: null,
    full_name: "User A",
    workspace: "IUJ8S4RADP",
    workspace_role: "MEMBER",
    current_status: {
      id: "GF8L4DEVSB",
      status_enum_identifier: "working",
      start_datetime: "2021-12-18T16:05:24.303317Z",
      end_datetime: null,
      deleted_yn: false,
    },
    display_name: "User A",
    phone_number: "",
    country: "",
    city: "",
    team_page_access_granted_yn: true,
  },
  {
    id: "XMC1I0J3G3",
    avatar:
      "https://be.tm-staging.cc/media/media_files/profile_pictures/sample_avatar_1_K0IGPBG.png",
    full_name: "User C",
    workspace: "IUJ8S4RADP",
    workspace_role: "MEMBER",
    current_status: {
      status_enum_identifier: null,
      end_datetime: null,
      deleted_yn: false,
    },
    display_name: "User C",
    phone_number: "",
    country: "",
    city: "",
    team_page_access_granted_yn: true,
  },
  {
    id: "WJZKNAGLKW",
    avatar:
      "https://be.tm-staging.cc/media/media_files/profile_pictures/sample_avatar_lxTTajM.png",
    full_name: "Abhilash V J",
    workspace: "IUJ8S4RADP",
    workspace_role: "ADMIN",
    current_status: {
      id: "MQMQVMVNNM",
      status_enum_identifier: "working",
      start_datetime: "2021-12-16T01:56:17.884638Z",
      end_datetime: null,
      deleted_yn: false,
    },
    display_name: "Abhilash",
    phone_number: "",
    country: "India",
    city: "",
    team_page_access_granted_yn: true,
  },
  {
    id: "9GNDPNP0N7",
    avatar:
      "https://be.tm-staging.cc/media/media_files/profile_pictures/sample_avatar_1_0ni4dB4.png",
    full_name: "user_C epic11",
    workspace: "IUJ8S4RADP",
    workspace_role: "MEMBER",
    current_status: {
      id: "RKL4TX2ENI",
      status_enum_identifier: "working",
      start_datetime: "2021-12-07T04:16:51.106484Z",
      end_datetime: null,
      deleted_yn: false,
    },
    display_name: "user c epic 11",
    phone_number: "",
    country: "",
    city: "",
    team_page_access_granted_yn: false,
  },
  {
    id: "VRL3MPRPOH",
    avatar:
      "https://be.tm-staging.cc/media/media_files/profile_pictures/sample_avatar_Qza5uQS.png",
    full_name: "User B",
    workspace: "IUJ8S4RADP",
    workspace_role: "MEMBER",
    current_status: {
      id: "BA6S4OR4NL",
      status_enum_identifier: "working",
      start_datetime: "2021-09-23T16:43:42.955189Z",
      end_datetime: null,
      deleted_yn: false,
    },
    display_name: "User B",
    phone_number: "",
    country: "",
    city: "",
    team_page_access_granted_yn: true,
  },
  {
    id: "AWE8EFW3PA",
    avatar:
      "https://be.tm-staging.cc/media/media_files/profile_pictures/shutterstock_small.png",
    full_name: "User A epic 11",
    workspace: "IUJ8S4RADP",
    workspace_role: "MEMBER",
    current_status: {
      id: "1PA81PPI4O",
      status_enum_identifier: "working",
      start_datetime: "2021-12-17T17:17:50.824464Z",
      end_datetime: null,
      deleted_yn: false,
    },
    display_name: "user a epic 11",
    phone_number: "",
    country: "Japan",
    city: "Tokyo",
    team_page_access_granted_yn: true,
  },
  {
    id: "NNHCX21JUM",
    avatar:
      "https://be.tm-staging.cc/media/media_files/profile_pictures/sample_avatar_qOEWyKx.png",
    full_name: "Briggs Victoria",
    workspace: "IUJ8S4RADP",
    workspace_role: "ADMIN",
    current_status: {
      id: "ESCX88B6XN",
      status_enum_identifier: "working",
      start_datetime: "2021-12-19T01:32:14.553632Z",
      end_datetime: null,
      deleted_yn: false,
    },
    display_name: "Briggs",
    phone_number: "",
    country: "Philippines",
    city: "",
    team_page_access_granted_yn: true,
  },
  {
    id: "5BVT9P74P1",
    avatar:
      "https://be.tm-staging.cc/media/media_files/profile_pictures/sample_avatar_Tj1iNqX.png",
    full_name: "user_B epic11",
    workspace: "IUJ8S4RADP",
    workspace_role: "MEMBER",
    current_status: {
      id: "YVZ1XNLWZQ",
      status_enum_identifier: "working",
      start_datetime: "2021-12-14T17:56:31.938534Z",
      end_datetime: null,
      deleted_yn: false,
    },
    display_name: "user b epic 11",
    phone_number: "",
    country: "",
    city: "",
    team_page_access_granted_yn: false,
  },
  {
    id: "VH5UNJ4NQQ",
    avatar:
      "https://be.tm-staging.cc/media/media_files/profile_pictures/avatar_user_B_sAzMAJf_ZVMJ8p0.png",
    full_name: "Briggs Sample",
    workspace: "IUJ8S4RADP",
    workspace_role: "MEMBER",
    current_status: {
      id: "ZDLDTS0PU4",
      status_enum_identifier: "working",
      start_datetime: "2021-12-15T13:43:30.472784Z",
      end_datetime: null,
      deleted_yn: false,
    },
    display_name: "Briggs Sample",
    phone_number: "",
    country: "",
    city: "",
    team_page_access_granted_yn: true,
  },
];

export default users;
