/**
 * Merge task constants
 */
export const contentSelectorOptions = [
  { label: "Add work time", value: "work_time", disabled: true },
  { label: "Add activity history", value: "activity_history", disabled: true },
  { label: "Add description", value: "description" },
  { label: "Add checklists", value: "checklists" },
  { label: "Add labels", value: "labels" },
  { label: "Add contributors", value: "contributors" },
];
export const contentSelectorDefaultValue = ["work_time", "activity_history"];
