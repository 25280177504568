import React from "react";
import { useDispatch } from "react-redux";
import { Tooltip, Popover } from "antd";
import { Feature } from "flagged";
import { remove, union } from "lodash";

import {
  ArchiveIcon,
  DeleteIcon,
  DraftIcon,
  MoreActionsIcon,
} from "../../CustomIcons/CustomIcons.component";
import { ListContext } from "../../List/List";
import { SectionContext } from "../../Section/Section";

import MergeTaskButton from "../MergeTask/MergeTaskButton/MergeTaskButton";
import MoveTask from "../MoveTask/MoveTask";

import { startPartialUpdateTask } from "../../../actions/tasks.action";

export interface MoreActionsProps {
  archived?: boolean;
  onCopy?: (task: TaskObject) => void;
  onPin?: (pinned: boolean) => void;
  panelType: PanelType;
  projectId: string;
  task: TaskJoinedToUserAndScheduledObject;
}
const MoreActions: React.FC<MoreActionsProps> = ({
  archived,
  onPin,
  panelType,
  projectId,
  task,
}) => {
  const listContext = React.useContext(ListContext);
  const sectionContext = React.useContext(SectionContext);
  const { setCounterToReload } = listContext || {};
  const { setDisplayedTasks } = sectionContext || {};

  const dispatch = useDispatch();

  const [displayPopover, setDisplayPopover] = React.useState(false);

  const { id: taskId } = task;

  const handleArchive = () => {
    // TODO 13.11.7 - integrate archive task endpoint
    // const payload = {
    //   id: task.id,
    //   archived_yn: true,
    // };
    // dispatch(startPartialUpdateTask(payload));
    const lsArchivedTasks = localStorage.getItem("archived_tasks");
    const archivedTasks = lsArchivedTasks ? JSON.parse(lsArchivedTasks) : [];
    const newArchivedTasks = union([...archivedTasks, taskId]);
    localStorage.setItem("archived_tasks", JSON.stringify(newArchivedTasks));

    setDisplayedTasks((c) => c.filter(({ id }) => id !== taskId));
  };

  const handleUnarchive = () => {
    // TODO 13.11.4 - integrate unarchive task endpoint
    const lsArchivedTasks = localStorage.getItem("archived_tasks");
    const archivedTasks = lsArchivedTasks ? JSON.parse(lsArchivedTasks) : [];
    const index = archivedTasks.indexOf(taskId);
    if (index !== -1) {
      archivedTasks.splice(index, 1);
    }
    localStorage.setItem("archived_tasks", JSON.stringify(archivedTasks));
    setCounterToReload((c) => c + 1);
  };

  const disablePropagation = (e: any) => e.stopPropagation();

  const content = (
    <div className="MoreActions__Popover__Content" onClick={disablePropagation}>
      <Feature name="under_development">
        {!archived && <MoveTask panelType={panelType} />}
        <MergeTaskButton task={task} panelType={panelType} />
      </Feature>
      <div className="MoreActions__Popover__Action disabled">
        <DraftIcon className="MoreActions__Popover__Icon" />
        <span>Rename</span>
      </div>

      {archived ? (
        <div className="MoreActions__Popover__Action" onClick={handleUnarchive}>
          <ArchiveIcon className="MoreActions__Popover__Icon" />
          <span>Unarchive</span>
        </div>
      ) : (
        <div className="MoreActions__Popover__Action" onClick={handleArchive}>
          <ArchiveIcon className="MoreActions__Popover__Icon" />
          <span>Archive</span>
        </div>
      )}

      {archived && (
        <>
          <div className="MoreActions__Popover__Action disabled"></div>
          <div className="MoreActions__Popover__Action">
            <DeleteIcon className="MoreActions__Popover__Icon" />
            <span>Delete</span>
          </div>
        </>
      )}
    </div>
  );

  return (
    <Popover
      placement="bottom"
      destroyTooltipOnHide={true}
      content={content}
      overlayClassName="MoreActions__Popover"
      getPopupContainer={(trigger: any) => trigger.parentElement}
      visible={displayPopover}
    >
      <Tooltip title="More actions">
        <MoreActionsIcon
          onClick={(e: React.MouseEvent) => {
            setDisplayPopover((c) => !c);
            disablePropagation(e);
          }}
          className="MoreActions"
        />
      </Tooltip>
    </Popover>
  );
};

export { MoreActions as default };
