import { RootState } from "../store/configureStore";

export interface StatusTypeState {
  data: StatusTypeArray;
  error: string;
  loading: boolean;
}
export const selectStatusTypesState = (rootState: RootState) =>
  rootState.statusTypes;
export const statusTypesReducerDefaultState: StatusTypeState = {
  data: [],
  error: "",
  loading: false,
};

const statusTypesReducer = (
  state = statusTypesReducerDefaultState,
  action: { type: string; statusTypes: StatusTypeArray; error: string }
) => {
  switch (action.type) {
    case "REQUEST_FETCH_STATUS_TYPES":
      return {
        ...state,
        error: "",
        loading: true,
      };
    case "REQUEST_FETCH_STATUS_TYPES_SUCCESS":
      return {
        data: action.statusTypes,
        error: "",
        loading: false,
      };
    case "REQUEST_FETCH_STATUS_TYPES_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export { statusTypesReducer as default };
