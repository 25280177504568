import React from "react";

export default function useEllipsisOnOverflow(text: string | undefined) {
  const ref = React.useRef<HTMLSpanElement>(null);
  const [showEllipsis, setShowEllipsis] = React.useState(false);

  React.useEffect(() => {
    const updateEllipsis = () => {
      if (!!text && ref && ref.current) {
        if (ref.current.offsetWidth < ref.current.scrollWidth) {
          setShowEllipsis(true);
        } else {
          setShowEllipsis(false);
        }
      }
    };

    // call update when component rendered
    updateEllipsis();

    // register resize listener and update the ellipsis state when size changed
    window.addEventListener("resize", updateEllipsis);

    return () => window.removeEventListener("resize", updateEllipsis);
  }, [ref, text]);

  return [ref, showEllipsis] as [React.RefObject<HTMLSpanElement>, boolean];
}
