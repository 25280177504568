import React from "react";
import moment from "moment";

import { ActivityCardItemContext } from "../ActivityCardItem/ActivityCardItem";
import {
  minutesToMs,
  getTimeAgoString,
} from "../../../utils/time-conversion.util";

/**
 * Default display
 * Displays timestamp of activity
 *
 * Functionality
 * If prop confirmed_yn is true and prop confirmed_datetime is set, displays prop confirmed_datetime as timestamp
 * If prop deleted_yn is true and prop last_update_date is set, displays prop last_update_date as timestamp
 * If prop confirmed_yn is false, prop deleted_yn is false, and prop last_update_date is set, displays prop last_update_date as timestamp from now
 */
const ActivityTimestamp: React.FC<ActivityTimestampProps> = (props) => {
  const context = React.useContext(ActivityCardItemContext);
  const { activityObject } = context;
  const {
    last_update_date,
    confirmed_yn,
    confirmed_datetime,
    deleted_yn,
  } = activityObject;
  const { deletedTimeAgo } = props;

  const [timeAgoString, setTimeAgoString] = React.useState("");
  let interval = React.useRef<number>(0);

  const getDeletedOrUpdatedTimeAgoString = () => {
    const action = deletedTimeAgo ? "Deleted" : "Updated";
    if (last_update_date)
      setTimeAgoString(`${action} ${getTimeAgoString(last_update_date)}`);
  };

  React.useEffect(() => {
    getDeletedOrUpdatedTimeAgoString();
    interval.current = window.setInterval(() => {
      getDeletedOrUpdatedTimeAgoString();
    }, minutesToMs(1));

    return () => {
      window.clearInterval(interval.current);
    };
  }, [deleted_yn, last_update_date]);

  return (
    <div className="ActivityTimestamp">
      {(() => {
        if (deletedTimeAgo || (!confirmed_yn && !deleted_yn)) {
          return <span>{timeAgoString}</span>;
        } else if (confirmed_yn && confirmed_datetime) {
          return moment(confirmed_datetime).format("h:mm A");
        } else if (deleted_yn && last_update_date) {
          return moment(last_update_date).format("h:mm A");
        }
      })()}
    </div>
  );
};

export default ActivityTimestamp;
