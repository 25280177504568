import React from "react";
import { Badge } from "antd";

import { LoadingIcon } from "../../CustomIcons/CustomIcons.component";

import useAsyncTasksInList from "../../../hooks/useAsyncTasksInList.hook";
import { getUnreadActivitiesCountService } from "../../../services/unread-activities.service";

import ws from "../../../sockets/websockets";

const ListUnreadActivitiesCount: React.FC<ListUnreadActivitiesCountProps> = ({
  list,
}) => {
  const [unreadActivitiesCount, setUnreadActivitiesCount] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  const [tasksInList] = useAsyncTasksInList(list.id);
  const tasksInListIds = React.useMemo(
    () => (!!tasksInList ? tasksInList.map(({ id }) => id) : []),
    [tasksInList]
  );

  const getListUnreadActivities = React.useCallback(async () => {
    try {
      const payload = {
        task_list_id: list.id,
      };
      const response = await getUnreadActivitiesCountService(payload);
      if (response.status === 200) {
        setUnreadActivitiesCount(response.data.activity_count);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }, [list]);

  React.useEffect(() => {
    getListUnreadActivities();
  }, [getListUnreadActivities]);

  React.useEffect(() => {
    const wsOnMessage = async (event: MessageEvent) => {
      try {
        const messageEventData = JSON.parse(event.data);
        const { event: eventType, meta } = messageEventData;
        const { task_id } = meta || {};
        switch (eventType.trim()) {
          case "new_activity":
          case "new_activity_read_log":
          case "new_activity_read_log_mark_all":
            if (tasksInListIds.includes(task_id)) getListUnreadActivities();
            break;
          default:
            break;
        }
      } catch (e) {}
    };
    ws.addEventListener("message", wsOnMessage);

    return () => {
      ws.removeEventListener("message", wsOnMessage);
    };
  }, [tasksInListIds, getListUnreadActivities]);

  return (
    <div className="ListUnreadActivitiesCount">
      {loading ? (
        <LoadingIcon className="ListUnreadActivitiesCount__Loader" />
      ) : (
        <Badge
          className="ListUnreadActivitiesCount__Badge"
          count={unreadActivitiesCount}
          showZero={true}
        />
      )}
    </div>
  );
};

export default ListUnreadActivitiesCount;
