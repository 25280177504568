import axios from "axios";

const defaultOptions = {
  baseURL: `${window.location.protocol}//${process.env.REACT_APP_HOST}/`,
  headers: {
    "Content-Type": "application/json",
  },
  xsrfHeaderName: "X-XSRF-TOKEN",
  xsrfCookieName: "csrftoken",
};

const instance = axios.create(defaultOptions);

// instance.interceptors.request.use(req => {
//     if (axios.defaults.headers.common["Authorization"]) return req;
//     throw ({ message: "the token is not available" });
// }, error => {
//     return Promise.reject(error);
// }
// );

//on successful response
// instance.interceptors.response.use(response => response,
//     error => {
//         const fallbackValue = [
//             {
//                 userId: "Not authorized", id: "aerw15311sq",
//                 title: "Please try     again", completed: false
//             }];
//         return Promise.reject(fallbackValue);
//     }
// );

const setAuthToken = (token) => {
  if (token) {
    //applying token
    instance.defaults.headers.common["Authorization"] = `Token ${token}`;
  } else {
    //deleting the token from header
    delete instance.defaults.headers.common["Authorization"];
  }
};

export { setAuthToken, instance as default };
