import React from "react";
import { Row, Col, Popover, Cascader } from "antd";
import cn from "classnames";
import {
  CollapseIcon,
  ExpandIcon,
  FilterIcon,
} from "../../CustomIcons/CustomIcons.component";

/**
 * UnreadActivities header can collapses / expands / filters all the tasks
 * when user click collapse / expand / collapse icon.
 */
const UnreadActivitiesHeader = ({
  options,
  collapse,
  onOpen,
  onClose,
  onFilter,
}: UnreadActivitiesHeaderProps) => {
  const handleChange = (value: any) => {
    onFilter(value);
  };

  // we use antd Cascader as projects filter
  const filterContent = (
    <Cascader
      className="UnreadActivitiesHeader__cascader"
      options={options}
      onChange={handleChange}
      changeOnSelect={true}
      placeholder="Please select to filter"
    />
  );
  return (
    <Row
      className="UnreadActivitiesHeader"
      justify="space-between"
      align="middle"
    >
      <Col>
        <h1 className="UnreadActivitiesHeader__title">Unread</h1>
      </Col>
      <Col>
        <Row>
          <Row>
            <div
              className={cn(
                "UnreadActivitiesHeader__icon",
                "UnreadActivitiesHeader__collapse",
                {
                  "UnreadActivitiesHeader__icon-active":
                    collapse === "collapse",
                }
              )}
              onClick={onClose}
            >
              <CollapseIcon />
            </div>
            <div
              className={cn(
                "UnreadActivitiesHeader__icon",
                "UnreadActivitiesHeader__expand",
                {
                  "UnreadActivitiesHeader__icon-active": collapse === "expand",
                }
              )}
              onClick={onOpen}
            >
              <ExpandIcon />
            </div>
          </Row>
          <div className="UnreadActivitiesHeader__filter">
            <Popover
              content={filterContent}
              placement="bottomRight"
              trigger="click"
            >
              <div className="UnreadActivitiesHeader__icon">
                <FilterIcon />
              </div>
            </Popover>
          </div>
        </Row>
      </Col>
    </Row>
  );
};

export default UnreadActivitiesHeader;
