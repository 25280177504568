import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { Feature, useFeature } from "flagged";

import useAsyncConsolidateUserTasks from "../../../hooks/useAsyncConsolidateUserTasks.hook";
import useAsyncMyDeskTasks from "../../../hooks/useAsyncMyDeskTasks.hook";
import useConsolidateTask from "../../../hooks/useConsolidateTask.hook";
import useRedirect from "../../../hooks/useRedirect.hook";

import { LoadingIcon } from "../../CustomIcons/CustomIcons.component";
import Details from "../../InTheOffice/Details/Details";
import ProjectCardList from "../../Projects/ProjectCardList/ProjectCardList";
import TaskDetails from "../../Tasks/TaskDetails/TaskDetails";
import TaskPanelHeader from "../TaskPanelHeader/TaskPanelHeader";

import { setTaskDetails } from "../../../actions/task-details.action";
import { TASK_PAGES, TASK_PAGE_TITLES } from "../../../constants/constants";
import {
  selectTaskDetailsState,
  taskDetailsReducerDefaultState,
} from "../../../reducers/task-details.reducer";
import { formatUrlTitle } from "../../../utils/url.util";
import { fetchTaskRecordService } from "../../../services/tasks.service";
import { asyncFetchListOfTask } from "../../../utils/tasks.util";
import PageEmptyState from "../../General/PageEmptyState/PageEmptyState";
import PageUnderConstruction from "../../General/PageUnderConstruction/PageUnderConstruction";

interface TaskPanelContext {
  todayTasks?: JoinTaskToUserArray;
  todayTasksLoading: boolean;
  nextWorkDayTasks?: JoinTaskToUserArray;
  nextWorkDayTasksLoading: boolean;
  starredTasks?: JoinTaskToUserArray;
  starredTasksLoading: boolean;
}
export const TaskPanelContext = React.createContext<TaskPanelContext>(
  {} as TaskPanelContext
);

/**
 * Panel for [Today, Tasks] navigation
 *
 * Default Display
 * Displays ProjectCardList component
 *
 * Functionality
 * Passes down received [filter] prop to child components
 */
const TaskPanel: React.FC<MyDeskTaskPanelProps> = ({ taskPage }) => {
  const isBeta = useFeature("under_development");

  const redirect = useRedirect();

  const { taskId: paramsTaskId, taskName: paramsTaskName } = useParams<{
    taskId?: string;
    taskName?: string;
  }>();
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();

  const taskDetails = useSelector(selectTaskDetailsState);

  const [paramsTask, setParamsTask] = React.useState<TaskObject>();
  const [paramsTaskLoading, setParamsTaskLoading] = React.useState(false);

  const [todayTasks, todayTasksLoading] = useAsyncMyDeskTasks("today");
  const [nextWorkDayTasks, nextWorkDayTasksLoading] = useAsyncMyDeskTasks(
    "next_shift"
  );
  const [starredTasks, starredTasksLoading] = useAsyncMyDeskTasks("starred");

  const [
    consolidatedTodayTasks,
    consolidatedTodayTasksLoading,
  ] = useAsyncConsolidateUserTasks(todayTasks);
  const [
    consolidatedNextWorkDayTasks,
    consolidatedNextWorkDayTasksLoading,
  ] = useAsyncConsolidateUserTasks(nextWorkDayTasks);
  const [
    consolidatedStarredTasks,
    consolidatedStarredTasksLoading,
  ] = useAsyncConsolidateUserTasks(starredTasks);

  const isLoading =
    consolidatedTodayTasksLoading ||
    consolidatedNextWorkDayTasksLoading ||
    consolidatedStarredTasksLoading;

  const urlTaskPage = searchParams.get("p");

  const showEmptyState = React.useMemo(() => {
    return (
      (taskPage === TASK_PAGES.TODAY &&
        !!todayTasks &&
        todayTasks.length === 0) ||
      (taskPage === TASK_PAGES.NEXT_SHIFT &&
        !!nextWorkDayTasks &&
        nextWorkDayTasks.length === 0) ||
      (taskPage === TASK_PAGES.STARRED &&
        !!starredTasks &&
        starredTasks.length === 0)
    );
  }, [
    taskPage,
    JSON.stringify(todayTasks),
    JSON.stringify(nextWorkDayTasks),
    JSON.stringify(starredTasks),
  ]);

  const tasksInPage = React.useMemo(() => {
    switch (urlTaskPage) {
      case TASK_PAGES.TODAY:
        return consolidatedTodayTasks;
      case TASK_PAGES.NEXT_SHIFT:
        return consolidatedNextWorkDayTasks;
      case TASK_PAGES.STARRED:
        return consolidatedStarredTasks;
      default:
    }
  }, [
    urlTaskPage,
    consolidatedTodayTasks,
    consolidatedNextWorkDayTasks,
    consolidatedStarredTasks,
  ]);

  const taskPageKeyValue = React.useMemo(
    () => Object.entries(TASK_PAGES).find((_) => _[1] === taskPage),
    [taskPage]
  );
  const taskPageKey = React.useMemo(
    () => (taskPageKeyValue ? taskPageKeyValue[0] : undefined),
    [taskPageKeyValue]
  );

  React.useEffect(() => {
    (async () => {
      setParamsTask(undefined);
      if (paramsTaskId) {
        try {
          setParamsTaskLoading(true);
          const response = await fetchTaskRecordService(paramsTaskId);
          if (response.status === 200) {
            setParamsTaskLoading(false);
            setParamsTask(response.data as TaskObject);
          } else {
            throw new Error();
          }
        } catch (e) {
          setParamsTaskLoading(false);
        }
      }
    })();
  }, [paramsTaskId]);

  const handleTaskSelect = ({ id: taskId, title: taskTitle }: TaskObject) => {
    redirect({
      path: `/t/${taskId}/${formatUrlTitle(taskTitle)}/activity`,
      searchParams: `p=${taskPage}`,
    });
  };

  React.useEffect(() => {
    (async () => {
      const taskInPage =
        tasksInPage && paramsTask
          ? tasksInPage!.find(({ id }) => id === paramsTask.id)
          : undefined;

      if (!!paramsTaskId && !!paramsTask && !isLoading && !!taskInPage) {
        dispatch(setTaskDetails(taskInPage));
      } else if (!!paramsTaskId && !!paramsTask && !isLoading && !taskInPage) {
        const taskList = await asyncFetchListOfTask(paramsTaskId);

        if (taskList) {
          const { id: listId, title: listTitle } = taskList;
          redirect({
            path: `/l/${listId}/${formatUrlTitle(
              listTitle
            )}/${paramsTaskId}/${paramsTaskName}/activity`,
          });
        }
      } else {
        dispatch(setTaskDetails(taskDetailsReducerDefaultState));
      }
    })();
  }, [
    dispatch,
    isLoading,
    paramsTask,
    paramsTaskId,
    paramsTaskName,
    redirect,
    tasksInPage,
  ]);

  return (
    <TaskPanelContext.Provider
      value={{
        todayTasks,
        todayTasksLoading,
        nextWorkDayTasks,
        nextWorkDayTasksLoading,
        starredTasks,
        starredTasksLoading,
      }}
    >
      <div className="TaskPanel">
        {isBeta ? (
          <>
            <TaskPanelHeader />
            {isLoading && (
              <div className="TaskPanel__Loading">
                <LoadingIcon />
              </div>
            )}
            {!isLoading && showEmptyState && (
              <PageEmptyState
                title={`No tasks in ${
                  taskPageKey && TASK_PAGE_TITLES[taskPageKey]
                }`}
                content={
                  <span>
                    Here you can find all tasks that are&nbsp;
                    {taskPage === TASK_PAGES.TODAY && (
                      <span>scheduled for today or were tracked today</span>
                    )}
                    {taskPage === TASK_PAGES.NEXT_SHIFT && (
                      <span>scheduled for the next workday</span>
                    )}
                    {taskPage === TASK_PAGES.STARRED && <span>starred</span>}.
                  </span>
                }
              />
            )}
            {!isLoading && !showEmptyState && (
              <ProjectCardList
                onTaskSelect={handleTaskSelect}
                taskPage={taskPage}
              />
            )}
          </>
        ) : (
          <PageUnderConstruction />
        )}
      </div>

      {isBeta && paramsTaskLoading && <Details loading={paramsTaskLoading} />}
      {isBeta && !paramsTaskLoading && !isEmpty(taskDetails) && (
        <TaskDetails task={taskDetails} />
      )}
    </TaskPanelContext.Provider>
  );
};

export { TaskPanel as default };
