import React from "react";
import { useSelector } from "react-redux";

import MarkActivitiesAsRead from "../MarkActivitiesAsRead/MarkActivitiesAsRead";
import ReactToActivity from "../ReactToActivity/ReactToActivity";
import EditActivity from "../EditActivity/EditActivity";
import DeleteActivity from "../DeleteActivity/DeleteActivity";
import { selectUserDetailsState } from "../../../reducers/user-details.reducer";
import { ActivityCardItemContext } from "../ActivityCardItem/ActivityCardItem";

/**
 * Default display
 * Displays actions available for activities
 *
 * Functionality
 * If activity is draft, displays delete action
 * If activity is confirmed and belongs to current user, displays react to activity, edit, delete action
 * If activity is confirmed and belongs to other user, displays mark activity as read, react to activity action
 */
const ActivityActions: React.FC<ActivityActionsProps> = (props) => {
  const context = React.useContext(ActivityCardItemContext);
  const { activityObject, editing, hovered } = context;
  const { displayUnreadItems } = props;
  const { id: activityId, user, confirmed_yn, deleted_yn } = activityObject;

  const userDetails = useSelector(selectUserDetailsState).data;

  const activityBelongsToCurrentUser = user === userDetails.id;
  const displayDrafItems =
    activityBelongsToCurrentUser && !confirmed_yn && !deleted_yn;
  const displayConfirmedActions = confirmed_yn && hovered;

  return (
    <div className="ActivityActions">
      {displayConfirmedActions && (
        <>
          {displayUnreadItems && (
            <MarkActivitiesAsRead id={activityId} type="activity" />
          )}
          {activityBelongsToCurrentUser && (
            <>
              {!editing && <EditActivity />}

              <DeleteActivity />
            </>
          )}
        </>
      )}

      {displayDrafItems && (
        <div className="ActivityCardItemHeader__Actions__Drafts">
          <DeleteActivity />
        </div>
      )}
    </div>
  );
};

export default ActivityActions;
