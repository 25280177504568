import { Dispatch } from "redux";

import { getActivityTypeService } from "../services/activity-types.service";
import { dispatchError } from "../utils/error.util";

export const populateActivityTypes = (activityTypes: ActivityTypeArray) => ({
  type: "POPULATE_ACTIVITY_TYPES",
  activityTypes,
});

export const startPopulateActivityTypes = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({ type: "REQUEST_FETCH_ACTIVITY_TYPES" });
      const response = await getActivityTypeService();
      if (response.status === 200) {
        const responseData: ActivityTypeArray = response.data;
        const activityTypes = responseData.sort((a, b) => {
          return a.title.localeCompare(b.title);
        });
        dispatch({
          type: "REQUEST_FETCH_ACTIVITY_TYPES_SUCCESS",
          activityTypes,
        });
      } else {
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch({ type: "REQUEST_FETCH_ACTIVITY_TYPES_FAILED", error });
      dispatchError({
        e,
        title: "Populate activity types error",
      });
    }
  };
};
