import React from "react";
import { useSelector } from "react-redux";

import { selectWorkTimesState } from "../reducers/work-times.reducer";

export default function useWorkTimesByDate(
  workDate: WorkDayObject["work_date"] = ""
) {
  const { data: workTimesList, loading: workTimesListLoading } = useSelector(
    selectWorkTimesState
  );

  const workTimes = workTimesList.find((item) => item.date === workDate) || {
    date: workDate,
    work_time: 0,
    break_time: 0,
    active_time: 0,
    manual_time: 0,
    processing_time: 0,
    tasks_allocated_time: 0,
    tracked_time: 0,
  };

  const returnValue: [WorkTimes, boolean | undefined] = [
    workTimes,
    workTimesListLoading,
  ];

  return returnValue;
}
