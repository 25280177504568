import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { sumBy } from "lodash";

import useAsyncProjectsWithTodayTasks from "../../../hooks/useAsyncProjectsWithTodayTasks.hook";
import useAsyncTasksInProjectArray from "../../../hooks/useAsyncTasksInProjectArray.hook";
import useWorkDayTaskArrayTotalTime from "../../../hooks/useWorkDayTaskArrayTotalTime.hook";
import useWorkDayTaskObjectTotalTime from "../../../hooks/useWorkDayTaskObjectTotalTime.hook";
import useWorkDayTotalTaskTime from "../../../hooks/useWorkDayTotalTaskTime.hook";
import useWorkTimesByDate from "../../../hooks/useWorkTimesByDate.hook";

import {
  ExclamationCircleFilledIcon,
  LoadingIcon,
} from "../../CustomIcons/CustomIcons.component";
import CheckoutProjectTimeBar from "./CheckoutProjectTimeBar/CheckoutProjectTimeBar";
import CheckoutTaskTimeBar from "./CheckoutTaskTimeBar/CheckoutTaskTimeBar";
import CheckoutUnallocatedTimeBar from "./CheckoutUnallocatedTimeBar/CheckoutUnallocatedTimeBar";

import { selectProjectsState } from "../../../reducers/projects.reducer";
import { getTotalDuration } from "../../../utils/time-conversion.util";
import { CheckoutPanelContext } from "../CheckoutPanel/CheckoutPanel";

import { getTaskTimeService } from "../../../services/task-time.service";

interface CheckoutTimeSummaryProps {
  checkoutTaskId: TaskObject["id"];
}
const CheckoutTimeSummary: React.FC<CheckoutTimeSummaryProps> = ({
  checkoutTaskId,
}) => {
  const { checkoutWorkDay } = React.useContext(CheckoutPanelContext);
  const { id: checkoutWorkDayId = "", work_date: checkoutWorkDayDate } =
    checkoutWorkDay || {};

  const { data: projects, loading: projectsLoading } = useSelector(
    selectProjectsState
  );

  const [
    checkoutDateWorkTimes,
    checkoutDateWorkTimesLoading,
  ] = useWorkTimesByDate(checkoutWorkDayDate);

  const unallocatedTime =
    checkoutDateWorkTimes.work_time -
    checkoutDateWorkTimes.tasks_allocated_time;

  const [
    projectsWithTodayTasks,
    projectsWithTodayTasksLoading,
  ] = useAsyncProjectsWithTodayTasks(projects);

  const projectsWithTodayTasksIds = React.useMemo(() => {
    return !!projectsWithTodayTasks
      ? projectsWithTodayTasks.map(({ id }) => id)
      : [];
  }, [projectsWithTodayTasks]);

  const [
    projectsTotalTaskTime,
    setProjectsTotalTaskTime,
  ] = React.useState<number>();
  const fetchProjectsTaskTime = React.useCallback(async () => {
    const projectsTaskTimes = (await Promise.all(
      projectsWithTodayTasksIds.map(async (projectId) => {
        try {
          const response = await getTaskTimeService({
            project: projectId,
            work_day: checkoutWorkDayId,
          });
          if (response.status === 200) {
            const taskTimes = response.data as TaskTimeArray;
            return sumBy(taskTimes, "seconds_worked");
          }
        } catch (e) {}
      })
    ).then(function (values) {
      // remove undefined values
      return values.filter(function (value) {
        return typeof value !== "undefined";
      });
    })) as number[];
    setProjectsTotalTaskTime(projectsTaskTimes.reduce((a, b) => a + b, 0));
  }, [projectsWithTodayTasksIds, checkoutWorkDayId]);

  React.useEffect(() => {
    fetchProjectsTaskTime();
  }, [fetchProjectsTaskTime]);

  const checkoutTaskTotalTime = useWorkDayTaskObjectTotalTime(
    checkoutWorkDayId,
    checkoutTaskId
  );
  const checkoutWorkDayTotalAllocatedTime = useWorkDayTotalTaskTime(
    checkoutWorkDayId
  );
  const checkoutWorkDayTotalTime =
    checkoutWorkDayTotalAllocatedTime + unallocatedTime;

  const projectsWidth =
    !!checkoutWorkDayTotalTime && !!projectsTotalTaskTime
      ? `${((projectsTotalTaskTime / checkoutWorkDayTotalTime) * 100).toFixed(
          2
        )}%`
      : "0px";
  const checkoutTaskWidth = !!checkoutWorkDayTotalTime
    ? `${((checkoutTaskTotalTime / checkoutWorkDayTotalTime) * 100).toFixed(
        2
      )}%`
    : "0px";
  const checkoutUnallocatedWidth = !!checkoutWorkDayTotalTime
    ? `${((unallocatedTime / checkoutWorkDayTotalTime) * 100).toFixed(2)}%`
    : "0px";
  const isLoading =
    projectsLoading ||
    checkoutDateWorkTimesLoading ||
    projectsWithTodayTasksLoading ||
    projectsTotalTaskTime === undefined;

  return (
    <div className="CheckoutTimeSummary">
      {isLoading ? (
        <LoadingIcon />
      ) : (
        <>
          <div className="CheckoutTimeSummary__TotalTimeContainer">
            <div
              className="CheckoutTimeSummary__TotalTimeContainer__Projects"
              style={{
                width: projectsWidth,
              }}
            >
              {!!projectsWithTodayTasks &&
                projectsWithTodayTasks.length > 0 &&
                projectsWithTodayTasks.map((project, index) => (
                  <CheckoutProjectTimeBar
                    key={index}
                    project={project}
                    projectsTotalTime={
                      projectsTotalTaskTime ? projectsTotalTaskTime : 0
                    }
                  />
                ))}
            </div>
            <CheckoutTaskTimeBar
              checkoutTaskTotalTime={checkoutTaskTotalTime}
              width={checkoutTaskWidth}
            />
            <CheckoutUnallocatedTimeBar
              checkoutWorkDayUnallocatedTime={unallocatedTime}
              width={checkoutUnallocatedWidth}
            />
          </div>
          <div className="CheckoutTimeSummary__Time">
            <div className="CheckoutTimeSummary__Time__Allocated">
              <span>
                Allocated:&nbsp;
                {getTotalDuration(
                  moment.duration(checkoutWorkDayTotalAllocatedTime, "seconds")
                )}
              </span>
            </div>
            <div className="CheckoutTimeSummary__Time__Unallocated">
              {unallocatedTime > 0 && <ExclamationCircleFilledIcon />}
              <span>
                Unallocated:&nbsp;
                {getTotalDuration(moment.duration(unallocatedTime, "seconds"))}
              </span>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CheckoutTimeSummary;
