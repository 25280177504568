import { AxiosResponse } from "axios";

import axios from "../apis/django.instance";

interface GetExternalTrackedTimeServiceData {
  time_tracker: string;
  start_datetime: string;
}

type GetExternalTrackedTimeService = (
  data: GetExternalTrackedTimeServiceData
) => Promise<AxiosResponse<any>>;

interface GetTimeTrackedServiceData {
  user: string;
  time_tracker: string;
  shift_id: string;
}

type GetTimeTrackedService = (
  data: GetTimeTrackedServiceData
) => Promise<AxiosResponse<any>>;

export const getExternalTimeTrackerListService = () => {
  return axios.get("/api/work/time_tracker/");
};

export const getExternalTrackedTimeService: GetExternalTrackedTimeService = (
  data
) => {
  return axios.post("/api/work/external_tracked_time/", data);
};

export const getTimeTrackedService: GetTimeTrackedService = (data) => {
  return axios.get("/api/work/time_tracked_externally/", { params: data });
};
