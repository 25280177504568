import React from "react";

import { selectProjectOfTask } from "../selectors/project.selector";
import { getJoinTaskToUserListService } from "../services/tasks.service";

import ws from "../sockets/websockets";

export default function useAsyncProjectsWithTodayTasks(projects: ProjectArray) {
  const [
    projectsWithTodayTasks,
    setProjectsWithTodayTasks,
  ] = React.useState<ProjectArray>();

  const isLoading = projectsWithTodayTasks === undefined;

  const fetchProjectsWithTodayTasks = React.useCallback(async () => {
    if (projects.length > 0) {
      const projectsWithTodayTasks = (await Promise.all(
        projects.map(async (project) => {
          const { id: projectId } = project;
          if (projectId) {
            try {
              const response = await getJoinTaskToUserListService({
                parent_project: projectId,
                today_yn: true,
              });
              if (response.status === 200 && response.data.length > 0) {
                return project;
              } else {
                throw new Error();
              }
            } catch (e) {}
          }
        })
      ).then(function (values) {
        // remove undefined values
        return values.filter(function (value) {
          return typeof value !== "undefined";
        });
      })) as ProjectArray;

      setProjectsWithTodayTasks(projectsWithTodayTasks);
    }
  }, [projects]);

  React.useEffect(() => {
    fetchProjectsWithTodayTasks();
  }, [fetchProjectsWithTodayTasks]);

  React.useEffect(() => {
    const wsOnMessage = async (event: MessageEvent) => {
      try {
        const messageEventData = JSON.parse(event.data);
        const { event: eventType } = messageEventData;

        if (eventType === "new_task") {
          const eventTask = messageEventData.meta.data;
          if (!!selectProjectOfTask(eventTask)) {
            fetchProjectsWithTodayTasks();
          }
        }
      } catch (e) {}
    };

    ws.addEventListener("message", wsOnMessage);

    return () => {
      ws.removeEventListener("message", wsOnMessage);
    };
  }, [fetchProjectsWithTodayTasks]);

  return [projectsWithTodayTasks, isLoading] as [
    ProjectArray | undefined,
    boolean
  ];
}
