import moment, { Moment } from "moment";
import { range } from "lodash";

export const isContinuously = (
  earlier: Moment,
  later: Moment,
  precision: "millisecond" | "second" | "minute" | "hour" | "day" = "second"
) => {
  return earlier.isSame(later) || earlier.add(1, precision).isSame(later);
};

// aggragate continuously timelines into single one
export const aggregateTimelines = (timelines: Timeline[]): Timeline[] => {
  const sorted = timelines.sort((a, b) =>
    moment(a.start).diff(moment(b.start))
  );
  return sorted.reduce((acc: Timeline[], cur: Timeline) => {
    const clone = [...acc];
    const lastTimeline = clone.pop();
    const rest = clone;
    // merge timelines if they are continuously and have same type
    if (
      lastTimeline &&
      lastTimeline.end &&
      isContinuously(moment(lastTimeline.end), moment(cur.start)) &&
      lastTimeline.type === cur.type
    ) {
      const mergedTimeline = {
        start: lastTimeline.start,
        end: cur.end,
        type: lastTimeline.type,
      };
      return [...rest, mergedTimeline];
    }
    // otherwise append current timeline to the tail of the array
    return [...acc, cur];
  }, []);
};

// add a timeline to timeline array, split the timeline if necessary
export const addTimeline = (
  timelines: Timeline[],
  addedTimeline: Timeline
): Timeline[] => {
  const now = moment();
  return timelines.flatMap((timeline) => {
    // if new added shift is overlap with current timeline and they are diffrent types
    // split the timeline into at most 3 parts.
    const roundedTimelineStart = moment(timeline.start).startOf("minute");
    const roundedTimelineEnd = timeline.end
      ? moment(timeline.end).startOf("minute")
      : now;
    const earlierThanTimeline =
      addedTimeline.end &&
      moment(addedTimeline.end).isBefore(roundedTimelineStart);
    const laterThanTimeline = moment(addedTimeline.start).isAfter(
      roundedTimelineEnd
    );
    // means new added time overlaps with current timeline
    if (
      timeline.type !== addedTimeline.type &&
      !earlierThanTimeline &&
      !laterThanTimeline
    ) {
      const splitedTimelines = [];
      const maxStart = moment(addedTimeline.start).isAfter(roundedTimelineStart)
        ? addedTimeline.start
        : timeline.start;
      const minEnd = addedTimeline.end
        ? moment(addedTimeline.end).isBefore(roundedTimelineEnd)
          ? addedTimeline.end
          : timeline.end
        : timeline.end;
      if (moment(timeline.start).isBefore(moment(maxStart))) {
        const left = {
          start: timeline.start,
          end: maxStart,
          type: timeline.type,
        };
        splitedTimelines.push(left);
      }
      const middle = {
        start: maxStart,
        end: minEnd,
        type: addedTimeline.type,
      };
      splitedTimelines.push(middle);
      if (minEnd && roundedTimelineEnd.isAfter(moment(minEnd))) {
        const right = {
          start: minEnd,
          end: timeline.end,
          type: timeline.type,
        };
        splitedTimelines.push(right);
      }
      return splitedTimelines;
    }
    return timeline;
  });
};

// generate padding time slots between current time line and previous time
export const generateLeftPaddingTimes = (
  current: Timeline,
  prev: Timeline | undefined
): HourTime[] => {
  const currentHour = moment(current.start).hour();
  if (!prev) {
    const diff = moment(current.start).diff(moment(current.start).startOf("hour"));
    return [
      {
        hour: currentHour,
        duration: moment.duration(diff).asSeconds(),
      },
    ];
  }
  const nowOrEnd = prev.end ? moment(prev.end) : moment();
  const prevHour = nowOrEnd.hour();
  if (currentHour === prevHour) {
    const diff = moment(current.start).diff(nowOrEnd);
    return [
      {
        hour: currentHour,
        duration: moment.duration(diff).asSeconds(),
      },
    ];
  }
  return range(prevHour, currentHour + 1).map((hour) => {
    let duration = 0;
    if (hour === prevHour) {
      const diff = moment(nowOrEnd).endOf("hour").diff(nowOrEnd);
      duration = moment.duration(diff).asSeconds();
    } else if (hour === currentHour) {
      const diff = moment(current.start).diff(moment(current.start).startOf("hour"));
      duration = moment.duration(diff).asSeconds();
    } else {
      duration = 3600;
    }
    return {
      hour,
      duration,
    };
  });
};

// get all time slots from given timelines, including padding ghost slots
export const getTimesByHour = (timelines: Timeline[]): HourTime[] => {
  return timelines.flatMap((timeline, index) => {
    const nowOrEnd = timeline.end ? moment(timeline.end) : moment();
    const startHours = moment(timeline.start).hour();
    const endHours = nowOrEnd.hour();
    const leftPadding = generateLeftPaddingTimes(
      timeline,
      timelines[index - 1]
    );
    if (startHours === endHours) {
      const diff = nowOrEnd.diff(moment(timeline.start));
      const duration = moment.duration(diff).asSeconds();
      return [
        ...leftPadding,
        {
          hour: startHours,
          timeline,
          duration,
        },
      ];
    }
    const hourTimes = range(startHours, endHours + 1).map((hour) => {
      let duration = 0;
      if (hour === startHours) {
        const diff = moment(timeline.start).endOf("hour").diff(moment(timeline.start));
        duration = moment.duration(diff).asSeconds();
      } else if (hour === endHours) {
        const diff = nowOrEnd.diff(moment(nowOrEnd).startOf("hour"));
        duration = moment.duration(diff).asSeconds();
      } else {
        duration = 3600;
      }
      return {
        hour,
        timeline,
        duration,
      };
    });
    return [...leftPadding, ...hourTimes];
  });
};
