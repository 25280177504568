import { RootState } from "../store/configureStore";

export interface StatusState {
  data: Status;
  error: string;
  loading: boolean;
}
export const selectStatusState = (rootState: RootState) => rootState.status;
export const statusReducerDefaultState: StatusState = {
  data: {} as Status,
  error: "",
  loading: false,
};

const statusReducer = (
  state = statusReducerDefaultState,
  action: { type: string; status: Status; error: string }
) => {
  switch (action.type) {
    case "REQUEST_FETCH_STATUS":
    case "REQUEST_CREATE_STATUS":
      return {
        ...state,
        error: "",
        loading: true,
      };
    case "REQUEST_FETCH_STATUS_SUCCESS":
    case "REQUEST_CREATE_STATUS_SUCCESS":
      return {
        data: action.status,
        error: "",
        loading: false,
      };
    case "REQUEST_FETCH_STATUS_FAILED":
    case "REQUEST_CREATE_STATUS_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case "SET_STATUS":
      return {
        data: action.status,
        error: "",
        loading: false,
      };
    default:
      return state;
  }
};

export { statusReducer as default };
