import React from "react";

import Button from "../../Button/Button";
import { ListContext } from "../../List/List";

const UnarchiveSectionTasksButton = () => {
  const context = React.useContext(ListContext);
  const { setCounterToReload } = context;

  const handleClick = async () => {
    // TODO 13.11.3 - update with actual endpoint to unarchive tasks in section
    localStorage.removeItem("archived_tasks");
    setCounterToReload((c) => c + 1);
  };

  return (
    <Button
      className="UnarchiveSectionTasksButton"
      onClick={handleClick}
      size="small"
    >
      Unarchive all
    </Button>
  );
};

export default UnarchiveSectionTasksButton;
