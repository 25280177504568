import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Tooltip } from "antd";
import { sumBy } from "lodash";

import useAsyncProjectTaskTimes from "../../../hooks/useAsyncProjectTaskTimes.hook";
import useWorkTimesByDate from "../../../hooks/useWorkTimesByDate.hook";

import { BulletPointIcon } from "../../CustomIcons/CustomIcons.component";

import {
  personalTasksProjectObject,
  projectDefaultColor,
} from "../../../constants/constants";
import { selectCurrentWorkDayState } from "../../../reducers/work-day.reducer";
import { getTotalDuration } from "../../../utils/time-conversion.util";

export const getWidth = (partial: number, total: number) => {
  return `${parseFloat(((partial / total) * 100).toFixed(2))}%`;
};

/**
 * Displays the work time related to the project or unallocated time passed
 * Time is represented as the width of the time bar
 * Color of the bar is according to project (static for Personal Tasks and unallocated time)
 * Hovering on the time bar will display the time in XhYYm, project title, and project color
 */
const ProjectTimeBar: React.FC<ProjectTimeBarProps> = ({
  isUnallocated = false,
  project,
  totalTime,
}) => {
  const {
    data: { work_date: currentWorkDayDate },
  } = useSelector(selectCurrentWorkDayState);

  const [backgroundColor, setBackgroundColor] = React.useState<string>();

  const [
    currentWorkDayDateWorkTimes,
    currentWorkDayDateWorkTimesLoading,
  ] = useWorkTimesByDate(currentWorkDayDate);
  const unallocatedTime =
    currentWorkDayDateWorkTimes.work_time -
    currentWorkDayDateWorkTimes.tasks_allocated_time;

  const { id: projectId, color: projectColor, title: projectTitle } =
    project || {};
  const { value: projectColorValue } = projectColor || {};
  const colorValue = projectColorValue ? projectColorValue : "#FFFFFF7F";

  const [projectTaskTimes, projectTaskTimesLoading] = useAsyncProjectTaskTimes(
    projectId
  );
  const projectTotalTaskTime = projectTaskTimes
    ? sumBy(projectTaskTimes, "seconds_worked")
    : 0;

  const isLoading = isUnallocated
    ? currentWorkDayDateWorkTimesLoading
    : projectTaskTimesLoading;
  const time = isUnallocated ? unallocatedTime : projectTotalTaskTime;
  const width = getWidth(
    isUnallocated ? unallocatedTime : projectTotalTaskTime,
    totalTime
  );

  React.useEffect(() => {
    const isPersonalTasks = projectTitle === personalTasksProjectObject.title;

    if (isPersonalTasks) {
      setBackgroundColor(projectDefaultColor);
    } else if (!isPersonalTasks && colorValue) {
      setBackgroundColor(colorValue);
    }
  }, [projectTitle, colorValue]);

  const text = (
    <span>
      {getTotalDuration(moment.duration(time, "seconds"))}
      <br />
      <BulletPointIcon
        style={{
          color: backgroundColor,
          fontSize: "0.8rem",
        }}
      />
      &nbsp;
      {isUnallocated ? "Unallocated Time" : projectTitle}
    </span>
  );
  return (
    <Tooltip
      placement="topRight"
      overlayClassName="ProjectTimeBar__Tooltip"
      title={text}
    >
      <div
        className="ProjectTimeBar"
        style={{
          backgroundColor,
          width,
        }}
      ></div>
    </Tooltip>
  );
};

export { ProjectTimeBar as default };
