import { RootState } from "../store/configureStore";

// Checkout status of the current date
export interface CheckoutStatusState extends BaseReducerState {
  data: CheckoutStatusArray;
}
export const selectCheckoutStatusState = (rootState: RootState) =>
  rootState.checkoutStatus;
export const checkoutStatusDefaultState: CheckoutStatusState = {
  data: [],
  error: "",
  loading: false,
};
export const checkoutStatusReducer = (
  state = checkoutStatusDefaultState,
  action: { type: string; checkoutStatus: CheckoutStatusArray; error: string }
) => {
  switch (action.type) {
    case "REQUEST_FETCH_CHECKOUT_STATUS":
      return {
        ...state,
        error: "",
        loading: true,
      };
    case "REQUEST_FETCH_CHECKOUT_STATUS_SUCCESS":
      return {
        data: action.checkoutStatus,
        error: "",
        loading: false,
      };
    case "REQUEST_FETCH_CHECKOUT_STATUS_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

// Checkout status of the selected checkout date
export interface CheckoutDateStatusState extends BaseReducerState {
  data: CheckoutStatusArray;
}
export const selectCheckoutDateStatusState = (rootState: RootState) =>
  rootState.checkoutDateStatus;
export const checkoutDateStatusDefaultState: CheckoutDateStatusState = {
  data: [],
  error: "",
  loading: false,
};
export const checkoutDateStatusReducer = (
  state = checkoutDateStatusDefaultState,
  action: {
    type: string;
    checkoutDateStatus: CheckoutStatusArray;
    error: string;
  }
) => {
  switch (action.type) {
    case "REQUEST_FETCH_CHECKOUT_DATE_STATUS":
      return {
        ...state,
        error: "",
        loading: true,
      };
    case "REQUEST_FETCH_CHECKOUT_DATE_STATUS_SUCCESS":
      return {
        data: action.checkoutDateStatus,
        error: "",
        loading: false,
      };
    case "REQUEST_FETCH_CHECKOUT_DATE_STATUS_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

interface CheckoutChecksState extends BaseReducerState {
  data: CheckoutCheckArray;
}
export const selectCheckoutChecksState = (rootState: RootState) =>
  rootState.checkoutChecks;
export const checkoutChecksDefaultState: CheckoutChecksState = {
  data: [],
  error: "",
  loading: false,
};
export const checkoutChecksReducer = (
  state = checkoutChecksDefaultState,
  action: { type: string; checkoutChecks: CheckoutCheckArray; error: string }
) => {
  switch (action.type) {
    case "REQUEST_FETCH_CHECKOUT_CHECKS":
      return {
        ...state,
        error: "",
        loading: true,
      };
    case "REQUEST_FETCH_CHECKOUT_CHECKS_SUCCESS":
      return {
        data: action.checkoutChecks,
        error: "",
        loading: false,
      };
    case "REQUEST_FETCH_CHECKOUT_CHECKS_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export interface CheckoutCheckStatusesState extends BaseReducerState {
  data: CheckoutCheckStatus[];
}
export const selectCheckoutCheckStatusesState = (rootState: RootState) =>
  rootState.checkoutCheckStatuses;
export const checkoutCheckStatusesDefaultState: CheckoutCheckStatusesState = {
  data: [],
  error: "",
  loading: false,
};
export const checkoutCheckStatusesReducer = (
  state = checkoutCheckStatusesDefaultState,
  action: {
    type: string;
    checkoutCheckStatuses: CheckoutCheckStatus[];
    error: string;
  }
) => {
  switch (action.type) {
    case "REQUEST_FETCH_CHECKOUT_CHECK_STATUSES":
      return {
        ...state,
        error: "",
        loading: true,
      };
    case "REQUEST_FETCH_CHECKOUT_CHECK_STATUSES_SUCCESS":
      return {
        data: action.checkoutCheckStatuses,
        error: "",
        loading: false,
      };
    case "REQUEST_FETCH_CHECKOUT_CHECK_STATUSES_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
