import React from "react";
import { useDispatch } from "react-redux";
import { Checkbox, Modal } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import Button from "../../Button/Button";
import {
  CloseIcon,
  ArchiveIcon,
  UnarchiveIcon,
  LoadingIcon,
} from "../../CustomIcons/CustomIcons.component";

import { startUpdateProject } from "../../../actions/projects.action";

export interface ProjectArchiveTogglerProps {
  isButton?: boolean;
  project: ProjectObject;
}

const ProjectArchiveToggler: React.FC<ProjectArchiveTogglerProps> = ({
  isButton = false,
  project,
}) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const { archived_yn: archived } = project;
  const text = archived ? "Unarchive" : "Archive";

  const handleAction = async () => {
    setLoading(true);
    const payload = {
      id: project.id,
      archived_yn: !archived,
    } as ProjectObject;
    dispatch(startUpdateProject(payload));
    setLoading(false);
    setVisible(false);
  };

  return (
    <div className="ProjectArchiveToggler">
      {isButton ? (
        <Button
          className="ProjectArchiveToggler__Button"
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            setVisible(true);
          }}
        >
          {archived ? <UnarchiveIcon /> : <ArchiveIcon />}
          <span>{text}</span>
        </Button>
      ) : (
        <div
          className="ProjectArchiveToggler__Button"
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            setVisible(true);
          }}
        >
          {archived ? <UnarchiveIcon /> : <ArchiveIcon />}
          <span>{text}</span>
        </div>
      )}

      <Modal
        className="ProjectArchiveToggler__Modal"
        centered={true}
        visible={visible}
        onOk={() => {}}
        onCancel={() => setVisible(false)}
        closeIcon={<CloseIcon />}
        footer={null}
      >
        <div
          className="ProjectArchiveToggler__Modal__Container"
          onClick={(e: React.MouseEvent) => e.stopPropagation()}
        >
          <div className="ProjectArchiveToggler__Modal__Container__Message">
            <span className="ProjectArchiveToggler__Modal__Container__Message__Title">
              {text} project
            </span>
            <div className="ProjectArchiveToggler__Modal__Container__Message__Text">
              <span>
                {archived
                  ? `All previous members will have the same access to the project as they had when it was archived.`
                  : `All members will loose access to this project. Information in existing checkout reports including today's checkout reports will remain available.`}
              </span>
              {!archived && (
                <span>
                  <Checkbox
                    checked={checked}
                    onChange={(e: CheckboxChangeEvent) =>
                      setChecked(e.target.checked)
                    }
                  >
                    I understand that all members will loose access to this
                    project.
                  </Checkbox>
                </span>
              )}
            </div>
          </div>
          <div className="ProjectArchiveToggler__Modal__Container__Action">
            <Button onClick={() => setVisible(false)}>Cancel</Button>
            <Button
              type="primary"
              disabled={(!archived && !checked) || loading}
              onClick={handleAction}
            >
              {loading ? <LoadingIcon /> : text}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ProjectArchiveToggler;
