import React from "react";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";

const UnauthorizedView = () => (
  <div className="View">
    <div className="NotFound">
      <Result
        status="403"
        title="403"
        subTitle="Sorry, you are not authorized to access this page."
        extra={
          <Link to="/">
            <Button type="primary">Back Home</Button>
          </Link>
        }
      />
    </div>
  </div>
);

export default UnauthorizedView;
