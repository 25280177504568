import React from "react";
import moment, { Moment } from "moment";

import WorkTimeRecordTable from "../../WorkTime/WorkTimeRecordTable/WorkTimeRecordTable";

import { workDayDateFormat } from "../../../constants/constants";
import { isToday, isYesterday } from "../../../utils/dates.util";

export interface TeamHistoryDateItemProps {
  dateWithTableDataObject: DateWithTableDataObject;
}

const TeamHistoryDateItem: React.FC<TeamHistoryDateItemProps> = ({
  dateWithTableDataObject,
}) => {
  const { date, tableData } = dateWithTableDataObject;

  const [titleText, setTitleText] = React.useState("");

  React.useEffect(() => {
    if (isToday(moment(date, workDayDateFormat))) {
      setTitleText("Today");
    } else if (isYesterday(moment(date, workDayDateFormat))) {
      setTitleText("Yesterday");
    } else {
      setTitleText(moment(date, workDayDateFormat).format("dddd, MMMM D"));
    }
  }, [date]);

  return (
    <div className="TeamHistoryDateItem">
      <div className="TeamHistoryDateItem__Header">
        <div className="TeamHistoryDateItem__Header__Title">
          <span className="TeamHistoryDateItem__Header__Title__Text">
            {titleText}
          </span>
        </div>
      </div>
      <div className="TeamHistoryDateItem__Body">
        {tableData && tableData.records.length > 0 ? (
          <WorkTimeRecordTable
            isLoading={false}
            tableData={tableData}
            type="member"
          />
        ) : (
          <div className="TeamHistoryDateItem__Body__NoWorkDay">
            <span>No worktime information to show here.</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default TeamHistoryDateItem;
