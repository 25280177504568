import { RootState } from "../store/configureStore";

export interface TaskTimeState extends BaseReducerState {
  data: TaskTimeArray;
}
export const selectTaskTimeState = (rootState: RootState) => rootState.taskTime;
export const taskTimeReducerDefaultState: TaskTimeState = {
  data: [],
  error: "",
  loading: false,
};
const taskTimeReducer = (
  state = taskTimeReducerDefaultState,
  action: { type: string; taskTime: TaskTimeArray; error: string }
) => {
  switch (action.type) {
    case "REQUEST_FETCH_TASK_TIME":
    case "REQUEST_CREATE_TASK_TIME":
    case "REQUEST_UPDATE_TASK_TIME":
      return {
        ...state,
        error: "",
        loading: true,
      };
    case "REQUEST_FETCH_TASK_TIME_SUCCESS":
    case "REQUEST_CREATE_TASK_TIME_SUCCESS":
    case "REQUEST_UPDATE_TASK_TIME_SUCCESS":
    case "POPULATE_TASK_TIME":
      return {
        data: action.taskTime,
        error: "",
        loading: false,
      };
    case "REQUEST_FETCH_TASK_TIME_FAILED":
    case "REQUEST_CREATE_TASK_TIME_FAILED":
    case "REQUEST_UPDATE_TASK_TIME_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export { taskTimeReducer as default };
