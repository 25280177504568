import React from "react";
import { Row, Col } from "antd";
import cn from "classnames";
import { ArrowDownIcon } from "../../CustomIcons/CustomIcons.component";
import ProjectInitial from "../../Projects/ProjectInitial/ProjectInitial";

/**
 * ProjectHeader contains the project name, project avatar and activities count
 * It can be toggled when user click the project header.
 * Also, it can be marked as read when user click mark button.
 */
const ProjectHeader = ({
  open = false,
  projectColor,
  projectName,
  count,
  onToggle,
  onMarkAsRead,
}: ProjectHeaderProps) => {
  const handleMarkAsRead = (e: any) => {
    if (e) {
      e.stopPropagation();
    }
    if (onMarkAsRead) {
      onMarkAsRead();
    }
  };
  const handleCount = (e: any) => {
    if (e) {
      e.stopPropagation();
    }
  };
  const project = {
    color: { value: projectColor },
    title: projectName,
  } as ProjectObject;
  return (
    <Row
      className="ProjectHeader"
      justify="space-between"
      align="middle"
      onClick={onToggle}
    >
      <Col>
        <Row align="middle">
          <ProjectInitial project={project} size={32} />
          <span className="ProjectHeader__name">{projectName}</span>
          <ArrowDownIcon
            className={cn("ProjectHeader__arrow", {
              ProjectHeader__rotate: !open,
            })}
          />
        </Row>
      </Col>
      <Col>
        <Row align="middle">
          <div className="ProjectHeader__badge" onClick={handleCount}>
            {count}
          </div>
          <div className="ProjectHeader__mark" onClick={handleMarkAsRead}>
            Mark as read
          </div>
        </Row>
      </Col>
    </Row>
  );
};

export default ProjectHeader;
