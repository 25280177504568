import React from "react";
import { Input } from "antd";

import Modal from "../../General/Modal/Modal";

export interface WorkspaceMemberEditEmailModalProps {
  confirmCallback: (newEmail: string) => void;
  cancelCallback: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  email: string;
  visible: boolean;
}

const WorkspaceMemberEditEmailModal: React.FC<WorkspaceMemberEditEmailModalProps> = ({
  confirmCallback,
  cancelCallback,
  email,
  visible,
}) => {
  const [newEmail, setNewEmail] = React.useState("");

  React.useEffect(() => {
    setNewEmail(email);
  }, [email]);

  return (
    <Modal
      className="WorkspaceMemberEditEmailModal"
      title="Change member’s email"
      prefixText="The member will be notified at the current and new email address."
      centered={true}
      visible={visible}
      onCancel={cancelCallback}
      onOk={() => confirmCallback(newEmail)}
      okButtonProps={{
        type: "primary",
        disabled: newEmail === "",
      }}
      okText="Save changes"
    >
      <Input
        placeholder="Enter new email"
        value={newEmail}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setNewEmail(e.target.value)
        }
      />
    </Modal>
  );
};

export default WorkspaceMemberEditEmailModal;
