import axios from "../apis/django.instance";

export const getStatusHistoryService: GetStatusHistoryService = (data) => {
  const { end_date, start_date, work_shift } = data || {};
  const params = {
    from_date: start_date,
    to_date: end_date,
    work_shift,
  };
  return axios.get("/api/work/status_history/", { params }); // must be in this format in order to mock implementation in unit test
};

export const getTotalStatusDurationService: GetTotalStatusDurationService = (
  data
) => {
  return axios.post("/api/work/total_status_duration/", data); // must be in this format in order to mock implementation in unit test
};

export const getCurrentStatusDurationService = () => {
  return axios.get("api/work/current_status_duration/"); // must be in this format in order to mock implementation in unit test
};
