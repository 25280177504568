import React from "react";
import { useSelector } from "react-redux";

import LoginForm from "../../modules/Login/LoginForm/LoginForm.component";
import GlobalSystemMessage from "../../modules/General/GlobalSystemMessage/GlobalSystemMessage";
import { selectErrorState } from "../../reducers/error.reducer";

const LoginView = () => {
  const error = useSelector(selectErrorState);
  return (
    <div className="View">
      {error && <GlobalSystemMessage />}
      <div className="LoginView">
        <LoginForm />
      </div>
    </div>
  );
};

export { LoginView as default };
