import React from "react";
import moment from "moment";
import { LoadingIcon } from "../../CustomIcons/CustomIcons.component";

const StartWorkTimeDisplay = ({ time }: StartWorkTimeDisplayProps) => {
  return (
    <span className="StartWorkTimeDisplay">
      {time === undefined ? (
        <LoadingIcon style={{ fontSize: "16rem" }} />
      ) : (
        <span className="StartWorkTimeDisplay__Time">
          {moment(time).format("HH:mm")}{" "}
        </span>
      )}
    </span>
  );
};

export { StartWorkTimeDisplay as default };
