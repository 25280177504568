import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { selectUserDetailsState } from "../reducers/user-details.reducer";

export default function useCheckBetaVersion() {
  const navigate = useNavigate();

  const {
    data: { has_beta_version_access_yn },
  } = useSelector(selectUserDetailsState);

  const { search } = useLocation();
  const beta = new URLSearchParams(search).get("beta");

  if (beta === "on" && has_beta_version_access_yn === false) {
    navigate("/unauthorized");
  }

  return beta === "on" && !!has_beta_version_access_yn;
}
