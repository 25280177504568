import React from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Popover } from "antd";

import { SummaryOfTheDayContext } from "../../Checkout/SummaryOfTheDay/SummaryOfTheDay";

import { AddIcon } from "../../CustomIcons/CustomIcons.component";
import SelectTaskDesinationPopoverContent, {
  ActionClickCallbackParams,
} from "../SelectTaskDesinationPopoverContent/SelectTaskDesinationPopoverContent";

import { startCreateTask } from "../../../actions/tasks.action";
import { menuStaticKeys } from "../../../constants/constants";
import { CreateTaskRecordServiceData } from "../../../services/tasks.service";
import { CheckoutPanelContext } from "../../Checkout/CheckoutPanel/CheckoutPanel";

const CreateNewTaskButton: React.FC<CreateNewTaskButtonProps> = ({
  panelType,
  searchText,
  setSearchText,
}) => {
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const { summaryType } = React.useContext(SummaryOfTheDayContext);
  const { checkoutWorkDay } = React.useContext(CheckoutPanelContext);

  const [displayPopover, setDisplayPopover] = React.useState(false);

  const { id: checkoutWorkDayId } = checkoutWorkDay || {};

  const createTask = ({ selectedSection }: ActionClickCallbackParams) => {
    const payload: CreateTaskRecordServiceData = {
      section: selectedSection!.id,
      title: searchText,
      url: "Sample URL",
      description: "",
    };
    if (pathname === menuStaticKeys.TODAY) {
      payload.today_yn = true;
    } else if (pathname === menuStaticKeys.STARRED) {
      payload.starred_yn = true;
    } else if (pathname === menuStaticKeys.NEXT_SHIFT) {
      payload.next_shift_yn = true;
    }
    dispatch(
      startCreateTask(
        payload,
        summaryType === "checkout",
        !!payload.today_yn,
        checkoutWorkDayId
      )
    );
    setSearchText("");
    setDisplayPopover(false);
  };

  const cancelClickCallback = () => {
    setDisplayPopover(false);
  };

  return (
    <Popover
      content={
        <SelectTaskDesinationPopoverContent
          action={"create"}
          actionClickCallback={createTask}
          cancelClickCallback={cancelClickCallback}
          panelType={panelType}
          searchText={searchText}
        />
      }
      placement="bottomRight"
      overlayClassName="CreateNewTaskButton__Popover"
      visible={displayPopover}
    >
      <div
        className="CreateNewTaskButton"
        onClick={() => setDisplayPopover((c) => !c)}
      >
        <AddIcon />
        <span className="CreateNewTaskButton__Text">Create new task</span>
      </div>
    </Popover>
  );
};

export default CreateNewTaskButton;
