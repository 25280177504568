/**
 * https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
 */
import React, { useEffect } from "react";

const useScrollBottomCallback = (
  callback: Function,
  dependencies: any[],
  refObject?: React.MutableRefObject<any>
) => {
  useEffect(() => {
    const { current: element } = refObject || {};

    /**
     * Run callback if scrolled to bottom of reference
     */
    const scrollHandler = (e: any) => {
      if (element) {
        const { scrollTop, scrollHeight, clientHeight } = element;

        if (Math.round(scrollTop + clientHeight) === scrollHeight) {
          callback();
        }
      }
    };

    // Bind the event listener
    if (element) element.addEventListener("scroll", scrollHandler);

    return () => {
      // Unbind the event listener on clean up
      if (element) element.removeEventListener("scroll", scrollHandler);
    };
  }, [refObject, callback, ...dependencies]);
};

export { useScrollBottomCallback as default };
