import React from "react";
import { Tooltip } from "antd";
import moment from "moment";

import { getTotalDuration } from "../../../../utils/time-conversion.util";
interface CheckoutUnallocatedTimeBarProps {
  checkoutWorkDayUnallocatedTime: number;
  width: string;
}
const CheckoutUnallocatedTimeBar: React.FC<CheckoutUnallocatedTimeBarProps> = ({
  checkoutWorkDayUnallocatedTime,
  width,
}) => {
  const tooltip = (
    <div className="CheckoutTimeBarTooltip">
      <span className="CheckoutTimeBarTooltip__Title">Unallocated</span>
      <span>
        {getTotalDuration(
          moment.duration(checkoutWorkDayUnallocatedTime, "seconds")
        )}
      </span>
    </div>
  );
  return (
    <Tooltip title={tooltip} getPopupContainer={(trigger: any) => trigger}>
      <div className="CheckoutUnallocatedTimeBar" style={{ width }}></div>
    </Tooltip>
  );
};

export default CheckoutUnallocatedTimeBar;
