import React from "react";
import { Checkbox, Steps } from "antd";

import Button from "../../../Button/Button";
import {
  DeleteIcon,
  SuccessCircleFilledIcon,
} from "../../../CustomIcons/CustomIcons.component";
import Modal from "../../../General/Modal/Modal";
import SearchTaskCardItem from "../../SearchTaskCardItem/SearchTaskCardItem";
import TaskSearchBar from "../../TaskSearchBar/TaskSearchBar";

import {
  contentSelectorDefaultValue,
  contentSelectorOptions,
} from "../../../../constants/tasks.constant";
import { CheckboxValueType } from "antd/lib/checkbox/Group";

const { Step } = Steps;
const { Group } = Checkbox;

export interface MergeTaskModalHandleOkParams {
  selectedSourceTask?: TaskJoinedToUserAndScheduledObject;
  contentSelected: CheckboxValueType[];
  destinationTask?: TaskJoinedToUserAndScheduledObject;
}
export interface MergeTaskModalProps {
  handleCancel: Function;
  handleOk: (data: MergeTaskModalHandleOkParams) => void;
  panelType: PanelType;
  sourceTask: TaskJoinedToUserAndScheduledObject;
  visible: boolean;
}

export interface MergeTaskModalContext {
  selectedSourceTask?: TaskJoinedToUserAndScheduledObject;
  destinationTask?: TaskJoinedToUserAndScheduledObject;
}
export const MergeTaskModalContext = React.createContext<MergeTaskModalContext>(
  {} as MergeTaskModalContext
);

const MergeTaskModal: React.FC<MergeTaskModalProps> = ({
  handleCancel,
  handleOk,
  panelType,
  sourceTask,
  visible,
}) => {
  const [currentStep, setCurrentStep] = React.useState(0);
  const [selectedSourceTask, setSelectedSourceTask] = React.useState<
    TaskJoinedToUserAndScheduledObject
  >();
  const [contentSelected, setContentSelected] = React.useState<
    CheckboxValueType[]
  >(contentSelectorDefaultValue);
  const [destinationTask, setDestinationTask] = React.useState<
    TaskJoinedToUserAndScheduledObject
  >();
  const [missingRequiredStep, setMissingRequiredStep] = React.useState<
    number
  >();

  const handleStepChange = (step: number) => {
    setCurrentStep(step);
  };

  const handleContentSelectorChange = (checkedValue: CheckboxValueType[]) => {
    setContentSelected(checkedValue);
  };

  const handleClickMerge = React.useCallback(() => {
    const params: MergeTaskModalHandleOkParams = {
      selectedSourceTask,
      contentSelected,
      destinationTask,
    };
    handleOk(params);
  }, [handleOk, selectedSourceTask, contentSelected, destinationTask]);

  React.useEffect(() => {
    setSelectedSourceTask(sourceTask);
  }, [sourceTask]);

  React.useEffect(() => {
    if (!selectedSourceTask) {
      setMissingRequiredStep(1);
    } else if (!destinationTask) {
      setMissingRequiredStep(3);
    } else {
      setMissingRequiredStep(undefined);
    }
  }, [selectedSourceTask, destinationTask]);

  const MissingStepInstructions: React.FC = () => (
    <div className="MergeTaskModal__Instructions">
      Complete step {missingRequiredStep} to continue
    </div>
  );

  return (
    <MergeTaskModalContext.Provider
      value={{
        selectedSourceTask,
        destinationTask,
      }}
    >
      <Modal
        className="MergeTaskModal"
        title="Merge tasks"
        prefixText="Merging will move content from a source task into a target task and
        then delete the source task."
        centered={true}
        visible={visible}
        maskClosable={false}
        onOk={(e: React.MouseEvent<HTMLElement, MouseEvent>) => handleOk}
        onCancel={(e: React.MouseEvent<HTMLElement, MouseEvent>) =>
          handleCancel
        }
        footer={null}
        width={640}
        zIndex={1050}
      >
        <div className="MergeTaskModal__Steps">
          <Steps
            current={currentStep}
            onChange={handleStepChange}
            direction="vertical"
          >
            <Step
              title={
                <>
                  <span>
                    Content from this task will be moved to the target task
                  </span>
                  {selectedSourceTask && (
                    <div
                      className="MergeTaskModal__Steps__RemoveTask"
                      onClick={() => setSelectedSourceTask(undefined)}
                    >
                      <span>Remove</span>
                    </div>
                  )}
                </>
              }
              description={
                selectedSourceTask ? (
                  <SearchTaskCardItem task={selectedSourceTask} />
                ) : (
                  <TaskSearchBar
                    isMerge={true}
                    panelType={panelType}
                    handleTaskSelect={(
                      task: TaskJoinedToUserAndScheduledObject
                    ) => setSelectedSourceTask(task)}
                  />
                )
              }
            />

            <Step
              title="Select the content which will be moved"
              description={
                <div className="MergeTaskModal__Steps__ContentSelector">
                  <Group
                    options={contentSelectorOptions}
                    defaultValue={contentSelectorDefaultValue}
                    onChange={handleContentSelectorChange}
                  />
                </div>
              }
            />

            <Step
              title={
                <>
                  <span>
                    Select the target task into which the content will be moved
                  </span>
                  {destinationTask && (
                    <div
                      className="MergeTaskModal__Steps__RemoveTask"
                      onClick={() => setDestinationTask(undefined)}
                    >
                      <span>Remove</span>
                    </div>
                  )}
                </>
              }
              disabled={!selectedSourceTask}
              description={(() => {
                if (!selectedSourceTask) {
                  return <MissingStepInstructions />;
                } else if (destinationTask) {
                  return <SearchTaskCardItem task={destinationTask} />;
                } else {
                  return (
                    <TaskSearchBar
                      isMerge={true}
                      panelType={panelType}
                      handleTaskSelect={(
                        task: TaskJoinedToUserAndScheduledObject
                      ) => setDestinationTask(task)}
                    />
                  );
                }
              })()}
            />

            <Step
              title="Check the result"
              disabled={!!missingRequiredStep}
              description={
                selectedSourceTask && destinationTask ? (
                  <>
                    <div className="MergeTaskModal__Steps__ResultTask MergeTaskModal__Steps__ResultTask--Deleted">
                      <div className="MergeTaskModal__Steps__ResultTask__Title">
                        <DeleteIcon />
                        <span>This task will be deleted</span>
                      </div>
                      <SearchTaskCardItem task={selectedSourceTask} />
                    </div>

                    <div className="MergeTaskModal__Steps__ResultTask MergeTaskModal__Steps__ResultTask--New">
                      <div className="MergeTaskModal__Steps__ResultTask__Title">
                        <SuccessCircleFilledIcon />
                        <span>This task will be the new task</span>
                      </div>
                      <SearchTaskCardItem task={destinationTask!} />
                    </div>
                  </>
                ) : (
                  <MissingStepInstructions />
                )
              }
            />

            <Step
              title="Confirm tasks merge"
              disabled={!!missingRequiredStep}
              description={
                missingRequiredStep ? (
                  <MissingStepInstructions />
                ) : (
                  <>
                    <span>Heads up! This action cannot be undone.</span>
                    <div className="MergeTaskModal__Steps__Actions">
                      <Button type="primary" onClick={handleClickMerge}>
                        Merge
                      </Button>
                      <Button onClick={() => handleCancel()}>Cancel</Button>
                    </div>
                  </>
                )
              }
            />
          </Steps>
        </div>
      </Modal>
    </MergeTaskModalContext.Provider>
  );
};

export default MergeTaskModal;
