import React from "react";

/**
 * Default Display
 * Popover content displayed when CheckoutStatusIcon is hovered
 *
 * Functionality
 * Displays checkout_failed_message if failed
 */

const CheckoutStatusIconPopoverContent: React.FC<CheckoutStatusIconPopoverContentProps> = ({
  checkoutChecks,
  checkoutStatusArray,
}) => {
  const checkoutCheckStatusArrayJsx =
    checkoutStatusArray &&
    checkoutStatusArray.map(
      ({ issue_json, n_checkout_items, passed_yn }, index) => {
        const { checkout_failed_message } = issue_json || {};

        if (n_checkout_items > 0 && passed_yn === false) {
          return (
            <div
              className="CheckoutStatusIconPopoverContent__CheckoutCheckItem"
              key={index}
            >
              <span>{checkout_failed_message}</span>
            </div>
          );
        }
      }
    );

  return (
    <div className="CheckoutStatusIconPopoverContent">
      <div className="CheckoutStatusIconPopoverContent__Title">
        <span>Before leaving the office review your Checkout status:</span>
      </div>
      {checkoutCheckStatusArrayJsx}
    </div>
  );
};

export default CheckoutStatusIconPopoverContent;
