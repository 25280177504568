import { useEffect, useRef } from "react";

const usePrevious = (value: any) => {
  const ref: any = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export { usePrevious as default };
