import { AxiosResponse } from "axios";

import axios from "../apis/django.instance";

interface GetActivityListServiceData {
  user?: boolean;
  task?: TaskObject["id"];
  team?: boolean;
  isNew?: boolean;
  confirmed?: boolean;
  deleted?: boolean;
}
type GetActivityListService = (
  data: GetActivityListServiceData
) => Promise<AxiosResponse<ActivityArray>>;
export const getActivityListService: GetActivityListService = (data) => {
  const { confirmed, deleted, isNew, task, team, user } = data;
  return axios.get("/api/activities/activity/", {
    params: {
      confirmed,
      deleted,
      new: isNew,
      task,
      team,
      user,
    },
  }); // must be in this format in order to mock implementation in unit test
};
export const fetchActivityRecordService = (
  activityId: ActivityObject["id"]
) => {
  return axios.get(`/api/activities/activity/${activityId}/`);
};

export const addActivityService = (
  data: Omit<ActivityObject, "id" | "loading">
) => {
  return axios.post("/api/activities/activity/", data); // must be in this format in order to mock implementation in unit test
};
export const updateActivityService = (data: ActivityObject) => {
  const { id, ...rest } = data;
  return axios.put(`/api/activities/activity/${id}/`, rest); // must be in this format in order to mock implementation in unit test
};

export const getActivityReadStatusService: GetActivityReadStatusService = (
  activity_id
) => {
  return axios.get(`/api/activities/check_read_status/`, {
    params: {
      activity_id,
    },
  }); // must be in this format in order to mock implementation in unit test
};

export const markAllAsUnreadService: MarkAllAsUnreadService = (task_id) => {
  return axios.post(`/api/activities/mark_all_unread_as_read/`, { task_id }); // must be in this format in order to mock implementation in unit test
};

export interface GetUserActivitiesServiceData {
  fromDate?: string;
  toDate?: string;
  userId: string;
  read?: boolean;
}
export type GetUserActivitiesService = (
  data: GetUserActivitiesServiceData
) => Promise<AxiosResponse<ActivitySummaryArray>>;
export const getUserActivitiesService: GetUserActivitiesService = ({
  fromDate,
  toDate,
  userId,
  read,
}) => {
  const params = read
    ? {
        from_date: fromDate,
        to_date: toDate,
        read,
      }
    : {
        from_date: fromDate,
        to_date: toDate,
      };
  return axios.get(`/api/activities/user_activity/${userId}/`, { params });
};
