import { RootState } from "../store/configureStore";

export interface LabelsState extends BaseReducerState {
  data: Label[];
}
export const selectLabelsState = (rootState: RootState) => rootState.labels;
export const labelsReducerDefaultState: LabelsState = {
  data: [],
  error: "",
  loading: false,
};
const labelsReducer = (
  state = labelsReducerDefaultState,
  action: { type: string; payload: Label[]; error: string }
) => {
  switch (action.type) {
    case "REQUEST_LABELS":
      return {
        ...state,
        error: "",
        loading: true,
      };
    case "REQUEST_LABELS_SUCCESS":
      return {
        data: action.payload,
        error: "",
        loading: false,
      };
    case "REQUEST_LABELS_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export { labelsReducer as default };
