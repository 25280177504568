import React from "react";
import { useSelector } from "react-redux";

import {
  selectScheduledTasksState,
  selectUserTasksState,
} from "../reducers/tasks.reducer";
import { fetchTaskRecordService } from "../services/tasks.service";
import { dispatchError } from "../utils/error.util";
import {
  joinScheduledTaskAndTaskJoinedToUserTaskObject,
  joinTaskObjectToUserTaskObject,
} from "../utils/tasks-consolidate.util";

export interface Params {
  sectionId: SectionObject["id"];
  archived?: boolean;
}
export default function useAsyncTaskById(taskId?: TaskObject["id"]) {
  // TODO: update to not use global user tasks state
  const { data: userTasks, loading: userTasksLoading } = useSelector(
    selectUserTasksState
  );
  const { data: scheduledTasks, loading: scheduledTasksLoading } = useSelector(
    selectScheduledTasksState
  );

  const [loading, setLoading] = React.useState(false);
  const [task, setTask] = React.useState<TaskObject>();
  const [
    consolidatedTask,
    setConsolidatedTask,
  ] = React.useState<TaskJoinedToUserAndScheduledObject>();

  const isLoading = loading || scheduledTasksLoading || userTasksLoading;

  const fetchTaskById = React.useCallback(async () => {
    if (taskId) {
      try {
        setLoading(true);
        const response = await fetchTaskRecordService(taskId);
        if (response.status === 200) {
          setTask({ ...response.data, loading: false } as TaskObject);
          setLoading(false);
        } else {
          throw new Error();
        }
      } catch (e) {
        setLoading(false);
        dispatchError({
          e,
          title: "Fetch task by id error",
        });
      }
    }
  }, [taskId]);

  React.useEffect(() => {
    fetchTaskById();
  }, [fetchTaskById]);

  React.useEffect(() => {
    if (task && !scheduledTasksLoading && !userTasksLoading) {
      const userTask =
        userTasks.find(({ task }) => task.id === taskId) ||
        ({} as JoinTaskToUserObject);
      const scheduledTask =
        userTask &&
        scheduledTasks.find(
          ({ task_id, join_task_to_user }) =>
            task_id === taskId && join_task_to_user === userTask.id
        );
      setConsolidatedTask(
        joinScheduledTaskAndTaskJoinedToUserTaskObject(
          joinTaskObjectToUserTaskObject(task, userTask),
          scheduledTask
        )
      );
    }
  }, [
    task,
    taskId,
    scheduledTasks,
    scheduledTasksLoading,
    userTasks,
    userTasksLoading,
  ]);

  return [consolidatedTask, isLoading] as [
    TaskJoinedToUserAndScheduledObject | undefined,
    boolean
  ];
}
