import { Dispatch } from "redux";

import {
  TrackingStatus,
  TrackingTask,
} from "../reducers/task-time-tracking.reducer";

export const setTrackingTask = (trackingTask: TrackingTask) => ({
  type: "SET_TRACKING_TASK",
  trackingTask,
});

export const setTrackingStatus = (trackingStatus: TrackingStatus) => ({
  type: "SET_TRACKING_STATUS",
  trackingStatus,
});

export const setLastTrackedTask = (lastTrackedTask: TrackingTask) => ({
  type: "SET_LAST_TRACKED_TASK",
  lastTrackedTask,
});

export const startSetTrackingTask = () => {
  return async (dispatch: Dispatch) => {
    return await new Promise((resolve) =>
      setTimeout(() => {
        const trackingTaskStringified =
          localStorage.getItem("trackingTask") || "";
        const trackingTask: TrackingTask = trackingTaskStringified
          ? JSON.parse(trackingTaskStringified)
          : {};
        dispatch(setTrackingTask(trackingTask));
        resolve(undefined);
      }, 1000)
    );
    /**
     * TODO 13.2.1 add endpoint call to fetch tracking task
     */
  };
};

export const startSetTrackingStatus = () => {
  return async (dispatch: Dispatch) => {
    return await new Promise((resolve) =>
      setTimeout(() => {
        if (localStorage.getItem("trackingStatus"))
          dispatch(
            setTrackingStatus(
              localStorage.getItem("trackingStatus") as TrackingStatus
            )
          );
        resolve(undefined);
      }, 1000)
    );
    /**
     * TODO 13.2.1 add endpoint call to fetch tracking status
     */
  };
};

export const startSetLastTrackedTask = () => {
  return async (dispatch: Dispatch) => {
    return await new Promise((resolve) =>
      setTimeout(() => {
        const lastTrackedTaskStringified =
          localStorage.getItem("lastTrackedTask") || "";
        const lastTrackedTask: TrackingTask = lastTrackedTaskStringified
          ? JSON.parse(lastTrackedTaskStringified)
          : {};
        dispatch(setLastTrackedTask(lastTrackedTask));
        resolve(undefined);
      }, 1000)
    );
    /**
     * TODO 13.2.1 add endpoint call to fetch last tracked task
     */
  };
};
