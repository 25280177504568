import { dispatchError } from "../utils/error.util";

let wsProtocol: string;
if (window.location.protocol == "https:") {
  wsProtocol = "wss://";
} else {
  wsProtocol = "ws://";
}

let socketPath = `${wsProtocol}${process.env.REACT_APP_HOST}/ws/work/user_status/`;

let ws = new WebSocket(socketPath);

ws.onopen = (event) => {
  console.log("WebSocket connection created.");
};

ws.onerror = (e) => {
  dispatchError({
    e,
    title: "WebSocket error",
  });
};

export default ws;
