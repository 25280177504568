import React from "react";
import { Tooltip } from "antd";
import moment from "moment";

import {
  NoteIcon,
  InfoCircleOutlinedIcon,
} from "../../../CustomIcons/CustomIcons.component";
import { getTotalDuration } from "../../../../utils/time-conversion.util";
import useEllipsisOnOverflow from "../../../../hooks/useEllipsisOnOverflow.hook";

/**
 *
 * The TimeSlot is the component that displays all slots row by row.
 * The text of a slot will be ellipsised if the width of the row is not enough
 * for all time slot information.
 * The sub-timeslots tooltip will show if the timeSlot object contains a children slots.
 * We can also decorate the time slot with different colors and icons.
 */
const TimeSlot: React.FC<TimeSlotProps> = ({ timeSlot }) => {
  const [ref, ellipsis] = useEllipsisOnOverflow(timeSlot.text);

  const durationInSeconds = timeSlot.children
    ? timeSlot.children.reduce(
        (acc, cur) => acc + (cur.durationInSeconds || 0),
        0
      )
    : timeSlot.durationInSeconds;

  const infoToolTip = timeSlot.children && (
    <div className="TimeSlot__Tip">
      {timeSlot.children.map((item) => (
        <div key={item.title} className="TimeSlot__TipItem">
          <div>{item.title}</div>
          <div style={{ color: timeSlot.textColor || item.color }}>
            {getTotalDuration(
              moment.duration(item.durationInSeconds || 0, "seconds")
            )}
          </div>
        </div>
      ))}
    </div>
  );

  const infoContent = timeSlot.children && (
    <Tooltip title={infoToolTip}>
      <InfoCircleOutlinedIcon className="TimeSlot__Icon-info" />
    </Tooltip>
  );

  return (
    <div className="TimeSlot">
      <span className="TimeSlot__Title">{timeSlot.title}</span>
      <div className="TimeSlot__Info">
        {timeSlot.hasNote && <NoteIcon className="TimeSlot__Icon-note" />}
        {timeSlot.hasWarning && <NoteIcon className="TimeSlot__Icon-warning" />}
        {infoContent}
        {!!timeSlot.text && ellipsis ? (
          <Tooltip
            overlayInnerStyle={{
              maxWidth: "200px",
              whiteSpace: "pre-wrap",
              fontSize: "12px",
              lineHeight: "17px",
            }}
            title={timeSlot.text}
          >
            <span
              ref={ref}
              className="TimeSlot__Time"
              style={{
                color:
                  timeSlot.textColor || timeSlot.borderColor || timeSlot.color,
              }}
            >
              {timeSlot.text}
            </span>
          </Tooltip>
        ) : (
          <span
            ref={ref}
            className="TimeSlot__Time"
            style={{
              color:
                timeSlot.textColor || timeSlot.borderColor || timeSlot.color,
            }}
          >
            {timeSlot.text ||
              getTotalDuration(
                moment.duration(durationInSeconds || 0, "seconds")
              )}
          </span>
        )}
      </div>
    </div>
  );
};

export default TimeSlot;
