import React from "react";

import { ActivityTabContentContext } from "../ActivityTabContent/ActivityTabContent";

import { DraftIcon } from "../../CustomIcons/CustomIcons.component";
import ActivityCardItem from "../../Activities/ActivityCardItem/ActivityCardItem";

/**
 * Default display
 * Displays list of all draft activities from the team of the current user and task that have not been confirmed
 * Displays "You don't have team drafts." if list is empty
 */
const TeamDrafts: React.FC = () => {
  const { teamDrafts } = React.useContext(ActivityTabContentContext);

  return (
    <div className="TeamDrafts">
      <div className="TeamDrafts__Header">
        <div className="TeamDrafts__Header__Title">
          <DraftIcon className="TeamDrafts__Header__Title__DraftIcon" />
          <span>Team drafts</span>
        </div>
      </div>

      <div className="TeamDrafts__Body">
        {!!teamDrafts && teamDrafts.length > 0 ? (
          teamDrafts.map((teamUnconfirmedActivity, index) => {
            return (
              <ActivityCardItem
                activityObject={teamUnconfirmedActivity}
                isTeamDrafts={true}
                key={index}
              />
            );
          })
        ) : (
          <div className="TeamDrafts__Body__NoDrafts">
            <span>You don't have team drafts.</span>
          </div>
        )}
      </div>
    </div>
  );
};

export { TeamDrafts as default };
