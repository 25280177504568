import { Dispatch } from "redux";
import { AxiosError } from "axios";

import { getStatusTypesService } from "../services/status.service";
import { dispatchError } from "../utils/error.util";

export const requestFetchStatusTypes = () => ({
  type: "REQUEST_FETCH_STATUS_TYPES",
});
export const requestFetchStatusTypesSuccess = (
  statusTypes: StatusTypeArray
) => ({
  type: "REQUEST_FETCH_STATUS_TYPES_SUCCESS",
  statusTypes,
});
export const requestFetchStatusTypesFailed = (error: string) => ({
  type: "REQUEST_FETCH_STATUS_TYPES_FAILED",
  error,
});
export const startPopulateStatusTypes = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(requestFetchStatusTypes());
      const response = await getStatusTypesService();
      if (response.status === 200) {
        // map response to match StatusTypeArray structure
        const statusTypes: StatusTypeArray = response.data.map(
          (type: string[]) => ({
            id: type[0],
            title: type[1],
          })
        );
        dispatch(requestFetchStatusTypesSuccess(statusTypes));
      } else {
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestFetchStatusTypesFailed(error));
      dispatchError({
        e,
        title: "Populate status types error",
      });
    }
  };
};
