import { Dispatch } from "redux";

import { getLabelsService } from "../services/labels.service";
import { dispatchError } from "../utils/error.util";

export const requestLabels = () => ({
  type: "REQUEST_LABELS",
});

export const requestLabelsSuccess = (labels: Label[]) => ({
  type: "REQUEST_LABELS_SUCCESS",
  payload: labels,
});

export const requestLabelsFailed = (error: string) => ({
  type: "REQUEST_LABELS_FAILED",
  error,
});

export const startPopulateLabels = () => {
  return async (dispatch: Dispatch) => {
    dispatch(requestLabels());
    try {
      const response = await getLabelsService();
      if (response.status === 200) {
        const labels = response.data;
        dispatch(requestLabelsSuccess(labels));
        return labels;
      } else {
        const error = "getLabelsService status is not 200";
        dispatch(requestLabelsFailed(error));
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestLabelsFailed(error));
      dispatchError({
        e,
        title: "Populate labels error",
      });
    }
  };
};
