interface Metadata {
  value: any;
}
type MetadataObj = { [key: string]: Metadata };
const isNotEmptyObject = (object: MetadataObj) => {
  return Object.keys(object).length > 0 && object.constructor === Object;
};

export { isNotEmptyObject };

export const getObjDiff = (obj1: any, obj2: any, exclude?: any) => {
  var r = {};

  if (!exclude) exclude = [];

  for (var prop in obj1) {
    if (obj1.hasOwnProperty(prop) && prop != "__proto__") {
      if (exclude.indexOf(obj1[prop]) == -1) {
        // check if obj2 has prop

        // @ts-expect-error
        if (!obj2.hasOwnProperty(prop)) r[prop] = obj1[prop];
        // check if prop is object and
        // NOT a JavaScript engine object (i.e. __proto__), if so, recursive diff
        else if (obj1[prop] === Object(obj1[prop])) {
          var difference = getObjDiff(obj1[prop], obj2[prop]);
          // @ts-expect-error
          if (Object.keys(difference).length > 0) r[prop] = difference;
        }

        // check if obj1 and obj2 are equal
        else if (obj1[prop] !== obj2[prop]) {
          // @ts-expect-error
          if (obj1[prop] === undefined) r[prop] = "undefined";
          // @ts-expect-error
          if (obj1[prop] === null) r[prop] = null;
          // @ts-expect-error
          else if (typeof obj1[prop] === "function") r[prop] = "function";
          // @ts-expect-error
          else if (typeof obj1[prop] === "object") r[prop] = "object";
          // @ts-expect-error
          else r[prop] = obj1[prop];
        }
      }
    }
  }

  return r;
};
