import React from "react";
import { useSelector } from "react-redux";

import useRedirect from "../../../hooks/useRedirect.hook";

import Button from "../../../modules/Button/Button";
import CustomAvatar from "../../../modules/CustomAvatar/CustomAvatar";
import { HomeIcon } from "../../../modules/CustomIcons/CustomIcons.component";

import { selectUserDetailsState } from "../../../reducers/user-details.reducer";
import { selectStatusState } from "../../../reducers/status.reducer";

const WorkspaceViewHeader: React.FC = () => {
  const redirect = useRedirect();

  const { data: userDetails, loading: userDetailsLoading } = useSelector(
    selectUserDetailsState
  );
  const { data: currentStatus, loading: currentStatusLoading } = useSelector(
    selectStatusState
  );
  const { avatar } = userDetails;
  const { id: currentStatusId } = currentStatus || {};

  return (
    <div className="WorkspaceViewHeader">
      <div className="WorkspaceViewHeader__Left">
        <Button
          className="BackToWorkspaceBtn"
          onClick={() =>
            redirect({
              path: "/office",
            })
          }
        >
          <HomeIcon />
          <span>Back to Workspace</span>
        </Button>
      </div>
      <div className="WorkspaceViewHeader__Right">
        <CustomAvatar src={avatar} work_status={currentStatusId} size={40} />
      </div>
    </div>
  );
};

export default WorkspaceViewHeader;
