import React from "react";
import cn from "classnames";

import { CaretDownIcon } from "../../CustomIcons/CustomIcons.component";
import MarkActivitiesAsRead from "../../Activities/MarkActivitiesAsRead/MarkActivitiesAsRead";
import ProjectInitial from "../ProjectInitial/ProjectInitial";
import RemoveProjectFromWorkday from "../RemoveProjectFromWorkday/RemoveProjectFromWorkday";
import UnreadActivitiesCount from "../../Activities/UnreadActivitiesCount/UnreadActivitiesCount";

/**
 * Default Display
 * ProjectCollapsePanelHeader displays the ProjectInitial, title, and the CaretDownIcon
 *
 * Functionality
 * Clicking the CaretDownIcon will toggle the ProjectCollapsePanelContent's collapsible panel
 */
export interface ProjectCollapsePanelHeaderProps {
  onToggle: Function;
  open: boolean;
  project: ProjectObject;
  projectActivitySummaryArray: ActivitySummaryArray;
  type: ActivitiesSummaryType;
}
const ProjectCollapsePanelHeader: React.FC<ProjectCollapsePanelHeaderProps> = ({
  onToggle,
  open = false,
  project,
  projectActivitySummaryArray,
  type,
}) => {
  const [hover, setHover] = React.useState(false);
  const [
    projectUnreadActivitiesCount,
    setProjectUnreadActivitiesCount,
  ] = React.useState(0);

  const { id: projectId, title: projectTitle } = project;
  const displayUnread = type === "team";

  React.useEffect(() => {
    setProjectUnreadActivitiesCount(
      projectActivitySummaryArray.filter(({ read }) => !read).length
    );
  }, [JSON.stringify(projectActivitySummaryArray)]);

  return (
    <div
      className="ProjectCollapsePanelHeader"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="ProjectCollapsePanelHeader__Left">
        <ProjectInitial project={project} />
        <div className="ProjectCollapsePanelHeader__Left__Title">
          <span className="ProjectCollapsePanelHeader__Left__Title__Text">
            {projectTitle}
          </span>
        </div>
        <CaretDownIcon
          className={cn("ProjectCollapsePanelHeader__Left__Icon", {
            "ProjectCollapsePanelHeader__Left__Icon--Open": open,
          })}
          onClick={onToggle}
        />
      </div>

      <div className="ProjectCollapsePanelHeader__Right">
        {displayUnread && projectId && (
          <>
            <UnreadActivitiesCount count={projectUnreadActivitiesCount} />
            {projectUnreadActivitiesCount > 0 && (
              <MarkActivitiesAsRead
                activityIdArray={projectActivitySummaryArray.map(
                  ({ id }) => id
                )}
              />
            )}
          </>
        )}

        {type === "checkout" && hover && (
          <RemoveProjectFromWorkday project={project} />
        )}
      </div>
    </div>
  );
};

export default ProjectCollapsePanelHeader;
