import React from "react";
import { useSelector } from "react-redux";

import useAsyncTasksInProjectArray from "./useAsyncTasksInProjectArray.hook";

import { selectCurrentWorkDayState } from "../reducers/work-day.reducer";
import useAsyncWorkDayTaskTimes from "./useAsyncWorkDayTaskTimes.hook";

export default function useAsyncProjectArrayTaskTimes(projects: ProjectArray) {
  const {
    data: { id: currentWorkDayId },
  } = useSelector(selectCurrentWorkDayState);

  const projectsIds = React.useMemo(() => {
    return projects.map(({ id }) => id);
  }, [projects]);

  const [
    tasksInProjectArray,
    tasksInProjectArrayLoading,
  ] = useAsyncTasksInProjectArray(projectsIds);

  const currentWorkDayTaskTimes = useAsyncWorkDayTaskTimes(currentWorkDayId);

  const projectArrayTaskTimes = React.useMemo(() => {
    if (!!tasksInProjectArray && !!currentWorkDayTaskTimes) {
      const tasksInProjectArrayIds = tasksInProjectArray.map(({ id }) => id);
      return currentWorkDayTaskTimes.filter(({ task }) =>
        tasksInProjectArrayIds.includes(task)
      );
    }
  }, [tasksInProjectArray, currentWorkDayTaskTimes]);

  const isLoading =
    (tasksInProjectArray === undefined && !tasksInProjectArrayLoading) ||
    currentWorkDayTaskTimes === undefined;

  return [projectArrayTaskTimes, isLoading] as [
    TaskTimeObject[] | undefined,
    boolean
  ];
}
