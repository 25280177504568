import React from "react";
import moment, { Moment } from "moment";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import cn from "classnames";
import { Button } from "antd";
import { Feature, useFeature } from "flagged";

import useAsyncObserverUsers from "../../../hooks/useAsyncObserverUsers.hook";

import { LoadingIcon } from "../../CustomIcons/CustomIcons.component";
import CheckoutHeader from "../CheckoutHeader/CheckoutHeader";
import CheckoutStatusWarning from "../CheckoutStatusWarning/CheckoutStatusWarning";
import CheckoutTimeSummary from "../CheckoutTimeSummary/CheckoutTimeSummary";
import CreateCheckout from "../CreateCheckout/CreateCheckout";
import SummaryOfTheDay from "../SummaryOfTheDay/SummaryOfTheDay";
import WorkShiftNotes from "../../WorkShift/WorkShiftNotes/WorkShiftNotes";
import ConfirmLeavingTheOffice from "../ConfirmLeavingTheOffice/ConfirmLeavingTheOffice";
import NextWorkDay from "../../WorkDay/NextWorkDay/NextWorkDay";
import WorkTimes from "../WorkTimes/WorkTimes";
import Observers from "../Observers/Observers";
import EditHistory from "../EditHistory/EditHistory";
import CheckoutStatus from "../CheckoutStatus/CheckoutStatus";
import ShiftsTable from "../ShiftsTable/ShiftsTable";

import {
  startGetCheckoutCheckStatuses,
  startFetchCheckoutDateStatus,
} from "../../../actions/checkout.action";
import { dateFormat, workDayDateFormat } from "../../../constants/constants";
import { selectUserDetailsState } from "../../../reducers/user-details.reducer";
import { selectCurrentWorkDayState } from "../../../reducers/work-day.reducer";
import { selectWorkShiftsState } from "../../../reducers/work-shift.reducer";
import {
  selectTrackingTaskState,
  selectLastTrackedTaskState,
} from "../../../reducers/task-time-tracking.reducer";
import { getObserverUsersService } from "../../../services/observable_users.service";
import { getWorkDayListService } from "../../../services/work-day.service";
import {
  pauseTrackingTask,
  resumeTrackingTask,
} from "../../../utils/task-time-tracking.util";
import { groupWorkShiftByDate } from "../../../utils/work-shift.util";

import ws from "../../../sockets/websockets";
import PageUnderConstruction from "../../General/PageUnderConstruction/PageUnderConstruction";

export const CheckoutPanelContext = React.createContext<CheckoutPanelContext>(
  {} as CheckoutPanelContext
);

/**
 * Default Display
 * Content displayed when global state nav equals "Checkout"
 *
 * In an empty checkout, the Next Workday section is only available if the checkout is for the current workday.
 * We use checkoutDateWorkShifts to determine if a checkout is empty (manually created)
 *
 * TODO 1.20 replace CheckoutTimeSummary prop with dynamic checkout task id
 */
export interface CheckoutPanelProps {
  date?: Moment;
  mode: CheckoutPanelMode;
  setDisplayModal?: React.Dispatch<React.SetStateAction<boolean>>;
  user?: UserObject;
}
const CheckoutPanel: React.FC<CheckoutPanelProps> = ({
  date,
  mode = "page",
  setDisplayModal,
  user,
}) => {
  const isBeta = useFeature("under_development");

  const dispatch = useDispatch();

  // global states
  const { data: userDetails, loading: userDetailsLoading } = useSelector(
    selectUserDetailsState
  );
  const { data: workShifts } = useSelector(selectWorkShiftsState);
  const { data: currentWorkDay } = useSelector(selectCurrentWorkDayState);
  const { id: trackingTaskId } = useSelector(selectTrackingTaskState);
  const { id: lastTrackedTaskId } = useSelector(selectLastTrackedTaskState);

  // local states
  const [checkoutUser, setCheckoutUser] = React.useState<UserObject>(
    {} as UserObject
  );
  const [checkoutDate, setCheckoutDate] = React.useState<string>("");
  const [checkoutWorkDay, setCheckoutWorkDay] = React.useState<WorkDayObject>();
  const [checkoutWorkDayLoading, setCheckoutWorkDayLoading] = React.useState(
    false
  );

  const [editingAllowed, setEditingAllowed] = React.useState(true);
  const [
    viewingOtherUsersCheckout,
    setViewingOtherUsersCheckout,
  ] = React.useState(false);

  const [observers, observersLoading] = useAsyncObserverUsers();

  // local variables
  const { id: checkoutUserId } = checkoutUser;
  const { work_date: currentWorkDayDate } = currentWorkDay;
  const { id: checkoutWorkDayId, work_date: checkoutWorkDayDate } =
    checkoutWorkDay || {};
  const checkoutDateIsLatestWorkDay =
    !!currentWorkDayDate &&
    !!checkoutDate &&
    currentWorkDayDate === checkoutDate;
  const dateGroupedWorkShifts = React.useMemo(
    () => groupWorkShiftByDate(workShifts),
    [workShifts]
  );
  const checkoutDateWorkShifts = isEmpty(dateGroupedWorkShifts)
    ? []
    : dateGroupedWorkShifts[
        moment(checkoutDate, workDayDateFormat).format(dateFormat)
      ];
  const dateGroupedWorkShiftIndex = Object.keys(dateGroupedWorkShifts).indexOf(
    moment(checkoutDate, workDayDateFormat).format(dateFormat)
  );
  const isLoading =
    userDetailsLoading ||
    checkoutWorkDay === undefined ||
    checkoutWorkDayLoading ||
    observersLoading;

  React.useEffect(() => {
    /**
     * Set checkout date to date prop passed
     * or global state currentWorkDay.work_date if date prop is undefined
     */
    if (date) {
      setCheckoutDate(date.startOf("day").format(workDayDateFormat));
    } else {
      setCheckoutDate(currentWorkDayDate);
    }
  }, [date, currentWorkDayDate]);

  React.useEffect(() => {
    const fetchCheckoutWorkDay = async () => {
      if (!date && checkoutDateIsLatestWorkDay) {
        setCheckoutWorkDay(currentWorkDay);
      } else if (checkoutUserId && checkoutDate) {
        try {
          setCheckoutWorkDayLoading(true);
          const response = await getWorkDayListService({
            user: checkoutUserId,
            work_date: checkoutDate,
          });
          if (response.status === 200) {
            if (response.data.length === 1) {
              setCheckoutWorkDay(response.data[0]);
            } else {
              setCheckoutWorkDay(undefined);
            }
            setCheckoutWorkDayLoading(false);
          } else {
            throw new Error();
          }
        } catch (e) {
          setCheckoutWorkDayLoading(false);
        }
      }
    };
    fetchCheckoutWorkDay();
  }, [
    currentWorkDay,
    checkoutUserId,
    checkoutDate,
    checkoutDateIsLatestWorkDay,
    date,
  ]);

  const todaysActivityRef = React.useRef<null | HTMLDivElement>(null);
  const workShiftNoteRef = React.useRef<null | HTMLDivElement>(null);

  const scrollToRef = (refString: string) => (e: React.MouseEvent) => {
    switch (refString) {
      case "todays_activity":
        todaysActivityRef.current!.scrollIntoView({ behavior: "smooth" });
        break;
      case "notes":
        workShiftNoteRef.current!.scrollIntoView({ behavior: "smooth" });
        break;
      default:
    }
  };

  React.useEffect(() => {
    /**
     * Set checkout user to user prop passed
     * or global state userDetails if user prop is undefined
     */
    const { id: userPropId } = user || {};
    const { id: currentUserId } = userDetails;

    if (user && !isEmpty(user)) {
      setCheckoutUser(user);
    } else {
      setCheckoutUser(userDetails);
    }
    if (userPropId && userPropId !== currentUserId) {
      setViewingOtherUsersCheckout(true);
      setEditingAllowed(false);
    } else if (!checkoutDateIsLatestWorkDay) {
      setEditingAllowed(false); // do not allow editing of previous work days
    } else {
      setEditingAllowed(true);
    }
  }, [user, userDetails, checkoutDateIsLatestWorkDay]);

  React.useEffect(() => {
    /**
     * Set checkout date to date prop passed
     * or global state currentWorkDay.work_date if date prop is undefined
     */
    if (date) {
      setCheckoutDate(moment(date).startOf("day").format(workDayDateFormat));
    } else {
      setCheckoutDate(currentWorkDayDate);
    }
  }, [date, currentWorkDayDate]);

  const getCheckoutDateStatus = React.useCallback(() => {
    if (checkoutWorkDayDate && checkoutUserId) {
      dispatch(
        startFetchCheckoutDateStatus({
          user_id: checkoutUserId,
          work_date: checkoutWorkDayDate.replace(/\//g, "-"),
        })
      );
    }
  }, [dispatch, checkoutUserId, checkoutWorkDayDate]);

  React.useEffect(() => {
    const synchronizeTaskTimeRecords = (
      messageEventData: TaskTimeEventData
    ) => {
      const { event: eventType } = messageEventData;
      switch (eventType) {
        case "new_task_time":
          getCheckoutDateStatus(); // re-run the checkout checks when the work time gets update for unallocated work time
          break;
        default:
          break;
      }
    };
    const wsOnMessage = async (event: MessageEvent) => {
      try {
        const messageEventData = JSON.parse(event.data);
        synchronizeTaskTimeRecords(messageEventData);
      } catch (e) {}
    };

    getCheckoutDateStatus();
    ws.addEventListener("message", wsOnMessage);

    return () => {
      ws.removeEventListener("message", wsOnMessage);
    };
  }, [dispatch, checkoutWorkDayId, getCheckoutDateStatus]);

  React.useEffect(() => {
    // pause or tracking task when checkout panel is opened
    if ((mode === "page" || mode === "leave_the_office") && trackingTaskId) {
      pauseTrackingTask();
    }

    return () => {
      // resume tracking task when checkout panel is closed
      if (
        (mode === "page" || mode === "leave_the_office") &&
        lastTrackedTaskId
      ) {
        resumeTrackingTask();
      }
    };
  }, [lastTrackedTaskId, mode, trackingTaskId]);

  React.useEffect(() => {
    dispatch(startGetCheckoutCheckStatuses());
  }, [dispatch]);

  return (
    <CheckoutPanelContext.Provider
      value={{
        scrollToRef,
        checkoutDate,
        checkoutWorkDay,
        setCheckoutDate,
        dateGroupedWorkShifts,
        dateGroupedWorkShiftIndex,
        checkoutDateWorkShifts,
      }}
    >
      <div
        className={cn("CheckoutPanel", {
          "CheckoutPanel--Page": mode === "page",
          "CheckoutPanel--LeaveTheOffice": mode === "leave_the_office",
          "CheckoutPanel--History": mode === "history",
        })}
      >
        {isBeta || mode === "leave_the_office" ? (
          <>
            {mode !== "leave_the_office" && (
              <CheckoutHeader checkoutUser={checkoutUser} mode={mode} />
            )}

            {isLoading && (
              <div className="CheckoutPanel__Loading">
                <LoadingIcon />
              </div>
            )}

            {(() => {
              if (!isLoading && checkoutWorkDayId === undefined) {
                return <CreateCheckout checkoutDate={checkoutDate} />;
              } else if (!isLoading && checkoutWorkDayId) {
                return (
                  <div className="CheckoutPanel__Body">
                    {mode === "page" && (
                      <div className="CheckoutPanel__Card">
                        <CheckoutStatusWarning />
                      </div>
                    )}
                    <div className="CheckoutPanel__Card CheckoutPanel__Main">
                      <div className="CheckoutPanel__Card__Title">
                        <div className="CheckoutPanel__Card__Title__Left">
                          <div className="CheckoutPanel__Card__Title__Left__Date">
                            {moment(checkoutDate, workDayDateFormat).format(
                              "dddd, MMMM D"
                            )}
                            {moment(checkoutDate, workDayDateFormat).isSame(
                              new Date(),
                              "day"
                            ) && " (Today)"}
                          </div>
                        </div>
                        <div className="CheckoutPanel__Card__Title__Right">
                          <Observers observers={observers} />

                          {isBeta && viewingOtherUsersCheckout && (
                            <Button
                              className="CheckoutPanel__Card__Title__Right__ModifyBtn"
                              type="primary"
                              onClick={() => setEditingAllowed((curr) => !curr)}
                            >
                              {editingAllowed ? "Done" : "Modify"}
                            </Button>
                          )}
                        </div>
                      </div>
                      <WorkTimes
                        date={checkoutDate}
                        mode={mode}
                        hasExternalTracking={
                          !isEmpty(userDetails.external_tracker_config_json)
                        }
                      />
                      {mode !== "leave_the_office" && (
                        <>
                          <ShiftsTable
                            editingAllowed={editingAllowed}
                            workdayId={checkoutWorkDayId}
                            userId={userDetails.id}
                            checkoutDate={checkoutDate}
                          />
                          {isBeta && <EditHistory date={checkoutDate} />}
                        </>
                      )}

                      <CheckoutStatus
                        date={checkoutDate}
                        mode={mode}
                        setDisplayModal={setDisplayModal}
                      />
                      <WorkShiftNotes
                        reference={workShiftNoteRef}
                        disabled={!editingAllowed}
                        userId={checkoutUserId}
                      />
                      {!isEmpty(checkoutUser) && mode !== "leave_the_office" && (
                        <>
                          <SummaryOfTheDay
                            collapseState="super-expand"
                            date={[checkoutDate]}
                            editingAllowed={editingAllowed}
                            type="checkout"
                            users={[checkoutUser]}
                          />
                          <CheckoutTimeSummary checkoutTaskId="EQSLMD9SBS" />{" "}
                        </>
                      )}
                    </div>

                    {mode === "page" &&
                      (checkoutDateWorkShifts ||
                        (!checkoutDateWorkShifts &&
                          checkoutDateIsLatestWorkDay)) && (
                        <div className="CheckoutPanel__Card">
                          <NextWorkDay
                            date={checkoutDate}
                            editingAllowed={editingAllowed}
                          />
                        </div>
                      )}

                    {mode === "leave_the_office" && (
                      <div className="CheckoutPanel__Card CheckoutPanel__LeaveTheOffice">
                        <ConfirmLeavingTheOffice
                          setDisplayModal={setDisplayModal}
                        />
                      </div>
                    )}
                  </div>
                );
              }
            })()}
          </>
        ) : (
          <PageUnderConstruction />
        )}
      </div>
    </CheckoutPanelContext.Provider>
  );
};

export default CheckoutPanel;
