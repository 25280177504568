import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tooltip } from "antd";

import CustomButton from "../../CustomButton/CustomButton";
import {
  LoadingIcon,
  StopTimeTrackerIcon,
  TimeTrackerIcon,
} from "../../CustomIcons/CustomIcons.component";
import ErrorModal from "../../General/ErrorModal/ErrorModal";

import { startCreateStatus } from "../../../actions/status.action";
import { workStatuses } from "../../../constants/constants";
import { selectStatusState } from "../../../reducers/status.reducer";
import {
  selectTrackingTaskState,
  selectTrackingStatusState,
} from "../../../reducers/task-time-tracking.reducer";
import {
  pauseTrackingTask,
  startTrackingTask,
  stopTrackingTask,
} from "../../../utils/task-time-tracking.util";
import { getStatusFromListStatusTypesByIdentifier } from "../../../utils/work-status.util";

interface TaskTimeTogglerProps {
  task: TaskJoinedToUserAndScheduledObject;
}
const TaskTimeToggler: React.FC<TaskTimeTogglerProps> = ({ task }) => {
  const dispatch = useDispatch();

  const trackingTask = useSelector(selectTrackingTaskState);
  const trackingStatus = useSelector(selectTrackingStatusState);
  const currentStatus = useSelector(selectStatusState);

  const [loadingState, setLoadingState] = React.useState<LoadingState>("false");
  const [showErrorModal, setShowErrorModal] = React.useState(false);

  const { id: taskId } = task;
  const { id: trackingTaskId } = trackingTask;
  const isTrackingToTask =
    taskId === trackingTaskId && trackingStatus === "tracking";
  const { id: currentStatusId } = currentStatus.data || {};

  const setTracking = async (
    type: TrackingType,
    task?: TaskJoinedToUserAndScheduledObject
  ) => {
    try {
      setLoadingState("true");
      if (type === "start" && task) {
        await startTrackingTask(task);
      } else if (type === "stop") {
        await stopTrackingTask();
      } else if (type === "pause") {
        await pauseTrackingTask();
      }
      /**
       * TODO 13.2.1 - remove settimeout when actual endpoint calls are implemented
       */
      setTimeout(() => {
        setLoadingState("success");
      }, 1000);
    } catch (e) {
      setLoadingState("error");
    }
  };

  const handleClickStopTracking = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    await setTracking("stop");
  };

  const handleClickStartTracking = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (currentStatusId === workStatuses.taking_break) {
      setShowErrorModal(true);
    } else {
      await setTracking("start", task);
    }
  };

  const modalActions = [
    <CustomButton
      onClick={(e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setShowErrorModal(false);
      }}
    >
      Cancel
    </CustomButton>,
    <CustomButton
      type="primary"
      onClick={async (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        const workingStatusType = getStatusFromListStatusTypesByIdentifier(
          workStatuses.working
        );
        dispatch(startCreateStatus(workingStatusType));
        await setTracking("start", task);
        setShowErrorModal(false);
      }}
    >
      OK
    </CustomButton>,
  ];

  return (
    <div className="TaskTimeToggler">
      <ErrorModal
        actions={modalActions}
        handleCancel={() => setShowErrorModal(false)}
        title="Confirmation"
        text="If you continue, your break will end."
        visible={showErrorModal}
      />
      {(() => {
        if (loadingState === "true") {
          return (
            <div className="TaskTimeToggler__ControlIcon TaskTimeToggler__ControlIcon--Loading">
              <LoadingIcon />
            </div>
          );
        } else if (isTrackingToTask) {
          return (
            <Tooltip title={"Stop time tracker"}>
              <StopTimeTrackerIcon
                className="TaskTimeToggler__ControlIcon TaskTimeToggler__ControlIcon--StopTracking"
                onClick={handleClickStopTracking}
              />
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              title={"Start time tracker"}
              getPopupContainer={(trigger: any) => trigger}
            >
              <TimeTrackerIcon
                className="TaskTimeToggler__ControlIcon TaskTimeToggler__ControlIcon--StartTracking"
                onClick={handleClickStartTracking}
              />
            </Tooltip>
          );
        }
      })()}
    </div>
  );
};

export { TaskTimeToggler as default };
