import React from "react";
import moment from "moment";
import { sum } from "lodash";

import { LoadingIcon } from "../../CustomIcons/CustomIcons.component";

import {
  getAllocatedTimeService,
  GetAllocatedTimeServiceResponse,
} from "../../../services/task-time.service";
import { getTotalDuration } from "../../../utils/time-conversion.util";

import ws from "../../../sockets/websockets";

/**
 * ProjectCollapsePanelFooter displays the total task time recorded in a project
 */
export interface ProjectCollapsePanelFooterProps {
  date: string[];
  project: ProjectObject;
  users: UserArray;
}
const ProjectCollapsePanelFooter: React.FC<ProjectCollapsePanelFooterProps> = ({
  date,
  project,
  users,
}) => {
  const [projectTotalTaskTime, setProjectTotalTaskTime] = React.useState(0);
  const [loadingState, setLoadingState] = React.useState<LoadingState>("false");
  const { id: projectId, title: projectTitle } = project;

  const stringifiedDate = JSON.stringify(date);
  const stringifiedUsers = JSON.stringify(users);

  React.useEffect(() => {
    const getProjectTotalTaskTime = async () => {
      const parsedDate: ProjectCollapsePanelFooterProps["date"] = JSON.parse(
        stringifiedDate
      );
      const parsedUsers: ProjectCollapsePanelFooterProps["users"] = JSON.parse(
        stringifiedUsers
      );
      try {
        setLoadingState("true");
        const allocatedTimeArray = await Promise.all(
          parsedUsers.map(async ({ id: user_id }) => {
            try {
              const from_date = parsedDate[0];
              const to_date = parsedDate[parsedDate.length - 1];
              const response = await getAllocatedTimeService({
                user_id,
                from_date,
                to_date,
              });
              if (response.status === 200) {
                const responseData: GetAllocatedTimeServiceResponse =
                  response.data;
                if (responseData.allocated_time.length > 0) {
                  return sum(
                    responseData.allocated_time
                      .filter(({ project }) => project === projectId)
                      .flatMap(({ tasks }) =>
                        tasks.map(({ total_seconds }) => total_seconds)
                      )
                  );
                }
              }
              return 0;
            } catch (e) {
              return 0;
            }
          })
        );
        setLoadingState("success");
        setProjectTotalTaskTime(sum(allocatedTimeArray));
      } catch (e) {
        setLoadingState("error");
      }
    };

    const wsOnMessage = async (event: MessageEvent) => {
      try {
        const messageEventData = JSON.parse(event.data);
        const { event: eventType } = messageEventData;
        switch (eventType) {
          case "new_task_time":
            getProjectTotalTaskTime(); // re-run the checkout checks when the work time gets update for unallocated work time
            break;
          default:
        }
      } catch (e) {}
    };

    getProjectTotalTaskTime();
    ws.addEventListener("message", wsOnMessage);

    return () => {
      ws.removeEventListener("message", wsOnMessage);
    };
  }, [stringifiedDate, stringifiedUsers, projectId, projectTitle]);

  return (
    <div className="ProjectCollapsePanelFooter">
      <span className="ProjectCollapsePanelFooter__Text">Total:</span>
      {loadingState === "true" ? (
        <LoadingIcon />
      ) : (
        <span>
          {getTotalDuration(moment.duration(projectTotalTaskTime, "seconds"))}
        </span>
      )}
    </div>
  );
};

export default ProjectCollapsePanelFooter;
