import React from "react";
import { Dropdown } from "antd";

import { CaretDownIcon } from "../../CustomIcons/CustomIcons.component";
import ListSelectionDropdownMenu from "./ListSelectionDropdownMenu/ListSelectionDropdownMenu";

/**
 * ListSelection displays ListSelectionDropdownMenu
 * and the title of selected list
 * or place holder if no list is selected
 */
export interface ListSelectionProps {
  handleSelectList: Function;
  handleToggleDropdown: any;
  listArray: ListArray;
  selectedList?: ListObject;
  selectedProject?: ProjectObject;
  showDropdown: boolean;
}
const ListSelection: React.FC<ListSelectionProps> = ({
  handleSelectList,
  handleToggleDropdown,
  listArray,
  selectedList,
  selectedProject,
  showDropdown,
}) => {
  return (
    <div className="ListSelection">
      <div className="ListSelection__Item">
        <div className="ListSelection__Item__Title">List</div>
        <Dropdown
          overlay={
            <ListSelectionDropdownMenu
              handleSelectList={(listObject: ListObject) => {
                handleSelectList(listObject);
              }}
              listArray={listArray}
              selectedProject={selectedProject}
            />
          }
          visible={showDropdown}
          getPopupContainer={(trigger: any) => trigger}
        >
          <div
            className="ListSelection__Item__Dropdown"
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              handleToggleDropdown();
            }}
          >
            <span className="ListSelection__Item__Dropdown__Selected">
              {selectedList ? selectedList.title : "List"}
            </span>
            <CaretDownIcon />
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

export default ListSelection;
