import React from "react";
import moment from "moment";
import { Tooltip } from "antd";
import cn from "classnames";

import {
  CaretDownIcon,
  ClockIcon,
  InfoCircleOutlinedIcon,
} from "../../../CustomIcons/CustomIcons.component";
import MarkActivitiesAsRead from "../../../Activities/MarkActivitiesAsRead/MarkActivitiesAsRead";
import UnreadActivitiesCount from "../../../Activities/UnreadActivitiesCount/UnreadActivitiesCount";

// import { selectWorkDaysState } from "../../../../reducers/work-day.reducer";
import { CHECKOUT_PANEL_KEY } from "../../../../constants/constants";
import {
  getDurationFormat,
  getTotalDuration,
  formatDuration,
} from "../../../../utils/time-conversion.util";
import { markCheckoutStatusAsReadService } from "../../../../services/checkout.service";
import { dispatchError } from "../../../../utils/error.util";

export interface CheckoutTaskCollapsePanelHeaderProps {
  activeKey: string;
  activitiesSummaryType: ActivitiesSummaryType;
  checkoutTaskTotalTime: number;
  setActiveKey: React.Dispatch<React.SetStateAction<string>>;
  workDaysCheckoutSummary: WorkDayCheckoutSummaryArray;
}
const CheckoutTaskCollapsePanelHeader: React.FC<CheckoutTaskCollapsePanelHeaderProps> = ({
  activeKey,
  activitiesSummaryType,
  checkoutTaskTotalTime,
  setActiveKey,
  workDaysCheckoutSummary,
}) => {
  const getWorkDayIds = React.useCallback(() => {
    const mappedWorkDayIds = workDaysCheckoutSummary.flatMap(
      ({ checkout_check }) => checkout_check.map(({ work_day }) => work_day)
    );
    return Array.from(new Set(mappedWorkDayIds));
  }, [workDaysCheckoutSummary]);

  const unreadCheckoutStatusCount = React.useMemo(
    () =>
      workDaysCheckoutSummary.filter(
        ({ checkout_read_status }) => !checkout_read_status
      ).length,
    [workDaysCheckoutSummary]
  );

  const handleClickCaret = () => {
    setActiveKey((c) => (!!c ? "" : CHECKOUT_PANEL_KEY));
  };

  const markShownUnreadCheckoutStatusAsRead = async () => {
    try {
      await markCheckoutStatusAsReadService({
        workday_ids: getWorkDayIds(),
      });
    } catch (e) {
      dispatchError({
        e,
        title: "Mark checkout status as read error",
      });
    }
  };

  return (
    <div className="CheckoutTaskCollapsePanelHeader">
      <div className="CheckoutTaskCollapsePanelHeader__Left">
        <ClockIcon />
        <span>Checkout</span>
        <CaretDownIcon
          className={cn("CheckoutTaskCollapsePanelHeader__Left__Caret", {
            "CheckoutTaskCollapsePanelHeader__Left__Caret--Open": !!activeKey,
          })}
          onClick={handleClickCaret}
        />
      </div>
      <div className="CheckoutTaskCollapsePanelHeader__Right">
        {activitiesSummaryType === "team" && unreadCheckoutStatusCount > 0 && (
          <>
            <UnreadActivitiesCount count={unreadCheckoutStatusCount} />
            <MarkActivitiesAsRead
              onClickAsync={markShownUnreadCheckoutStatusAsRead}
            />
          </>
        )}
        {/*
          * Temporarily removed until checkout task time has been implemented
        <Tooltip
          title={`Time spent on Checkout page: ${formatDuration(
            checkoutTaskTotalTime,
            "seconds",
            getDurationFormat(checkoutTaskTotalTime, "s")
          )}`}
        >
          <InfoCircleOutlinedIcon />
        </Tooltip>
        <span className="CheckoutTaskCollapsePanelHeader__Right__CheckoutTaskTime">
          {getTotalDuration(moment.duration(checkoutTaskTotalTime, "seconds"))}
        </span>
        */}
      </div>
    </div>
  );
};

export default CheckoutTaskCollapsePanelHeader;
