import { Skeleton } from "antd";
import React from "react";
import TimeSlot from "./TimeSlot";

/**
 *
 * The <TimeBar /> is the component that displays different time slots in one row.
 * The length of each slot is defined by its time duration.
 * A TimeBar component may contains multiple <TimeSlot /> component.
 */
const TimeBar: React.FC<TimeBarProps> = ({ timeSlots, loading }) => {
  const barItems = timeSlots.map((slot, index) => (
    <TimeSlot key={index} timeSlot={slot} />
  ));
  return (
    <div className="TimeBar">
      {loading ? (
        <Skeleton
          className="TimeBar__Skeleton"
          active={true}
          title={false}
          paragraph={{ rows: 1, width: "100%" }}
        />
      ) : (
        barItems
      )}
    </div>
  );
};

export default TimeBar;
