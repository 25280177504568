import React from "react";

import ProjectCollapsePanelContent from "../../Projects/ProjectCollapsePanelContent/ProjectCollapsePanelContent";

import { getTasksListService } from "../../../services/tasks.service";
import { LoadingIcon } from "../../CustomIcons/CustomIcons.component";

interface ProjectCollapsePanelProps {
  activitiesFilter: ActivitiesFilter;
  activitySummaryArray: ActivitySummaryArray;
  collapse: CollapseState;
  date: string[];
  projectsWithTasks?: {
    project: ProjectObject;
    tasks: TaskObject["id"][];
  }[];
  setCollapse: React.Dispatch<React.SetStateAction<CollapseState>>;
  type: SummaryOfTheDayProps["type"];
  users: SummaryOfTheDayProps["users"];
}

const defaultUsersProp: UserArray = [];

const ProjectCollapsePanel: React.FC<ProjectCollapsePanelProps> = ({
  activitiesFilter,
  activitySummaryArray,
  collapse,
  date,
  projectsWithTasks,
  setCollapse,
  type,
  users = defaultUsersProp,
}) => {
  const projectsActivitySummaryArray = React.useMemo(() => {
    if (projectsWithTasks) {
      return projectsWithTasks.reduce((acc, curr) => {
        return [
          ...acc,
          ...activitySummaryArray.filter(({ task }) =>
            curr.tasks.includes(task)
          ),
        ];
      }, [] as ActivitySummaryArray);
    } else {
      return [] as ActivitySummaryArray;
    }
  }, [activitySummaryArray, projectsWithTasks]);

  const displayEmptyActivities =
    (type === "history" || type === "team") &&
    projectsActivitySummaryArray.length === 0;

  const projectsJSX = projectsWithTasks?.map(({ project, tasks }) => {
    const projectActivitySummaryArray = activitySummaryArray.filter(
      ({ task }) => tasks.includes(task)
    );
    return (
      <div key={project.id} className="ProjectCollapsePanel__Panel">
        <ProjectCollapsePanelContent
          activitiesFilter={activitiesFilter}
          collapse={collapse}
          date={date}
          onToggle={(collapseState) => setCollapse(collapseState)}
          project={project}
          projectActivitySummaryArray={projectActivitySummaryArray}
          type={type}
          users={users}
        />
      </div>
    );
  });

  return (
    <div className="ProjectCollapsePanel">
      {!projectsWithTasks && (
        <div className="ProjectCollapsePanel__EmptyActivitiesState">
          <LoadingIcon />
        </div>
      )}

      {!!projectsWithTasks && displayEmptyActivities && (
        <div className="ProjectCollapsePanel__EmptyActivitiesState">
          <span>There are no activities</span>
        </div>
      )}

      {!!projectsWithTasks && !displayEmptyActivities && projectsJSX}
    </div>
  );
};

export default ProjectCollapsePanel;
