import React from "react";
import { Collapse, Tooltip } from "antd";
import { Feature } from "flagged";
import cn from "classnames";

import useAsyncConsolidateUserTasks from "../../../hooks/useAsyncConsolidateUserTasks.hook";
import useAsyncTasksInProject from "../../../hooks/useAsyncTasksInProject.hook";

import CardEmptyState from "../../EmptyState/CardEmptyState/CardEmptyState";
import {
  CaretDownIcon,
  LoadingIcon,
  TimeTrackerIcon,
} from "../../CustomIcons/CustomIcons.component";
import ProjectInitial from "../../Projects/ProjectInitial/ProjectInitial";
import TaskCardItem from "../../Tasks/TaskCardItem/TaskCardItem";
import { TaskPanelContext } from "../../Tasks/TaskPanel/TaskPanel";

import { checklist, TASK_PAGES } from "../../../constants/constants";

const { Panel } = Collapse;

/**
 * Default Display
 * Displays the details of a project passed
 * Displays the tasks sections related to the project
 *
 * Functionality
 * Sets global state nav to project title when InfoCircleOutlinedIcon is clicked
 */
const ProjectCardItem: React.FC<ProjectCardItemProps> = ({
  onTaskSelect,
  project,
  taskPage,
  panelType,
}) => {
  const { id: projectId, title: projectTitle } = project;

  const [hovered, setHovered] = React.useState(false);

  const { todayTasks, nextWorkDayTasks, starredTasks } = React.useContext(
    TaskPanelContext
  );

  const [
    consolidatedTodayTasks,
    consolidatedTodayTasksLoading,
  ] = useAsyncConsolidateUserTasks(todayTasks);
  const [
    consolidatedNextWorkDayTasks,
    consolidatedNextWorkDayTasksLoading,
  ] = useAsyncConsolidateUserTasks(nextWorkDayTasks);
  const [
    consolidatedStarredTasks,
    consolidatedStarredTasksLoading,
  ] = useAsyncConsolidateUserTasks(starredTasks);

  const tasksInProject = React.useMemo(() => {
    const getProjectTasks = (tasks?: TaskJoinedToUserAndScheduledArray) => {
      return tasks
        ? tasks.filter((task) => {
            const { task_list } = task;
            const { parent_project } = task_list || {};
            return parent_project === projectId;
          })
        : [];
    };

    switch (taskPage) {
      case TASK_PAGES.TODAY:
        return getProjectTasks(consolidatedTodayTasks);
      case TASK_PAGES.STARRED:
        return getProjectTasks(consolidatedStarredTasks);
      case TASK_PAGES.NEXT_SHIFT:
        return getProjectTasks(consolidatedNextWorkDayTasks);
      default:
    }
  }, [
    taskPage,
    consolidatedTodayTasks,
    consolidatedStarredTasks,
    consolidatedNextWorkDayTasks,
    projectId,
  ]);

  const hasTask = !!tasksInProject && tasksInProject.length > 0;
  const isLoading =
    consolidatedTodayTasksLoading ||
    consolidatedNextWorkDayTasksLoading ||
    consolidatedStarredTasksLoading;

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const tasksJsx =
    !!tasksInProject &&
    tasksInProject.map((task, index) => {
      return (
        <TaskCardItem
          key={index}
          task={task}
          checklist={checklist}
          tracking={false}
          onTaskSelect={onTaskSelect}
          project={project}
          panelType={panelType}
        />
      );
    });

  return (
    <Collapse
      className="ProjectCardItem"
      bordered={false}
      defaultActiveKey={[projectId]}
    >
      <Panel
        key={projectId}
        showArrow={false}
        collapsible="header"
        header={
          <div
            className="ProjectCardItem__Header"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div className="ProjectCardItem__Header__Title">
              <ProjectInitial project={project} />
              <span className="ProjectCardItem__Header__Title__Text">
                {projectTitle}
              </span>
              <CaretDownIcon />
            </div>

            <Feature name="under_development">
              {hovered && (
                <div className="ProjectCardItem__Header__Actions">
                  <div className="ProjectCardItem__Header__Actions__TimeTracker">
                    <Tooltip title={"Start time tracker"}>
                      <TimeTrackerIcon />
                    </Tooltip>
                  </div>
                </div>
              )}
            </Feature>
          </div>
        }
      >
        <div
          className={cn("ProjectCardItem__TaskList", {
            "ProjectCardItem__TaskList--Loading": isLoading,
          })}
        >
          {isLoading && <LoadingIcon />}
          {!isLoading && hasTask && tasksJsx}
          {!isLoading && !hasTask && (
            <CardEmptyState
              message="No tasks in project."
              panelType={panelType}
            />
          )}
        </div>
      </Panel>
    </Collapse>
  );
};

export { ProjectCardItem as default };
