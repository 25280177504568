import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  convertToRaw,
  convertFromRaw,
  Editor,
  DraftEditorCommand,
  EditorState,
  RichUtils,
  ContentBlock,
  ContentState,
  RawDraftContentState,
  CompositeDecorator,
} from "draft-js";
import cn from "classnames";

import { LoadingIcon } from "../../CustomIcons/CustomIcons.component";
import WorkShiftNotesToolbar from "../WorkShiftNotesToolbar/WorkShiftNotesToolbar";
import { CheckoutPanelContext } from "../../Checkout/CheckoutPanel/CheckoutPanel";

import { startPartialUpdateWorkDayRecord } from "../../../actions/work-day.action";
import { PartialUpdateWorkDayServiceData } from "../../../services/work-day.service";

// Pass the findLinkEntities function to strategy property and Link react component to component property:
const findLinkEntities = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
};
const Link = (props: {
  contentState: ContentState;
  children: React.ReactNode;
  entityKey: string;
}) => {
  const { url } = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a className="richTextCustomLink" href={url}>
      {props.children}
    </a>
  );
};

// specify draft.js decorator
const decorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link,
  },
]);

/**
 * WorkShiftNotes allows the user to edit the note for a work shift with a rich text editor
 * Currently stopped implementation as per Greg
 */
export interface WorkShiftNotesProps {
  reference?: React.MutableRefObject<null | HTMLDivElement>;
  disabled?: boolean;
  userId: UserObject["id"];
}
const WorkShiftNotes: React.FC<WorkShiftNotesProps> = ({
  reference,
  disabled = false,
  userId,
}) => {
  const dispatch = useDispatch();

  const { checkoutWorkDay } = React.useContext(CheckoutPanelContext);
  const {
    id: checkoutWorkDayId,
    work_date: checkoutWorkDate,
    checkout_notes: checkoutNotes,
    loading: checkoutLoading,
  } = checkoutWorkDay || {};
  const { loading: checkoutNotesLoading, value: checkoutNotesValue } =
    checkoutNotes || {};

  // pass decorator to editorstate create
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty(decorator)
  );
  const [contentState, setContentState] = React.useState<ContentState>();
  const [rawContent, setRawContent] = React.useState<RawDraftContentState>();
  const [hidePlaceholder, setHidePlaceholder] = React.useState(false);
  const [focused, setFocused] = React.useState(false);
  const [loadedFromDb, setLoadedFromDb] = React.useState(false);

  const editor = React.useRef<null | Editor>(null);
  const didMountRef = React.useRef(false);

  const focusEditor = () => {
    if (editor.current) {
      editor.current.focus();
      setFocused(true);
    }
  };

  const handleOnChange = (editorState: EditorState) => {
    setEditorState(editorState);
  };

  const handleKeyCommand = (
    command: DraftEditorCommand,
    editorState: EditorState
  ) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      setEditorState(newState);
      return "handled";
    }

    return "not-handled";
  };

  // add custom className for blockquote
  const blockStyleFn = (contentBlock: ContentBlock) => {
    const type = contentBlock.getType();
    if (type === "blockquote") {
      return "customBlockQuote";
    }
    return "";
  };

  React.useEffect(() => {
    // set the editor content to what is saved in the DB
    if (!didMountRef.current && checkoutNotesValue) {
      const DBEditorState = convertFromRaw(JSON.parse(checkoutNotesValue));
      setEditorState(EditorState.createWithContent(DBEditorState, decorator));
      setLoadedFromDb(true);
    } else if (!didMountRef.current && !checkoutNotesValue) {
      setLoadedFromDb(true);
    }
  }, [checkoutNotes, didMountRef]);

  React.useEffect(() => {
    if (editorState && loadedFromDb) {
      setContentState(editorState.getCurrentContent());
    }
  }, [editorState, loadedFromDb]);

  React.useEffect(() => {
    if (contentState && loadedFromDb) {
      setRawContent(convertToRaw(contentState));

      // hide placeholder if has no text and block is unstyled
      setHidePlaceholder(
        contentState.hasText() ||
          contentState.getBlockMap().first().getType() !== "unstyled"
      );
    }
  }, [contentState, loadedFromDb]);

  React.useEffect(() => {
    if (loadedFromDb && rawContent && checkoutWorkDayId) {
      didMountRef.current = true;
    }
  }, [loadedFromDb, rawContent, checkoutWorkDayId]);

  const updateWorkShiftNotes = React.useCallback(() => {
    // save stringified rich text content to db
    if (
      loadedFromDb &&
      rawContent &&
      checkoutWorkDayId &&
      didMountRef.current
    ) {
      const data = {
        id: checkoutWorkDayId,
        checkout_notes: JSON.stringify(rawContent),
        work_date: checkoutWorkDate,
        user: userId,
      } as PartialUpdateWorkDayServiceData;
      dispatch(startPartialUpdateWorkDayRecord(data));
    }
  }, [
    dispatch,
    rawContent,
    didMountRef,
    loadedFromDb,
    checkoutWorkDayId,
    checkoutWorkDate,
    userId,
  ]);

  return (
    <div ref={reference} className="WorkShiftNotes">
      <div
        className={cn("WorkShiftNotes__EditorWrapper", {
          "WorkShiftNotes__EditorWrapper--Focused": focused,
        })}
      >
        {!didMountRef.current || checkoutNotesLoading ? (
          <LoadingIcon />
        ) : (
          <Editor
            ref={editor}
            editorState={editorState}
            handleKeyCommand={handleKeyCommand}
            onBlur={updateWorkShiftNotes}
            onFocus={focusEditor}
            onChange={handleOnChange}
            placeholder={
              disabled
                ? "Notes cannot be edited for previous checkouts"
                : hidePlaceholder
                ? undefined
                : "Type a note here ..."
            }
            blockStyleFn={blockStyleFn}
            readOnly={disabled}
          />
        )}
        {focused && (
          <WorkShiftNotesToolbar
            editorState={editorState}
            setEditorState={setEditorState}
            contentState={contentState}
          />
        )}
      </div>
    </div>
  );
};

export default WorkShiftNotes;
