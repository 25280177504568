import React from "react";

const TeamMemberSelectorMoreActionsPopoverContent: React.FC<TeamMemberSelectorMoreActionsPopoverContentProps> = ({
  selectAll,
  deselectAll,
}) => {
  return (
    <div className="TeamMemberSelectorMoreActionsPopoverContent">
      <div
        className="TeamMemberSelectorMoreActionsPopoverContent__Action"
        onClick={() => {
          selectAll();
        }}
      >
        <span>Select all</span>
      </div>
      <div
        className="TeamMemberSelectorMoreActionsPopoverContent__Action"
        onClick={() => {
          deselectAll();
        }}
      >
        <span>Deselect all</span>
      </div>
    </div>
  );
};

export default TeamMemberSelectorMoreActionsPopoverContent;
