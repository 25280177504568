import moment from "moment";

import {
  fetchActivityRecordService,
  getUserActivitiesService,
  GetUserActivitiesServiceData,
} from "../services/activities.service";
import { isToday, isYesterday } from "../utils/dates.util";

export const dateKeyFormat = "dddd, MMMM D";
export const dateKeyWithYearFormat = "dddd, MMMM D YYYY";

export const groupActivitiesByDate = (
  activities: ActivityArray,
  activityKey: ActivityObjectDateKeys = "submitted_datetime",
  order: string = "desc"
) => {
  const groups: Record<string, ActivityArray> = {};

  const addToGroup = (dateKey: string, activity: ActivityObject) => {
    if (groups[dateKey] === undefined) {
      groups[dateKey] = [];
    }

    groups[dateKey].push(activity);
  };

  const createDateKey = (date: Date) => {
    if (isToday(moment(date))) {
      return "Today";
    } else if (isYesterday(moment(date))) {
      return "Yesterday";
    } else {
      return moment(date).format(dateKeyWithYearFormat);
    }
  };

  activities.forEach((activity) => {
    const activityKeyValue = activity[activityKey];

    if (activityKeyValue) {
      // only add to group if the value of the specified key is truthy
      const dateCreatedKey = createDateKey(new Date(activityKeyValue!));
      addToGroup(dateCreatedKey, activity);
    }
  });

  const clone: Record<string, ActivityArray> = Object.assign({}, { ...groups });
  delete clone["Today"];
  delete clone["Yesterday"];
  const ordered: Record<string, ActivityArray> = Object.keys(clone)
    .sort((a, b) => {
      if (moment(a).isBefore(b)) return order === "desc" ? 1 : -1;
      if (moment(a).isAfter(b)) return order === "desc" ? -1 : 1;
      return 0;
    })
    .reduce((obj: any, key: any) => {
      obj[moment(key, dateKeyWithYearFormat).format(dateKeyFormat)] =
        clone[key];
      return obj;
    }, {});

  const sortedGroups: Record<string, ActivityArray> =
    order === "desc"
      ? {
          ...(groups["Today"] && { Today: groups["Today"] }),
          ...(groups["Yesterday"] && { Yesterday: groups["Yesterday"] }),
          ...ordered,
        }
      : {
          ...ordered,
          ...(groups["Yesterday"] && { Yesterday: groups["Yesterday"] }),
          ...(groups["Today"] && { Today: groups["Today"] }),
        };

  return sortedGroups;
};

export const getUserActivities = async (
  fromDate: string,
  toDate: string,
  users: UserObject["id"][]
) => {
  if (users.length > 0 && fromDate && toDate) {
    const result: ActivitySummaryArray[] = await Promise.all(
      users.map(async (userId) => {
        try {
          const data: GetUserActivitiesServiceData = {
            fromDate,
            toDate,
            userId,
          };
          const response = await getUserActivitiesService(data);
          if (response.status === 200) {
            return response.data.filter(
              ({ confirmed_yn, deleted_yn }) => confirmed_yn && !deleted_yn
            );
          }
          return [];
        } catch (e) {
          return [];
        }
      })
    );
    const activitySummaryArray: ActivitySummaryArray = result.flatMap(
      (item) => item
    );
    return activitySummaryArray;
  } else {
    return [] as ActivitySummaryArray;
  }
};

export const asyncFetchActivityObject = async (
  activityId: ActivityObject["id"]
) => {
  try {
    const response = await fetchActivityRecordService(activityId);
    if (response.status === 200) {
      return response.data as ActivityObject;
    }
  } catch (e) {}
};
