import React from "react";
import cn from "classnames";
import { Table } from "antd";
import { MenuOutlined } from "@ant-design/icons";

import DraggableRow from "./DraggableRow";
import DroppableContainer from "./DroppableContainer";

/**
 * A draggable and droppable table implemented by react-beautiful-dnd
 * We insert a draggable column to the first column of the table
 * so user can drag on the "grab" icon and drop to the destination.
 *
 * We can achieve this by overriding the implentation of ant design's body component.
 */
const DndTable: React.FC<DndTableProps> = ({
  className,
  columns,
  disabled = false,
  ...props
}) => {
  const components = {
    body: {
      wrapper: (wrapperProps: any) => (
        <DroppableContainer id={props.id} {...wrapperProps} />
      ),
      row: (wrapperProps: any) => (
        <DraggableRow isDragDisabled={disabled} {...wrapperProps} />
      ),
    },
  };
  const draggableColumns = [
    {
      dataIndex: "drag-column",
      width: 30,
      render: () => (
        <MenuOutlined
          style={{ cursor: disabled ? "wait" : "grab", color: "#999" }}
        />
      ),
    },
    ...columns,
  ];

  // combine record's key and its index to indenfify the row and its order
  const generateRowKey = (record: any) => `${record.key}#${record.index}`;
  return (
    <Table
      className={cn("DndTable", className)}
      components={components}
      columns={draggableColumns}
      rowKey={generateRowKey}
      {...props}
    />
  );
};

export default DndTable;
