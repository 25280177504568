import axios from "../apis/django.instance";

export const getStatusTypesService = () => {
  return axios.get("/api/work/choices/work_status_types/"); // must be in this format in order to mock implementation in unit test
};

export const getCurrentUserStatus = () => {
  return axios.get("/api/work/current_user_status/"); // must be in this format in order to mock implementation in unit test
};

export const createStatusService: CreateStatusService = (data) => {
  return axios.post("/api/work/create_log_user_status/", data); // must be in this format in order to mock implementation in unit test
};

export const leaveTheOfficeService = () => {
  /**
   * Leave the office by setting the user’s latest StatusHistory record’s end_datetime to the current datetime.
   * This will also end the current shift of the user.
   */
  return axios.post(`/api/work/leave_office/`);
};
