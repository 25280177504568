import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "../../Button/Button";
import { ExclamationCircleFilledIcon } from "../../CustomIcons/CustomIcons.component";
import { dismissCheckoutCheckStatusService } from "../../../services/checkout.service";
import { CheckoutPanelContext } from "../CheckoutPanel/CheckoutPanel";
import Modal from "../../General/Modal/Modal";
import CheckoutStatusMessages from "../CheckoutStatusMessages/CheckoutStatusMessages";
import { selectCheckoutCheckStatusesState } from "../../../reducers/checkout.reducer";
import { startGetCheckoutCheckStatuses } from "../../../actions/checkout.action";

const CheckoutStatusWarning = () => {
  const dispatch = useDispatch();

  const checkoutPanelContext = React.useContext(CheckoutPanelContext);
  const { setCheckoutDate } = checkoutPanelContext;

  const checkoutCheckStatuses: CheckoutCheckStatus[] = useSelector(
    selectCheckoutCheckStatusesState
  ).data;

  const [open, setOpen] = React.useState(false);

  const latestStatus = checkoutCheckStatuses[0];

  const handleDateClick = () => {
    if (latestStatus) {
      setCheckoutDate(latestStatus.work_day.work_date);
    }
  };

  const showModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };
  const handleDismiss = async (ids: string[]) => {
    return Promise.all(
      ids.map(async (id) => {
        const response = await dismissCheckoutCheckStatusService(id);
        return response.status === 200;
      })
    ).then(() => dispatch(startGetCheckoutCheckStatuses()));
  };

  const handleDissmissCurrent = async () => {
    if (latestStatus) {
      const response = await dismissCheckoutCheckStatusService(latestStatus.id);
      if (response.status === 200) {
        dispatch(startGetCheckoutCheckStatuses());
      }
    }
  };
  return latestStatus ? (
    <div className="CheckoutStatusWarning">
      <div className="CheckoutStatusWarning__Title">
        <ExclamationCircleFilledIcon className="CheckoutStatusWarning__Icon" />
        <span>Previous workdays have Checkout Status messages.</span>
      </div>
      <div className="CheckoutStatusWarning__Details">
        <span className="CheckoutStatusWarning__Info">
          You have&nbsp;
          <span className="CheckoutStatusWarning__Count">
            {latestStatus.n_checkout_items}
          </span>
          &nbsp;unconfirmed checkout items on&nbsp;
          <span
            className="CheckoutStatusWarning__Date"
            onClick={handleDateClick}
          >
            {latestStatus.work_day.work_date}
          </span>
        </span>
        <Button
          className="CheckoutStatusWarning__Button"
          size="small"
          onClick={handleDissmissCurrent}
        >
          Dismiss
        </Button>
        {checkoutCheckStatuses.length > 1 && (
          <Button size="small" onClick={showModal}>
            Show {checkoutCheckStatuses.length - 1} more
          </Button>
        )}
      </div>
      <Modal
        visible={open}
        width={700}
        onCancel={closeModal}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <CheckoutStatusMessages
          onClose={closeModal}
          onDismiss={handleDismiss}
          statuses={checkoutCheckStatuses}
        />
      </Modal>
    </div>
  ) : (
    <></>
  );
};

export default CheckoutStatusWarning;
