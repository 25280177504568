import moment from "moment";

export const notifications: NotificationArray = [
  {
    id: "1",
    datetime: moment().subtract(1, "hours").toISOString(),
    list: undefined,
    project: "TeamMeister",
    unread: true,
  },
  {
    id: "2",
    datetime: moment().subtract(1, "hours").toISOString(),
    list: "Development",
    project: "TeamMeister",
    unread: true,
  },
  {
    id: "3",
    datetime: moment("2021-08-15", "YYYY-MM-DD").toISOString(),
    list: undefined,
    project: "TeamMeister",
    unread: false,
  },
  {
    id: "4",
    datetime: moment("2021-08-15", "YYYY-MM-DD").toISOString(),
    list: "Development",
    project: "TeamMeister",
    unread: false,
  },
  {
    id: "5",
    datetime: moment("2021-08-15", "YYYY-MM-DD").toISOString(),
    list: undefined,
    project: "TeamMeister",
    unread: false,
  },
  {
    id: "6",
    datetime: moment("2021-08-15", "YYYY-MM-DD").toISOString(),
    list: "Development",
    project: "TeamMeister",
    unread: false,
  },
  {
    id: "7",
    datetime: moment("2021-08-15", "YYYY-MM-DD").toISOString(),
    list: "Development",
    project: "TeamMeister",
    unread: false,
  },
  {
    id: "8",
    datetime: moment("2021-08-15", "YYYY-MM-DD").toISOString(),
    list: "Development",
    project: "TeamMeister",
    unread: false,
  },
  {
    id: "9",
    datetime: moment("2021-08-15", "YYYY-MM-DD").toISOString(),
    list: "Development",
    project: "TeamMeister",
    unread: false,
  },
  {
    id: "10",
    datetime: moment("2021-08-15", "YYYY-MM-DD").toISOString(),
    list: "Development",
    project: "TeamMeister",
    unread: false,
  },
  {
    id: "11",
    datetime: moment("2021-08-15", "YYYY-MM-DD").toISOString(),
    list: "Development",
    project: "TeamMeister",
    unread: true,
  },
];
