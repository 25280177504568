import React, { useState, useEffect } from "react";
import { Collapse } from "antd";
import { useDispatch, useSelector } from "react-redux";

import useRedirect from "../../../hooks/useRedirect.hook";

import ProjectHeader from "../ProjectHeader/ProjectHeader.component";
import TaskPanel from "../TaskPanel/TaskPanel.component";
import TaskHeader from "../TaskHeader/TaskHeader.component";

import { startMarkAsRead } from "../../../actions/unread.action";
import {
  getTaskIds,
  getUnreadActivitiesCount,
} from "../../../selectors/unread-activities.selector";
import { selectProjectsState } from "../../../reducers/projects.reducer";
import { formatUrlTitle } from "../../../utils/url.util";

const { Panel } = Collapse;
const PROJECT_PANEL_KEY = "project";

/**
 * ProjectCollapse represent all activities within a certained project.
 * it contains multiple tasks and can be collapsible.
 */
const ProjectCollapse = ({
  unreadProject,
  collapse,
  onToggle,
  filters = [],
}: ProjectCollapseProps) => {
  const redirect = useRedirect();

  const projects = useSelector(selectProjectsState).data;
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const projectColor = projects.find((p) => p.id === unreadProject.id)?.color;

  // track opened task keys
  const [openedTasks, setOpenedTasks] = useState<string[]>([]);

  // append or remove the key according to collapse state
  const handleTaskToggle = (key: string) => {
    setOpenedTasks((prev) => {
      if (prev.includes(key)) {
        return prev.filter((item) => item !== key);
      }
      return [...prev, key];
    });
  };

  // expand or collapse all tasks if collapse state changed
  useEffect(() => {
    if (collapse === "collapse") {
      setOpen(false);
      setOpenedTasks([]);
    } else if (collapse === "expand") {
      setOpen(true);
      setOpenedTasks(getTaskIds(unreadProject));
    }
  }, [collapse, unreadProject]);

  // expand or collapse all tasks if open state changed
  useEffect(() => {
    if (open) {
      setOpenedTasks(getTaskIds(unreadProject));
    } else {
      setOpenedTasks([]);
    }
    // should not add unreadProject here, since we only listen the change of open state
    // and expand/collapse tasks according to this state
    // eslint-disable-next-line
  }, [open]);

  const handleMarkTaskAsRead = (taskId: string) => {
    setOpenedTasks((prev) => prev.filter((key) => key !== taskId.toString()));
    dispatch(startMarkAsRead({ taskId }));
  };

  const handleMarkProjectAsRead = () => {
    setOpen(false);
    dispatch(startMarkAsRead({ projectId: unreadProject.id }));
  };

  const handleNavigateToProject = () => {
    redirect({
      path: `/p/${unreadProject.id}/${formatUrlTitle(
        unreadProject.project_name
      )}`,
    });
  };

  const handleNavigation = (
    type: "list" | "section" | "task",
    params: {
      listId?: string;
      listName?: string;
      sectionId?: string;
      sectionName?: string;
      taskId?: string;
      taskName?: string;
    }
  ) => {
    if (type === "list") {
      redirect({
        path: `/l/${params.listId}/${formatUrlTitle(params.listName!)}`,
      });
    } else if (type === "section") {
      redirect({
        path: `/s/${params.sectionId}/${formatUrlTitle(params.sectionName!)}`,
      });
    } else if (type === "task") {
      redirect({
        path: `/t/${params.taskId}/${formatUrlTitle(params.taskName!)}`,
      });
    }
  };

  const [listFilter, sectionFilter, taskFilter] = filters;

  // flat expand all task panels
  const tasks = unreadProject.lists
    .filter((list) => !listFilter || list.id === listFilter)
    .flatMap((listItem) => {
      const { id: list_id, list_name, sections } = listItem;
      return sections
        .filter((section) => !sectionFilter || section.id === sectionFilter)
        .flatMap((section) => {
          const { id: section_id, section_name, tasks } = section;
          return tasks
            .filter((task) => !taskFilter || task.id === taskFilter)
            .map((task) => (
              <Panel
                key={task.id}
                className="ProjectCollapse__task-panel"
                header={
                  <TaskHeader
                    count={task.activity_count}
                    open={openedTasks.includes(task.id.toString())}
                    listId={list_id}
                    listName={list_name}
                    sectionId={section_id}
                    sectionName={section_name}
                    taskId={task.id}
                    taskName={task.task_name}
                    onToggle={() => {
                      handleTaskToggle(task.id.toString());
                      onToggle("normal");
                    }}
                    onMarkAsRead={() => handleMarkTaskAsRead(task.id)}
                    onNavigate={handleNavigation}
                  />
                }
                showArrow={false}
              >
                <TaskPanel taskId={task.id} />
              </Panel>
            ));
        });
    });

  // calculate total activities count
  const count = getUnreadActivitiesCount(unreadProject);

  const activeProjectKey = open ? PROJECT_PANEL_KEY : "";
  return (
    <Collapse className="ProjectCollapse" activeKey={activeProjectKey}>
      <Panel
        className="ProjectCollapse__panel"
        key={PROJECT_PANEL_KEY}
        header={
          <ProjectHeader
            open={open}
            projectName={unreadProject.project_name}
            projectColor={projectColor?.title || "#fff"}
            count={count}
            onToggle={() => {
              setOpen((prev) => !prev);
              onToggle("normal");
            }}
            onMarkAsRead={() => handleMarkProjectAsRead()}
            onNavigate={() => handleNavigateToProject()}
          />
        }
        showArrow={false}
      >
        <Collapse
          className="ProjectCollapse__subCollapse"
          activeKey={openedTasks}
        >
          {tasks}
        </Collapse>
      </Panel>
    </Collapse>
  );
};

export default ProjectCollapse;
