import React from "react";

import useConsolidateTask from "./useConsolidateTask.hook";
import usePreviousHook from "./usePrevious.hook";

import {
  getArchivedTasksInSectionService,
  getTasksListService,
} from "../services/tasks.service";
import { dispatchError } from "../utils/error.util";
import { asyncFetchTaskObject } from "../utils/tasks.util";

import ws from "../sockets/websockets";

export interface Params {
  sectionId: SectionObject["id"];
  archived?: boolean;
  page?: number;
  pageSize?: number;
}
export default function useAsyncTasksInSection({
  sectionId,
  archived,
  page,
  pageSize,
}: Params) {
  const [tasksInSection, setTasksInSection] = React.useState<TaskArray>([]);
  const [loading, setLoading] = React.useState(false);
  const [maxCount, setMaxCount] = React.useState<number>();

  const [
    consolidatedTasksInSection,
    consolidatedTasksInSectionLoading,
  ] = useConsolidateTask(tasksInSection);

  const prevSectionId = usePreviousHook(sectionId);

  const isLoading = loading || consolidatedTasksInSectionLoading;

  const fetchTasksInSection = React.useCallback(async () => {
    if (sectionId) {
      try {
        setLoading(true);
        const response = await getTasksListService({
          section: sectionId,
          page,
          page_size: pageSize,
        });
        if (response.status === 200) {
          setLoading(false);
          setMaxCount(response.data.count as number);

          if (!!prevSectionId && prevSectionId === sectionId && page !== 1) {
            setTasksInSection((c) => [
              ...c,
              ...(response.data.results as TaskArray),
            ]);
          } else {
            setTasksInSection(response.data.results as TaskArray);
          }
        } else {
          throw new Error();
        }
      } catch (e) {
        setLoading(false);
        dispatchError({
          e,
          title: "Fetch tasks in section error",
        });
      }
    } else {
      setTasksInSection([]);
    }
  }, [archived, sectionId, page, pageSize, prevSectionId]);

  React.useEffect(() => {
    if (prevSectionId !== sectionId) {
      setTasksInSection([]);
      setMaxCount(undefined);
    }
  }, [prevSectionId, sectionId]);

  const fetchArchivedTasksInSection = React.useCallback(async () => {
    try {
      setLoading(true);
      const response = await getArchivedTasksInSectionService(sectionId);
      if (response.status === 200) {
        setLoading(true);
        setTasksInSection(response.data as TaskArray);
      } else {
        throw new Error();
      }
    } catch (e) {
      setLoading(true);
      dispatchError({
        e,
        title: "Fetch archived tasks in section error",
      });
    }
  }, [sectionId]);

  React.useEffect(() => {
    archived ? fetchArchivedTasksInSection() : fetchTasksInSection();
  }, [archived, fetchArchivedTasksInSection, fetchTasksInSection]);

  React.useEffect(() => {
    const wsOnMessage = async (event: MessageEvent) => {
      try {
        const messageEventData = JSON.parse(event.data);
        const { event: eventType } = messageEventData;

        if (eventType === "new_task") {
          const eventSectionId = messageEventData.meta.data.section.id;
          if (sectionId === eventSectionId) {
            fetchTasksInSection();
          }
        } else if (eventType === "new_join_task_to_user") {
          const eventTaskId = messageEventData.meta.task_id;
          const eventTask = await asyncFetchTaskObject(eventTaskId);

          // synchronize members of task record
          if (!!eventTask) {
            setTasksInSection((c) =>
              c
                ? c.map((task) => {
                    if (task.id === eventTaskId) {
                      return eventTask;
                    }
                    return task;
                  })
                : c
            );
          }
        }
      } catch (e) {}
    };

    ws.addEventListener("message", wsOnMessage);

    return () => {
      ws.removeEventListener("message", wsOnMessage);
    };
  }, [fetchTasksInSection, sectionId]);

  return [consolidatedTasksInSection, isLoading, maxCount] as [
    TaskJoinedToUserAndScheduledArray | undefined,
    boolean,
    number
  ];
}
