import { Dispatch } from "redux";

import { getTeammatesService } from "../services/teammates";
import { dispatchError } from "../utils/error.util";

export const requestTeammates = () => ({
  type: "REQUEST_TEAMMATES",
});
export const requestTeammatesSuccess = (teammates: Teammate[]) => ({
  type: "REQUEST_TEAMMATES_SUCCESS",
  teammates,
});
export const requestTeammatesFailed = (error: string) => ({
  type: "REQUEST_TEAMMATES_FAILED",
  error,
});
export const startPopulateTeammates = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(requestTeammates());
      const response = await getTeammatesService();
      if (response.status === 200) {
        dispatch(requestTeammatesSuccess(response.data || []));
      } else {
        const error = "Request teammates failed";
        dispatch(requestTeammatesFailed(error));
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestTeammatesFailed(error));
      dispatchError({
        e,
        title: "Populate teammates error",
      });
    }
  };
};
