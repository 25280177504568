import React from "react";

import { ActivityTabContentContext } from "../ActivityTabContent/ActivityTabContent";

import {
  DraftIcon,
  LoadingIcon,
} from "../../CustomIcons/CustomIcons.component";
import CreateActivityDraftDropdown from "../../Activities/CreateActivityDraftDropdown/CreateActivityDraftDropdown";
import ActivityCardItem from "../../Activities/ActivityCardItem/ActivityCardItem";

/**
 * Default display
 * Displays list of draft activities of the current user and task
 * Displays "You don't have drafts." if list is empty
 */
const MyDrafts: React.FC = () => {
  const {
    userDrafts: {
      data: userDraftsData,
      loading: userDraftsLoading,
      creating: userDraftsCreating,
    },
  } = React.useContext(ActivityTabContentContext);

  return (
    <div className="MyDrafts">
      <div className="MyDrafts__Header">
        <CreateActivityDraftDropdown />
        <div className="MyDrafts__Header__Title">
          <DraftIcon className="MyDrafts__Header__Title__DraftIcon" />
          <span>My drafts</span>
        </div>
      </div>

      <div className="MyDrafts__Body">
        {userDraftsCreating && (
          <div className="ActivityCardItem ActivityCardItem--Loading">
            <LoadingIcon />
          </div>
        )}

        {!userDraftsLoading &&
          userDraftsData.length > 0 &&
          userDraftsData.map((activity, index) => {
            return <ActivityCardItem activityObject={activity} key={index} />;
          })}

        {!userDraftsLoading && userDraftsData.length === 0 && (
          <div className="MyDrafts__Body__NoDrafts">
            <span>You don't have drafts.</span>
          </div>
        )}
      </div>
    </div>
  );
};

export { MyDrafts as default };
