import { find } from "lodash";

import { personalTasksProjectObject } from "../constants/constants";
import projectsColors from "../fixtures/projects-colors.fixture";

const projects: ProjectArray = [
  {
    id: "ACYKFFFQ3J",
    workspace: "6HDCHAGWWA",
    color: { id: "IMUE28DG8Y", value: "#FDF0F2", title: "#FDF0F2" },
    created_date: "2021-08-09T01:35:02.148582Z",
    title: "Sample Project A",
    description: "Sample Project A description",
    archived_yn: false,
    show_get_started_message_yn: true,
    loading: false,
  },
  {
    id: "49MWW016DJ",
    workspace: "6HDCHAGWWA",
    color: { id: "QWD2N2HMNA", value: "#9E9CC2", title: "#9E9CC2" },
    created_date: "2021-08-10T03:45:36.354867Z",
    title: "Sample Project B",
    description: "Sample Project B description",
    archived_yn: false,
    show_get_started_message_yn: true,
    loading: false,
  },
];

export const projectObject = projects[0];

export const projectsWithPersonalTasks = [
  personalTasksProjectObject,
  ...projects,
];

export { projects as default };
