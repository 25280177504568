import React from "react";

import { TeamIcon } from "../../../CustomIcons/CustomIcons.component";
import ActivityActions from "../../ActivityActions/ActivityActions";
import ActivityTimestamp from "../../ActivityTimestamp/ActivityTimestamp";
import { ActivityCardItemContext } from "../../ActivityCardItem/ActivityCardItem";

/**
 * Default Display
 * ActivityCardItemHeader is the header of an activity card item
 * Displays the name of the user who created the activity
 * Displays the [confirmed, updated, deleted] timestamp of the activity
 * Displays the "New" tag for unread activities
 * Displays the actions the user can take for an activity
 */
const ActivityCardItemHeader: React.FC = () => {
  const context = React.useContext(ActivityCardItemContext);
  const {
    activityBelongsToOtherUser,
    activityObject,
    activityReadStatus,
    activityUserDetails,
  } = context;
  const { last_update_date, confirmed_yn, deleted_yn } = activityObject;
  const { display_name } = activityUserDetails;

  const displayDrafItems = !confirmed_yn && !deleted_yn;
  const displayUnreadItems =
    confirmed_yn &&
    activityBelongsToOtherUser &&
    activityReadStatus === false &&
    !deleted_yn;

  const displayDeletedTimeAgo = !!deleted_yn && !!last_update_date;

  const displayActions = !deleted_yn;

  return (
    <div className="ActivityCardItemHeader">
      <div className="ActivityCardItemHeader__Details">
        <span className="ActivityCardItemHeader__Details__Name">
          {display_name}
        </span>

        <ActivityTimestamp />

        {displayUnreadItems && (
          <div className="ActivityCardItemHeader__Details__UnreadTag">New</div>
        )}
      </div>

      {displayActions && (
        <ActivityActions displayUnreadItems={!!displayUnreadItems} />
      )}

      {displayDeletedTimeAgo && (
        <div className="ActivityCardItemHeader__DeletedDateTimeAgo">
          <ActivityTimestamp deletedTimeAgo={true} />
        </div>
      )}
    </div>
  );
};

export default ActivityCardItemHeader;
