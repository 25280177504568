import moment, { Moment } from "moment";
import { sumBy } from "lodash";

import { workDayDateFormat } from "../constants/constants";
import { getDaysBetweenDates } from "./dates.util";

export const getTotalSecondsWorkedInArray = (array: TaskTimeArray) =>
  sumBy(array, "seconds_worked");

type GetUserWorkTimeTableDataCompleteRecords = (
  startDate: Moment,
  endDate: Moment,
  responseData: UserTaskTimeTableDataResponse
) => UserTaskTimeTableDataResponse;
export const getUserWorkTimeTableDataCompleteRecords: GetUserWorkTimeTableDataCompleteRecords = (
  startDate,
  endDate,
  responseData
) => {
  const daysInRange: string[] = getDaysBetweenDates(
    startDate,
    endDate,
    workDayDateFormat
  );
  const recordsWithoutWorkDay = daysInRange
    .filter(
      (day) => !responseData.records.map(({ date }) => date).includes(day)
    )
    .map((day) => {
      return {
        date: day,
      };
    });
  const completeSortedRecords: UserTaskTimeTableDataResponse["records"] = [
    ...responseData.records,
    ...recordsWithoutWorkDay,
  ].sort((a, b) => {
    return moment(a.date).diff(b.date);
  });

  return {
    ...responseData,
    records: completeSortedRecords,
  };
};
