import React, { useState } from "react";
import cn from "classnames";
import { Row, Col, Tooltip } from "antd";
import moment from "moment";

import useDeleteAnimation from "../../../hooks/useDeleteAnimation.hook";

import ActivityContent from "../ActivityContent/ActivityContent.component";
import { CopyLinkIcon } from "../../CustomIcons/CustomIcons.component";
import CustomAvatar from "../../CustomAvatar/CustomAvatar";

import { getInitials } from "../../../utils/string.util";

/**
 * A Activity is a message committed by a worker.
 * It contains a username, avatar, date and a message(activity) content.
 * An oprations section will popup if the mouse enters the activity body, or enters the section itself.
 * There will be a delete animation when the activiy is marked as read and removed from unread page.
 */
const Activity = ({ activity, onMarkAdRead }: ActivityProps) => {
  const [showOperations, setShowOperations] = useState(false);
  const { opacity, collapse, height, ref, handleDelete } = useDeleteAnimation({
    onDelete: onMarkAdRead,
  });

  const handleMouseEnter = () => {
    setShowOperations(true);
  };
  const handleMouseLeave = () => {
    setShowOperations(false);
  };

  const textList = Array.isArray(activity.text_json)
    ? activity.text_json
    : [activity.text_json];
  return (
    <Row
      ref={ref}
      style={height !== 0 ? { height: `${height + 20}px` } : {}}
      className={cn("Activity", {
        "Activity-opacity": opacity,
        "Activity-collapse": collapse,
      })}
      justify="space-between"
      align="top"
      wrap={false}
    >
      <Col className="Activity__avatar">
        <CustomAvatar
          src={activity.avatar}
          fallbackText={getInitials(activity.username)}
        />
      </Col>
      <Col className="Activity__content">
        <Row justify="space-between" align="middle">
          <Col>
            <span className="Activity__name">{activity.username}</span>
            <span className="Activity__time">
              {moment(activity.created_datetime).format("h:mm A")}
            </span>
            <span className="Activity__tag">New</span>
          </Col>
          <Col
            className={cn("Activity__operations", {
              "Activity__operations-show": showOperations,
            })}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <Row align="middle">
              {/*
              * Temporarily removed as Bug 20220323-1 until feature is implemented
              <Tooltip title="Copy link">
                <div className="Activity__operation Activity__link">
                  <CopyLinkIcon className="Activity__icon" />
                </div>
              </Tooltip>
              */}
              <div className="Activity__operation" onClick={handleDelete}>
                Mark as read
              </div>
            </Row>
          </Col>
        </Row>
        <Row
          className="Activity__body"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <ActivityContent text={textList} />
        </Row>
      </Col>
    </Row>
  );
};

export default Activity;
