import { Dispatch } from "redux";
import moment from "moment";

import { RootState } from "../store/configureStore";
import {
  createStatusService,
  getCurrentUserStatus,
  leaveTheOfficeService,
} from "../services/status.service";
import { outOfOfficeStatusType } from "../constants/constants";
import { dispatchError } from "../utils/error.util";
import {
  setCurrentWorkDayDefault,
  startSetCurrentWorkDay,
} from "./work-day.action";

export const setStatus = (type: string, status: Status) => {
  return {
    type,
    status,
  };
};

export const requestCreateStatus = () => ({
  type: "REQUEST_CREATE_STATUS",
});
export const requestCreateStatusFailed = (error: string) => ({
  type: "REQUEST_CREATE_STATUS_FAILED",
  error,
});

export const startCreateStatus = (data: StatusTypeObject) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(requestCreateStatus());
      const response = await createStatusService({
        status_enum_identifier: data.id,
      });
      if (response.status === 201) {
        dispatch(startSetCurrentWorkDay(data));
      } else {
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestCreateStatusFailed(error));
      dispatchError({
        e,
        title: "Create status error",
      });
      return e;
    }
  };
};

export const requestFetchStatus = () => ({
  type: "REQUEST_FETCH_STATUS",
});
export const requestFetchStatusFailed = (error: string) => ({
  type: "REQUEST_FETCH_STATUS_FAILED",
  error,
});
export const startGetCurrentUserStatus = () => {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    try {
      dispatch(requestFetchStatus());
      const currentUserStatusResponse = await getCurrentUserStatus();
      if (currentUserStatusResponse.status === 200) {
        const currentUserStatus = currentUserStatusResponse.data;
        const statusTypes: StatusTypeArray = getState().statusTypes.data;
        const currentUserStatusType = statusTypes.filter((statusType) => {
          return statusType.id === currentUserStatus.status_enum_identifier;
        })[0];

        /**
         * Check if last status is not from today or has ended, redirect to start work page if so
         */
        const lastLogIsToday = moment().isSame(
          moment(currentUserStatus.start_datetime),
          "day"
        );
        const lastLogHasEnded = !!currentUserStatus.end_datetime;

        if (!lastLogIsToday && !lastLogHasEnded) {
          const leaveTheOfficeResponse = await leaveTheOfficeService();
          if (leaveTheOfficeResponse.status === 200) {
            dispatch(
              setStatus("REQUEST_FETCH_STATUS_SUCCESS", outOfOfficeStatusType)
            );
            dispatch(setCurrentWorkDayDefault());
          }
        } else if (lastLogHasEnded) {
          dispatch(
            setStatus("REQUEST_FETCH_STATUS_SUCCESS", outOfOfficeStatusType)
          );
          dispatch(setCurrentWorkDayDefault());
        } else if (currentUserStatusType) {
          dispatch(
            setStatus("REQUEST_FETCH_STATUS_SUCCESS", currentUserStatusType)
          );
        }
      } else {
        const error = "getCurrentUserStatus status is not 200";
        dispatch(requestFetchStatusFailed(error));
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestFetchStatusFailed(error));
      dispatchError({
        e,
        title: "Get current user status error",
      });
    }
  };
};
