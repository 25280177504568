import { RootState } from "../store/configureStore";

export const selectTaskDetailsState = (rootState: RootState) =>
  rootState.taskDetails;

export const taskDetailsReducerDefaultState: TaskJoinedToUserAndScheduledObject = {} as TaskJoinedToUserAndScheduledObject;

const taskDetailsReducer = (
  state = taskDetailsReducerDefaultState,
  action: { type: string; taskDetails: TaskJoinedToUserAndScheduledObject }
) => {
  switch (action.type) {
    case "SET_TASK_DETAILS":
      return action.taskDetails;
    default:
      return state;
  }
};

export { taskDetailsReducer as default };
