import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { FlagsProvider } from "flagged";

import useCheckBetaVersion from "../hooks/useCheckBetaVersion.hook";

import { defaultPage, workStatuses } from "../constants/constants";
import { selectStatusState } from "../reducers/status.reducer";
import { selectAuthState } from "../reducers/auth.reducer";

export const PublicRoute = () => {
  // check if using beta version
  const isBetaVersion = useCheckBetaVersion();

  const {
    data: { token: authToken },
  } = useSelector(selectAuthState);

  const {
    data: { id: currentStatusId },
  } = useSelector(selectStatusState);

  return (
    <FlagsProvider
      features={{
        under_development: isBetaVersion,
      }}
    >
      {!authToken && <Outlet />}

      {!!authToken &&
        (currentStatusId === workStatuses.working ||
          currentStatusId === workStatuses.taking_break) && (
          <Navigate to={defaultPage} replace />
        )}

      {!!authToken && currentStatusId === workStatuses.out_of_office && (
        <Navigate to="/start-work" replace />
      )}
    </FlagsProvider>
  );
};

export { PublicRoute as default };
