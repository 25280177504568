import React from "react";
import { Popover } from "antd";

import { MoreActionsIcon } from "../../../CustomIcons/CustomIcons.component";
import TeamMemberSelectorMoreActionsPopoverContent from "./TeamMemberSelectorMoreActionsPopoverContent/TeamMemberSelectorMoreActionsPopoverContent";

const TeamMemberSelectorMoreActions: React.FC<TeamMemberSelectorMoreActionsProps> = ({
  selectAll,
  deselectAll,
}) => {
  const [visible, setVisible] = React.useState(false);

  const handleVisibleChange = (visible: boolean) => {
    setVisible(visible);
  };

  return (
    <div className="TeamMemberSelectorMoreActions">
      <Popover
        content={
          <TeamMemberSelectorMoreActionsPopoverContent
            selectAll={() => {
              setVisible(false);
              selectAll();
            }}
            deselectAll={() => {
              setVisible(false);
              deselectAll();
            }}
          />
        }
        overlayClassName="TeamMemberSelectorMoreActions__Popover"
        placement="bottomRight"
        trigger="click"
        getPopupContainer={(trigger: any) => trigger}
        visible={visible}
        onVisibleChange={handleVisibleChange}
      >
        <MoreActionsIcon />
      </Popover>
    </div>
  );
};

export default TeamMemberSelectorMoreActions;
