import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import useRedirect from "../../../../hooks/useRedirect.hook";
import useWorkTimesByDate from "../../../../hooks/useWorkTimesByDate.hook";

import { LoadingIcon } from "../../../CustomIcons/CustomIcons.component";
import CheckoutModalToggleBtn from "../../../Checkout/CheckoutModalToggleBtn/CheckoutModalToggleBtn";
import CheckoutStatusIcon from "../../../Checkout/CheckoutStatusIcon/CheckoutStatusIcon";

import { menuStaticKeys } from "../../../../constants/constants";
import { getTotalDuration } from "../../../../utils/time-conversion.util";
import { selectCurrentWorkDayState } from "../../../../reducers/work-day.reducer";

interface InTheOfficeBtnPopoverContentProps {
  setDisplayPopover: React.Dispatch<React.SetStateAction<boolean>>;
}
const InTheOfficeBtnPopoverContent: React.FC<InTheOfficeBtnPopoverContentProps> = ({
  setDisplayPopover,
}) => {
  const redirect = useRedirect();

  const {
    data: { work_date: workDate },
  } = useSelector(selectCurrentWorkDayState);

  const [workDateWorkTimes, workDateWorkTimesLoading] = useWorkTimesByDate(
    workDate
  );

  const handleClickCheckout = () => {
    redirect({
      path: menuStaticKeys.CHECKOUT,
    });
    setDisplayPopover(false);
  };

  return (
    <div className="InTheOfficeBtnPopoverContent">
      <div className="InTheOfficeBtnPopoverContent__Card">
        {workDateWorkTimesLoading &&
        workDateWorkTimes.work_time === 0 &&
        workDateWorkTimes.break_time === 0 ? (
          <LoadingIcon className="InTheOfficeBtnPopoverContent__Time-Loading" />
        ) : (
          <div className="InTheOfficeBtnPopoverContent__Time">
            <div className="InTheOfficeBtnPopoverContent__Time__Item InTheOfficeBtnPopoverContent__Time__Item--Work">
              <span>Work</span>
              <span className="InTheOfficeBtnPopoverContent__Time__Item__Time">
                {getTotalDuration(
                  moment.duration(workDateWorkTimes.work_time, "seconds")
                )}
              </span>
            </div>
            <div className="InTheOfficeBtnPopoverContent__Time__Item InTheOfficeBtnPopoverContent__Time__Item--Break">
              <span>Break</span>
              <span className="InTheOfficeBtnPopoverContent__Time__Item__Time">
                {getTotalDuration(
                  moment.duration(workDateWorkTimes.break_time, "seconds")
                )}
              </span>
            </div>
          </div>
        )}
        <CheckoutModalToggleBtn setDisplayPopover={setDisplayPopover} />
      </div>
      <div className="InTheOfficeBtnPopoverContent__Card">
        <div
          className="InTheOfficeBtnPopoverContent__Checkout"
          onClick={handleClickCheckout}
        >
          <span>Go to Checkout</span>
          <CheckoutStatusIcon />
        </div>
      </div>
    </div>
  );
};

export default InTheOfficeBtnPopoverContent;
