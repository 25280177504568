import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Modal as AntModal } from "antd";

import useRedirect from "./useRedirect.hook";

import { startPopulateLists } from "../actions/list.action";
import { startPopulateProjects } from "../actions/projects.action";
import { startPopulateSections } from "../actions/section.action";
import { defaultPage } from "../constants/constants";
import { selectProjectsState } from "../reducers/projects.reducer";
import { getProjectColorService } from "../services/projects-colors.service";
import {
  getMyProjectMemberships,
  getProjectRecord,
} from "../services/projects.service";
import { dispatchError } from "../utils/error.util";

export interface Args {
  action: string;
  resource_id: string;
  table_name: string;
}
export default function useSynchronizeProjectsMemberships() {
  const { projectId } = useParams<{
    projectId?: string;
  }>();

  const dispatch = useDispatch();
  const redirect = useRedirect();

  const { data: projects, loading: projectsLoading } = useSelector(
    selectProjectsState
  );

  return React.useCallback(
    async (messageEventData: Args) => {
      // synchronize projects membership
      try {
        const { action, resource_id, table_name } = messageEventData;

        if (table_name === "project_membership") {
          const projectMemberships = (await getMyProjectMemberships()) || [];

          if (
            action === "create" &&
            projectMemberships.length !== projects.length
          ) {
            const newProjectId = (
              projectMemberships.find(({ id }) => id === resource_id) || {}
            ).project;
            const newProject = newProjectId
              ? await getProjectRecord(newProjectId)
              : undefined;
            const newProjectColorId = newProject?.color;
            const newProjectWithData = {
              ...newProject,
              color: newProjectColorId
                ? await getProjectColorService(newProjectColorId)
                : null,
            } as ProjectObject;

            if (newProjectId && newProject) {
              if (projects.map(({ id }) => id).includes(newProjectId)) {
                dispatch({
                  type: "UPDATE_PROJECT",
                  project: newProjectWithData,
                });
              } else {
                dispatch({
                  type: "ADD_PROJECT",
                  project: newProjectWithData,
                });
              }
            }
          } else if (action === "delete") {
            dispatch(startPopulateProjects());
            dispatch(startPopulateLists());
            dispatch(startPopulateSections());

            const projectsWithMemberships = projectMemberships.map(
              ({ project }) => project
            );
            // check if user is on page where membership was deleted
            if (projectId && !projectsWithMemberships.includes(projectId)) {
              AntModal.info({
                title: "Info",
                content: `Your membership for this project has been removed.`,
                onOk: () => {
                  redirect({ path: defaultPage });
                },
                okText: "OK",
                cancelButtonProps: {
                  style: { display: "none" },
                },
                centered: true,
              });
            }
          }
        }
      } catch (e) {
        dispatchError({
          e,
          title: "Synchronize project membership error",
        });
      }
    },
    [dispatch, projects, projectId]
  );
}
