import { message } from "antd";

// replace special chars with "-" in url title
// /[&\/\\#,+()$~%.'":*?<>{}]/g
const formatUrlTitle = (url: string) =>
  url
    .replace(/[\s\/\\\[\]\-|`~!@#$%^&*()_+=;',.{}:"<>?`]+/g, "-")
    .toLowerCase();

const copyUrlToClipboard = async (url: string) => {
  await navigator.clipboard.writeText(url);
  message.success("Copied");
};

export { formatUrlTitle, copyUrlToClipboard };
