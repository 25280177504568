import { TimePicker, Popover } from "antd";
import React from "react";
import cn from "classnames";
import moment, { Moment } from "moment";

const format = "HH:mm";

/*
 * ShiftTimePicker implements the shift creation and updation using antd's TimePicker
 * It will validate that if the added time is overlapped with the current time which is
 * described by [min, max] value. It will also trigger error message once the added time is invalid.
 * Please note that the selected time will always be rounded to minute.
 */
const ShiftTimePicker: React.FC<ShiftTimePickerProps> = ({
  start,
  end,
  startRef,
  endRef,
  min,
  max,
  size = "normal",
  startErrorMessage,
  endErrorMessage,
  onStartChange,
  onEndChange,
  onError,
}) => {
  const [startError, setStartError] = React.useState("");
  const [endError, setEndError] = React.useState("");
  const [startVisible, setStartVisible] = React.useState(false);
  const [endVisible, setEndVisible] = React.useState(false);

  React.useEffect(() => {
    if (startErrorMessage) {
      setStartError(startErrorMessage);
    }
  }, [startErrorMessage]);

  React.useEffect(() => {
    if (endErrorMessage) {
      setEndError(endErrorMessage);
    }
  }, [endErrorMessage]);

  const isOutOfRange = (value: Moment) => {
    const roundedValue = value.startOf("minute");
    if (min && max) {
      const roundedMin = moment(min).startOf("minute");
      const roundedMax = moment(max).startOf("minute");
      return (
        roundedValue.isBefore(roundedMin) || roundedValue.isAfter(roundedMax)
      );
    }
    return false;
  };

  const handleSelectStart = (value: Moment | null) => {
    if (value) {
      if (isOutOfRange(value)) {
        setStartError(
          "The start time must be within the start and end times of the shift."
        );
        setStartVisible(true);
        if (onError) {
          onError(true);
        }
      } else {
        setStartError("");
        setStartVisible(false);
        if (onError) {
          onError(false);
        }
      }
      // round the time to the start of minute
      onStartChange(moment(value).startOf("minute"));
    }
  };
  const handleSelectEnd = (value: Moment | null) => {
    if (value) {
      if (isOutOfRange(value)) {
        setEndError(
          "The end time must be within the start and end times of the shift."
        );
        setEndVisible(true);
        if (onError) {
          onError(true);
        }
      } else {
        setEndError("");
        setEndVisible(false);
        if (onError) {
          onError(false);
        }
      }
      onEndChange(moment(value).startOf("minute"));
    }
  };

  const startPicker = (
    <TimePicker
      className={cn({ ShiftTimePicker__Error: !!startError })}
      ref={startRef}
      defaultValue={moment(start).startOf("minute")}
      allowClear={false}
      format={format}
      suffixIcon={false}
      onChange={handleSelectStart}
    />
  );

  const endPicker = (
    <TimePicker
      className={cn({ ShiftTimePicker__Error: !!endError })}
      ref={endRef}
      defaultValue={moment(end).startOf("minute")}
      allowClear={false}
      format={format}
      suffixIcon={false}
      onChange={handleSelectEnd}
    />
  );

  const errorPopover = (error: string) => (
    <div className="ShiftTimePicker__ErrorMsg">{error}</div>
  );

  return (
    <div
      className={cn("ShiftTimePicker", {
        "ShiftTimePicker-small": size === "small",
      })}
    >
      <div className="ShiftTimePicker__Column">
        <div className="ShiftTimePicker__Text">Start</div>
        {startError ? (
          <Popover
            destroyTooltipOnHide={true}
            getPopupContainer={(trigger: any) => trigger}
            trigger="click"
            placement="top"
            content={errorPopover(startError)}
            visible={startVisible}
            onVisibleChange={(visible: boolean) => setStartVisible(visible)}
          >
            {startPicker}
          </Popover>
        ) : (
          startPicker
        )}
      </div>
      <span className="ShiftTimePicker__Divider">-</span>
      <div className="ShiftTimePicker__Column">
        <div className="ShiftTimePicker__Text">End</div>
        {endError ? (
          <Popover
            destroyTooltipOnHide={true}
            getPopupContainer={(trigger: any) => trigger}
            trigger="click"
            placement="top"
            content={errorPopover(endError)}
            visible={endVisible}
            onVisibleChange={(visible: boolean) => setEndVisible(visible)}
          >
            {endPicker}
          </Popover>
        ) : (
          endPicker
        )}
      </div>
    </div>
  );
};

export default ShiftTimePicker;
