import error from "../fixtures/error.fixture";
import { RootState } from "../store/configureStore";

export interface ActivityTypesState {
  data: ActivityTypeArray;
  error: string;
  loading: boolean;
}
export const selectActivityTypesState = (rootState: RootState) =>
  rootState.activityTypes;
export const activityTypesReducerDefaultState: ActivityTypesState = {
  data: [],
  error: "",
  loading: false,
};

const activityTypesReducer = (
  state = activityTypesReducerDefaultState,
  action: { type: string; activityTypes: ActivityTypeArray; error: string }
) => {
  switch (action.type) {
    case "REQUEST_FETCH_ACTIVITY_TYPES":
      return {
        ...state,
        error: "",
        loading: true,
      };
    case "REQUEST_FETCH_ACTIVITY_TYPES_SUCCESS":
    case "POPULATE_ACTIVITY_TYPES":
      return {
        data: action.activityTypes,
        error: "",
        loading: false,
      };
    case "REQUEST_FETCH_ACTIVITY_TYPES_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export { activityTypesReducer as default };
