import axios from "../apis/django.instance";
import { dispatchError } from "../utils/error.util";

/**
 * Deprecated
 * @returns array of {
 * access_type_enum: null
 * archived_yn: false
 * id: "HCX7JRA4RM"
 * membership_yn: false
 * parent_project: "4N9OA5JJLC"
 * parent_type_enum: "project"
 * parent_user: null
 * removed_yn: false
 * show_list_in_sidebar_yn: null
 * title: "Address Checking"
 * }
 */
export const getListArrayService = () => {
  return axios.get("/api/projects/task_list/");
};

/**
 *
 * @returns array of {
 * archived_yn: false
 * authorized_user: null
 * id: "HCX7JRA4RM"
 * latest_activity: "2022-05-13T08:01:22+00:00"
 * parent_project: "4N9OA5JJLC"
 * parent_type_enum: "project"
 * parent_user: null
 * removed_yn: false
 * show_list_in_sidebar_yn: false
 * task_count: 2
 * title: "Address Checking"
 * unread_activity_count: 0
 * }
 */
export const fetchTaskListsService = async () => {
  try {
    const response = await axios.get("/api/projects/task_lists/");
    if (response.status == 200) {
      return response.data as ListArray;
    } else {
      throw new Error();
    }
  } catch (e) {
    dispatchError({
      e,
      title: "Fetch task lists error",
    });
    return null;
  }
};

export const fetchListRecordService: FetchListRecordService = async (id) => {
  try {
    const response = await axios.get(`/api/projects/task_list/${id}/`);
    if (response.status === 200) {
      return response.data as ListObject;
    } else {
      throw new Error();
    }
  } catch (e) {}
};

export const createListRecordService: CreateListRecordService = (data) => {
  return axios.post("/api/projects/task_list/", data);
};

export const updateWorkDayRecordService: UpdateListRecordService = (data) => {
  const { id, ...payload } = data;
  return axios.put(`/api/projects/task_list/${id}/`, payload); // must be in this format in order to mock implementation in unit test
};

export const getListRemovedSectionsService = (listId: ListObject["id"]) => {
  // TODO 13.11.3 - update with actual endpoint
  return axios.get(`/api/projects/task_list_removed_sections/${listId}`);
};

export const getListSectionsWithArchivedTasksService = (
  listId: ListObject["id"]
) => {
  // TODO 13.11.3 - update with actual endpoint
  return axios.get(
    `/api/projects/task_list_sections_with_archived_tasks/${listId}`
  );
};

export const unarchiveTasksInSectionService = (
  sectionId: SectionObject["id"]
) => {
  // TODO 13.11.3 - update with actual endpoint
  return axios.put(`projects/unarchive_section_tasks/${sectionId}`);
};

export const getMyListMemberships = async () => {
  try {
    const response = await axios.get(
      "/api/projects/task_list_memberships/my_memberships/"
    );
    if (response.status === 200) {
      return response.data as TaskListMemberArray;
    } else {
      throw new Error();
    }
  } catch (e) {
    dispatchError({
      e,
      title: "Get list memberships error",
    });
    return null;
  }
};
