import React from "react";
import Label from "../Label/Label";

const LabelList: React.FC<LabelListProps> = ({
  labels,
  defaultKeys,
  onChange,
  onEdit,
}) => {
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>(defaultKeys);

  const handleLabelToggle = (key: string) => {
    let newKeys;
    if (!!selectedKeys.find((item) => item === key)) {
      newKeys = selectedKeys.filter((item) => item !== key);
    } else {
      newKeys = [...selectedKeys, key];
    }
    setSelectedKeys(newKeys);
    onChange(newKeys);
  };

  const labelsJsx = labels.map((label) => (
    <Label
      key={label.id}
      label={label}
      checked={!!selectedKeys.find((key) => key === label.id)}
      onChange={handleLabelToggle}
      onEdit={onEdit}
    />
  ));
  return <div className="LabelList">{labelsJsx}</div>;
};

export default LabelList;
