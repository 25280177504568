import React from "react";
import { useSelector } from "react-redux";

import { selectProjectsState } from "../reducers/projects.reducer";
import { getUsersByProjectService } from "../services/users.service";

export default function useAsyncWorkspaceContributors(
  role: TaskContributorRole
) {
  const { data: projects } = useSelector(selectProjectsState);

  const [workspaceContributors, setWorkspaceContributors] = React.useState<
    ProjectUser[]
  >([]);
  const [loadingState, setLoadingState] = React.useState<LoadingState>("false");

  const getWorkspaceContributors = React.useCallback(async () => {
    setLoadingState("true");
    const workspaceUsers = await Promise.all(
      projects.map(async ({ id: projectId }) => {
        try {
          const response: ProjectUser[] = await getUsersByProjectService(
            projectId
          );
          return response.filter(({ accessType }) => accessType === role);
        } catch (e) {
          return [] as ProjectUser[];
        }
      })
    );

    // flatten into one array
    const flattedWorkspaceUsers = ([] as ProjectUser[]).concat.apply(
      [] as ProjectUser[],
      workspaceUsers
    );

    // remove duplicate users
    const noDuplicateWorkspaceUsers = flattedWorkspaceUsers.filter(
      (value, index, self) => {
        return self.map(({ id }) => id).indexOf(value.id) === index;
      }
    );
    setLoadingState("success");
    setWorkspaceContributors(noDuplicateWorkspaceUsers);
  }, [projects]);

  React.useEffect(() => {
    getWorkspaceContributors();
  }, [getWorkspaceContributors]);

  const returnValue: [ProjectUser[], LoadingState] = [
    workspaceContributors,
    loadingState,
  ];

  return returnValue;
}
