import React from "react";
import { Dropdown, Input, Menu } from "antd";
import cn from "classnames";
import { DragDropContext } from "react-beautiful-dnd";

import Button from "../../Button/Button";
import {
  AddIcon,
  CloseIcon,
  DeleteIcon,
  MoreActionsIcon,
} from "../../CustomIcons/CustomIcons.component";
import DndTable from "../../General/DndTable/DndTable";
import Modal from "../../General/Modal/Modal";
import { archivedTitle } from "../ManageSectionsModal/ManageSectionsModal";

export interface AddSectionsModalProps {
  handleCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  handleOk?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  visible: boolean;
}
const AddSectionsModal: React.FC<AddSectionsModalProps> = ({
  handleCancel,
  handleOk = () => {},
  visible,
}) => {
  const [sections, setSections] = React.useState<Section[]>([]);
  const [isAdding, setIsAdding] = React.useState(false);
  const [sectionName, setSectionName] = React.useState("");

  // make DndTable undraggble if last drag-drop request hasn't finished yet
  const [draggable, setDraggable] = React.useState(true);

  const hasInput = !!sectionName;
  const sectionNameExists = !!sections.find(
    ({ section }) => sectionName === section
  );

  const handleDragEnd = async (result: any) => {
    setDraggable(false);
    const {
      source: { index: srcIndex, droppableId: srcTable },
      destination: { index: dstIndex, droppableId: dstTable },
    } = result;

    // update the section list immediately to make UE more smoothly
    const curSections = [...sections];
    if (srcTable === "active" && dstTable === "active") {
      const [section] = curSections.splice(srcIndex, 1);
      curSections.splice(dstIndex, 0, section);
      setSections(curSections);
    }
    // await orderSectionService(srcIndex, srcTable, dstIndex, dstTable);
    // await fetchSectionsData();
    setDraggable(true);
  };

  const sectionColumns = [
    {
      title: "Section",
      dataIndex: "section",
      align: "left",
    },
    {
      title: "Tasks",
      dataIndex: "tasks",
      align: "right",
    },
    {
      dataIndex: "archived",
      title: archivedTitle,
      align: "right",
    },
    {
      title: "",
      align: "right",
      key: "action",
      render: (record: Section) => (
        <Dropdown
          overlay={
            <Menu className="AddSectionsModal__Menu">
              <Menu.Item
                key="delete"
                icon={<DeleteIcon />}
                onClick={() => {
                  setSections((c) => c.filter((section) => section !== record));
                }}
              >
                Delete
              </Menu.Item>
            </Menu>
          }
          placement="bottomRight"
          trigger={["click"]}
        >
          <MoreActionsIcon className="AddSectionsModal__MoreIcon" />
        </Dropdown>
      ),
    },
  ];

  return (
    <Modal
      className="AddSectionsModal"
      title="Add sections"
      prefixText="You can add sections to the task list in order to organize tasks."
      centered={true}
      visible={visible}
      okText="Close"
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      zIndex={1034}
    >
      <div className="AddSectionsModal__Table">
        <div className="AddSectionsModal__Table__Header">
          <div className="AddSectionsModal__Table__Header__Title">Sections</div>
          <div
            className={cn("AddSectionsModal__Table__Header__Action", {
              "AddSectionsModal__Table__Header__Action--Flex": isAdding,
            })}
          >
            {isAdding ? (
              <Input
                className={cn(
                  "AddSectionsModal__Table__Header__Action__Input",
                  {
                    "AddSectionsModal__Table__Header__Action__Input--HasInput": hasInput,
                  }
                )}
                placeholder="Enter section name"
                value={sectionName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSectionName(e.target.value)
                }
                suffix={
                  hasInput ? (
                    <div className="AddSectionsModal__Table__Header__Action__Input__Suffix">
                      <Button
                        icon={<AddIcon />}
                        onClick={() => {
                          setSections((c) => [
                            ...c,
                            {
                              id: String(sections.length + 1),
                              key: String(sections.length + 1),
                              index: sections.length,
                              section: sectionName,
                              tasks: 0,
                              archived: 0,
                            },
                          ]);
                          setSectionName("");
                        }}
                        disabled={sectionNameExists}
                      >
                        Create
                      </Button>
                      <CloseIcon onClick={() => setSectionName("")} />
                    </div>
                  ) : (
                    <></>
                  )
                }
              />
            ) : (
              <Button icon={<AddIcon />} onClick={() => setIsAdding(true)}>
                Add new section
              </Button>
            )}
          </div>
        </div>
        <div className="AddSectionsModal__Table__Body">
          {sections.length === 0 ? (
            <div className="AddSectionsModal__Table__Body__EmptyState">
              No sections yet.
            </div>
          ) : (
            <DragDropContext onDragEnd={handleDragEnd}>
              <DndTable
                id="active"
                columns={sectionColumns}
                dataSource={sections}
                pagination={false}
                disabled={!draggable}
              />
            </DragDropContext>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AddSectionsModal;
