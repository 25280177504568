import React from "react";
import { Tooltip } from "antd";

import { CommentIcon } from "../../../modules/CustomIcons/CustomIcons.component";

interface CommentTooltipProps {
  tooltipText: string;
}
const CommentTooltip: React.FC<CommentTooltipProps> = ({ tooltipText }) => {
  return (
    <div className="CommentTooltip">
      <Tooltip overlayClassName="CommentTooltip__Tooltip" title={tooltipText}>
        <CommentIcon />
      </Tooltip>
    </div>
  );
};

export default CommentTooltip;
