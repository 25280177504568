import React from "react";
import moment, { Moment } from "moment";

import CustomAvatar from "../../CustomAvatar/CustomAvatar";
import SummaryOfTheDay from "../../Checkout/SummaryOfTheDay/SummaryOfTheDay";
import WorkTimeRecordTable from "../../WorkTime/WorkTimeRecordTable/WorkTimeRecordTable";

import { workDayDateFormat } from "../../../constants/constants";

interface TeamHistoryMemberItemProps {
  endDate?: Moment;
  shownObservableUserWithTableDataObject: ShownObservableUserWithTableDataObject;
  startDate?: Moment;
}

const TeamHistoryMemberItem: React.FC<TeamHistoryMemberItemProps> = ({
  endDate,
  shownObservableUserWithTableDataObject,
  startDate,
}) => {
  const {
    shownObservableUser,
    tableData,
  } = shownObservableUserWithTableDataObject;
  const { observed_user } = shownObservableUser;
  const {
    avatar: observedUserAvatar,
    display_name: observedUserDisplayName,
    current_status: observedUserCurrentStatus,
  } = observed_user;

  return (
    <div className="TeamHistoryMemberItem">
      <div className="TeamHistoryMemberItem__Header">
        <div className="TeamHistoryMemberItem__Header__Title">
          <CustomAvatar
            size={24}
            src={observedUserAvatar}
            work_status={observedUserCurrentStatus.status_enum_identifier}
          />
          <span className="TeamHistoryMemberItem__Header__Title__Text">
            {observedUserDisplayName}
          </span>
        </div>
      </div>
      <div className="TeamHistoryMemberItem__Body">
        {tableData && tableData.records.length > 0 ? (
          <WorkTimeRecordTable
            isLoading={false}
            tableData={tableData}
            type="date"
          />
        ) : (
          <div className="TeamHistoryMemberItem__Body__NoWorkDay">
            <span>No worktime information to show here.</span>
          </div>
        )}
        <SummaryOfTheDay
          date={[
            moment(startDate).format(workDayDateFormat),
            moment(endDate).format(workDayDateFormat),
          ]}
          type="team"
          users={[
            shownObservableUserWithTableDataObject.shownObservableUser
              .observed_user,
          ]}
        />
      </div>
    </div>
  );
};

export default TeamHistoryMemberItem;
