import React from "react";
import { Tooltip, Popover } from "antd";

import TaskAssigneesPopoverContent from "./TaskAssigneesPopoverContent/TaskAssigneesPopoverContent";
import ContributorsSearcher from "./ContributorsSeacher/ContributorsSearcher";
import CustomAvatar from "../../CustomAvatar/CustomAvatar";
import AvatarGroup from "../../AvatarGroup/AvatarGroup.component";
import { UserIcon } from "../../CustomIcons/CustomIcons.component";

import { getInitials } from "../../../utils/string.util";

/**
 * TaskAssignees displays the owner and contributors of a task
 *
 * Default Display
 * Displays the owners avatar
 * Displays the contributors' avatars (maximum of 2 avatars displayed, the remaining will be displayed as count)
 *
 * Hover Display
 * Displays TaskAssigneesPopoverContent when hovered
 *
 * Functionality
 * Fetches the task's contributors on mount
 * Re-fetches task's contributors when webhook event "new_join_task_to_user" is triggered
 */
const TaskAssignees: React.FC<TaskAssigneesProps> = ({
  task,
  isMouseEnterCard = false,
  onPin,
}) => {
  const {
    owner_user: taskOwnerUserId,
    members: taskMembers = [],
    tasklist_members: taskListMembers,
  } = task;
  const [visible, setVisible] = React.useState(false);
  const owner = taskMembers.find(
    ({ id: taskMemberId }) => taskMemberId === taskOwnerUserId
  );
  const membersWithoutOwner = React.useMemo(() => {
    return taskMembers.filter(
      ({ id: taskMemberId }) => taskMemberId !== taskOwnerUserId
    );
  }, [taskMembers, taskOwnerUserId]);
  const taskListMembersWithoutTaskMembers = React.useMemo(() => {
    return taskListMembers.filter(
      ({ user }) => !taskMembers.map(({ id }) => id).includes(user.id)
    );
  }, [taskListMembers, taskMembers]);

  const handleVisibleChange = (visible: boolean) => {
    setVisible(visible);
    if (onPin) {
      onPin(visible);
    }
  };

  return (
    <Popover
      content={
        membersWithoutOwner && (
          <ContributorsSearcher
            task={task}
            taskMembers={taskMembers}
            listMembers={taskListMembersWithoutTaskMembers.map(
              ({ user }) => user
            )}
            ownerId={taskOwnerUserId}
          />
        )
      }
      trigger="click"
      placement="bottom"
      overlayClassName="TaskAssignees__ContributorsSearcher"
      destroyTooltipOnHide={true}
      visible={visible}
      onVisibleChange={handleVisibleChange}
    >
      {owner ? (
        <Tooltip
          title={
            <TaskAssigneesPopoverContent
              contributors={taskMembers}
              ownerId={taskOwnerUserId}
            />
          }
          trigger="hover"
          placement="top"
          overlayClassName="TaskAssignees__Popover"
          getPopupContainer={(trigger: any) => trigger.parentElement}
        >
          <div className="TaskAssignees">
            {taskOwnerUserId && (
              <CustomAvatar
                size={20}
                src={owner.avatar}
                fallbackText={getInitials(owner.display_name)}
              />
            )}
            {membersWithoutOwner && membersWithoutOwner.length > 0 && (
              <AvatarGroup
                avatars={membersWithoutOwner.map((c) => c.avatar || "")}
                avatarsFallBackText={membersWithoutOwner.map((c) =>
                  getInitials(c.display_name)
                )}
                maxCount={2}
              />
            )}
          </div>
        </Tooltip>
      ) : (
        <Tooltip
          title="Edit members"
          trigger="hover"
          placement="top"
          overlayClassName="TaskAssignees__Popover"
          getPopupContainer={(trigger: any) => trigger.parentElement}
        >
          {isMouseEnterCard && (
            <div className="TaskAssignees">
              <UserIcon className="TaskAssignees__Empty" />
            </div>
          )}
        </Tooltip>
      )}
    </Popover>
  );
};

export { TaskAssignees as default };
