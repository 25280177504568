import React from "react";
import { useSelector } from "react-redux";
import cn from "classnames";

import LeaveTheOfficeBtn from "../../General/LeaveTheOfficeBtn/LeaveTheOfficeBtn";
import { LoadingIcon } from "../../CustomIcons/CustomIcons.component";

import { selectCheckoutStatusState } from "../../../reducers/checkout.reducer";
import { leaveTheOfficeService } from "../../../services/status.service";

const ConfirmLeavingTheOffice: React.FC<ConfirmLeavingTheOfficeProps> = ({
  setDisplayModal,
}) => {
  const currentDateCheckoutStatusState = useSelector(selectCheckoutStatusState);
  const {
    data: currentDateCheckoutStatus,
    loading: currentDateCheckoutStatusLoading,
  } = currentDateCheckoutStatusState;

  const [confirmLeavingOffice, setConfirmLeavingOffice] = React.useState(false);

  const allCheckoutChecksPassed = currentDateCheckoutStatus.every(
    (item) => item.passed_yn === true
  );

  const leaveTheOffice = async () => {
    if (setDisplayModal) {
      setDisplayModal(false);
    }
    await leaveTheOfficeService(); // success in handled in "user_left_office" synchronization in AppRouter
  };

  const handleClickLeaveTheOffice = () => {
    if (allCheckoutChecksPassed || confirmLeavingOffice) {
      leaveTheOffice();
    } else {
      setConfirmLeavingOffice(true);
    }
  };

  return (
    <div
      className={cn("ConfirmLeavingTheOffice", {
        "ConfirmLeavingTheOffice--Confirm": confirmLeavingOffice,
      })}
    >
      {currentDateCheckoutStatusLoading && (
        <LoadingIcon className="ConfirmLeavingTheOffice__Loading" />
      )}

      {confirmLeavingOffice && (
        <span>Activity Details for some tasks are missing.</span>
      )}

      {!currentDateCheckoutStatusLoading && (
        <LeaveTheOfficeBtn
          onClick={handleClickLeaveTheOffice}
          confirmLeavingOffice={confirmLeavingOffice}
        />
      )}
    </div>
  );
};

export default ConfirmLeavingTheOffice;
