export const workShiftSchedules: WorkShiftScheduleArray = [
  {
    id: '1',
    user: '1',
    start_datetime: "2021-04-28T00:00:00Z",
    end_datetime: "2021-04-28T09:00:00Z",
    work_time_seconds: 32400,
    schedule_notes: "",
  },
];

export { workShiftSchedules as default };
