import React from "react";
import { useSelector } from "react-redux";

import { selectTaskTimeState } from "../reducers/task-time.reducer";

export default function useAsyncWorkDayTaskTimes(
  workDayId?: WorkDayObject["id"]
) {
  const { data: taskTimeArray } = useSelector(selectTaskTimeState);

  const [
    workDayTaskTimes,
    setWorkDayTaskTimes,
  ] = React.useState<TaskTimeArray>();

  React.useEffect(() => {
    /**
     * TODO replace with filtered endpoint call instead of deriving from task time global state
     */
    if (workDayId) {
      setWorkDayTaskTimes(
        taskTimeArray.filter(({ work_day }) => work_day === workDayId)
      );
    }
  }, [workDayId, taskTimeArray]);

  return workDayTaskTimes;
}
