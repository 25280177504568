import React from "react";

import UserWorkDaySummaryListGroupedByDate from "../../../WorkDay/UserWorkDaySummaryListGroupedByDate/UserWorkDaySummaryListGroupedByDate";

interface CheckoutTaskCollapsePanelContentProps {
  activitiesSummaryType: ActivitiesSummaryType;
  users: UserArray;
  workDaysCheckoutSummaryGroupedByDate: Record<
    string,
    WorkDayCheckoutSummaryArray
  >;
}
const CheckoutTaskCollapsePanelContent: React.FC<CheckoutTaskCollapsePanelContentProps> = ({
  activitiesSummaryType,
  users,
  workDaysCheckoutSummaryGroupedByDate,
}) => {
  return (
    <div className="CheckoutTaskCollapsePanelContent">
      <UserWorkDaySummaryListGroupedByDate
        type={activitiesSummaryType}
        users={users}
        workDaysCheckoutSummaryGroupedByDate={
          workDaysCheckoutSummaryGroupedByDate
        }
      />
    </div>
  );
};

export default CheckoutTaskCollapsePanelContent;
