import React from "react";
import { useSelector } from "react-redux";
import { sumBy } from "lodash";

import useAsyncProjectArrayTaskTimes from "../../../hooks/useAsyncProjectArrayTaskTimes.hook";
import useWorkTimesByDate from "../../../hooks/useWorkTimesByDate.hook";

import ProjectTimeBarContainer from "../../Projects/ProjectTimeBarContainer/ProjectTimeBarContainer";
import TimeDisplay from "../../TimeDisplay/TimeDisplay.component";

import { selectCurrentWorkDayState } from "../../../reducers/work-day.reducer";
import { secondsToMs } from "../../../utils/time-conversion.util";

/**
 * Displays the total time for projects and unallocated time
 */
const ProjectTotalTime = ({ projects }: ProjectTotalTimeProps) => {
  const [projectsTaskTimes] = useAsyncProjectArrayTaskTimes(projects);

  const projectsTotalTaskTime = React.useMemo(() => {
    return !!projectsTaskTimes ? sumBy(projectsTaskTimes, "seconds_worked") : 0;
  }, [projectsTaskTimes]);

  const {
    data: { work_date: currentWorkDayDate },
  } = useSelector(selectCurrentWorkDayState);

  const [currentWorkDayDateWorkTimes] = useWorkTimesByDate(currentWorkDayDate);
  const unallocatedTime =
    currentWorkDayDateWorkTimes.work_time -
    currentWorkDayDateWorkTimes.tasks_allocated_time;

  const projectsAndUnallocatedTotalTime =
    projectsTotalTaskTime + unallocatedTime;

  return (
    <div className="ProjectTotalTime">
      <ProjectTimeBarContainer
        projects={projects}
        projectsAndUnallocatedTotalTime={projectsAndUnallocatedTotalTime}
      />
      <div className="ProjectTotalTime__Time">
        <span>Tasks:</span>
        <TimeDisplay
          time={secondsToMs(projectsTotalTaskTime)}
          active={false}
          mouseOver={false}
          lastUnit="m"
        />
      </div>
      <div className="ProjectTotalTime__Time">
        <span>Unallocated:</span>
        <TimeDisplay
          time={secondsToMs(unallocatedTime)}
          active={false}
          mouseOver={false}
          lastUnit="m"
        />
      </div>
    </div>
  );
};

export { ProjectTotalTime as default };
