import React from "react";
import { useSelector } from "react-redux";

import useAsyncConsolidateUserTasks from "../../../hooks/useAsyncConsolidateUserTasks.hook";

import ProjectTotalTime from "../../Projects/ProjectTotalTime/ProjectTotalTime";
import ProjectCardItem from "../../Projects/ProjectCardItem/ProjectCardItem";
import { TaskPanelContext } from "../../Tasks/TaskPanel/TaskPanel";

import {
  personalTasksProjectObject,
  TASK_PAGES,
} from "../../../constants/constants";
import { selectProjectsState } from "../../../reducers/projects.reducer";

/**
 * Default Display
 * Displays list of projects if projects array is not empty
 *
 * Functionality
 * Passes down [filter] prop to ProjectCardItem child component
 * If projects array is not empty and [filter] is "today", displays ProjectTotalTime component
 */
const ProjectCardList = ({ onTaskSelect, taskPage }: ProjectCardListProps) => {
  const projects = useSelector(selectProjectsState).data;

  const { todayTasks, nextWorkDayTasks, starredTasks } = React.useContext(
    TaskPanelContext
  );

  const [
    consolidatedTodayTasks,
    consolidatedTodayTasksLoading,
  ] = useAsyncConsolidateUserTasks(todayTasks);
  const [
    consolidatedNextWorkDayTasks,
    consolidatedNextWorkDayTasksLoading,
  ] = useAsyncConsolidateUserTasks(nextWorkDayTasks);
  const [
    consolidatedStarredTasks,
    consolidatedStarredTasksLoading,
  ] = useAsyncConsolidateUserTasks(starredTasks);

  const projectsWithTasksIds = React.useMemo(() => {
    const getProjectsIds = (tasks?: TaskJoinedToUserAndScheduledArray) => {
      const projectsIds = tasks
        ? tasks.map(({ task_list }) => {
            const { parent_project } = task_list || {};
            return parent_project;
          })
        : [];
      return Array.from(new Set(projectsIds));
    };
    switch (taskPage) {
      case TASK_PAGES.TODAY:
        return getProjectsIds(consolidatedTodayTasks);
      case TASK_PAGES.STARRED:
        return getProjectsIds(consolidatedStarredTasks);
      case TASK_PAGES.NEXT_SHIFT:
        return getProjectsIds(consolidatedNextWorkDayTasks);
      default:
    }
  }, [
    consolidatedTodayTasks,
    consolidatedNextWorkDayTasks,
    consolidatedStarredTasks,
    taskPage,
  ]);

  const projectsWithTasks = React.useMemo(() => {
    return projects.filter(({ id: projectId }) =>
      projectsWithTasksIds?.includes(projectId)
    );
  }, [projects, projectsWithTasksIds]);

  const hasProject = projectsWithTasks.length > 0;

  return (
    <div className="ProjectCardList">
      {hasProject &&
        projectsWithTasks.map((project, index: number) => {
          return (
            <ProjectCardItem
              key={index}
              isUserProject={project.title === personalTasksProjectObject.title}
              onTaskSelect={onTaskSelect}
              project={project}
              panelType="task"
              taskPage={taskPage}
            />
          );
        })}
      {hasProject && taskPage === "today" && (
        <ProjectTotalTime projects={projectsWithTasks} taskPage={taskPage} />
      )}
    </div>
  );
};

export { ProjectCardList as default };
