import React from "react";
import { Tooltip } from "antd";

import CustomButton from "../../CustomButton/CustomButton";
import { DraftIcon } from "../../CustomIcons/CustomIcons.component";
import { ActivityCardItemContext } from "../ActivityCardItem/ActivityCardItem";

/**
 * Default display
 * Displays CustomButton with DraftIcon
 *
 * Functionality
 * Clicking on the edit button will update the parent activity's local editing state to true
 */
const EditActivity: React.FC = () => {
  const context = React.useContext(ActivityCardItemContext);
  const { setEditing } = context;
  return (
    <div className="EditActivity">
      <Tooltip title="Edit">
        <CustomButton
          className="EditActivity__Btn"
          onClick={() => {
            setEditing(true);
          }}
          icon={<DraftIcon />}
          size="small"
        />
      </Tooltip>
    </div>
  );
};

export default EditActivity;
