import React from "react";
import { Tooltip } from "antd";
import moment from "moment";

import { getTotalDuration } from "../../../../utils/time-conversion.util";

interface CheckoutTaskTimeBarProps {
  checkoutTaskTotalTime: number;
  width: string;
}
const CheckoutTaskTimeBar: React.FC<CheckoutTaskTimeBarProps> = ({
  checkoutTaskTotalTime,
  width,
}) => {
  const tooltip = (
    <div className="CheckoutTimeBarTooltip">
      <span className="CheckoutTimeBarTooltip__Title">Checkout</span>
      <span>
        {getTotalDuration(moment.duration(checkoutTaskTotalTime, "seconds"))}
      </span>
    </div>
  );
  return (
    <Tooltip title={tooltip} getPopupContainer={(trigger: any) => trigger}>
      <div className="CheckoutTaskTimeBar" style={{ width }}></div>
    </Tooltip>
  );
};

export default CheckoutTaskTimeBar;
