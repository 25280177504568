import { Tooltip } from "antd";
import moment from "moment";
import React from "react";

import { InfoCircleOutlinedIcon } from "../../CustomIcons/CustomIcons.component";
import { getTotalDuration } from "../../../utils/time-conversion.util";

const ShiftCard: React.FC<ShiftCardProps> = ({ timeSlots }) => {
  const slots = timeSlots.map((slot, index) => (
    <div key={index} className="ShiftCard__Slot">
      <div className="ShiftCard__Duration">
        <div style={{ color: slot.color, fontWeight: 600 }}>
          {getTotalDuration(moment.duration(slot.durationInSeconds, "seconds"))}
        </div>
        {slot.hasNote && (
          <Tooltip
            overlayInnerStyle={{
              width: "232px",
              whiteSpace: "pre-wrap",
              fontSize: "12px",
              lineHeight: "17px",
            }}
            title={slot.noteText}
          >
            <InfoCircleOutlinedIcon className="ShiftCard__Duration__Icon" />
          </Tooltip>
        )}
      </div>
      <div className="ShiftCard__Title">{slot.title}</div>
    </div>
  ));
  return <div className="ShiftCard">{slots}</div>;
};

export default ShiftCard;
