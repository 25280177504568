import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal as AntModal } from "antd";

import useRedirect from "../../hooks/useRedirect.hook";

import Modal from "../General/Modal/Modal";
import ProjectForm from "./ProjectForm/ProjectForm.component";
import ProjectPreview from "../Projects/ProjectPreview/ProjectPreview";

import {
  startCreateProject,
  startUpdateProject,
} from "../../actions/projects.action";
import { startCreateProjectsColors } from "../../actions/projects-colors.action";
import { selectProjectsColorsState } from "../../reducers/projects-colors.reducer";
import { formatUrlTitle } from "../../utils/url.util";

const EMPTY_PROJECT = {
  title: "",
} as ProjectObject;
const initProjectWithColor = (project: ProjectObject) => {
  if (project.color && project.color.value) {
    return {
      ...project,
      hexColor: project.color.value,
    };
  }
  return project;
};

/**
 * This component is used to create or edit a project. Once the project is created,
 * the application will navigate to the overview page of this project.
 * TODO: the creation of section is not implemented yet.
 */
const ProjectModal = ({ open, onClose, onOk, project }: ProjectModalProps) => {
  const redirect = useRedirect();
  
  const [currentProject, setCurrentProject] = useState<ProjectObject>(
    EMPTY_PROJECT
  );
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const isCreate = !project;
  const colors = useSelector(selectProjectsColorsState).data;

  React.useEffect(() => {
    if (project) {
      setCurrentProject(initProjectWithColor(project));
    }
  }, [project]);

  const handleSubmit = async (value: ProjectObject) => {
    setLoading(true);
    if (!value.hexColor) {
      return;
    }

    const color = colors.find(
      (item: ProjectColorObject) =>
        item.value.toLocaleLowerCase() === value.hexColor?.toLowerCase()
    );
    let payload;
    if (color) {
      payload = {
        ...value,
        color: color.id,
      } as any;
    } else {
      // create new color if it doesn't exist in current color list
      const colorRsp = (await dispatch(
        startCreateProjectsColors({
          value: value.hexColor,
          title: value.hexColor,
        })
      )) as any;
      payload = {
        ...value,
        color: colorRsp.id,
      };
    }
    let response: any;
    if (isCreate) {
      response = await dispatch(startCreateProject(payload));
    } else {
      response = await dispatch(startUpdateProject(payload));
    }
    setLoading(false);
    if (response.success) {
      // reset project to empty
      if (isCreate) {
        setCurrentProject({
          title: "",
          color: {},
          hexColor: "",
        } as ProjectObject);
        redirect({
          path: `/p/${response.payload.id}/${formatUrlTitle(
            response.payload.title
          )}`,
        });
      }
      if (onOk) onOk();
    }
    return response;
  };

  const handleChange = (value: ProjectObject) => {
    setCurrentProject(value);
  };

  const handleClose = () => {
    AntModal.confirm({
      title: "Warning",
      content: `Do you want to cancel ${
        isCreate ? "creating" : "editing"
      } this project?`,
      onOk: () => {
        if (project) {
          setCurrentProject(initProjectWithColor(project));
        } else {
          setCurrentProject({
            title: "",
            color: {},
            hexColor: "",
          } as ProjectObject);
        }
        onClose();
      },
      okText: "Yes",
      cancelText: "No",
      centered: true,
    });
  };

  return (
    <Modal
      className="ProjectModal"
      visible={open}
      onCancel={handleClose}
      fullScreen={true}
      footer={null}
    >
      <div className="ProjectModal__Content">
        <div className="ProjectModal__Left">
          <ProjectForm
            isCreate={isCreate}
            project={currentProject}
            loading={loading}
            onChange={handleChange}
            onSubmit={handleSubmit}
          />
        </div>
        <div className="ProjectModal__Right">
          <ProjectPreview />
        </div>
      </div>
    </Modal>
  );
};

export default ProjectModal;
