import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";

import useRedirect from "../../../hooks/useRedirect.hook";

import { ExclamationCircleFilledIcon } from "../../CustomIcons/CustomIcons.component";

import {
  dateFormat,
  menuStaticKeys,
  workDayDateFormat,
} from "../../../constants/constants";
import { selectCheckoutCheckStatusesState } from "../../../reducers/checkout.reducer";

interface CheckoutStatusProps {
  date: string;
  mode: CheckoutPanelMode;
  setDisplayModal?: React.Dispatch<React.SetStateAction<boolean>>;
}
const CheckoutStatus: React.FC<CheckoutStatusProps> = ({
  date,
  mode,
  setDisplayModal,
}) => {
  const redirect = useRedirect();
  
  const checkoutCheckStatuses = useSelector(selectCheckoutCheckStatusesState)
    .data;
  const checkoutDate = moment(date, dateFormat).format(workDayDateFormat);
  const statuses = checkoutCheckStatuses
    .filter((item) => item.work_day.work_date === checkoutDate)
    .filter((item) => !!item.issue_json)
    .map((item) => ({
      date,
      message: item.issue_json!.checkout_failed_message,
    }));
  const checkoutStatus = statuses.map((item, index) => {
    return (
      <div key={index} className="CheckoutStatus__Item">
        <ExclamationCircleFilledIcon className="CheckoutStatus__Item-Icon" />
        <span>{item.message}</span>
      </div>
    );
  });

  const handleClickLink = (e: React.MouseEvent) => {
    if (setDisplayModal) {
      setDisplayModal(false);
      redirect({
        path: menuStaticKeys.CHECKOUT,
      });
    }
  };

  return statuses.length > 0 ? (
    <div className="CheckoutStatus">
      <div className="CheckoutStatus__Header">
        <div className="CheckoutStatus__Header__Title">Checkout status</div>
        {mode === "leave_the_office" && (
          <div
            className="CheckoutStatus__Header__Link"
            onClick={handleClickLink}
          >
            Go to Checkout
          </div>
        )}
      </div>
      <div className="CheckoutStatus__Content">{checkoutStatus}</div>
    </div>
  ) : (
    <></>
  );
};

export default CheckoutStatus;
