import {
  alternateUserDetails,
  otherAlternateUserDetails,
} from "../fixtures/user-details.fixture";

export const teamMemberArray: TeamMemberArray = [
  {
    id: "1",
    avatar: require("../assets/images/avatars/shutterstock_172135856_0015_016.png"),
    full_name: "Gregory Hunt",
    selected: false,
    starred_yn: true,
    unreadActivitiesCount: 3,
  },
  {
    id: "2",
    avatar: require("../assets/images/avatars/shutterstock_172135856_0003_004.png"),
    full_name: "John Williams",
    selected: false,
    starred_yn: true,
    unreadActivitiesCount: 2,
  },
  {
    id: alternateUserDetails.id,
    avatar: alternateUserDetails.avatar!,
    full_name: alternateUserDetails.full_name!,
    selected: false,
    starred_yn: true,
    unreadActivitiesCount: 0,
  },
  {
    id: "4",
    avatar: require("../assets/images/avatars/shutterstock_172135856_0013_014.png"),
    full_name: "Jane Brown",
    selected: false,
    starred_yn: true,
    unreadActivitiesCount: 0,
  },
  {
    id: "5",
    avatar: require("../assets/images/avatars/shutterstock_172135856_0006_007.png"),
    full_name: "Mike Howell",
    selected: false,
    starred_yn: true,
    unreadActivitiesCount: 10,
  },
  {
    id: "6",
    avatar: require("../assets/images/avatars/shutterstock_172135856_0009_010.png"),
    full_name: "Kelly Barker",
    selected: false,
    starred_yn: false,
    unreadActivitiesCount: 0,
  },
  {
    id: "7",
    avatar: require("../assets/images/avatars/shutterstock_172135856_0021_022.png"),
    full_name: "Michael Perry",
    selected: false,
    starred_yn: false,
    unreadActivitiesCount: 0,
  },
  {
    id: "8",
    avatar: require("../assets/images/avatars/shutterstock_172135856_0020_021.png"),
    full_name: "Ann Clements",
    selected: false,
    starred_yn: true,
    unreadActivitiesCount: 7,
  },
  {
    id: otherAlternateUserDetails.id,
    avatar: otherAlternateUserDetails.avatar!,
    full_name: otherAlternateUserDetails.full_name!,
    selected: false,
    starred_yn: true,
    unreadActivitiesCount: 0,
  },
];

export const starredTeamMembers = teamMemberArray.filter(
  ({ starred_yn }) => starred_yn
);

export const teamPanelTabs = ["Today", "History"];
