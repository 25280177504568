import { useEffect, useState, useRef } from "react";

const useDeleteAnimation = ({
  onDelete,
  animationDuration = 150,
  dependencies = [],
}: {
  onDelete: (params?: any) => void;
  animationDuration?: number;
  dependencies?: any[];
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [opacity, setOpacity] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (ref && ref.current) {
      setHeight(ref.current.clientHeight);
    }
  }, [ref]);

  // reset opacity and colapse when dependencies change
  useEffect(() => {
    return () => {
      setOpacity(false);
      setCollapse(false);
    }
  }, dependencies);

  const handleDelete = (params?: any) => {
    setOpacity(true);
    setTimeout(() => {
      setCollapse(true);
      setTimeout(() => {
        onDelete(params);
      }, animationDuration);
    }, animationDuration);
  };

  return {
    ref,
    opacity,
    collapse,
    height,
    handleDelete,
  };
};

export default useDeleteAnimation;
