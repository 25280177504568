import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popover } from "antd";

import {
  BulletPointIcon,
  LoadingIcon,
  NotificationBellIcon,
} from "../../CustomIcons/CustomIcons.component";
import NotificationsPopoverContent from "./NotificationsPopoverContent/NotificationsPopoverContent";

import { startMarkMultipleNotificationsAsRead } from "../../../actions/notifications.action";
import { selectNotificationsState } from "../../../reducers/notifications.reducer";

export const NotificationsContext = React.createContext<NotificationsContext>(
  {} as NotificationsContext
);
const Notifications = () => {
  const dispatch = useDispatch();

  const notificationsState = useSelector(selectNotificationsState);
  const {
    data: notifications,
    loading: notificationsLoading,
  } = notificationsState;

  const hasUnreadNotifications = notifications.some(({ unread }) => unread);

  const [readNotifications, setReadNotifications] = React.useState<
    NotificationsContext["readNotifications"]
  >([]);

  const handleVisibleChange = (visible: boolean) => {
    if (!visible && readNotifications.length > 0) {
      dispatch(
        startMarkMultipleNotificationsAsRead({
          ids: readNotifications,
        })
      );
    }
  };

  return (
    <NotificationsContext.Provider
      value={{
        readNotifications,
        setReadNotifications,
      }}
    >
      <div className="Notifications">
        {notificationsLoading ? (
          <LoadingIcon className="Notifications__Loading" />
        ) : (
          <Popover
            content={<NotificationsPopoverContent />}
            trigger="click"
            placement="bottomLeft"
            overlayClassName="Notifications__Popover"
            onVisibleChange={handleVisibleChange}
          >
            {hasUnreadNotifications && (
              <BulletPointIcon className="Notifications__UnreadFlag" />
            )}
            <NotificationBellIcon className="Notifications__Icon" />
          </Popover>
        )}
      </div>
    </NotificationsContext.Provider>
  );
};

export default Notifications;
