import { RootState } from "../store/configureStore";

export interface StatusHistoryState extends BaseReducerState {
  data: StatusHistoryArray;
}
export const selectStatusHistoryState = (rootState: RootState) =>
  rootState.statusHistory;
export const statusHistoryReducerDefaultState: StatusHistoryState = {
  data: [],
  error: "",
  loading: false,
};
const statusHistoryReducer = (
  state = statusHistoryReducerDefaultState,
  action: { type: string; statusHistory: StatusHistoryArray; error: string }
) => {
  switch (action.type) {
    case "REQUEST_FETCH_STATUS_HISTORY":
      return {
        ...state,
        error: "",
        loading: true,
      };
    case "REQUEST_FETCH_STATUS_HISTORY_SUCCESS":
      return {
        data: action.statusHistory,
        error: "",
        loading: false,
      };
    case "REQUEST_FETCH_STATUS_HISTORY_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export { statusHistoryReducer as default };
