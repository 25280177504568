import React, { useState } from "react";
import classNames from "classnames";
import moment from "moment";

/**
 * Default display
 * Hours Display
 * If prop active is false, mouseOver is false, prop lastUnit is 'h', prop addZeroToSingleDigit is false, prop displayZeroes is false, and hourDuration is greater than 0 and single digit, displays time in ('h' moment format)h
 * If prop active is false, mouseOver is false, prop lastUnit is 'h', prop addZeroToSingleDigit is true, prop displayZeroes is false, and hourDuration is greater than 0 and single digit, displays time in ('hh' moment format)h
 * If prop active is false, mouseOver is false, prop lastUnit is 'h', prop addZeroToSingleDigit is false, prop displayZeroes is true, and hourDuration is 0, displays time in ('h' moment format)h
 * If prop active is false, mouseOver is false, prop lastUnit is 'h', prop addZeroToSingleDigit is true, prop displayZeroes is true, and hourDuration is 0, displays time in ('hh' moment format)h
 * Minute Display
 * If prop active is false, mouseOver is false, prop lastUnit is 'm', prop addZeroToSingleDigit is false, prop displayZeroes is false, and minuteDuration is greater than 0 and single digit, displays time in ('m' moment format)m
 * If prop active is false, mouseOver is false, prop lastUnit is 'm', prop addZeroToSingleDigit is true, prop displayZeroes is false, and minuteDuration is greater than 0 and single digit, displays time in ('mm' moment format)m
 * If prop active is false, mouseOver is false, prop lastUnit is 'm', prop addZeroToSingleDigit is false, prop displayZeroes is true, and minuteDuration is 0, displays time in ('h' moment format)h ('m' moment format)m
 * If prop active is false, mouseOver is false, prop lastUnit is 'm', prop addZeroToSingleDigit is true, prop displayZeroes is true, and minuteDuration is 0, displays time in ('hh' moment format)h ('mm' moment format)m
 * Second Display
 * If prop active is false, mouseOver is false, prop lastUnit is 's', prop addZeroToSingleDigit is false, prop displayZeroes is false, and secondDuration is greater than 0 and single digit, displays time in ('s' moment format)s
 * If prop active is false, mouseOver is false, prop lastUnit is 's', prop addZeroToSingleDigit is true, prop displayZeroes is false, and secondDuration is greater than 0 and single digit, displays time in ('ss' moment format)s
 * If prop active is false, mouseOver is false, prop lastUnit is 's', prop addZeroToSingleDigit is false, prop displayZeroes is true, and secondDuration is 0, displays time in ('h' moment format)h ('m' moment format)m ('s' moment format)s
 * If prop active is false, mouseOver is false, prop lastUnit is 's', prop addZeroToSingleDigit is true, prop displayZeroes is true, and hourDuration is 0, displays time in ('hh' moment format)h ('mm' moment format)m ('ss' moment format)s
 *
 * Active display
 * Hour Display
 * If prop active is true, mouseOver is false, prop lastUnit is 'h', prop addZeroToSingleDigit is false, prop displayZeroes is false, and hourDuration is greater than 0 and single digit, displays time in ('h' moment format)
 * If prop active is true, mouseOver is false, prop lastUnit is 'h', prop addZeroToSingleDigit is true, prop displayZeroes is false, and hourDuration is greater than 0 and single digit, displays time in ('hh' moment format)
 * If prop active is true, mouseOver is false, prop lastUnit is 'h', prop addZeroToSingleDigit is false, prop displayZeroes is true, and hourDuration is 0, displays time in ('h' moment format)
 * If prop active is true, mouseOver is false, prop lastUnit is 'h', prop addZeroToSingleDigit is true, prop displayZeroes is true, and hourDuration is 0, displays time in ('hh' moment format)
 * Minute Display
 * If prop active is true, mouseOver is false, prop lastUnit is 'm', prop addZeroToSingleDigit is false, prop displayZeroes is false, and minuteDuration is greater than 0 and single digit, displays time in ('h' moment format):('m' moment format)
 * If prop active is true, mouseOver is false, prop lastUnit is 'm', prop addZeroToSingleDigit is true, prop displayZeroes is false, and minuteDuration is greater than 0 and single digit, displays time in ('hh' moment format):('mm' moment format)
 * If prop active is true, mouseOver is false, prop lastUnit is 'm', prop addZeroToSingleDigit is false, prop displayZeroes is true, and minuteDuration is 0, displays time in ('h' moment format):('m' moment format)
 * If prop active is true, mouseOver is false, prop lastUnit is 'm', prop addZeroToSingleDigit is true, prop displayZeroes is true, and minuteDuration is 0, displays time in ('hh' moment format):('mm' moment format)
 * Second Display
 * If prop active is true, mouseOver is false, prop lastUnit is 's', prop addZeroToSingleDigit is false, prop displayZeroes is false, and secondDuration is greater than 0 and single digit, displays time in ('h' moment format):('m' moment format):('s' moment format)
 * If prop active is true, mouseOver is false, prop lastUnit is 's', prop addZeroToSingleDigit is true, prop displayZeroes is false, and secondDuration is greater than 0 and single digit, displays time in ('hh' moment format):('mm' moment format):('ss' moment format)
 * If prop active is true, mouseOver is false, prop lastUnit is 's', prop addZeroToSingleDigit is false, prop displayZeroes is true, and secondDuration is 0, displays time in ('h' moment format):('m' moment format):('s' moment format)
 * If prop active is true, mouseOver is false, prop lastUnit is 's', prop addZeroToSingleDigit is true, prop displayZeroes is true, and hourDuration is 0, displays time in ('hh' moment format):('mm' moment format):('ss' moment format)
 *
 * Hover Display
 * If prop mouseOver is true, hovered, and prop addZeroToSingleDigit is false, displays time in display time in ('h' moment format):('m' moment format):('s' moment format)
 * If prop mouseOver is true, hovered, and prop addZeroToSingleDigit is false, displays time in display time in ('hh' moment format):('mm' moment format):('ss' moment format)
 */
const TimeDisplay: React.FC<TimeDisplayProps> = (props) => {
  const {
    time = 0,
    active,
    mouseOver,
    lastUnit,
    addZeroToSingleDigit,
    displayZeroes,
  } = props;
  const [hovered, setHovered] = useState(false);
  const roundedOffToHundredthsTime =
    Math.round((time + Number.EPSILON) * 100) / 100;
  const minuteFormat = addZeroToSingleDigit ? "mm" : "m",
    secondFormat = addZeroToSingleDigit ? "ss" : "s";

  const hourDuration = moment.duration(roundedOffToHundredthsTime).hours();
  const minuteDuration = moment.duration(roundedOffToHundredthsTime).minutes();
  const secondDuration = moment.duration(roundedOffToHundredthsTime).seconds();
  const momentHourString = hourDuration.toString();

  const hourString = `${
      addZeroToSingleDigit && momentHourString.length === 1 ? "0" : ""
    }${momentHourString}`,
    minuteString = moment(roundedOffToHundredthsTime).format(minuteFormat),
    secondString = moment(roundedOffToHundredthsTime).format(secondFormat);

  const displayHover = mouseOver && hovered;
  const displayDefaultHours =
    lastUnit === "h" || displayZeroes || (!displayZeroes && hourDuration > 0);
  const displayDefaultMinutes =
    lastUnit !== "h" &&
    (lastUnit === "m" ||
      displayZeroes ||
      (!displayZeroes && minuteDuration > 0));
  const displayDefaultSeconds =
    lastUnit !== "h" &&
    lastUnit !== "m" &&
    (lastUnit === "s" ||
      displayZeroes ||
      (!displayZeroes && secondDuration > 0));

  const handleMouseOver = () => {
    setHovered(true);
  };
  const handleMouseOut = () => {
    setHovered(false);
  };

  const getActiveString = () => {
    switch (lastUnit) {
      case "h":
        return hourString;
      case "m":
        return `${hourString}:${minuteString}`;
      case "s":
        return `${hourString}:${minuteString}:${secondString}`;
      default:
        return;
    }
  };

  let activeString = getActiveString();

  return (
    <div
      className={classNames("TimeDisplay", {
        "TimeDisplay--Hovered": displayHover,
      })}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      {(() => {
        if (displayHover) {
          return (
            <>
              {hourString}:{minuteString}:{secondString}
            </>
          );
        } else if (active) {
          return <>{activeString}</>;
        } else {
          return (
            <>
              {displayDefaultHours &&
                `${hourString}h${displayDefaultMinutes ? " " : ""}`}
              {displayDefaultMinutes &&
                `${minuteString}m${displayDefaultSeconds ? " " : ""}`}
              {displayDefaultSeconds && `${secondString}s`}
            </>
          );
        }
      })()}
    </div>
  );
};

export { TimeDisplay as default };
