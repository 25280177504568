import React from "react";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import CustomButton from "../../../CustomButton/CustomButton";
import { CheckoutIcon } from "../../../CustomIcons/CustomIcons.component";
import ActivityInput, {
  setActivityLoadingState,
} from "../../ActivityInput/ActivityInput";
import ActivityInputDisplay from "../../ActivityInputDisplay/ActivityInputDisplay";
import { ActivityCardItemContext } from "../../ActivityCardItem/ActivityCardItem";
import { ActivityTabContentContext } from "../../ActivityTabContent/ActivityTabContent";

import { startUpdateActivity } from "../../../../actions/activities.action";
import { selectActivityTypesState } from "../../../../reducers/activity-types.reducer";
import { ActivitiesState } from "../../../../reducers/activities.reducer";

const ActivityCardItemBody: React.FC = () => {
  const dispatch = useDispatch();

  const {
    activityObject,
    editing,
    isTeamDrafts,
    setEditing,
  } = React.useContext(ActivityCardItemContext);
  const { confirmedActivities, setConfirmedActivities } = React.useContext(
    ActivityTabContentContext
  );
  const { data: confirmedActivitiesData = [] } = confirmedActivities || {};

  const {
    id: activityId,
    activity_type: activityTypeID,
    text_json: activityTextJson,
    confirmed_yn,
    loading: activityLoading,
  } = activityObject;

  const [updatedTextJson, setUpdatedTextJson] = React.useState<
    ActivityObject["text_json"]
  >();

  React.useEffect(() => {
    setUpdatedTextJson(activityTextJson);
  }, [activityTextJson]);

  const displayEditing = editing && confirmed_yn;
  const displayConfirmed = !editing && confirmed_yn;
  const displayDraft = !editing && !confirmed_yn;

  const activityTypes = useSelector(selectActivityTypesState);
  const activityType = activityTypes.data.filter(
    (activityType) => activityType.id === activityTypeID
  )[0];
  const { title } = activityType || {};

  // if input is required and no text consider as error
  let confirmIsDisabled =
    !!updatedTextJson && updatedTextJson.constructor === Array
      ? updatedTextJson
          .map(
            ({ input_required_yn, section_text }) =>
              input_required_yn && section_text === ""
          )
          .some((hasError) => hasError === true)
      : false;

  const handleFormattedActivityInputChange = (
    activityInput: ActivityInputObject,
    activityInputIndex: number
  ) => {
    setUpdatedTextJson((c) => {
      if (!!c && c.constructor === Array) {
        return c.map((item, index) => {
          if (index === activityInputIndex) {
            return activityInput;
          }
          return item;
        });
      } else {
        return c;
      }
    });
  };

  const handleUnformattedActivityInputChange = (object: {
    [key: string]: any;
  }) => {
    setUpdatedTextJson(object);
  };

  const handleClickCancel = (e: React.MouseEvent<HTMLElement>) => {
    // dispatch(startPopulateUserConfirmedActivities());
    setEditing(false);
  };

  const handleClickConfirm = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      setActivityLoadingState(
        confirmedActivitiesData,
        setConfirmedActivities as React.Dispatch<
          React.SetStateAction<ActivitiesState>
        >,
        activityId
      );

      const payload: ActivityObject = {
        ...activityObject,
        submitted_yn: true,
        confirmed_yn: true,
        submitted_datetime: moment().toISOString(),
        confirmed_datetime: moment().toISOString(),
        last_update_date: moment().toISOString(),
        text_json: updatedTextJson!,
      };

      dispatch(startUpdateActivity(payload));
      setEditing(false);
    },
    [
      activityId,
      activityObject,
      confirmedActivitiesData,
      dispatch,
      setConfirmedActivities,
      setEditing,
      updatedTextJson,
    ]
  );

  return (
    <div className="ActivityCardItemBody">
      <div className="ActivityCardItemBody__Header">
        <div
          className={classNames("ActivityCardItemBody__Header__Title", {
            "ActivityCardItemBody__Header__Title--Editing":
              displayEditing && !isTeamDrafts,
            "ActivityCardItemBody__Header__Title--Confirmed":
              displayConfirmed && !isTeamDrafts,
            "ActivityCardItemBody__Header__Title--Draft":
              displayDraft && !isTeamDrafts,
            "ActivityCardItemBody__Header__Title--TeamDraft": isTeamDrafts,
          })}
        >
          {title}
        </div>
      </div>

      {!!updatedTextJson &&
        updatedTextJson.constructor === Array &&
        updatedTextJson.length > 0 && (
          <div className="ActivityCardItemBody__InputGroup">
            {updatedTextJson.map(
              (activityInput: ActivityInputObject, index: number) => {
                if (displayConfirmed || isTeamDrafts) {
                  return (
                    <ActivityInputDisplay
                      key={`${index}-${activityId}`}
                      activityInput={activityInput}
                      loading={activityLoading}
                    />
                  );
                } else {
                  return (
                    <ActivityInput
                      key={`${index}-${activityId}`}
                      inputIndex={index}
                      onFormattedInputChange={
                        handleFormattedActivityInputChange
                      }
                    />
                  );
                }
              }
            )}
          </div>
        )}

      {!!updatedTextJson && updatedTextJson.constructor === Object && (
        <div className="ActivityCardItemBody__InputGroup">
          {Object.entries(updatedTextJson).map(([key, value], index) => {
            const object = {} as {
              [key: string]: any;
            };
            object[key] = value;

            if (displayConfirmed || isTeamDrafts) {
              return (
                <ActivityInputDisplay
                  key={`${index}-${activityId}`}
                  activityInput={object}
                  loading={activityLoading}
                />
              );
            } else {
              return (
                <ActivityInput
                  key={`${index}-${activityId}`}
                  propertyKey={key}
                  inputIndex={index}
                  onUnformattedInputChange={
                    handleUnformattedActivityInputChange
                  }
                />
              );
            }
          })}
        </div>
      )}

      {(!confirmed_yn || editing) && !isTeamDrafts && (
        <div className="ActivityCardItemBody__Footer">
          {editing && (
            <CustomButton
              className="ActivityCardItemBody__Footer__CancelBtn"
              onClick={handleClickCancel}
            >
              Cancel
            </CustomButton>
          )}
          <CustomButton
            className="ActivityCardItemBody__Footer__ConfirmBtn"
            type="success"
            icon={<CheckoutIcon />}
            disabled={confirmIsDisabled}
            onClick={handleClickConfirm}
          >
            Confirm
          </CustomButton>
        </div>
      )}
    </div>
  );
};

export default ActivityCardItemBody;
