import React from "react";
import cn from "classnames";

import { AddIcon } from "../../CustomIcons/CustomIcons.component";
import TaskSearchBar from "../../Tasks/TaskSearchBar/TaskSearchBar";

import { TASK_PAGES } from "../../../constants/constants";
import { CustomButtonProps } from "../../CustomButton/CustomButton";

export interface AddTaskBtnProps extends Omit<CustomButtonProps, "type"> {
  type: ActivitiesSummaryType;
}

const AddTaskBtn: React.FC<AddTaskBtnProps> = ({
  className,
  disabled,
  type,
}) => {
  const [expand, setExpand] = React.useState(false);
  const [taskPage, setTaskPage] = React.useState<TaskPage>();

  React.useEffect(() => {
    if (type === "next-workday") {
      setTaskPage(TASK_PAGES.NEXT_SHIFT);
    } else if (type === "checkout") {
      setTaskPage(TASK_PAGES.TODAY);
    }
  }, [type]);

  return (
    <div
      className={cn("AddTask", className, {
        "AddTask--Disabled": disabled,
        "AddTask--Expand": expand,
      })}
    >
      {expand ? (
        <TaskSearchBar
          activitiesSummaryType={type}
          panelType="checkout"
          taskPage={taskPage}
          handleSearchClose={() => {
            setExpand(false);
          }}
        />
      ) : (
        <div
          className="AddTask__Btn"
          onClick={() => {
            setExpand(true);
          }}
        >
          <AddIcon />
          <span>Add task</span>
        </div>
      )}
    </div>
  );
};

export default AddTaskBtn;
