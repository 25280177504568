import { RootState } from "../store/configureStore";

export interface PrioritiesState extends BaseReducerState {
  data: PriorityArray;
}
export const selectPrioritiesState = (rootState: RootState) =>
  rootState.priorities;
export const prioritiesReducerDefaultState: PrioritiesState = {
  data: [],
  error: "",
  loading: false,
};

const prioritiesReducer = (
  state = prioritiesReducerDefaultState,
  action: { type: string; priorities: PriorityArray; error: string }
) => {
  switch (action.type) {
    case "REQUEST_FETCH_PRIORITIES":
      return {
        ...state,
        error: "",
        loading: true,
      };
    case "REQUEST_FETCH_PRIORITIES_SUCCESS":
      return {
        data: action.priorities,
        error: "",
        loading: false,
      };
    case "REQUEST_FETCH_PRIORITIES_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export { prioritiesReducer as default };
