import React from "react";
import { SketchPicker, ColorResult } from "react-color";
import { Popover } from "antd";

export const defaultColors = [
  "#FF2900",
  "#FF8000",
  "#FFAA00",
  "#40BF00",
  "#00BFBF",
  "#0079F2",
  "#8D00F2",
  "#F200A1",
];

/**
 * ColorPicker is the component that select color from a palette.
 * It uses react-color library and uses SketchPicker to implement picker.
 * When user pick a color from the SketchPicker component, the background of
 * this component will also change.
 */
const ColorPicker = ({
  colors = defaultColors,
  defaultValue,
  container,
  onChange,
}: ColorPickerProps) => {
  const [selectedColor, setSelectedColor] = React.useState<string>("");

  React.useEffect(() => {
    if (defaultValue) {
      setSelectedColor(defaultValue);
    }
  }, [defaultValue, colors]);

  const handleChange = (color: ColorResult) => {
    setSelectedColor(color.hex);
    if (onChange) {
      onChange(color.hex);
    }
  };

  return (
    <div className="ColorPicker">
      <Popover
        overlayClassName="ColorPicker__Popover"
        trigger="click"
        placement="right"
        getPopupContainer={(trigger: any) => container || trigger.parentElement}
        content={
          <SketchPicker
            width="344px"
            color={selectedColor}
            disableAlpha={true}
            presetColors={colors}
            onChange={handleChange}
          />
        }
      >
        {selectedColor ? (
          <div
            className="ColorPicker__Picker"
            style={{ backgroundColor: selectedColor }}
          />
        ) : (
          <div className="ColorPicker__Empty">Select a color</div>
        )}
      </Popover>
    </div>
  );
};

export default ColorPicker;
