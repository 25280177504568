import { Button as AntButton, ButtonProps as AntButtonProps } from "antd";
import React from "react";
import cn from "classnames";

/**
 * The wrapper of and design's button, and provide a
 * consistent UI including hover effects.
 */
const Button: React.FC<AntButtonProps> = ({
  children,
  className,
  type = "default",
  size = "middle",
  ...rest
}) => {
  return (
    <AntButton
      className={cn("AntButton", className, {
        "AntButton-small": size === "small",
        "AntButton-middle": size === "middle",
        "AntButton-large": size === "large",
        "AntButton-primary": type === "primary",
      })}
      type={type}
      {...rest}
    >
      {children}
    </AntButton>
  );
};

export default Button;
