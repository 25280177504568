import moment, { Moment } from "moment";
import tz from "moment-timezone";

export const isToday = (momentDate: moment.Moment) => {
  const today = moment().startOf("day");
  return momentDate.isSame(today, "d");
};

export const isThisMonth = (momentDate: moment.Moment) => {
  const today = moment().startOf("day");
  return momentDate.isSame(today, "month");
};

export const isMonthOf = (
  momentDate: moment.Moment,
  momentDateCheckMonth: moment.Moment
) => {
  return momentDate.isSame(momentDateCheckMonth, "month");
};

export const isYesterday = (momentDate: moment.Moment) => {
  const yesterday = moment().subtract(1, "days").startOf("day");
  return momentDate.isSame(yesterday, "d");
};

export const isPast = (momentDate: moment.Moment) => {
  return moment(momentDate).isBefore();
};

type SortObjectsArrayByDateKey = (
  array: any[],
  dateKey: string,
  latest: boolean
) => any[];
export const sortObjectsArrayByDateKey: SortObjectsArrayByDateKey = (
  array,
  dateKey,
  latest
) => {
  if (array.length === 0) {
    return array;
  }
  const isDateString = !isNaN(Date.parse(array[0][dateKey]));
  if (isDateString) {
    return array.sort((a, b) =>
      latest === true
        ? moment(a[dateKey]).isBefore(moment(b[dateKey]))
          ? 1
          : -1
        : moment(a[dateKey]).isAfter(moment(b[dateKey]))
        ? 1
        : -1
    );
  } else {
    return array;
  }
};

export const getDaysBetweenDates = function (
  startDate: Moment,
  endDate: Moment,
  format: string
) {
  const now = moment(startDate).clone(),
    dates = [];

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format(format));
    now.add(1, "days");
  }
  return dates;
};

export const getTimezones = (): string[] => {
  return tz.tz.names();
};

export const getBrowserTimezone = (): string => {
  return tz.tz.guess();
};

export const getTimezoneInfo = (location: string, now?: Date) => {
  const timezone = tz(now).tz(location);
  const [country, city] = location.split("/");
  const zone = (city || country).replace(/_/g, " ");
  return {
    timezone: location,
    location: zone,
    offset: timezone.utcOffset(),
    utc: timezone.format("Z"),
    time: timezone.format("HH:mm A"),
  };
};

export const getSortedTimezones = (now?: Date) => {
  return getTimezones()
    .map((tz) => getTimezoneInfo(tz, now))
    .sort((t1, t2) => {
      if (t1.offset === t2.offset) {
        return t1.location.localeCompare(t2.location);
      }
      const offset1 = Number(t1.offset);
      const offset2 = Number(t2.offset);
      return offset1 - offset2;
    });
};

export const formatTimezone = (location: string, now?: Date) => {
  const { utc, location: zone, time } = getTimezoneInfo(location, now);
  return `(UTC${utc}) ${zone} ${time}`;
};
