import React, { ChangeEvent } from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Input } from "antd";

import { ActivityCardItemContext } from "../ActivityCardItem/ActivityCardItem";
import { ActivityTabContentContext } from "../ActivityTabContent/ActivityTabContent";

import { startUpdateActivity } from "../../../actions/activities.action";
import { ActivitiesState } from "../../../reducers/activities.reducer";
import { ACTIVITY_FILTERS } from "../../../constants/activity.constant";

const { TextArea } = Input;

/**
 * Default display
 * Displays the input field for activities
 * Displayed for activity drafts
 * Displayed for confirmed and being edited activities
 *
 * Functionality
 * Local state inputValue is set to section_text prop on mount
 * Sets local state inputValue on input change
 * Sets local state focused to true on input focus
 * Sets local state focused to false on input focus
 * Debounce for 1s on inputValue change; if activity is draft, updates activity via endpoint (DB update)
 * Debounce for 1s on inputValue change; if activity is confirmed and being edited, updates global state (in-memory update)
 * Displays label if prop display_title_in_edit_yn is true
 */
export const setActivityLoadingState = (
  activities: ActivityArray,
  setter: React.Dispatch<React.SetStateAction<ActivitiesState>>,
  activityId: ActivityObject["id"]
) => {
  if (activities.find(({ id }) => id === activityId)) {
    setter((c) => ({
      ...c,
      data: c.data.map((activity) => {
        if (activity.id === activityId) {
          return {
            ...activity,
            loading: true,
          };
        }
        return activity;
      }),
    }));
  }
};
export interface ActivityInputProps {
  inputIndex: number;
  propertyKey?: string;
  onFormattedInputChange?: (
    activityInput: ActivityInputObject,
    activityInputIndex: number
  ) => void;
  onUnformattedInputChange?: (object: { [key: string]: any }) => void;
}
const ActivityInput: React.FC<ActivityInputProps> = ({
  inputIndex,
  propertyKey,
  onFormattedInputChange,
  onUnformattedInputChange,
}) => {
  const { activityObject, editing } = React.useContext(ActivityCardItemContext);
  const {
    userDrafts: { data: userDraftsData },
    setUserDrafts,
    submittedUnconfirmedActivities: {
      data: submittedUnconfirmedActivitiesData,
    },
    setSubmittedUnconfirmedActivities,
    filter,
  } = React.useContext(ActivityTabContentContext);

  const { text_json: activityText } = activityObject;
  const activityFormattedInputObject =
    activityText.constructor === Array ? activityText[inputIndex] : undefined;

  const activityUnformattedInputObject =
    activityText.constructor === Object ? activityText : undefined;

  const {
    display_title_in_edit_yn: displayInputTitleInEdit,
    section_text: inputText,
    section_title: inputTitle,
  } = activityFormattedInputObject || ({} as ActivityInputObject);

  const [inputValue, setInputValue] = React.useState<string>();
  const [focused, setFocused] = React.useState(false);

  React.useEffect(() => {
    if (!!inputText) {
      // if text_json is formatted properly
      setInputValue(inputText);
    } else if (activityUnformattedInputObject && propertyKey) {
      setInputValue(activityUnformattedInputObject[propertyKey]);
    }
  }, [inputText, activityUnformattedInputObject, propertyKey]);

  const dispatch = useDispatch();

  const handleChangeInput = React.useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      const value = event.target.value;
      setInputValue(value);
      if (onFormattedInputChange && activityText.constructor === Array) {
        onFormattedInputChange(
          {
            ...activityText[inputIndex],
            section_text: value,
          },
          inputIndex
        );
      }

      if (onUnformattedInputChange && activityText.constructor === Object) {
        const object = {} as { [key: string]: any };
        if (propertyKey) object[propertyKey] = value;
        onUnformattedInputChange(object);
      }
    },
    [
      activityText,
      inputIndex,
      onFormattedInputChange,
      onUnformattedInputChange,
      propertyKey,
    ]
  );

  const handleFocusInput = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    setFocused(true);
  };

  const handleBlurInput = React.useCallback(
    (event: React.FocusEvent<HTMLTextAreaElement>) => {
      setFocused(false);

      if (!editing && activityObject.text_json.constructor === Array) {
        const updatedTextJSON = (activityText as ActivityInputArray).map(
          (i, index) => {
            if (index === inputIndex) {
              return {
                ...activityFormattedInputObject,
                section_text: inputValue || "",
              };
            }
            return i;
          }
        );

        const updatedActivity: ActivityObject = {
          ...activityObject,
          text_json: updatedTextJSON,
          last_update_date: moment().toISOString(),
        };
        const { id: updatedActivityId } = updatedActivity;

        switch (filter) {
          case ACTIVITY_FILTERS.DEFAULT:
            setActivityLoadingState(
              userDraftsData,
              setUserDrafts as React.Dispatch<
                React.SetStateAction<ActivitiesState>
              >,
              updatedActivityId
            );
            break;
          case ACTIVITY_FILTERS.UNCONFIRMED:
            setActivityLoadingState(
              submittedUnconfirmedActivitiesData,
              setSubmittedUnconfirmedActivities as React.Dispatch<
                React.SetStateAction<ActivitiesState>
              >,
              updatedActivityId
            );
            break;
          default:
        }

        dispatch(startUpdateActivity(updatedActivity));
      } else if (!editing && activityObject.text_json.constructor === Object) {
        let clone = {
          ...activityObject,
          text_json: {
            ...activityUnformattedInputObject,
          } as {
            [key: string]: any;
          },
        };
        if (propertyKey) clone.text_json[propertyKey] = inputValue;
        dispatch(startUpdateActivity(clone));
      }
    },
    [
      activityFormattedInputObject,
      activityObject,
      activityText,
      activityUnformattedInputObject,
      inputValue,
      dispatch,
      editing,
      inputIndex,
      propertyKey,
      userDraftsData,
      setUserDrafts,
      filter,
      submittedUnconfirmedActivitiesData,
      setSubmittedUnconfirmedActivities,
    ]
  );

  return (
    <div
      className={classNames("ActivityInput", {
        "ActivityInput--Focused": focused,
        "ActivityInput--Filled": inputValue !== "",
        "ActivityInput--NoLabel": !displayInputTitleInEdit,
      })}
    >
      {displayInputTitleInEdit && (
        <div className="ActivityInput__Label">
          <span>{inputTitle}</span>
        </div>
      )}
      <TextArea
        bordered={false}
        value={inputValue}
        onChange={handleChangeInput}
        onFocus={handleFocusInput}
        onBlur={handleBlurInput}
        autoSize
        className="ActivityInput__TextArea"
      />
    </div>
  );
};

export default ActivityInput;
