import React from "react";

import { LoadingIcon } from "../../CustomIcons/CustomIcons.component";

import {
  markAsReadService,
  markActivitiesAsReadService,
} from "../../../services/unread-activities.service";
import { dispatchError } from "../../../utils/error.util";

interface MarkActivitiesAsReadProps {
  activityIdArray?: ActivityObject["id"][];
  id?: string;
  onClickAsync?: (...args: any[]) => Promise<any>;
  type?: "project" | "task" | "activity";
}
const MarkActivitiesAsRead: React.FC<MarkActivitiesAsReadProps> = ({
  activityIdArray,
  id,
  onClickAsync,
  type,
}) => {
  const [loading, setLoading] = React.useState(false);

  const handleClick = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!onClickAsync && id && type) {
      try {
        setLoading(true);
        const data: MarkAsReadParams = {
          projectId: type === "project" ? id : undefined,
          taskId: type === "task" ? id : undefined,
          activityId: type === "activity" ? id : undefined,
        };
        await markAsReadService(data);
        setLoading(false);
      } catch (e) {
        dispatchError({
          e,
          title: `Mark ${type} activities as read error`,
        });
        setLoading(false);
      }
    } else if (!onClickAsync && activityIdArray) {
      try {
        setLoading(true);
        await markActivitiesAsReadService(activityIdArray);
        setLoading(true);
      } catch (e) {
        dispatchError({
          e,
          title: `Mark activities as read error`,
        });
        setLoading(false);
      }
    } else if (onClickAsync) {
      try {
        setLoading(true);
        await onClickAsync();
        setLoading(false);
      } catch (e) {
        dispatchError({
          e,
          title: `Mark activities as read async callback error`,
        });
        setLoading(false);
      }
    }
  };

  return (
    <div className="MarkActivitiesAsRead" onClick={handleClick}>
      {loading ? <LoadingIcon /> : <span>Mark as read</span>}
    </div>
  );
};

export default MarkActivitiesAsRead;
