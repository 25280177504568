import React from "react";
import { Form, Modal as AntModal } from "antd";
import { useSelector, useDispatch } from "react-redux";

import Modal from "../General/Modal/Modal";
import AccountForm from "./AccountForm/AccountForm.component";
import PersonalForm from "./PersonalForm/PersonalForm.component";

import { startUpdateUserDetails } from "../../actions/user-details.action";
import { selectUserDetailsState } from "../../reducers/user-details.reducer";
import AvatarUploader from "./AvatarUploader/AvatarUploader";
import { getObjDiff } from "../../utils/object.util";

/**
 * This component is used to edit the profile of the login user.
 * we initiate the form object in this component and pass it to the form component
 * in order to have a full control of the data of the form.
 */
const AccountSettings = ({ open, onClose, onOk }: AccountSettingsProps) => {
  const dispatch = useDispatch();
  const { data: userDetails, loading: userDetailsLoading } = useSelector(
    selectUserDetailsState
  );
  const [accountForm] = Form.useForm();
  const [personalForm] = Form.useForm();

  // show initial avatar if it is empty string
  const [avatar, setAvatar] = React.useState<File | "">();

  React.useEffect(() => {
    if (!open) {
      accountForm.resetFields();
      personalForm.resetFields();
    }
  }, [open, accountForm, personalForm]);

  const handleSave = async () => {
    const accountValues = await accountForm.validateFields();
    const personalValues = await personalForm.validateFields();

    const payload = {
      ...userDetails,
      full_name: personalValues.fullName,
      display_name: personalValues.displayName,
      avatar: avatar,
      timezone: personalValues.timezone,
      country: personalValues.country,
      city: personalValues.city,
      what_i_do: personalValues.role,
    } as any;

    // only include in payload if value changed to be able to check updated keys
    const uniquePayload = {
      ...getObjDiff(payload, userDetails),
      avatar,
    } as AccountUser;

    // delete keys with undefined values
    Object.keys(uniquePayload).forEach((key) =>
      uniquePayload[key as keyof typeof uniquePayload] === undefined
        ? delete uniquePayload[key as keyof typeof uniquePayload]
        : {}
    );

    const result: any = await dispatch(startUpdateUserDetails(uniquePayload));
    if (result.success) {
      onOk();
    } else {
      AntModal.error({
        title: "Save Profile failed",
        content: result.error,
      });
    }
  };
  const handleAvatarChange = (avatar: File | "") => {
    setAvatar(avatar);
  };

  return (
    <Modal
      className="AccountSettingsModal"
      title="Edit Profile"
      maskClosable={false}
      centered={true}
      confirmLoading={userDetailsLoading}
      visible={open}
      onCancel={onClose}
      onOk={handleSave}
      destroyOnClose={true}
      okText="Save"
      cancelText="Close"
    >
      <div className="AccountSettings">
        <div className="AccountSettings__FormWrapper">
          <div className="AccountSettings__Title">Personal Information</div>
          <div className="AccountSettings__Form">
            <PersonalForm userDetails={userDetails} form={personalForm} />
          </div>

          <div className="AccountSettings__Title">Email and Password</div>
          <div className="AccountSettings__Form">
            <AccountForm userDetails={userDetails} form={accountForm} />
          </div>
        </div>
        <div className="AccountSettings__Avatar">
          <div className="AccountSettings__Title">Profile Photo</div>
          <AvatarUploader
            name={userDetails.display_name}
            defaultAvatar={userDetails.avatar || ""}
            onAvatarChange={handleAvatarChange}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AccountSettings;
