export const joinTaskObjectToUserTaskObject = (
  task: TaskObject,
  userTask?: JoinTaskToUserObject
) => {
  const { id: join_task_to_user_id, task: parent_task, ...rest } =
    userTask || {};
  return {
    ...task,
    join_task_to_user_id,
    ...rest,
  } as TaskJoinedToUserObject;
};

export const joinTaskArrayToUserTaskArray = (
  taskArray: TaskArray,
  userTaskArray: JoinTaskToUserArray
) => {
  return taskArray.map((taskObject: TaskObject) => {
    const userTaskObject = userTaskArray.find(
      (userTaskObject) => userTaskObject.task.id === taskObject.id
    );
    return joinTaskObjectToUserTaskObject(taskObject, userTaskObject);
  });
};

export const joinScheduledTaskAndTaskJoinedToUserTaskObject = (
  taskJoinedToUserObject: TaskJoinedToUserObject,
  scheduledTask?: ScheduleTaskObject
) => {
  return {
    ...taskJoinedToUserObject,
    scheduled_task_id: scheduledTask ? scheduledTask.id : null,
    scheduled_datetime: scheduledTask ? scheduledTask.scheduled_datetime : null,
  } as TaskJoinedToUserAndScheduledObject;
};

export const scheduleTaskJoinedToUser = (
  taskJoinedToUserArray: TaskJoinedToUserArray,
  scheduleTaskArray: ScheduleTaskArray
) => {
  const taskJoinedToUserAndScheduledArray = taskJoinedToUserArray.map(
    (taskJoinedToUserObject) => {
      const scheduleTask = scheduleTaskArray.find(
        (scheduleTaskArrayItem) =>
          scheduleTaskArrayItem.join_task_to_user ===
          taskJoinedToUserObject.join_task_to_user_id
      );
      return joinScheduledTaskAndTaskJoinedToUserTaskObject(
        taskJoinedToUserObject,
        scheduleTask
      );
    }
  );
  return taskJoinedToUserAndScheduledArray;
};
