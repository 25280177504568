import { RootState } from "../store/configureStore";

export const selectErrorState = (rootState: RootState) => rootState.error;

export const errorReducerDefaultState = null as ModalError;

const errorReducer = (
  state = errorReducerDefaultState,
  action: { type: string; error: ModalError }
) => {
  switch (action.type) {
    case "SET_ERROR":
      return action.error;
    default:
      return state;
  }
};

export { errorReducer as default };
