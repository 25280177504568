import React from "react";

import { getTasksAddedToWorkDayService } from "../services/tasks.service";

import ws from "../sockets/websockets";
import useConsolidateTask from "./useConsolidateTask.hook";

export default function useAsyncTasksAddedToWorkDay(
  workDayId: WorkDayObject["id"]
) {
  const [
    tasksAddedToWorkDay,
    setTasksAddedToWorkDay,
  ] = React.useState<TaskArray>();
  const [loading, setLoading] = React.useState(false);

  const [consolidatedTasks, consolidatedTasksLoading] = useConsolidateTask(
    tasksAddedToWorkDay
  );

  const isLoading = workDayId ? false : loading || consolidatedTasksLoading;

  const fetchTasksAddedToWorkDay = React.useCallback(async () => {
    if (workDayId) {
      try {
        setLoading(true);
        const response = await getTasksAddedToWorkDayService(workDayId);
        if (response.status === 200) {
          setTasksAddedToWorkDay(response.data);
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    }
  }, [workDayId]);

  React.useEffect(() => {
    fetchTasksAddedToWorkDay();
    const wsOnMessage = async (event: MessageEvent) => {
      try {
        const messageEventData = JSON.parse(event.data);
        const { event: eventType } = messageEventData;
        switch (eventType.trim()) {
          case "added_task_to_workday":
          case "removed_task_from_workday":
          case "removed_project_from_workday":
            fetchTasksAddedToWorkDay();
            break;
        }
      } catch (e) {}
    };
    ws.addEventListener("message", wsOnMessage);

    return () => {
      ws.removeEventListener("message", wsOnMessage);
    };
  }, [fetchTasksAddedToWorkDay]);

  return [consolidatedTasks, isLoading] as [
    TaskJoinedToUserAndScheduledArray | undefined,
    boolean
  ];
}
