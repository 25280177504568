import React from "react";
import moment from "moment";

import { ArrowPointerIcon } from "../../../CustomIcons/CustomIcons.component";
import { getTotalDuration } from "../../../../utils/time-conversion.util";

interface WorkHoursTooltipProps {
  work_time: number;
  allocated_time: number;
  tracked_time?: number;
}
const WorkHoursTooltip: React.FC<WorkHoursTooltipProps> = ({
  work_time,
  allocated_time,
  tracked_time,
}) => {
  return (
    <div className="WorkHoursTooltip">
      <div className="WorkHoursTooltip__Row">
        <div>Office (Work)</div>
        <div className="WorkHoursTooltip__Info">
          <ArrowPointerIcon />
          <span className="WorkHoursTooltip__Text">
            {getTotalDuration(moment.duration(work_time, "seconds"))}
          </span>
        </div>
      </div>
      <div className="WorkHoursTooltip__Row">
        <div>Tasks (Allocated)</div>
        <div>
          {getTotalDuration(moment.duration(allocated_time, "seconds"))}
        </div>
      </div>
      {tracked_time !== undefined && (
        <div className="WorkHoursTooltip__Row">
          <div>Tracked</div>
          <div>
            {getTotalDuration(moment.duration(tracked_time, "seconds"))}
          </div>
        </div>
      )}
    </div>
  );
};

export default WorkHoursTooltip;
