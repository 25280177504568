import React from "react";
import { Input, Radio, RadioChangeEvent } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";

import useDebounce from "../../../hooks/useDebounce.hook";

import Button from "../../Button/Button";
import { AddIcon } from "../../CustomIcons/CustomIcons.component";
import AddWorkspaceMemberModal from "../AddWorkspaceMemberModal/AddWorkspaceMemberModal";
import WorkspaceMembersTable from "../WorkspaceMembersTable/WorkspaceMembersTable";

import { getUsersByWorkspaceService } from "../../../services/users.service";
import WorkspaceMemberAccountPanel from "../WorkspaceMemberAccountPanel/WorkspaceMemberAccountPanel";

const { Group: RadioGroup, Button: RadioButton } = Radio;

export const filters = ["active", "inactive"];

const WorkspaceMembersPanel = () => {
  const [filter, setFilter] = React.useState(filters[0]);
  const [searchText, setSearchText] = React.useState("");
  const [workspaceMembers, setWorkspaceMembers] = React.useState<AccountUser[]>(
    []
  );
  const [filteredMembers, setFilteredMembers] = React.useState<AccountUser[]>(
    []
  );
  const [selectedMember, setSelectedMember] = React.useState<AccountUser>();
  const [showModal, setShowModal] = React.useState(false);

  const debounceSearchText = useDebounce(searchText, 1000);

  const handleFilterChange = (e: RadioChangeEvent) => {
    setFilter(e.target.value);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleTableRowClick = (record: any, rowIndex: any) => {
    const {
      member: { id: memberId },
    } = record;
    setSelectedMember(workspaceMembers.find(({ id }) => id === memberId));
  };

  // TODO - 13.16.1 - add callback for searching members using endpoint
  //   const handleProjectSearch = React.useCallback(() => {
  //     if (!!searchText && searchedProjectsLoadingState === "success") {
  //       setFilteredProjects(
  //         searchedProjects.filter(({ archived_yn }) =>
  //           filter === filters[0] ? true : archived_yn
  //         )
  //       );
  //     } else if (!searchText) {
  //       setFilteredProjects(
  //         projects.filter(({ archived_yn }) =>
  //           filter === filters[0] ? true : archived_yn
  //         )
  //       );
  //     }
  //   }, [
  //     filter,
  //     projects,
  //     searchText,
  //     searchedProjects,
  //     searchedProjectsLoadingState,
  //   ]);

  //   React.useEffect(() => {
  //     handleProjectSearch();
  //   }, [handleProjectSearch]);

  const getWorkspaceMembers = React.useCallback(async () => {
    try {
      const response = await getUsersByWorkspaceService();
      if (response.status === 200) {
        setWorkspaceMembers(response.data);
      }
    } catch (e) {
      const error = (e as Error).message;
    }
  }, []);

  const handleAddWorkspaceMemberConfirm = () => {
    /**
     * TODO 13.16.2: Add call to actual endpoint to add member to workspace
     */
  };

  React.useEffect(() => {
    getWorkspaceMembers();
  }, [getWorkspaceMembers]);

  React.useEffect(() => {
    const activeMembers = workspaceMembers;
    const inactiveMembers = [workspaceMembers[0]];
    const filteredMembers =
      filter === filters[0] ? activeMembers : inactiveMembers;
    if (!!debounceSearchText) {
      setFilteredMembers(
        filteredMembers.filter(({ display_name = "" }) =>
          display_name.toLowerCase().includes(debounceSearchText.toLowerCase())
        )
      );
    } else if (!debounceSearchText) {
      setFilteredMembers(filteredMembers);
    }
  }, [workspaceMembers, filter, debounceSearchText]);

  return (
    <>
      {selectedMember ? (
        <WorkspaceMemberAccountPanel member={selectedMember} />
      ) : (
        <div className="WorkspaceMembersPanel">
          <div className="WorkspaceMembersPanel__Header">
            <div className="WorkspaceMembersPanel__Header__Title">Members</div>
            <div className="WorkspaceMembersPanel__Header__Actions">
              <RadioGroup
                className="WorkspaceMembersPanel__Header__Actions__Filter"
                onChange={handleFilterChange}
                defaultValue={filters[0]}
              >
                <RadioButton value={filters[0]}>Active</RadioButton>
                <RadioButton value={filters[1]}>Inactive</RadioButton>
              </RadioGroup>
              <Input
                className="WorkspaceMembersPanel__Header__Actions__Search"
                value={searchText}
                size={"default" as SizeType}
                placeholder="Search"
                onChange={handleSearchChange}
              />
              <Button
                className="WorkspaceMembersPanel__Header__Actions__AddMemberBtn"
                onClick={() => setShowModal(true)}
              >
                <AddIcon />
                <span>Add new member</span>
              </Button>
              <AddWorkspaceMemberModal
                showModal={showModal}
                onCancelCallback={() => setShowModal(false)}
                onCloseCallback={() => setShowModal(false)}
                onConfirmCallback={handleAddWorkspaceMemberConfirm}
              />
            </div>
          </div>
          <div className="WorkspaceMembersPanel__Content">
            <span>
              Members page includes all members that were invited to your
              workspace. Workspace admins can manage existing and add new
              members.
            </span>
            <WorkspaceMembersTable
              members={filteredMembers}
              onRowClick={handleTableRowClick}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default WorkspaceMembersPanel;
