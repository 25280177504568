import { Dispatch } from "redux";
import { findIndex } from "lodash";

import { store } from "../store/configureStore";

import { setError } from "./error.action";
import {
  getTaskTimeService,
  addTaskTimeService,
  updateTaskTimeService,
} from "../services/task-time.service";
import { addTaskToWorkDayService } from "../services/tasks.service";
import { dispatchError } from "../utils/error.util";

export const populateTaskTime = (taskTime: TaskTimeArray) => ({
  type: "POPULATE_TASK_TIME",
  taskTime,
});

export const startPopulateTaskTime = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: "REQUEST_FETCH_TASK_TIME",
      });
      const response = await getTaskTimeService();
      if (response.status === 200) {
        dispatch({
          type: "REQUEST_FETCH_TASK_TIME_SUCCESS",
          taskTime: response.data,
        });
      } else {
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch({
        type: "REQUEST_FETCH_TASK_TIME_FAILED",
        error,
      });
      dispatchError({
        e,
        title: "Populate task time error",
      });
    }
  };
};

export const startAddTaskTime = (data: AddTaskTimeServiceData) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch({
        type: "REQUEST_CREATE_TASK_TIME",
      });
      const response = await addTaskTimeService(data);
      if (response.status === 201) {
        const createdTaskTime: TaskTimeObject = response.data;
        dispatch({
          type: "REQUEST_CREATE_TASK_TIME_SUCCESS",
          taskTime: [...store.getState().taskTime.data, createdTaskTime],
        });

        /**
         * TODO Bug 20211214-5: Remove call to add task to workday once AV finished refactoring
         * https://meistery.slack.com/archives/C02N7529JUU/p1639711301024900?thread_ts=1639472300.026700&cid=C02N7529JUU
         */
        const { task, work_day } = data;
        await addTaskToWorkDayService({
          task,
          user: store.getState().userDetails.data.id,
          work_day,
        });
      } else {
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch({
        type: "REQUEST_CREATE_TASK_TIME_FAILED",
        error,
      });
      dispatchError({
        e,
        title: "Add task time error",
      });
    }
  };
};

export const startUpdateTaskTime = (data: UpdateTaskTimeServiceData) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch({
        type: "REQUEST_UPDATE_TASK_TIME",
      });
      const response = await updateTaskTimeService(data);
      if (response.status === 200) {
        const updatedTaskTime: TaskTimeObject = response.data;
        const taskTimeStateData = store.getState().taskTime.data;
        const taskTimeIndex = findIndex(taskTimeStateData, [
          "id",
          updatedTaskTime.id,
        ]);
        dispatch({
          type: "REQUEST_UPDATE_TASK_TIME_SUCCESS",
          taskTime:
            taskTimeIndex !== -1
              ? taskTimeStateData.splice(taskTimeIndex, 1, updatedTaskTime)
              : taskTimeStateData,
        });
      } else {
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch({
        type: "REQUEST_UPDATE_TASK_TIME_FAILED",
        error,
      });
      dispatchError({
        e,
        title: "Update task time error",
      });
    }
  };
};
