import React from "react";
import {
  CheckoutIcon,
  DraftIcon,
} from "../../CustomIcons/CustomIcons.component";

const Label: React.FC<LabelProps> = ({ label, checked, onChange, onEdit }) => {
  const { id, title, color } = label;

  // apply 0.1 alpha to color as background
  const backgroundColor = color + "1A";

  return (
    <div className="Label">
      <div
        className="Label__Body"
        style={{ backgroundColor }}
        onClick={() => onChange(id)}
      >
        <div className="Label__Title" style={{ color }}>
          {title}
        </div>
        {checked && <CheckoutIcon className="Label__Check" style={{ color }} />}
      </div>
      <DraftIcon className="Label__Edit" onClick={() => onEdit(id)} />
    </div>
  );
};

export default Label;
