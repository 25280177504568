import { AxiosResponse } from "axios";

import axios from "../apis/django.instance";
import { dispatchError } from "../utils/error.util";

/**
 * Tasks Services
 */
export const getUserTaskGroupsCountService = async () => {
  try {
    const response = await axios.get("/api/projects/tasks/groups/counts/");
    if (response.status === 200) {
      return response.data as {
        today_count: number;
        next_workday_count: number;
        starred_count: number;
      };
    } else {
      throw new Error();
    }
  } catch (e) {
    dispatchError({
      e,
      title: "Get user task groups count error",
    });
    return null;
  }
};
interface GetTaskListServiceData {
  owner_user?: UserDetailsObject["id"];
  section?: SectionObject["id"];
  parent_list?: ListObject["id"];
  parent_project?: ProjectObject["id"];
  parent_type_enum?: ListObject["parent_type_enum"];
  search?: string;
  task_ids?: string[];
  page?: number;
  page_size?: number;
}
type GetTasksListService = (
  data?: GetTaskListServiceData
) => Promise<AxiosResponse<any>>;

export const getTasksListService: GetTasksListService = (data) => {
  const {
    owner_user,
    section,
    parent_list,
    parent_project,
    parent_type_enum,
    search,
    task_ids,
    page,
    page_size,
  } = data || {};
  return axios.get("/api/projects/task/", {
    params: {
      owner_user,
      section,
      parent_list,
      parent_project,
      parent_type_enum,
      search,
      details_yn: true,
      task_ids: task_ids ? task_ids?.join(",") : undefined,
      page,
      page_size,
    },
  }); // must be in this format in order to mock implementation in unit test
};

export const fetchTaskRecordService = (taskId: TaskObject["id"]) => {
  return axios.get(`/api/projects/task/${taskId}`, {
    params: {
      details_yn: true,
    },
  });
};

export interface CreateTaskRecordServiceData {
  section: SectionObject["id"];
  title: string;
  url: string;
  description: string;

  // will automatically set personal task values depending on which page task is created
  next_shift_yn?: boolean;
  today_yn?: boolean;
  starred_yn?: boolean;
}
export type CreateTaskRecordService = (
  data: CreateTaskRecordServiceData
) => Promise<AxiosResponse<TaskObject>>;
export const addTaskRecordService: CreateTaskRecordService = (data) => {
  return axios.post("/api/projects/task/", data); // must be in this format in order to mock implementation in unit test
};

export const getTasksInNextWorkShiftScheduleService: GetTasksInNextWorkShiftScheduleService = (
  data: GetTasksInNextWorkShiftScheduleServiceData
) => {
  const { end_datetime, start_datetime } = data;
  const params = {
    end_datetime,
    start_datetime,
  };
  return axios.get(`/api/work/shedule_task/`, { params });
};

export const partialUpdateTaskRecordService: PartialUpdateTaskService = (
  data
) => {
  const { id: taskId, ...payload } = data;
  return axios.patch(`/api/projects/task/${taskId}/`, payload);
};

/**
 * Join Task To User Services (Previously Personal Tasks)
 */

export const getJoinTaskToUserListService: GetJoinTaskToUserListService = ({
  next_shift_yn,
  starred_yn,
  today_yn,
  parent_list,
  parent_project,
  parent_type_enum,
  current_user = true,
  project_id,
  task,
}: GetJoinTaskToUserListServiceData) => {
  const params = {
    details_yn: true,
    next_shift_yn,
    starred_yn,
    today_yn,
    parent_list,
    parent_project,
    parent_type_enum,
    current_user,
    project_id,
    task,
  };
  return axios.get("/api/projects/user_task/", {
    params,
  }); // must be in this format in order to mock implementation in unit test
};
export const fetchUserTaskRecordService = (
  userTaskId: JoinTaskToUserObject["id"]
) => {
  const params = {
    details_yn: true,
  };
  return axios.get(`/api/projects/user_task/${userTaskId}`, {
    params,
  });
};

export const createJoinTaskToUserRecordService: CreateJoinTaskToUserRecordService = (
  data
) => axios.post("/api/projects/user_task/", data);

export const partialUpdateJoinTaskToUserRecordService: PartialUpdateJoinTaskToUserRecordService = (
  data
) => {
  const { id, ...payload } = data;
  return axios.patch(`/api/projects/user_task/${id}/`, payload);
};

export const updateJoinTaskToUserRecordService: UpdateJoinTaskToUserRecordService = (
  data
) => {
  const { id } = data;
  let clone: any = Object.assign({}, { ...data });
  delete clone.id;
  return axios.put(`/api/projects/user_task/${id}/`, clone); // must be in this format in order to mock implementation in unit test
};

type GetTasksInNextShiftService = (
  date: string
) => Promise<AxiosResponse<JoinTaskToUserArray>>;
export const getTasksInNextShiftService: GetTasksInNextShiftService = (
  date
) => {
  const params = {
    date,
    current_user: true,
  };
  // only need to add schedule to get correct response
  return axios.get("/api/projects/tasks_in_next_shift/", { params });
};

/**
 * Schedule Tasks Services
 */
interface GetScheduleTaskListServiceData {
  user_task_id?: JoinTaskToUserObject["id"];
}
type GetScheduleTaskListService = (
  data?: GetScheduleTaskListServiceData
) => Promise<AxiosResponse<any>>;
export const getScheduleTaskListService: GetScheduleTaskListService = (
  data
) => {
  if (!data) {
    return axios.get("/api/work/shedule_task/"); // must be in this format in order to mock implementation in unit test
  } else {
    return axios.get("/api/work/shedule_task/", {
      params: data,
    });
  }
};

export const fetchScheduleTaskRecordService = (
  scheduledTaskId: ScheduleTaskObject["id"]
) => {
  return axios.get(`/api/projects/user_task/${scheduledTaskId}`);
};

export const addScheduleTaskRecordService: AddScheduleTaskRecordService = (
  data
) => {
  return axios.post("/api/work/shedule_task/", data); // must be in this format in order to mock implementation in unit test
};

export const updateScheduleTaskRecordService: UpdateScheduleTaskRecordService = (
  data
) => {
  const { id } = data;
  let clone: any = Object.assign({}, { ...data });
  delete clone.id;
  return axios.put(`/api/work/shedule_task/${id}/`, clone); // must be in this format in order to mock implementation in unit test
};

export const partialUpdateScheduleTaskRecordService: PartialUpdateScheduleTaskRecordService = (
  data
) => {
  const { id, ...payload } = data;
  return axios.patch(`/api/work/shedule_task/${id}/`, payload);
};

export const deleteScheduleTaskRecordService: DeleteScheduleTaskRecordService = (
  id
) => {
  return axios.delete(`/api/work/shedule_task/${id}/`);
};

/**
 * Task Workday Services
 */
export interface AddRemoveTaskToFromWorkDayServiceData {
  task: TaskObject["id"];
  user: UserDetailsObject["id"];
  work_day: WorkDayObject["id"];
}
export interface AddRemoveTaskToFromWorkDayServiceResponse {
  task: TaskObject["id"];
  work_day: WorkDayObject["id"];
}
export type AddRemoveTaskToFromWorkDayService = (
  data: AddRemoveTaskToFromWorkDayServiceData
) => Promise<AxiosResponse<AddRemoveTaskToFromWorkDayServiceResponse>>;

export const addTaskToWorkDayService: AddRemoveTaskToFromWorkDayService = (
  data
) => {
  return axios.post(`/api/work/workday_task/`, data);
};

export const removeTaskFromWorkDayService: AddRemoveTaskToFromWorkDayService = (
  data
) => {
  return axios.delete(`/api/work/workday_task/`, { data });
};

export const getTasksAddedToWorkDayService = (
  workDayId: WorkDayObject["id"]
) => {
  return axios.get(`/api/work/work_day/${workDayId}/tasks/`);
};

export const getArchivedTasksInSectionService = (
  sectionId: SectionObject["id"]
) => {
  // TODO 13.11.3 - update with actual endpoint
  return axios.get(`/api/projects/section_archived_tasks/${sectionId}`);
};
