import React from "react";
import cn from "classnames";

/**
 * CardEmptyState is displayed as empty state for cards
 * prop message is displayed as the message
 */
export interface CardEmptyStateProps {
  message: string;
  panelType: PanelType;
}
const CardEmptyState: React.FC<CardEmptyStateProps> = ({
  message,
  panelType,
}) => {
  return (
    <div className={cn("CardEmptyState", `CardEmptyState--${panelType}Panel`)}>
      <span>{message}</span>
    </div>
  );
};

export default CardEmptyState;
