import { basicReducerDefaultState } from "../constants/constants";
import { RootState } from "../store/configureStore";

/**
 * WORK DAYS
 */
export interface WorkDaysInitAction {
  type: "@@INIT";
}
export interface WorkDaysRequestAction {
  type: "REQUEST_FETCH_WORK_DAYS" | "REQUEST_CREATE_WORK_DAY";
}
export interface WorkDaysFetchSuccessAction {
  type: "REQUEST_FETCH_WORK_DAYS_SUCCESS";
  workDays: WorkDayArray;
}
export interface WorkDaysRequestSuccessAction {
  type:
    | "REQUEST_PARTIAL_UPDATE_WORK_DAY"
    | "REQUEST_PARTIAL_UPDATE_WORK_DAY_SUCCESS"
    | "REQUEST_CREATE_WORK_DAY_SUCCESS";
  workDay: WorkDayObject;
}
export interface WorkDaysRequestFailedAction {
  type:
    | "REQUEST_FETCH_WORK_DAYS_FAILED"
    | "REQUEST_PARTIAL_UPDATE_WORK_DAY_FAILED"
    | "REQUEST_CREATE_WORK_DAY_FAILED";
  error: string;
}
export interface WorkDaysState extends BasicReducerState {
  data: WorkDayArray;
}
export type WorkDaysActions =
  | WorkDaysInitAction
  | WorkDaysFetchSuccessAction
  | WorkDaysRequestAction
  | WorkDaysRequestSuccessAction
  | WorkDaysRequestFailedAction;

export const selectWorkDaysState = (rootState: RootState) => rootState.workDays;
export const workDaysReducerDefaultState: WorkDaysState = {
  ...basicReducerDefaultState,
  data: [],
};
export const workDaysReducer = (
  state = workDaysReducerDefaultState,
  action: WorkDaysActions
) => {
  switch (action.type) {
    case "REQUEST_FETCH_WORK_DAYS":
      return {
        ...state,
        error: null,
        loading: true,
      };
    case "REQUEST_FETCH_WORK_DAYS_SUCCESS":
      return {
        ...state,
        data: action.workDays,
        error: null,
        loading: false,
      };
    case "REQUEST_FETCH_WORK_DAYS_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case "REQUEST_PARTIAL_UPDATE_WORK_DAY":
      return {
        ...state,
        error: null,
        data: state.data.map((item) => {
          if (item.id === action.workDay.id) {
            const updatingCheckoutNotes = Object.keys(action.workDay).includes(
              "checkout_notes"
            );
            const updatedWorkDay = {
              ...item,
              checkout_notes: {
                loading: updatingCheckoutNotes,
                value: updatingCheckoutNotes
                  ? action.workDay.checkout_notes
                  : item.checkout_notes.value,
              },
              loading: true,
            } as WorkDayObject;
            return updatedWorkDay;
          }
          return item;
        }),
      };
    case "REQUEST_PARTIAL_UPDATE_WORK_DAY_SUCCESS":
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.id === action.workDay.id) {
            return {
              ...action.workDay,
              loading: false,
            };
          }
          return item;
        }),
        error: null,
      };
    case "REQUEST_PARTIAL_UPDATE_WORK_DAY_FAILED":
      return {
        ...state,
        error: action.error,
      };
    case "REQUEST_CREATE_WORK_DAY":
      return {
        ...state,
        creating: true,
        error: null,
      };
    case "REQUEST_CREATE_WORK_DAY_SUCCESS":
      return {
        ...state,
        creating: false,
        data: [...state.data, action.workDay],
        error: null,
      };
    case "REQUEST_CREATE_WORK_DAY_FAILED":
      return {
        ...state,
        creating: false,
        error: action.error,
      };
    default:
      return state;
  }
};

/**
 * CURRENT WORKDAY
 */
export interface CurrentWorkDayState extends BaseReducerState {
  data: WorkDayObject;
}
export interface CurrentWorkDayInitAction {
  type:
    | "@@INIT"
    | "REQUEST_FETCH_CURRENT_WORK_DAY"
    | "SET_CURRENT_WORK_DAY_DEFAULT";
}
export interface FetchCurrentWorkDaySuccessAction {
  type: "REQUEST_FETCH_CURRENT_WORK_DAY_SUCCESS";
  currentWorkDay: WorkDayObject;
}
export interface CurrentWorkDayRequestFailedAction {
  type: "REQUEST_FETCH_CURRENT_WORK_DAY_FAILED";
  error: string;
}
export type CurrentWorkDayActions =
  | CurrentWorkDayInitAction
  | FetchCurrentWorkDaySuccessAction
  | CurrentWorkDayRequestFailedAction;

export const selectCurrentWorkDayState = (rootState: RootState) =>
  rootState.currentWorkDay;
export const currentWorkDayReducerDefaultState: CurrentWorkDayState = {
  data: {} as WorkDayObject,
  error: null,
  loading: false,
};
export const currentWorkDayReducer = (
  state = currentWorkDayReducerDefaultState,
  action: CurrentWorkDayActions
) => {
  switch (action.type) {
    case "REQUEST_FETCH_CURRENT_WORK_DAY":
      return {
        ...state,
        error: "",
        loading: true,
      };
    case "REQUEST_FETCH_CURRENT_WORK_DAY_SUCCESS":
      return {
        data: action.currentWorkDay,
        error: "",
        loading: false,
      };
    case "REQUEST_FETCH_CURRENT_WORK_DAY_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case "SET_CURRENT_WORK_DAY_DEFAULT":
      return currentWorkDayReducerDefaultState;
    default:
      return state;
  }
};

export const selectCheckoutWorkDayState = (rootState: RootState) =>
  rootState.checkoutWorkDay;
export const checkoutWorkDayReducerDefaultState = {} as WorkDayObject;
export const checkoutWorkDayReducer = (
  state = checkoutWorkDayReducerDefaultState,
  action: { type: string; checkoutWorkDay: WorkDayObject }
) => {
  switch (action.type) {
    case "SET_CHECKOUT_WORK_DAY":
      return action.checkoutWorkDay;
    default:
      return state;
  }
};
