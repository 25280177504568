import moment from "moment";

import statusTypes from "../fixtures/status-types.fixture";

export const statusHistoryArray: StatusHistoryArray = [
  {
    id: '1',
    end_datetime: moment().startOf("day").add(10, "hours").toISOString(),
    start_datetime: moment().startOf("day").add(9, "hours").toISOString(),
    status: statusTypes[0].id,
  },
  {
    id: '2',
    end_datetime: null,
    start_datetime: moment().subtract(5, "minutes").toISOString(),
    status: statusTypes[1].id,
  },
  {
    id: '3',
    end_datetime: moment().startOf("day").add(12, "hours").toISOString(),
    start_datetime: moment().startOf("day").add(11, "hours").toISOString(),
    status: statusTypes[0].id,
  },
];
export const statusHistoryObject = statusHistoryArray[0];
