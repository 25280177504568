import { Dispatch } from "redux";

import { getOfficeService } from "../services/office.service";
import { dispatchError } from "../utils/error.util";

export const requestOffice = () => ({
  type: "REQUEST_OFFICE",
});
export const requestOfficeSuccess = (payload: Office) => ({
  type: "REQUEST_OFFICE_SUCCESS",
  payload,
});
export const requestOfficeFailed = (error: string) => ({
  type: "REQUEST_OFFICE_FAILED",
  error,
});
export const startPopulateOffice = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(requestOffice());
      const response = await getOfficeService();
      dispatch(requestOfficeSuccess(response));
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestOfficeFailed(error));
      dispatchError({
        e,
        title: "Populate office error",
      });
    }
  };
};
