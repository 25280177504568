import React from "react";
import { Input } from "antd";
import ContributorList from "./ContributorList/ContributorList";

const ContributorsSearcher: React.FC<ContributorsSearcherProps> = ({
  task,
  taskMembers,
  listMembers,
  ownerId,
}) => {
  const [keyword, setKeyword] = React.useState("");

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.target.value.toLocaleLowerCase());
  };

  return (
    <div className="ContributorsSearcher">
      <Input
        className="ContributorsSearcher__Input"
        placeholder="Search by member name"
        bordered={false}
        onChange={handleSearch}
      />
      <ContributorList
        task={task}
        type="task"
        title="Task Members"
        contributors={taskMembers.filter((member) =>
          member.display_name.toLocaleLowerCase().includes(keyword)
        )}
        ownerId={ownerId}
      />
      <ContributorList
        task={task}
        type="list"
        title="List Members"
        contributors={listMembers.filter((member) =>
          member.display_name.toLocaleLowerCase().includes(keyword)
        )}
        ownerId={ownerId}
      />
    </div>
  );
};

export default ContributorsSearcher;
