import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { isEmpty } from "lodash";
import cn from "classnames";

import CheckoutTaskCollapsePanel from "../../../Checkout/CheckoutTaskCollapsePanel/CheckoutTaskCollapsePanel";
import PageEmptyState from "../../../General/PageEmptyState/PageEmptyState";
import SummaryOfTheDay from "../../../Checkout/SummaryOfTheDay/SummaryOfTheDay";
import { getUserWorkTimeTableData } from "../HistoryTabPane/HistoryTabPane";
import TeamMemberSelector from "../../../Team/TeamMemberSelector/TeamMemberSelector";
import TeamHistoryDateList from "../../../Team/TeamHistoryDateList/TeamHistoryDateList";
import { TeamPanelContext } from "../../../Team/TeamPanel/TeamPanel";

import { workDayDateFormat } from "../../../../constants/constants";
import { selectThirdPartyTrackerState } from "../../../../reducers/third-party-tracking.reducer";
import { selectCurrentWorkDayState } from "../../../../reducers/work-day.reducer";

const today = moment();
const TodayTabPane = () => {
  const {
    selectedObservableUser,
    observableUsers,
    observableUsersLoading,
  } = React.useContext(TeamPanelContext);

  const { data: thirdPartyTracker } = useSelector(selectThirdPartyTrackerState);
  const {
    data: { work_date: currentWorkDayDate },
  } = useSelector(selectCurrentWorkDayState);

  const [loading, setLoading] = React.useState(false);
  const [
    shownObservableUsers,
    setShownObservableUsers,
  ] = React.useState<ObservableUserArray>([]);
  const [
    shownObservableUserWithTableDataArray,
    setShownObservableUserWithTableDataArray,
  ] = React.useState<ShownObservableUserWithTableDataArray>();

  const hasUsers = shownObservableUsers.length > 0;
  const isLoading = loading || observableUsersLoading;

  React.useEffect(() => {
    if (selectedObservableUser) {
      setShownObservableUsers([selectedObservableUser]);
    } else if (!!observableUsers) {
      setShownObservableUsers(
        observableUsers.filter(({ shown_yn }) => shown_yn)
      );
    }
  }, [selectedObservableUser, observableUsers]);

  React.useEffect(() => {
    const getShownObservableUsersTableData = async () => {
      if (shownObservableUsers.length > 0 && !isEmpty(thirdPartyTracker)) {
        setLoading(true);
        try {
          const shownObservableUsersWithTableData: ShownObservableUserWithTableDataObject[] = await getUserWorkTimeTableData(
            moment(),
            shownObservableUsers,
            moment(),
            thirdPartyTracker
          );
          setShownObservableUserWithTableDataArray(
            shownObservableUsersWithTableData
          );
          setLoading(false);
        } catch (e) {
          setLoading(false);
        }
      }
    };
    getShownObservableUsersTableData();
  }, [JSON.stringify(shownObservableUsers), thirdPartyTracker]);

  return (
    <div className="TodayTabPane">
      <TeamMemberSelector />
      <div
        className={cn("TodayTabPane__Body", {
          "TodayTabPane__Body--Empty": !hasUsers,
        })}
      >
        {isLoading || !hasUsers ? (
          <PageEmptyState
            title="No user selected"
            content={<span>Select a user to see details</span>}
            loading={isLoading}
          />
        ) : (
          <>
            <div className="TodayTabPane__Body__DateRangeDisplay">
              {today.format("dddd, MMMM D")}
            </div>

            {!isLoading && shownObservableUserWithTableDataArray && (
              <TeamHistoryDateList
                shownObservableUserWithTableDataArray={
                  shownObservableUserWithTableDataArray
                }
              />
            )}

            <SummaryOfTheDay
              date={[currentWorkDayDate]}
              type="team"
              users={shownObservableUsers.map(
                ({ observed_user }) => observed_user
              )}
            />

            <CheckoutTaskCollapsePanel
              activitiesSummaryType="team"
              dates={[
                today.format(workDayDateFormat),
                today.format(workDayDateFormat),
              ]}
              users={shownObservableUsers.map(
                ({ observed_user }) => observed_user
              )}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default TodayTabPane;
