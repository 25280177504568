import React from "react";

import TaskDescriptionInput from "../../TaskDescriptionInput/TaskDescriptionInput";
import TaskDescriptionInputDisplay from "../../TaskDescriptionInputDisplay/TaskDescriptionInputDisplay";

export const DetailsTabContentContext = React.createContext<
  DetailsTabContentContext
>({} as DetailsTabContentContext);
/**
 * Displays description tab content
 * Empty for now
 */
const DetailsTabContent: React.FC<{task: TaskObject}> = ({ task }) => {
  const [editing, setEditing] = React.useState<boolean>(false);

  return (
    <DetailsTabContentContext.Provider
      value={{
        editing,
        setEditing,
      }}
    >
      <div className="DetailsTabContent">
        <div className="DetailsTabContent__Section">
          <div className="DetailsTabContent__Section__Title">Description</div>
          <div className="DetailsTabContent__Section__Content">
            {editing ? (
              <TaskDescriptionInput task={task} />
            ) : (
              <TaskDescriptionInputDisplay task={task} />
            )}
          </div>
        </div>
      </div>
    </DetailsTabContentContext.Provider>
  );
};

export { DetailsTabContent as default };
