import React from "react";
import cn from "classnames";
import { Avatar } from "antd";

interface AvatarGroupProps {
  avatars: string[];
  avatarsFallBackText?: string[];
  maxCount?: number;
  size?: number;
  onClick?: () => void;
}

const AvatarGroup: React.FC<AvatarGroupProps> = ({
  avatars,
  avatarsFallBackText,
  maxCount = 3,
  size = 20,
  onClick,
}) => {
  const expandedAvatars = avatars.slice(0, maxCount).map((avatar, index) => (
    <React.Fragment key={index}>
      {avatarsFallBackText && avatarsFallBackText[index] ? (
        <Avatar
          className={cn(
            { AvatarGroup__clickable: !!onClick },
            "AvatarGroup__FallbackText"
          )}
          style={{
            zIndex: avatars.length - index,
          }}
          src={avatar}
          size={size}
        >
          {avatarsFallBackText[index]}
        </Avatar>
      ) : (
        <Avatar
          className={cn({ AvatarGroup__clickable: !!onClick })}
          style={{ zIndex: avatars.length - index }}
          size={size}
          src={avatar}
        />
      )}
    </React.Fragment>
  ));
  const handleClick = () => {
    if (onClick) onClick();
  };
  return (
    <div className="AvatarGroup" onClick={handleClick}>
      {expandedAvatars}

      {/**
       * Count exceeding maxCount
       */}
      {(avatars.length > maxCount || avatars.length === 0) && (
        <Avatar
          size={size}
          className={cn("AvatarGroup__Count", {
            AvatarGroup__clickable: !!onClick,
          })}
        >
          {avatars.length === 0 ? 0 : avatars.length - maxCount}
        </Avatar>
      )}
    </div>
  );
};

export default AvatarGroup;
