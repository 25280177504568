import React from "react";
import { Form, Input } from "antd";

import Modal from "../../General/Modal/Modal";

const RenameSectionsModal = ({
  defaultValue,
  sections,
  removedSections,
  open,
  onOk,
  onClose,
}: RenameSectionsModalProps) => {
  const [sectionForm] = Form.useForm();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (open) {
      sectionForm.setFieldsValue({ section: defaultValue });
    }
  }, [open, defaultValue]);

  const handleRename = async () => {
    const values = await sectionForm.validateFields();
    const sectionName = values.section;
    if (sections.includes(sectionName) && sectionName !== defaultValue) {
      sectionForm.setFields([
        {
          name: "section",
          errors: ["This section already exists."],
        },
      ]);
      return;
    }
    if (removedSections.includes(sectionName) && sectionName !== defaultValue) {
      sectionForm.setFields([
        {
          name: "section",
          errors: ["This section already exists in ‘Removed Sections’."],
        },
      ]);
      return;
    }
    setLoading(true);
    await onOk(sectionName);
    setLoading(false);
  };
  return (
    <Modal
      className="RenameSectionsModal"
      title="Rename sections"
      centered={true}
      visible={open}
      confirmLoading={loading}
      okText="Save changes"
      onOk={handleRename}
      onCancel={onClose}
    >
      <div className="RenameSectionsModal__Table">
        <div className="RenameSectionsModal__Table__Body">
          <Form form={sectionForm}>
            <Form.Item
              name="section"
              rules={[{ required: true, message: "Enter a section name." }]}
            >
              <Input
                style={{ width: "100%" }}
                placeholder="Enter a section name."
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default RenameSectionsModal;
