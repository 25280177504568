import React from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "antd";
import { sumBy } from "lodash";

import { SummaryOfTheDayContext } from "../../Checkout/SummaryOfTheDay/SummaryOfTheDay";

import {
  CloseIcon,
  LoadingIcon,
} from "../../CustomIcons/CustomIcons.component";
import DeleteConfirmationModal from "../../General/DeleteConfirmationModal/DeleteConfirmationModal";

import { selectTaskTimeState } from "../../../reducers/task-time.reducer";
import { selectUserDetailsState } from "../../../reducers/user-details.reducer";
import { removeProjectFromWorkDayService } from "../../../services/projects.service";
import { dispatchError } from "../../../utils/error.util";
import { CheckoutPanelContext } from "../../Checkout/CheckoutPanel/CheckoutPanel";

export interface RemoveProjectFromWorkdayProps {
  project: ProjectObject;
}
const RemoveProjectFromWorkday: React.FC<RemoveProjectFromWorkdayProps> = ({
  project,
}) => {
  const { tasksAddedToWorkDay } = React.useContext(SummaryOfTheDayContext);
  const { checkoutWorkDay } = React.useContext(CheckoutPanelContext);

  const userDetails = useSelector(selectUserDetailsState);

  const [loading, setLoading] = React.useState(false);
  const [
    workDayProjectTotalTaskTime,
    setWorkDayProjectTotalTaskTime,
  ] = React.useState(0);
  const [
    displayConfirmationModal,
    setDisplayConfirmationModal,
  ] = React.useState(false);
  const [
    confirmationModalMessage,
    setConfirmationModalMessage,
  ] = React.useState("");

  const taskTime = useSelector(selectTaskTimeState).data;

  const { id: userId } = userDetails.data;
  const { id: checkoutWorkDayId = "" } = checkoutWorkDay || {};
  const { id: projectId } = project;

  React.useEffect(() => {
    if (
      projectId &&
      checkoutWorkDayId &&
      taskTime.length > 0 &&
      !!tasksAddedToWorkDay &&
      tasksAddedToWorkDay.length > 0
    ) {
      const projectObjectTaskArrayIds = tasksAddedToWorkDay.map(({ id }) => id);
      setWorkDayProjectTotalTaskTime(
        sumBy(
          taskTime.filter(
            ({ task, work_day }) =>
              projectObjectTaskArrayIds.includes(task) &&
              work_day === checkoutWorkDayId
          ),
          "seconds_worked"
        )
      );
    }
  }, [checkoutWorkDayId, projectId, tasksAddedToWorkDay, taskTime]);

  const removeProjectFromWorkDay = React.useCallback(async () => {
    if (userId && checkoutWorkDayId && !loading) {
      setLoading(true);
      try {
        const response = await removeProjectFromWorkDayService({
          project: projectId,
          user: userId,
          work_day: checkoutWorkDayId,
        });
        if (response.status === 200) {
          // TODO issue 20220202-3: synchronzie all tasks under project to be removed from today if BE does not support this
        }
      } catch (e) {
        dispatchError({
          e,
          title: `Remove project from workday error.`,
        });
        setLoading(false);
      }
    }
  }, [checkoutWorkDayId, loading, projectId, userId]);

  React.useEffect(() => {
    if (
      workDayProjectTotalTaskTime > 0 &&
      !!tasksAddedToWorkDay &&
      tasksAddedToWorkDay.length > 0
    ) {
      setConfirmationModalMessage(
        "Project will be removed and hours will be added to unallocated. Do you want to remove all tasks of this project?"
      );
    } else if (workDayProjectTotalTaskTime > 0) {
      setConfirmationModalMessage(
        "Project will be removed and hours will be added to unallocated"
      );
    } else if (!!tasksAddedToWorkDay && tasksAddedToWorkDay.length > 1) {
      setConfirmationModalMessage(
        "Do you want to remove all tasks of this project?"
      );
    } else {
      setConfirmationModalMessage("");
    }
  }, [tasksAddedToWorkDay, workDayProjectTotalTaskTime]);

  const handleClick = async () => {
    if (!confirmationModalMessage) {
      await removeProjectFromWorkDay();
      setDisplayConfirmationModal(false);
    } else {
      setDisplayConfirmationModal(true);
    }
  };

  return (
    <>
      <DeleteConfirmationModal
        closeCallback={() => setDisplayConfirmationModal(false)}
        confirmCallback={() => removeProjectFromWorkDay()}
        displayModal={displayConfirmationModal}
        objectName="project"
        setDisplayModal={setDisplayConfirmationModal}
        text={confirmationModalMessage}
      />
      <Tooltip title="Remove project">
        <div className="RemoveProjectFromWorkday" onClick={handleClick}>
          {loading ? <LoadingIcon /> : <CloseIcon />}
        </div>
      </Tooltip>
    </>
  );
};

export default RemoveProjectFromWorkday;
