import React from "react";
import { Input } from "antd";

import TaskContributorsDisplay from "../../TaskContributorsDisplay/TaskContributorsDisplay";

export interface TaskContributorSelectorPopoverContentProps {
  handleAddTaskContributor: (taskContributor: ProjectUser) => void;
  handleRemoveTaskContributor: (taskContributor: ProjectUser) => void;
  role: TaskContributorRole;
  selectedTaskContributors: ProjectUser[];
  taskContributors: ProjectUser[];
}
const TaskContributorSelectorPopoverContent: React.FC<TaskContributorSelectorPopoverContentProps> = ({
  handleAddTaskContributor,
  handleRemoveTaskContributor,
  role,
  selectedTaskContributors,
  taskContributors,
}) => {
  const [
    filteredTaskContributors,
    setFilteredTaskContributors,
  ] = React.useState<ProjectUser[]>([]);
  const [searchText, setSearchText] = React.useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  React.useEffect(() => {
    // filter task contributors so we don't include selected task contributors in workspace list to be selected
    const withoutSelected = taskContributors.filter((taskContributor) => {
      return selectedTaskContributors.indexOf(taskContributor) === -1;
    });

    setFilteredTaskContributors(
      withoutSelected.filter(({ fullName = "" }) =>
        fullName.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  }, [searchText, selectedTaskContributors, taskContributors]);

  return (
    <div className="TaskContributorSelectorPopoverContent">
      <Input
        className="TaskContributorSelectorPopoverContent__Search"
        placeholder={`Search by ${role} name"`}
        onChange={handleInputChange}
      />
      <TaskContributorsDisplay
        handleRemove={handleRemoveTaskContributor}
        taskContributors={selectedTaskContributors}
        type="selected"
        role={role}
      />
      <TaskContributorsDisplay
        handleAdd={handleAddTaskContributor}
        taskContributors={filteredTaskContributors}
        type="workspace"
        role={role}
      />
    </div>
  );
};

export default TaskContributorSelectorPopoverContent;
