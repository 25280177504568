import { Dispatch } from "redux";

import { store } from "../store/configureStore";

import {
  getCheckoutChecksService,
  getWorkspaceCheckoutChecksStatusService,
  getCheckoutCheckStatusService,
  GetWorkspaceCheckoutCheckStatusServiceData,
} from "../services/checkout.service";
import { dispatchError } from "../utils/error.util";

export const requestFetchCheckoutChecks = () => ({
  type: "REQUEST_FETCH_CHECKOUT_CHECKS",
});
export const requestFetchCheckoutChecksSuccess = (
  checkoutChecks: CheckoutCheckArray
) => ({
  type: "REQUEST_FETCH_CHECKOUT_CHECKS_SUCCESS",
  checkoutChecks,
});
export const requestFetchCheckoutChecksFailed = (error: string) => ({
  type: "REQUEST_FETCH_CHECKOUT_CHECKS_FAILED",
  error,
});

export const startFetchCheckoutChecks = () => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(requestFetchCheckoutChecks());
      const response = await getCheckoutChecksService();
      if (response.status === 200) {
        dispatch(requestFetchCheckoutChecksSuccess(response.data));
      } else {
        const error = "getCheckoutChecksService status is not 200";
        dispatch(requestFetchCheckoutChecksFailed(error));
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestFetchCheckoutChecksFailed(error));
      dispatchError({
        e,
        title: "Get checkout checks error",
      });
    }
  };
};

export const requestFetchCheckoutStatus = () => ({
  type: "REQUEST_FETCH_CHECKOUT_STATUS",
});
export const requestFetchCheckoutStatusSuccess = (
  checkoutStatus: CheckoutStatusArray
) => ({
  type: "REQUEST_FETCH_CHECKOUT_STATUS_SUCCESS",
  checkoutStatus,
});
export const requestFetchCheckoutStatusFailed = (error: string) => ({
  type: "REQUEST_FETCH_CHECKOUT_STATUS_FAILED",
  error,
});
export const startFetchCheckoutStatus = () => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(requestFetchCheckoutStatus());
      const {
        userDetails: {
          data: { id: userId },
        },
        currentWorkDay: {
          data: { work_date: currentWorkDayDate },
        },
      } = store.getState();
      const response = await getWorkspaceCheckoutChecksStatusService({
        user_id: userId,
        work_date: currentWorkDayDate,
      });
      if (response.status === 200) {
        dispatch(requestFetchCheckoutStatusSuccess(response.data));
      } else {
        const error =
          "getWorkspaceCheckoutChecksStatusService status is not 200";
        dispatch(requestFetchCheckoutStatusFailed(error));
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestFetchCheckoutStatusFailed(error));
      dispatchError({
        e,
        title: "Get checkout status error",
      });
    }
  };
};

export const requestFetchCheckoutDateStatus = () => ({
  type: "REQUEST_FETCH_CHECKOUT_DATE_STATUS",
});
export const requestFetchCheckoutDateStatusSuccess = (
  checkoutDateStatus: CheckoutStatusArray
) => ({
  type: "REQUEST_FETCH_CHECKOUT_DATE_STATUS_SUCCESS",
  checkoutDateStatus,
});
export const requestFetchCheckoutDateStatusFailed = (error: string) => ({
  type: "REQUEST_FETCH_CHECKOUT_DATE_STATUS_FAILED",
  error,
});
export const startFetchCheckoutDateStatus = ({
  user_id,
  work_date,
}: GetWorkspaceCheckoutCheckStatusServiceData) => {
  return async (dispatch: Dispatch<any>) => {
    if (user_id && work_date) {
      try {
        dispatch(requestFetchCheckoutDateStatus());
        const response = await getWorkspaceCheckoutChecksStatusService({
          user_id,
          work_date,
        });
        if (response.status === 200) {
          dispatch(requestFetchCheckoutDateStatusSuccess(response.data));
        } else {
          const error =
            "getWorkspaceCheckoutChecksStatusService status is not 200";
          dispatch(requestFetchCheckoutDateStatusFailed(error));
          throw new Error();
        }
      } catch (e) {
        const error = (e as Error).message;
        dispatch(requestFetchCheckoutDateStatusFailed(error));
        dispatchError({
          e,
          title: "Get checkout date status error",
        });
      }
    }
  };
};

export const requestFetchCheckoutCheckStatuses = () => ({
  type: "REQUEST_FETCH_CHECKOUT_CHECK_STATUSES",
});
export const requestFetchCheckoutCheckStatusesSuccess = (
  checkoutCheckStatuses: CheckoutCheckStatus[]
) => ({
  type: "REQUEST_FETCH_CHECKOUT_CHECK_STATUSES_SUCCESS",
  checkoutCheckStatuses,
});
export const requestFetchCheckoutCheckStatusesFailed = (error: string) => ({
  type: "REQUEST_FETCH_CHECKOUT_CHECK_STATUSES_FAILED",
  error,
});
export const startGetCheckoutCheckStatuses = () => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(requestFetchCheckoutCheckStatuses());
      const response = await getCheckoutCheckStatusService();
      if (response.status === 200) {
        const statuses: CheckoutCheckStatus[] = response.data;
        dispatch(
          requestFetchCheckoutCheckStatusesSuccess(
            statuses.sort(
              (a, b) =>
                new Date(b.work_day.work_date).getTime() -
                new Date(a.work_day.work_date).getTime()
            )
          )
        );
      } else {
        const error = "getCheckoutCheckStatusService status is not 200";
        dispatch(requestFetchCheckoutCheckStatusesFailed(error));
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestFetchCheckoutCheckStatusesFailed(error));
      dispatchError({
        e,
        title: "Get checkout check statuses error",
      });
    }
  };
};
