import React from "react";
import { Form, Switch } from "antd";

import { InfoCircleOutlinedIcon } from "../../CustomIcons/CustomIcons.component";

export interface AccessRightsFormProps {
  form: any;
  allowAddManualShifts?: boolean;
  allowInvisibleMode?: boolean;
  onValuesChange: (changedValues: any, allValues: any) => void;
}
const AccessRightsForm: React.FC<AccessRightsFormProps> = ({
  form,
  allowAddManualShifts = false,
  allowInvisibleMode,
  onValuesChange,
}) => {
  return (
    <div className="AccessRightsForm">
      <div className="AccessRightsForm__Title">
        <span>Access Rights</span>
      </div>
      <Form
        form={form}
        onValuesChange={(changedValues: any, allValues: any) =>
          onValuesChange(changedValues, allValues)
        }
      >
        <Form.Item
          name="allowAddManualShifts"
          label="Allow ‘Add manual shifts'"
          tooltip={{
            title: `If on, the member can add manual shifts to the Checkout.`,
            icon: <InfoCircleOutlinedIcon />,
          }}
        >
          <Switch defaultChecked={allowAddManualShifts} />
        </Form.Item>
        <Form.Item
          name="allowInvisibleMode"
          label="Allow ‘Invisible mode’"
          tooltip={{
            title: `If on, the member can switch to Invisible mode.`,
            icon: <InfoCircleOutlinedIcon />,
          }}
        >
          <Switch defaultChecked={allowInvisibleMode} />
        </Form.Item>
      </Form>
    </div>
  );
};

export default AccessRightsForm;
