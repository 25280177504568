import React from "react";
import { useSelector } from "react-redux";
import { Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import { useFeature } from "flagged";

import HistoryTabPane from "./HistoryTabPane/HistoryTabPane";
import ScheduleTabPane from "./ScheduleTabPane/ScheduleTabPane";
import TodayTabPane from "./TodayTabPane/TodayTabPane";

import { teamPanelTabs } from "../../../constants/team.constant";
import { selectUserDetailsState } from "../../../reducers/user-details.reducer";
import { getObservableUsersService } from "../../../services/observable_users.service";

import ws from "../../../sockets/websockets";
import { isEmpty } from "lodash";
import PageUnderConstruction from "../../General/PageUnderConstruction/PageUnderConstruction";

const { TabPane } = Tabs;

const getTabPaneContent = (tab: string) => {
  switch (tab) {
    case "Today":
      return <TodayTabPane />;
    case "History":
      return <HistoryTabPane />;
    case "Schedule":
    default:
      return <ScheduleTabPane />;
  }
};

export const TeamPanelContext = React.createContext<TeamPanelContext>(
  {} as TeamPanelContext
);

const TeamPanel = () => {
  const isBeta = useFeature("under_development");
  const navigate = useNavigate();

  const userDetails = useSelector(selectUserDetailsState);

  const [
    observableUsers,
    setObservableUsers,
  ] = React.useState<ObservableUserArray>();
  const [observableUsersLoading, setObservableUsersLoading] = React.useState(
    false
  );
  const [
    selectedObservableUser,
    setSelectedObservableUser,
  ] = React.useState<ObservableUserObject>();

  const starredObservableUsers = (observableUsers || []).filter(
    ({ starred_yn }) => starred_yn
  );
  const shownObservableUsers = selectedObservableUser
    ? [selectedObservableUser]
    : starredObservableUsers.filter(({ shown_yn }) => shown_yn);

  const fetchObservableUsers = React.useCallback(async () => {
    try {
      setObservableUsersLoading(true);
      const response = await getObservableUsersService();
      if (response.status === 200) {
        setObservableUsersLoading(false);
        setObservableUsers(response.data);
      } else {
        throw new Error();
      }
    } catch (e) {
      setObservableUsersLoading(false);
    }
  }, []);

  React.useEffect(() => {
    fetchObservableUsers();

    const wsOnMessage = async (event: MessageEvent) => {
      try {
        const messageEventData = JSON.parse(event.data);
        const { event: eventType } = messageEventData;
        switch (eventType.trim()) {
          case "created_or_updated_observable_user":
            fetchObservableUsers();
            break;
        }
      } catch (e) {}
    };
    ws.addEventListener("message", wsOnMessage);
    return () => {
      ws.removeEventListener("message", wsOnMessage);
    };
  }, [fetchObservableUsers]);

  React.useEffect(() => {
    if (
      !isEmpty(userDetails.data) &&
      !userDetails.data.team_page_access_granted_yn
    ) {
      navigate(-1);
    }
  }, [navigate, userDetails.data]);

  return (
    <TeamPanelContext.Provider
      value={{
        selectedObservableUser,
        setSelectedObservableUser,
        observableUsers,
        observableUsersLoading,
        setObservableUsers,
        starredObservableUsers,
        shownObservableUsers,
      }}
    >
      <div className="TeamPanel">
        {isBeta ? (
          <Tabs className="TeamPanel__Tabs" defaultActiveKey="1">
            {teamPanelTabs.map((tab, index) => (
              <TabPane
                className="TeamPanel__Tabs__TabPane"
                tab={tab}
                key={index}
              >
                {getTabPaneContent(tab)}
              </TabPane>
            ))}
          </Tabs>
        ) : (
          <PageUnderConstruction />
        )}
      </div>
    </TeamPanelContext.Provider>
  );
};

export default TeamPanel;
