import React from "react";
import cn from "classnames";

import {
  CollapseIcon,
  ExpandIcon,
  SuperExpandIcon,
} from "../../CustomIcons/CustomIcons.component";
import AddTaskBtn from "../../Tasks/AddTaskBtn/AddTaskBtn";

export const activityFilters = ["unread", "all"];

/**
 * Default Display
 * SummaryOfTheDayHeader displays the buttons to collapse or expand all of the collapsible panels nested in SummaryOfTheDay
 *
 * Functionality
 * Calls prop onCollapse when CollapseIcon is clicked
 * Calls prop onExpand when ExpandIcon is clicked
 * Calls prop onSuperExpand when SuperExpandIcon is clicked
 */
interface SummaryOfTheDayHeaderProps {
  activitiesFilter: ActivitiesFilter;
  editingAllowed: boolean;
  onCollapse: Function;
  onExpand: Function;
  onFilterClick: Function;
  onSuperExpand: Function;
  type: SummaryOfTheDayProps["type"];
}
const SummaryOfTheDayHeader: React.FC<SummaryOfTheDayHeaderProps> = ({
  activitiesFilter,
  editingAllowed,
  onCollapse,
  onExpand,
  onFilterClick,
  onSuperExpand,
  type,
}) => {
  const titleText =
    type === "checkout" || type === "next-workday" ? "Tasks" : "Activity";

  const handleFilterClick = (filterItem: string) => (e: React.MouseEvent) => {
    onFilterClick(filterItem);
  };

  return (
    <div className="SummaryOfTheDayHeader">
      <div className="SummaryOfTheDayHeader__Title">
        <div className="SummaryOfTheDayHeader__Title__Text">{titleText}</div>
      </div>
      <div className="SummaryOfTheDayHeader__Actions">
        {type === "team" && (
          <div className="SummaryOfTheDayHeader__Actions__Filter">
            {activityFilters.map((filter, index) => (
              <div
                key={index}
                className={cn("SummaryOfTheDayHeader__Actions__Filter__Item", {
                  "SummaryOfTheDayHeader__Actions__Filter__Item--Active":
                    activitiesFilter === filter,
                })}
                onClick={handleFilterClick(filter)}
              >
                <span>{filter}</span>
              </div>
            ))}
          </div>
        )}
        {(type === "checkout" || type === "next-workday") && (
          <AddTaskBtn disabled={!editingAllowed} type={type} />
        )}
        <div
          className={cn("SummaryOfTheDayHeader__Actions__Collapse", {
            "SummaryOfTheDayHeader__Actions__Collapse--NextWorkday":
              type === "next-workday",
          })}
        >
          <CollapseIcon onClick={onCollapse} />
          <ExpandIcon onClick={onExpand} />
          {type !== "next-workday" && (
            <SuperExpandIcon onClick={onSuperExpand} />
          )}
        </div>
      </div>
    </div>
  );
};

export default SummaryOfTheDayHeader;
