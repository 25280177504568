export const checkoutStatus: CheckoutStatusArray = [
  {
    id: "1",
    work_day: "34",
    checkout_check: "1",
    passed_yn: false,
    issue_json: {
      checkout_failed_message:
        "Msg: You have 100% of unallocated time. Please reduce it below 20%.",
    },
    n_checkout_items: 1,
  },
  {
    id: "2",
    work_day: "34",
    checkout_check: "2",
    passed_yn: true,
    issue_json: null,
    n_checkout_items: 0,
  },
  {
    id: "3",
    work_day: "34",
    checkout_check: "3",
    passed_yn: true,
    issue_json: null,
    n_checkout_items: 0,
  },
];

export const checkoutStatusPassed: CheckoutStatusArray = checkoutStatus.map(
  (item) => {
    item.passed_yn = true;
    return item;
  }
);

export const checkoutChecks: CheckoutCheckArray = [
  {
    id: "2",
    title: "confirm_activity",
    identifier: "confirm_activity",
    success_message: "Activity confirmation check passed",
    failed_message_template:
      "Msg: The activity was not finished. Please finish and confirm it.",
    configuration_json: null,
  },
  {
    id: "3",
    title: "schedule_for_next_shift",
    identifier: "schedule_for_next_shift",
    success_message: "Next shift schedule time check passed",
    failed_message_template:
      "Msg: The schedule for the next shift was not defined. Please define it.",
    configuration_json: null,
  },
  {
    id: "1",
    title: "unallocated_time",
    identifier: "unallocated_time",
    success_message: "Unallocated time check passed",
    failed_message_template:
      "Msg: You have {}% of unallocated time. Please reduce it below 20%.",
    configuration_json: { unallocated_time_threshold_percentage: 44 },
  },
];

export const checkoutCheckStatuses: CheckoutCheckStatus[] = [
  {
    id: '1',
    checkout_check: '1',
    message_dismissed_yn: false,
    n_checkout_items: 1,
    passed_yn: true,
    work_day: {
      id: '1',
      checkout_notes: '',
      user: '1',
      work_date: '2021-09-10'
    }
  }
]
