import React from "react";
import cn from "classnames";

import TimeSlot from "./TimeSlot";
import TimeBar from "../../../TimeBar/TimeBar";
import { LoadingIcon } from "../../../CustomIcons/CustomIcons.component";

const TimeCard: React.FC<TimeCardProps> = ({
  timeSlots,
  showTimeBar = true,
  loading = false,
}) => {
  // we expand all the slots since there may have some slots with secondary children slots.
  const flatSlots = timeSlots.flatMap((slot) => slot.children || slot);

  // split slots by priorty
  const prioritySlots = flatSlots.filter((slot) => !!slot.priority);
  const nonPrioritySlots = flatSlots.filter((slot) => !slot.priority);

  // sort slot bar by priority if has
  const sortedSlots = [
    ...prioritySlots.sort((a, b) => a.priority! - b.priority!),
    ...nonPrioritySlots,
  ];
  const slots = timeSlots.map((slot, index) => (
    <TimeSlot key={index} timeSlot={slot} />
  ));
  const showLoadingIcon = loading && !showTimeBar;
  return (
    <div className={cn("TimeCard", { "TimeCard-loading": showLoadingIcon })}>
      {showLoadingIcon && <LoadingIcon />}
      {showTimeBar && <TimeBar timeSlots={sortedSlots} loading={loading} />}
      {!showLoadingIcon && slots}
    </div>
  );
};

export default TimeCard;
