export const externalCalendarPresetArray: CalendarPreset[] = [
  "day",
  "week",
  "month",
];

export const getPresetText = (
  preset: CalendarPreset,
  isPanel: boolean = false
) => {
  if (isPanel) {
    return preset.replace(/_/g, " ");
  }
  return preset;
};

export const internalCalendarPresetArray: CalendarPreset[] = [
  "today",
  "current_week",
  "current_month",
];
