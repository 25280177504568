import { RootState } from "../store/configureStore";

export interface AuthState extends BaseReducerState {
  data: Auth;
}
export const authReducerDefaultState: AuthState = {
  data: {} as Auth,
  error: "",
  loading: false,
};
export const selectAuthState = (rootState: RootState) => rootState.auth;
const authReducer = (
  state = authReducerDefaultState,
  action: {
    type: string;
    token: string | null;
    email: string | null;
    error: string;
  }
) => {
  switch (action.type) {
    case "REQUEST_FETCH_LOGIN":
    case "REQUEST_FETCH_LOGOUT":
      return {
        ...state,
        error: "",
        loading: true,
      };
    case "REQUEST_FETCH_LOGIN_SUCCESS":
      return {
        data: {
          token: action.token,
          email: action.email,
        } as Auth,
        error: "",
        loading: false,
      };
    case "REQUEST_FETCH_LOGIN_FAILED":
    case "REQUEST_FETCH_LOGOUT_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case "REQUEST_FETCH_LOGOUT_SUCCESS":
      return {
        data: {} as Auth,
        error: "",
        loading: false,
      };
    default:
      return state;
  }
};

export { authReducer as default };
