import axios from "../apis/django.instance";

export const getObservableUsersService = () => {
  return axios.get(`/api/accounts/me/observable_users/`); // must be in this format in order to mock implementation in unit test
};

export const partialUpdateObservableUserService = (
  data: Partial<ObservableUserObject>
) => {
  const { id, ...payload } = data;
  return axios.patch(`/api/accounts/me/observable_users/${id}/`, payload);
};

export const getObserverUsersService = () => {
  return axios.get("api/accounts/me/observers/");
};
