import React from "react";
import TeamMemberSelector from "../../../Team/TeamMemberSelector/TeamMemberSelector";

const ScheduleTabPane = () => {
  return (
    <div className="ScheduleTabPane">
      <TeamMemberSelector />
    </div>
  );
};

export default ScheduleTabPane;
