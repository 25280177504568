import userDetails from "./user-details.fixture";
import { ActivitiesState } from "../reducers/activities.reducer";

const activities: ActivityArray = [
  {
    id: "NUSBRB1LOJ",
    task: "233M5RE2EE",
    user: "EGUN7PV8KA",
    username: "Admin",
    avatar: "",
    activity_type: "JNRI18TQ6S",
    created_datetime: "2021-08-10T00:19:30.158183Z",
    created_automatically_yn: false,
    last_update_date: "2021-08-10T00:19:30.158183Z",
    confirmed_yn: false,
    confirmed_datetime: null,
    submitted_yn: false,
    submitted_datetime: null,
    text_json: [
      {
        section_text: "",
        section_title: "Priorities",
        input_required_yn: false,
        display_title_in_edit_yn: true,
        display_title_in_read_yn: true,
        display_section_in_read_if_empty_yn: false,
      },
      {
        section_text: "",
        section_title: "Report",
        input_required_yn: true,
        display_title_in_edit_yn: true,
        display_title_in_read_yn: true,
        display_section_in_read_if_empty_yn: true,
      },
      {
        section_text: "",
        section_title: "Next steps",
        input_required_yn: false,
        display_title_in_edit_yn: true,
        display_title_in_read_yn: true,
        display_section_in_read_if_empty_yn: false,
      },
    ],
    deleted_yn: false,
    changed_yn: false,
    change_datetime: null,
    loading: false,
  },
  {
    id: "N0K5E5STPN",
    task: "233M5RE2EE",
    user: "9LBX5JWFS1",
    username: "Briggs Victoria",
    avatar: "",
    activity_type: "JNRI18TQ6S",
    created_datetime: "2021-08-10T00:16:48.715374Z",
    created_automatically_yn: false,
    last_update_date: "2021-08-10T00:16:48.715374Z",
    confirmed_yn: false,
    confirmed_datetime: null,
    submitted_yn: false,
    submitted_datetime: null,
    text_json: [
      {
        section_text: "",
        section_title: "Priorities",
        input_required_yn: false,
        display_title_in_edit_yn: true,
        display_title_in_read_yn: true,
        display_section_in_read_if_empty_yn: false,
      },
      {
        section_text: "",
        section_title: "Report",
        input_required_yn: true,
        display_title_in_edit_yn: true,
        display_title_in_read_yn: true,
        display_section_in_read_if_empty_yn: true,
      },
      {
        section_text: "",
        section_title: "Next steps",
        input_required_yn: false,
        display_title_in_edit_yn: true,
        display_title_in_read_yn: true,
        display_section_in_read_if_empty_yn: false,
      },
    ],
    deleted_yn: false,
    changed_yn: false,
    change_datetime: null,
    loading: false,
  },
  {
    id: "AWE1FIMMOE",
    task: "233M5RE2EE",
    user: "9LBX5JWFS1",
    username: "Briggs Victoria",
    avatar: "",
    activity_type: "JNRI18TQ6S",
    created_datetime: "2021-08-09T08:31:07Z",
    created_automatically_yn: false,
    last_update_date: "2021-08-09T08:37:32.938648Z",
    confirmed_yn: false,
    confirmed_datetime: null,
    submitted_yn: false,
    submitted_datetime: null,
    text_json: [
      {
        section_text: "secondsss",
        section_title: "Priorities",
        input_required_yn: false,
        display_title_in_edit_yn: true,
        display_title_in_read_yn: true,
        display_section_in_read_if_empty_yn: false,
      },
      {
        section_text: "second",
        section_title: "Report",
        input_required_yn: true,
        display_title_in_edit_yn: true,
        display_title_in_read_yn: true,
        display_section_in_read_if_empty_yn: true,
      },
      {
        section_text: "second",
        section_title: "Next steps",
        input_required_yn: false,
        display_title_in_edit_yn: true,
        display_title_in_read_yn: true,
        display_section_in_read_if_empty_yn: false,
      },
    ],
    deleted_yn: false,
    changed_yn: false,
    change_datetime: null,
    loading: false,
  },
  {
    id: "R9A9OINDZX",
    task: "L79UAUOJHA",
    user: "BU5EPP7QOX",
    username: "Kenny Liu",
    avatar: "media_files/profile_pictures/stan_sahkuCh.jpeg",
    activity_type: "JNRI18TQ6S",
    created_datetime: "2021-08-09T02:42:22.511687Z",
    created_automatically_yn: false,
    last_update_date: "2021-08-09T02:42:29.115082Z",
    confirmed_yn: true,
    confirmed_datetime: "2021-08-09T02:42:29.115082Z",
    submitted_yn: true,
    submitted_datetime: "2021-08-09T02:42:29.115082Z",
    text_json: [
      {
        section_text: "1",
        section_title: "Priorities",
        input_required_yn: false,
        display_title_in_edit_yn: true,
        display_title_in_read_yn: true,
        display_section_in_read_if_empty_yn: false,
      },
      {
        section_text: "111",
        section_title: "Report",
        input_required_yn: true,
        display_title_in_edit_yn: true,
        display_title_in_read_yn: true,
        display_section_in_read_if_empty_yn: true,
      },
      {
        section_text: "222",
        section_title: "Next steps",
        input_required_yn: false,
        display_title_in_edit_yn: true,
        display_title_in_read_yn: true,
        display_section_in_read_if_empty_yn: false,
      },
    ],
    deleted_yn: false,
    changed_yn: false,
    change_datetime: null,
    loading: false,
  },
  {
    id: "F6JHM69VE8",
    task: "233M5RE2EE",
    user: "EGUN7PV8KA",
    username: "Admin",
    avatar: "",
    activity_type: "JNRI18TQ6S",
    created_datetime: "2021-08-09T02:40:39.504134Z",
    created_automatically_yn: false,
    last_update_date: "2021-08-09T07:48:22.700880Z",
    confirmed_yn: true,
    confirmed_datetime: "2021-08-09T07:48:22.700880Z",
    submitted_yn: true,
    submitted_datetime: "2021-08-09T07:48:22.700880Z",
    text_json: [
      {
        section_text: "test",
        section_title: "Priorities",
        input_required_yn: false,
        display_title_in_edit_yn: true,
        display_title_in_read_yn: true,
        display_section_in_read_if_empty_yn: false,
      },
      {
        section_text: "test",
        section_title: "Report",
        input_required_yn: true,
        display_title_in_edit_yn: true,
        display_title_in_read_yn: true,
        display_section_in_read_if_empty_yn: true,
      },
      {
        section_text: "test",
        section_title: "Next steps",
        input_required_yn: false,
        display_title_in_edit_yn: true,
        display_title_in_read_yn: true,
        display_section_in_read_if_empty_yn: false,
      },
    ],
    deleted_yn: false,
    changed_yn: false,
    change_datetime: null,
    loading: false,
  },
  {
    id: "A2RUUCFKM8",
    task: "233M5RE2EE",
    user: "9LBX5JWFS1",
    username: "Briggs Victoria",
    avatar: "",
    activity_type: "JNRI18TQ6S",
    created_datetime: "2021-08-09T01:38:33.208355Z",
    created_automatically_yn: false,
    last_update_date: "2021-08-09T07:47:40.560052Z",
    confirmed_yn: true,
    confirmed_datetime: "2021-08-09T07:47:40.560052Z",
    submitted_yn: true,
    submitted_datetime: "2021-08-09T07:47:40.560052Z",
    text_json: [
      {
        section_text: "sample priorities",
        section_title: "Priorities",
        input_required_yn: false,
        display_title_in_edit_yn: true,
        display_title_in_read_yn: true,
        display_section_in_read_if_empty_yn: false,
      },
      {
        section_text: "sample report",
        section_title: "Report",
        input_required_yn: true,
        display_title_in_edit_yn: true,
        display_title_in_read_yn: true,
        display_section_in_read_if_empty_yn: true,
      },
      {
        section_text: "sample next steps",
        section_title: "Next steps",
        input_required_yn: false,
        display_title_in_edit_yn: true,
        display_title_in_read_yn: true,
        display_section_in_read_if_empty_yn: false,
      },
    ],
    deleted_yn: false,
    changed_yn: false,
    change_datetime: null,
    loading: false,
  },
];

const userActivities = activities.filter(
  (activity) => activity.user === userDetails.id
);
const userUnconfirmedActivities = userActivities.filter(
  (activity) => activity.confirmed_yn === false
);
const userConfirmedActivities = userActivities.filter(
  (activity) => activity.confirmed_yn === true
);

const otherUserActivities = activities.filter(
  (activity) => activity.user !== userDetails.id
);
const confirmedOtherUserActivities = otherUserActivities.filter(
  (activity) => activity.confirmed_yn === true
);
const unconfirmedOtherUserActivities = otherUserActivities.filter(
  (activity) => activity.confirmed_yn === false
);

const userUnconfirmedActivityObject = userUnconfirmedActivities[0];
const userConfirmedActivityObject = userConfirmedActivities[0];
const otherUserActivityObject = otherUserActivities[0];
const confirmedOtherUserActivityObject = confirmedOtherUserActivities[0];
const unconfirmedOtherUserActivityObject = unconfirmedOtherUserActivities[0];

const teamUnconfirmedActivities: ActivityArray = [
  {
    id: "NUSBRB1LOJ",
    task: "233M5RE2EE",
    user: "EGUN7PV8KA",
    username: "Admin",
    avatar: "",
    activity_type: "JNRI18TQ6S",
    created_datetime: "2021-08-10T00:19:30.158183Z",
    created_automatically_yn: false,
    last_update_date: "2021-08-10T00:19:30.158183Z",
    confirmed_yn: false,
    confirmed_datetime: null,
    submitted_yn: false,
    submitted_datetime: null,
    text_json: [
      {
        section_text: "",
        section_title: "Priorities",
        input_required_yn: false,
        display_title_in_edit_yn: true,
        display_title_in_read_yn: true,
        display_section_in_read_if_empty_yn: false,
      },
      {
        section_text: "",
        section_title: "Report",
        input_required_yn: true,
        display_title_in_edit_yn: true,
        display_title_in_read_yn: true,
        display_section_in_read_if_empty_yn: true,
      },
      {
        section_text: "",
        section_title: "Next steps",
        input_required_yn: false,
        display_title_in_edit_yn: true,
        display_title_in_read_yn: true,
        display_section_in_read_if_empty_yn: false,
      },
    ],
    deleted_yn: false,
    changed_yn: false,
    change_datetime: null,
    loading: false,
  },
];

const unreadActivities: ActivityArray = [
  {
    id: "F6JHM69VE8",
    task: "233M5RE2EE",
    user: "EGUN7PV8KA",
    username: "Admin",
    avatar: "",
    activity_type: "JNRI18TQ6S",
    created_datetime: "2021-08-09T02:40:39.504134Z",
    created_automatically_yn: false,
    last_update_date: "2021-08-09T07:48:22.700880Z",
    confirmed_yn: true,
    confirmed_datetime: "2021-08-09T07:48:22.700880Z",
    submitted_yn: true,
    submitted_datetime: "2021-08-09T07:48:22.700880Z",
    text_json: [
      {
        section_text: "test",
        section_title: "Priorities",
        input_required_yn: false,
        display_title_in_edit_yn: true,
        display_title_in_read_yn: true,
        display_section_in_read_if_empty_yn: false,
      },
      {
        section_text: "test",
        section_title: "Report",
        input_required_yn: true,
        display_title_in_edit_yn: true,
        display_title_in_read_yn: true,
        display_section_in_read_if_empty_yn: true,
      },
      {
        section_text: "test",
        section_title: "Next steps",
        input_required_yn: false,
        display_title_in_edit_yn: true,
        display_title_in_read_yn: true,
        display_section_in_read_if_empty_yn: false,
      },
    ],
    deleted_yn: false,
    changed_yn: false,
    change_datetime: null,
    loading: false,
  },
];

export {
  teamUnconfirmedActivities,
  userConfirmedActivities,
  userUnconfirmedActivities,
  confirmedOtherUserActivities,
  unconfirmedOtherUserActivities,
  unreadActivities,
  userUnconfirmedActivityObject,
  userConfirmedActivityObject,
  otherUserActivityObject,
  confirmedOtherUserActivityObject,
  unconfirmedOtherUserActivityObject,
  activities as default,
};
