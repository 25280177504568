import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown, Menu } from "antd";
import moment from "moment";

import {
  AddIcon,
  CaretDownIcon,
} from "../../CustomIcons/CustomIcons.component";

import { startCreateActivity } from "../../../actions/activities.action";
import { selectActivityTypesState } from "../../../reducers/activity-types.reducer";
import { selectTaskDetailsState } from "../../../reducers/task-details.reducer";
import { selectUserDetailsState } from "../../../reducers/user-details.reducer";
import { ActivityTabContentContext } from "../ActivityTabContent/ActivityTabContent";

/**
 * Default display
 * Displays dropdown menu toggle to create
 *
 * Uncollapsed display
 * Displays dynamic list of activity types as dropdown menu items
 *
 * Functionality
 * Clicking an activity type dropdown menu item creates a new activity draft of that type
 */
const CreateActivityDraftDropdown: React.FC<CreateActivityDraftDropdownProps> = (
  props
) => {
  const dispatch = useDispatch();

  const { setUserDrafts } = React.useContext(ActivityTabContentContext);

  const activityTypes = useSelector(selectActivityTypesState);
  const userDetails = useSelector(selectUserDetailsState).data;
  const taskDetails = useSelector(selectTaskDetailsState);

  const handleClickDropdownMenuItem = ({ key }: { key: string }) => {
    setUserDrafts((c) => ({
      ...c,
      creating: true,
    }));

    const selectedActivityTypeObject = activityTypes.data.filter(
      (activityType) => activityType.title === key
    )[0];

    const data = {
      activity_type: selectedActivityTypeObject.id,
      avatar: userDetails.avatar,
      confirmed_datetime: null,
      confirmed_yn: false,
      created_automatically_yn: false,
      created_datetime: moment().toISOString(),
      deleted_yn: false,
      last_update_date: moment().toISOString(),
      submitted_datetime: null,
      submitted_yn: false,
      task: taskDetails.id,
      text_json: selectedActivityTypeObject.text_template_json,
      user: userDetails.id,
      username: userDetails.display_name,
    };
    dispatch(startCreateActivity(data));
  };

  const createActivityDraftDropdownMenu = (
    <Menu
      className="CreateActivityDraftDropdown__Menu"
      onClick={handleClickDropdownMenuItem}
    >
      {activityTypes.data.map((activityType, index: number) => {
        const { title, id } = activityType;
        return (
          <Menu.Item key={title}>
            <span>{title}</span>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  return (
    <Dropdown overlay={createActivityDraftDropdownMenu} trigger={["click"]}>
      <div className="CreateActivityDraftDropdown">
        <AddIcon className="CreateActivityDraftDropdown__AddIcon" />
        <span className="CreateActivityDraftDropdown__Text">Create</span>
        <CaretDownIcon />
      </div>
    </Dropdown>
  );
};

export { CreateActivityDraftDropdown as default };
