import { RootState } from "../store/configureStore";

import { basicReducerDefaultState } from "../constants/constants";

export interface TasksInitAction {
  type: "@@INIT";
}
export interface TasksRequestAction {
  type: "REQUEST_FETCH_TASKS" | "REQUEST_CREATE_TASK";
}
export interface FetchTasksSuccessAction {
  type: "REQUEST_FETCH_TASKS_SUCCESS";
  tasks: TaskArray;
}
export interface TasksRequestFailedAction {
  type:
    | "REQUEST_FETCH_TASKS_FAILED"
    | "REQUEST_CREATE_TASK_FAILED"
    | "REQUEST_PARTIAL_UPDATE_TASK_FAILED";
  error: string;
}
export interface TasksRequestSuccessAction {
  type: "REQUEST_CREATE_TASK_SUCCESS" | "REQUEST_PARTIAL_UPDATE_TASK_SUCCESS";
  task: TaskObject;
}
export interface PartialUpdateTaskAction {
  type: "REQUEST_PARTIAL_UPDATE_TASK";
  taskId: TaskObject["id"];
  updatedKeys: (keyof TaskObject)[];
}
export type TaskActions =
  | TasksInitAction
  | TasksRequestAction
  | FetchTasksSuccessAction
  | TasksRequestFailedAction
  | TasksRequestSuccessAction
  | PartialUpdateTaskAction;
export interface TasksState extends BasicReducerState {
  data: TaskArray;
  updatedKeys: (keyof TaskObject)[];
}
export const selectTasksState = (rootState: RootState) => rootState.tasks;
export const tasksReducerDefaultState: TasksState = {
  data: [],
  updatedKeys: [],
  ...basicReducerDefaultState,
};
export const tasksReducer = (
  state = tasksReducerDefaultState,
  action: TaskActions
) => {
  switch (action.type) {
    case "REQUEST_FETCH_TASKS":
      return {
        ...state,
        error: null,
        loading: true,
      };
    case "REQUEST_FETCH_TASKS_SUCCESS":
      return {
        ...state,
        data: action.tasks,
        error: null,
        loading: false,
      };
    case "REQUEST_FETCH_TASKS_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case "REQUEST_CREATE_TASK":
      return {
        ...state,
        creating: true,
      };
    case "REQUEST_CREATE_TASK_SUCCESS":
      return {
        ...state,
        data: [...state.data, action.task],
        creating: false,
      };
    case "REQUEST_CREATE_TASK_FAILED":
      return {
        ...state,
        creating: false,
        error: action.error,
      };
    case "REQUEST_PARTIAL_UPDATE_TASK":
      return {
        ...state,
        error: null,
        data: state.data.map((item) => {
          if (item.id === action.taskId) {
            return {
              ...item,
              loading: true,
            };
          }
          return item;
        }),
        updatedKeys: action.updatedKeys,
      };
    case "REQUEST_PARTIAL_UPDATE_TASK_SUCCESS":
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.id === action.task.id) {
            return {
              ...action.task,
              loading: false,
            };
          }
          return item;
        }),
        updatedKeys: [],
      };
    case "REQUEST_PARTIAL_UPDATE_TASK_FAILED":
      return {
        ...state,
        error: action.error,
        updatedKeys: [],
      };
    default:
      return state;
  }
};

/**
 * USER TASKS
 */
export interface UserTasksRequestAction {
  type: "REQUEST_FETCH_USER_TASKS" | "REQUEST_CREATE_USER_TASK";
}
export interface FetchUserTasksSuccessAction {
  type: "REQUEST_FETCH_USER_TASKS_SUCCESS";
  userTasks: JoinTaskToUserArray;
}
export interface UserTasksRequestFailedAction {
  type:
    | "REQUEST_FETCH_USER_TASKS_FAILED"
    | "REQUEST_CREATE_USER_TASK_FAILED"
    | "REQUEST_PARTIAL_UPDATE_TASK_FAILED";
  error: string;
}
export interface UserTasksRequestSuccessAction {
  type:
    | "REQUEST_CREATE_USER_TASK_SUCCESS"
    | "REQUEST_PARTIAL_UPDATE_USER_TASK"
    | "REQUEST_PARTIAL_UPDATE_USER_TASK_SUCCESS";
  userTask: JoinTaskToUserObject;
}
export type UserTaskActions =
  | UserTasksRequestAction
  | FetchUserTasksSuccessAction
  | UserTasksRequestFailedAction
  | UserTasksRequestSuccessAction;
export interface UserTasksState extends BasicReducerState {
  data: JoinTaskToUserArray;
}
export const selectUserTasksState = (rootState: RootState) =>
  rootState.userTasks;
export const userTasksReducerDefaultState: UserTasksState = {
  data: [],
  ...basicReducerDefaultState,
};
export const userTasksReducer = (
  state = userTasksReducerDefaultState,
  action: UserTaskActions
) => {
  switch (action.type) {
    case "REQUEST_FETCH_USER_TASKS":
      return {
        ...state,
        error: null,
        loading: true,
      };
    case "REQUEST_FETCH_USER_TASKS_SUCCESS":
      return {
        ...state,
        data: action.userTasks,
        error: null,
        loading: false,
      };
    case "REQUEST_FETCH_USER_TASKS_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case "REQUEST_CREATE_USER_TASK":
      return {
        ...state,
        creating: true,
        error: null,
      };
    case "REQUEST_CREATE_USER_TASK_SUCCESS":
      return {
        ...state,
        creating: false,
        data: [...state.data, action.userTask],
        error: null,
      };
    case "REQUEST_CREATE_USER_TASK_FAILED":
      return {
        ...state,
        creating: false,
        error: action.error,
      };
    case "REQUEST_PARTIAL_UPDATE_USER_TASK":
      return {
        ...state,
        error: null,
        data: state.data.map((item) => {
          if (item.id === action.userTask.id) {
            const updatedUserTask = {
              ...item,
              today_yn: {
                loading: Object.keys(action.userTask).includes("today_yn")
                  ? true
                  : false,
                value: Object.keys(action.userTask).includes("today_yn")
                  ? action.userTask.today_yn
                  : item.today_yn.value,
              },
              next_shift_yn: {
                loading: Object.keys(action.userTask).includes("next_shift_yn")
                  ? true
                  : false,
                value: Object.keys(action.userTask).includes("next_shift_yn")
                  ? action.userTask.next_shift_yn
                  : item.next_shift_yn.value,
              },
              starred_yn: {
                loading: Object.keys(action.userTask).includes("starred_yn")
                  ? true
                  : false,
                value: Object.keys(action.userTask).includes("starred_yn")
                  ? action.userTask.starred_yn
                  : item.starred_yn.value,
              },
              priority: {
                loading: Object.keys(action.userTask).includes("priority")
                  ? true
                  : false,
                value: Object.keys(action.userTask).includes("priority")
                  ? action.userTask.priority
                  : item.priority.value,
              },
              loading: true,
            } as JoinTaskToUserObject;
            return updatedUserTask;
          }
          return item;
        }),
      };
    case "REQUEST_PARTIAL_UPDATE_USER_TASK_SUCCESS":
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.id === action.userTask.id) {
            return {
              ...action.userTask,
              loading: false,
            };
          }
          return item;
        }),
      };
    case "REQUEST_PARTIAL_UPDATE_TASK_FAILED":
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

/**
 * SCHEDULED TASKS
 */
export interface ScheduledTasksRequestAction {
  type: "REQUEST_FETCH_SCHEDULED_TASKS";
}
export interface FetchScheduledTasksSuccessAction {
  type: "REQUEST_FETCH_SCHEDULED_TASKS_SUCCESS";
  scheduledTasks: ScheduleTaskArray;
}
export interface ScheduledTasksFailedAction {
  type: "REQUEST_FETCH_SCHEDULED_TASKS_FAILED";
  error: string;
}
export interface ScheduledTasksState extends BasicReducerState {
  data: ScheduleTaskArray;
  updatedKeys: (keyof TaskObject)[];
}
export const selectScheduledTasksState = (rootState: RootState) =>
  rootState.scheduledTasks;
export const scheduledTasksReducerDefaultState: ScheduledTasksState = {
  data: [],
  updatedKeys: [],
  ...basicReducerDefaultState,
};
export const scheduledTasksReducer = (
  state = scheduledTasksReducerDefaultState,
  action: {
    type: string;
    scheduledTasks: ScheduleTaskArray;
    scheduledTask: ScheduleTaskObject;
    scheduledTaskId: ScheduleTaskObject["id"];
    error: string;
  }
) => {
  switch (action.type) {
    case "REQUEST_FETCH_SCHEDULED_TASKS":
      return {
        ...state,
        error: null,
        loading: true,
      };
    case "REQUEST_FETCH_SCHEDULED_TASKS_SUCCESS":
      return {
        ...state,
        data: action.scheduledTasks,
        error: null,
        loading: false,
      };
    case "REQUEST_FETCH_SCHEDULED_TASKS_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
