import React from "react";
import cn from "classnames";

import { CaretDownIcon } from "../../CustomIcons/CustomIcons.component";
import DeleteSectionButton from "../DeleteSectionButton/DeleteSectionButton";
import UnarchiveSectionTasksButton from "../../Tasks/UnarchiveSectionTasksButton/UnarchiveSectionTasksButton";

export interface SectionHeaderProps {
  archiveMode?: boolean;
  handleSectionToggle: Function;
  isOpen: boolean;
  panelType: PanelType;
  section: SectionObject;
}
const SectionHeader: React.FC<SectionHeaderProps> = ({
  archiveMode = false,
  handleSectionToggle,
  isOpen,
  panelType,
  section,
}) => {
  const { id: sectionId, title: sectionTitle } = section || {};
  return (
    <div className="SectionHeader">
      <div
        className={cn("SectionHeader__Title", {
          "SectionHeader__Title--TaskPanel": panelType === "task",
        })}
      >
        <span className="SectionHeader__Title__Text">{sectionTitle}</span>
        <CaretDownIcon
          className={cn("SectionHeader__Title__Icon", {
            "SectionHeader__Title__Icon--Open": isOpen,
          })}
          onClick={() => {
            handleSectionToggle(sectionId);
          }}
        />
      </div>

      {archiveMode && (
        <div className="SectionHeader__ArchiveActions">
          <UnarchiveSectionTasksButton />
          <DeleteSectionButton section={section} />
        </div>
      )}
    </div>
  );
};

export default SectionHeader;
