import React from "react";
import { Menu, MenuProps } from "antd";

import { ListSelectionProps } from "../ListSelection";
export interface ListSelectionDropdownMenuProps {
  handleSelectList: ListSelectionProps["handleSelectList"];
  listArray: ListSelectionProps["listArray"];
  selectedProject?: ListSelectionProps["selectedProject"];
}
const ListSelectionDropdownMenu: React.FC<ListSelectionDropdownMenuProps> = ({
  handleSelectList,
  listArray,
  selectedProject,
}) => {
  const hasSection = listArray.length > 0;

  const handleClick: MenuProps["onClick"] = ({ key, domEvent }) => {
    domEvent.stopPropagation();
    handleSelectList(listArray[Number(key)]);
  };

  return (
    <Menu
      className="ListSelectionDropdownMenu"
      disabled={!selectedProject}
      onClick={handleClick}
    >
      {!selectedProject && (
        <Menu.Item className="ListSelectionDropdownMenu__Empty">
          <span>No projected selected</span>
        </Menu.Item>
      )}
      {selectedProject &&
        hasSection &&
        listArray.map((listObject, index: number) => {
          return (
            <Menu.Item className="ListSelectionDropdownMenu__Item" key={index}>
              <span>{listObject.title}</span>
            </Menu.Item>
          );
        })}
      {selectedProject && !hasSection && (
        <Menu.Item className="ListSelectionDropdownMenu__Empty">
          <span>No list available</span>
        </Menu.Item>
      )}
    </Menu>
  );
};

export default ListSelectionDropdownMenu;
