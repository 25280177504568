import React from "react";
import { useSelector } from "react-redux";
import { sumBy } from "lodash";

import { selectTaskTimeState } from "../reducers/task-time.reducer";

export default function useWorkDayTaskObjectTotalTime(
  workDayObjectId: WorkDayObject["id"],
  taskObjectId: TaskObject["id"]
) {
  const taskTimeArray = useSelector(selectTaskTimeState).data;

  const [
    workDayTaskObjectTotalTime,
    setWorkDayTaskObjectTotalTime,
  ] = React.useState(0);

  React.useEffect(() => {
    if (taskTimeArray.length > 0) {
      setWorkDayTaskObjectTotalTime(
        sumBy(
          taskTimeArray.filter(
            ({ task, work_day }) =>
              task === taskObjectId && work_day === workDayObjectId
          ),
          "seconds_worked"
        )
      );
    }
  }, [taskObjectId, taskTimeArray, workDayObjectId]);

  return workDayTaskObjectTotalTime;
}
