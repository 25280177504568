import React from "react";
import { Feature } from "flagged";

import MoreActions from "../../Tasks/MoreActions/MoreActions";
import TaskTimeToggler from "../../TaskTimeTracking/TaskTimeToggler/TaskTimeToggler";

export interface TaskCardActionsProps {
  archived?: boolean;
  task: TaskJoinedToUserAndScheduledObject;
  project: ProjectObject;
  tracking: TaskCardItemProps["tracking"];
  onCopy?: (task: TaskObject) => void;
  onPin?: (pinned: boolean) => void;
  panelType: PanelType;
}
const TaskCardActions: React.FC<TaskCardActionsProps> = ({
  archived,
  onCopy,
  onPin,
  panelType,
  project,
  task,
}) => {
  return (
    <Feature name="under_development">
      <div className="TaskCardActions">
        <MoreActions
          archived={archived}
          onCopy={onCopy}
          onPin={onPin}
          panelType={panelType}
          projectId={project.id}
          task={task}
        />
        <TaskTimeToggler task={task} />
      </div>
    </Feature>
  );
};

export default TaskCardActions;
