import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tooltip } from "antd";
import { sumBy } from "lodash";

import {
  CloseIcon,
  LoadingIcon,
} from "../../CustomIcons/CustomIcons.component";
import DeleteConfirmationModal from "../../General/DeleteConfirmationModal/DeleteConfirmationModal";
import { CheckoutPanelContext } from "../../Checkout/CheckoutPanel/CheckoutPanel";

import { selectTaskTimeState } from "../../../reducers/task-time.reducer";
import { selectUserDetailsState } from "../../../reducers/user-details.reducer";
import { startPartialUpdateJoinTaskToUserRecord } from "../../../actions/tasks.action";

export interface RemoveTaskFromWorkDayProps {
  task: TaskJoinedToUserAndScheduledObject;
}
const RemoveTaskFromWorkDay: React.FC<RemoveTaskFromWorkDayProps> = ({
  task,
}) => {
  const dispatch = useDispatch();

  const { checkoutWorkDay } = React.useContext(CheckoutPanelContext);

  const taskTime = useSelector(selectTaskTimeState).data;
  const userDetails = useSelector(selectUserDetailsState).data;

  const [
    checkoutWorkDayTotalTaskTime,
    setCheckoutWorkDayTotalTaskTime,
  ] = React.useState(0);
  const [
    displayConfirmationModal,
    setDisplayConfirmationModal,
  ] = React.useState(false);
  const [
    confirmationModalMessage,
    setConfirmationModalMessage,
  ] = React.useState("");

  const { id: userId } = userDetails;
  const { id: checkoutWorkDayId = "" } = checkoutWorkDay || {};
  const {
    id: taskId,
    join_task_to_user_id: userTaskId,
    loading: taskLoading,
  } = task;

  const removeTaskFromWorkDay = React.useCallback(async () => {
    if (userId && checkoutWorkDayId && taskId && userTaskId && !taskLoading) {
      // add title if removing today_yn to determine if system feedback message will be displayed
      const data = {
        id: userTaskId,
        today_yn: false,
        taskId,
      };
      dispatch(startPartialUpdateJoinTaskToUserRecord(data));
    }
  }, [dispatch, checkoutWorkDayId, taskId, taskLoading, userId, userTaskId]);

  React.useEffect(() => {
    if (taskId && checkoutWorkDayId && taskTime.length > 0) {
      setCheckoutWorkDayTotalTaskTime(
        sumBy(
          taskTime.filter(
            ({ task, work_day }) =>
              task === taskId && work_day === checkoutWorkDayId
          ),
          "seconds_worked"
        )
      );
    }
  }, [taskId, taskTime, checkoutWorkDayId]);

  React.useEffect(() => {
    if (checkoutWorkDayTotalTaskTime > 0) {
      setConfirmationModalMessage(
        "Task will be removed and hours will be added to unallocated."
      );
    } else {
      setConfirmationModalMessage("");
    }
  }, [checkoutWorkDayTotalTaskTime]);

  const handleClick = async () => {
    if (!confirmationModalMessage) {
      await removeTaskFromWorkDay();
      setDisplayConfirmationModal(false);
    } else {
      setDisplayConfirmationModal(true);
    }
  };

  return (
    <>
      <DeleteConfirmationModal
        closeCallback={() => setDisplayConfirmationModal(false)}
        confirmCallback={() => removeTaskFromWorkDay()}
        displayModal={displayConfirmationModal}
        objectName="task"
        setDisplayModal={setDisplayConfirmationModal}
        text={confirmationModalMessage}
      />
      <Tooltip title="Remove task">
        <div className="RemoveTaskFromWorkDay" onClick={handleClick}>
          {taskLoading ? <LoadingIcon /> : <CloseIcon />}
        </div>
      </Tooltip>
    </>
  );
};

export default RemoveTaskFromWorkDay;
