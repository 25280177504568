import React from "react";
import { Empty } from "antd";
import ContributorItem from "../ContributorItem/ContributorItem";

const ContributorList: React.FC<ContributorListProps> = ({
  task,
  title,
  contributors,
  type,
  ownerId,
}) => {
  const list = contributors.map((c) => (
    <ContributorItem
      task={task}
      type={type}
      key={c.id}
      contributor={c}
      ownerId={ownerId}
    />
  ));
  return (
    <div className="ContributorList">
      <div className="ContributorList__Title">
        {title} ({contributors.length})
      </div>
      {contributors.length > 0 ? (
        list
      ) : (
        <Empty
          className="ContributorList__Empty"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      )}
    </div>
  );
};

export default ContributorList;
