import React from "react";
import moment, { Moment } from "moment";

import ShiftTimePicker from "./ShiftTimePicker";
import Button from "../../Button/Button";

const getPrimaryColor = (type: TimelineType) => {
  if (type === "work") {
    return "#40BF00";
  } else if (type === "break") {
    return "#0079F2";
  } else if (type === "manual") {
    return "#FFAA00";
  }
  return "#40BF00";
};

/*
 * ShiftPopover is a component that user can add or edit shift time using it.
 * It contains the <ShiftTimePicker /> which handles the time creation/updation under the hood,
 * and the Button to submit the request.
 */
const ShiftPopover: React.FC<ShiftPopoverProps> = ({
  defaultStart,
  defaultEnd,
  min,
  max,
  type,
  startErrorMessage,
  endErrorMessage,
  onSave,
}) => {
  const color = getPrimaryColor(type);
  const [start, setStart] = React.useState<Moment>(defaultStart);
  const [end, setEnd] = React.useState<Moment>(defaultEnd || moment());
  const [hasError, setHasError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setStart(defaultStart);
  }, [defaultStart]);

  React.useEffect(() => {
    setEnd(defaultEnd || moment());
  }, [defaultEnd]);

  const handleAdd = async () => {
    setLoading(true);
    try {
      await onSave(start, end);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <div className="ShiftPopover">
      <ShiftTimePicker
        size="small"
        start={start}
        end={end}
        min={min}
        max={max === null ? moment() : max}
        startErrorMessage={startErrorMessage}
        endErrorMessage={endErrorMessage}
        onStartChange={(value) => setStart(value)}
        onEndChange={(value) => setEnd(value)}
        onError={(error) => setHasError(error)}
      />
      <Button
        style={{ backgroundColor: color }}
        className="ShiftPopover__Btn"
        type="primary"
        size="small"
        loading={loading}
        disabled={hasError}
        onClick={handleAdd}
      >
        Add {type}
      </Button>
    </div>
  );
};

export default ShiftPopover;
