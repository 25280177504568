import { RootState } from "../store/configureStore";

export interface ListState extends BaseReducerState {
  data: ListArray;
}
export const selectListsState = (rootState: RootState) => rootState.lists;
export const listsReducerDefaultState: ListState = {
  data: [],
  loading: false,
  error: "",
};

export interface ListsRequestAction {
  type: "REQUEST_FETCH_LISTS" | "REQUEST_CREATE_LIST";
}
export interface FetchListsSuccessAction {
  type: "REQUEST_FETCH_LISTS_SUCCESS";
  lists: ListArray;
}
export interface ListsRequestsFailedAction {
  type:
    | "REQUEST_FETCH_LISTS_FAILED"
    | "REQUEST_CREATE_LIST_FAILED"
    | "REQUEST_UPDATE_LIST_FAILED";
  error: string;
}
export interface ListsRequestSuccessAction {
  type:
    | "REQUEST_CREATE_LIST_SUCCESS"
    | "REQUEST_UPDATE_LIST"
    | "REQUEST_UPDATE_LIST_SUCCESS"
    | "UPDATE_LIST"
    | "TOGGLE_LIST_SUCCESS"
    | "ADD_LIST";
  list: ListObject;
}
export interface ListRemoveAction {
  type: "REMOVE_LIST";
  listId: ListObject["id"];
}
export type ListsActions =
  | ListsRequestAction
  | FetchListsSuccessAction
  | ListsRequestsFailedAction
  | ListsRequestSuccessAction
  | ListRemoveAction;
const listsReducer = (
  state = listsReducerDefaultState,
  action: ListsActions
) => {
  switch (action.type) {
    case "REQUEST_FETCH_LISTS":
    case "REQUEST_CREATE_LIST":
    case "REQUEST_UPDATE_LIST":
      return {
        ...state,
        error: "",
        loading: true,
      };
    case "REQUEST_CREATE_LIST_SUCCESS":
    case "ADD_LIST":
      return {
        data: [...state.data, action.list].sort((a, b) => {
          return a.title.localeCompare(b.title);
        }),
        error: null,
        loading: false,
      };
    case "REQUEST_FETCH_LISTS_SUCCESS":
      return {
        data: action.lists,
        error: "",
        loading: false,
      };
    case "REQUEST_UPDATE_LIST_SUCCESS":
    case "UPDATE_LIST":
      return {
        data: state.data.map((item) => {
          if (item.id === action.list.id) {
            return action.list;
          }
          return item;
        }),
        error: "",
        loading: false,
      };
    case "REQUEST_FETCH_LISTS_FAILED":
    case "REQUEST_CREATE_LIST_FAILED":
    case "REQUEST_UPDATE_LIST_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case "TOGGLE_LIST_SUCCESS":
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.id === action.list.id) {
            return {
              ...item,
              show_list_in_sidebar_yn: !item.show_list_in_sidebar_yn,
            };
          }
          return item;
        }),
      };
    case "REMOVE_LIST":
      return {
        ...state,
        data: state.data.filter(({ id: listId }) => {
          listId === action.listId;
        }),
        error: null,
        loading: false,
      };
    default:
      return state;
  }
};

export { listsReducer as default };
