import React from "react";
import { useSelector } from "react-redux";

import { selectUserDetailsState } from "../reducers/user-details.reducer";
import { getUserTaskGroupsCountService } from "../services/tasks.service";

import ws from "../sockets/websockets";

export default function useAsyncMyDeskTasksCount() {
  const {
    data: { id: userId },
  } = useSelector(selectUserDetailsState);

  const [todayTasksCount, setTodayTasksCount] = React.useState<number>();
  const [
    nextWorkDayTasksCount,
    setNextWorkDayTasksCount,
  ] = React.useState<number>();
  const [starredTasksCount, setStarredTasksCount] = React.useState<number>();
  const [loading, setLoading] = React.useState(false);

  const fetchMyDeskTasksCount = React.useCallback(async () => {
    setLoading(true);
    const { today_count, next_workday_count, starred_count } =
      (await getUserTaskGroupsCountService()) || {};
    setLoading(false);
    setTodayTasksCount(today_count);
    setNextWorkDayTasksCount(next_workday_count);
    setStarredTasksCount(starred_count);
  }, []);

  React.useEffect(() => {
    fetchMyDeskTasksCount();
  }, [fetchMyDeskTasksCount]);

  React.useEffect(() => {
    const wsOnMessage = async (event: MessageEvent) => {
      try {
        const messageEventData = JSON.parse(event.data);
        const { event: eventType } = messageEventData;
        if (eventType === "new_join_task_to_user") {
          const {
            next_shift_yn,
            starred_yn,
            today_yn,
            priority,
            ...rest
          } = messageEventData.meta.data;
          const isCurrentUser = messageEventData.meta.user_id === userId;
          if (isCurrentUser) fetchMyDeskTasksCount();
        }
      } catch (e) {}
    };

    ws.addEventListener("message", wsOnMessage);

    return () => {
      ws.removeEventListener("message", wsOnMessage);
    };
  }, [userId, fetchMyDeskTasksCount]);

  return [
    { todayTasksCount, nextWorkDayTasksCount, starredTasksCount },
    loading,
  ] as [
    (
      | {
          todayTasksCount: number;
          nextWorkDayTasksCount: number;
          starredTasksCount: number;
        }
      | undefined
    ),
    boolean
  ];
}
