import React from "react";
import cn from "classnames";
import { Tag } from "antd";

import { ShowArchiveIcon } from "../../CustomIcons/CustomIcons.component";
import Priority from "../../Tasks/Priority/Priority";
import ManageTaskTime from "../../WorkTime/ManageTaskTime/ManageTaskTime";
import TaskAssignees from "../../Tasks/TaskAssignees/TaskAssignees";
import TaskCardActions from "../../Tasks/TaskCardActions/TaskCardActions";
import TaskCardTags from "../../Tasks/TaskCardTags/TaskCardTags";
import TaskDestinationDisplay from "../../Tasks/TaskDestinationDisplay/TaskDestinationDisplay";

/**
 * TaskCardItem displays the details of a task record
 *
 * Default Display
 * Displays the priority of a task, TaskCardTags, and TaskAssignees
 *
 * Hover Display
 * Displays TaskCardActions when hovered
 *
 * Conditional Display
 * Displays TaskDestinationDisplay if prop panelType is "task"
 */
const TaskCardItem: React.FC<TaskCardItemProps> = ({
  archived,
  task,
  checklist,
  tracking,
  project,
  panelType,
  onTaskSelect,
  onCopy,
}) => {
  const [hovered, setHovered] = React.useState(false);

  // if it is needed to pin the actions area even if mouse leave
  // one case is when user clicks on popover like label picker.
  const [pinned, setPinned] = React.useState(false);

  const {
    id: taskId,
    title: taskTitle,
    join_task_to_user_id,
    priority,
    today_yn,
  } = task;

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    if (!pinned) {
      setHovered(false);
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (onTaskSelect) {
      onTaskSelect(task);
    }
  };

  const disablePropagation = (e: any) => {
    e.stopPropagation();
  };

  return (
    <div
      className={cn("TaskCardItem", {
        "TaskCardItem--TaskPanel": panelType === "task",
        "TaskCardItem--ProjectPanel": panelType === "project",
      })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <div className="TaskCardItem__Row">
        <Priority task={task} />
        <div className="TaskCardItem__Row__Title">{taskTitle}</div>
        <div className="TaskCardItem__Row__Actions">
          {hovered && (
            <TaskCardActions
              archived={archived}
              project={project}
              task={task}
              tracking={tracking}
              onCopy={onCopy}
              onPin={(value) => setPinned(value)}
              panelType={panelType}
            />
          )}
          <ManageTaskTime hovered={hovered} taskId={taskId} />
        </div>
      </div>

      {panelType === "task" && (
        <div className="TaskCardItem__Row">
          <TaskDestinationDisplay task={task} />
        </div>
      )}

      <div className="TaskCardItem__Row">
        <TaskCardTags checklist={checklist} task={task} />
      </div>

      {archived && (
        <div className="TaskCardItem__Row">
          <Tag
            className="ArchivedTag"
            icon={<ShowArchiveIcon />}
            color="#FFF2D9"
          >
            Archived
          </Tag>
        </div>
      )}

      <div onClick={disablePropagation}>
        <TaskAssignees
          task={task}
          isMouseEnterCard={hovered}
          onPin={(value) => setPinned(value)}
        />
      </div>
    </div>
  );
};

export { TaskCardItem as default };
