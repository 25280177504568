import React from "react";
import { useSelector } from "react-redux";
import { Input, Radio, RadioChangeEvent } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";

import useDebounce from "../../../hooks/useDebounce.hook";
import useAsyncSearchProjectByTitle from "../../../hooks/useAsyncSearchProjectByTitle.hook";

import Button from "../../Button/Button";
import { AddIcon } from "../../CustomIcons/CustomIcons.component";
import ProjectModal from "../../ProjectModal/ProjectModal.component";
import ProjectTable from "../ProjectTable/ProjectTable";

import { selectProjectsState } from "../../../reducers/projects.reducer";

export const filters = ["active", "archived"];

const { Group: RadioGroup, Button: RadioButton } = Radio;

const WorkspaceProjects = () => {
  const { data: projects, loading: projectsLoading } = useSelector(
    selectProjectsState
  );

  const [filteredProjects, setFilteredProjects] = React.useState<ProjectArray>(
    []
  );
  const [filter, setFilter] = React.useState(filters[0]);
  const [searchText, setSearchText] = React.useState("");
  const [showProjectModal, setShowProjectModal] = React.useState(false);

  const debounceSearchText = useDebounce(searchText, 2000);
  const [
    searchedProjects,
    searchedProjectsLoadingState,
  ] = useAsyncSearchProjectByTitle(debounceSearchText);
  const isLoading = projectsLoading || searchedProjectsLoadingState === "true";

  const handleFilterChange = (e: RadioChangeEvent) => {
    setFilter(e.target.value);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const handleProjectSearch = React.useCallback(() => {
    if (!!searchText && searchedProjectsLoadingState === "success") {
      setFilteredProjects(
        searchedProjects.filter(({ archived_yn }) =>
          filter === filters[0] ? !archived_yn : archived_yn
        )
      );
    } else if (!searchText) {
      setFilteredProjects(
        projects.filter(({ archived_yn }) =>
          filter === filters[0] ? !archived_yn : archived_yn
        )
      );
    }
  }, [
    filter,
    projects,
    searchText,
    searchedProjects,
    searchedProjectsLoadingState,
  ]);

  const handleProjectModalOk = () => {
    setShowProjectModal(false);
  };

  React.useEffect(() => {
    handleProjectSearch();
  }, [handleProjectSearch]);

  return (
    <div className="WorkspaceProjects">
      <ProjectModal
        open={showProjectModal}
        onOk={handleProjectModalOk}
        onClose={handleProjectModalOk}
      />
      <div className="WorkspaceProjects__Header">
        <div className="WorkspaceProjects__Header__Title">Projects</div>
        <div className="WorkspaceProjects__Header__Actions">
          <RadioGroup
            className="WorkspaceProjects__Header__Actions__Filter"
            onChange={handleFilterChange}
            defaultValue={filters[0]}
          >
            <RadioButton value={filters[0]}>Active</RadioButton>
            <RadioButton value={filters[1]}>Archived</RadioButton>
          </RadioGroup>
          <Input
            className="WorkspaceProjects__Header__Actions__Search"
            value={searchText}
            size={"default" as SizeType}
            placeholder="Search"
            onChange={handleSearchChange}
          />
          <Button
            className="WorkspaceProjects__Header__Actions__CreateProjectBtn"
            onClick={() => setShowProjectModal(true)}
          >
            <AddIcon />
            <span>Create new project</span>
          </Button>
        </div>
      </div>
      <div className="WorkspaceProjects__Content">
        <span>
          Project page includes all projects that were created in your
          workspace. Workspace admins can manage existing and create new
          projects.
        </span>
        <ProjectTable loading={isLoading} projects={filteredProjects} />
      </div>
    </div>
  );
};

export default WorkspaceProjects;
