import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Popover } from "antd";
import { isEmpty } from "lodash";

import CustomButton from "../../CustomButton/CustomButton";
import {
  LoadingIcon,
  PauseTimeTrackerIcon,
  RightArrowIcon,
  StopTimeTrackerIcon,
  TimeTrackerIcon,
} from "../../CustomIcons/CustomIcons.component";
import ErrorModal from "../../General/ErrorModal/ErrorModal";

import { startCreateStatus } from "../../../actions/status.action";
import { workStatuses } from "../../../constants/constants";
import { selectStatusState } from "../../../reducers/status.reducer";
import {
  selectTrackingTaskState,
  selectTrackingStatusState,
  selectLastTrackedTaskState,
} from "../../../reducers/task-time-tracking.reducer";
import { secondsToMs } from "../../../utils/time-conversion.util";
import {
  pauseTrackingTask,
  removeLastTrackedTask,
  resumeTrackingTask,
  stopTrackingTask,
} from "../../../utils/task-time-tracking.util";
import { getStatusFromListStatusTypesByIdentifier } from "../../../utils/work-status.util";

/**
 * Default Display
 * Displays tracking status based on global state status
 *
 * Hovered Display
 * Displays task being tracked on hover (currently static)
 *
 * Functionality
 * Sets local variable tracking to undefined is global state status is an empty object
 * Sets local variable tracking to true if global state status identifier is 'working'
 * Sets local variable tracking to false if global state status identifier is not 'working'
 * Displays blank fragment if local variable tracking is undefined
 * Displays 'Tracking' if local variable tracking is true
 * Displays 'Paused' if local variable tracking is true
 */
const TrackingTag = () => {
  let interval = React.useRef<number>(0);
  const dispatch = useDispatch();

  const trackingStatus = useSelector(selectTrackingStatusState);
  const lastTrackedTask = useSelector(selectLastTrackedTaskState);
  const currentStatus = useSelector(selectStatusState);

  const [trackedSeconds, setTrackedSeconds] = React.useState(0);
  const [loadingState, setLoadingState] = React.useState<LoadingState>("false");
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const [showPopover, setShowPopover] = React.useState(false);

  const didMount = React.useRef(false);

  const { title: lastTrackedTaskTitle } = lastTrackedTask || {};
  const { id: currentStatusId } = currentStatus.data || {};

  React.useEffect(() => {
    interval.current = window.setInterval(() => {
      setTrackedSeconds((c) => c + 1);
    }, secondsToMs(1));

    return () => {
      window.clearInterval(interval.current);
    };
  }, [interval.current]);

  const setTracking = async (type: TrackingType) => {
    try {
      setLoadingState("true");
      if (type === "resume") {
        await resumeTrackingTask();
      } else if (type === "stop") {
        await stopTrackingTask();
      } else if (type === "pause") {
        await pauseTrackingTask();
      }
      setTimeout(() => {
        setLoadingState("success");
      }, 1000);
    } catch (e) {
      setLoadingState("error");
    }
  };

  const handleClickStopTracking = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    await setTracking("stop");
  };

  const handleClickResumeTracking = async (
    e: React.MouseEvent<HTMLElement>
  ) => {
    e.stopPropagation();
    if (currentStatusId === workStatuses.taking_break) {
      setShowErrorModal(true);
    } else {
      await setTracking("resume");
    }
  };

  const handleClickModalAction = (type: TrackingType) => (
    e: React.MouseEvent<HTMLElement>
  ) => {
    e.stopPropagation();
    const workingStatusType = getStatusFromListStatusTypesByIdentifier(
      workStatuses.working
    );
    dispatch(startCreateStatus(workingStatusType));
    setTracking(type);
    setShowErrorModal(false);
  };

  const handleClickClose = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    await removeLastTrackedTask();
    setShowPopover(false);
  };

  React.useEffect(() => {
    if (currentStatusId === workStatuses.taking_break) {
      setTracking("pause");
    } else if (
      currentStatusId === workStatuses.working &&
      lastTrackedTaskTitle
    ) {
      setTracking("resume");
    }
  }, [currentStatusId, lastTrackedTaskTitle]);

  /**
   * TODO 13.2.1 - updated dynamic destination details, update dynamic tracking time
   */

  const content = (
    <div className="TrackingTag__Popover__Content">
      <div className="TrackingTag__Popover__Content__Left">
        <div className="TrackingTag__Popover__Content__Left__Header">
          <span className="TrackingTag__Popover__Content__Left__Header__Title">
            {lastTrackedTaskTitle}
          </span>
          <span className="TrackingTag__Popover__Content__Left__Header__WorkTime">
            2h 15m
          </span>
        </div>
        <div className="TrackingTag__Popover__Content__Left__Destination">
          <span className="TrackingTag__Popover__Content__Left__Destination__ProjectTitle">
            Project title
          </span>
          <RightArrowIcon />
          <span className="TrackingTag__Popover__Content__Left__Destination__ListTitle">
            List title
          </span>
          <RightArrowIcon />
          <span className="TrackingTag__Popover__Content__Left__Destination__SectionTitle">
            Section title
          </span>
        </div>
      </div>
      {trackingStatus === "stopped" && lastTrackedTaskTitle && (
        <div className="TrackingTag__Popover__Content__Right">
          <CustomButton className="CloseBtn" onClick={handleClickClose}>
            Close
          </CustomButton>
        </div>
      )}
    </div>
  );

  const modalActions = [
    <CustomButton onClick={handleClickModalAction("resume")}>
      Resume
    </CustomButton>,
    <CustomButton type="danger" onClick={handleClickModalAction("stop")}>
      Stop
    </CustomButton>,
  ];

  const handleVisibleChange = (show: boolean) => setShowPopover(show);

  return (
    <Popover
      placement="right"
      content={content}
      onVisibleChange={handleVisibleChange}
      overlayClassName="TrackingTag__Popover"
      trigger="hover"
      visible={showPopover}
    >
      <div className="TrackingTag">
        <ErrorModal
          actions={modalActions}
          handleCancel={() => setShowErrorModal(false)}
          title="Confirmation"
          text="Do you want to resume working or stop tracking time?"
          visible={showErrorModal}
        />
        {(() => {
          if (loadingState === "true") {
            return <LoadingIcon />;
          } else if (trackingStatus === "tracking") {
            return <StopTimeTrackerIcon onClick={handleClickStopTracking} />;
          } else if (trackingStatus === "paused") {
            return <PauseTimeTrackerIcon onClick={handleClickResumeTracking} />;
          } else if (trackingStatus === "stopped" && lastTrackedTaskTitle) {
            return <TimeTrackerIcon onClick={handleClickResumeTracking} />;
          } else {
            return <></>;
          }
        })()}
      </div>
    </Popover>
  );
};

export { TrackingTag as default };
