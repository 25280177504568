import { store } from "../store/configureStore";

export const selectSectionById = (id: SectionObject["id"]) => {
  const sections: SectionArray = store.getState().sections.data;
  return sections.find(({ id: sectionId }) => sectionId === id);
};

export const selectSectionsInList = (listObject: ListObject) => {
  const sectionArray: SectionArray = store.getState().sections.data;
  return sectionArray.filter(
    ({ parent_list }) => parent_list === listObject.id
  );
};

export const selectSectionsInProject = (projectObject: ProjectObject) => {
  const userDetails: AccountUser = store.getState().userDetails.data;
  const listArray: ListArray = store.getState().lists.data;
  const sectionArray: SectionArray = store.getState().sections.data;
  return listArray.flatMap(({ id: listId, parent_project, parent_user }) => {
    const belongsToProject = parent_project === projectObject.id;
    const belongsToUser =
      parent_project === null && parent_user === userDetails.id;
    return sectionArray.filter(
      ({ parent_list }) =>
        (belongsToProject || belongsToUser) && listId === parent_list
    );
  });
};

export const selectSectionOfTask = ({
  section: taskSectionObject,
}: TaskJoinedToUserAndScheduledObject) => {
  const { id: taskSectionId } = taskSectionObject;
  const sectionArray: SectionArray = store.getState().sections.data;

  return sectionArray.find(({ id: sectionId }) => sectionId === taskSectionId);
};
