import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { find } from "lodash";

import ActivityTabHeader from "./ActivityTabHeader/ActivityTabHeader";
import ActivityTabBody from "./ActivityTabBody/ActivityTabBody";

import { selectUserDetailsState } from "../../../reducers/user-details.reducer";
import { selectTaskDetailsState } from "../../../reducers/task-details.reducer";
import { getActivityListService } from "../../../services/activities.service";
import { dispatchError } from "../../../utils/error.util";

import ws from "../../../sockets/websockets";

export interface ActivitiesState {
  data: ActivityArray;
  error: string | null;
  loading: boolean;
  creating: boolean;
}
export interface ActivityTabContentContext {
  userDrafts: ActivitiesState;
  setUserDrafts: React.Dispatch<React.SetStateAction<ActivitiesState>>;
  confirmedActivities: ActivitiesState;
  setConfirmedActivities: React.Dispatch<React.SetStateAction<ActivitiesState>>;
  teamDrafts?: ActivityArray;
  setTeamDrafts: React.Dispatch<
    React.SetStateAction<ActivityArray | undefined>
  >;
  unreadActivities?: ActivityArray;
  setUnreadActivities: React.Dispatch<
    React.SetStateAction<ActivityArray | undefined>
  >;
  submittedUnconfirmedActivities: ActivitiesState;
  setSubmittedUnconfirmedActivities: React.Dispatch<
    React.SetStateAction<ActivitiesState>
  >;
  filter: string;
  setFilter: React.Dispatch<
    React.SetStateAction<ActivityTabContentContext["filter"]>
  >;
}
export const ActivityTabContentContext = React.createContext<ActivityTabContentContext>(
  {} as ActivityTabContentContext
);

export const defaultActivitiesState = {
  data: [],
  error: null,
  loading: true,
  creating: false,
};
/**
 * Default Display
 * Displays activity tab header and body
 *
 * Functionality
 * Passes filter prop to child components (determines what activities will be displayed)
 */
const ActivityTabContent: React.FC = () => {
  const {
    data: { workspace: userWorkspace, id: userId },
  } = useSelector(selectUserDetailsState);
  const { id: taskDetailsId }: TaskJoinedToUserAndScheduledObject = useSelector(
    selectTaskDetailsState
  );

  const [userDrafts, setUserDrafts] = React.useState<ActivitiesState>(
    defaultActivitiesState
  );

  const [
    confirmedActivities,
    setConfirmedActivities,
  ] = React.useState<ActivitiesState>(defaultActivitiesState);

  const [teamDrafts, setTeamDrafts] = React.useState<ActivityArray>();

  const [
    unreadActivities,
    setUnreadActivities,
  ] = React.useState<ActivityArray>();

  const [
    submittedUnconfirmedActivities,
    setSubmittedUnconfirmedActivities,
  ] = React.useState<ActivitiesState>(defaultActivitiesState);

  const getUserDrafts = React.useCallback(async () => {
    try {
      const response = await getActivityListService({
        user: true,
        task: taskDetailsId,
        confirmed: false,
      });
      if (response.status === 200) {
        const sorted = response.data
          .sort((a: ActivityObject, b: ActivityObject) => {
            const keyA = a.created_datetime,
              keyB = b.created_datetime;
            if (moment(keyA).isBefore(keyB)) return 1;
            if (moment(keyA).isAfter(keyB)) return -1;
            return 0;
          })
          .map((activity) => ({
            // add loading state for each record
            ...activity,
            loading: false,
          }));

        setUserDrafts((c) => ({
          ...c,
          data: sorted,
          loading: false,
        }));
      } else {
        throw new Error();
      }
    } catch (e) {
      dispatchError({
        title: "Get unconfirmed activities error",
        e,
      });
      setUserDrafts((c) => ({
        ...c,
        loading: false,
      }));
    }
  }, [taskDetailsId]);

  const getConfirmedActivities = React.useCallback(async () => {
    try {
      const response = await getActivityListService({
        task: taskDetailsId,
        confirmed: true,
      });
      if (response.status === 200) {
        setConfirmedActivities((c) => ({
          ...c,
          data: response.data.map((activity) => ({
            // add loading state for each record
            ...activity,
            loading: false,
          })),
          loading: false,
        }));
      } else {
        throw new Error();
      }
    } catch (e) {
      dispatchError({
        title: "Get confirmed activities error",
        e,
      });
      setUserDrafts((c) => ({
        ...c,
        loading: false,
      }));
    }
  }, [taskDetailsId]);

  const getTeamDrafts = React.useCallback(async () => {
    if (userWorkspace) {
      try {
        const response = await getActivityListService({
          user: false,
          team: true,
          task: taskDetailsId,
          confirmed: false,
        });
        if (response.status === 200) {
          setTeamDrafts(response.data);
        } else {
          throw new Error();
        }
      } catch (e) {
        dispatchError({
          title: "Get team drafts error",
          e,
        });
      }
    }
  }, [taskDetailsId, userWorkspace]);

  const getUnreadActivities = React.useCallback(async () => {
    try {
      const response = await getActivityListService({
        task: taskDetailsId,
        isNew: true,
        deleted: false,
      });
      if (response.status === 200) {
        setUnreadActivities(response.data);
      } else {
        throw new Error();
      }
    } catch (e) {
      dispatchError({
        title: "Get new activities error",
        e,
      });
    }
  }, [taskDetailsId]);

  const getSubmittedUnconfirmedActivities = React.useCallback(async () => {
    try {
      /**
       * TODO 13.14.1 - update parameters to submitted: true, confirmed: false
       * currrent endpoint does not have parameter for submitted yet
       */
      const response = await getActivityListService({
        user: true,
        task: taskDetailsId,
        confirmed: false,
      });
      if (response.status === 200) {
        const sorted = response.data
          .sort((a: ActivityObject, b: ActivityObject) => {
            const keyA = a.created_datetime,
              keyB = b.created_datetime;
            if (moment(keyA).isBefore(keyB)) return 1;
            if (moment(keyA).isAfter(keyB)) return -1;
            return 0;
          })
          .map((activity) => ({
            // add loading state for each record
            ...activity,
            loading: false,
          }));

        setSubmittedUnconfirmedActivities((c) => ({
          ...c,
          data: sorted,
          loading: false,
        }));
      } else {
        throw new Error("");
      }
    } catch (e) {
      dispatchError({
        title: "Get submitted unconfirmed activities error",
        e,
      });
    }
  }, [taskDetailsId]);

  React.useEffect(() => {
    getUserDrafts();
    getConfirmedActivities();
    getTeamDrafts();
    getUnreadActivities();
    getSubmittedUnconfirmedActivities();
  }, [
    getUserDrafts,
    getConfirmedActivities,
    getTeamDrafts,
    getUnreadActivities,
    getSubmittedUnconfirmedActivities,
    taskDetailsId, // included because we need to re-fetch activities if taskDetailsId changed
  ]);

  const [filter, setFilter] = React.useState("Default");

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    /**
     * TODO - implement load more on scroll once pagination is applied to endpoints
     */
  };

  React.useEffect(() => {
    const wsOnMessage = async (event: MessageEvent) => {
      try {
        const messageEventData = JSON.parse(event.data);
        const {
          id: updatedActivityId,
          event: eventType,
          meta,
        } = messageEventData;
        const { user_id } = meta || {};

        const activityBelongsToUser = user_id === userId;

        const arrayIsUpdated = (array: ActivityArray) =>
          !!find(array, { id: updatedActivityId });

        const isUpdated = (activities: ActivitiesState) =>
          !!find(activities.data, { id: updatedActivityId });

        const unreadActivitiesUpdated =
          !!unreadActivities && arrayIsUpdated(unreadActivities);
        const userDraftsUpdated = !!userDrafts && isUpdated(userDrafts);
        const confirmedActivitiesUpdated =
          !!confirmedActivities && isUpdated(confirmedActivities);
        const teamDraftsUpdated = !!teamDrafts && arrayIsUpdated(teamDrafts);

        const userDraftCreated =
          activityBelongsToUser &&
          !unreadActivitiesUpdated &&
          !userDraftsUpdated &&
          !teamDraftsUpdated;

        const teamDraftsCreated =
          !activityBelongsToUser &&
          !unreadActivitiesUpdated &&
          !teamDraftsUpdated &&
          !userDraftsUpdated;

        switch (eventType.trim()) {
          case "new_activity":
            if (unreadActivitiesUpdated) getUnreadActivities();

            if ((teamDraftsCreated || teamDraftsUpdated) && userWorkspace)
              getTeamDrafts();

            if (userDraftsUpdated || teamDraftsUpdated)
              getConfirmedActivities();

            if (teamDraftsUpdated) {
              getUnreadActivities();
            }

            if (userDraftCreated || userDraftsUpdated) {
              getUserDrafts();
              getSubmittedUnconfirmedActivities();
            }

            if (confirmedActivitiesUpdated) getConfirmedActivities();

            if (userDraftCreated) {
              setUserDrafts((c) => ({
                ...c,
                creating: false,
              }));
            }
            break;
          case "new_activity_read_log":
          case "new_activity_read_log_mark_all":
            getConfirmedActivities();
            getUnreadActivities();
            /**
             * TODO 13.14.1 - add handling for event when draft activity is automatically submitted after ending shift (stays unconfirmed)
             * https://meistery.slack.com/archives/C01D76KJA78/p1616737848034900?thread_ts=1616404827.012300&cid=C01D76KJA78
             */
            break;
          default:
        }
      } catch (e) {}
    };

    ws.addEventListener("message", wsOnMessage);

    return () => {
      ws.removeEventListener("message", wsOnMessage);
    };
  }, [
    userId,
    userWorkspace,
    unreadActivities,
    userDrafts,
    confirmedActivities,
    teamDrafts,
    getUnreadActivities,
    getTeamDrafts,
    getConfirmedActivities,
    getUserDrafts,
    getSubmittedUnconfirmedActivities,
  ]);

  return (
    <ActivityTabContentContext.Provider
      value={{
        userDrafts,
        setUserDrafts,
        teamDrafts,
        setTeamDrafts,
        confirmedActivities,
        setConfirmedActivities,
        unreadActivities,
        setUnreadActivities,
        submittedUnconfirmedActivities,
        setSubmittedUnconfirmedActivities,
        filter,
        setFilter,
      }}
    >
      <div className="ActivityTabContent" onScroll={handleScroll}>
        <ActivityTabHeader />
        <ActivityTabBody />
      </div>
    </ActivityTabContentContext.Provider>
  );
};

export { ActivityTabContent as default };
