import React from "react";
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";

const DraggableRow: React.FC<DraggableRowProps> = ({
  children,
  isDragDisabled,
  ...props
}) => {
  const rowKey: string = props["data-row-key"];

  // key is the identifier for a row, index is its current order
  const [key, index] = (rowKey || "").split("#");
  return rowKey ? (
    <Draggable
      draggableId={key}
      index={Number(index)}
      isDragDisabled={isDragDisabled}
    >
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => {
        const style = {
          backgroundColor: snapshot.isDragging ? "#f5f5f5" : "#fff",
          borderBottom: snapshot.isDragging ? "none" : "1px solid #00000026",
          ...provided.draggableProps.style,
        };
        return (
          <tr
            {...(props as any)}
            {...provided.draggableProps}
            ref={provided.innerRef}
            style={style}
          >
            {Array.isArray(children)
              ? children.map((node: any) => {
                  if (node.key === "drag-column") {
                    return (
                      <td key={node.key} {...provided.dragHandleProps}>
                        {node}
                      </td>
                    );
                  }
                  return node;
                })
              : children}
          </tr>
        );
      }}
    </Draggable>
  ) : (
    <></>
  );
};

export default DraggableRow;
