import React from "react";
import { Popover } from "antd";
import { isEqual } from "lodash";

import useAsyncWorkspaceContributors from "../../../hooks/useAsyncWorkspaceContributors.hook";

import { InTheOfficeContentContext } from "../../../views/InTheOffice/InTheOfficeContent";

import AvatarGroup from "../../AvatarGroup/AvatarGroup.component";
import CustomAvatar from "../../CustomAvatar/CustomAvatar";
import { LoadingIcon, UserIcon } from "../../CustomIcons/CustomIcons.component";
import TaskContributorSelectorPopoverContent from "./TaskContributorSelectorPopoverContent/TaskContributorSelectorPopoverContent";
import { getInitials } from "../../../utils/string.util";

export interface TaskContributorSelectorProps {
  role: TaskContributorRole;
}
const TaskContributorSelector: React.FC<TaskContributorSelectorProps> = ({
  role,
}) => {
  const inTheOfficeContentContext = React.useContext(InTheOfficeContentContext);
  const {
    taskOwnersFilter,
    setTaskOwnersFilter,
    taskMembersFilter,
    setTaskMembersFilter,
  } = inTheOfficeContentContext;

  const [
    taskContributors,
    taskContributorsLoadingState,
  ] = useAsyncWorkspaceContributors(role);

  const [showPopover, setShowpopover] = React.useState(false);
  const [
    selectedTaskContributors,
    setSelectedTaskContributors,
  ] = React.useState<ProjectUser[]>([]);

  const selectedTaskContributorsAvatars = selectedTaskContributors.map(
    ({ avatar }) => (avatar ? avatar : "")
  );
  const selectedTaskContributorsFallbackText = selectedTaskContributors.map(
    ({ fullName }) => getInitials(fullName)
  );

  const handleVisibleChange = (visible: boolean) => {
    setShowpopover(visible);
  };

  const handleAddTaskContributor = (taskContributor: ProjectUser) => {
    const setFunction =
      role === "member" ? setTaskMembersFilter : setTaskOwnersFilter;
    setFunction((c) => [...c, taskContributor]);
  };

  const handleRemoveTaskContributor = (taskContributor: ProjectUser) => {
    const setFunction =
      role === "member" ? setTaskMembersFilter : setTaskOwnersFilter;
    setFunction((c) => c.filter((d) => !isEqual(d, taskContributor)));
  };

  React.useEffect(() => {
    if (role === "owner") {
      setSelectedTaskContributors(taskOwnersFilter);
    }
  }, [role, taskOwnersFilter]);

  React.useEffect(() => {
    if (role === "member") {
      setSelectedTaskContributors(taskMembersFilter);
    }
  }, [role, taskMembersFilter]);

  return (
    <div className="TaskContributorSelector">
      {taskContributorsLoadingState === "true" ? (
        <LoadingIcon />
      ) : (
        <Popover
          content={
            <TaskContributorSelectorPopoverContent
              handleAddTaskContributor={handleAddTaskContributor}
              handleRemoveTaskContributor={handleRemoveTaskContributor}
              role={role}
              selectedTaskContributors={selectedTaskContributors}
              taskContributors={taskContributors}
            />
          }
          trigger="click"
          placement="bottom"
          overlayClassName="TaskContributorSelector__Popover"
          visible={showPopover}
          onVisibleChange={handleVisibleChange}
        >
          {selectedTaskContributors.length > 0 ? (
            <AvatarGroup
              avatars={selectedTaskContributorsAvatars}
              avatarsFallBackText={selectedTaskContributorsFallbackText}
              size={20}
            />
          ) : (
            <CustomAvatar size={20} icon={<UserIcon />} />
          )}
        </Popover>
      )}
    </div>
  );
};

export default TaskContributorSelector;
