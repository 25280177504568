import { Dispatch } from "redux";
import { getUsersByWorkspaceService } from "../services/users.service";
import { dispatchError } from "../utils/error.util";

export const requestWorkspaceUsers = () => ({
  type: "REQUEST_WORKSPACE_USERS",
});

export const requestWorkspaceUsersSuccess = (users: WorkspaceUser[]) => ({
  type: "REQUEST_WORKSPACE_USERS_SUCCESS",
  payload: users,
});

export const requestWorkspaceUsersFailed = (error: string) => ({
  type: "REQUEST_WORKSPACE_USERS_FAILED",
  error,
});

export const startPopulateWorkspaceUsers = () => {
  return async (dispatch: Dispatch) => {
    dispatch(requestWorkspaceUsers());
    try {
      const response = await getUsersByWorkspaceService();
      const { status, data } = response;
      if (status === 200) {
        const payload = data.map((item: any) => ({
          id: item.id,
          avatar: item.avatar,
          fullName: item.full_name,
          displayName: item.display_name,
        }));
        dispatch(requestWorkspaceUsersSuccess(payload));
        return payload;
      } else {
        const error = "getUsersByWorkspaceService status is not 200";
        dispatch(requestWorkspaceUsersFailed(error));
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestWorkspaceUsersFailed(error));
      dispatchError({
        e,
        title: "Populate users error",
      });
      return [];
    }
  };
};
