import React from "react";

import { getUserActivitiesService } from "../services/activities.service";

import ws from "../sockets/websockets";

interface useAsyncUserActivitySummaryArrayParams {
  fromDate?: string;
  toDate?: string;
  users: UserArray;
}
export default function useAsyncUserActivitySummaryArray({
  fromDate,
  toDate,
  users,
}: useAsyncUserActivitySummaryArrayParams) {
  const [
    userActivitySummaryArray,
    setUserActivitySummaryArray,
  ] = React.useState<ActivitySummaryArray>();

  const [loading, setLoading] = React.useState(false);

  const usersIds = React.useMemo(() => users.map(({ id: userId }) => userId), [
    JSON.stringify(users),
  ]);

  const fetchUserActivities = React.useCallback(async () => {
    if (usersIds.length > 0) {
      try {
        setLoading(true);
        const result: ActivitySummaryArray[] = await Promise.all(
          usersIds.map(async (id) => {
            try {
              const response = await getUserActivitiesService({
                fromDate,
                toDate,
                userId: id,
              });
              if (response.status === 200) {
                return response.data.filter(({ deleted_yn }) => !deleted_yn);
              }
              return [];
            } catch (e) {
              return [];
            }
          })
        );
        setUserActivitySummaryArray(result.flatMap((item) => item));
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    }
  }, [usersIds, fromDate, toDate]);

  React.useEffect(() => {
    fetchUserActivities();
  }, [fetchUserActivities]);

  React.useEffect(() => {
    const wsOnMessage = async (event: MessageEvent) => {
      try {
        const messageEventData = JSON.parse(event.data);
        const { event: eventType } = messageEventData;
        switch (eventType.trim()) {
          case "new_activity":
          case "new_activity_read_log":
          case "new_activity_read_log_mark_all":
            fetchUserActivities();
            break;
        }
      } catch (e) {}
    };
    ws.addEventListener("message", wsOnMessage);

    return () => {
      ws.removeEventListener("message", wsOnMessage);
    };
  }, [fetchUserActivities, userActivitySummaryArray]);

  const returnValue: [ActivitySummaryArray | undefined, boolean] = [
    userActivitySummaryArray,
    loading,
  ];

  return returnValue;
}
