import React from "react";
import { Tooltip, Popover } from "antd";

import LabelPicker from "../../LabelPicker/LabelPicker";
import { LabelIcon } from "../../CustomIcons/CustomIcons.component";

/**
 * Default display
 * Displays NextShiftIcon if task is scheduled for next shift
 */
const LabelDisplay: React.FC<LabelDisplayProps> = ({ task }) => {
  const [visible, setVisible] = React.useState(false);

  const handleVisibleChange = (visible: boolean) => {
    setVisible(visible);
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (e) e.stopPropagation();
  };

  // TODO: integrate endpoint
  const handleLabelsChange = (value: string[]) => {};

  const handleLabelPickerClose = () => {
    setVisible(false);
  };

  return (
    <Popover
      placement="bottom"
      destroyTooltipOnHide={true}
      overlayClassName="LabelDisplay__Popover"
      content={
        <LabelPicker
          labels={[]}
          defaultKeys={["1"]}
          onChange={handleLabelsChange}
          onClose={handleLabelPickerClose}
        />
      }
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
      getPopupContainer={(trigger: any) => trigger.parentElement}
    >
      <Tooltip title="Edit Labels">
        <LabelIcon className="LabelDisplay" onClick={handleClick} />
      </Tooltip>
    </Popover>
  );
};

export default LabelDisplay;
