import { Dispatch } from "redux";

import { store } from "../store/configureStore";

import {
  fetchNotificationsService,
  markAllNotificationsAsReadService,
  markMultipleNotificationsAsReadService,
  MarkMultipleNotificationsAsReadServiceData,
  markNotificationAsReadService,
} from "../services/notifications.service";
import { dispatchError } from "../utils/error.util";

export const requestFetchNotifications = () => ({
  type: "REQUEST_FETCH_NOTIFICATIONS",
});
export const requestFetchNotificationsSuccess = (
  notifications: NotificationArray
) => ({
  type: "REQUEST_FETCH_NOTIFICATIONS_SUCCESS",
  notifications,
});
export const requestFetchNotificationsFailed = (error: string) => ({
  type: "REQUEST_FETCH_NOTIFICATIONS_FAILED",
  error,
});
export const startPopulateNotifications = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(requestFetchNotifications());
      const response = await fetchNotificationsService();
      if (response.status === 200) {
        const notifications: NotificationArray = response.data || [];
        dispatch(requestFetchNotificationsSuccess(notifications));
      } else {
        const error = "fetchNotificationsService status is not 200";
        dispatch(requestFetchNotificationsFailed(error));
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestFetchNotificationsFailed(error));
      dispatchError({
        e,
        title: "Populate notifications error",
      });
    }
  };
};

export const requestMarkAllNotificationsAsRead = () => ({
  type: "REQUEST_MARK_ALL_NOTIFICATIONS_AS_READ",
});
export const requestMarkAllNotificationsAsReadSuccess = (
  notifications: NotificationArray
) => ({
  type: "REQUEST_MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS",
  notifications,
});
export const requestMarkAllNotificationsAsReadFailed = (error: string) => ({
  type: "REQUEST_MARK_ALL_NOTIFICATIONS_AS_READ_FAILED",
  error,
});
export const startMarkAllNotificationsAsRead = () => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await markAllNotificationsAsReadService();
      if (response.status === 200) {
        const updatedNotifications: NotificationArray = response.data;
        dispatch(
          requestMarkAllNotificationsAsReadSuccess(updatedNotifications)
        );
      } else {
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestMarkAllNotificationsAsReadFailed(error));
      dispatchError({
        e,
        title: "Mark all notifications as read",
      });
    }
  };
};

export const requestMarkNotificationsAsRead = () => ({
  type: "REQUEST_MARK_NOTIFICATION_AS_READ",
});
export const requestMarkNotificationsAsReadSuccess = (
  notifications: NotificationArray
) => ({
  type: "REQUEST_MARK_NOTIFICATION_AS_READ_SUCCESS",
  notifications,
});
export const requestMarkNotificationsAsReadFailed = (error: string) => ({
  type: "REQUEST_MARK_NOTIFICATION_AS_READ_FAILED",
  error,
});
export const startMarkNotificationAsRead = (id: NotificationObject["id"]) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await markNotificationAsReadService(id);
      if (response.status === 200) {
        const updatedNotification: NotificationObject = response.data;
        const notificationsStateData: NotificationArray = store.getState()
          .notifications.data;

        const updatedNotifications = notificationsStateData.map((item) =>
          item.id === updatedNotification.id ? updatedNotification : item
        );

        dispatch(requestMarkNotificationsAsReadSuccess(updatedNotifications));
      } else {
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestMarkNotificationsAsReadFailed(error));
      dispatchError({
        e,
        title: "Mark notification as read error",
      });
    }
  };
};

export const requestMarkMultipleNotificationsAsRead = () => ({
  type: "REQUEST_MARK_MULTIPLE_NOTIFICATIONS_AS_READ",
});
export const requestMarkMultipleNotificationsAsReadSuccess = (
  notifications: NotificationArray
) => ({
  type: "REQUEST_MARK_NOTIFICATION_AS_READ_SUCCESS",
  notifications,
});
export const requestMarkMultipleNotificationsAsReadFailed = (
  error: string
) => ({
  type: "REQUEST_MARK_MULTIPLE_NOTIFICATIONS_AS_READ_FAILED",
  error,
});
export const startMarkMultipleNotificationsAsRead = (
  ids: MarkMultipleNotificationsAsReadServiceData
) => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await markMultipleNotificationsAsReadService(ids);
      if (response.status === 200) {
        const updatedNotifications: NotificationArray = response.data;

        dispatch(
          requestMarkMultipleNotificationsAsReadSuccess(updatedNotifications)
        );
      } else {
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestMarkMultipleNotificationsAsReadFailed(error));
      dispatchError({
        e,
        title: "Mark multiple notifications as read error",
      });
    }
  };
};
