import { Dispatch } from "redux";
import moment from "moment";
import { findIndex, remove } from "lodash";

import { RootState, store } from "../store/configureStore";

import {
  getActivityListService,
  addActivityService,
  updateActivityService,
} from "../services/activities.service";
import { dispatchError } from "../utils/error.util";

export const requestFetchActivities = () => ({
  type: "REQUEST_FETCH_ACTIVITIES",
});

export const requestFetchActivitiesSuccess = (activities: ActivityArray) => ({
  type: "REQUEST_FETCH_ACTIVITIES_SUCCESS",
  activities,
});

export const requestFetchActivitiesFailed = (error: string) => ({
  type: "REQUEST_FETCH_ACTIVITIES_FAILED",
  error,
});

export const startPopulateActivities = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({ type: "REQUEST_FETCH_ACTIVITIES" });
      const response = await getActivityListService({
        user: true,
      });
      if (response.status === 200) {
        const activities: ActivityArray = response.data;
        dispatch(requestFetchActivitiesSuccess(activities));
      } else {
        const error = "getActivityListService status is not 200";
        dispatch(requestFetchActivitiesFailed(error));
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestFetchActivitiesFailed(error));
      dispatchError({
        e,
        title: "Populate activities error",
      });
    }
  };
};

export const requestCreateActivity = () => ({
  type: "REQUEST_CREATE_ACTIVITY",
});
export const requestCreateActivitySuccess = (activities: ActivityArray) => ({
  type: "REQUEST_CREATE_ACTIVITY_SUCCESS",
  activities,
});
export const requestCreateActivityFailed = (error: string) => ({
  type: "REQUEST_CREATE_ACTIVITY_FAILED",
  error,
});

export const startCreateActivity = (
  data: Omit<ActivityObject, "id" | "loading">
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(requestCreateActivity());
      const response = await addActivityService(data);
      if (response.status === 201) {
        const addedActivity: ActivityObject = response.data;
        dispatch(
          requestCreateActivitySuccess([
            ...store.getState().activities.data,
            addedActivity,
          ])
        );
      } else {
        const error = "addActivityService status is not 201";
        dispatch(requestCreateActivityFailed(error));
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestCreateActivityFailed(error));
      dispatchError({
        e,
        title: "Add activity error",
      });
    }
  };
};

export const requestUpdateActivity = () => ({
  type: "REQUEST_UPDATE_ACTIVITY",
});
export const requestUpdateActivitySuccess = (activities: ActivityArray) => ({
  type: "REQUEST_UPDATE_ACTIVITY_SUCCESS",
  activities,
});
export const requestUpdateActivityFailed = (error: string) => ({
  type: "REQUEST_UPDATE_ACTIVITY_FAILED",
  error,
});

export const startUpdateActivity = (activity: ActivityObject) => {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    try {
      dispatch(requestUpdateActivity());
      const response = await updateActivityService(activity);
      if (response.status === 200) {
        const updatedActivity = {
          ...(response.data as ActivityObject),
          loading: false,
        };
        const activitiesStateData = getState().activities.data;
        const activitiesIndex = findIndex(activitiesStateData, [
          "id",
          updatedActivity.id,
        ]);
        dispatch(
          requestUpdateActivitySuccess(
            activitiesIndex !== -1
              ? activitiesStateData.splice(activitiesIndex, 1, updatedActivity)
              : activitiesStateData
          )
        );
      } else {
        const error = "updateActivityService status is not 200";
        dispatch(requestUpdateActivityFailed(error));
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestUpdateActivityFailed(error));
      dispatchError({
        e,
        title: "Update activity error",
      });
    }
  };
};

export const requestDeleteActivity = () => ({
  type: "REQUEST_DELETE_ACTIVITY",
});
export const requestDeleteActivitySuccess = (activities: ActivityArray) => ({
  type: "REQUEST_DELETE_ACTIVITY_SUCCESS",
  activities,
});
export const requestDeleteActivityFailed = (error: string) => ({
  type: "REQUEST_DELETE_ACTIVITY_FAILED",
  error,
});

export const startDeleteActivity = (data: ActivityObject) => {
  return async (dispatch: Dispatch<any>, getState: () => RootState) => {
    try {
      dispatch(requestDeleteActivity());
      const response = await updateActivityService({
        ...data,
        deleted_yn: true,
        last_update_date: moment().toISOString(),
      });
      if (response.status === 200) {
        const deletedActivity: ActivityObject = response.data;
        dispatch(
          requestDeleteActivitySuccess(
            remove(
              getState().activities.data,
              (o) => o.id !== deletedActivity.id
            )
          )
        );
      } else {
        const error = "updateActivityService status is not 200";
        dispatch(requestDeleteActivityFailed(error));
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestDeleteActivityFailed(error));
      dispatchError({
        e,
        title: "Delete activity error",
      });
    }
  };
};
