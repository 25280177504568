import React from "react";
import cn from "classnames";
import { Moment } from "moment";

import CustomAvatar from "../../../CustomAvatar/CustomAvatar";
import { LoadingIcon } from "../../../CustomIcons/CustomIcons.component";

import { workDayDateFormat } from "../../../../constants/constants";
import { getUserActivities } from "../../../../utils/activities.util";
import { dispatchError } from "../../../../utils/error.util";
import { getInitials } from "../../../../utils/string.util";

export interface StarredTeamMemberItemProps {
  endDate: Moment;
  observableUser: ObservableUserObject;
  onClickCallback: Function;
  startDate: Moment;
}

const StarredTeamMemberItem: React.FC<StarredTeamMemberItemProps> = ({
  endDate,
  onClickCallback,
  observableUser,
  startDate,
}) => {
  const { observed_user, shown_yn } = observableUser;
  const { avatar, display_name = "", current_status } = observed_user || {};
  const { status_enum_identifier } = current_status || {};

  const [
    unreadActivitiesCount,
    setUnreadActivitiesCount,
  ] = React.useState<number>();
  const [loading, setLoading] = React.useState(false);

  const fromDateString = !!startDate ? startDate.format(workDayDateFormat) : "";
  const toDateString = !!endDate ? endDate.format(workDayDateFormat) : "";

  React.useEffect(() => {
    const getUserUnreadActivitiesCount = async () => {
      try {
        if (!!fromDateString && !!toDateString && !!observed_user) {
          setLoading(true);
          const activitySummaryArray = await getUserActivities(
            fromDateString,
            toDateString,
            [observed_user.id]
          );
          const unreadActivitySummaryArray = activitySummaryArray.filter(
            ({ read }) => !read
          );
          setUnreadActivitiesCount(unreadActivitySummaryArray.length);
          setLoading(false);
        }
      } catch (e) {
        setUnreadActivitiesCount(undefined);
        setLoading(false);
        dispatchError({
          e,
          title: "GET observable user unread activities count error",
        });
      }
    };
    getUserUnreadActivitiesCount();
  }, [fromDateString, toDateString, observed_user.id]);

  return (
    <div
      className={cn("StarredTeamMemberItem", {
        "StarredTeamMemberItem--Selected": shown_yn,
      })}
      onClick={() => {
        onClickCallback(observableUser);
      }}
    >
      <CustomAvatar
        src={avatar}
        size={24}
        work_status={status_enum_identifier}
        fallbackText={getInitials(display_name)}
      />
      <span className="StarredTeamMemberItem__Name">{display_name}</span>
      {loading && <LoadingIcon />}
      {!loading && unreadActivitiesCount && (
        <span className="StarredTeamMemberItem__UnreadActivitiesCount">
          {unreadActivitiesCount}
        </span>
      )}
    </div>
  );
};

export default StarredTeamMemberItem;
