import React from "react";

import { CaretDownIcon } from "../../CustomIcons/CustomIcons.component";

const placeholderItem = (
  <>
    <div className="ProjectPreview__Menu__ProjectPlaceholder">
      <div className="ProjectPreview__Menu__ProjectPlaceholder__Details">
        <div className="ProjectPreview__Menu__ProjectPlaceholder__Initial" />
        <div className="ProjectPreview__Menu__ProjectPlaceholder__Title" />
      </div>
      <div className="ProjectPreview__Menu__ProjectPlaceholder__Action" />
    </div>
    <div className="ProjectPreview__Menu__ListPlaceholder">
      <div className="ProjectPreview__Menu__ListPlaceholder__Details">
        <div className="ProjectPreview__Menu__ListPlaceholder__Title" />
      </div>
      <div className="ProjectPreview__Menu__ProjectPlaceholder__Action" />
    </div>
  </>
);

const taskPlaceholder = (
  <div className="ProjectPreview__Content__Body__TaskPlaceholder">
    <div className="ProjectPreview__Content__Body__TaskPlaceholder__Title" />
    <div className="ProjectPreview__Content__Body__TaskPlaceholder__Tags">
      <div />
      <div />
      <div />
    </div>
  </div>
);

const ProjectPreview = () => {
  return (
    <div className="ProjectPreview">
      <div className="ProjectPreview__Menu">
        <div className="ProjectPreview__Menu__Title">Projects</div>
        <div className="ProjectPreview__Menu__Project">
          <div className="ProjectPreview__Menu__Project__Initial">N</div>
          <div className="ProjectPreview__Menu__Project__Title">
            New Project
          </div>
          <CaretDownIcon />
        </div>
        <div className="ProjectPreview__Menu__List">Task List 1</div>
        {placeholderItem}
        {placeholderItem}
        {placeholderItem}
      </div>
      <div className="ProjectPreview__Content">
        <div className="ProjectPreview__Content__Header">
          <div className="ProjectPreview__Content__Header__ProjectDetails">
            <div className="ProjectPreview__Content__Header__ProjectDetails__Initial">
              N
            </div>
            <div className="ProjectPreview__Content__Header__ProjectDetails__Title">
              New Project
            </div>
          </div>
          <div className="ProjectPreview__Content__Header__ListDetails">
            <div className="ProjectPreview__Content__Header__ListDetails__Corner">
              <div />
            </div>
            <div className="ProjectPreview__Content__Header__ListDetails__Title">
              Task List 1
            </div>
          </div>
          <div className="ProjectPreview__Content__Header__SearchBar"></div>
        </div>
        <div className="ProjectPreview__Content__Body">
          {taskPlaceholder}
          {taskPlaceholder}
          {taskPlaceholder}
          {taskPlaceholder}
          {taskPlaceholder}
        </div>
      </div>
    </div>
  );
};

export default ProjectPreview;
