import React from "react";

import UserTaskActivityList from "../../Activities/UserTaskActivityList/UserTaskActivityList";
import UserTaskActivityListGroupedByDate from "../../Activities/UserTaskActivityListGroupedByDate/UserTaskActivityListGroupedByDate";

/**
 * Default Display
 * TaskCollapsePanelContent displays all of the activities of the current user for a specific task on the selected checkout date
 */
const TaskCollapsePanelContent: React.FC<TaskCollapsePanelContentProps> = ({
  date,
  task,
  taskActivitySummaryArray,
  type,
  users,
}) => {
  const { id: taskId } = task;
  const groupByDate = type !== "checkout";

  const activityListJsx = groupByDate ? (
    <UserTaskActivityListGroupedByDate
      displayActivityAvatar={type !== "history"}
      displayActivityHeader={type !== "history"}
      displayWorkDayLastShiftEndDateTime={type === "history"}
      taskActivitySummaryArray={taskActivitySummaryArray}
      taskId={taskId}
      type={type}
      users={users}
    />
  ) : (
    <UserTaskActivityList taskActivitySummaryArray={taskActivitySummaryArray} />
  );

  return <div className="TaskCollapsePanelContent">{activityListJsx}</div>;
};

export default TaskCollapsePanelContent;
