import React from "react";
import cn from "classnames";
import { Collapse } from "antd";
import { isEqual } from "lodash";
import { Feature } from "flagged";

import useAsyncTasksInSection from "../../hooks/useAsyncTasksInSection.hook";
import useVisibleOnScroll from "../../hooks/useVisibleOnScroll.hook";

import { ListContext } from "../List/List";

import CardEmptyState from "../EmptyState/CardEmptyState/CardEmptyState";
import { LoadingIcon } from "../CustomIcons/CustomIcons.component";
import SectionHeader from "./SectionHeader/SectionHeader";
import DraggableItem from "../General/DragAndDrop/DraggableItem/DraggableItem";
import DroppableItem from "../General/DragAndDrop/DroppableItem/DroppableItem";
import TaskCardItem from "../Tasks/TaskCardItem/TaskCardItem";

import { checklist } from "../../constants/constants";

const { Panel } = Collapse;
export interface SectionProps extends MyDeskTaskPanelProps {
  draggablePlaceholderProps: DraggablePlaceholderProps;
  handleSectionToggle: Function;
  index?: number;
  onTaskCopy?: (task: TaskObject) => void;
  onTaskSelect?: (task: TaskObject) => void;
  openedSections: SectionObject["id"][];
  panelType: PanelType;
  project: ProjectObject;
  section: SectionObject;

  sourceSection?: SectionObject;
  destinationSection?: SectionObject;
  sourceIndex?: number;
  destinationIndex?: number;
}

export interface SectionProps extends MyDeskTaskPanelProps {
  archiveMode?: boolean;
  section: SectionObject;
  project: ProjectObject;
  panelType: PanelType;
  openedSections: SectionObject["id"][];
  handleSectionToggle: Function;
  onTaskSelect?: (task: TaskObject) => void;
  onTaskCopy?: (task: TaskObject) => void;
}

// TODO 13.11.7 - remove context used for mocking reactivity
export interface SectionContext {
  setDisplayedTasks: React.Dispatch<
    React.SetStateAction<TaskJoinedToUserAndScheduledArray>
  >;
}
export const SectionContext = React.createContext<SectionContext>(
  {} as SectionContext
);
// END TODO

const Section: React.FC<SectionProps> = ({
  draggablePlaceholderProps,
  archiveMode = false,
  handleSectionToggle,
  index = 0,
  onTaskCopy,
  onTaskSelect,
  openedSections,
  panelType,
  project,
  section,

  sourceSection,
  destinationSection,
  sourceIndex,
  destinationIndex,
}) => {
  const scrollRef = React.useRef(null);
  const sectionEndRef = React.useRef(null);

  const { counterToReload } = React.useContext(ListContext);

  /**
   * TODO 13.11.8 - filter tasks by InTheOfficeContentContext owner and member filters
   */
  const [
    displayedTasks,
    setDisplayedTasks,
  ] = React.useState<TaskJoinedToUserAndScheduledArray>([]);
  const [page, setPage] = React.useState(1);

  const { id: sectionId } = section || {};

  const isTaskPanel = panelType === "task";
  const hasTask = displayedTasks.length > 0;
  const isOpen = sectionId ? openedSections.includes(sectionId) : false;
  const pageSize = 10;

  const [
    tasksInSection,
    tasksInSectionLoading,
    tasksInSectionMaxCount,
  ] = useAsyncTasksInSection({
    sectionId,
    archived: archiveMode,
    page,
    pageSize,
  });
  const [
    tasksInSourceSection,
    tasksInSourceSectionLoading,
  ] = useAsyncTasksInSection({
    sectionId: sourceSection ? sourceSection.id : "",
  });

  const scrollBottomCallback = React.useCallback(() => {
    if (
      !tasksInSectionLoading &&
      tasksInSectionMaxCount !== undefined &&
      !!tasksInSection &&
      tasksInSection.length < tasksInSectionMaxCount
    ) {
      setPage((c) => {
        if (c * pageSize <= tasksInSectionMaxCount) {
          return c + 1;
        }
        return c;
      });
    }
  }, [tasksInSectionLoading, tasksInSection, tasksInSectionMaxCount]);

  const isSectionEnd = useVisibleOnScroll(
    sectionEndRef,
    document.getElementsByClassName("ListPanel")[0]
  );

  React.useEffect(() => {
    if (isSectionEnd) scrollBottomCallback();
  }, [isSectionEnd, scrollBottomCallback]);

  React.useEffect(() => {
    if (!!tasksInSection) setDisplayedTasks(tasksInSection);
  }, [tasksInSection, counterToReload]);

  const draggedToSection = isEqual(section, destinationSection);
  const draggedToDiffSection = !isEqual(sourceSection, destinationSection);
  const removedFromSection =
    draggedToDiffSection && isEqual(section, sourceSection);

  React.useEffect(() => {
    if (
      draggedToSection &&
      !draggedToDiffSection &&
      sourceIndex !== undefined &&
      destinationIndex !== undefined
    ) {
      // if task is moved to same section, reorder the tasks
      setDisplayedTasks((c) => {
        const clone = [...c];
        const [removed] = clone.splice(sourceIndex, 1);
        clone.splice(destinationIndex, 0, removed);
        return clone;
      });
    }
  }, [draggedToSection, draggedToDiffSection, sourceIndex, destinationIndex]);

  React.useEffect(() => {
    if (
      draggedToSection &&
      draggedToDiffSection &&
      !!destinationIndex &&
      !!sourceIndex &&
      !!tasksInSourceSection
    ) {
      // if task is moved to another section, add task to destination section
      const movedTask = tasksInSourceSection[sourceIndex];
      setDisplayedTasks((c) => {
        const clone = [...c];
        clone.splice(destinationIndex, 0, movedTask);
        return clone;
      });
    }
  }, [
    draggedToSection,
    draggedToDiffSection,
    destinationIndex,
    sourceIndex,
    tasksInSourceSection,
  ]);

  React.useEffect(() => {
    if (removedFromSection && sourceIndex !== undefined) {
      // if task is moved to another section, remove task from source section
      setDisplayedTasks((c) => {
        const clone = [...c];
        clone.splice(sourceIndex, 1);
        return clone;
      });
    }
  }, [removedFromSection, sourceIndex]);

  const tasksJsx = displayedTasks.map((task, index) => {
    const taskJsx = (
      <TaskCardItem
        key={index}
        archived={archiveMode}
        task={task}
        checklist={checklist}
        tracking={false}
        project={project}
        panelType={panelType}
        onTaskSelect={onTaskSelect}
        onCopy={onTaskCopy}
      />
    );
    return (
      <Feature key={index} name="under_development">
        {(isEnabled: boolean) =>
          isEnabled ? (
            <DraggableItem key={task.id} draggableId={task.id} index={index}>
              {taskJsx}
            </DraggableItem>
          ) : (
            taskJsx
          )
        }
      </Feature>
    );
  });

  const sectionJsx = (
    <div
      ref={scrollRef}
      className={cn("Section__Tasks", {
        "Section__Tasks--TaskPanel": isTaskPanel,
        "Section__Tasks--ProjectPanel": !isTaskPanel,
      })}
    >
      {tasksInSectionMaxCount !== undefined && hasTask && tasksJsx}
      <div ref={sectionEndRef} />
      {tasksInSectionMaxCount !== undefined &&
        !hasTask && (
          <CardEmptyState
            message="No tasks in section."
            panelType={panelType}
          />
        )}
      {tasksInSectionLoading && <LoadingIcon className="Section__Loader" />}
    </div>
  );

  return (
    <SectionContext.Provider
      value={{
        setDisplayedTasks,
      }}
    >
      <Collapse bordered={false} activeKey={openedSections}>
        <Panel
          key={sectionId}
          showArrow={false}
          collapsible="header"
          header={
            <SectionHeader
              archiveMode={archiveMode}
              handleSectionToggle={handleSectionToggle}
              isOpen={isOpen}
              panelType={panelType}
              section={section}
            />
          }
        >
          <Feature name="under_development">
            {(isEnabled: boolean) =>
              isEnabled ? (
                <DroppableItem
                  key={String(index)}
                  droppableId={String(index)}
                  draggablePlaceholderProps={draggablePlaceholderProps}
                >
                  {sectionJsx}
                </DroppableItem>
              ) : (
                sectionJsx
              )
            }
          </Feature>
        </Panel>
      </Collapse>
    </SectionContext.Provider>
  );
};

export default Section;
