import { store } from "../store/configureStore";
import { AxiosError } from "axios";

import { setError } from "../actions/error.action";
import { requestFetchLogoutSuccess } from "../actions/auth.action";
import { setUserDetailsDefault } from "../actions/user-details.action";
import { setAuthToken } from "../apis/django.instance";

interface DispatchErrorParams {
  e: unknown;
  title: string;
}
export const dispatchError = ({ e, title }: DispatchErrorParams) => {
  // TODO replace with alert error once alert has beeen implemented
  const status = (e as AxiosError).response?.status;
  // const error = (e as Error).message;

  if (status === 401) {
    setAuthToken(null);
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("email");
    store.dispatch(requestFetchLogoutSuccess());
    store.dispatch(setUserDetailsDefault());
  }

  // store.dispatch(
  //   setError({
  //     title,
  //     text: error ? error : (e as Error).message,
  //     status,
  //   })
  // );
};
