export const sectionArray: SectionArray = [
  { id: "B058T4CZDA", parent_list: "NOWVC6LH1X", title: "Sample Section A" },
  { id: "BJSB3UUVVP", parent_list: "7U86C77U5R", title: "Sample Section A" },
];
export const sectionObject: SectionObject = sectionArray[0];

export const sections = [
  {
    id: '1',
    section: "In Progress",
    tasks: 32,
    archived: 6,
  },
  {
    id: "2",
    section: "On hold",
    tasks: 2,
    archived: 16,
  },
  {
    id: "3",
    section: "Final review",
    tasks: 0,
    archived: 0,
  },
];

export const removedSections = [
  {
    id: "4",
    section: "Queued",
    tasks: 0,
    archived: 9,
  },
  {
    id: "5",
    section: "Test",
    tasks: 0,
    archived: 0,
  },
];
