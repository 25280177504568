import { RootState } from "../store/configureStore";

export interface TeammatesState extends BaseReducerState {
  data: Teammate[];
}
export const selectTeammatesState = (rootState: RootState) =>
  rootState.teammates;
export const teammatesReducerDefaultState: TeammatesState = {
  data: [],
  error: "",
  loading: false,
};
const teammatesReducer = (
  state = teammatesReducerDefaultState,
  action: { type: string; teammates: Teammate[]; error: string }
) => {
  switch (action.type) {
    case "REQUEST_TEAMMATES":
      return {
        ...state,
        error: "",
        loading: true,
      };
    case "REQUEST_TEAMMATES_SUCCESS":
      return {
        data: action.teammates,
        error: "",
        loading: false,
      };
    case "REQUEST_TEAMMATES_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export { teammatesReducer as default };
