import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { ActivityTabContentContext } from "../ActivityTabContent/ActivityTabContent";

import ActivityCardItem from "../ActivityCardItem/ActivityCardItem";

import { selectTaskDetailsState } from "../../../reducers/task-details.reducer";
import { groupActivitiesByDate } from "../../../utils/activities.util";
import { markAllAsUnreadService } from "../../../services/activities.service";

/**
 * Default display
 * Displays list of unread activities of the current user and task
 */
const UnreadTaskActivities: React.FC = () => {
  const { unreadActivities } = React.useContext(ActivityTabContentContext);

  const taskDetails = useSelector(selectTaskDetailsState);

  const [
    groupedUnreadTaskActivities,
    setGroupedUnreadTaskActivities,
  ] = React.useState<ReturnType<typeof groupActivitiesByDate> | undefined>(
    undefined
  );

  const handleClick = async () => {
    await markAllAsUnreadService(taskDetails.id);
  };

  React.useEffect(() => {
    if (!!unreadActivities && unreadActivities.length > 0) {
      setGroupedUnreadTaskActivities(groupActivitiesByDate(unreadActivities));
    } else {
      setGroupedUnreadTaskActivities(undefined);
    }
  }, [unreadActivities]);

  return (
    <div className="UnreadTaskActivities">
      {!groupedUnreadTaskActivities ? (
        <div className="UnreadTaskActivities__NoActivities">
          <span>You don't have unread activities.</span>
        </div>
      ) : (
        <>
          {Object.keys(groupedUnreadTaskActivities!).map((key) => {
            return (
              <div className="UnreadTaskActivities__Item" key={key}>
                <div className="UnreadTaskActivities__Item__Header">
                  <div className="UnreadTaskActivities__Item__Header__Title">
                    <span>{key}</span>
                  </div>
                </div>
                <div className="UnreadTaskActivities__Item__Body">
                  {groupedUnreadTaskActivities![key].map(
                    (newActivity, index) => {
                      return (
                        <ActivityCardItem
                          activityObject={newActivity}
                          key={index}
                        />
                      );
                    }
                  )}
                </div>
              </div>
            );
          })}
          <div
            className="UnreadTaskActivities__MarkAllAsRead"
            onClick={handleClick}
          >
            <span>Mark all as read</span>
          </div>
        </>
      )}
    </div>
  );
};

export default UnreadTaskActivities;
