import Item from "antd/lib/list/Item";
import axios from "../apis/django.instance";

export const getUsersByWorkspaceService: GetUsersByWorkspaceService = async () => {
  const response = await axios.get("/api/accounts/get_user_details/");
  return response;
};

export const getUsersByProjectService: GetUsersByProjectService = async (
  projectId
) => {
  try {
    const response = await axios.get(
      `/api/projects/project/${projectId}/memberships/`
    );
    const { status, data } = response;
    if (status === 200) {
      return data.map((item: any) => ({
        id: item.user.id,
        membershipId: item.id,
        avatar: item.user.avatar,
        fullName: item.user.full_name,
        displayName: item.user.display_name,
        accessType: item.access_type_enum,
        status:
          item.user.current_status.status_enum_identifier || "out_of_office",
        projectRole: item.project_role,
        taskCount: item.task_count,
      }));
    }
    return [];
  } catch (e) {
    return [];
  }
};

export const getUsersByTaskListService: GetUsersByTaskListService = async (
  listId
) => {
  try {
    const response = await axios.get(
      `/api/projects/task_list/${listId}/memberships/`
    );
    const { status, data } = response;
    if (status === 200) {
      return data.map((item: any) => ({
        ...item.user,
        membershipId: item.id,
        fullName: item.user.full_name,
        displayName: item.user.display_name,
        accessType: item.access_type_enum,
      }));
    }
    return [];
  } catch (e) {
    return [];
  }
};

export const getBulkTaskListMembershipService = async (
  task_list_ids: ListObject["id"][]
) => {
  try {
    const { status, data } = await axios.post(
      `/api/projects/task_list/memberships/`,
      {
        task_list_ids,
      }
    );
    if (status == 200) {
      return data.map((item: any) => ({
        ...item.user,
        membershipId: item.id,
        fullName: item.user.full_name,
        displayName: item.user.display_name,
        accessType: item.access_type_enum,
        taskListId: item.task_list,
      }));
    }
    return [];
  } catch (e) {
    return [];
  }
};

export const addUserToProjectService = async (
  payload: AddUserToProjectParams
) => {
  const body = {
    project: payload.project,
    user: payload.user,
    access_type_enum: payload.accessType,
    project_role: payload.projectRole,
  };
  try {
    const response = await axios.post(
      "/api/projects/project_memberships/",
      body
    );
    const { status } = response;
    if (status === 201) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const addUserToTaskListService = async ({
  taskListId,
  userId,
}: {
  taskListId: string;
  userId: string;
}) => {
  const body = {
    task_list: taskListId,
    user: userId,
    access_type_enum: "member",
    show_list_in_sidebar_yn: true,
  };
  try {
    const response = await axios.post(
      "/api/projects/task_list_memberships/",
      body
    );
    const { status } = response;
    if (status === 201) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const updateUserofProjectService = async (
  payload: AddUserToProjectParams
) => {
  const body = {
    project: payload.project,
    user: payload.user,
    access_type_enum: payload.accessType,
    project_role: payload.projectRole,
  };
  try {
    const response = await axios.put(
      `/api/projects/project_memberships/${payload.membershipId}/`,
      body
    );
    const { status } = response;
    if (status === 200) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const updateUserofTaskListService = async ({
  membershipId,
  show_list_in_sidebar_yn,
}: {
  membershipId: string;
  show_list_in_sidebar_yn: boolean;
}) => {
  const body = {
    show_list_in_sidebar_yn,
  };
  try {
    const response = await axios.patch(
      `/api/projects/task_list_memberships/${membershipId}/`,
      body
    );
    const { status } = response;
    if (status === 200) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const deleteUserFromProjectService = async (membershipId: string) => {
  try {
    const response = await axios.delete(
      `/api/projects/project_memberships/${membershipId}/`
    );
    const { status } = response;
    if (status === 200 || status === 204) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const deleteUserFromTaskListService = async (membershipId: string) => {
  try {
    const response = await axios.delete(
      `/api/projects/task_list_memberships/${membershipId}/`
    );
    const { status } = response;
    if (status === 200 || status === 204) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};
