import React from "react";

import {
  personalTasksProjectObject,
  projectsDefaultColorInMenu,
} from "../../../constants/constants";

/**
 * Default Display
 * ProjectInitial displays the first character in in the title of the passed project prop
 * It has a background color value of of the passed project prop
 */

const ProjectInitial: React.FC<ProjectInitialProps> = ({
  project,
  size = 24,
  isMenu = false,
  isActive = false,
  isCircle = false,
}) => {
  const { color, title } = project || {};
  const { value: backgroundColor } = color || {};
  const initial = title.charAt(0).toUpperCase();

  return (
    <div
      className="ProjectInitial"
      style={{
        backgroundColor:
          isMenu && personalTasksProjectObject.title === title
            ? projectsDefaultColorInMenu
            : backgroundColor,
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: isCircle ? "50%" : 0,
      }}
    >
      <span className="ProjectInitial__Text">{initial}</span>
    </div>
  );
};

export default ProjectInitial;
