import React from "react";
import { Input, Popover } from "antd";
import cn from "classnames";
import { SizeType } from "antd/lib/config-provider/SizeContext";

import useDebounce from "../../../hooks/useDebounce.hook";

import CreateNewTaskButton from "../CreateNewTaskButton/CreateNewTaskButton";
import {
  CloseIcon,
  LoadingIcon,
} from "../../CustomIcons/CustomIcons.component";
import SearchTaskCardList from "../../Tasks/SearchTaskCardList/SearchTaskCardList";

import { getTasksListService } from "../../../services/tasks.service";
import useScrollBottomCallbackHook from "../../../hooks/useScrollBottomCallback.hook";

export interface TaskSearchBarContext {
  activitiesSummaryType?: ActivitiesSummaryType;
  isMerge?: boolean;
  handleTaskSelect?: (task: TaskJoinedToUserAndScheduledObject) => void;
  panelType: PanelType;
  searchText: string;
  taskPage?: TaskPage;
}
export const TaskSearchBarContext = React.createContext<TaskSearchBarContext>(
  {} as TaskSearchBarContext
);

/**
 * TaskSearchBar is used for searching or creating new tasks
 *
 * Default Display
 * Input for search text or task title for creation
 *
 * Conditional Display
 * CreateNewTaskButton is displayed when searchText is not blank string
 * CloseIcon is displayed when searchText is not blank string
 * SearchTaskCardList displayed when searchText is not blank string
 */
export interface TaskSearchBarProps extends MyDeskTaskPanelProps {
  activitiesSummaryType?: ActivitiesSummaryType;
  handleSearchClose?: Function;
  handleTaskSelect?: (task: TaskJoinedToUserAndScheduledObject) => void;
  isMerge?: boolean;
  panelType: PanelType;
}
const TaskSearchBar: React.FC<TaskSearchBarProps> = ({
  activitiesSummaryType,
  handleSearchClose,
  handleTaskSelect,
  isMerge = false,
  panelType,
  taskPage,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [searchText, setSearchText] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [maxCount, setMaxCount] = React.useState<number>();
  const [searchResult, setSearchResult] = React.useState<TaskArray>([]);

  const scrollRef = React.useRef(null);

  const debounceSearchText = useDebounce(searchText, 1000);
  const isSearching = debounceSearchText !== "" && searchText !== "";

  const initialLoading = loading && maxCount === undefined;

  const fetchTasksByTitle = React.useCallback(async () => {
    if (!!debounceSearchText) {
      try {
        setLoading(true);
        const response = await getTasksListService({
          search: debounceSearchText,
          page,
          page_size: 10, // page size is static
        });
        if (response.status === 200) {
          setMaxCount(response.data.count as number);
          setSearchResult((c) => [
            ...c,
            ...(response.data.results as TaskArray),
          ]);
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    }
  }, [debounceSearchText, page]);

  const scrollBottomCallback = React.useCallback(() => {
    if (!loading && maxCount !== undefined && searchResult.length < maxCount)
      setPage((c) => c + 1);
  }, [loading, searchResult.length, maxCount]);

  useScrollBottomCallbackHook(scrollBottomCallback, [], scrollRef);

  React.useEffect(() => {
    fetchTasksByTitle();
  }, [fetchTasksByTitle]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchText(value);
  };

  const handleClickClose = () => {
    if (handleSearchClose) handleSearchClose();
    setSearchText("");
  };

  return (
    <TaskSearchBarContext.Provider
      value={{
        activitiesSummaryType,
        handleTaskSelect,
        isMerge,
        panelType,
        searchText,
        taskPage,
      }}
    >
      <div
        className={cn("TaskSearchBar", {
          "TaskSearchBar--Searching": isSearching,
        })}
      >
        <Popover
          content={
            <div
              ref={scrollRef}
              className={cn("TaskSearchBar__Body", {
                "TaskSearchBar__Body--Loading": loading,
              })}
            >
              {(() => {
                if (initialLoading) {
                  return <LoadingIcon />;
                } else if (!initialLoading && !isMerge) {
                  return (
                    <>
                      {/* <SearchTaskCardList
                        filter="today"
                        searchTaskArray={searchResult}
                      /> */}
                      <SearchTaskCardList
                        filter="all"
                        searchTaskArray={searchResult}
                      />
                    </>
                  );
                } else if (!initialLoading && isMerge) {
                  return (
                    <SearchTaskCardList
                      filter="all"
                      searchTaskArray={searchResult}
                    />
                  );
                }
              })()}
              {loading && !!maxCount && <LoadingIcon />}
            </div>
          }
          overlayStyle={{ zIndex: 1000 }}
          getPopupContainer={(trigger: any) => trigger}
          trigger="click"
          placement="bottom"
          visible={isSearching}
        >
          <div className="TaskSearchBar__Header">
            <Input
              value={searchText}
              size={"default" as SizeType}
              placeholder="Search or create a new task"
              className="TaskSearchBar__Header__Input"
              onChange={handleOnChange}
            />
            {isSearching ? (
              <div className="TaskSearchBar__Header__Actions">
                <CreateNewTaskButton
                  panelType={panelType}
                  searchText={searchText}
                  setSearchText={setSearchText}
                />
                <CloseIcon
                  onClick={handleClickClose}
                  className="TaskSearchBar__Header__Actions__Close"
                />
              </div>
            ) : null}
          </div>
        </Popover>
      </div>
    </TaskSearchBarContext.Provider>
  );
};

export default TaskSearchBar;
