import React from "react";
import { Form, Input, Switch } from "antd";

import { InfoCircleOutlinedIcon } from "../../CustomIcons/CustomIcons.component";

const { TextArea } = Input;

export interface ExternalIntegrationFormProps {
  form: any;
  externalTrackerEnabled?: boolean;
  externalTrackerConfig?: string;
  externalCalendarConfig?: string;
  onValuesChange: (changedValues: any, allValues: any) => void;
}
const ExternalIntegrationForm: React.FC<ExternalIntegrationFormProps> = ({
  form,
  externalTrackerEnabled = false,
  externalTrackerConfig = "",
  externalCalendarConfig = "",
  onValuesChange,
}) => {
  return (
    <div className="ExternalIntegrationForm">
      <div className="ExternalIntegrationForm__Title">
        <span>Integration</span>
      </div>
      <Form
        form={form}
        onValuesChange={(changedValues: any, allValues: any) =>
          onValuesChange(changedValues, allValues)
        }
      >
        <Form.Item
          name="externalTracker"
          label="External tracker"
          tooltip={{
            title: `Select if the user is tracking time with\nan external tracker. If enabled,\nconfigure the tracker below.`,
            icon: <InfoCircleOutlinedIcon />,
          }}
        >
          <Switch defaultChecked={externalTrackerEnabled} />
        </Form.Item>
        <Form.Item
          name="externalTrackerConfig"
          label="External tracker config"
          rules={[
            {
              required: externalTrackerEnabled,
              message:
                "Add the external tracker configuration or turn off ‘External Tracker’",
            },
          ]}
        >
          <TextArea defaultValue={externalTrackerConfig} />
        </Form.Item>
        <Form.Item
          name="externalCalendarConfig"
          label="External calendar config"
        >
          <TextArea defaultValue={externalCalendarConfig} />
        </Form.Item>
      </Form>
    </div>
  );
};

export default ExternalIntegrationForm;
