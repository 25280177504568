import React from "react";
import { Input, Form, Select, Switch } from "antd";

import { InfoCircleOutlinedIcon } from "../../CustomIcons/CustomIcons.component";
import TeamPageObserverSelector from "../TeamPageObserverSelector/TeamPageObserverSelector";
import WorkspaceMemberEditEmailModal from "../WorkspaceMemberEditEmailModal/WorkspaceMemberEditEmailModal";

import { memberTypes } from "../../../constants/member.constant";
import { workHoursSources } from "../../../constants/constants";

const { Option } = Select;

export interface WorkspaceMemberAccountFormProps {
  showTitle?: boolean;
  showRequiredFieldsOnly?: boolean;
  showOptionalFieldsOnly?: boolean;
  form: any;
  email?: string;
  userType?: string;
  teamPageOwners?: AccountUser[];
  workHoursSource?: string;
  onValuesChange?: (changedValues: any, allValues: any) => void;
  readOnly?: boolean;
}

const WorkspaceMemberAccountForm: React.FC<WorkspaceMemberAccountFormProps> = ({
  showTitle = false,
  showRequiredFieldsOnly = false,
  showOptionalFieldsOnly = false,
  form,
  email = "",
  userType = "",
  teamPageOwners = [],
  workHoursSource,
  onValuesChange,
  readOnly = false,
}) => {
  const handleChangeSelectedMembers = (selectedMembers: AccountUser[]) => {};
  const [showModal, setShowModal] = React.useState(false);

  const handleConfirmEditEmail = (email: string) => {
    /**
     * TODO 13.16.3: Add endpoint call to edit email
     */
    setShowModal(false);
  };

  return (
    <div className="WorkspaceMemberAccountForm">
      {showTitle && (
        <div className="WorkspaceMemberAccountForm__Title">
          <span>Account</span>
        </div>
      )}

      <fieldset disabled={readOnly}>
        <Form
          form={form}
          onValuesChange={(changedValues: any, allValues: any) => {
            if (onValuesChange) onValuesChange(changedValues, allValues);
          }}
        >
          {readOnly && (
            <>
              <WorkspaceMemberEditEmailModal
                confirmCallback={handleConfirmEditEmail}
                cancelCallback={() => setShowModal(false)}
                email={email}
                visible={showModal}
              />
              <div className="SampleClass" onClick={() => setShowModal(true)}>
                Edit
              </div>
            </>
          )}
          {(showRequiredFieldsOnly || !showOptionalFieldsOnly) && (
            <>
              <Form.Item
                name="email"
                label="Email"
                initialValue={email}
                rules={[{ required: true, message: "Email is required." }]}
              >
                {readOnly ? <span>example@example.com</span> : <Input />}
              </Form.Item>
              <Form.Item
                name="userType"
                label="User Type"
                initialValue={userType}
                rules={[{ required: true, message: "User Type is required." }]}
              >
                <Select
                  placeholder="Select user type"
                  defaultValue={userType}
                  allowClear
                >
                  {Object.entries(memberTypes).map(([_, value]) => (
                    <Option key={value} value={value}>
                      {value}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="teamPageOwners"
                className="WorkspaceMemberAccountForm__TeamPages"
                label="Team Pages"
                tooltip={{
                  title: `Team page owners can see\nmembers’ complete worktime and\nactivities information.`,
                  icon: <InfoCircleOutlinedIcon />,
                }}
                rules={[
                  {
                    required: true,
                    message: "Team page owner must be selected",
                  },
                ]}
                trigger="onChangeSelectedMembers"
                valuePropName="selectedMembers"
              >
                <TeamPageObserverSelector
                  onChangeSelectedMembers={handleChangeSelectedMembers}
                  selectedMembers={teamPageOwners}
                />
              </Form.Item>
            </>
          )}

          {(!showRequiredFieldsOnly || showOptionalFieldsOnly) && (
            <>
              <Form.Item
                name="workHoursSource"
                label="Work hours source"
                tooltip={{
                  title: `Select which source is used to\ncalculate work hours.`,
                  icon: <InfoCircleOutlinedIcon />,
                }}
              >
                <Select
                  placeholder="Select work hours sources"
                  defaultValue={workHoursSource}
                  allowClear
                >
                  {Object.entries(workHoursSources).map(([_, value]) => (
                    <Option key={value} value={value}>
                      {value}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}
        </Form>
      </fieldset>
    </div>
  );
};

export default WorkspaceMemberAccountForm;
