import { Dispatch } from "redux";

import {
  getProjectsColorsListService,
  createColorService,
} from "../services/projects-colors.service";
import { dispatchError } from "../utils/error.util";

export const requestFetchProjectColors = () => ({
  type: "REQUEST_FETCH_PROJECT_COLORS",
});
export const requestFetchProjectColorsSuccess = (
  projectsColors: ProjectColorArray
) => ({
  type: "REQUEST_FETCH_PROJECT_COLORS_SUCCESS",
  projectsColors,
});
export const requestFetchProjectColorsFailed = (error: string) => ({
  type: "REQUEST_FETCH_PROJECT_COLORS_FAILED",
  error,
});
export const startPopulateProjectsColors = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(requestFetchProjectColors());
      const response = await getProjectsColorsListService();
      if (response.status === 200) {
        dispatch(requestFetchProjectColorsSuccess(response.data));
      } else {
        const error = "getProjectsColorsListService status is not 200";
        dispatch(requestFetchProjectColorsFailed(error));
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestFetchProjectColorsFailed(error));
      dispatchError({
        e,
        title: "Get project colors error",
      });
    }
  };
};

export const requestCreateProjectColor = () => ({
  type: "REQUEST_CREATE_PROJECT_COLOR",
});
export const requestCreateProjectColorSuccess = (
  projectsColor: ProjectColorObject
) => ({
  type: "REQUEST_CREATE_PROJECT_COLOR_SUCCESS",
  projectsColor,
});
export const requestCreateProjectColorError = (error: string) => ({
  type: "REQUEST_CREATE_PROJECT_COLOR_FAILED",
  error,
});
export const startCreateProjectsColors = (
  payload: Omit<ProjectColorObject, "id">
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(requestCreateProjectColor());
      const response = await createColorService(payload);
      if (response.status === 201) {
        const createdProjectColor = response.data;
        dispatch(requestCreateProjectColorSuccess(createdProjectColor));
        return createdProjectColor;
      } else {
        const error = "createColorService status is not 201";
        dispatch(requestCreateProjectColorError(error));
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestCreateProjectColorError(error));
      dispatchError({
        e,
        title: "Create project color error",
      });
    }
  };
};
