import { Modal as AntModal } from "antd";
import React from "react";
import moment, { Moment } from "moment";

import ShiftTimePicker from "./ShiftTimePicker";
import Modal from "../../General/Modal/Modal";

const format = "HH:mm";
const defaultTime = moment("00:00", format);
const AddShiftModal: React.FC<AddShiftModalProps> = ({
  open,
  onOk,
  onClose,
}) => {
  const [start, setStart] = React.useState<Moment>(defaultTime);
  const [end, setEnd] = React.useState<Moment>(defaultTime);
  const [loading, setLoading] = React.useState(false);
  const endTimeRef = React.useRef<any>(null);

  const handleOk = async () => {
    if (end.isSameOrBefore(start)) {
      AntModal.confirm({
        title: "Warning",
        content:
          "Are you sure you want to create a shift which ends after midnight?",
        onOk: async () => {
          setLoading(true);
          await onOk(start, end);
          setLoading(false);
        },
        onCancel: () => {
          if (endTimeRef && endTimeRef.current) {
            endTimeRef.current.focus();
          }
        },
      });
    } else {
      setLoading(true);
      await onOk(start, end);
      setLoading(false);
    }
  };

  const handleClose = () => {
    setStart(defaultTime);
    setEnd(defaultTime);
    onClose();
  };

  return (
    <Modal
      className="AddShiftModal"
      title="Add Manual Shift"
      centered={true}
      visible={open}
      cancelText="Close"
      okText="Next"
      onOk={handleOk}
      onCancel={handleClose}
      width={263}
      destroyOnClose={true}
      confirmLoading={loading}
    >
      <ShiftTimePicker
        start={start}
        end={end}
        endRef={endTimeRef}
        onStartChange={(time) => setStart(time)}
        onEndChange={(time) => setEnd(time)}
      />
    </Modal>
  );
};

export default AddShiftModal;
