import React from "react";
import moment from "moment";

import { getTotalDuration } from "../../../../utils/time-conversion.util";

interface CheckoutTaskCollapsePanelFooterProps {
  checkoutTaskTotalTime: number;
}
const CheckoutTaskCollapsePanelFooter: React.FC<CheckoutTaskCollapsePanelFooterProps> = ({
  checkoutTaskTotalTime,
}) => {
  return (
    <div className="CheckoutTaskCollapsePanelFooter">
      <span className="CheckoutTaskCollapsePanelFooter__TotalTime">
        {/*
          * Temporarily removed until checkout task time has been implemented
        <span>Total:</span>
        <span>
          {getTotalDuration(moment.duration(checkoutTaskTotalTime, "seconds"))}
        </span>
        */}
      </span>
    </div>
  );
};

export default CheckoutTaskCollapsePanelFooter;
