import React from "react";
import { Input } from "antd";
import cn from "classnames";
import { Popover } from "antd";

import AvatarGroup from "../../AvatarGroup/AvatarGroup.component";
import Button from "../../Button/Button";
import CustomAvatar from "../../CustomAvatar/CustomAvatar";

import { getUsersByWorkspaceService } from "../../../services/users.service";
import { AddIcon, CloseIcon } from "../../CustomIcons/CustomIcons.component";
import { getInitials } from "../../../utils/string.util";

export interface TeamPageObserverSelectorProps {
  onChangeSelectedMembers: (selectedMembers: AccountUser[]) => void;
  selectedMembers: AccountUser[];
}
const TeamPageObserverSelector: React.FC<TeamPageObserverSelectorProps> = ({
  onChangeSelectedMembers,
  selectedMembers = [],
}) => {
  const [searchText, setSearchText] = React.useState("");
  const [workspaceMembers, setWorkspaceMembers] = React.useState<AccountUser[]>(
    []
  );
  const [searchFilteredMembers, setSearchFilteredMembers] = React.useState<
    AccountUser[]
  >([]);

  const filteredMembers = searchFilteredMembers.filter(
    (member) => selectedMembers.indexOf(member) == -1
  );

  const getWorkspaceMembers = React.useCallback(async () => {
    try {
      const response = await getUsersByWorkspaceService();
      if (response.status === 200) {
        setWorkspaceMembers(response.data);
      }
    } catch (e) {
      const error = (e as Error).message;
    }
  }, []);

  const addMember = (member: AccountUser) => {
    onChangeSelectedMembers([...selectedMembers, member]);
  };

  const removeMember = (member: AccountUser) => {
    const clone = [...selectedMembers];
    const index = clone.indexOf(member);
    if (index !== -1) clone.splice(index, 1);
    onChangeSelectedMembers(clone);
  };

  React.useEffect(() => {
    getWorkspaceMembers();
  }, [getWorkspaceMembers]);

  React.useEffect(() => {
    if (!!searchText) {
      setSearchFilteredMembers(
        workspaceMembers.filter(({ display_name = "" }) =>
          display_name.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    } else if (!searchText) {
      setSearchFilteredMembers(workspaceMembers);
    }
  }, [workspaceMembers, searchText]);

  const avatars = selectedMembers.map(({ avatar }) => (avatar ? avatar : ""));
  const avatarsFallBackText = selectedMembers.map(({ display_name }) =>
    getInitials(display_name)
  );

  const popoverContent = (
    <div className="TeamPageObserverSelector">
      <Input
        className="TeamPageObserverSelector__Search"
        placeholder="Search by member name"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setSearchText(e.target.value)
        }
      />
      <div className="TeamPageObserverSelector__Selected">
        <div className="TeamPageObserverSelector__Selected__Header">
          <span>Observed by ({selectedMembers.length})</span>
        </div>
        <div
          className={cn("TeamPageObserverSelector__Selected__Body", {
            "TeamPageObserverSelector__Selected__Body--Empty":
              selectedMembers.length === 0,
          })}
        >
          {selectedMembers.length > 0 ? (
            selectedMembers.map((member, index) => {
              const { avatar, current_status, display_name } = member;
              const { status_enum_identifier } = current_status || {};
              return (
                <div
                  key={index}
                  className="TeamPageObserverSelector__Selected__Item"
                >
                  <div className="TeamPageObserverSelector__Selected__Item__UserDetails">
                    <CustomAvatar
                      size={24}
                      src={avatar}
                      work_status={status_enum_identifier}
                    />
                    <span>{display_name}</span>
                  </div>
                  <Button
                    className="TeamPageObserverSelector__Selected__Item__RemoveBtn"
                    onClick={() => removeMember(member)}
                  >
                    <CloseIcon />
                  </Button>
                </div>
              );
            })
          ) : (
            <span>No members yet.</span>
          )}
        </div>
      </div>
      <div className="TeamPageObserverSelector__Members">
        <div className="TeamPageObserverSelector__Members__Header">
          <span>Workspace members ({filteredMembers.length})</span>
        </div>
        <div
          className={cn("TeamPageObserverSelector__Members__Body", {
            "TeamPageObserverSelector__Members__Body--Empty":
              filteredMembers.length === 0,
          })}
        >
          {filteredMembers.length > 0 ? (
            filteredMembers.map((member, index) => {
              const { avatar, current_status, display_name } = member;
              const { status_enum_identifier } = current_status || {};
              return (
                <div
                  key={index}
                  className="TeamPageObserverSelector__Members__Item"
                >
                  <div className="TeamPageObserverSelector__Members__Item__UserDetails">
                    <CustomAvatar
                      size={24}
                      src={avatar}
                      work_status={status_enum_identifier}
                    />
                    <span>{display_name}</span>
                  </div>
                  <Button
                    className="TeamPageObserverSelector__Members__Item__AddBtn"
                    onClick={() => addMember(member)}
                  >
                    <AddIcon />
                    <span>Add</span>
                  </Button>
                </div>
              );
            })
          ) : (
            <span>No members.</span>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <Popover
      content={popoverContent}
      trigger="click"
      overlayClassName="WorkspaceMemberAccountForm__TeamPages__Popover"
      placement="top"
    >
      {selectedMembers.length > 0 ? (
        <AvatarGroup
          avatars={avatars}
          avatarsFallBackText={avatarsFallBackText}
          size={20}
        />
      ) : (
        <span className="">Select team page owner</span>
      )}
    </Popover>
  );
};

export default TeamPageObserverSelector;
