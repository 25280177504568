import React from "react";
import { Dropdown, Menu, Tooltip, Typography } from "antd";
import { Feature } from "flagged";

import useEllipsisOnOverflowHook from "../../../hooks/useEllipsisOnOverflow.hook";

import {
  CollapseIcon,
  ExpandIcon,
  DraftIcon,
  ShowArchiveIcon,
  MoreActionsIcon,
} from "../../CustomIcons/CustomIcons.component";
import ListMembers from "../ListMembers/ListMembers";
import ProjectInitial from "../../Projects/ProjectInitial/ProjectInitial";
import TaskSearchBar from "../../Tasks/TaskSearchBar/TaskSearchBar";

const { Title } = Typography;

export interface ListPanelHeaderProps {
  list: ListObject;
  projectOfList: ProjectObject;
  onCollapse: Function;
  onExpand: Function;
  onManageSections: () => void;
  onShowArchive: () => void;
  showArchive: boolean;
}
const ListPanelHeader: React.FC<ListPanelHeaderProps> = ({
  list,
  projectOfList,
  onCollapse,
  onExpand,
  onManageSections,
  onShowArchive,
  showArchive,
}) => {
  const { title: projectTitle } = projectOfList;
  const { title: listTitle } = list;

  const [projectTitleRef, showProjectTitleEllipsis] = useEllipsisOnOverflowHook(
    projectTitle
  );
  const [listTitleRef, showListTitleEllipsis] = useEllipsisOnOverflowHook(
    listTitle
  );

  const menuItems = (
    <>
      <Menu.Item
        key="manageSection"
        icon={<DraftIcon />}
        onClick={onManageSections}
      >
        Manage sections
      </Menu.Item>
      {!showArchive && (
        <Menu.Item
          key="showArchive"
          icon={<ShowArchiveIcon />}
          onClick={onShowArchive}
        >
          Show Archive
        </Menu.Item>
      )}
    </>
  );

  return (
    <div className="ListPanelHeader">
      <div className="ListPanelHeader__Container">
        <div className="ListPanelHeader__Container__Wrapper">
          <Title
            level={4}
            className="ListPanelHeader__Container__Wrapper__Title"
          >
            <ProjectInitial project={projectOfList} />
            <Tooltip
              title={showProjectTitleEllipsis ? projectTitle : undefined}
            >
              <span
                className="ListPanelHeader__Container__Wrapper__Title__Text"
                ref={projectTitleRef}
              >
                {projectTitle}
              </span>
            </Tooltip>
          </Title>
        </div>
        <div className="ListPanelHeader__Container__Wrapper">
          <Title
            level={4}
            className="ListPanelHeader__Container__Wrapper__Title"
          >
            <div className="ListPanelHeader__Container__Wrapper__Title__Corner">
              <div></div>
            </div>
            <Tooltip title={showListTitleEllipsis ? listTitle : undefined}>
              <span
                className="ListPanelHeader__Container__Wrapper__Title__Text"
                ref={listTitleRef}
              >
                {listTitle}
              </span>
            </Tooltip>
          </Title>

          <div className="ListPanelHeader__Container__Wrapper__ActionsContainer">
            <ListMembers list={list} />
            <div className="ListPanelHeader__Container__Wrapper__ActionsContainer__Action">
              <CollapseIcon onClick={onCollapse} />
            </div>
            <div className="ListPanelHeader__Container__Wrapper__ActionsContainer__Action">
              <ExpandIcon onClick={onExpand} />
            </div>
            <Feature name="under_development">
              <Dropdown
                overlay={
                  <Menu className="ManageSectionsModal__Menu">{menuItems}</Menu>
                }
                placement="bottomRight"
                trigger={["click"]}
              >
                <div className="ListPanelHeader__Container__Wrapper__ActionsContainer__Action">
                  <MoreActionsIcon className="ManageSectionsModal__MoreIcon" />
                </div>
              </Dropdown>
            </Feature>
          </div>
        </div>
        <TaskSearchBar panelType="list" />
      </div>
    </div>
  );
};

export default ListPanelHeader;
