import { AxiosResponse } from "axios";

import axios from "../apis/django.instance";

export interface GetTaskTimeServiceData {
  user?: UserDetailsObject["id"];
  work_day?: WorkDayObject["id"];
  task?: TaskObject["id"];
  from_date?: string;
  to_date?: string;
  project?: ProjectObject["id"];
  ordering?: string;
}
export type GetTaskTimeService = (
  params?: GetTaskTimeServiceData
) => Promise<AxiosResponse<TaskTimeArray>>;
export const getTaskTimeService: GetTaskTimeService = (params) => {
  if (params) {
    return axios.get("/api/work/task_time/", { params }); // must be in this format in order to mock implementation in unit test
  }
  return axios.get("/api/work/task_time/"); // must be in this format in order to mock implementation in unit test
};

export const addTaskTimeService: AddTaskTimeService = (data) => {
  return axios.post("/api/work/task_time/", data); // must be in this format in order to mock implementation in unit test
};

export const updateTaskTimeService: UpdateTaskTimeService = (data) => {
  const { id } = data;
  return axios.put(`/api/work/task_time/${id}/`, data); // must be in this format in order to mock implementation in unit test
};

export interface GetUserTaskTimeTableDataServiceData {
  fromDate: string;
  timeTrackerId: ExternalTimeTrackerObject["id"];
  toDate: string;
  userId: string;
}
export type GetUserTaskTimeTableDataService = (
  data: GetUserTaskTimeTableDataServiceData
) => Promise<AxiosResponse<UserTaskTimeTableDataResponse>>;
export const getUserTaskTimeTableDataService: GetUserTaskTimeTableDataService = ({
  fromDate,
  timeTrackerId,
  toDate,
  userId,
}) => {
  const params = {
    from_date: fromDate,
    to_date: toDate,
    timetracker_id: timeTrackerId,
  };
  return axios.get(`/api/work/user_task_time/${userId}/`, { params });
};

export interface GetAllocatedTimeServiceData {
  user_id: string;
  from_date: string;
  to_date: string;
}
export interface GetAllocatedTimeServiceResponse {
  allocated_time: {
    project: ProjectObject["id"];
    tasks: {
      task: TaskObject["id"];
      total_seconds: number;
    }[];
    total_seconds: number;
  }[];
  workday_id: WorkDayObject["id"][];
}
export type GetAllocatedTimeService = (
  data?: GetAllocatedTimeServiceData
) => Promise<AxiosResponse<GetAllocatedTimeServiceResponse>>;
export const getAllocatedTimeService: GetAllocatedTimeService = (data) => {
  const { user_id, from_date, to_date } = data || {};
  const params = {
    user_id,
    from_date, // format should be YYYY-MM-DD
    to_date, // format should be YYYY-MM-DD
  };
  return axios.get(`/api/work/allocated_time/`, { params });
};

export const getWorkShiftsService = async (
  id: string
): Promise<WorkShiftSummary[]> => {
  const response = await axios.get(
    `/api/work/work_day/${id}/workshifts_summary/`
  );
  if (response.status === 200) {
    return response.data;
  }
  return [];
};

export const syncWorkShiftsService = async (
  fromDate: string,
  toDate: string
) => {
  await axios.post("/api/work/sync_time_tracked_externally_records/", {
    from_date: fromDate,
    to_date: toDate,
    notify_user: true,
  });
};
