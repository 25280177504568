import React from "react";
import { Draggable } from "react-beautiful-dnd";
import cn from "classnames";

export interface DraggableItemProps {
  draggableId: string;
  index: number;
  children: React.ReactNode;
}
const DraggableItem: React.FC<DraggableItemProps> = ({
  draggableId,
  index,
  children,
}) => {
  return (
    <Draggable key={index} draggableId={draggableId} index={index}>
      {(provided, snapshot) => {
        return (
          <div
            className={cn("DraggableItem", {
              "DraggableItem--IsDragging": snapshot.isDragging,
            })}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={provided.draggableProps.style}
          >
            {children}
          </div>
        );
      }}
    </Draggable>
  );
};

export default DraggableItem;
