import { store } from "../store/configureStore";

export const selectListsInProject = (
  projectObject?: ProjectObject,
  lists?: ListArray
) => {
  if (!projectObject) return [];
  const userDetails: AccountUser = store.getState().userDetails.data;
  const listArray: ListArray = lists || store.getState().lists.data;
  return listArray.filter(({ parent_project, parent_user }) => {
    const belongsToProject = parent_project === projectObject.id;
    const belongsToUser =
      parent_project === null &&
      belongsToProject === null &&
      parent_user === userDetails.id;
    return belongsToProject || belongsToUser;
  });
};

export const selectListByTitle = (title: ListObject["title"]) => {
  const listArray: ListArray = store.getState().lists.data;
  return listArray.find(({ title: listTitle }) => listTitle === title);
};

export const selectListById = (id: ListObject["id"]) => {
  const listArray: ListArray = store.getState().lists.data;
  return listArray.find(({ id: listId }) => listId === id);
};

export const selectListOfTask = ({
  section: taskSectionObject,
}: TaskJoinedToUserAndScheduledObject) => {
  const { id: taskSectionId } = taskSectionObject;
  const listArray: ListArray = store.getState().lists.data;
  const sectionArray: SectionArray = store.getState().sections.data;

  return sectionArray
    .flatMap(({ id: sectionId, parent_list: sectionParentList }) => {
      const taskBelongsToSection = sectionId === taskSectionId;
      return listArray.filter(({ id: listId }) => {
        const sectionBelongsToList = listId === sectionParentList;
        return taskBelongsToSection && sectionBelongsToList;
      });
    })
    .filter((item: ListObject | undefined) => item)[0];
};
