import React from "react";
import { Dispatch } from "redux";
import { notification } from "antd";

import { store } from "../store/configureStore";

import {
  CloseIcon,
  UndoIcon,
} from "../modules/CustomIcons/CustomIcons.component";

import {
  getTasksListService,
  getJoinTaskToUserListService,
  getScheduleTaskListService,
  addTaskRecordService,
  partialUpdateJoinTaskToUserRecordService,
  updateJoinTaskToUserRecordService,
  addScheduleTaskRecordService,
  updateScheduleTaskRecordService,
  createJoinTaskToUserRecordService,
  partialUpdateTaskRecordService,
  partialUpdateScheduleTaskRecordService,
  deleteScheduleTaskRecordService,
  addTaskToWorkDayService,
  CreateTaskRecordServiceData,
  AddRemoveTaskToFromWorkDayServiceData,
  removeTaskFromWorkDayService,
} from "../services/tasks.service";
import { dispatchError } from "../utils/error.util";

/**
 * Tasks
 */
export const requestFetchTasks = () => ({
  type: "REQUEST_FETCH_TASKS",
});
export const requestFetchTasksSuccess = (
  tasks: TaskJoinedToUserAndScheduledObject[]
) => ({
  type: "REQUEST_FETCH_TASKS_SUCCESS",
  tasks,
});
export const requestFetchTasksFailed = (error: string) => ({
  type: "REQUEST_FETCH_TASKS_FAILED",
  error,
});
export const startPopulateTasks = () => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(requestFetchTasks());
      const response = await getTasksListService();
      if (response.status === 200) {
        dispatch(requestFetchTasksSuccess(response.data.results));
      } else {
        throw new Error("getTasksListService status is not 200");
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestFetchTasksFailed(error));
      dispatchError({
        e,
        title: "Get tasks error",
      });
    }
  };
};

/**
 * Join Task To User
 */
export const startPopulateUserTasks = () => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch({
        type: "REQUEST_FETCH_USER_TASKS",
      });
      const response = await getJoinTaskToUserListService({
        current_user: true,
      });
      if (response.status === 200) {
        dispatch({
          type: "REQUEST_FETCH_USER_TASKS_SUCCESS",
          userTasks: response.data.map((item: any) => {
            const { next_shift_yn, starred_yn, today_yn, priority } = item;
            return {
              ...item,
              next_shift_yn: {
                loading: false,
                value: next_shift_yn,
              },
              starred_yn: {
                loading: false,
                value: starred_yn,
              },
              today_yn: {
                loading: false,
                value: today_yn,
              },
              priority: {
                loading: false,
                value: priority,
              },
            };
          }),
        });
      } else {
        throw new Error("getJoinTaskToUserListService status is not 200");
      }
    } catch (e) {
      const error = (e as Error).message;

      dispatch({
        type: "REQUEST_FETCH_USER_TASKS_FAILED",
        error,
      });
      dispatchError({
        e,
        title: "Get user tasks error",
      });
    }
  };
};

/**
 * Scheduled Tasks
 */
export const requestFetchScheduledTasks = () => ({
  type: "REQUEST_FETCH_SCHEDULED_TASKS",
});
export const requestFetchScheduledTasksSuccess = (
  scheduledTasks: ScheduleTaskArray
) => ({
  type: "REQUEST_FETCH_SCHEDULED_TASKS_SUCCESS",
  scheduledTasks,
});
export const requestFetchScheduledTasksFailed = (error: string) => ({
  type: "REQUEST_FETCH_SCHEDULED_TASKS_FAILED",
  error,
});
export const startPopulateScheduledTasks = () => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(requestFetchScheduledTasks());
      const response = await getScheduleTaskListService();
      if (response.status === 200) {
        dispatch(requestFetchScheduledTasksSuccess(response.data));
      } else {
        throw new Error("getScheduleTaskListService status is not 200");
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestFetchScheduledTasksFailed(error));
      dispatchError({
        e,
        title: "Get scheduled tasks error",
      });
    }
  };
};

/**
 * Task Actions
 */
export const requestCreateTask = () => ({
  type: "REQUEST_CREATE_TASK",
});
export const requestCreateTaskSuccess = (task: TaskObject) => ({
  type: "REQUEST_CREATE_TASK_SUCCESS",
  task,
});
export const requestCreateTaskFailed = (error: string) => ({
  type: "REQUEST_CREATE_TASK_FAILED",
  error,
});
export const startCreateTask = (
  data: CreateTaskRecordServiceData,
  addToCheckoutWorkDay: boolean = false,
  addToCurrentWorkDay: boolean = false,
  checkoutWorkDayId?: WorkDayObject["id"]
) => {
  return async (dispatch: Dispatch<any>) => {
    const {
      userDetails: {
        data: { id: userDetailsId },
      },
      currentWorkDay: {
        data: { id: currentWorkDayId },
      },
    } = store.getState();

    try {
      dispatch(requestCreateTask());

      const addTaskRecordServiceResponse = await addTaskRecordService(data);

      if (addTaskRecordServiceResponse.status === 201) {
        // success dispatch is located in web socket synchronization handling in useInitApp.hook to prevent duplicate dispatch

        if (addToCheckoutWorkDay || addToCurrentWorkDay) {
          const workDayId = addToCheckoutWorkDay
            ? checkoutWorkDayId
            : currentWorkDayId;
          const payload: AddRemoveTaskToFromWorkDayServiceData = {
            task: addTaskRecordServiceResponse.data.id,
            user: userDetailsId,
            work_day: workDayId,
          };
          const addTaskToWorkDayServiceResponse = await addTaskToWorkDayService(
            payload
          );
        }
      } else {
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestCreateTaskFailed(error));
      dispatchError({
        e,
        title: "Add task record error",
      });
    }
  };
};

export const requestPartialUpdateTask = (
  taskId: TaskObject["id"],
  updatedKeys: (keyof TaskObject)[]
) => ({
  type: "REQUEST_PARTIAL_UPDATE_TASK",
  taskId,
  updatedKeys,
});
export const requestPartialUpdateTaskSuccess = (task: TaskObject) => ({
  type: "REQUEST_PARTIAL_UPDATE_TASK_SUCCESS",
  task,
});
export const requestPartialUpdateTaskFailed = (
  taskId: TaskObject["id"],
  error: string
) => ({
  type: "REQUEST_PARTIAL_UPDATE_TASK_FAILED",
  taskId,
  error,
});
export const startPartialUpdateTask = (data: PartialUpdateTaskServiceData) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(
        requestPartialUpdateTask(
          data.id,
          Object.keys(data) as (keyof TaskObject)[]
        )
      );
      const response = await partialUpdateTaskRecordService(data);
      if (response.status === 200) {
        // success dispatch is located in web socket synchronization handling in useInitApp.hook to prevent duplicate dispatch
      } else {
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestPartialUpdateTaskFailed(data.id, error));
      dispatchError({
        e,
        title: "Partial update task error",
      });
    }
  };
};

/**
 * Join Task To User (Previously Personal Task) Actions
 */
export const requestCreateUserTask = () => ({
  type: "REQUEST_CREATE_USER_TASK",
});
export const requestCreateUserTaskSuccess = (
  userTask: JoinTaskToUserObject
) => ({
  type: "REQUEST_CREATE_USER_TASK_SUCCESS",
  userTask,
});
export const requestCreateUserTaskFailed = (error: string) => ({
  type: "REQUEST_CREATE_USER_TASK_FAILED",
  error,
});
export const startCreateJoinTaskToUserRecord = (
  data: CreateJoinTaskToUserRecordServiceData
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const {
        currentWorkDay: {
          data: { id: currentWorkDayId },
        },
      } = store.getState();

      dispatch(requestCreateUserTask());
      const response = await createJoinTaskToUserRecordService(data);
      if (response.status === 201) {
        // success dispatch is located in web socket synchronization handling in useInitApp.hook to prevent duplicate dispatch
        if (data.today_yn) {
          try {
            await addTaskToWorkDayService({
              task: data.task,
              user: data.user,
              work_day: currentWorkDayId,
            });
          } catch (e) {}
        }
      } else {
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestCreateUserTaskFailed(error));
      dispatchError({
        e,
        title: "Add user task error",
      });
    }
  };
};

export const startPartialUpdateJoinTaskToUserRecord = (
  data: PartialUpdateJoinTaskToUserRecordServiceData
) => {
  return async (dispatch: Dispatch<any>) => {
    const {
      currentWorkDay: {
        data: { id: currentWorkDayId },
      },
      userDetails: {
        data: { id: userDetailsId },
      },
    } = store.getState();

    try {
      dispatch({
        type: "REQUEST_PARTIAL_UPDATE_USER_TASK",
        userTask: data,
      });
      const response = await partialUpdateJoinTaskToUserRecordService(data);
      if (response.status === 200) {
        // success dispatch is located in web socket synchronization handling in useInitApp.hook to prevent duplicate dispatch

        /**
         * TODO Bug 20211216-3: Remove call to add/remove task to/from workday after AV finishes refactoring
         * https://meistery.slack.com/archives/C02N7529JUU/p1639711301024900?thread_ts=1639472300.026700&cid=C02N7529JUU
         */
        if (data.today_yn) {
          try {
            await addTaskToWorkDayService({
              task: data.taskId,
              user: userDetailsId,
              work_day: currentWorkDayId,
            });
          } catch (e) {}
        } else if (data.today_yn === false) {
          try {
            await removeTaskFromWorkDayService({
              task: data.taskId,
              user: userDetailsId,
              work_day: currentWorkDayId,
            });
          } catch (e) {}
        }
      } else {
        throw new Error();
      }

      // Allow undo from system feedback message
      let callbackData = { ...data },
        action = "";
      if (data.today_yn === false) {
        callbackData.title = "";
        callbackData.today_yn = true;
        action = "removed from Today";
      } else if (data.starred_yn === false) {
        callbackData.title = "";
        callbackData.starred_yn = true;
        action = "removed from Starred";
      } else if (data.next_shift_yn === false) {
        callbackData.title = "";
        callbackData.next_shift_yn = true;
        action = "removed from Next Shift";
      }
      if (data.title) {
        const callback = () => {
          dispatch(startPartialUpdateJoinTaskToUserRecord(callbackData));
        };

        notification.open({
          className: "Snackbar",
          closeIcon: <CloseIcon />,
          message: data.title,
          description: (
            <>
              <span>{action}</span>
              <UndoIcon className="Snackbar__Undo" onClick={callback} />
            </>
          ),
          placement: "bottomLeft", // placement should be bottom but not supported in AntD 4.16.0, applied override fix in component
          duration: 5,
        });
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch({
        type: "REQUEST_PARTIAL_UPDATE_USER_TASK_FAILED",
        userTaskId: data.id,
        error,
      });
      dispatchError({
        e,
        title: "Update user task error",
      });
    }
  };
};

export const startUpdateJoinTaskToUserRecord = (
  data: UpdateJoinTaskToUserRecordServiceData
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const response = await updateJoinTaskToUserRecordService(data);
      if (response.status === 200) {
        // synchronization is handled in useInitApp hook and useAsyncMyDeskTasks hook
      }
    } catch (e) {
      dispatchError({
        e,
        title: "Update user task error",
      });
    }
  };
};

/**
 * Schedule Task Actions
 */

export const startAddScheduleTaskRecord = (
  data: AddScheduleTaskServiceData
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const response = await addScheduleTaskRecordService(data);
      if (response.status === 201) {
        // TODO synchronize schedule tasks when schedule task feature is re-implemented
      }
    } catch (e) {
      dispatchError({
        e,
        title: "Add schedule task error",
      });
    }
  };
};

export const startUpdateScheduleTaskRecord = (
  data: UpdateScheduleTaskServiceData
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const response = await updateScheduleTaskRecordService(data);
      if (response.status === 200) {
        // TODO synchronize schedule tasks when schedule task feature is re-implemented
      }
    } catch (e) {
      dispatchError({
        e,
        title: "Update schedule task error",
      });
    }
  };
};

export const startPartialUpdateScheduleTaskRecord = (
  data: PartialUpdateScheduleTaskRecordServiceData
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const response = await partialUpdateScheduleTaskRecordService(data);
      if (response.status === 200) {
        // TODO synchronize schedule tasks when schedule task feature is re-implemented
      }
    } catch (e) {
      dispatchError({
        e,
        title: "Partial update schedule task error",
      });
    }
  };
};

export const startDeleteScheduleTaskRecord = (id: ScheduleTaskObject["id"]) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const response = await deleteScheduleTaskRecordService(id);
      if (response.status === 204) {
        // TODO synchronize schedule tasks when schedule task feature is re-implemented
      }
    } catch (e) {
      dispatchError({
        e,
        title: "Delete schedule task error",
      });
    }
  };
};

/**
 * Others
 */

export const startAddJoinTaskToUserAndScheduleTaskRecord = (
  data: CreateJoinTaskToUserRecordServiceData,
  scheduled_datetime: ScheduleTaskObject["scheduled_datetime"]
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const createUserTaskResponse = await createJoinTaskToUserRecordService(
        data
      );
      if (createUserTaskResponse.status === 201) {
        const createScheduleTaskResponse = await addScheduleTaskRecordService({
          join_task_to_user: createUserTaskResponse.data.id,
          scheduled_datetime,
        });
        if (createScheduleTaskResponse.status === 201) {
          // success dispatch is located in web socket synchronization handling in useInitApp.hook to prevent duplicate dispatch
        }
      } else {
        throw new Error();
      }
    } catch (e) {
      dispatchError({
        e,
        title: "Add join task to user and scheduled task error",
      });
    }
  };
};
