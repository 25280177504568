import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import createSagaMiddleware from "redux-saga";

/**
 * Activities Reducer
 */
import activitiesReducer from "../reducers/activities.reducer";
import activityTypesReducer from "../reducers/activity-types.reducer";

import authReducer from "../reducers/auth.reducer";
import {
  checkoutChecksReducer,
  checkoutCheckStatusesReducer,
  checkoutDateStatusReducer,
  checkoutStatusReducer,
} from "../reducers/checkout.reducer";
import errorReducer from "../reducers/error.reducer";
import listsReducer from "../reducers/list.reducer";
import statusReducer from "../reducers/status.reducer";
import statusHistoryReducer from "../reducers/status-history.reducer";
import statusTypesReducer from "../reducers/status-types.reducer";
import {
  thirdPartyTrackingListReducer,
  thirdPartyTrackerReducer,
} from "../reducers/third-party-tracking.reducer";
import {
  trackingTaskReducer,
  trackingStatusReducer,
  lastTrackedTaskReducer,
  TrackingStatus,
} from "../reducers/task-time-tracking.reducer";
import userDetailsReducer from "../reducers/user-details.reducer";

import prioritiesReducer from "../reducers/priorities.reducer";
import ProjectDetailsReducer from "../reducers/project-details.reducer";
import projectsReducer from "../reducers/projects.reducer";
import projectsColorsReducer from "../reducers/projects-colors.reducer";
import sectionsReducer from "../reducers/section.reducer";
import taskDetailsReducer from "../reducers/task-details.reducer";
import {
  tasksReducer,
  scheduledTasksReducer,
  userTasksReducer,
} from "../reducers/tasks.reducer";
import { nextCalendarScheduleReducer } from "../reducers/third-party-calendar.reducer";
import {
  workDaysReducer,
  checkoutWorkDayReducer,
  currentWorkDayReducer,
} from "../reducers/work-day.reducer";
import taskTimeReducer from "../reducers/task-time.reducer";
import { workShiftsReducer } from "../reducers/work-shift.reducer";
import workShiftSchedulesReducer from "../reducers/work-shift-schedule.reducer";
import workTimesReducer from "../reducers/work-times.reducer";
import unreadReducer from "../reducers/unread.reducer";
import officeReducer from "../reducers/office.reducer";
import workspaceUsersReducer from "../reducers/workspace-users.reducer";
import labelsReducer from "../reducers/labels.reducer";
import teammatesReducer from "../reducers/teammates.reducer";

import activityTypes from "../fixtures/activity-types.fixture";
import activities from "../fixtures/activities.fixture";
import notificationsReducer from "../reducers/notifications.reducer";
import priorities from "../fixtures/priorities.fixture";
import { projectsWithPersonalTasks as projects } from "../fixtures/projects.fixture";
import projectsColors from "../fixtures/projects-colors.fixture";
import {
  sortedTasks as tasks,
  joinTaskToUserArray as userTasks,
  scheduleTaskArray as scheduledTasks,
} from "../fixtures/tasks.fixture";
import statusTypes from "../fixtures/status-types.fixture";
import unallocatedTime from "../fixtures/unallocated-time.fixture";
import {
  workDayArray as workDays,
  workDayObject,
} from "../fixtures/work-day.fixture";
import taskTime from "../fixtures/task-time.fixture";
import { workShiftArray as workShifts } from "../fixtures/work-shift.fixture";
import workShiftSchedules from "../fixtures/work-shift-schedules.fixture";
import {
  unreadProjects,
  unreadActivities,
} from "../fixtures/unread-activities.fixture";
import { office } from "../fixtures/office.fixture";
import workspaceUsers from "../fixtures/workspace-users.fixture";
import { labels } from "../fixtures/labels.fixture";
import { teammates } from "../fixtures/teammates.fixture";

/**
 * TS fix
 * Error - Property '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' does not exist on type 'Window & typeof globalThis'
 */
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();
const middlewares = [thunk, sagaMiddleware];
const rootReducer = combineReducers({
  // activities.reducer
  activities: activitiesReducer,
  activityTypes: activityTypesReducer,

  // auth.reducer
  auth: authReducer,

  // checkout.reducer
  checkoutChecks: checkoutChecksReducer,
  checkoutDateStatus: checkoutDateStatusReducer,
  checkoutStatus: checkoutStatusReducer,
  checkoutCheckStatuses: checkoutCheckStatusesReducer,

  // error.reducer
  error: errorReducer,

  // list.reducer
  lists: listsReducer,

  // notifications.reducer
  notifications: notificationsReducer,

  // office.reducer
  office: officeReducer,

  // priorities.reducer
  priorities: prioritiesReducer,

  // projects.reducer
  projects: projectsReducer,

  // projects-color.reducer
  projectsColors: projectsColorsReducer,

  // project-details.reducer
  projectDetails: ProjectDetailsReducer,

  // status.reducer
  status: statusReducer,

  // status-history.reducer
  statusHistory: statusHistoryReducer,

  // status-types.reducer
  statusTypes: statusTypesReducer,

  // section.reducer
  sections: sectionsReducer,

  // tasks.reducer
  tasks: tasksReducer,
  scheduledTasks: scheduledTasksReducer,
  userTasks: userTasksReducer,

  // task-details.reducer
  taskDetails: taskDetailsReducer,

  // task-time.reducer
  taskTime: taskTimeReducer,

  // task-time-tracking.reducer
  trackingTask: trackingTaskReducer,
  trackingStatus: trackingStatusReducer,
  lastTrackedTask: lastTrackedTaskReducer,

  // third-party-calendar.reducer
  nextCalendarSchedule: nextCalendarScheduleReducer,

  // third-party-tracking.reducer
  thirdPartyTracker: thirdPartyTrackerReducer,
  thirdPartyTrackingList: thirdPartyTrackingListReducer,

  // unread.reducer
  unread: unreadReducer,

  // user-details.reducer
  userDetails: userDetailsReducer,

  // work-day.reducer
  checkoutWorkDay: checkoutWorkDayReducer,
  currentWorkDay: currentWorkDayReducer,
  workDays: workDaysReducer,

  // work-shift.reducer
  workShifts: workShiftsReducer,

  // work-shift-schedule.reducer
  workShiftSchedules: workShiftSchedulesReducer,

  // work-times.reducer
  workTimes: workTimesReducer,

  // workspace-users.reducer
  workspaceUsers: workspaceUsersReducer,

  // labels.reducer
  labels: labelsReducer,

  // teammates.reducer
  teammates: teammatesReducer,
});
const projectDetails = projects[0];
const taskDetails = tasks[0];

const initialState: any = {
  activities,
  activityTypes,

  auth: {},
  checkoutChecks: [],
  checkoutDateStatus: [],
  checkoutDateUnallocatedTime: unallocatedTime,
  checkoutStatus: [],
  checkoutWorkDay: workDayObject,
  checkoutCheckStatuses: [],
  currentWorkDay: workDayObject,
  error: null,
  lists: [],
  nextCalendarSchedule: [],
  status: {},
  statusHistory: [],
  statusTypes,
  userDetails: {},
  priorities,
  projectDetails,
  projects,
  projectsColors,
  scheduledTasks,
  systemFeedbackMessage: {},
  sections: [],
  taskDetails,
  tasks,

  // task-time-tracking.reducer
  trackingTask: {},
  trackingStatus: "stopped" as TrackingStatus,
  lastTrackedTask: {},

  // third-party-tracking.reducer
  thirdPartyTracker: {},
  thirdPartyTrackingList: [],

  userTasks,
  workDays,
  taskTime,
  workShifts,
  workShiftSchedules,
  workTimes: [],
  unread: {
    projects: unreadProjects,
    activities: unreadActivities,
  },
  office: {
    office,
  },
  workspaceUsers: {
    list: workspaceUsers,
    loading: false,
  },
  labels,
  teammates,
};

export type RootState = ReturnType<typeof rootReducer>;

const configureStore = () => {
  return createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middlewares))
  );
};

/**
 * Create a new configure store for storybook with initial state values
 */
const storyBookConfigureStore = () => {
  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );
};

const store: any = configureStore();
const storybookStore = storyBookConfigureStore();

export {
  store,
  storybookStore,
  storyBookConfigureStore,
  configureStore as default,
};
