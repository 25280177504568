import React from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { Tooltip } from "antd";

import {
  LoadingIcon,
  TodayIcon,
} from "../../CustomIcons/CustomIcons.component";

import {
  startCreateJoinTaskToUserRecord,
  startPartialUpdateJoinTaskToUserRecord,
} from "../../../actions/tasks.action";
import { selectPrioritiesState } from "../../../reducers/priorities.reducer";
import { selectUserDetailsState } from "../../../reducers/user-details.reducer";
import { getPriorityByTitle } from "../../../utils/priorities.util";

/**
 * Default Display
 * Displays passed join_task_to_user record's [today_yn]
 *
 * Functionality
 * If join_task_to_user_id exists, updates join_task_to_user record's [today_yn] to true or false
 */
const ToggleDoToday: React.FC<ToggleDoTodayProps> = ({
  task,
  search = false,
}) => {
  const dispatch = useDispatch();

  const { data: priorities } = useSelector(selectPrioritiesState);
  const {
    data: { id: userId },
  } = useSelector(selectUserDetailsState);

  const {
    join_task_to_user_id: joinTaskToUserId,
    today_yn,
    id: taskId,
    title: taskTitle,
    loading: taskLoading,
  } = task;

  const { loading: todayYnLoading, value: todayYn } = today_yn || {};
  const isUpdating = taskLoading && todayYnLoading;
  const tooltipText = todayYn ? "Remove from Today" : "Add to Today";
  const nonePriority = getPriorityByTitle(priorities, "None");

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (e) e.stopPropagation();
    if (joinTaskToUserId) {
      // add title if removing today_yn to determine if system feedback message will be displayed
      const data = {
        id: joinTaskToUserId,
        today_yn: !todayYn,
        title: todayYn ? taskTitle : "",
        taskId,
      };
      dispatch(startPartialUpdateJoinTaskToUserRecord(data));
    } else if (nonePriority) {
      dispatch(
        startCreateJoinTaskToUserRecord({
          today_yn: true,
          priority: nonePriority.id,
          task: taskId,
          user: userId,
        })
      );
    } else {
      throw new Error(`nonePriority does not exist`);
    }
  };

  return (
    <div className="ToggleDoToday">
      {search ? (
        <div className="ToggleDoToday__Search" onClick={handleClick}>
          {isUpdating ? (
            <LoadingIcon />
          ) : (
            <TodayIcon
              className={classNames("ToggleDoToday__Icon", {
                "ToggleDoToday__Icon--IsActive": todayYn,
              })}
            />
          )}
          <span className="ToggleDoToday__Text">{tooltipText}</span>
        </div>
      ) : (
        <Tooltip title={tooltipText}>
          {isUpdating ? (
            <LoadingIcon />
          ) : (
            <TodayIcon
              className={classNames("ToggleDoToday__Icon", {
                "ToggleDoToday__Icon--IsActive": todayYn,
              })}
              onClick={handleClick}
            />
          )}
        </Tooltip>
      )}
    </div>
  );
};

export { ToggleDoToday as default };
