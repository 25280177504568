import React from "react";
import { Menu, MenuProps } from "antd";

import useAsyncTasksInSection from "../../../../hooks/useAsyncTasksInSection.hook";

import { SectionSelectionProps } from "../SectionSelection";
import { LoadingIcon } from "../../../CustomIcons/CustomIcons.component";

export interface SectionPositionSelectionDropdownMenuProps {
  handleSelectPosition: SectionSelectionProps["handleSelectPosition"];
  selectedPosition?: SectionSelectionProps["selectedPosition"];
  selectedSection?: SectionSelectionProps["selectedSection"];
}
const SectionPositionSelectionDropdownMenu: React.FC<SectionPositionSelectionDropdownMenuProps> = ({
  handleSelectPosition,
  selectedPosition,
  selectedSection,
}) => {
  const [tasksInSection, tasksInSectionLoading] = useAsyncTasksInSection({
    sectionId: selectedSection ? selectedSection.id : "",
  });

  const positions = React.useMemo(
    () =>
      tasksInSection
        ? Array.from({ length: tasksInSection.length + 1 }, (_, i) => i + 1)
        : [],
    [tasksInSection]
  );

  const handleClick: MenuProps["onClick"] = ({
    item,
    key,
    keyPath,
    domEvent,
  }) => {
    domEvent.stopPropagation();
    handleSelectPosition(key);
  };

  return (
    <Menu
      className="SectionPositionSelectionDropdownMenu"
      disabled={!selectedSection}
      onClick={handleClick}
    >
      {!selectedSection && (
        <Menu.Item disabled>
          <span>No section selected</span>
        </Menu.Item>
      )}

      {!!selectedSection && tasksInSectionLoading && (
        <Menu.Item disabled style={{ textAlign: "center" }}>
          <LoadingIcon />
        </Menu.Item>
      )}

      {!!selectedSection &&
        !!tasksInSection &&
        tasksInSection.length > 0 &&
        positions.map((value) => {
          return (
            <Menu.Item key={value}>
              <span>{value}</span>
            </Menu.Item>
          );
        })}

      {!!selectedSection && !!tasksInSection && tasksInSection.length === 0 && (
        <Menu.Item key={1}>
          <span>{1}</span>
        </Menu.Item>
      )}
    </Menu>
  );
};

export default SectionPositionSelectionDropdownMenu;
