import { Dispatch } from "redux";

import {
  createListRecordService,
  updateWorkDayRecordService,
  fetchTaskListsService,
} from "../services/list.service";
import { dispatchError } from "../utils/error.util";
import { AxiosError } from "axios";

export const requestFetchLists = () => ({
  type: "REQUEST_FETCH_LISTS",
});
export const requestFetchListsSuccess = (lists: ListArray) => ({
  type: "REQUEST_FETCH_LISTS_SUCCESS",
  lists,
});
export const requestFetchListsFailed = (error: string) => ({
  type: "REQUEST_FETCH_LISTS_FAILED",
  error,
});
export const startPopulateLists = () => {
  return async (dispatch: Dispatch) => {
    const lists = await fetchTaskListsService();
    if (!!lists) {
      dispatch(
        requestFetchListsSuccess(
          lists.sort((a, b) => {
            return a.title.localeCompare(b.title);
          })
        )
      );
    } else {
      dispatch(requestFetchListsFailed("Fetch task lists error"));
    }
  };
};

export const requestCreateList = () => ({
  type: "REQUEST_CREATE_LIST",
});
export const requestCreateListSuccess = (list: ListObject) => ({
  type: "REQUEST_CREATE_LIST_SUCCESS",
  list,
});
export const requestCreateListFailed = (error: string) => ({
  type: "REQUEST_CREATE_LIST_FAILED",
  error,
});
export const startCreateListRecord = (data: CreateListRecordServiceData) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(requestCreateList());
      const response = await createListRecordService(data);
      if (response.status === 201) {
        const createdList: ListObject = response.data;
        dispatch(requestCreateListSuccess(createdList));
        return {
          success: true,
          payload: response,
        };
      } else {
        const error = "createListRecordService status is not 201";
        dispatch(requestCreateListFailed(error));
        return { success: false };
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestCreateListFailed(error));
      dispatchError({
        e,
        title: "Create list error",
      });

      const { data } = (e as AxiosError).response || {};
      // list title duplicated
      if (
        data &&
        data.length > 0 &&
        data[0] === "Cannot create the TaskList , Title is duplicated"
      ) {
        return {
          success: false,
          message: "duplicated",
        };
      }
      return {
        success: false,
      };
    }
  };
};

export const requestUpdateList = () => ({
  type: "REQUEST_UPDATE_LIST",
});
export const requestUpdateListSuccess = (list: ListObject) => ({
  type: "REQUEST_UPDATE_LIST_SUCCESS",
  list,
});
export const requestUpdateListFailed = (error: string) => ({
  type: "REQUEST_UPDATE_LIST_FAILED",
  error,
});
export const startUpdateListRecord = (data: UpdateListRecordServiceData) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(requestUpdateList());
      const response = await updateWorkDayRecordService(data);
      if (response.status === 200) {
        const updatedList: ListObject = response.data;
        dispatch(requestUpdateListSuccess(updatedList));
        return {
          success: true,
          payload: response,
        };
      } else {
        const error = "updateWorkDayRecordService status is not 200";
        dispatch(requestUpdateListFailed(error));
        return { success: false };
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestUpdateListFailed(error));
      dispatchError({
        e,
        title: "Update list error",
      });

      const { data } = (e as AxiosError).response || {};
      // list title duplicated
      if (
        data &&
        data.length > 0 &&
        data[0] === "Cannot update the TaskList , Title is duplicated"
      ) {
        return {
          success: false,
          message: "duplicated",
        };
      }
      return {
        success: false,
      };
    }
  };
};

export const toggleListSuccess = (listId: string) => ({
  type: "TOGGLE_LIST_SUCCESS",
  list: {
    id: listId,
  },
});
