import { AxiosResponse } from "axios";

import axios from "../apis/django.instance";
import { labels } from "../fixtures/labels.fixture";

export const getLabelsService = (): Promise<AxiosResponse<Label[]>> => {
  // return axios.get("/api/labels/label_list/");
  const response = {
    status: 200,
    data: labels,
  } as any;
  return new Promise((resolve) => setTimeout(() => resolve(response), 200));
};
