import React from "react";
import { Row, Col } from "antd";
import cn from "classnames";
import {
  ArrowDownIcon,
  ArrowRightIcon,
} from "../../CustomIcons/CustomIcons.component";

/**
 * TaskHeader contains the list name, section name, and activities count for this task
 * It can be toggled when use click the arrow icon.
 * Also, it can be marked as read when user click mark button.
 */
const TaskHeader = ({
  open = false,
  listId,
  listName,
  sectionId,
  sectionName,
  taskName,
  count,
  onToggle,
  onMarkAsRead,
  onNavigate,
}: TaskHeaderProps) => {
  const handleMarkAsRead = (e: any) => {
    if (e) {
      e.stopPropagation();
    }
    if (onMarkAsRead) {
      onMarkAsRead();
    }
  };
  const handleCount = (e: any) => {
    if (e) {
      e.stopPropagation();
    }
  };
  const handleListNameClick = (e: any) => {
    if (e) {
      e.stopPropagation();
    }
    if (onNavigate) {
      onNavigate("list", {
        listId,
        listName,
      });
    }
  };
  const handleSectionNameClick = (e: any) => {
    if (e) {
      e.stopPropagation();
    }
    if (onNavigate) {
      onNavigate("section", {
        sectionId,
        sectionName,
      });
    }
  };
  return (
    <Row
      className="TaskHeader"
      justify="space-between"
      align="middle"
      onClick={onToggle}
    >
      <Col className="TaskHeader__content">
        <Row align="middle">
          <span className="TaskHeader__title">{taskName}</span>
          <ArrowDownIcon
            className={cn("TaskHeader__arrow", { TaskHeader__rotate: !open })}
          />
        </Row>
        <Row align="middle">
          <span className="TaskHeader__category" onClick={handleListNameClick}>
            {listName}
          </span>
          <ArrowRightIcon className="TaskHeader__category TaskHeader__category-arrow" />
          <span
            className="TaskHeader__category"
            onClick={handleSectionNameClick}
          >
            {sectionName}
          </span>
        </Row>
      </Col>
      <Col>
        <Row align="middle">
          <div className="TaskHeader__badge" onClick={handleCount}>
            {count}
          </div>
          <div className="TaskHeader__mark" onClick={handleMarkAsRead}>
            Mark as read
          </div>
        </Row>
      </Col>
    </Row>
  );
};

export default TaskHeader;
