import React from "react";
import { flatten } from "lodash";

import { getTasksListService } from "../services/tasks.service";
import useConsolidateTask from "./useConsolidateTask.hook";

import { selectProjectOfTask } from "../selectors/project.selector";
import { asyncFetchTaskObject } from "../utils/tasks.util";

import ws from "../sockets/websockets";

export default function useAsyncTasksInProjectArray(
  projectArrayIds: (ProjectObject["id"] | null | undefined)[]
) {
  const [
    tasksInProjectArray,
    setTasksInProjectArray,
  ] = React.useState<TaskArray>();

  const [consolidatedTasks, consolidatedTasksLoading] = useConsolidateTask(
    tasksInProjectArray
  );

  const isLoading =
    tasksInProjectArray === undefined || consolidatedTasksLoading;

  const fetchTasksInProjectArray = React.useCallback(async () => {
    if (projectArrayIds.length > 0) {
      const tasksInProjectArray = (await Promise.all(
        projectArrayIds.map(async (projectId) => {
          if (projectId) {
            try {
              const response = await getTasksListService({
                parent_project: projectId,
              });
              if (response.status === 200) {
                return response.data.results as TaskArray;
              } else {
                throw new Error();
              }
            } catch (e) {}
          }
        })
      ).then(function (values) {
        // remove undefined values
        return values.filter(function (value) {
          return typeof value !== "undefined";
        });
      })) as TaskArray[];

      setTasksInProjectArray(flatten(tasksInProjectArray));
    }
  }, [projectArrayIds]);

  React.useEffect(() => {
    fetchTasksInProjectArray();
  }, [fetchTasksInProjectArray]);

  React.useEffect(() => {
    const wsOnMessage = async (event: MessageEvent) => {
      try {
        const messageEventData = JSON.parse(event.data);
        const { event: eventType } = messageEventData;

        if (eventType === "new_task") {
          const eventTask = messageEventData.meta.data;
          if (!!selectProjectOfTask(eventTask)) {
            fetchTasksInProjectArray();
          }
        } else if (eventType === "new_join_task_to_user") {
          const eventTaskId = messageEventData.meta.task_id;
          const eventTask = await asyncFetchTaskObject(eventTaskId);

          // synchronize members of task record
          if (!!eventTask) {
            setTasksInProjectArray((c) =>
              c
                ? c.map((task) => {
                    if (task.id === eventTaskId) {
                      return eventTask;
                    }
                    return task;
                  })
                : c
            );
          }
        }
      } catch (e) {}
    };

    ws.addEventListener("message", wsOnMessage);

    return () => {
      ws.removeEventListener("message", wsOnMessage);
    };
  }, [fetchTasksInProjectArray]);

  return [consolidatedTasks, isLoading] as [
    TaskJoinedToUserAndScheduledArray | undefined,
    boolean
  ];
}
