import React from "react";

import {
  LoadingIcon,
  NoTaskIcon,
} from "../../CustomIcons/CustomIcons.component";

/**
 * Default display
 * Displays when global state tasks today_yn count is 0
 */
export interface PageEmptyStateProps {
  title: string;
  content: JSX.Element;
  loading?: boolean;
}
const PageEmptyState: React.FC<PageEmptyStateProps> = ({
  title,
  content,
  loading = false,
}) => {
  return (
    <div className="PageEmptyState">
      <div className="PageEmptyState__Container">
        {loading ? (
          <LoadingIcon />
        ) : (
          <>
            <NoTaskIcon className="PageEmptyState__Icon" />
            <span className="PageEmptyState__Title">{title}</span>
            <span className="PageEmptyState__Content">{content}</span>
          </>
        )}
      </div>
    </div>
  );
};

export default PageEmptyState;
