import React from "react";
import { useSelector } from "react-redux";
import { find, findIndex } from "lodash";

import { selectUserDetailsState } from "../reducers/user-details.reducer";
import { getJoinTaskToUserListService } from "../services/tasks.service";
import { dispatchError } from "../utils/error.util";
import {
  asyncFetchUserTaskObject,
  asyncFetchUserTaskObjectByTaskId,
} from "../utils/tasks.util";

import ws from "../sockets/websockets";
import { selectProjectsState } from "../reducers/projects.reducer";

export default function useAsyncMyDeskTasks(filter: TaskFilter) {
  const {
    data: { id: userId },
  } = useSelector(selectUserDetailsState);

  const { data: projects, loading: projectsLoading } = useSelector(
    selectProjectsState
  );

  const [userTasks, setUserTasks] = React.useState<JoinTaskToUserArray>();
  const [loading, setLoading] = React.useState(false);

  const isLoading = projectsLoading || loading;

  const fetchMyDeskTasks = React.useCallback(async () => {
    if (projects.length > 0) {
      try {
        setLoading(true);
        const response = await getJoinTaskToUserListService({
          today_yn: filter === "today" ? true : undefined,
          starred_yn: filter === "starred" ? true : undefined,
          next_shift_yn: filter === "next_shift" ? true : undefined,
        });
        if (response.status === 200) {
          const userTasks = response.data.map((item: any) => {
            const { next_shift_yn, starred_yn, today_yn, priority } = item;
            return {
              ...item,
              loading: false,
              next_shift_yn: {
                loading: false,
                value: next_shift_yn,
              },
              starred_yn: {
                loading: false,
                value: starred_yn,
              },
              today_yn: {
                loading: false,
                value: today_yn,
              },
              priority: {
                loading: false,
                value: priority,
              },
            };
          }) as JoinTaskToUserArray;
          const unarchivedUserTasks = userTasks.filter(({ task_list }) => {
            // filter out tasks where list or project is archived
            const { archived_yn, parent_project } = task_list;
            const project = projects.find(
              ({ id: projectId }) => projectId === parent_project // projects array only contain unarchived projects
            );
            return archived_yn === false && !!project;
          });
          setUserTasks(unarchivedUserTasks);
          setLoading(false);
        } else {
          throw new Error();
        }
      } catch (e) {
        setLoading(false);
        dispatchError({
          e,
          title: `Fetch tasks added to ${filter} error`,
        });
      }
    } else {
      setUserTasks([]);
    }
  }, [filter, projects]);

  React.useEffect(() => {
    fetchMyDeskTasks();
  }, [fetchMyDeskTasks]);

  React.useEffect(() => {
    const wsOnMessage = async (event: MessageEvent) => {
      try {
        const messageEventData = JSON.parse(event.data);
        const { event: eventType } = messageEventData;

        // use if else instead of switch because of variable declaration scope
        if (eventType === "new_activity") {
          const { task_id: eventTaskId } = messageEventData.meta;
          const eventUserTask = userTasks?.find(
            ({ task }) => task.id === eventTaskId
          );
          if (!!eventUserTask && userTasks) {
            const updatedUserTask = await asyncFetchUserTaskObject(
              eventUserTask.id
            );
            if (updatedUserTask)
              setUserTasks((c) =>
                c
                  ? c.map((userTask) => {
                      const { id } = userTask;
                      if (id === updatedUserTask.id) {
                        return updatedUserTask;
                      }
                      return userTask;
                    })
                  : c
              );
          }
        } else if (eventType === "removed_project_from_workday") {
          const { project: eventProjectId } = messageEventData.meta;
          const userTasksParentProjectIds = userTasks?.map(
            ({ task_list }) => task_list.parent_project
          );
          if (userTasksParentProjectIds?.includes(eventProjectId)) {
            setUserTasks((c) =>
              c?.filter(
                ({ task_list }) => task_list.parent_project !== eventProjectId
              )
            );
          }
        } else if (eventType === "added_task_to_workday") {
          const { task: eventTaskId } = messageEventData.meta;
          const index = findIndex(userTasks, {
            task: {
              id: eventTaskId,
            },
          });

          if (index === -1 && filter === "today") {
            const eventUserTask = await asyncFetchUserTaskObjectByTaskId(
              eventTaskId
            );
            if (eventUserTask)
              setUserTasks((c) => (!!c ? [...c, eventUserTask] : c));
          }
        } else if (eventType === "removed_task_from_workday") {
          const { task: eventTaskId } = messageEventData.meta;
          const index = findIndex(userTasks, {
            task: {
              id: eventTaskId,
            },
          });
          if (index !== -1 && filter === "today") {
            setUserTasks((c) =>
              c?.filter(({ task }) => task.id !== eventTaskId)
            );
          }
        } else if (eventType === "new_join_task_to_user") {
          const {
            next_shift_yn,
            starred_yn,
            today_yn,
            priority,
            ...rest
          } = messageEventData.meta.data;
          const eventUserId = messageEventData.meta.user_id;
          const isCurrentUser = eventUserId === userId;
          const eventUserTask = {
            ...rest,
            next_shift_yn: {
              loading: false,
              value: next_shift_yn,
            },
            starred_yn: {
              loading: false,
              value: starred_yn,
            },
            today_yn: {
              loading: false,
              value: today_yn,
            },
            priority: {
              loading: false,
              value: priority,
            },
          } as JoinTaskToUserObject;
          const userTask = find(userTasks, { id: eventUserTask.id });
          const exists = !!userTask;

          const addedToToday =
            filter === "today" && eventUserTask.today_yn.value && !exists;
          const addedToNextWorkDay =
            filter === "next_shift" &&
            eventUserTask.next_shift_yn.value &&
            !exists;
          const addedToStarred =
            filter === "starred" && eventUserTask.starred_yn.value && !exists;

          const removedFromToday =
            filter === "today" && !eventUserTask.today_yn.value && exists;
          const removedFromNextWorkDay =
            filter === "next_shift" &&
            !eventUserTask.next_shift_yn.value &&
            exists;
          const removedFromStarred =
            filter === "starred" && !eventUserTask.starred_yn.value && exists;

          if (
            isCurrentUser &&
            (addedToToday || addedToNextWorkDay || addedToStarred)
          ) {
            // add user task from my desk page
            setUserTasks((c) => (c ? [...c, eventUserTask] : c));
          } else if (
            isCurrentUser &&
            (removedFromToday || removedFromNextWorkDay || removedFromStarred)
          ) {
            // remove user task from my desk page
            setUserTasks((c) =>
              !!c && !!userTask ? c.filter(({ id }) => id !== userTask.id) : c
            );
          } else if (isCurrentUser && exists) {
            // update user task in my desk page
            setUserTasks((c) =>
              c
                ? c.map((item) => {
                    const { id } = item;
                    if (eventUserTask.id === id) {
                      return eventUserTask;
                    }
                    return item;
                  })
                : c
            );
          }
        }
      } catch (e) {}
    };

    ws.addEventListener("message", wsOnMessage);

    return () => {
      ws.removeEventListener("message", wsOnMessage);
    };
  }, [filter, userTasks, userId, fetchMyDeskTasks]);

  return [userTasks, isLoading] as [JoinTaskToUserArray | undefined, boolean];
}
