import { RootState } from "../store/configureStore";

export interface WorkShiftsState extends BaseReducerState {
  data: WorkShiftArray;
}
export const selectWorkShiftsState = (rootState: RootState) =>
  rootState.workShifts;
export const workShiftsReducerDefaultState: WorkShiftsState = {
  data: [],
  error: "",
  loading: false,
};
export const workShiftsReducer = (
  state = workShiftsReducerDefaultState,
  action: { type: string; workShifts: WorkShiftArray; error: string }
) => {
  switch (action.type) {
    case "REQUEST_FETCH_WORK_SHIFTS":
    case "REQUEST_UPDATE_WORK_SHIFT":
      return {
        ...state,
        error: "",
        loading: true,
      };
    case "REQUEST_FETCH_WORK_SHIFTS_SUCCESS":
    case "REQUEST_UPDATE_WORK_SHIFT_SUCCESS":
      return {
        data: action.workShifts,
        error: "",
        loading: false,
      };
    case "REQUEST_FETCH_WORK_SHIFTS_FAILED":
    case "REQUEST_UPDATE_WORK_SHIFT_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
