import { Dispatch } from "redux";

import {
  getUnreadProjectsService,
  getUnreadActivitiesService,
  markAsReadService,
} from "../services/unread-activities.service";
import { dispatchError } from "../utils/error.util";

export const requestFetchUnreadProjects = () => ({
  type: "REQUEST_FETCH_UNREAD_PROJECTS",
});
export const requestFetchUnreadProjectsSuccess = (
  payload: UnreadProject[]
) => ({
  type: "REQUEST_FETCH_UNREAD_PROJECTS_SUCCESS",
  payload,
});
export const requestFetchUnreadProjectsFailed = (error: string) => ({
  type: "REQUEST_FETCH_UNREAD_PROJECTS_FAILED",
  error,
});
// The thunk action for fetching unread projects.
export const startPopulateUnreadProjects = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(requestFetchUnreadProjects());
      const response = await getUnreadProjectsService();
      if (response.status === 200) {
        dispatch(requestFetchUnreadProjectsSuccess(response.data));
      } else {
        const error = "getUnreadProjectsService status is not 200";
        dispatch(requestFetchUnreadProjectsFailed(error));
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestFetchUnreadProjectsFailed(error));
      dispatchError({
        e,
        title: "Populate unread projects error",
      });
    }
  };
};

export const requestFetchUnreadActivitiesInProject = () => ({
  type: "REQUEST_FETCH_UNREAD_ACTIVITIES_IN_PROJECT",
});
export const requestFetchUnreadActivitiesInProjectSuccess = (
  payload: UnreadActivity[]
) => ({
  type: "REQUEST_FETCH_UNREAD_ACTIVITIES_IN_PROJECT_SUCCESS",
  payload,
});
export const requestFetchUnreadActivitiesInProjectFailed = (error: string) => ({
  type: "REQUEST_FETCH_UNREAD_ACTIVITIES_IN_PROJECT_FAILED",
  error,
});
// The thunk action for fetching unread activities.
export const startPopulateUnreadActivities = (
  taskId: string,
  limit?: number,
  offset?: number
) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(requestFetchUnreadActivitiesInProject());
      const response = await getUnreadActivitiesService(taskId, limit, offset);
      if (response.status === 200) {
        dispatch(requestFetchUnreadActivitiesInProjectSuccess(response.data));
      } else {
        const error = "getUnreadActivitiesService status is not 200";
        dispatch(requestFetchUnreadActivitiesInProjectFailed(error));
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestFetchUnreadActivitiesInProjectFailed(error));
      dispatchError({
        e,
        title: "Populate unread activites error",
      });
    }
  };
};

export const requestMarkUnreadActivities = () => ({
  type: "REQUEST_MARK_UNREAD_ACTIVITIES",
});
export const requestMarkUnreadActivitiesSuccess = (
  payload: UnreadActivity[]
) => ({
  type: "REQUEST_MARK_UNREAD_ACTIVITIES_SUCCESS",
  payload,
});
export const requestMarkUnreadActivitiesFailed = (error: string) => ({
  type: "REQUEST_MARK_UNREAD_ACTIVITIES_FAILED",
  error,
});
// The thunk action for marking the payload as read.
export const startMarkAsRead = (payload: MarkAsReadParams) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(requestMarkUnreadActivities());
      const response = await markAsReadService(payload);
      if (response.status === 200 || response.status === 201) {
        const activities = response.data;
        let markedActivities = [];
        if (Array.isArray(activities)) {
          markedActivities = activities;
        } else {
          markedActivities = [activities];
        }
        markedActivities = markedActivities.map((ac) => ({
          id: ac.activity,
          task: ac.task,
        })) as UnreadActivity[];
        dispatch(requestMarkUnreadActivitiesSuccess(markedActivities));
      } else {
        const error = "markAsReadService status is not 201";
        dispatch(requestMarkUnreadActivitiesFailed(error));
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestMarkUnreadActivitiesFailed(error));
      dispatchError({
        e,
        title: "Mark unread activities error",
      });
    }
  };
};
