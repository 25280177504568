export const labels: Label[] = [
  {
    id: "1",
    project_id: "D4Y56C1WZ3",
    title: "Label1",
    color: "#0079F2",
  },
  {
    id: "2",
    project_id: "D4Y56C1WZ3",
    title: "Label2",
    color: "#40BF00",
  },
  {
    id: "3",
    project_id: "D4Y56C1WZ3",
    title: "Label2",
    color: "#FFBF00",
  },
  {
    id: "4",
    project_id: "RZILB70BCN",
    title: "Label1",
    color: "#0079F2",
  },
];
