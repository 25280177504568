import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Tooltip } from "antd";
import cn from "classnames";

import {
  LoadingIcon,
  StarFilledIcon,
} from "../../CustomIcons/CustomIcons.component";
import {
  startCreateJoinTaskToUserRecord,
  startPartialUpdateJoinTaskToUserRecord,
} from "../../../actions/tasks.action";
import { selectPrioritiesState } from "../../../reducers/priorities.reducer";
import { selectUserDetailsState } from "../../../reducers/user-details.reducer";
import { getPriorityByTitle } from "../../../utils/priorities.util";

/**
 * AddToStarred displays and sets the join_user_to_task records' starred_yn
 *
 * Default Display
 * Displays passed join_task_to_user record's [starred_yn]
 *
 * Functionality
 * Creates a join_task_to_user record with [starred_yn] set to true if join_task_to_user_id does not exist
 * If join_task_to_user_id exists, updates join_task_to_user record's [starred_yn] to true or false
 * Owners of a task can update join_task_to_user record's [starred_yn] to true but not to false
 */
const AddToStarred: React.FC<AddToStarredProps> = ({
  task: {
    starred_yn,
    join_task_to_user_id: joinTaskToUserId,
    id: taskId,
    title: taskTitle,
    loading: taskLoading,
  },
  search = false,
}) => {
  const dispatch = useDispatch();

  const { data: priorities } = useSelector(selectPrioritiesState);
  const {
    data: { id: userId },
  } = useSelector(selectUserDetailsState);
  const { loading: starredYnLoading, value: starredYn } = starred_yn || {};
  const isUpdating = taskLoading && starredYnLoading;

  let tooltipText = starredYn ? "Remove from Starred" : "Add to Starred";
  const nonePriority = getPriorityByTitle(priorities, "None");

  const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
    if (e) e.stopPropagation();
    if (joinTaskToUserId) {
      const data = {
        id: joinTaskToUserId,
        starred_yn: !starredYn,
        title: starredYn === true ? taskTitle : "",
        taskId,
      };
      dispatch(startPartialUpdateJoinTaskToUserRecord(data));
    } else if (nonePriority) {
      dispatch(
        startCreateJoinTaskToUserRecord({
          starred_yn: true,
          priority: nonePriority.id,
          task: taskId,
          user: userId,
        })
      );
    } else {
      throw new Error(`nonePriority does not exist`);
    }
  };

  return (
    <div className="AddToStarred">
      {search ? (
        <div className="AddToStarred__Search" onClick={handleOnClick}>
          {isUpdating ? (
            <LoadingIcon />
          ) : (
            <StarFilledIcon
              className={cn("AddToStarred__Icon", {
                "AddToStarred__Icon--IsActive": starredYn,
              })}
            />
          )}
          <span className="AddToStarred__Text">{tooltipText}</span>
        </div>
      ) : (
        <Tooltip title={tooltipText}>
          {isUpdating ? (
            <LoadingIcon />
          ) : (
            <StarFilledIcon
              className={cn("AddToStarred__Icon", {
                "AddToStarred__Icon--IsActive": starredYn,
              })}
              onClick={handleOnClick}
            />
          )}
        </Tooltip>
      )}
    </div>
  );
};

export { AddToStarred as default };
