import axios from "../apis/django.instance";
import { sections, removedSections } from "../fixtures/section.fixture";

export const getSectionListService = () => {
  return axios.get("/api/projects/task_section/"); // must be in this format in order to mock implementation in unit test
};

export const getSectionRecordByIdService: GetSectionRecordByIdService = (
  id
) => {
  return axios.get(`/api/projects/task_section/${id}/`);
};

export const createListRecordService: CreateSectionRecordService = (data) => {
  return axios.post("/api/projects/task_section/", data);
};

export const updateWorkDayRecordService: UpdateSectionRecordService = (
  data
) => {
  const { id, ...payload } = data;
  return axios.put(`/api/projects/task_section/${id}/`, payload); // must be in this format in order to mock implementation in unit test
};

let response = {
  sections,
  removed_sections: removedSections,
};
let index = 0;

// GET api/work/task_lists/{id}/sections?summary=True
export const getSectionsSummaryOfListService = async () => {
  return new Promise((resolve) => setTimeout(() => resolve(response), 1000));
};

export const addSectionService = async (
  taskListId: string,
  sectionName: string
) => {
  const newSection = {
    id: `key-${index}`,
    section: sectionName,
    tasks: 0,
    archived: 0,
  };
  response = {
    ...response,
    sections: [newSection, ...response.sections],
  };
  index += 1;
  return new Promise((resolve) => setTimeout(() => resolve(newSection), 1000));
};

export const updateSectionService = async (section: any) => {
  const activeSections = response.sections.map((item) => {
    if (item.id === section.id) {
      return section;
    }
    return item;
  });
  const removedSections = response.removed_sections.map((item) => {
    if (item.id === section.id) {
      return section;
    }
    return item;
  });
  response = {
    sections: activeSections,
    removed_sections: removedSections,
  };
  return new Promise((resolve) => setTimeout(() => resolve(section), 1000));
};

export const archiveSectionService = async (sectionId: string) => {
  const sections = response.sections.map((item) => {
    if (item.id === sectionId) {
      return {
        ...item,
        tasks: 0,
        archived: item.archived + item.tasks,
      };
    }
    return item;
  });
  response = {
    ...response,
    sections,
  };
  return new Promise((resolve) => setTimeout(() => resolve(true), 1000));
};

export const unarchiveSectionService = async (sectionId: string) => {
  const sections = response.sections.map((item) => {
    if (item.id === sectionId) {
      return {
        ...item,
        archived: 0,
        tasks: item.archived + item.tasks,
      };
    }
    return item;
  });
  response = {
    ...response,
    sections,
  };
  return new Promise((resolve) => setTimeout(() => resolve(true), 1000));
};

// PATCH api/work/task_lists/{id}/sections
export const orderSectionService = async (
  srcIndex: number,
  srcTable: "active" | "removed",
  dstIndex: number,
  dstTable: "active" | "removed"
) => {
  const sections = [...response.sections];
  const removedSections = [...response.removed_sections];
  if (srcTable === "active" && dstTable === "active") {
    const [section] = sections.splice(srcIndex, 1);
    sections.splice(dstIndex, 0, section);
  } else if (srcTable === "removed" && dstTable === "removed") {
    const [section] = removedSections.splice(srcIndex, 1);
    removedSections.splice(dstIndex, 0, section);
  } else if (srcTable === "active" && dstTable === "removed") {
    const [section] = sections.splice(srcIndex, 1);
    removedSections.splice(dstIndex, 0, section);
  } else if (srcTable === "removed" && dstTable === "active") {
    const [section] = removedSections.splice(srcIndex, 1);
    sections.splice(dstIndex, 0, section);
  }
  return new Promise((resolve) =>
    setTimeout(() => {
      response = {
        sections,
        removed_sections: removedSections,
      };
      resolve(true);
    }, 2000)
  );
};

// DELETE api/work/task_lists/{id}/sections/{id}
export const deleteSectionService = async (sectionId: string) => {
  const activeSections = response.sections.filter(
    (item) => item.id !== sectionId
  );
  const removedSections = response.removed_sections.filter(
    (item) => item.id !== sectionId
  );
  response = {
    sections: activeSections,
    removed_sections: removedSections,
  };
  return new Promise((resolve) => setTimeout(() => resolve(true), 1000));
};
