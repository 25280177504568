import React from "react";
import { isEmpty } from "lodash";
import cn from "classnames";

import UserWorkDaySummaryItemGroupedByDate from "./UserWorkDaySummaryItemGroupedByDate/UserWorkDaySummaryItemGroupedByDate";

import { groupObjectArrayByObjectDateKey } from "../../../utils/objects-array.util";

interface UserWorkDaySummaryListGroupedByDateProps {
  type: ActivitiesSummaryType;
  users: UserArray;
  workDaysCheckoutSummaryGroupedByDate: ReturnType<
    typeof groupObjectArrayByObjectDateKey
  >;
}
const UserWorkDaySummaryListGroupedByDate: React.FC<UserWorkDaySummaryListGroupedByDateProps> = ({
  type,
  users,
  workDaysCheckoutSummaryGroupedByDate,
}) => {
  const noCheckoutTasks = isEmpty(workDaysCheckoutSummaryGroupedByDate);
  return (
    <div
      className={cn("UserWorkDaySummaryListGroupedByDate", {
        "UserWorkDaySummaryListGroupedByDate--Empty": noCheckoutTasks,
      })}
    >
      {noCheckoutTasks ? (
        <span>There are no checkout tasks.</span>
      ) : (
        Object.keys(workDaysCheckoutSummaryGroupedByDate).map((key) => {
          const dateWorkDayCheckoutSummaryArray: WorkDayCheckoutSummaryArray =
            workDaysCheckoutSummaryGroupedByDate[key];

          return (
            <UserWorkDaySummaryItemGroupedByDate
              key={key}
              dateKey={key}
              dateWorkDayCheckoutSummaryArray={dateWorkDayCheckoutSummaryArray}
              type={type}
              users={users}
            />
          );
        })
      )}
    </div>
  );
};

export default UserWorkDaySummaryListGroupedByDate;
