import axios from "../apis/django.instance";

export const fetchNotificationsService = () => {
  return axios.get("/api/notifications/"); // must be in this format in order to mock implementation in unit test
};

export const markAllNotificationsAsReadService = () => {
  return axios.put("/api/notifications/mark_all_as_read/");
};

export const markNotificationAsReadService = (id: NotificationObject["id"]) => {
  return axios.patch(`/api/notifications/mark_as_read/${id}`);
};

export interface MarkMultipleNotificationsAsReadServiceData {
  ids: NotificationObject["id"][];
}
export const markMultipleNotificationsAsReadService = (
  ids: MarkMultipleNotificationsAsReadServiceData
) => {
  return axios.patch(`/api/notifications/mark_as_read/`, ids);
};
