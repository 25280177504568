import React from "react";
import cn from "classnames";
import { Menu, Dropdown, Tooltip } from "antd";
import { DragDropContext } from "react-beautiful-dnd";

import DndTable from "../../General/DndTable/DndTable";
import {
  MoreActionsIcon,
  InfoCircleOutlinedIcon,
  DraftIcon,
  ArchiveIcon,
  CloseIcon,
  DeleteIcon,
} from "../../CustomIcons/CustomIcons.component";

import AddSectionInput from "../AddSectionInput/AddSectionInput";
import RenameSectionModal from "../RenameSectionModal/RenameSectionModal";
import Confirm from "../../General/Confirm/Confirm";
import Modal from "../../General/Modal/Modal";
import {
  addSectionService,
  archiveSectionService,
  deleteSectionService,
  getSectionsSummaryOfListService,
  orderSectionService,
  unarchiveSectionService,
  updateSectionService,
} from "../../../services/section.service";

export const archivedTitle = (
  <div className="ManageSectionsModal__TooltipHeader">
    <span>Archived tasks</span>
    <Tooltip
      overlayInnerStyle={{
        width: "208px",
        whiteSpace: "pre-wrap",
        fontSize: "12px",
        lineHeight: "17px",
      }}
      title="Archived tasks are only available in the Archive."
    >
      <InfoCircleOutlinedIcon className="ManageSectionsModal__TooltipIcon" />
    </Tooltip>
  </div>
);

export const renderDropdownMenu = (menus: SectionsModalMenuItem[]) => {
  const menuItems = menus.map(
    ({
      key,
      text,
      icon,
      disabled = false,
      confirmContent,
      confirmOkText = "Ok",
      confirmCancelText = "Cancel",
      tooltip,
      onClick,
    }) => {
      const handleClick = () => {
        if (onClick) {
          onClick();
        }
      };
      const menuItem = (
        <Menu.Item
          key={key}
          className={cn({ "ManageSectionsModal__Menu-disabled": disabled })}
          icon={icon}
          disabled={disabled}
          onClick={confirmContent ? undefined : handleClick}
        >
          {tooltip ? (
            <Tooltip
              overlayInnerStyle={{ fontSize: "12px", lineHeight: "17px" }}
              title={tooltip}
              trigger="hover"
            >
              {text}
            </Tooltip>
          ) : (
            text
          )}
        </Menu.Item>
      );

      return confirmContent ? (
        <Confirm
          key={key}
          content={confirmContent}
          okText={confirmOkText}
          cancelText={confirmCancelText}
          onOk={handleClick}
        >
          {menuItem}
        </Confirm>
      ) : (
        menuItem
      );
    }
  );
  return (
    <Dropdown
      overlay={<Menu className="ManageSectionsModal__Menu">{menuItems}</Menu>}
      placement="bottomLeft"
      trigger={["click"]}
    >
      <MoreActionsIcon className="ManageSectionsModal__MoreIcon" />
    </Dropdown>
  );
};

/**
 *
 * The <ManageSectionsModal /> is the component to manage all sections for a task_list.
 * It use react-beautiful-dnd library to handle drag-and-drop feature.
 * It will update the UI immediately and its corresponding state once the drag-drop animation
 * is finished in order to provide a smooth user experience. In the meantime, it will disable
 * the drag-drop feature during the processing of request using draggable variable to avoid messing up the data.
 */
const ManageSectionsModal = ({ open, onClose }: ManageSectionsModalProps) => {
  const [renameModalVisible, setRenameModalVisible] = React.useState(false);
  const [selectedSection, setSelectedSection] = React.useState<Section>();
  const [sections, setSections] = React.useState<Section[]>([]);
  const [removedSections, setRemoveSections] = React.useState<Section[]>([]);
  const [loading, setLoading] = React.useState(false);

  // make DndTable undraggble if last drag-drop request hasn't finished yet
  const [draggable, setDraggable] = React.useState(true);

  const fetchSectionsData = React.useCallback(async () => {
    const response: any = await getSectionsSummaryOfListService();
    setSections(
      (response.sections as Section[]).map((section, index) => ({
        ...section,
        key: section.id,
        index,
      }))
    );
    setRemoveSections(
      (response.removed_sections as Section[]).map((section, index) => ({
        ...section,
        key: section.id,
        index,
      }))
    );
  }, []);

  React.useEffect(() => {
    if (open) {
      setLoading(true);
      fetchSectionsData().then(() => setLoading(false));
    }
  }, [open, fetchSectionsData]);

  const handleRenameOpen = (section: Section) => {
    setSelectedSection(section);
    setRenameModalVisible(true);
  };

  const handleArchive = async (section: Section) => {
    setLoading(true);
    await archiveSectionService(section.id);
    await fetchSectionsData();
    setLoading(false);
  };

  const handleUnarchive = async (section: Section) => {
    setLoading(true);
    await unarchiveSectionService(section.id);
    await fetchSectionsData();
    setLoading(false);
  };

  const handleUnarchiveAndRestore = async (section: Section) => {
    setLoading(true);
    const srcIndex = removedSections.findIndex(
      (item) => item.id === section.id
    );
    await orderSectionService(srcIndex, "removed", 0, "active");
    await unarchiveSectionService(section.id);
    await fetchSectionsData();
    setLoading(false);
  };

  const handleRemove = async (section: Section) => {
    const srcIndex = sections.findIndex((item) => item.id === section.id);
    setLoading(true);
    await orderSectionService(srcIndex, "active", 0, "removed");
    if (section.tasks > 0) {
      await handleArchive(section);
    }
    await fetchSectionsData();
    setLoading(false);
  };

  const handleDelete = async (section: Section) => {
    setLoading(true);
    await deleteSectionService(section.id);
    await fetchSectionsData();
    setLoading(false);
  };

  const handleRestore = async (section: Section) => {
    const srcIndex = removedSections.findIndex(
      (item) => item.id === section.id
    );
    setLoading(true);
    await orderSectionService(srcIndex, "removed", 0, "active");
    await fetchSectionsData();
    setLoading(false);
  };

  const handleRestoreWhenAdd = async (sectionName: string) => {
    const section = removedSections.find(
      (item) => item.section === sectionName
    );
    if (section) {
      await handleRestore(section);
    }
  };

  const handleDragEnd = async (result: any) => {
    setDraggable(false);
    const {
      source: { index: srcIndex, droppableId: srcTable },
      destination: { index: dstIndex, droppableId: dstTable },
    } = result;

    // update the section list immediately to make UE more smoothly
    const curSections = [...sections];
    const curRemovedSections = [...removedSections];
    if (srcTable === "active" && dstTable === "active") {
      const [section] = curSections.splice(srcIndex, 1);
      curSections.splice(dstIndex, 0, section);
      setSections(curSections);
    } else if (srcTable === "removed" && dstTable === "removed") {
      const [section] = curRemovedSections.splice(srcIndex, 1);
      curRemovedSections.splice(dstIndex, 0, section);
      setRemoveSections(curRemovedSections);
    } else if (srcTable === "active" && dstTable === "removed") {
      const [section] = curSections.splice(srcIndex, 1);
      curRemovedSections.splice(dstIndex, 0, section);
      setSections(curSections);
      setRemoveSections(curRemovedSections);
    } else if (srcTable === "removed" && dstTable === "active") {
      const [section] = curRemovedSections.splice(srcIndex, 1);
      curSections.splice(dstIndex, 0, section);
      setSections(curSections);
      setRemoveSections(curRemovedSections);
    }
    await orderSectionService(srcIndex, srcTable, dstIndex, dstTable);
    await fetchSectionsData();
    setDraggable(true);
  };

  const handleSectionAdd = async (name: string) => {
    await addSectionService("", name);
    await fetchSectionsData();
  };

  const handleRenameOk = async (sectionName: string) => {
    const newSection = {
      ...selectedSection,
      section: sectionName,
    };
    await updateSectionService(newSection);
    await fetchSectionsData();
    setRenameModalVisible(false);
  };

  const handleRenameClose = () => {
    setRenameModalVisible(false);
  };

  const sectionMenus = (section: Section) => [
    {
      key: "rename",
      text: "Rename section",
      icon: <DraftIcon />,
      onClick: () => handleRenameOpen(section),
    },
    {
      key: "archive",
      text: "Archive all tasks",
      icon: <ArchiveIcon />,
      confirmContent: "Do you want to archive all tasks of this section?",
      confirmOkText: "Archive",
      disabled: section.tasks === 0,
      tooltip: section.tasks === 0 ? "You don't have task to be archived." : "",
      onClick: () => handleArchive(section),
    },
    {
      key: "unarchive",
      text: "Unarchive all tasks",
      icon: <ArchiveIcon />,
      confirmContent: "Do you want to unarchive all tasks of this section?",
      confirmOkText: "Unarchive",
      disabled: section.archived === 0,
      tooltip:
        section.archived === 0 ? "You don't have task to be unarchived." : "",
      onClick: () => handleUnarchive(section),
    },
    {
      key: "remove",
      text: "Remove section",
      icon: <CloseIcon />,
      confirmContent:
        section.tasks > 0
          ? "Do you want to archive all tasks and remove this section from the task list?"
          : "",
      confirmOkText: "Remove",
      onClick: () => handleRemove(section),
    },
    {
      key: "delete",
      text: "Delete section",
      icon: <DeleteIcon />,
      confirmContent: "Do you want to delete this section?",
      confirmOkText: "Delete",
      disabled: section.tasks > 0 || section.archived > 0,
      tooltip:
        section.tasks > 0 || section.archived > 0
          ? "Only empty sections can be deleted."
          : "",
      onClick: () => handleDelete(section),
    },
  ];

  const removedMenus = (section: Section) => [
    {
      key: "rename",
      text: "Rename section",
      icon: <DraftIcon />,
      onClick: () => handleRenameOpen(section),
    },
    {
      key: "unarchive",
      text: "Unarchive all tasks",
      icon: <ArchiveIcon />,
      confirmContent:
        "Do you want to unarchive all tasks and restore that section?",
      confirmOkText: "Unarchive",
      disabled: section.archived === 0,
      tooltip:
        section.archived === 0 ? "You don't have task to be unarchived." : "",
      onClick: () => handleUnarchiveAndRestore(section),
    },
    {
      key: "restore",
      text: "Restore section",
      icon: <ArchiveIcon />,
      onClick: () => handleRestore(section),
    },
    {
      key: "delete",
      text: "Delete section",
      icon: <DeleteIcon />,
      confirmContent: "Do you want to delete this section?",
      confirmOkText: "Delete",
      disabled: section.archived > 0,
      tooltip:
        section.archived > 0
          ? "Only empty sections can be deleted."
          : undefined,
      onClick: () => handleDelete(section),
    },
  ];

  const sectionColumns = [
    {
      title: "Section",
      dataIndex: "section",
      align: "left",
    },
    {
      title: "Tasks",
      dataIndex: "tasks",
      align: "right",
    },
    {
      dataIndex: "archived",
      title: archivedTitle,
      align: "right",
    },
    {
      title: "",
      align: "right",
      key: "action",
      render: (record: Section) => renderDropdownMenu(sectionMenus(record)),
    },
  ];

  const removedSectionColumns = [
    {
      title: "Section",
      dataIndex: "section",
      align: "left",
    },
    {
      title: archivedTitle,
      align: "right",
      dataIndex: "archived",
    },
    {
      title: "",
      align: "right",
      key: "action",
      render: (record: Section) => renderDropdownMenu(removedMenus(record)),
    },
  ];

  return (
    <Modal
      className="ManageSectionsModal"
      title="Manage sections"
      prefixText="You can change the section order, rename sections, archive all tasks of
      a section, and remove empty sections from the task list. Changes apply
      to all members."
      centered={true}
      width={720}
      visible={open}
      onCancel={onClose}
      footer={null}
      zIndex={999}
    >
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="ManageSectionsModal__Table">
          <div className="ManageSectionsModal__Table__Header">
            <div className="ManageSectionsModal__Table__Header__Title">
              Sections
            </div>
            <div className="ManageSectionsModal__Table__Header__Action">
              <AddSectionInput
                sections={sections.map((item) => item.section)}
                removedSections={removedSections.map((item) => item.section)}
                onAdd={handleSectionAdd}
                onRestore={handleRestoreWhenAdd}
              />
            </div>
          </div>
          <div className="ManageSectionsModal__Table__Body">
            <DndTable
              id="active"
              columns={sectionColumns}
              dataSource={sections}
              pagination={false}
              loading={loading}
              disabled={!draggable}
            />
          </div>
        </div>

        <div className="ManageSectionsModal__Table">
          <div className="ManageSectionsModal__Table__Header">
            <div className="ManageSectionsModal__Table__Header__Title">
              Removed Sections
            </div>
            <div className="ManageSectionsModal__Table__Header__Action"></div>
          </div>
          <div className="ManageSectionsModal__Table__Body">
            <DndTable
              id="removed"
              columns={removedSectionColumns}
              dataSource={removedSections}
              pagination={false}
              loading={loading}
              disabled={!draggable}
            />
          </div>
        </div>
        <RenameSectionModal
          defaultValue={selectedSection?.section || ""}
          sections={sections.map((item) => item.section)}
          removedSections={removedSections.map((item) => item.section)}
          open={renameModalVisible}
          onOk={handleRenameOk}
          onClose={handleRenameClose}
        />
      </DragDropContext>
    </Modal>
  );
};

export default ManageSectionsModal;
