import React from "react";
import { useSelector } from "react-redux";
import { sumBy } from "lodash";

import { selectTaskTimeState } from "../reducers/task-time.reducer";

export default function useWorkDayTotalTaskTime(
  workDayObjectId: WorkDayObject["id"]
) {
  const taskTimeArray = useSelector(selectTaskTimeState).data;

  const [workDayTotalTaskTime, setWorkDayTotalTaskTime] = React.useState(0);

  React.useEffect(() => {
    if (taskTimeArray.length > 0) {
      setWorkDayTotalTaskTime(
        sumBy(
          taskTimeArray.filter(({ work_day }) => work_day === workDayObjectId),
          "seconds_worked"
        )
      );
    }
  }, [taskTimeArray, workDayObjectId]);

  return workDayTotalTaskTime;
}
