import React from "react";
import { useLocation, useMatch, useSearchParams } from "react-router-dom";

import CheckoutPanel from "../../modules/Checkout/CheckoutPanel/CheckoutPanel";
import HistoryPanel from "../../modules/Tasks/HistoryPanel/HistoryPanel";
import ListPanel from "../../modules/List/ListPanel/ListPanel";
import TaskPanel from "../../modules/Tasks/TaskPanel/TaskPanel";
import TeamPanel from "../../modules/Team/TeamPanel/TeamPanel";
import UnreadActivities from "../../modules/UnreadActivities/UnreadActivities.component";
import Office from "../../modules/Office/Office.component";
import ProjectOverview from "../../modules/ProjectOverview/ProjectOverview.component";

import { menuStaticKeys, TASK_PAGES } from "../../constants/constants";

export interface InTheOfficeContentContext {
  taskOwnersFilter: ProjectUser[];
  setTaskOwnersFilter: React.Dispatch<React.SetStateAction<ProjectUser[]>>;
  taskMembersFilter: ProjectUser[];
  setTaskMembersFilter: React.Dispatch<React.SetStateAction<ProjectUser[]>>;
}
export const InTheOfficeContentContext = React.createContext<InTheOfficeContentContext>(
  {} as InTheOfficeContentContext
);

const InTheOfficeContent = () => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const isProject = useMatch("/p/:projectId/:projectName");
  const isList = useMatch("/l/:listId/:listName");
  const isListSubPage = useMatch(
    "/l/:listId/:listName/:taskId/:taskName/:taskSubPage"
  );
  const isTask = useMatch("/t/:taskId/:taskName/:taskSubPage");
  const taskPage = searchParams.get("p");

  const [taskOwnersFilter, setTaskOwnersFilter] = React.useState<ProjectUser[]>(
    []
  );
  const [taskMembersFilter, setTaskMembersFilter] = React.useState<
    ProjectUser[]
  >([]);

  return (
    <InTheOfficeContentContext.Provider
      value={{
        taskOwnersFilter,
        setTaskOwnersFilter,
        taskMembersFilter,
        setTaskMembersFilter,
      }}
    >
      {(() => {
        if (isProject) {
          return <ProjectOverview />;
        }
        if (isList || isListSubPage) {
          return <ListPanel />;
        }
        if (
          isTask &&
          taskPage &&
          (taskPage === TASK_PAGES.TODAY ||
            taskPage === TASK_PAGES.NEXT_SHIFT ||
            taskPage === TASK_PAGES.STARRED)
        ) {
          return <TaskPanel taskPage={taskPage} />;
        }

        switch (pathname) {
          case menuStaticKeys.TODAY:
            return <TaskPanel taskPage={TASK_PAGES.TODAY} />;
          case menuStaticKeys.NEXT_SHIFT:
            return <TaskPanel taskPage={TASK_PAGES.NEXT_SHIFT} />;
          case menuStaticKeys.STARRED:
            return <TaskPanel taskPage={TASK_PAGES.STARRED} />;
          case menuStaticKeys.CHECKOUT:
            return <CheckoutPanel mode="page" />;
          case menuStaticKeys.HISTORY:
            return <HistoryPanel />;
          case menuStaticKeys.TEAM:
            return <TeamPanel />;
          case menuStaticKeys.UNREAD:
            return <UnreadActivities />;
          case menuStaticKeys.OFFICE:
            return <Office />;
          default:
            return <></>;
        }
      })()}
    </InTheOfficeContentContext.Provider>
  );
};

export default InTheOfficeContent;
