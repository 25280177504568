import React from "react";
import { Tooltip } from "antd";
import moment from "moment";
import { sumBy } from "lodash";

import useAsyncTasksInProject from "../../../../hooks/useAsyncTasksInProject.hook";
import useWorkDayTaskArrayTotalTime from "../../../../hooks/useWorkDayTaskArrayTotalTime.hook";

import { getTotalDuration } from "../../../../utils/time-conversion.util";
import { CheckoutPanelContext } from "../../CheckoutPanel/CheckoutPanel";
import { getTaskTimeService } from "../../../../services/task-time.service";
interface CheckoutProjectTimeBarProps {
  project: ProjectObject;
  projectsTotalTime: number;
}
const CheckoutProjectTimeBar: React.FC<CheckoutProjectTimeBarProps> = ({
  project,
  projectsTotalTime,
}) => {
  const { checkoutWorkDay } = React.useContext(CheckoutPanelContext);
  const { id: projectId, color, title: projectTitle } = project;
  const { value: projectColorValue } = color || {};
  const { id: checkoutWorkDayId = "" } = checkoutWorkDay || {};

  const [projectTotalTime, setProjectTotalTime] = React.useState<number>();

  const fetchProjectTotalTime = React.useCallback(async () => {
    try {
      const response = await getTaskTimeService({
        project: projectId,
        work_day: checkoutWorkDayId,
      });
      if (response.status === 200) {
        const taskTimes = response.data as TaskTimeArray;
        setProjectTotalTime(sumBy(taskTimes, "seconds_worked"));
      }
    } catch (e) {}
  }, [checkoutWorkDayId, projectId]);

  React.useEffect(() => {
    fetchProjectTotalTime();
  }, [fetchProjectTotalTime]);

  const width = `${
    projectTotalTime
      ? ((projectTotalTime / projectsTotalTime) * 100).toFixed(2)
      : 0
  }%`;

  const tooltip = (
    <div className="CheckoutTimeBarTooltip">
      {/** Add dynamic psuedo element */}
      <div
        style={{
          borderRight: `2px solid ${projectColorValue}`,
          bottom: "0",
          height: "50%",
          left: "0",
          position: "absolute",
          width: "1px",
          top: "0",
          transform: "translateY(50%)",
        }}
      />
      <span
        className="CheckoutTimeBarTooltip__Title"
        style={{ color: projectColorValue }}
      >
        {projectTitle}
      </span>
      <span>
        {getTotalDuration(
          moment.duration(projectTotalTime ? projectTotalTime : 0, "seconds")
        )}
      </span>
    </div>
  );

  return (
    <>
      {projectTotalTime === undefined ? (
        <div className="CheckoutProjectTimeBarLoader"></div>
      ) : (
        <Tooltip title={tooltip} getPopupContainer={(trigger: any) => trigger}>
          <div
            className="CheckoutProjectTimeBar"
            style={{ width, backgroundColor: projectColorValue }}
          ></div>
        </Tooltip>
      )}
    </>
  );
};

export default CheckoutProjectTimeBar;
