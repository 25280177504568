const projectDetailsReducerDefaultState: ProjectDetails = {} as ProjectDetails;

const projectDetailsReducer = (
  state = projectDetailsReducerDefaultState,
  action: { type: string; projectDetails: ProjectDetails }
) => {
  switch (action.type) {
    case "SET_PROJECT_DETAILS":
      return action.projectDetails;
    default:
      return state;
  }
};

export { projectDetailsReducerDefaultState, projectDetailsReducer as default };
