import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Input, Button, Select } from "antd";
import { Feature } from "flagged";

import {
  CaretDownIcon,
  InfoCircleOutlinedIcon,
} from "../../CustomIcons/CustomIcons.component";
import AddSectionBtn from "../../Section/AddSectionBtn/AddSectionBtn";
import ColorPicker from "../../ColorPicker/ColorPicker.component";

import { selectActivityTypesState } from "../../../reducers/activity-types.reducer";
import { startPopulateProjectsColors } from "../../../actions/projects-colors.action";
import { selectProjectsColorsState } from "../../../reducers/projects-colors.reducer";
import { selectUserDetailsState } from "../../../reducers/user-details.reducer";
import { activityTypesOrder } from "../../../constants/constants";

const { TextArea } = Input;
const { Option } = Select;

const ProjectForm = ({
  isCreate = true,
  project,
  loading = false,
  onChange,
  onSubmit,
}: ProjectFormProps) => {
  const dispatch = useDispatch();
  const colors = useSelector(selectProjectsColorsState).data;
  const userDetails = useSelector(selectUserDetailsState).data;
  const { data: activityTypes } = useSelector(selectActivityTypesState);

  const initialValues = {
    title: project.title,
    list: "Task list 1",
    projectColor: project.hexColor,
    autoActivity: activityTypes.find(
      ({ title }) => title === activityTypesOrder[1]
    )?.title,
    description: project.description,
  };
  const [form] = Form.useForm();

  const handleColorSelect = (value: string) => {
    handleValueChange({ hexColor: value });
  };

  const handleFinish = async (values: any) => {
    /**
     * TODO 13.15.2: update payload with additional values
     * when endpoint gets updated
     */
    const newProject = {
      ...project,
      title: values.title,
      description: values.description,
      task_list_title: values.list,
      workspace: userDetails.workspace,
    } as any;
    const response: any = await onSubmit(newProject);
    if (response.message === "duplicated") {
      form.setFields([
        {
          name: "title",
          errors: [
            "A project with this name already exists. Enter another project name",
          ],
        },
      ]);
    }
  };

  const handleValueChange = (values: any) => {
    let newProject = { ...project };
    if (values.title !== undefined) {
      newProject.title = values.title;
    }
    if (values.hexColor !== undefined) {
      newProject.hexColor = values.hexColor;
    }
    onChange(newProject);
  };

  React.useEffect(() => {
    dispatch(startPopulateProjectsColors());
  }, [dispatch]);

  return (
    <div className="ProjectForm">
      <p className="ProjectForm__Title">
        {isCreate ? "Create a project" : "Edit project"}
      </p>
      <Form
        form={form}
        name="projectForm"
        initialValues={initialValues}
        onFinish={handleFinish}
        onValuesChange={handleValueChange}
      >
        <Form.Item
          name="title"
          label="Project name"
          rules={[{ required: true, message: "Enter the project name" }]}
        >
          <Input />
        </Form.Item>

        {isCreate && (
          <>
            <Form.Item
              className="ProjectForm__TaskListName"
              name="list"
              label="Task list name"
              rules={[{ required: true, message: "Enter the task list name" }]}
            >
              <Input />
            </Form.Item>

            <Feature name="under_development">
              <Form.Item name="addSection" label="&nbsp;">
                <AddSectionBtn />
              </Form.Item>
            </Feature>
          </>
        )}

        <Feature name="under_development">
          <Form.Item
            name="autoActivity"
            label="Auto activity"
            tooltip={{
              title: `The chosen template will be applied\nto all newly created task lists but not\nto existing tasks lists.`,
              icon: <InfoCircleOutlinedIcon />,
            }}
            rules={[{ required: true, message: "Enter the auto activity" }]}
          >
            <Select
              placeholder="Select auto activity"
              allowClear
              suffixIcon={<CaretDownIcon />}
            >
              {activityTypes.map(({ title }) => (
                <Option key={title} value={title}>
                  {title}
                </Option>
              ))}
              <Option
                key={activityTypesOrder[activityTypesOrder.length - 1]}
                value={activityTypesOrder[activityTypesOrder.length - 1]}
              >
                {activityTypesOrder[activityTypesOrder.length - 1]}
              </Option>
            </Select>
          </Form.Item>
        </Feature>

        <Form.Item
          name="projectColor"
          label="Project color"
          trigger="onChange"
          valuePropName="defaultValue"
          rules={[{ required: true, message: "Select the project color" }]}
        >
          <ColorPicker
            colors={colors.map((item: ProjectColorObject) => item.value)}
            onChange={handleColorSelect}
          />
        </Form.Item>

        <Form.Item name="description" label="Description">
          <TextArea />
        </Form.Item>

        <Form.Item>
          <Button
            className="ProjectForm__Button"
            type="primary"
            htmlType="submit"
            size="large"
            loading={loading}
          >
            {isCreate ? "Create project" : "Done"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ProjectForm;
