export const workStatuses = {
  working: "working",
  taking_break: "taking_break",
  out_of_office: "out_of_office",
};

export const webSocketEvents = {
  leftOffice: "user_left_office",
  login: "user_login",
  logout: "user_logout",
  startWork: "User start work",
  break: "User break",
  out: "User out",
};

export const projectDefaultColor = "#0000007F";
export const projectsDefaultColorInMenu = "#FFFFFF7F";

export const personalTasksProjectObject: PersonalTasksProjectObject = {
  color: {
    id: "0",
    title: projectDefaultColor,
    value: projectDefaultColor,
  },
  workspace: "0",
  description: "",
  id: null,
  title: "Personal Tasks",
};

export const projectDetailsTabs: { title: string }[] = [
  { title: "Dashboard" },
  { title: "Description" },
  { title: "Team" },
  { title: "Details" },
];

export const dateFormat = "YYYY/MM/DD";

export const checklist = [{}, {}, {}, {}];

export const avatarImageHeightSize = {
  large: 40,
  small: 24,
  default: 32,
};

export const activityTypesOrder = [
  "Comment",
  "Daily Report",
  "Weekly Review",
  "Monthly Report",
  "Objectives",
  "Milestones",
  "Feedback",
  "No activities",
];

export const axiosResponse = {
  status: 200,
  data: {},
  statusText: "",
  headers: undefined,
  config: {},
};

export const menuStaticKeys = {
  OFFICE: "/office",
  TEAM: "/team",
  UNREAD: "/unread",
  CHECKOUT: "/checkout",
  TODAY: "/today",
  NEXT_SHIFT: "/nextShift",
  STARRED: "/starred",
  CALENDAR: "/calendar",
  HISTORY: "/history",
  LIST: "/l/43B5MGQKOY/list-a",
};

export const defaultPage = menuStaticKeys.OFFICE;

export const nonProjectMenuKeys = Object.entries(menuStaticKeys).map(
  ([key, value]) => value
);

export const workDayDateFormat = "YYYY-MM-DD";

export const CHECKOUT_STATUS: {
  CRITICAL: CheckoutStatus;

  NON_CRITICAL: CheckoutStatus;

  PASSED: CheckoutStatus;

  STILL_IN_OFFICE: CheckoutStatus;
} = {
  CRITICAL: 1,

  NON_CRITICAL: 2,

  PASSED: 3,

  STILL_IN_OFFICE: 4,
};

export const TASK_PAGES: {
  TODAY: TaskPage;
  NEXT_SHIFT: TaskPage;
  STARRED: TaskPage;
} = {
  TODAY: "today",
  NEXT_SHIFT: "nextShift",
  STARRED: "starred",
};
export const TASK_PAGE_TITLES: {
  [key: string]: string | TaskPage;
} = {
  TODAY: "Today",
  NEXT_SHIFT: "Next Workday",
  STARRED: "Starred",
};
export const CHECKOUT_CHECKS = {
  UNALLOCATED_TIME: "unallocated_time",
  SCHEDULE_FOR_NEXT_SHIFT: "schedule_for_next_shift",
  CONFIRM_ACTIVITY: "confirm_activity",
  TASK_SCHEDULED_NEXT_WORKDAY: "tasks_scheduled_on_next_workday_missing",
};

export const outOfOfficeStatusType: { id: WorkStatusTypes; title: string } = {
  id: "out_of_office",
  title: "Out of Office",
};

export const CHECKOUT_PANEL_KEY = "checkout";

export const workHoursSources = {
  OFFICE_TIME: "Office time",
  TASKS_TIME: "Tasks time",
  TRACKED_TIME: "Tracked time",
};

export const reducerExtendedBaseDefaultState = {
  error: "",
  fetchInProgress: true,
  createInProgress: {
    inProgress: false,
    recordId: undefined,
  },
  updateInProgress: {
    inProgress: false,
    recordId: undefined,
    updatedKeys: undefined,
  },
  deleteInProgress: {
    inProgress: false,
    recordId: undefined,
  },
};

export const basicReducerDefaultState: BasicReducerState = {
  error: null,
  loading: true,
  creating: false,
};

export enum WorkStatusTypesEnum {
  working = "working",
  taking_break = "taking_break",
  out_of_office = "out_of_office",
}

export enum ChangedValueTypeEnum {
  status_hist_status = "status_hist_status",
  status_hist_start_datetime = "status_hist_start_datetime",
  status_hist_end_datetime = "status_hist_end_datetime",
  status_hist_add = "status_hist_add",
  status_hist_delete = "status_hist_delete",
  work_shift_add = "work_shift_add",
  work_shift_delete = "work_shift_delete",
  work_shift_start_datetime = "work_shift_start_datetime",
  work_shift_end_datetime = "work_shift_end_datetime",
  work_shift_notes = "work_shift_notes",
  task_time_length_seconds = "task_time_length_seconds",
  task_time_record_start_datetime = "task_time_record_start_datetime",
  task_time_delete = "task_time_delete",
  workday_task_history_add = "workday_task_history_add",
  workday_task_history_delete = "workday_task_history_delete",
  activity_add = "activity_add",
  activity_delete = "activity_delete",
  activity_text_json = "activity_text_json",
}
