import React from "react";

import CheckoutModal from "../CheckoutModal/CheckoutModal";
import LeaveTheOfficeBtn from "../../General/LeaveTheOfficeBtn/LeaveTheOfficeBtn";

export interface CheckoutModalToggleBtnProps {
  setDisplayPopover: React.Dispatch<React.SetStateAction<boolean>>;
}
const CheckoutModalToggleBtn: React.FC<CheckoutModalToggleBtnProps> = ({
  setDisplayPopover,
}) => {
  const [displayModal, setDisplayModal] = React.useState(false);

  const showModal = () => {
    setDisplayPopover(false);
    setDisplayModal(true);
  };

  return (
    <div className="CheckoutModalToggleBtn">
      <LeaveTheOfficeBtn onClick={showModal} />

      {/**
       * Add conditional rendering to trigger unmount on checkout panel leave the office mode
       */}
      {displayModal && (
        <CheckoutModal
          displayModal={displayModal}
          mode="leave_the_office"
          setDisplayModal={setDisplayModal}
        />
      )}
    </div>
  );
};

export default CheckoutModalToggleBtn;
