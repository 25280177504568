import React from "react";
import LeaveTheOfficeBtn from "../General/LeaveTheOfficeBtn/LeaveTheOfficeBtn";
import { CloseIcon } from "../CustomIcons/CustomIcons.component";

import Modal from "../General/Modal/Modal";

const SignOutModal = ({
  open,
  onClose,
  onSignOut,
  onLeaveTheOffice,
}: SignOutModalProps) => {
  return (
    <Modal
      className="SignOutModal"
      title="Sign Out"
      prefixText="To end your workday, use the 'Leave the Office' button."
      suffixText={
        <span>
          No, I want to&nbsp;
          <span className="SignOutModal__SignOut" onClick={onSignOut}>
            sign out
          </span>
          &nbsp; of Meistery Workspace on all devices.
        </span>
      }
      centered={true}
      width={512}
      visible={open}
      onCancel={onClose}
      closeIcon={<CloseIcon />}
      footer={null}
    >
      <LeaveTheOfficeBtn onClick={onLeaveTheOffice} />
    </Modal>
  );
};

export default SignOutModal;
