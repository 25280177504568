const userDetails: AccountUser = {
  avatar: null,
  city: "",
  country: "Philippines",
  display_name: "Briggss",
  email: "briggs@meistery.net",
  external_calendar_config_json: {
    calendar_url:
      "https://calendar.google.com/calendar/ical/briggs%40meistery.net/private-28c70dc4ceb932e681bb13c5876c9316/basic.ics",
  },
  external_tracker_config_json: {
    api_key: "YlaJn2cjMN1IHG9qRsbRTJpR3E9fdZZQ6sXm2N04",
    projectID: "61286",
    userID: "68644",
  },
  full_name: "Briggs Victoria",
  id: "9LBX5JWFS1",
  phone_number: "",
  team_page_access_granted_yn: true,
  timezone: "UTC",
  workspace: "6HDCHAGWWA",
  workspace_role: "ADMIN",
  workspace_name: "Workspace 1",
  what_i_do: "DEV",
  has_beta_version_access_yn: true,
  using_beta_version_yn: true,
};

export const alternateUserDetails: any = {
  id: "3",
  timezone: "UTC",
  email: "briggs@meistery.net",
  full_name: "briggs victoria",
  avatar:
    "http://18.180.218.209/media/media_files/profile_pictures/sample_avatar_SBOLRhS.png",
  external_tracker_config_json: {
    userID: "68644",
    api_key: "YlaJn2cjMN1IHG9qRsbRTJpR3E9fdZZQ6sXm2N04",
    projectID: "61286",
  },
  external_calendar_config_json: {
    calendar_url:
      "https://calendar.google.com/calendar/ical/briggs%40meistery.net/private-28c70dc4ceb932e681bb13c5876c9316/basic.ics",
  },
  team_page_access_granted_yn: true,
  workspace_name: "Workspace 1",
  what_i_do: "DEV",
};

export const otherUserDetails: AccountUser = {
  avatar: null,
  city: "",
  country: "",
  display_name: "Admin Example",
  email: "admin@example.com",
  external_calendar_config_json: null,
  external_tracker_config_json: null,
  full_name: "Admin",
  id: "EGUN7PV8KA",
  phone_number: "",
  team_page_access_granted_yn: true,
  timezone: "UTC",
  workspace: "6HDCHAGWWA",
  workspace_role: "MEMBER",
  workspace_name: "Workspace 1",
  what_i_do: "Manager",
  has_beta_version_access_yn: true,
  using_beta_version_yn: true,
};

export const otherAlternateUserDetails: any = {
  id: "166",
  timezone: "UTC",
  email: "qauserA@epic8.com",
  full_name: "User A Epic 8",
  avatar:
    "http://13.230.211.75/media/media_files/profile_pictures/avatar_user_A_lTlrSlC.png",
  external_tracker_config_json: {
    userID: "9999",
    api_key: "9999",
    projectID: "9999",
  },
  external_calendar_config_json: {
    calendar_url:
      "https://calendar.google.com/calendar/ical/c_qfqsprgqvpm0dqidee8mlet7bc%40group.calendar.google.com/private-ee5f0a55553f68ac4f3ebe1e6727ade7/basic.ics",
  },
  team_page_access_granted_yn: true,
  workspace_name: "Workspace 1",
  what_i_do: "Manager",
};

export { userDetails as default };
