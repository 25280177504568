export const unreadActivities: UnreadActivity[] = [{
    id: '1',
    task: '1',
    username: 'Edward',
    avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
    activity_type: 1,
    created_datetime: "2021-02-21T06:40:42.046336Z",
    created_automatically_yn: false,
    last_update_date: "2021-02-21T06:40:42.046367Z",
    confirmed_yn: true,
    confirmed_datetime: "2021-02-21T06:40:04.388122Z",
    submitted_yn: true,
    submitted_datetime: "2021-02-21T06:40:04.388158Z",
    user: '1',
    deleted_yn: false,
    text_json: [
      {
        section_title: "Priorities",
        display_title: true,
        display_title_in_edit_yn: true,
        display_title_in_read_yn: true,
        display_section_in_read_if_empty_yn: false,
        section_text:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        input_required_yn: false,
      },
      {
        section_title: "Report",
        display_title: true,
        display_title_in_edit_yn: true,
        display_title_in_read_yn: true,
        display_section_in_read_if_empty_yn: false,
        input_required_yn: false,
        section_text:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it.",
      },
      {
        section_title: "Next steps",
        display_title: true,
        display_title_in_edit_yn: true,
        display_title_in_read_yn: true,
        display_section_in_read_if_empty_yn: false,
        input_required_yn: false,
        section_text:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
    ],
  }, {
    id: '2',
    task: '1',
    username: 'Edward',
    avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
    activity_type: 1,
    created_datetime: "2021-02-23T06:40:42.046336Z",
    created_automatically_yn: false,
    last_update_date: "2021-02-23T06:40:42.046367Z",
    confirmed_yn: true,
    confirmed_datetime: "2021-02-23T06:40:04.388122Z",
    submitted_yn: true,
    submitted_datetime: "2021-02-23T06:40:04.388158Z",
    user: '1',
    deleted_yn: false,
    text_json: [
      {
        section_title: "Comment",
        display_title: false,
        display_title_in_edit_yn: true,
        display_title_in_read_yn: false,
        display_section_in_read_if_empty_yn: true,
        input_required_yn: false,
        section_text:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it.",
      },
    ],
  }, {
    id: '3',
    task: '2',
    username: 'John',
    avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
    activity_type: 1,
    created_datetime: "2021-02-23T06:40:42.046336Z",
    created_automatically_yn: false,
    last_update_date: "2021-02-23T06:40:42.046367Z",
    confirmed_yn: true,
    confirmed_datetime: "2021-02-23T06:40:04.388122Z",
    submitted_yn: true,
    submitted_datetime: "2021-02-23T06:40:04.388158Z",
    user: '1',
    deleted_yn: false,
    text_json: [
      {
        section_title: "Priorities",
        display_title: true,
        display_title_in_edit_yn: true,
        display_title_in_read_yn: true,
        display_section_in_read_if_empty_yn: false,
        input_required_yn: false,
        section_text:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
      {
        section_title: "Report",
        display_title: true,
        display_title_in_edit_yn: true,
        display_title_in_read_yn: true,
        display_section_in_read_if_empty_yn: false,
        input_required_yn: false,
        section_text:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it.",
      },
      {
        section_title: "Next steps",
        display_title: true,
        display_title_in_edit_yn: true,
        display_title_in_read_yn: true,
        display_section_in_read_if_empty_yn: false,
        input_required_yn: false,
        section_text:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
      },
    ],
  }, {
    id: '4',
    task: '3',
    username: 'John',
    avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
    activity_type: 1,
    created_datetime: "2021-02-23T06:40:42.046336Z",
    created_automatically_yn: false,
    last_update_date: "2021-02-23T06:40:42.046367Z",
    confirmed_yn: true,
    confirmed_datetime: "2021-02-23T06:40:04.388122Z",
    submitted_yn: true,
    submitted_datetime: "2021-02-23T06:40:04.388158Z",
    user: '1',
    deleted_yn: false,
    text_json: [
      {
        section_title: "Comment",
        display_title: false,
        display_title_in_edit_yn: true,
        display_title_in_read_yn: false,
        display_section_in_read_if_empty_yn: true,
        input_required_yn: false,
        section_text:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it.",
      },
    ],
  }, {
    id: '5',
    task: '4',
    username: 'John',
    avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
    activity_type: 1,
    created_datetime: "2021-02-23T06:40:42.046336Z",
    created_automatically_yn: false,
    last_update_date: "2021-02-23T06:40:42.046367Z",
    confirmed_yn: true,
    confirmed_datetime: "2021-02-23T06:40:04.388122Z",
    submitted_yn: true,
    submitted_datetime: "2021-02-23T06:40:04.388158Z",
    user: '1',
    deleted_yn: false,
    text_json: [
      {
        section_title: "Comment",
        display_title: false,
        display_title_in_edit_yn: true,
        display_title_in_read_yn: false,
        display_section_in_read_if_empty_yn: true,
        input_required_yn: false,
        section_text:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it.",
      },
    ],
  }, {
    id: '6',
    task: '1',
    username: 'Edward',
    avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
    activity_type: 1,
    created_datetime: "2021-03-09T06:40:42.046336Z",
    created_automatically_yn: false,
    last_update_date: "2021-03-09T06:40:42.046336Z",
    confirmed_yn: true,
    confirmed_datetime: "2021-03-09T06:40:42.046336Z",
    submitted_yn: true,
    submitted_datetime: "2021-03-09T06:40:42.046336Z",
    user: '1',
    deleted_yn: false,
    text_json: [
      {
        section_title: "Comment",
        display_title: false,
        display_title_in_edit_yn: true,
        display_title_in_read_yn: false,
        display_section_in_read_if_empty_yn: true,
        input_required_yn: false,
        section_text:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it.",
      },
    ],
  }, {
    id: '7',
    task: '1',
    username: 'Edward',
    avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
    activity_type: 1,
    created_datetime: "2021-03-09T06:40:42.046336Z",
    created_automatically_yn: false,
    last_update_date: "2021-03-09T06:40:42.046336Z",
    confirmed_yn: true,
    confirmed_datetime: "2021-03-09T06:40:42.046336Z",
    submitted_yn: true,
    submitted_datetime: "2021-03-09T06:40:42.046336Z",
    user: '1',
    deleted_yn: false,
    text_json: [
      {
        section_title: "Comment",
        display_title: false,
        display_title_in_edit_yn: true,
        display_title_in_read_yn: false,
        display_section_in_read_if_empty_yn: true,
        input_required_yn: false,
        section_text:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it.",
      },
    ],
  }, {
    id: '8',
    task: '1',
    username: 'Edward',
    avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
    activity_type: 1,
    created_datetime: "2021-03-09T06:40:42.046336Z",
    created_automatically_yn: false,
    last_update_date: "2021-03-09T06:40:42.046336Z",
    confirmed_yn: true,
    confirmed_datetime: "2021-03-09T06:40:42.046336Z",
    submitted_yn: true,
    submitted_datetime: "2021-03-09T06:40:42.046336Z",
    user: '1',
    deleted_yn: false,
    text_json: [
      {
        section_title: "Comment",
        display_title: false,
        display_title_in_edit_yn: true,
        display_title_in_read_yn: false,
        display_section_in_read_if_empty_yn: true,
        input_required_yn: false,
        section_text:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it.",
      },
    ],
  },
];

export const unreadProjects: UnreadProject[] = [
  {
    id: '1',
    project_name: "Chatbot System",
    lists: [
      {
        id: '1',
        list_name: "Project Management",
        sections: [
          {
            id: '1',
            section_name: "Archived",
            tasks: [
              {
                id: '1',
                task_name: "Complete analysis of chatbot logs",
                activity_count: unreadActivities.filter((msg) => msg.task === '1')
                  .length,
              },
            ],
          },
        ],
      },
      {
        id: '2',
        list_name: "Development",
        sections: [
          {
            id: '2',
            section_name: "On Hold",
            tasks: [
              {
                id: '2',
                task_name: "Template message Implementation",
                activity_count: unreadActivities.filter((msg) => msg.task === '2')
                  .length,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: '2',
    project_name: "Data Forecaster",
    lists: [
      {
        id: '3',
        list_name: "Sales",
        sections: [
          {
            id: '3',
            section_name: "In Progress",
            tasks: [
              {
                id: '3',
                task_name: "Model Training",
                activity_count: unreadActivities.filter((msg) => msg.task === '3')
                  .length,
              },
              {
                id: '4',
                task_name: "Model Report",
                activity_count: unreadActivities.filter((msg) => msg.task === '4')
                  .length,
              },
            ],
          },
        ],
      },
    ],
  },
];
