import React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Popover, Typography } from "antd";
import moment from "moment";

import {
  NormalPanelIcon,
  CompactPanelIcon,
  LoadingIcon,
} from "../../CustomIcons/CustomIcons.component";
import FilterTasksButton from "../FilterTasksButton/FilterTasksButton";
import TaskSearchBar from "../TaskSearchBar/TaskSearchBar";

import { menuStaticKeys, TASK_PAGES } from "../../../constants/constants";
import { selectNextCalendarScheduleState } from "../../../reducers/third-party-calendar.reducer";

const { Title } = Typography;

export const panelsPopoverContent = (
  <div className="PanelHeader__ActionsContainer__Action__Popover__Content PanelHeader__ActionsContainer__Action__Popover__Content--Task">
    <div>
      <span>Normal view</span>
      <NormalPanelIcon />
    </div>
    <div>
      <span>Compact view</span>
      <CompactPanelIcon />
    </div>
  </div>
);

const TaskPanelHeader = () => {
  const { pathname, search } = useLocation();

  const nextCalendarScheduleState = useSelector(
    selectNextCalendarScheduleState
  );
  const {
    data: nextCalendarSchedule,
    loading: nextCalendarScheduleLoading,
  } = nextCalendarScheduleState;
  const hasNextSchedule = nextCalendarSchedule.length > 0;

  const taskPageParams = new URLSearchParams(search).get("p") as TaskPage;
  const pathTitle = taskPageParams
    ? taskPageParams
    : (pathname.replace(/\//g, "") as TaskPage);
  const nextShiftTitle = hasNextSchedule
    ? `Next Workday (${moment(nextCalendarSchedule[0].start_datetime).format(
        "dddd, MMMM D"
      )})`
    : "Next Workday";
  const isNextShift =
    pathname === menuStaticKeys.NEXT_SHIFT ||
    taskPageParams === TASK_PAGES.NEXT_SHIFT;

  const title = isNextShift ? nextShiftTitle : pathTitle;

  return (
    <div className="TaskPanelHeader">
      <div className="TaskPanelHeader__Container">
        <div className="TaskPanelHeader__Container__Wrapper">
          <Title
            level={4}
            className="TaskPanelHeader__Container__Wrapper__Title"
          >
            <span className="TaskPanelHeader__Container__Wrapper__Title__Text">
              {title}
              {nextCalendarScheduleLoading && isNextShift && (
                <>
                  &nbsp;
                  <LoadingIcon />
                </>
              )}
            </span>
          </Title>
          <div className="TaskPanelHeader__Container__Wrapper__ActionsContainer">
            <FilterTasksButton />
          </div>
          {/* Temporarily removed until functionality is implemented
          <div className="TaskPanelHeader__Container__Wrapper__ActionsContainer">
            <div className="TaskPanelHeader__Container__Wrapper__ActionsContainer__Action">
              <FilterIcon />
            </div>
            
            <Popover
              content={panelsPopoverContent}
              trigger="click"
              placement="bottom"
              overlayClassName="TaskPanelHeader__Container__Wrapper__ActionsContainer__Action__Popover"
              getPopupContainer={(trigger: any) => trigger}
            >
              <div className="TaskPanelHeader__Container__Wrapper__ActionsContainer__Action">
                <NormalPanelIcon />
              </div>
            </Popover>
          
          </div> */}
        </div>
        <TaskSearchBar panelType="task" taskPage={pathTitle} />
      </div>
    </div>
  );
};

export default TaskPanelHeader;
