import { Dispatch } from "redux";
import { getSectionListService } from "../services/section.service";
import { setError } from "../actions/error.action";
import { dispatchError } from "../utils/error.util";

export const requestFetchSections = () => ({
  type: "REQUEST_FETCH_SECTIONS",
});
export const requestFetchSectionsSuccess = (sections: SectionArray) => ({
  type: "REQUEST_FETCH_SECTIONS_SUCCESS",
  sections,
});
export const requestFetchSectionsFailed = (error: string) => ({
  type: "REQUEST_FETCH_SECTIONS_FAILED",
  error,
});
export const startPopulateSections = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(requestFetchSections());
      const response = await getSectionListService();
      if (response.status === 200) {
        const sections: SectionArray = response.data;
        const sorted = sections.sort((a, b) => {
          return a.title.localeCompare(b.title);
        });
        dispatch(requestFetchSectionsSuccess(sorted));
      } else {
        const error = "getSectionListService status is not 200";
        dispatch(requestFetchSectionsFailed(error));
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestFetchSectionsFailed(error));
      dispatchError({
        e,
        title: "Populate sections error",
      });
    }
  };
};
