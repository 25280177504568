import React from "react";

import {
  LoadingIcon,
  NoTaskIcon,
} from "../../CustomIcons/CustomIcons.component";

/**
 * Custom Display
 * Displays the placeholder when no project or task details are shown
 */
export interface DetailsProps {
  loading?: boolean;
}
const Details: React.FC<DetailsProps> = ({ loading = false }) => {
  return (
    <div className="Details">
      <div className="Details__Message">
        {loading ? (
          <LoadingIcon />
        ) : (
          <>
            <NoTaskIcon className="Details__Message__Icon" />
            <span className="Details__Message__Title">No task selected</span>
            <span className="Details__Message__Text">
              Click a task card to see its details.
            </span>
          </>
        )}
      </div>
    </div>
  );
};

export { Details as default };
