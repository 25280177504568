import React from "react";
import { Moment } from "moment";

import CustomDateRangePicker, {
  CustomDateRangePickerProps,
} from "../../CustomDateRangePicker/CustomDateRangePicker";

export interface HistoryPanelHeaderProps {
  handleChangeCalendar: CustomDateRangePickerProps["onCalendarChange"];
  handlePresetClick: CustomDateRangePickerProps["onPresetClick"];
  historyStartDate?: Moment;
  historyEndDate?: Moment;
  loading: boolean;
}

const HistoryPanelHeader: React.FC<HistoryPanelHeaderProps> = ({
  handleChangeCalendar,
  handlePresetClick,
  historyStartDate,
  historyEndDate,
  loading,
}) => {
  return (
    <div className="HistoryPanelHeader">
      <div className="HistoryPanelHeader__Title">
        <span>History</span>
      </div>
      <div className="HistoryPanelHeader__Actions">
        <CustomDateRangePicker
          onCalendarChange={handleChangeCalendar}
          onPresetClick={handlePresetClick}
          startDateValue={historyStartDate}
          endDateValue={historyEndDate}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default HistoryPanelHeader;
