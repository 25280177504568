import React from "react";
import classNames from "classnames";
import { Button, ButtonProps as AntButtonProps } from "antd";

/**
 * Default Display
 * Extends and displays re-styled Ant Design Button
 * https://ant.design/components/button/
 *
 * Functionality
 * Takes similar props as Ant Design Button
 */
export interface CustomButtonProps extends Omit<AntButtonProps, "type"> {
  type?: AntButtonProps["type"] | "success" | "danger";
}
const CustomButton: React.FC<CustomButtonProps> = ({
  className = "",
  type,
  ...rest
}) => {
  return (
    <Button
      className={classNames("CustomButton", className, {
        "CustomButton--Success": type === "success",
      })}
      type={type as AntButtonProps["type"]}
      {...rest}
    ></Button>
  );
};

export { CustomButton as default };
