import React from "react";
import cn from "classnames";

import { TaskSearchBarContext } from "../TaskSearchBar/TaskSearchBar";

import Priority from "../../Tasks/Priority/Priority";
import TaskAssignees from "../../Tasks/TaskAssignees/TaskAssignees";
import TaskCardTags from "../../Tasks/TaskCardTags/TaskCardTags";
import TaskDestinationDisplay from "../../Tasks/TaskDestinationDisplay/TaskDestinationDisplay";
import ToggleDoToday from "../../Tasks/ToggleDoToday/ToggleDoToday";
import AddToStarred from "../../Tasks/AddToStarred/AddToStarred";
import AddToNextShift from "../../Tasks/AddToNextShift/AddToNextShift";
import AddRemoveToFromWorkDay from "../../Tasks/AddRemoveToFromWorkDay/AddRemoveToFromWorkDay";

import { checklist, TASK_PAGES } from "../../../constants/constants";

/**
 * Task Card Item displayed in the search bar
 *
 * Default Display
 * Displays consolidated task object (task, join_task_to_user, scheduled task)
 * Displays the priority of a task (functional)
 * Displays the title of a task
 * Displays the parent project and task folder of a task
 * Displays the date of scheduled task (if existing)
 *
 * Hover Display
 * Displays if task is for today (functional)
 * Displays if a task is added to my activity (functional)
 * Displays the owner of the task and the contributors count
 */
export interface SearchTaskCardItemProps {
  task: TaskJoinedToUserAndScheduledObject;
}
const SearchTaskCardItem: React.FC<SearchTaskCardItemProps> = ({ task }) => {
  const context = React.useContext(TaskSearchBarContext);
  const {
    activitiesSummaryType,
    handleTaskSelect,
    isMerge,
    searchText,
    taskPage,
  } = context;

  const {
    title: taskTitle,
    join_task_to_user_id: joinTaskToUserId,
    priority,
    today_yn,
  } = task;

  const [hovered, setHovered] = React.useState(false);

  const TaskTitleSearchTextHighlighted = () => {
    const htmlText = taskTitle.replace(
      searchText,
      `<mark>${searchText}</mark>`
    );
    return <span dangerouslySetInnerHTML={{ __html: htmlText }} />;
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleClick = () => {
    if (isMerge && handleTaskSelect) {
      handleTaskSelect(task);
    }
  };

  const TaskPageSearchTaskCardAction = () => {
    switch (taskPage) {
      case TASK_PAGES.TODAY:
        return <ToggleDoToday task={task} search={true} />;
      case TASK_PAGES.NEXT_SHIFT:
        return <AddToNextShift task={task} search={true} />;
      case TASK_PAGES.STARRED:
        return <AddToStarred task={task} search={true} />;
      default:
        return <></>;
    }
  };

  const ActivitiesSummaryTypeSearchTaskCardAction = () => {
    switch (activitiesSummaryType) {
      case "next-workday":
        return <AddToNextShift task={task} search={true} />;
      case "checkout":
      default:
        return <AddRemoveToFromWorkDay task={task} />;
    }
  };

  const SearchTaskCardAction = activitiesSummaryType
    ? ActivitiesSummaryTypeSearchTaskCardAction
    : TaskPageSearchTaskCardAction;

  return (
    <div
      className={cn("SearchTaskCardItem", {
        "SearchTaskCardItem--Hovered": hovered,
        "SearchTaskCardItem--Merge": isMerge,
      })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <div className="SearchTaskCardItem__Header">
        <div className="SearchTaskCardItem__Header__Left">
          <Priority task={task} />
          <div className="SearchTaskCardItem__Header__Left__Title">
            <TaskTitleSearchTextHighlighted />
          </div>
        </div>

        <div className="SearchTaskCardItem__Header__Right">
          {hovered && <SearchTaskCardAction />}
        </div>
      </div>
      <TaskDestinationDisplay task={task} />
      <div className="SearchTaskCardItem__Footer">
        <TaskCardTags checklist={checklist} task={task} />
        <TaskAssignees task={task} isMouseEnterCard={hovered} />
      </div>
    </div>
  );
};

export { SearchTaskCardItem as default };
