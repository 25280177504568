import { Modal } from "antd";
import React from "react";

import Button from "../../../Button/Button";
import { MergeIcon } from "../../../CustomIcons/CustomIcons.component";
import MergeTaskModal, {
  MergeTaskModalHandleOkParams,
} from "../MergeTaskModal/MergeTaskModal";

export interface MergeTaskButtonProps {
  panelType: PanelType;
  task: TaskJoinedToUserAndScheduledObject;
}
const MergeTaskButton: React.FC<MergeTaskButtonProps> = ({
  panelType,
  task,
}) => {
  const [openModal, setOpenModal] = React.useState(false);

  const handleOk = (data: MergeTaskModalHandleOkParams) => {
    /**
     * TODO 13.11.6 - process data from merge task modal
     */
    alert("Merge task logic to be applied after actual API integration");
  };

  const handleCancel = () => {
    setOpenModal(false);
  };

  return (
    <>
      <div className="MergeTaskButton" onClick={() => setOpenModal(true)}>
        <MergeIcon />
        <span>Merge into</span>
      </div>
      <MergeTaskModal
        handleOk={handleOk}
        handleCancel={handleCancel}
        panelType={panelType}
        sourceTask={task}
        visible={openModal}
      />
    </>
  );
};

export default MergeTaskButton;
