import React from "react";
import { isEmpty } from "lodash";
import cn from "classnames";

import CustomAvatar from "../../CustomAvatar/CustomAvatar";

import { formatDuration } from "../../../utils/time-conversion.util";
import { HistoryPanelContext } from "../../Tasks/HistoryPanel/HistoryPanel";
import { HistoryTabPaneContext } from "../../Team/TeamPanel/HistoryTabPane/HistoryTabPane";
import { getInitials } from "../../../utils/string.util";

const ItemUsersTaskTimeInWorkDayAndTask: React.FC<ItemUsersTaskTimeInWorkDayAndTaskProps> = ({
  taskId,
  type,
  userObject,
  workDate,
}) => {
  const historyPanelContext = React.useContext(HistoryPanelContext);
  const historyTabPaneContext = React.useContext(HistoryTabPaneContext);
  const { tableData: historyPanelTableData } = historyPanelContext;
  const { shownObservableUserWithTableDataArray } = historyTabPaneContext;

  const [workDateAllocatedTime, setWorkDateAllocatedTime] = React.useState(0);
  const [workDateHasShift, setWorkDateHasShift] = React.useState(false);

  const { id: userId, display_name: userDisplayName = "" } = userObject;

  React.useEffect(() => {
    if (historyPanelTableData) {
      const workDateAllocatedTime = historyPanelTableData.records
        .flatMap(({ date, shifts }) => {
          if (date === workDate && shifts && shifts.length > 0) {
            setWorkDateHasShift(true);
            return shifts
              .map(({ allocated_time }) => allocated_time)
              .reduce((p, c) => p + c, 0);
          } else {
            return 0;
          }
        })
        .reduce((p, c) => p + c, 0);
      setWorkDateAllocatedTime(workDateAllocatedTime);
    }
  }, [historyPanelTableData, workDate]);

  React.useEffect(() => {
    if (shownObservableUserWithTableDataArray) {
      const shownObservableUserWithTableDataObject = shownObservableUserWithTableDataArray.find(
        ({ shownObservableUser }) =>
          shownObservableUser.observed_user.id === userId
      );
      const { tableData } = shownObservableUserWithTableDataObject || {};
      if (tableData) {
        const workDateAllocatedTime = tableData.records
          .flatMap(({ date, shifts }) => {
            if (date === workDate && shifts && shifts.length > 0) {
              setWorkDateHasShift(true);
              return shifts
                .map(({ allocated_time }) => allocated_time)
                .reduce((p, c) => p + c, 0);
            } else {
              return 0;
            }
          })
          .reduce((p, c) => p + c, 0);
        setWorkDateAllocatedTime(workDateAllocatedTime);
      }
    }
  }, [JSON.stringify(shownObservableUserWithTableDataArray), userId]);

  return (
    <>
      {!isEmpty(userObject) && !(type === "team" && !workDateHasShift) && (
        <div
          className={cn("ItemUsersTaskTimeInWorkDayAndTask", {
            "ItemUsersTaskTimeInWorkDayAndTask--Team": type === "team",
          })}
        >
          {type === "team" && (
            <>
              <CustomAvatar
                size={18}
                src={userObject.avatar}
                work_status={userObject.current_status?.status_enum_identifier}
                fallbackText={getInitials(userDisplayName)}
              />
              <span className="ItemUsersTaskTimeInWorkDayAndTask__Name">
                {userDisplayName}
              </span>
            </>
          )}
          <span className="ItemUsersTaskTimeInWorkDayAndTask__TaskTime">
            {formatDuration(workDateAllocatedTime)}
          </span>
        </div>
      )}
    </>
  );
};

export default ItemUsersTaskTimeInWorkDayAndTask;
