import React, { ReactNode } from "react";
import { Table } from "antd";
import cn from "classnames";
import { Moment } from "moment";
import { filter } from "lodash";
import { useSelector } from "react-redux";

import CheckoutModal from "../../Checkout/CheckoutModal/CheckoutModal";
import {
  TableGeneralViewIcon,
  TableDetailedViewIcon,
  LoadingIcon,
} from "../../CustomIcons/CustomIcons.component";
import WorkTimeRecordTableSummary from "./WorkTimeRecordTableSummary/WorkTimeRecordTableSummary";

import {
  getColumns,
  TableView,
} from "../../../constants/work-time-record-table.constant";
import { selectStatusState } from "../../../reducers/status.reducer";
import {
  minutesToMs,
  minutesToSeconds,
} from "../../../utils/time-conversion.util";
import { workStatuses } from "../../../constants/constants";
import { selectCurrentWorkDayState } from "../../../reducers/work-day.reducer";

export const viewArray: TableView[] = ["standard", "detailed"];

const WorkTimeRecordTable: React.FC<WorkTimeRecordTableProps> = ({
  isLoading,
  tableData,
  type,
}) => {
  let interval = React.useRef<number>(0);

  const [view, setView] = React.useState<TableView>("standard");
  const [displayCheckoutModal, setDisplayCheckoutModal] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState<UserObject>();
  const [selectedDate, setSelectedDate] = React.useState<Moment>();
  const [columnData, setColumnData] = React.useState<
    any
    // temporarily set to any to resolve AntD typing error, previous type - WorkTimeRecordTableColumnArray
  >();
  const [dataSource, setDataSource] = React.useState<
    any
    // temporarily set to any to resolve AntD typing error, previous type - UserTaskTimeTableDataResponse["records"]
  >();

  const {
    data: { id: currentStatusId },
  } = useSelector(selectStatusState);
  const {
    data: { work_date: currentWorkDayDate },
  } = useSelector(selectCurrentWorkDayState);

  const handleClickAction = (view: TableView) => (e: React.MouseEvent) => {
    setView(view);
  };

  React.useEffect(() => {
    const clickWorkHoursCallback = (user: UserObject, date: Moment) => {
      setSelectedUser(user);
      setSelectedDate(date);
      setDisplayCheckoutModal(true);
    };

    setColumnData(getColumns({ view, type, clickWorkHoursCallback }));
  }, [view, type]);

  React.useEffect(() => {
    if (tableData && tableData.records.length > 0) {
      setDataSource(tableData.records);
    } else {
      setDataSource(undefined);
    }
  }, [tableData]);

  React.useEffect(() => {
    // search for record that is current work date and has unfinished shifts
    const recordToUpdate = dataSource
      ? (filter(dataSource, function (record) {
          return (
            record.date === currentWorkDayDate &&
            record.shifts?.find(
              ({ end_datetime }: any) => end_datetime === null
            )
          );
        }) as UserTaskTimeTableDataRecordArray)
      : ([] as UserTaskTimeTableDataRecordArray);

    // increment record work hours and office or break hours depending on current status
    const recordWithIncrementedTime = recordToUpdate
      ? (recordToUpdate.map((record) => {
          const { shifts, work_hours } = record;
          const { total_seconds = 0 } = work_hours || {};

          return {
            ...record,
            shifts: shifts?.map((shift) => {
              const {
                end_datetime,
                office_hours_in_seconds,
                break_in_seconds,
              } = shift;
              if (end_datetime === null) {
                return {
                  ...shift,
                  office_hours_in_seconds:
                    currentStatusId === workStatuses.working
                      ? office_hours_in_seconds + minutesToSeconds(1)
                      : office_hours_in_seconds,
                  break_in_seconds:
                    currentStatusId === workStatuses.taking_break
                      ? break_in_seconds + minutesToSeconds(1)
                      : break_in_seconds,
                };
              }
              return shift;
            }),
            work_hours: {
              ...work_hours,
              total_seconds: total_seconds + minutesToSeconds(1),
            },
          };
        })[0] as UserTaskTimeTableDataRecordObject)
      : undefined;

    interval.current = window.setInterval(() => {
      setDataSource((c: any) => {
        if (c && recordWithIncrementedTime) {
          return c.map((record: any) => {
            const { date } = record;
            if (date === recordWithIncrementedTime.date) {
              return recordWithIncrementedTime;
            }
            return record;
          });
        }
        return c;
      });
    }, minutesToMs(1));

    return () => {
      window.clearInterval(interval.current);
    };
  }, [dataSource, interval, currentStatusId, currentWorkDayDate]);

  const viewsJsx = viewArray.map((viewValue, index) => {
    const Component =
      viewValue === "detailed" ? TableDetailedViewIcon : TableGeneralViewIcon;
    return (
      <Component
        key={index}
        className={cn("WorkTimeRecordTable__Header__Actions__Item", {
          "WorkTimeRecordTable__Header__Actions__Item--Active":
            viewValue === view,
        })}
        onClick={handleClickAction(viewValue)}
      />
    );
  });

  const tableSummary: (
    data: readonly BaseWorkTimeRecordTableObject[]
  ) => ReactNode = (data) => {
    if (type === "date" && tableData) {
      return <WorkTimeRecordTableSummary tableData={tableData} view={view} />;
    } else {
      return undefined;
    }
  };

  return (
    <div className="WorkTimeRecordTable">
      <div className="WorkTimeRecordTable__Header">
        <div className="WorkTimeRecordTable__Header__Title">
          <span className="WorkTimeRecordTable__Header__Title__Text">
            Worktime
          </span>
        </div>
        <div className="WorkTimeRecordTable__Header__Actions">{viewsJsx}</div>
      </div>

      {selectedDate && (
        <CheckoutModal
          checkoutUser={selectedUser}
          date={selectedDate}
          displayModal={displayCheckoutModal}
          mode="history"
          setDisplayModal={setDisplayCheckoutModal}
        />
      )}
      {columnData && (
        <Table
          className={cn(
            "WorkTimeRecordTable__Table",
            `WorkTimeRecordTable__Table--${view}`
          )}
          columns={columnData}
          dataSource={dataSource}
          pagination={false}
          summary={tableSummary}
          loading={{
            spinning: isLoading,
            indicator: <LoadingIcon />,
          }}
        ></Table>
      )}
    </div>
  );
};

export default WorkTimeRecordTable;
