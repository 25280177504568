import React from "react";
import { useSelector } from "react-redux";
import { useParams, useMatch } from "react-router-dom";
import { Tooltip } from "antd";
import cn from "classnames";

import useAsyncTasksInProject from "../../../hooks/useAsyncTasksInProject.hook";

import CustomButton from "../../CustomButton/CustomButton";
import ProjectSelection from "../../Projects/ProjectSelection/ProjectSelection";
import ListSelection from "../../List/ListSelection/ListSelection";
import SectionSelection from "../../Section/SectionSelection/SectionSelection";

import { selectListsState } from "../../../reducers/list.reducer";
import { selectProjectsState } from "../../../reducers/projects.reducer";
import {
  selectListsInProject,
  selectListById,
} from "../../../selectors/list.selector";
import { selectProjectOfList } from "../../../selectors/project.selector";
import { selectSectionsInList } from "../../../selectors/section.selector";

/**
 * SelectTaskDesinationPopoverContent displays the destination and position selection of tasks
 *
 * Default Display
 * ProjectSelection, ListSelection, SectionSelection components are displayed
 *
 * Hover Display
 * Error message is displayed as tooltip text when create button is hovered and error message is not a blank string
 *
 * Conditional Display
 * Create button is disabled if error message is not a blank string
 */
export interface ActionClickCallbackParams {
  selectedProject: ProjectObject;
  selectedList: ListObject;
  selectedSection: SectionObject;
  selectedPosition: number;
}
export interface SelectTaskDesinationPopoverContentProps {
  action: string;
  actionClickCallback: Function;
  cancelClickCallback: Function;
  panelType: PanelType;
  searchText?: string;
}
const SelectTaskDesinationPopoverContent: React.FC<SelectTaskDesinationPopoverContentProps> = ({
  action,
  actionClickCallback,
  cancelClickCallback,
  panelType,
  searchText = "",
}) => {
  const isListFormat = useMatch("/l/:listId/:listName");
  const isList = React.useMemo(() => panelType === "list" && isListFormat, [
    panelType,
    isListFormat,
  ]);

  const { listId = "" } = useParams<{ listId: string }>();

  const { data: projects, loading: projectsLoading } = useSelector(
    selectProjectsState
  );
  const { data: lists } = useSelector(selectListsState);

  const [selectedProject, setSelectedProject] = React.useState<ProjectObject>();
  const [selectedList, setSelectedList] = React.useState<ListObject>();
  const [selectedSection, setSelectedSection] = React.useState<SectionObject>();
  const [selectedPosition, setSelectedPosition] = React.useState<number>();
  const [
    listsInSelectedProject,
    setListsInSelectedProject,
  ] = React.useState<ListArray>([]);
  const [
    sectionsInSelectedList,
    setSectionsInSelectedList,
  ] = React.useState<SectionArray>([]);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [
    showProjectSelectionDropdown,
    setShowProjectSelectionDropdown,
  ] = React.useState(false);
  const [
    showListSelectionDropdown,
    setShowListSelectionDropdown,
  ] = React.useState(false);
  const [
    showSectionSelectionDropdown,
    setShowSectionSelectionDropdown,
  ] = React.useState(false);
  const [
    showPositionSelectionDropdown,
    setShowPositionSelectionDropdown,
  ] = React.useState(false);

  const { id: selectedProjectId } = selectedProject || {};

  const [
    tasksInSelectedProject,
    tasksInSelectedProjectLoading,
  ] = useAsyncTasksInProject(selectedProjectId);

  const handleSelectProject = (project: ProjectObject) => {
    setSelectedProject(project);
    setSelectedList(undefined);
    setSelectedSection(undefined);
    setSelectedPosition(undefined);
    closeAllDropdown();
  };
  const handleSelectList = (list: ListObject) => {
    setSelectedList(list);
    setSelectedSection(undefined);
    setSelectedPosition(undefined);
    closeAllDropdown();
  };
  const handleSelectSection = (section: SectionObject) => {
    setSelectedSection(section);
    setSelectedPosition(undefined);
    closeAllDropdown();
  };
  const handleSelectPosition = (position: number) => {
    setSelectedPosition(position);
    closeAllDropdown();
  };

  const handleToggleDropdownProject = () => {
    setShowProjectSelectionDropdown((c) => !c);
    setShowListSelectionDropdown(false);
    setShowSectionSelectionDropdown(false);
  };
  const handleToggleDropdownList = () => {
    setShowListSelectionDropdown((c) => !c);
    setShowProjectSelectionDropdown(false);
    setShowSectionSelectionDropdown(false);
  };
  const handleToggleDropdownSection = () => {
    setShowSectionSelectionDropdown((c) => !c);
    setShowProjectSelectionDropdown(false);
    setShowListSelectionDropdown(false);
  };

  const handleTogglePositionDropdown = () => {
    setShowPositionSelectionDropdown((c) => !c);
    setShowSectionSelectionDropdown(false);
    setShowProjectSelectionDropdown(false);
    setShowListSelectionDropdown(false);
  };

  const closeAllDropdown = () => {
    setShowProjectSelectionDropdown(false);
    setShowListSelectionDropdown(false);
    setShowSectionSelectionDropdown(false);
    setShowPositionSelectionDropdown(false);
  };

  React.useEffect(() => {
    // set the default selected project if from project panel
    if (panelType === "list" && isList) {
      const list = selectListById(listId);
      const projectOfList = list ? selectProjectOfList(list) : undefined;
      if (projectOfList) setSelectedProject(projectOfList);
      if (list) setSelectedList(list);
    }
  }, [panelType, projects, isList, listId]);

  React.useEffect(() => {
    if (selectedProject && lists) {
      setListsInSelectedProject(selectListsInProject(selectedProject));
    }
  }, [selectedProject, lists]);

  React.useEffect(() => {
    if (selectedList) {
      setSectionsInSelectedList(selectSectionsInList(selectedList));
    }
  }, [selectedList]);

  React.useEffect(() => {
    let taskAlreadyExistsInProject: boolean | undefined;
    if (selectedProject && selectedSection) {
      taskAlreadyExistsInProject =
        !!tasksInSelectedProject &&
        !!tasksInSelectedProject.find(({ title }) => {
          return title.toLowerCase().trim() === searchText.toLowerCase().trim();
        });
    }

    if (
      !(selectedProject && selectedList && selectedSection && selectedPosition)
    ) {
      setErrorMessage("All fields needs to be populated");
    } else if (action === "create" && searchText === "") {
      setErrorMessage(
        "You must specify the task name in order to create the task"
      );
    } else if (action === "create" && taskAlreadyExistsInProject) {
      setErrorMessage("This name already exists for the specified project");
    } else {
      setErrorMessage("");
    }
  }, [
    action,
    tasksInSelectedProject,
    searchText,
    selectedProject,
    selectedList,
    selectedSection,
    selectedPosition,
  ]);

  return (
    <div
      className="SelectTaskDesinationPopoverContent"
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
    >
      <div className="SelectTaskDesinationPopoverContent__Header">
        <span>Select destination</span>
      </div>
      <div className="SelectTaskDesinationPopoverContent__Body">
        <div className="SelectTaskDesinationPopoverContent__Body__Selection">
          <ProjectSelection
            handleSelectProject={handleSelectProject}
            selectedProject={selectedProject}
            projects={projects}
            projectsLoading={!!projectsLoading}
            showDropdown={showProjectSelectionDropdown}
            setShowDropdown={setShowProjectSelectionDropdown}
            handleToggleDropdown={handleToggleDropdownProject}
          />
          <ListSelection
            handleSelectList={handleSelectList}
            handleToggleDropdown={handleToggleDropdownList}
            listArray={listsInSelectedProject}
            selectedList={selectedList}
            selectedProject={selectedProject}
            showDropdown={showListSelectionDropdown}
          />
          <SectionSelection
            handleSelectSection={handleSelectSection}
            handleSelectPosition={handleSelectPosition}
            handleToggleSectionDropdown={handleToggleDropdownSection}
            handleTogglePositionDropdown={handleTogglePositionDropdown}
            sectionArray={sectionsInSelectedList}
            selectedList={selectedList}
            selectedSection={selectedSection}
            selectedPosition={selectedPosition}
            showSectionDropdown={showSectionSelectionDropdown}
            showPositionDropdown={showPositionSelectionDropdown}
          />
        </div>
        <div className="SelectTaskDesinationPopoverContent__Body__Actions">
          <CustomButton
            className="SelectTaskDesinationPopoverContent__Body__Actions__Cancel"
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              cancelClickCallback();
            }}
          >
            Cancel
          </CustomButton>
          <Tooltip
            title={!!errorMessage ? errorMessage : undefined}
            overlayClassName="AddTaskButton__ErrorTooltip"
          >
            <CustomButton
              className={cn(
                "SelectTaskDesinationPopoverContent__Body__Actions__Action",
                {
                  "SelectTaskDesinationPopoverContent__Body__Actions__Action--Disabled":
                    !!errorMessage || tasksInSelectedProjectLoading,
                }
              )}
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                actionClickCallback({
                  selectedProject,
                  selectedList,
                  selectedSection,
                  selectedPosition,
                });
              }}
            >
              {action}
            </CustomButton>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default SelectTaskDesinationPopoverContent;
