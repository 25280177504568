import React from "react";

import { ReportIcon } from "../../CustomIcons/CustomIcons.component";

const TaskDraftActivityDisplay: React.FC = () => {
  return (
    <div className="TaskDraftActivityDisplay">
      <ReportIcon />
      <span>Draft</span>
    </div>
  );
};

export default TaskDraftActivityDisplay;
