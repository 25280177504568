import axios from "../apis/django.instance";

export const loginService = (data: LoginService) => {
  return axios.post("/api/accounts/token/", data); // must be in this format in order to mock implementation in unit test
};

export const webhookLoginService = () => {
  return axios.post("/api/accounts/login/") // must be in this format in order to mock implementation in unit test
}

export const webhookLogoutService = () => {
  return axios.post("/api/accounts/logout/") // must be in this format in order to mock implementation in unit test
}
