import React from "react";
import cn from "classnames";

const RadioGroup = ({
  options,
  value,
  className,
  onChange,
}: RadioGroupProps) => {
  const [selected, setSelected] = React.useState(value || options[0].value);

  React.useEffect(() => {
    if (value) {
      setSelected(value);
    }
  }, [value]);

  const handleSelect = (val: string) => {
    setSelected(val);
    if (onChange) {
      onChange(val);
    }
  };
  const radios = options.map((item, index) => (
    <div
      key={index}
      className={cn(
        "RadioGroup__Option",
        { "RadioGroup__Option-active": selected === item.value },
        { "RadioGroup__Option-center": !item.subtitle }
      )}
      onClick={() => handleSelect(item.value)}
    >
      <span>{item.title}</span>
      <span>{item.subtitle}</span>
    </div>
  ));
  return <div className={cn("RadioGroup", className)}>{radios}</div>;
};

export default RadioGroup;
