import React from "react";
import { Button as AntButton, Avatar, Modal } from "antd";
import Button from "../../Button/Button";

import { toBase64 } from "../../../utils/base64.util";
import { getInitials } from "../../../utils/string.util";

const AvatarUploader = ({
  name,
  defaultAvatar,
  onAvatarChange,
}: AvatarUploaderProps) => {
  const [avatar, setAvatar] = React.useState<string>(defaultAvatar);

  // convert the image to base64 string so it can be sent to the endpoint
  const hanldeUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (file.size > 1024 * 1024) {
        Modal.error({
          title: "File size too large",
          content: "Please make sure the image size is less than 1MB.",
        });
        return;
      }
      onAvatarChange(file);
      const base64 = await toBase64(file);
      setAvatar(base64);
    }
  };

  const handleUploadClick = (e: any) => {
    e.preventDefault();
    // create a virtual <input type="file" /> element to upload image
    const input = document.createElement("input");
    input.type = "file";
    input.name = "avatar";
    input.accept = "image/png, image/jpeg";
    input.onchange = hanldeUpload as any;
    input.click();
  };

  const handleRemove = () => {
    setAvatar("");
    onAvatarChange("");
  };

  React.useEffect(() => {
    setAvatar(defaultAvatar);
  }, [defaultAvatar]);
  
  return (
    <div className="AvatarUploader">
      <div className="AvatarUploader__AvatarBox">
        <Avatar size={80} src={avatar}>
          {getInitials(name)}
        </Avatar>
      </div>
      <Button className="AvatarUploader__Upload" onClick={handleUploadClick}>
        Upload image
      </Button>
      <AntButton
        className="AvatarUploader__Remove"
        type="link"
        disabled={!avatar}
        onClick={handleRemove}
      >
        Remove
      </AntButton>
    </div>
  );
};

export default AvatarUploader;
