import React from "react";
import { Menu, MenuProps } from "antd";

import { SectionSelectionProps } from "../SectionSelection";

export interface SectionSelectionDropdownMenuProps {
  handleSelectSection: SectionSelectionProps["handleSelectSection"];
  sectionArray: SectionSelectionProps["sectionArray"];
  selectedList?: SectionSelectionProps["selectedList"];
}
const SectionSelectionDropdownMenu: React.FC<SectionSelectionDropdownMenuProps> = ({
  handleSelectSection,
  sectionArray,
  selectedList,
}) => {
  const hasSection = sectionArray.length > 0;

  const handleClick: MenuProps["onClick"] = ({ key, domEvent }) => {
    domEvent.stopPropagation();
    handleSelectSection(sectionArray[Number(key)]);
  };

  return (
    <Menu
      className="SectionSelectionDropdownMenu"
      disabled={!selectedList}
      onClick={handleClick}
    >
      {!selectedList && (
        <Menu.Item className="ListSelectionDropdownMenu__Empty">
          <span>No list selected</span>
        </Menu.Item>
      )}
      {selectedList &&
        hasSection &&
        sectionArray.map((sectionObject, index: number) => {
          return (
            <Menu.Item
              className="SectionSelectionDropdownMenu__Item"
              key={index}
            >
              <span>{sectionObject.title}</span>
            </Menu.Item>
          );
        })}
      {selectedList && !hasSection && (
        <Menu.Item className="SectionSelectionDropdownMenu__Empty">
          <span>No section available</span>
        </Menu.Item>
      )}
    </Menu>
  );
};

export default SectionSelectionDropdownMenu;
