import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

import { store } from "../store/configureStore";

// public views
import LoginView from "../views/Login/Login.view";
import UnauthorizedView from "../views/Unauthorized/Unauthorized.view";

// private views
import StartWorkView from "../views/StartWork/StartWork.view";
import InTheOfficeView from "../views/InTheOffice/InTheOffice.view";
import NotFoundView from "../views/NotFound/NotFound.view";
import WorkspaceView from "../views/WorkspaceView/WorkspaceView.view";

import {
  requestFetchLoginSuccess,
  requestFetchLogoutSuccess,
} from "../actions/auth.action";
import { startPopulateStatusTypes } from "../actions/status-types.action";
import { setStatus } from "../actions/status.action";
import {
  setUserDetailsDefault,
  startPopulateUserDetails,
} from "../actions/user-details.action";
import { startSetCurrentWorkDay } from "../actions/work-day.action";
import {
  outOfOfficeStatusType,
  webSocketEvents,
  workStatuses,
} from "../constants/constants";
import { selectStatusTypesState } from "../reducers/status-types.reducer";
import { selectAuthState } from "../reducers/auth.reducer";

import ws from "../sockets/websockets";
import {
  removeLastTrackedTask,
  stopTrackingTask,
} from "../utils/task-time-tracking.util";
import { setAuthToken } from "../apis/django.instance";
import { selectUserDetailsState } from "../reducers/user-details.reducer";

// export const history = createBrowserHistory();

export const individualOfficePrivateUrls = [
  "/office",
  "/team",
  "/unread",
  "/checkout",
  "/today",
  "/nextShift",
  "/starred",
  "/calendar",
  "/history",
];
export const sharedOfficePrivateUrls = [
  "/p/:projectId/:projectName",
  "/l/:listId/:listName",
  "/l/:listId/:listName/:listSubPage",
  "/s/:sectionId/:sectionName",
  "/t/:taskId/:taskName/:taskSubPage",
  "/l/:listId/:listName/:taskId/:taskName/:taskSubPage",
];
export const privateOfficeUrls = [
  ...individualOfficePrivateUrls,
  ...sharedOfficePrivateUrls,
];

export const individualWorkspacePrivateUrls = [
  "/projects",
  "/members",
  "/team-page",
  "/workspace",
];
export const privateWorkspaceUrls = [...individualWorkspacePrivateUrls];
const AppRouter = () => {
  const dispatch = useDispatch();

  const { data: statusTypes } = useSelector(selectStatusTypesState);
  const {
    data: { email: authEmail },
  } = useSelector(selectAuthState);
  const {
    data: { email: userEmail, id: userId },
  } = useSelector(selectUserDetailsState);

  React.useEffect(() => {
    const synchronizeAuth = ({ email, event }: AuthEventData) => {
      const lsEmail = localStorage.getItem("email");
      if (lsEmail === email || userEmail === email) {
        switch (event) {
          case webSocketEvents.login:
            const token = localStorage.getItem("token");
            if (token && email) {
              dispatch(requestFetchLoginSuccess(token, email));
              store.dispatch(startPopulateStatusTypes()).then(() => {
                store.dispatch(startPopulateUserDetails());
              });
            }
            break;
          case webSocketEvents.logout:
            setAuthToken(null);
            window.localStorage.removeItem("token");
            window.localStorage.removeItem("email");
            dispatch(requestFetchLogoutSuccess());
            dispatch(setUserDetailsDefault());
            break;
          default:
        }
      }
    };

    const synchronizeWorkStatus = async (messageEventData: any) => {
      try {
        const {
          event: eventType,
          email: eventEmail,
          status: eventStatus,
        } = messageEventData;
        const isCurrentUserEvent = eventEmail === authEmail;
        if (eventType === "new_status" && isCurrentUserEvent) {
          switch (eventStatus) {
            case workStatuses.working:
              const eventStatusType = statusTypes.find((statusType) => {
                return statusType.id === workStatuses.working;
              });
              if (eventStatusType) {
                dispatch(setStatus("SET_STATUS", eventStatusType));
                dispatch(startSetCurrentWorkDay(eventStatusType));
              }
              break;
          }
        } else if (eventType === "user_left_office" && isCurrentUserEvent) {
          dispatch(setStatus("SET_STATUS", outOfOfficeStatusType));
          stopTrackingTask();
          removeLastTrackedTask();
        }
      } catch (e) {}
    };

    const synchronizeUserDetails = async (messageEventData: any) => {
      const { resource_id, table_name } = messageEventData;
      if (table_name === "user" && resource_id === userId) {
        store.dispatch(startPopulateUserDetails());
      }
    };

    const wsOnMessage = async (event: MessageEvent) => {
      try {
        const messageEventData = JSON.parse(event.data);
        synchronizeAuth(messageEventData);
        synchronizeWorkStatus(messageEventData);
        synchronizeUserDetails(messageEventData);
      } catch (e) {}
    };

    ws.addEventListener("message", wsOnMessage);

    return () => {
      ws.removeEventListener("message", wsOnMessage);
    };
  }, [dispatch, authEmail, statusTypes, userId]);

  return (
    // <Router location={history.location} navigator={history}>
    <BrowserRouter>
      <Routes>
        <Route path={"/"} element={<PublicRoute />}>
          <Route path={"/"} element={<LoginView />} />
        </Route>

        <Route path={"/unauthorized"} element={<PrivateRoute />}>
          <Route path={"/unauthorized"} element={<UnauthorizedView />} />
        </Route>

        <Route path={"/start-work"} element={<PrivateRoute />}>
          <Route path={"/start-work"} element={<StartWorkView />} />
        </Route>

        {/** Private Workspace URLs */}
        <Route path={"/projects"} element={<PrivateRoute />}>
          <Route path={"/projects"} element={<WorkspaceView />} />
        </Route>
        <Route path={"/members"} element={<PrivateRoute />}>
          <Route path={"/members"} element={<WorkspaceView />} />
        </Route>
        <Route path={"/team-page"} element={<PrivateRoute />}>
          <Route path={"/team-page"} element={<WorkspaceView />} />
        </Route>
        <Route path={"/workspace"} element={<PrivateRoute />}>
          <Route path={"/workspace"} element={<WorkspaceView />} />
        </Route>

        {/** Shared Office Private URLs */}
        <Route path={"/p/:projectId/:projectName"} element={<PrivateRoute />}>
          <Route
            path={"/p/:projectId/:projectName"}
            element={<InTheOfficeView />}
          />
        </Route>

        <Route path={"/l/:listId/:listName"} element={<PrivateRoute />}>
          <Route path={"/l/:listId/:listName"} element={<InTheOfficeView />} />
        </Route>

        <Route
          path={"/l/:listId/:listName/:listSubPage"}
          element={<PrivateRoute />}
        >
          <Route
            path={"/l/:listId/:listName/:listSubPage"}
            element={<InTheOfficeView />}
          />
        </Route>

        <Route path={"/s/:sectionId/:sectionName"} element={<PrivateRoute />}>
          <Route
            path={"/s/:sectionId/:sectionName"}
            element={<InTheOfficeView />}
          />
        </Route>

        <Route
          path={"/t/:taskId/:taskName/:taskSubPage"}
          element={<PrivateRoute />}
        >
          <Route
            path={"/t/:taskId/:taskName/:taskSubPage"}
            element={<InTheOfficeView />}
          />
        </Route>

        <Route
          path={"/l/:listId/:listName/:taskId/:taskName/:taskSubPage"}
          element={<PrivateRoute />}
        >
          <Route
            path={"/l/:listId/:listName/:taskId/:taskName/:taskSubPage"}
            element={<InTheOfficeView />}
          />
        </Route>

        {/** Individual Office Private URLs */}
        <Route path={"/office"} element={<PrivateRoute />}>
          <Route path={"/office"} element={<InTheOfficeView />} />
        </Route>
        <Route path={"/team"} element={<PrivateRoute />}>
          <Route path={"/team"} element={<InTheOfficeView />} />
        </Route>
        <Route path={"/unread"} element={<PrivateRoute />}>
          <Route path={"/unread"} element={<InTheOfficeView />} />
        </Route>
        <Route path={"/checkout"} element={<PrivateRoute />}>
          <Route path={"/checkout"} element={<InTheOfficeView />} />
        </Route>
        <Route path={"/today"} element={<PrivateRoute />}>
          <Route path={"/today"} element={<InTheOfficeView />} />
        </Route>
        <Route path={"/nextShift"} element={<PrivateRoute />}>
          <Route path={"/nextShift"} element={<InTheOfficeView />} />
        </Route>
        <Route path={"/starred"} element={<PrivateRoute />}>
          <Route path={"/starred"} element={<InTheOfficeView />} />
        </Route>
        <Route path={"/calendar"} element={<PrivateRoute />}>
          <Route path={"/calendar"} element={<InTheOfficeView />} />
        </Route>
        <Route path={"/history"} element={<PrivateRoute />}>
          <Route path={"/history"} element={<InTheOfficeView />} />
        </Route>

        <Route path="*" element={<NotFoundView />}></Route>
      </Routes>
    </BrowserRouter>
    // </Router>
  );
};

export default AppRouter;
