// get all task ids belong to this project
const getTaskIds = (project: UnreadProject) => {
  return project.lists.flatMap((list) =>
    list.sections.flatMap((section) =>
      section.tasks.map((task) => task.id.toString())
    )
  );
};

const getUnreadActivitiesCount = (project: UnreadProject) => {
  return project.lists.reduce((total, current) => {
    const { sections } = current;
    return (
      total +
      sections.reduce((sectionTotal, sectionCurrent) => {
        const { tasks } = sectionCurrent;
        return (
          sectionTotal +
          tasks.reduce(
            (taskTotal, taskCurrent) => taskTotal + taskCurrent.activity_count,
            0
          )
        );
      }, 0)
    );
  }, 0);
};

export { getTaskIds, getUnreadActivitiesCount };
