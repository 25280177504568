import { store } from "../store/configureStore";

export const selectProjectOfTask = (
  taskObject: TaskJoinedToUserAndScheduledObject | TaskObject
) => {
  const { section } = taskObject;
  const { id: taskSectionId } = section;
  const sectionArray: SectionArray = store.getState().sections.data;
  const listArray: ListArray = store.getState().lists.data;
  const projectArray: ProjectArray = store.getState().projects.data;

  const sectionTaskBelongsTo = sectionArray.find(
    ({ id: sectionId }) => taskSectionId === sectionId
  );
  const { parent_list: parentList } = sectionTaskBelongsTo || {};
  const listTaskBelongsTo = listArray.find(
    ({ id: listId }) => parentList === listId
  );
  const { parent_project: parentProject } = listTaskBelongsTo || {};
  const projectTaskBelongsTo = projectArray.find(
    ({ id: projectId }) => projectId === parentProject
  );
  return projectTaskBelongsTo;
};

export const selectProjectOfList = (listObject: ListObject) => {
  const { parent_project } = listObject;
  const projectArray: ProjectArray = store.getState().projects.data;

  return projectArray.find(({ id: projectId }) => projectId === parent_project);
};

export const selectProjectByTitle = (title: ProjectObject["title"]) => {
  const projectArray: ProjectArray = store.getState().projects.data;
  return projectArray.find(({ title: projectTitle }) => projectTitle === title);
};

export const selectProjectById = (id: ProjectObject["id"]) => {
  const projectArray: ProjectArray = store.getState().projects.data;
  return projectArray.find(({ id: projectId }) => projectId === id);
};
