import React from "react";

import { getTasksListService } from "../services/tasks.service";
import useConsolidateTask from "./useConsolidateTask.hook";

import { selectProjectOfTask } from "../selectors/project.selector";
import { asyncFetchTaskObject } from "../utils/tasks.util";

import ws from "../sockets/websockets";

export default function useAsyncTasksInProject(
  projectId?: ProjectObject["id"] | null
) {
  const [tasksInProject, setTasksInProject] = React.useState<TaskArray>();

  const [consolidatedTasks, consolidatedTasksLoading] = useConsolidateTask(
    tasksInProject
  );

  const isLoading = tasksInProject === undefined || consolidatedTasksLoading;

  const fetchTasksInProject = React.useCallback(async () => {
    if (projectId) {
      try {
        const response = await getTasksListService({
          parent_project: projectId,
        });
        if (response.status === 200) {
          setTasksInProject(response.data.results as TaskArray);
        } else {
          throw new Error();
        }
      } catch (e) {}
    }
  }, [projectId]);

  React.useEffect(() => {
    fetchTasksInProject();
  }, [fetchTasksInProject]);

  React.useEffect(() => {
    const wsOnMessage = async (event: MessageEvent) => {
      try {
        const messageEventData = JSON.parse(event.data);
        const { event: eventType } = messageEventData;

        if (eventType === "new_task") {
          const eventTask = messageEventData.meta.data;
          if (!!selectProjectOfTask(eventTask)) {
            fetchTasksInProject();
          }
        } else if (eventType === "new_join_task_to_user") {
          const eventTaskId = messageEventData.meta.task_id;
          const eventTask = await asyncFetchTaskObject(eventTaskId);

          // synchronize members of task record
          if (!!eventTask) {
            setTasksInProject((c) =>
              c
                ? c.map((task) => {
                    if (task.id === eventTaskId) {
                      return eventTask;
                    }
                    return task;
                  })
                : c
            );
          }
        }
      } catch (e) {}
    };

    ws.addEventListener("message", wsOnMessage);

    return () => {
      ws.removeEventListener("message", wsOnMessage);
    };
  }, [fetchTasksInProject]);

  return [consolidatedTasks, isLoading] as [
    TaskJoinedToUserAndScheduledArray | undefined,
    boolean
  ];
}
