import React from "react";
import { useSelector } from "react-redux";
import { Form } from "antd";

import Button from "../../Button/Button";
import CustomAvatar from "../../CustomAvatar/CustomAvatar";
import {
  BlockIcon,
  CaretRightIcon,
  MailIcon,
} from "../../CustomIcons/CustomIcons.component";
import PersonalForm from "../../AccountSettings/PersonalForm/PersonalForm.component";
import WorkspaceMemberAccountForm from "../WorkspaceMemberAccountForm/WorkspaceMemberAccountForm";
import AccessRightsForm from "../AccessRightsForm/AccessRightsForm";
import ExternalIntegrationForm from "../ExternalIntegrationForm/ExternalIntegrationForm";
import ProjectTable from "../../Projects/ProjectTable/ProjectTable";

import { memberTypes } from "../../../constants/member.constant";
import { workHoursSources } from "../../../constants/constants";
import { selectProjectsState } from "../../../reducers/projects.reducer";

export interface WorkspaceMemberAccountPanelProps {
  member: AccountUser;
}
const WorkspaceMemberAccountPanel: React.FC<WorkspaceMemberAccountPanelProps> = ({
  member,
}) => {
  const { data: projects, loading: projectsLoading } = useSelector(
    selectProjectsState
  );

  const [workspacePersonalForm] = Form.useForm();
  const [workspaceAccountForm] = Form.useForm();
  const [workspaceAccessRightsForm] = Form.useForm();
  const [workspaceExternalIntegrationForm] = Form.useForm();

  const [email, setEmail] = React.useState("");
  const [userType, setUserType] = React.useState(memberTypes.MEMBER);
  const [teamPageOwners, setTeamPageOwners] = React.useState<AccountUser[]>([]);
  const [workHoursSource, setWorkHoursSource] = React.useState(
    workHoursSources.OFFICE_TIME
  );
  const [allowAddManualShifts, setAllowAddManualShifts] = React.useState(false);
  const [allowInvisibleMode, setAllowInvisibleMode] = React.useState(false);
  const [externalTrackerEnabled, setExternalTrackerEnabled] = React.useState(
    false
  );
  const [externalTrackerConfig, setExternalTrackerConfig] = React.useState("");
  const [externalCalendarConfig, setExternalCalendarConfig] = React.useState(
    ""
  );

  const { avatar, display_name = "" } = member;
  const initials = display_name
    .split(" ")
    .map((n) => n[0])
    .join("");

  React.useEffect(() => {
    /**
     * TODO 13.16.3: local state with values from member prop, currently does not have all values needed
     */
    setEmail("example@example.com");
  }, []);

  return (
    <div className="WorkspaceMemberAccountPanel">
      <div className="WorkspaceMemberAccountPanel__Header">
        <div className="WorkspaceMemberAccountPanel__Header__Title">
          <span>Members</span>
          <CaretRightIcon />
          <CustomAvatar src={avatar} size={32} fallbackText={initials} />
          <span>{display_name}</span>
        </div>
        <div className="WorkspaceMemberAccountPanel__Header__Actions">
          <Button
            className="WorkspaceMemberAccountPanel__Header__Actions__SendInvite"
            icon={<MailIcon />}
          >
            <span>Invite</span>
          </Button>
          <Button
            className="WorkspaceMemberAccountPanel__Header__Actions__Deactivate"
            icon={<BlockIcon />}
          >
            Deactivate
          </Button>
        </div>
      </div>
      <div className="WorkspaceMemberAccountPanel__Content">
        <div className="WorkspaceMemberAccountPanel__Content__Section">
          <div className="WorkspaceMemberAccountPanel__Content__Section__Title">
            <span>Profile</span>
            <Button>Edit profile</Button>
          </div>
          <PersonalForm
            form={workspacePersonalForm}
            userDetails={member}
            readOnly={true}
          />
        </div>
        <div className="WorkspaceMemberAccountPanel__Content__Section">
          <div className="WorkspaceMemberAccountPanel__Content__Section__Title">
            <span>Account</span>
          </div>
          <WorkspaceMemberAccountForm
            form={workspaceAccountForm}
            email={email}
            userType={userType}
            teamPageOwners={teamPageOwners}
            workHoursSource={workHoursSource}
            onValuesChange={(
              { email, userType, teamPageOwners, workHoursSource }: any,
              allValues: any
            ) => {
              if (email) setEmail(email);
              if (userType) setUserType(userType);
              if (teamPageOwners) setTeamPageOwners(teamPageOwners);
              if (workHoursSource) setWorkHoursSource(workHoursSource);
            }}
            readOnly={true}
          />
        </div>
        <div className="WorkspaceMemberAccountPanel__Content__Section">
          <AccessRightsForm
            form={workspaceAccessRightsForm}
            allowAddManualShifts={allowAddManualShifts}
            allowInvisibleMode={allowInvisibleMode}
            onValuesChange={(changedValues: any, allValues: any) => {
              const {
                allowAddManualShifts,
                allowInvisibleMode,
              } = changedValues;
              if (allowAddManualShifts)
                setAllowAddManualShifts(allowAddManualShifts);
              if (allowInvisibleMode) setAllowInvisibleMode(allowInvisibleMode);
            }}
          />
        </div>
        <div className="WorkspaceMemberAccountPanel__Content__Section">
          <ExternalIntegrationForm
            form={workspaceExternalIntegrationForm}
            externalTrackerEnabled={externalTrackerEnabled}
            externalTrackerConfig={externalTrackerConfig}
            externalCalendarConfig={externalCalendarConfig}
            onValuesChange={(
              {
                externalTracker,
                externalTrackerConfig,
                externalCalendarConfig,
              }: any,
              allValues: any
            ) => {
              if (externalTracker) setExternalTrackerEnabled(externalTracker);
              if (externalTrackerConfig)
                setExternalTrackerConfig(externalTrackerConfig);
              if (externalCalendarConfig)
                setExternalCalendarConfig(externalCalendarConfig);
            }}
          />
        </div>

        <div className="WorkspaceMemberAccountPanel__Content__Section">
          <div className="WorkspaceMemberAccountPanel__Content__Section__Title">
            <span>Projects</span>
          </div>
          {/**
           * TODO 13.16.3: update projects with projects member belongs to
           */}
          <ProjectTable loading={!!projectsLoading} projects={projects} />
        </div>
      </div>
    </div>
  );
};

export default WorkspaceMemberAccountPanel;
