import React from "react";
import { DetailsTabContentContext } from "../TaskDetails/DetailsTabContent/DetailsTabContent";

const TaskDescriptionInputDisplay: React.FC<{ task: TaskObject }> = ({
  task,
}) => {
  const context = React.useContext(DetailsTabContentContext);
  const { setEditing } = context;
  const { description } = task;
  return (
    <div
      className="TaskDescriptionInputDisplay"
      onClick={() => {
        setEditing(true);
      }}
    >
      {description === "" || description === "''" ? (
        <span>Click here to add description</span>
      ) : (
        <span>{description}</span>
      )}
    </div>
  );
};

export default TaskDescriptionInputDisplay;
