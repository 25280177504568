import React from "react";
import { useSelector } from "react-redux";
import { Layout } from "antd";

import useInitApp from "../../hooks/useInitApp.hook";

import VerticalMenu from "../../modules/VerticalMenu/VerticalMenu";
import GlobalSystemMessage from "../../modules/General/GlobalSystemMessage/GlobalSystemMessage";
import Teammates from "../../modules/Team/Teammates/Teammates";
import InTheOfficeContent from "./InTheOfficeContent";

import { selectErrorState } from "../../reducers/error.reducer";
import { selectUserDetailsState } from "../../reducers/user-details.reducer";
import { selectCurrentWorkDayState } from "../../reducers/work-day.reducer";

const { Header, Content, Sider } = Layout;

/**
 * View associated with in the office route
 *
 * Default Display
 * Displays different panel contents depending on global state [nav]
 *
 * Functionality
 * Dispatches asynchronous actions to populate global states related to [In The Office View]
 * Listens to websocket server for create/update of tasks, join_task_to_user records, scheduled tasks
 * Repopulates consolidate global state [tasks] when create/update occurs
 */

const InTheOfficeView = () => {
  useInitApp();

  const error = useSelector(selectErrorState);
  const {
    data: { timezone: userTimezone },
  } = useSelector(selectUserDetailsState);
  const {
    data: { work_date: currentWorkDayDate },
  } = useSelector(selectCurrentWorkDayState);

  return (
    <div className="InTheOffice View">
      {userTimezone && currentWorkDayDate && (
        <Layout>
          <Header>{error && <GlobalSystemMessage />}</Header>
          <Layout>
            <Sider>
              <VerticalMenu />
            </Sider>
            <Content className="InTheOffice__content" id="inTheOffice__Content">
              <InTheOfficeContent />
            </Content>
            <Sider className="InTheOffice__RightSider" theme="light">
              <Teammates />
            </Sider>
          </Layout>
        </Layout>
      )}
    </div>
  );
};

export default InTheOfficeView;
