import React from "react";
import { Empty } from "antd";

import UserTaskActivityItemGroupedByDate from "../UserTaskActivityItemGroupedByDate/UserTaskActivityItemGroupedByDate";

import { groupActivitiesByDate } from "../../../utils/activities.util";

interface UserTaskActivityListGroupedByDateProps {
  displayActivityAvatar?: boolean;
  displayActivityHeader?: boolean;
  displayWorkDayLastShiftEndDateTime?: boolean;
  displayWorkDayTotalTaskTime?: boolean;
  order?: string;
  taskActivitySummaryArray: ActivitySummaryArray;
  taskId: TaskObject["id"];
  type: ActivitiesSummaryType;
  users: UserArray;
}
const UserTaskActivityListGroupedByDate: React.FC<UserTaskActivityListGroupedByDateProps> = ({
  displayActivityAvatar = true,
  displayActivityHeader = true,
  displayWorkDayLastShiftEndDateTime = false,
  displayWorkDayTotalTaskTime = true,
  order = "desc",
  taskActivitySummaryArray,
  taskId,
  type,
  users,
}) => {
  const taskActivitiesGroupedByDate =
    taskActivitySummaryArray.length > 0
      ? groupActivitiesByDate(
          taskActivitySummaryArray,
          "created_datetime",
          order
        )
      : undefined;

  const dateActivitiesJsx = taskActivitiesGroupedByDate ? (
    Object.keys(taskActivitiesGroupedByDate).map((key) => {
      const dateActivities = taskActivitiesGroupedByDate[key];
      if (dateActivities.length > 0) {
        return (
          <UserTaskActivityItemGroupedByDate
            key={key}
            dateActivities={dateActivities}
            dateKey={key}
            displayActivityAvatar={displayActivityAvatar}
            displayActivityHeader={displayActivityHeader}
            displayWorkDayLastShiftEndDateTime={
              displayWorkDayLastShiftEndDateTime
            }
            displayWorkDayTotalTaskTime={displayWorkDayTotalTaskTime}
            taskId={taskId}
            type={type}
            users={users}
          />
        );
      } else {
        return <Empty />;
      }
    })
  ) : (
    <Empty />
  );

  return (
    <div className="UserTaskActivityListGroupedByDate">{dateActivitiesJsx}</div>
  );
};

export default UserTaskActivityListGroupedByDate;
