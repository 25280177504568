import React from "react";
import moment from "moment";
import cn from "classnames";

import CustomAvatar from "../../../CustomAvatar/CustomAvatar";

import { workDayDateFormat } from "../../../../constants/constants";
import { dateKeyFormat } from "../../../../utils/objects-array.util";
import { getTotalDuration } from "../../../../utils/time-conversion.util";
import { getInitials } from "../../../../utils/string.util";

interface UserWorkDaySummaryItemGroupedByDateProps {
  dateKey: string;
  dateWorkDayCheckoutSummaryArray: WorkDayCheckoutSummaryArray;
  type: ActivitiesSummaryType;
  users: UserArray;
}
const UserWorkDaySummaryItemGroupedByDate: React.FC<UserWorkDaySummaryItemGroupedByDateProps> = ({
  dateKey,
  dateWorkDayCheckoutSummaryArray,
  type,
  users,
}) => {
  const [workDate, setWorkDate] = React.useState<WorkDayObject["work_date"]>();

  React.useEffect(() => {
    switch (dateKey) {
      case "Today":
        setWorkDate(moment().startOf("day").format(workDayDateFormat));
        break;
      case "Yesterday":
        setWorkDate(
          moment().startOf("day").subtract(1, "day").format(workDayDateFormat)
        );
        break;
      default:
        setWorkDate(moment(dateKey, dateKeyFormat).format(workDayDateFormat));
    }
  }, [dateKey]);

  return (
    <div
      className={cn(
        "UserWorkDaySummaryItemGroupedByDate",
        `UserWorkDaySummaryItemGroupedByDate--${type}`
      )}
    >
      <div className="UserWorkDaySummaryItemGroupedByDate__Header">
        <div className="UserWorkDaySummaryItemGroupedByDate__Header__Title">
          <span>{dateKey}</span>
        </div>
      </div>

      {dateWorkDayCheckoutSummaryArray.length <= 0 && (
        <div className="UserWorkDaySummaryItemGroupedByDate__Empty">
          <span>There are no checkout tasks for {dateKey}.</span>
        </div>
      )}

      {dateWorkDayCheckoutSummaryArray.length > 0 && (
        <>
          <div className="UserWorkDaySummaryItemGroupedByDate__WorkDayCheckoutTaskTimeList">
            {dateWorkDayCheckoutSummaryArray.map(
              (
                { total_seconds: workDaySummaryTotalSeconds, user_id: userId },
                index
              ) => {
                const userDetails = users.find(({ id }) => id === userId);
                const {
                  avatar: userAvatar,
                  current_status,
                  display_name: userDisplayName = "",
                } = userDetails || {};
                const { status_enum_identifier: userStatus } =
                  current_status || {};

                return (
                  <div
                    key={index}
                    className={cn(
                      "UserWorkDaySummaryItemGroupedByDate__WorkDayCheckoutTaskTimeList__Item",
                      {
                        "UserWorkDaySummaryItemGroupedByDate__WorkDayCheckoutTaskTimeList__Item--Team":
                          type === "team",
                      }
                    )}
                  >
                    {type === "team" && (
                      <>
                        <CustomAvatar
                          src={userAvatar}
                          work_status={userStatus}
                          size={18}
                          fallbackText={getInitials(userDisplayName)}
                        />
                        <span className="UserWorkDaySummaryItemGroupedByDate__WorkDayCheckoutTaskTimeList__Item__UserFullName">
                          {userDisplayName}
                        </span>
                      </>
                    )}
                    <span className="UserWorkDaySummaryItemGroupedByDate__WorkDayCheckoutTaskTimeList__Item__Time">
                      {getTotalDuration(
                        moment.duration(workDaySummaryTotalSeconds, "seconds")
                      )}
                    </span>
                  </div>
                );
              }
            )}
          </div>

          {dateWorkDayCheckoutSummaryArray.map(
            (
              {
                checkout_check: workDaySummaryCheckoutCheck,
                checkout_read_status: isRead,
                last_time_in_office: lastShiftEndDateTime,
                user_id: userId,
              },
              index
            ) => {
              const failedCheckoutChecks = workDaySummaryCheckoutCheck.filter(
                ({ passed_yn }) => !passed_yn
              );

              const userDetails = users.find(({ id }) => id === userId);
              const {
                avatar: userAvatar,
                current_status,
                display_name: userDisplayName = "",
              } = userDetails || {};
              const { status_enum_identifier: userStatus } =
                current_status || {};

              return (
                <div
                  key={index}
                  className="UserWorkDaySummaryItemGroupedByDate__Item"
                >
                  <div className="UserWorkDaySummaryItemGroupedByDate__Item__Header">
                    {type === "team" && (
                      <>
                        <CustomAvatar
                          src={userAvatar}
                          work_status={userStatus}
                          size={24}
                          fallbackText={getInitials(userDisplayName)}
                        />
                        <div className="UserWorkDaySummaryItemGroupedByDate__Item__Header__UserFullName">
                          {userDisplayName}
                        </div>
                      </>
                    )}
                    {lastShiftEndDateTime && (
                      <div className="UserWorkDaySummaryItemGroupedByDate__Item__Header__LastShiftEndDateTime">
                        {moment(lastShiftEndDateTime).format("HH:mm A")}
                      </div>
                    )}
                    {type === "team" && !isRead && (
                      <div className="UserWorkDaySummaryItemGroupedByDate__Item__Header__UnreadTag">
                        New
                      </div>
                    )}
                  </div>

                  <div className="UserWorkDaySummaryItemGroupedByDate__Item__Body">
                    <div className="UserWorkDaySummaryItemGroupedByDate__Item__Body__WorkDaySummaryCard">
                      <div
                        className={cn(
                          "UserWorkDaySummaryItemGroupedByDate__Item__Body__WorkDaySummaryCard__Title",
                          {
                            "UserWorkDaySummaryItemGroupedByDate__Item__Body__WorkDaySummaryCard__Title--Failed":
                              failedCheckoutChecks.length > 0,
                            "UserWorkDaySummaryItemGroupedByDate__Item__Body__WorkDaySummaryCard__Title--Passed":
                              failedCheckoutChecks.length === 0,
                          }
                        )}
                      >
                        <span>Checkout Status</span>
                      </div>
                      <div className="UserWorkDaySummaryItemGroupedByDate__Item__Body__WorkDaySummaryCard__Content">
                        {failedCheckoutChecks.length > 0 ? (
                          failedCheckoutChecks.map(({ issue_json }, index) => {
                            const { checkout_failed_message } =
                              issue_json || {};
                            return (
                              <div
                                key={index}
                                className="UserWorkDaySummaryItemGroupedByDate__Item__Body__WorkDaySummaryCard__Content__Item"
                              >
                                <span>{checkout_failed_message}</span>
                              </div>
                            );
                          })
                        ) : (
                          <div className="UserWorkDaySummaryItemGroupedByDate__Item__Body__WorkDaySummaryCard__Content__Item">
                            <span>
                              No issues were found in checkout report.
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          )}
        </>
      )}
    </div>
  );
};

export default UserWorkDaySummaryItemGroupedByDate;
