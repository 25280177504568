import React from "react";
import { useSelector } from "react-redux";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { FlagsProvider } from "flagged";

import useCheckBetaVersion from "../hooks/useCheckBetaVersion.hook";
import useRedirect from "../hooks/useRedirect.hook";

import { defaultPage, workStatuses } from "../constants/constants";
import { selectStatusState } from "../reducers/status.reducer";
import { selectAuthState } from "../reducers/auth.reducer";

const PrivateRoute = () => {
  const redirect = useRedirect();
  // check if using beta version
  const isBetaVersion = useCheckBetaVersion();
  const {
    data: { token: authToken },
  } = useSelector(selectAuthState);
  const {
    data: { id: currentStatusId },
  } = useSelector(selectStatusState);

  const { pathname } = useLocation();

  // use children to avoid additional rerender when URL is changed
  return (
    <FlagsProvider
      features={{
        under_development: isBetaVersion,
      }}
    >
      {/**
       * If authenticated, return an outlet that will render child elements
       * If not, return element that will navigate to login page
       */}
      {!authToken && <Navigate to="/" replace />}

      {!!authToken &&
        (((currentStatusId === workStatuses.working ||
          currentStatusId === workStatuses.taking_break) &&
          pathname !== "/start-work") ||
          (currentStatusId === workStatuses.out_of_office &&
            pathname === "/start-work")) && <Outlet />}

      {!!authToken &&
        (currentStatusId === workStatuses.working ||
          currentStatusId === workStatuses.taking_break) &&
        pathname === "/start-work" && <Navigate to={defaultPage} replace />}

      {!!authToken &&
        currentStatusId === workStatuses.out_of_office &&
        pathname !== "/start-work" && <Navigate to="/start-work" replace />}
    </FlagsProvider>
  );
};

export { PrivateRoute as default };
