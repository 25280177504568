import React from "react";
import { Avatar } from "antd";

export interface WorkspaceMembersPopoverContentProps {
  members: ObservableUserArray;
}
const WorkspaceMembersPopoverContent: React.FC<WorkspaceMembersPopoverContentProps> = ({
  members,
}) => {
  const membersJsx = members.map(({ observed_user }, index) => {
    const { avatar, display_name } = observed_user;
    return (
      <div key={index} className="WorkspaceMembersPopoverContent__Member">
        <Avatar src={avatar} size={20} />
        <span>{display_name}</span>
      </div>
    );
  });
  return <div className="WorkspaceMembersPopoverContent">{membersJsx}</div>;
};

export default WorkspaceMembersPopoverContent;
