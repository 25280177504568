import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { workStatuses } from "../constants/constants";

import { startPopulateActivityTypes } from "../actions/activity-types.action";
import {
  startFetchCheckoutChecks,
  startFetchCheckoutStatus,
} from "../actions/checkout.action";
import { startPopulateLabels } from "../actions/labels.action";
import { startPopulateLists } from "../actions/list.action";
import { startPopulateNotifications } from "../actions/notifications.action";
import {
  requestOfficeSuccess,
  startPopulateOffice,
} from "../actions/office.action";
import { startPopulatePriorities } from "../actions/priorities.action";
import { startPopulateProjects } from "../actions/projects.action";
import { startPopulateSections } from "../actions/section.action";
import { setStatus } from "../actions/status.action";
import { startPopulateStatusHistory } from "../actions/status-history.action";
import {
  startSetLastTrackedTask,
  startSetTrackingStatus,
  startSetTrackingTask,
} from "../actions/task-time-tracking.action";
import { startPopulateTaskTime } from "../actions/task-time.action";
import {
  requestCreateTaskSuccess,
  requestCreateUserTaskSuccess,
  requestPartialUpdateTaskSuccess,
  startPopulateScheduledTasks,
  startPopulateTasks,
  startPopulateUserTasks,
} from "../actions/tasks.action";
import { startSetNextCalendarSchedule } from "../actions/third-party-calendar.action";
import { startPopulateThirdPartyTrackingList } from "../actions/third-party-tracking.action";
import { startPopulateWorkDays } from "../actions/work-day.action";
import { startPopulateWorkShiftSchedules } from "../actions/work-shift-schedule.action";
import { startPopulateWorkShifts } from "../actions/work-shift.action";
import {
  startPopulateWorkTimes,
  updateCurrentBreakTime,
  updateCurrentWorkTime,
} from "../actions/work-times.action";

import { selectTaskTimeState } from "../reducers/task-time.reducer";
import { selectUserDetailsState } from "../reducers/user-details.reducer";
import { selectProjectsState } from "../reducers/projects.reducer";
import { selectCurrentWorkDayState } from "../reducers/work-day.reducer";
import { selectStatusState } from "../reducers/status.reducer";
import {
  selectScheduledTasksState,
  selectTasksState,
  selectUserTasksState,
} from "../reducers/tasks.reducer";
import { selectCurrentWorkTimesState } from "../reducers/work-times.reducer";
import {
  fetchUserTaskRecordService,
  getJoinTaskToUserListService,
} from "../services/tasks.service";
import {
  getMyProjectMemberships,
  getProjectRecord,
} from "../services/projects.service";
import { getProjectColorService } from "../services/projects-colors.service";
import {
  fetchListRecordService,
  getMyListMemberships,
} from "../services/list.service";

import { dispatchError } from "../utils/error.util";
import { minutesToMs, minutesToSeconds } from "../utils/time-conversion.util";
import { getStatusFromListStatusTypesByIdentifier } from "../utils/work-status.util";

import ws from "../sockets/websockets";
import { selectListsState } from "../reducers/list.reducer";
import useSynchronizeProjectsMemberships from "./useSynchronizeProjectsMemberships.hook";
import { selectOfficeState } from "../reducers/office.reducer";

export default function useInitApp() {
  let interval = React.useRef<number>(0);

  const dispatch = useDispatch();

  const { data: userDetails } = useSelector(selectUserDetailsState);
  const {
    data: { id: currentWorkDayId, work_date: currentWorkDayDate },
  } = useSelector(selectCurrentWorkDayState);
  const {
    data: { id: currentStatusDataId },
  } = useSelector(selectStatusState);
  const workTimes =
    (useSelector(selectCurrentWorkTimesState) as WorkTimes) || {};
  const { data: projects, loading: projectsLoading } = useSelector(
    selectProjectsState
  );
  const { data: lists, loading: listsLoading } = useSelector(selectListsState);
  const { data: tasks, loading: tasksLoading } = useSelector(selectTasksState);
  const { data: userTasks, loading: userTasksLoading } = useSelector(
    selectUserTasksState // TODO: update to not use global user tasks state
  );
  const { data: scheduledTasks, loading: scheduledTasksLoading } = useSelector(
    selectScheduledTasksState
  );

  const { work_time = 0, break_time = 0 } = workTimes;
  const { id: userId } = userDetails;

  const synchronizeProjectsMemberships = useSynchronizeProjectsMemberships();

  React.useEffect(() => {
    if (userId && currentWorkDayDate) {
      dispatch(startSetNextCalendarSchedule());
      dispatch(startPopulateNotifications());
      dispatch(startPopulateProjects());

      // TODO: can be removed as statusHistory global state is not used, need to check first
      // dispatch(startPopulateStatusHistory()); // removed to improve performance

      dispatch(startPopulatePriorities());
      dispatch(startPopulateLists());
      dispatch(startPopulateSections());
      dispatch(startPopulateOffice());

      // TODO: remove comment when working on tasks group
      // dispatch(startPopulateTasks()); // temporarily remove fetching whole lists to improve performance
      // dispatch(startPopulateUserTasks()); // temporarily remove fetching whole lists to improve performance
      // dispatch(startPopulateScheduledTasks()); // temporarily remove fetching whole lists to improve performance
      dispatch(startPopulateTaskTime());

      // TODO: remove comment when shifts and schedules are required
      // dispatch(startPopulateWorkShiftSchedules()); // temporarily remove fetching to improve performance
      // dispatch(startPopulateWorkShifts()); // temporarily remove fetching to improve performance

      dispatch(startPopulateActivityTypes());
      // dispatch(startFetchCheckoutChecks()); // temporarily remove fetching to improve performance
      // dispatch(startPopulateWorkDays()); // temporarily remove fetching to improve performance
      // dispatch(startFetchCheckoutStatus()); // temporarily remove fetching to improve performance

      dispatch(startPopulateThirdPartyTrackingList());
      dispatch(startSetTrackingTask());
      dispatch(startSetTrackingStatus());
      dispatch(startSetLastTrackedTask());
      dispatch(startPopulateLabels());
    }
  }, [dispatch, userId, currentWorkDayDate]);

  React.useEffect(() => {
    if (currentWorkDayDate) {
      dispatch(startPopulateWorkTimes(currentWorkDayDate));
    }
  }, [dispatch, currentWorkDayDate, currentStatusDataId]);

  React.useEffect(() => {
    // we use setTimeout instead of setInterval here since the
    // updation of work_time or break_time will make this
    // effect be triggered again in each time interval
    const timer = setTimeout(() => {
      if (currentStatusDataId === workStatuses.working) {
        dispatch(updateCurrentWorkTime(work_time + minutesToSeconds(1)));
      } else if (currentStatusDataId === workStatuses.taking_break) {
        dispatch(updateCurrentBreakTime(break_time + minutesToSeconds(1)));
      }
    }, minutesToMs(1));
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [dispatch, currentStatusDataId, work_time, break_time]);

  const synchronizeProjects = React.useCallback(
    async (message: ProjectMessageEventData) => {
      try {
        const { event: eventType, id: newProjectId } = message;
        switch (eventType) {
          case "new_project":
            const newProject = newProjectId
              ? await getProjectRecord(newProjectId)
              : undefined;
            const newProjectColorId = newProject?.color;
            const newProjectWithData = {
              ...newProject,
              color: newProjectColorId
                ? await getProjectColorService(newProjectColorId)
                : null,
            } as ProjectObject;

            if (newProjectId && newProject) {
              if (projects.map(({ id }) => id).includes(newProjectId)) {
                dispatch({
                  type: "UPDATE_PROJECT",
                  project: newProjectWithData,
                });
              } else {
                dispatch({
                  type: "ADD_PROJECT",
                  project: newProjectWithData,
                });
                dispatch(startPopulateLists());
                dispatch(startPopulateSections());
              }
            }
            break;
          case "add_user_to_project":
          case "remove_user_from_project":
            dispatch(startPopulateSections());
            break;
          default:
        }
      } catch (e) {
        dispatchError({
          e,
          title: "Synchronize projects error",
        });
      }
    },
    [dispatch, projects]
  );

  const synchronizeTaskListMemberships = React.useCallback(
    async (messageEventData: any) => {
      try {
        const { action, client_id, resource_id, table_name } = messageEventData;

        if (table_name === "tasklist_membership") {
          const listMemberships = (await getMyListMemberships()) || [];

          if (action === "create") {
            const newListId = (
              listMemberships.find(({ id }) => id === resource_id) || {}
            ).task_list;

            const newList = newListId
              ? await fetchListRecordService(newListId)
              : undefined;

            if (newListId && newList) {
              if (lists.map(({ id }) => id).includes(newListId)) {
                dispatch({ type: "UPDATE_LIST", list: newList });
              } else {
                dispatch({
                  type: "ADD_LIST",
                  list: newList,
                });
              }
            }
          } else if (action === "delete") {
            // TODO: insert logic for informing user their membership for a list has been removed
            dispatch(startPopulateLists());
          }
        }
      } catch (e) {
        dispatchError({
          e,
          title: "Synchronize task list memberships error",
        });
      }
    },
    [dispatch, lists]
  );

  React.useEffect(() => {
    const fetchUserTaskRecord = async ({
      userTaskId,
      taskId,
    }: {
      userTaskId?: JoinTaskToUserObject["id"];
      taskId?: TaskObject["id"];
    }) => {
      try {
        if (userTaskId) {
          const response = await fetchUserTaskRecordService(userTaskId);
          if (response.status === 200) {
            return response.data;
          }
        } else if (taskId) {
          const response = await getJoinTaskToUserListService({
            task: taskId,
          });
          if (response.status === 200) {
            return response.data;
          }
        }
      } catch (e) {}
    };

    const synchronizeTasks = async (messageEventData: any) => {
      const { event: eventType } = messageEventData;

      if (eventType === "new_task") {
        // synchronizes task record
        const eventTask: TaskObject = {
          ...messageEventData.meta.data,
          loading: false,
        };

        // check if task already exists in global state
        const task = tasks.find(({ id }) => id === eventTask.id);

        if (task) {
          // if task is updated, not created
          dispatch(requestPartialUpdateTaskSuccess(eventTask));
        } else {
          // if task is created, fetch user task related since this will not be included in global state
          dispatch(requestCreateTaskSuccess(eventTask));
        }
      }

      if (eventType === "new_join_task_to_user") {
        // synchronize user task record
        const metaData = messageEventData.meta.data;
        const eventUserId = metaData.user.id;
        const isCurrentUser = userId === eventUserId;

        const eventUserTask: JoinTaskToUserObject = {
          ...metaData,
          next_shift_yn: {
            loading: false,
            value: metaData.next_shift_yn,
          },
          starred_yn: {
            loading: false,
            value: metaData.starred_yn,
          },
          today_yn: {
            loading: false,
            value: metaData.today_yn,
          },
          priority: {
            loading: false,
            value: metaData.priority,
          },
        };

        // check if user task already exists in global state
        const userTask = userTasks.find(({ id }) => id === eventUserTask.id);

        if (isCurrentUser && !!userTask) {
          // if user task is updated, not created
          dispatch({
            type: "REQUEST_PARTIAL_UPDATE_USER_TASK_SUCCESS",
            userTask: eventUserTask,
          });
        } else if (isCurrentUser && !userTask) {
          dispatch(requestCreateUserTaskSuccess(eventUserTask));
        }
      }

      switch (eventType) {
        case "new_task":
        case "new_join_task_to_user":
        case "new_scheduled_task":
        case "added_task_to_workday":
        case "removed_project_from_workday":
          dispatch(startPopulateLists());
          break;
        default:
      }
    };

    const synchronizeUserWorkStatus = (
      messageEventData: StatusMessageEventData
    ) => {
      const eventIsForCurrentUser =
        messageEventData.email === localStorage.getItem("email");
      if (eventIsForCurrentUser) {
        switch (messageEventData.status) {
          case workStatuses.working:
          case workStatuses.taking_break:
          case workStatuses.out_of_office:
            const workStatusType = getStatusFromListStatusTypesByIdentifier(
              messageEventData.status
            );
            if (workStatusType) {
              dispatch(setStatus("SET_STATUS", workStatusType));
            }
            break;
          default:
        }
      }
    };

    const synchronizeTaskTimeRecords = (
      messageEventData: TaskTimeEventData
    ) => {
      const { event: eventType } = messageEventData;
      switch (eventType) {
        case "new_task_time":
          dispatch(startPopulateTaskTime());
          dispatch(startPopulateWorkTimes(currentWorkDayDate)); // TODO optimization: update work times global state with allocated time instead of re-fetching when websocket metadata gets updated
          dispatch(startFetchCheckoutStatus()); // re-run the checkout checks when the work time gets update for unallocated work time
          break;
        default:
      }
    };
    const synchronizeActivities = async (
      messageEventData: ActivityMessageEventData
    ) => {
      const { event: eventType } = messageEventData;
      switch (eventType) {
        case "new_activity":
          dispatch(startFetchCheckoutStatus()); // re-run the checkout checks when the activities get updated for unconfirmed activities
          // other synchronization of unread activities count in src\hooks\useAsyncMyDeskTasks.hook.ts and src\modules\Activities\UnreadActivitiesCount\UnreadActivitiesCount.tsx
          break;
        default:
      }
    };

    const synchronizeLists = (message: ListMessageEventData) => {
      const { event: eventType } = message;
      switch (eventType) {
        case "new_task_list":
          dispatch(startPopulateLists());
          break;
        default:
      }
    };

    const wsOnMessage = async (event: MessageEvent) => {
      try {
        const messageEventData = JSON.parse(event.data);

        synchronizeTasks(messageEventData);
        synchronizeUserWorkStatus(messageEventData);
        synchronizeTaskTimeRecords(messageEventData);
        synchronizeActivities(messageEventData);
        synchronizeProjects(messageEventData);
        synchronizeProjectsMemberships(messageEventData);
        synchronizeLists(messageEventData);
        synchronizeTaskListMemberships(messageEventData);
      } catch (e) {}
    };

    ws.addEventListener("message", wsOnMessage);

    return () => {
      ws.removeEventListener("message", wsOnMessage);
    };
  }, [
    dispatch,
    synchronizeProjects,
    synchronizeProjectsMemberships,
    synchronizeTaskListMemberships,
    currentWorkDayDate,
    tasks,
    userTasks,
    scheduledTasks,
    userId,
  ]);

  return null;
}
