import React from "react";
import { useSelector } from "react-redux";
import cn from "classnames";
import { AxiosResponse } from "axios";

import CustomAvatar from "../../CustomAvatar/CustomAvatar";
import ActivityCardItemHeader from "./ActivityCardItemHeader/ActivityCardItemHeader";
import ActivityCardItemBody from "./ActivityCardItemBody/ActivityCardItemBody";

import { selectUserDetailsState } from "../../../reducers/user-details.reducer";
import { getActivityReadStatusService } from "../../../services/activities.service";
import { getUserDetailsByIDService } from "../../../services/user-details.service";
import { getInitials } from "../../../utils/string.util";

import ws from "../../../sockets/websockets";

export const ActivityCardItemContext = React.createContext<ActivityCardItemContext>(
  {} as ActivityCardItemContext
);

/**
 * Default display
 * Displays activity's user's avatar
 * Displays ActivityCardItemHeader
 * Displays ActivityCardItemBody
 *
 * Functionality
 * Updates hovered local state onMouseEnter and onMouseLeave, passed as prop to ActivityCardItemHeader
 * Different styling when activity is deleted
 */
const ActivityCardItem: React.FC<ActivityCardItemProps> = ({
  activityObject,
  displayAvatar = true,
  displayHeader = true,
  isTeamDrafts,
}) => {
  const {
    id: activityId,
    user,
    deleted_yn,
    loading: activityLoading,
  } = activityObject;

  const userDetails = useSelector(selectUserDetailsState);

  const [activityReadStatus, setActivityReadStatus] = React.useState<boolean>();
  const [
    activityUserDetails,
    setActivityUserDetails,
  ] = React.useState<UserDetailsObject>({} as UserDetailsObject);
  const [hovered, setHovered] = React.useState(false);
  const [editing, setEditing] = React.useState(false);

  const { avatar, current_status, display_name = "" } =
    activityUserDetails || {};
  const { status_enum_identifier } = current_status || {};
  const activityBelongsToOtherUser = user !== userDetails.data.id;
  const displayBody = !deleted_yn;

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const getActivityReadStatus = React.useCallback(async () => {
    try {
      await getActivityReadStatusService(activityId).then((response) => {
        if (response.status === 200) {
          setActivityReadStatus(response.data.read_status);
        }
      });
    } catch (e) {}
  }, [activityId]);

  React.useEffect(() => {
    const wsOnMessage = async (event: MessageEvent) => {
      try {
        const messageEventData = JSON.parse(event.data);
        const { id: eventID, event: eventType, meta } = messageEventData;
        const { activity_id } = meta || {};
        const activityIsUpdated = activity_id === activityId;

        switch (eventType.trim()) {
          case "new_activity_read_log":
            if (activityIsUpdated && activityBelongsToOtherUser) {
              getActivityReadStatus();
            }
            break;
          default:
            break;
        }
      } catch (e) {}
    };
    ws.addEventListener("message", wsOnMessage);

    return () => {
      ws.removeEventListener("message", wsOnMessage);
    };
  }, [activityBelongsToOtherUser, activityId, getActivityReadStatus]);

  React.useEffect(() => {
    if (user) {
      getUserDetailsByIDService(user).then((response: AxiosResponse) => {
        if (response.status === 200) {
          setActivityUserDetails(response.data);
        }
      });
    }
  }, [user]);

  React.useEffect(() => {
    if (activityBelongsToOtherUser && !isTeamDrafts) {
      getActivityReadStatus();
    }
  }, [activityBelongsToOtherUser, isTeamDrafts, getActivityReadStatus]);

  return (
    <ActivityCardItemContext.Provider
      value={{
        activityBelongsToOtherUser,
        activityObject,
        activityReadStatus,
        activityUserDetails,
        editing,
        hovered,
        isTeamDrafts: !!isTeamDrafts,
        setEditing,
      }}
    >
      <div
        className={cn("ActivityCardItem", {
          "ActivityCardItem--Deleted": deleted_yn,
        })}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {displayAvatar && (
          <CustomAvatar
            src={avatar}
            work_status={status_enum_identifier}
            fallbackText={getInitials(display_name)}
            size={24}
          />
        )}
        <div className="ActivityCardItem__Card">
          {displayHeader && <ActivityCardItemHeader />}
          {displayBody && <ActivityCardItemBody />}
        </div>
      </div>
    </ActivityCardItemContext.Provider>
  );
};

export { ActivityCardItem as default };
