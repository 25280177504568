import { Dispatch } from "redux";

import { store } from "../store/configureStore";

import { setStatus } from "../actions/status.action";
import {
  createWorkDayRecordService,
  getLatestWorkDayService,
  getWorkDayListService,
  CreateWorkDayRecordServiceData,
  PartialUpdateWorkDayServiceData,
  partialUpdateWorkDayRecordService,
} from "../services/work-day.service";
import { dispatchError } from "../utils/error.util";

export const startPopulateWorkDays = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: "REQUEST_FETCH_WORK_DAYS",
      });
      const response = await getWorkDayListService({
        user: store.getState().userDetails.data.id,
      });
      if (response.status === 200) {
        dispatch({
          type: "REQUEST_FETCH_WORK_DAYS_SUCCESS",
          workDays: response.data.map((item: any) => {
            const { checkout_notes } = item;
            return {
              ...item,
              checkout_notes: {
                loading: false,
                value: checkout_notes,
              },
            };
          }) as WorkDayArray,
        });
      } else {
        const error = "getWorkDayListService status is not 200";
        dispatch({
          type: "REQUEST_FETCH_WORK_DAYS_FAILED",
          error,
        });
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch({
        type: "REQUEST_FETCH_WORK_DAYS_FAILED",
        error,
      });
      dispatchError({
        e,
        title: "Populate work days error",
      });
    }
  };
};

export const startSetCurrentWorkDay = (data?: StatusTypeObject) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const {
        userDetails: {
          data: { id: userId },
        },
      } = store.getState();
      dispatch({
        type: "REQUEST_FETCH_CURRENT_WORK_DAY",
      });
      const response = await getLatestWorkDayService({ user: userId });
      if (response.status === 200) {
        dispatch({
          type: "REQUEST_FETCH_CURRENT_WORK_DAY_SUCCESS",
          currentWorkDay: {
            ...response.data,
            checkout_notes: {
              loading: false,
              value: response.data.checkout_notes,
            },
          },
        });
        if (data) {
          dispatch(setStatus("REQUEST_CREATE_STATUS_SUCCESS", data));
        }
      } else {
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch({
        type: "REQUEST_FETCH_CURRENT_WORK_DAY_FAILED",
        error,
      });
      dispatchError({
        e,
        title: "Get latest work day error",
      });
    }
  };
};

export const setCurrentWorkDayDefault = () => {
  return {
    type: "SET_CURRENT_WORK_DAY_DEFAULT",
  };
};

export const setCheckoutWorkDay = (checkoutWorkDay: WorkDayObject) => {
  return {
    type: "SET_CHECKOUT_WORK_DAY",
    checkoutWorkDay,
  };
};
export const startCreateWorkDayRecord = (
  data: CreateWorkDayRecordServiceData
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch({
        type: "REQUEST_CREATE_WORK_DAY",
      });
      const response = await createWorkDayRecordService(data);
      if (response.status === 200) {
        const createdWorkDay: WorkDayObject = response.data;
        dispatch(setCheckoutWorkDay(createdWorkDay));
        dispatch({
          type: "REQUEST_CREATE_WORK_DAY_SUCCESS",
          workDay: createdWorkDay,
        });
      } else {
        const error = "createWorkDayRecordService status is not 200";
        dispatch({
          type: "REQUEST_CREATE_WORK_DAY_FAILED",
          error,
        });
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch({
        type: "REQUEST_CREATE_WORK_DAY_FAILED",
        error,
      });
      dispatchError({
        e,
        title: "Create work day record error",
      });
    }
  };
};

export const requestPartialUpdateWorkDayFailed = (
  workDayId: WorkDayObject["id"],
  error: string
) => ({
  type: "REQUEST_PARTIAL_UPDATE_WORK_DAY_FAILED",
  workDayId,
  error,
});
export const startPartialUpdateWorkDayRecord = (
  data: PartialUpdateWorkDayServiceData
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch({
        type: "REQUEST_PARTIAL_UPDATE_WORK_DAY",
        workDay: data,
      });
      const response = await partialUpdateWorkDayRecordService(data);
      if (response.status === 200) {
        const updatedWorkDay: WorkDayObject = response.data;

        if (store.getState().currentWorkDay.data.id === updatedWorkDay.id)
          dispatch(startSetCurrentWorkDay()); // update current workday global state

        dispatch({
          type: "REQUEST_PARTIAL_UPDATE_WORK_DAY_SUCCESS",
          workDay: updatedWorkDay,
        });
        return {
          success: true,
        };
      } else {
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch({
        type: "REQUEST_PARTIAL_UPDATE_WORK_DAY_FAILED",
        workDayId: data.id,
        error,
      });
      dispatchError({
        e,
        title: "Update work day record error",
      });
    }
  };
};
