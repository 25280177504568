import React from "react";

import { ErrorModalIcon } from "../../CustomIcons/CustomIcons.component";
import Modal from "../Modal/Modal";

/**
 * Default Display
 * Displays the global state error (title and text)
 *
 * Functionality
 * Sets global state error to null when "Git it" button is clicked
 * Sets global state error to null when CloseIcon is clicked
 */
export interface ErrorModalProps {
  actions?: JSX.Element[];
  handleCancel: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  handleOk?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  text: string;
  title: string;
  visible: boolean;
}
const ErrorModal: React.FC<ErrorModalProps> = ({
  actions,
  handleCancel,
  handleOk = () => {},
  text,
  title,
  visible,
}) => {
  return (
    <Modal
      className="ErrorModal"
      centered={true}
      visible={visible}
      okText="Got it"
      cancelButtonProps={{ style: { display: "none" } }}
      okButtonProps={{
        type: "primary",
      }}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={actions ? actions : undefined}
    >
      <ErrorModalIcon className="ErrorModal__Icon" />
      <div className="ErrorModal__Message">
        <span className="ErrorModal__Message__Title">{title}</span>
        <span className="ErrorModal__Message__Text">{text}</span>
      </div>
    </Modal>
  );
};

export default ErrorModal;
