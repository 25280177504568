import React from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import { LoadingIcon } from "../../CustomIcons/CustomIcons.component";

/**
 * Default display
 * Displays activity input in read mode
 *
 * Functionality
 * Displays if prop section_text is not empty
 * Displays even if text is empty if prop display_section_in_read_if_empty_yn is true
 * Displays label if prop display_title_in_read_yn is true
 * Processes prop section_text markdown
 */
export interface ActivityInputDisplayProps {
  activityInput:
    | ActivityInputObject
    | {
        [key: string]: any;
      };
  loading: ActivityObject["loading"];
}
const ActivityInputDisplay: React.FC<ActivityInputDisplayProps> = ({
  activityInput,
  loading,
}) => {
  const {
    display_section_in_read_if_empty_yn,
    display_title_in_read_yn,
    section_text,
    section_title,
  } = activityInput;

  return (
    <>
      {loading && (
        <div className="ActivityInputDisplay">
          <LoadingIcon />
        </div>
      )}

      {/** Handle formatted activity input */}
      {!loading &&
        activityInput.hasOwnProperty("section_text") &&
        (section_text !== "" || display_section_in_read_if_empty_yn) && (
          <div className="ActivityInputDisplay">
            {display_title_in_read_yn && (
              <div className="ActivityInputDisplay__Label">
                <span>{section_title}</span>
              </div>
            )}

            <div className="ActivityInputDisplay__Text">
              <span>
                <ReactMarkdown
                  plugins={[gfm]}
                  source={section_text}
                ></ReactMarkdown>
              </span>
            </div>
          </div>
        )}

      {!loading &&
        !activityInput.hasOwnProperty("section_text") &&
        Object.entries(activityInput).map(([, value], index) => {
          /** Handle unformatted activity input */
          return (
            <div className="ActivityInputDisplay" key={index}>
              <div className="ActivityInputDisplay__Text">
                <span>
                  <ReactMarkdown
                    plugins={[gfm]}
                    source={String(value)}
                  ></ReactMarkdown>
                </span>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default ActivityInputDisplay;
