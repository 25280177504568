import React from "react";
import { Tooltip } from "antd";
import cn from "classnames";

/**
 *
 * The <TimeSlot /> component is used to show the colored bar of different times.
 * We can use "TimeSlot" object to describe the duration/color/title/popover... of this component,
 */
const TimeSlot: React.FC<TimeSlotProps> = ({ timeSlot }) => {
  const [visible, setVisible] = React.useState(false);
  const [trigger, setTrigger] = React.useState<"hover" | "click">("hover");

  // pass hideTooltip handler to child
  // so that child component can control it
  const tooltipContent = timeSlot.tooltip ? (
    <div className="TimeSlot__Tooltip">
      {React.cloneElement(timeSlot.tooltip as any, {
        onHideTooltip: () => setVisible(false),
        onSetTrigger: (t: "hover" | "click") => setTrigger(t),
      })}
    </div>
  ) : (
    <></>
  );

  const slot = (
    <div
      style={
        {
          display: timeSlot.durationInSeconds === 0 ? "none" : "block",
          backgroundColor: timeSlot.color,
          flex: timeSlot.durationInSeconds,
          border: `1px solid ${timeSlot.borderColor}`,
          cursor: timeSlot.tooltip ? "pointer" : "default",
          "--stripeColor": timeSlot.color, // pass color to pseudo element
        } as any
      }
      className={cn({ TimeSlot__Stripe: timeSlot.stripe })}
    />
  );

  const handleVisibleChange = (visible: boolean) => setVisible(visible);
  return timeSlot.tooltip ? (
    <Tooltip
      overlayInnerStyle={{ padding: 0 }}
      overlayStyle={{ paddingBottom: "14px" }}
      placement="top"
      title={tooltipContent}
      visible={visible}
      destroyTooltipOnHide={true}
      getPopupContainer={(trigger: any) => trigger.parentNode}
      onVisibleChange={handleVisibleChange}
      trigger={trigger}
    >
      {slot}
    </Tooltip>
  ) : (
    slot
  );
};

export default TimeSlot;
