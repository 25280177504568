import { Avatar } from "antd";
import React from "react";

import CustomAvatar from "../../CustomAvatar/CustomAvatar";
import { getInitials } from "../../../utils/string.util";

const ObserversTooltip: React.FC<ObserversTooltipProps> = ({ observers }) => {
  const observersJsx = observers.map((ob) => (
    <div key={ob.id} className="ObserversTooltip__Item">
      <CustomAvatar
        size={20}
        src={ob.avatar}
        fallbackText={getInitials(ob.displayName)}
      />
      <span className="ObserversTooltip__Name">{ob.displayName}</span>
    </div>
  ));
  return (
    <div className="ObserversTooltip">
      <div className="ObserversTooltip__Title">
        Your Checkout is shared with the following users.
      </div>
      <div>{observersJsx}</div>
    </div>
  );
};

export default ObserversTooltip;
