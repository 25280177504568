import React from "react";
import { useSelector, useDispatch } from "react-redux";
import moment, { Moment } from "moment";

import usePreviousHook from "../../hooks/usePrevious.hook";

import StartWorkAvatar from "../../modules/StartWork/StartWorkAvatar/StartWorkAvatar.component";
import StartWorkTimeDisplay from "../../modules/StartWork/StartWorkTimeDisplay/StartWorkTimeDisplay.component";
import StartWorkBtn from "../../modules/StartWork/StartWorkBtn/StartWorkBtn.component";
import GlobalSystemMessage from "../../modules/General/GlobalSystemMessage/GlobalSystemMessage";

import { startLogout } from "../../actions/auth.action";
import { selectErrorState } from "../../reducers/error.reducer";
import { selectUserDetailsState } from "../../reducers/user-details.reducer";
import { minutesToMs } from "../../utils/time-conversion.util";

const StartWorkView = () => {
  const dispatch = useDispatch();

  const error = useSelector(selectErrorState);
  const {
    data: { timezone: userTimezone },
    loading: userDetailsLoading,
  } = useSelector(selectUserDetailsState);

  const [time, setTime] = React.useState<Moment>();

  const handleClickLogout = () => {
    dispatch(startLogout());
  };

  React.useEffect(() => {
    if (!userDetailsLoading && userTimezone) {
      // wait for user details to be loaded so default moment instance will use user's timezone
      setTime(moment());

      let intervalID = setInterval(() => {
        setTime((c) => {
          return moment(c).add(10, "seconds");
        });
      }, minutesToMs(1));

      return () => clearInterval(intervalID);
    }
  }, [userTimezone, userDetailsLoading]);

  return (
    <div className="View">
      {error && <GlobalSystemMessage />}
      <div className="StartWork">
        <div className="StartWork__Container">
          <StartWorkAvatar handleClick={handleClickLogout} />
          <StartWorkTimeDisplay time={time} />
          <StartWorkBtn />
        </div>
        {/*<StartWorkChangeWallpaper />*/}
      </div>
    </div>
  );
};

export default StartWorkView;
