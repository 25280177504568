import React from "react";
import { Checkbox, Form, Popover, Steps, Tooltip } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import AccessRightsForm from "../AccessRightsForm/AccessRightsForm";
import AvatarUploader from "../../AccountSettings/AvatarUploader/AvatarUploader";
import Button from "../../Button/Button";
import { InfoCircleOutlinedIcon } from "../../CustomIcons/CustomIcons.component";
import ExternalIntegrationForm from "../ExternalIntegrationForm/ExternalIntegrationForm";
import WorkspaceMemberAccountForm from "../WorkspaceMemberAccountForm/WorkspaceMemberAccountForm";
import Modal from "../../General/Modal/Modal";
import PersonalForm from "../../AccountSettings/PersonalForm/PersonalForm.component";

import { workHoursSources } from "../../../constants/constants";
import { memberTypes } from "../../../constants/member.constant";
import { toBase64 } from "../../../utils/base64.util";

const { Step } = Steps;

export interface AddWorkspaceMemberModalProps {
  showModal: boolean;
  onCancelCallback: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onCloseCallback: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onConfirmCallback: Function;
}
const AddWorkspaceMemberModal: React.FC<AddWorkspaceMemberModalProps> = ({
  showModal,
  onCancelCallback,
  onCloseCallback,
  onConfirmCallback,
}) => {
  const [workspaceAccountForm] = Form.useForm();
  const [workspacePersonalForm] = Form.useForm();
  const [workspaceAccessRightsForm] = Form.useForm();
  const [workspaceExternalIntegrationForm] = Form.useForm();

  const [current, setCurrent] = React.useState(0);
  const [email, setEmail] = React.useState("");
  const [userType, setUserType] = React.useState(memberTypes.MEMBER);
  const [teamPageOwners, setTeamPageOwners] = React.useState<AccountUser[]>([]);

  const [fullName, setFullName] = React.useState<AccountUser["full_name"]>("");
  const [displayName, setDisplayName] = React.useState<
    AccountUser["display_name"]
  >("");
  const [workspaceRole, setWorkspaceRole] = React.useState<
    AccountUser["workspace_role"]
  >("");
  const [country, setCountry] = React.useState<AccountUser["country"]>("");
  const [city, setCity] = React.useState<AccountUser["city"]>("");
  const [timezone, setTimezone] = React.useState<AccountUser["timezone"]>("");
  const [avatar, setAvatar] = React.useState<File | "">();
  const [base64Avatar, setBase64Avatar] = React.useState("");

  const [workHoursSource, setWorkHoursSource] = React.useState(
    workHoursSources.OFFICE_TIME
  );

  const [allowAddManualShifts, setAllowAddManualShifts] = React.useState(false);
  const [allowInvisibleMode, setAllowInvisibleMode] = React.useState(false);

  const [externalTrackerEnabled, setExternalTrackerEnabled] = React.useState(
    false
  );
  const [externalTrackerConfig, setExternalTrackerConfig] = React.useState("");
  const [externalCalendarConfig, setExternalCalendarConfig] = React.useState(
    ""
  );

  const [sendInvitation, setSendInvitation] = React.useState(true);
  const [showEmailConfirmation, setShowEmailConfirmation] = React.useState(
    false
  );

  const getBase64Avatar = React.useCallback(async () => {
    if (avatar) {
      setBase64Avatar(await toBase64(avatar));
    } else {
      setBase64Avatar("");
    }
  }, [avatar]);

  const getAddMemberFormValues = React.useCallback(() => {
    return {
      email,
      userType,
      teamPageOwners,
      fullName,
      displayName,
      workspaceRole,
      country,
      city,
      timezone,
      avatar,
      workHoursSource,
      externalTrackerEnabled,
      allowAddManualShifts,
      allowInvisibleMode,
      externalTrackerConfig,
      externalCalendarConfig,
    };
  }, [
    email,
    userType,
    teamPageOwners,
    fullName,
    displayName,
    workspaceRole,
    country,
    city,
    timezone,
    avatar,
    workHoursSource,
    externalTrackerEnabled,
    allowAddManualShifts,
    allowInvisibleMode,
    externalTrackerConfig,
    externalCalendarConfig,
  ]);

  React.useEffect(() => {
    getBase64Avatar();
  }, [getBase64Avatar]);

  const steps = [
    {
      title: "Account",
      content: (
        <>
          <div className="AddWorkspaceMemberModal__Instructions">
            <span>
              Enter the email address of the new member, choose the user type,
              and add the new member to one or multiple team pages.
            </span>
          </div>
          <WorkspaceMemberAccountForm
            showRequiredFieldsOnly={true}
            form={workspaceAccountForm}
            email={email}
            userType={userType}
            teamPageOwners={teamPageOwners}
            onValuesChange={({ email, userType, teamPageOwners }: any) => {
              if (email) setEmail(email);
              if (userType) setUserType(userType);
              if (teamPageOwners) setTeamPageOwners(teamPageOwners);
            }}
          />
        </>
      ),
    },
    {
      title: "Profile",
      content: (
        <>
          <div className="AddWorkspaceMemberModal__Instructions">
            <span>
              Enter the users profile info (optional). The user will be asked to
              confirm the profile during sign up.
            </span>
          </div>
          <div className="AddWorkspaceMemberModal__ProfileForm">
            <PersonalForm
              userDetails={
                {
                  full_name: fullName,
                  display_name: displayName,
                  workspace_role: workspaceRole,
                  country,
                  city,
                  timezone,
                } as AccountUser
              }
              form={workspacePersonalForm}
              onValuesChange={({
                fullName,
                displayName,
                role,
                country,
                city,
                timezone,
              }: any) => {
                if (fullName) setFullName(fullName);
                if (displayName) setDisplayName(displayName);
                if (role) setWorkspaceRole(role);
                if (country) setCountry(country);
                if (city) setCity(city);
                if (timezone) setTimezone(timezone);
              }}
            />
            <AvatarUploader
              name="avatar"
              defaultAvatar={base64Avatar}
              onAvatarChange={(avatar: File | "") => {
                setAvatar(avatar);
              }}
            />
          </div>
        </>
      ),
    },
    {
      title: "Settings",
      content: (
        <>
          <div className="AddWorkspaceMemberModal__Instructions">
            <span>
              Modify the following settings (optional). This can be changed
              later.
            </span>
          </div>
          <WorkspaceMemberAccountForm
            showOptionalFieldsOnly={true}
            showTitle={true}
            form={workspaceAccountForm}
            workHoursSource={workHoursSource}
            onValuesChange={({ workHoursSource }: any) => {
              if (workHoursSource) setWorkHoursSource(workHoursSource);
            }}
          />
          <AccessRightsForm
            form={workspaceAccessRightsForm}
            allowAddManualShifts={allowAddManualShifts}
            allowInvisibleMode={allowInvisibleMode}
            onValuesChange={({
              allowAddManualShifts,
              allowInvisibleMode,
            }: any) => {
              if (allowAddManualShifts)
                setAllowAddManualShifts(allowAddManualShifts);
              if (allowInvisibleMode) setAllowInvisibleMode(allowInvisibleMode);
            }}
          />
          <ExternalIntegrationForm
            form={workspaceExternalIntegrationForm}
            externalTrackerEnabled={externalTrackerEnabled}
            externalTrackerConfig={externalTrackerConfig}
            externalCalendarConfig={externalCalendarConfig}
            onValuesChange={({
              externalTracker,
              externalTrackerConfig,
              externalCalendarConfig,
            }: any) => {
              if (externalTracker) setExternalTrackerEnabled(externalTracker);
              if (externalTrackerConfig)
                setExternalTrackerConfig(externalTrackerConfig);
              if (externalCalendarConfig)
                setExternalCalendarConfig(externalCalendarConfig);
            }}
          />
          <div className="AddWorkspaceMemberModal__EmailInvitation">
            <Checkbox
              checked={sendInvitation}
              onChange={(e: CheckboxChangeEvent) => {
                setSendInvitation(e.target.checked);
              }}
            />
            <span>Send email invitation</span>
            <Tooltip title="You can send an email invitation now or later.">
              <InfoCircleOutlinedIcon />
            </Tooltip>
          </div>
        </>
      ),
    },
  ];

  const handleClickAddMember = (e: React.MouseEvent<HTMLElement>) => {
    if (sendInvitation) {
      onConfirmCallback();
    } else {
      setShowEmailConfirmation(true);
    }
  };

  return (
    <Modal
      className="AddWorkspaceMemberModal"
      title="Add new member"
      centered={true}
      visible={showModal}
      onCancel={onCancelCallback}
      footer={null}
    >
      <div className="AddWorkspaceMemberModal__Steps">
        <Steps current={current}>
          {steps.map((item, index) => (
            <Step
              key={item.title}
              title={item.title}
              subTitle={index !== 0 ? "(Optional)" : undefined}
            />
          ))}
        </Steps>
        <div className="AddWorkspaceMemberModal__Steps__Content">
          {steps[current].content}
        </div>
        <div className="AddWorkspaceMemberModal__Steps__Action">
          <Button onClick={onCloseCallback}>Cancel</Button>
          {current > 0 && (
            <Button onClick={() => setCurrent((c) => c - 1)}>Back</Button>
          )}
          {current < steps.length - 1 && (
            <Button
              type="primary"
              onClick={() => {
                setCurrent((c) => c + 1);
              }}
            >
              Next
            </Button>
          )}
          {current === steps.length - 1 && (
            <Popover
              content={
                <div className="AddWorkspaceMemberModal__Popover__Content">
                  <div className="AddWorkspaceMemberModal__Popover__Content__Title">
                    <span>Add without sending invitation?</span>
                  </div>
                  <div className="AddWorkspaceMemberModal__Popover__Content__Message">
                    <span>
                      Do you want to add this member without sending an email
                      invitation? You can always send it later.
                    </span>
                  </div>
                  <div className="AddWorkspaceMemberModal__Popover__Content__Action">
                    <Button onClick={() => setShowEmailConfirmation(false)}>
                      Cancel
                    </Button>
                    <Button type="primary" onClick={handleClickAddMember}>
                      Yes, add without invitation
                    </Button>
                  </div>
                </div>
              }
              placement="top"
              visible={showEmailConfirmation}
              overlayClassName="AddWorkspaceMemberModal__Popover"
            >
              <Button type="primary" onClick={handleClickAddMember}>
                Add Member
              </Button>
            </Popover>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AddWorkspaceMemberModal;
