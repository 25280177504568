import React from "react";
import { Popover } from "antd";

import { InTheOfficeContentContext } from "../../../views/InTheOffice/InTheOfficeContent";

import Button from "../../Button/Button";
import {
  FilterIcon,
  FilterActiveIcon,
} from "../../CustomIcons/CustomIcons.component";
import FilterTasksButtonPopoverContent from "./FilterTasksButtonPopoverContent/FilterTasksButtonPopoverContent";

const FilterTasksButton = () => {
  const inTheOfficeContentContext = React.useContext(InTheOfficeContentContext);

  const { taskOwnersFilter, taskMembersFilter } = inTheOfficeContentContext;

  const hasFilter = taskOwnersFilter.length > 0 || taskMembersFilter.length > 0;

  return (
    <Popover
      content={<FilterTasksButtonPopoverContent />}
      trigger="click"
      placement="bottomRight"
      overlayClassName="FilterTasksButton__Popover"
    >
      <Button className="FilterTasksButton">
        {hasFilter ? <FilterActiveIcon /> : <FilterIcon />}
      </Button>
    </Popover>
  );
};

export default FilterTasksButton;
