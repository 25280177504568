import { RootState } from "../store/configureStore";

export type TrackingTask = TaskJoinedToUserAndScheduledObject;
export const selectTrackingTaskState = (rootState: RootState) =>
  rootState.trackingTask;
export const trackingTaskDefaultState = {} as TrackingTask;
export const trackingTaskReducer = (
  state = trackingTaskDefaultState,
  action: { type: string; trackingTask: TrackingTask }
) => {
  switch (action.type) {
    case "SET_TRACKING_TASK":
      return action.trackingTask;
    default:
      return state;
  }
};

export type TrackingStatus = "tracking" | "stopped" | "paused";
export const selectTrackingStatusState = (rootState: RootState) =>
  rootState.trackingStatus;
export const trackingStatusDefaultState = "stopped" as TrackingStatus;
export const trackingStatusReducer = (
  state = trackingStatusDefaultState,
  action: { type: string; trackingStatus: TrackingStatus }
) => {
  switch (action.type) {
    case "SET_TRACKING_STATUS":
      return action.trackingStatus;
    default:
      return state;
  }
};

export const selectLastTrackedTaskState = (rootState: RootState) =>
  rootState.lastTrackedTask;
export const lastTrackedTaskDefaultState = {} as TrackingTask;
export const lastTrackedTaskReducer = (
  state = lastTrackedTaskDefaultState,
  action: { type: string; lastTrackedTask: TaskJoinedToUserAndScheduledObject }
) => {
  switch (action.type) {
    case "SET_LAST_TRACKED_TASK":
      return action.lastTrackedTask;
    default:
      return state;
  }
};
