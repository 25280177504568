import React from "react";

import StarredTeamMemberItem from "../StarredTeamMemberItem/StarredTeamMemberItem";

export interface StarredTeamMemberListProps {
  endDate: TeamMemberSelectorProps["endDate"];
  onClickCallback: Function;
  maxCount?: number;
  starredObservableUsers?: ObservableUserArray;
  startDate: TeamMemberSelectorProps["startDate"];
}
const StarredTeamMemberList: React.FC<StarredTeamMemberListProps> = ({
  endDate,
  onClickCallback,
  maxCount = 11,
  starredObservableUsers,
  startDate,
}) => {
  return (
    <div className="StarredTeamMemberList">
      {!!starredObservableUsers &&
        starredObservableUsers.map((observableUser, index) => {
          const count = index + 1;
          if (count <= maxCount) {
            return (
              <StarredTeamMemberItem
                key={index}
                endDate={endDate}
                observableUser={observableUser}
                onClickCallback={onClickCallback}
                startDate={startDate}
              />
            );
          } else {
            return <></>;
          }
        })}
    </div>
  );
};

export default StarredTeamMemberList;
