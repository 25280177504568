import React from "react";
import { Input } from "antd";

import Button from "../../Button/Button";
import Confirm from "../../General/Confirm/Confirm";

type ConfirmType = "duplicate" | "restore" | "none";

/**
 *
 * This component is used to add new section, there are 3 confirms type,
 * which are "duplicat", "restore" and "none".
 * if the new section already exists in sections list, then it is in duplicate state;
 * if the new section already exists in "removed" list, then it is in restore state;
 * otherwise it is normal state.
 */
const AddSectionInput = ({
  sections,
  removedSections,
  onAdd,
  onRestore,
}: AddSectionInputProps) => {
  const [value, setValue] = React.useState("");
  const [confirmType, setConfirmType] = React.useState<ConfirmType>("none");
  const [loading, setLoading] = React.useState(false);

  const handleAdd = async () => {
    if (sections.includes(value)) {
      setConfirmType("duplicate");
      return;
    }
    if (removedSections.includes(value)) {
      setConfirmType("restore");
      return;
    }
    setConfirmType("none");
    setLoading(true);
    await onAdd(value);
    setLoading(false);
    setValue("");
  };

  const handleRestore = async () => {
    setLoading(true);
    await onRestore(value);
    setLoading(false);
    setValue("");
  };

  const button = (
    <Button
      size="small"
      type="primary"
      className="AddSectionInput__Button"
      loading={loading}
      onClick={handleAdd}
    >
      Add
    </Button>
  );

  return (
    <div className="AddSectionInput">
      <Input
        size="small"
        className="AddSectionInput__Input"
        placeholder="Add new section"
        value={value}
        onChange={(e: any) => setValue(e.target.value)}
      />
      {confirmType === "none" && button}
      {confirmType === "duplicate" && (
        <Confirm
          defaultVisible={true}
          content="This section already exists."
          showCancel={false}
          onOk={() => {}}
        >
          {button}
        </Confirm>
      )}
      {confirmType === "restore" && (
        <Confirm
          defaultVisible={true}
          content="This section already exists in ‘Removed Sections’. Do you want to restore it to the task list?"
          onOk={handleRestore}
        >
          {button}
        </Confirm>
      )}
    </div>
  );
};

export default AddSectionInput;
