import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import { useFeature } from "flagged";

import useAsyncTaskById from "../../../hooks/useAsyncTaskById.hook";
import useRedirect from "../../../hooks/useRedirect.hook";

import ListPanelHeader from "../../List/ListPanelHeader/ListPanelHeader";
import Details from "../../InTheOffice/Details/Details";
import List from "../../List/List";
import TaskDetails from "../../Tasks/TaskDetails/TaskDetails";
import ManageSectionsModal from "../../Section/ManageSectionsModal/ManageSectionsModal";
import PageUnderConstruction from "../../General/PageUnderConstruction/PageUnderConstruction";

import { setTaskDetails } from "../../../actions/task-details.action";
import { personalTasksProjectObject } from "../../../constants/constants";
import { selectListsState } from "../../../reducers/list.reducer";
import { selectProjectsState } from "../../../reducers/projects.reducer";
import { selectTaskDetailsState } from "../../../reducers/task-details.reducer";
import { formatUrlTitle, copyUrlToClipboard } from "../../../utils/url.util";

const ListPanel = () => {
  const isBeta = useFeature("under_development");
  const redirect = useRedirect();

  const { listId, taskId } = useParams<{ listId: string; taskId?: string }>();

  const dispatch = useDispatch();

  const { data: lists } = useSelector(selectListsState);
  const { data: projects } = useSelector(selectProjectsState);
  const taskDetails = useSelector(selectTaskDetailsState);

  const [collapse, setCollapse] = React.useState<CollapseState>("expand");
  const [manageSectionsOpen, setManageSectionsOpen] = React.useState(false);
  const [showArchive, setShowArchive] = React.useState(false);

  const [consolidatedTask, consolidatedTaskLoading] = useAsyncTaskById(taskId);

  React.useEffect(() => {
    if (!consolidatedTaskLoading && consolidatedTask) {
      dispatch(setTaskDetails(consolidatedTask));
    } else if (!consolidatedTaskLoading && !consolidatedTask) {
      dispatch(setTaskDetails({} as TaskJoinedToUserAndScheduledObject));
    }
  }, [dispatch, consolidatedTask, consolidatedTaskLoading]);

  const list = lists.find((item) => item.id === listId);
  const projectOfList =
    !!projects && !!list && projects.find((p) => p.id === list.parent_project);

  const handleTaskSelect = (task: TaskObject) => {
    if (list) {
      redirect({
        path: `/l/${listId}/${formatUrlTitle(list.title)}/${
          task.id
        }/${formatUrlTitle(task.title)}/activity`,
      });
    }
  };
  const handleTaskCopy = (task: TaskObject) => {
    if (list) {
      const origin = window.location.origin;
      const url = `${origin}/l/${listId}/${formatUrlTitle(list.title)}/${
        task.id
      }/${formatUrlTitle(task.title)}`;
      copyUrlToClipboard(url);
    }
  };

  return (
    <>
      <div className="ListPanel">
        {!!isBeta ? (
          <>
            {projectOfList && list && (
              <ListPanelHeader
                list={list}
                projectOfList={projectOfList}
                onCollapse={() => setCollapse("collapse")}
                onExpand={() => setCollapse("expand")}
                onManageSections={() => setManageSectionsOpen(true)}
                onShowArchive={() => {
                  setShowArchive(true);
                }}
                showArchive={showArchive}
              />
            )}
            {list && projectOfList && (
              <List
                list={list}
                project={projectOfList}
                isUserProject={
                  projectOfList.title === personalTasksProjectObject.title
                }
                panelType="project"
                collapse={collapse}
                onClickNormalMode={() => {
                  setShowArchive(false);
                }}
                onTaskSelect={handleTaskSelect}
                onTaskCopy={handleTaskCopy}
                showArchive={showArchive}
              />
            )}

            {!isEmpty(taskDetails) ? (
              <TaskDetails task={taskDetails} />
            ) : (
              <Details />
            )}
          </>
        ) : (
          <PageUnderConstruction />
        )}
      </div>
      <ManageSectionsModal
        open={manageSectionsOpen}
        onClose={() => setManageSectionsOpen(false)}
      />
    </>
  );
};

export default ListPanel;
