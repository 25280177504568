import React from "react";
import moment, { Moment } from "moment";

import Button from "../../Button/Button";
import {
  DeleteIcon,
  LoadingIcon,
} from "../../CustomIcons/CustomIcons.component";
import { AddIcon } from "../../CustomIcons/CustomIcons.component";
import ShiftPopover from "./ShiftPopover";
import { getTotalDuration } from "../../../utils/time-conversion.util";

const getTitle = (type: TimelineType) => {
  switch (type) {
    case "work":
    case "break":
    case "active":
    case "manual":
      return type;
    case "inactive":
      return "During break time tracking is ignored";
    case "processing":
      return "Processing ...";
    default:
      return "";
  }
};
const getAddShiftType = (type: TimelineType) => {
  switch (type) {
    case "work":
      return "break";
    case "break":
      return "work";
    default:
      return type;
  }
};
const getButtonTitle = (type: TimelineType) => {
  switch (type) {
    case "break":
      return "Add Work";
    case "work":
      return "Add Break";
    case "manual":
      return "Edit";
    default:
      return "";
  }
};
/*
 * TimelineTooltip is the pop-up component when user hovers on the timeline bar
 * in Edit shift Modal.
 * There is 2 mode for this component:
 * 1. when addShift flag is set to false, it will display the current shift information.
 * 2. when addShift flag is set to true (when user clicks the add-time button), it will change
 *    to <ShiftPopover /> component which user can add shift time on it.
 */
const TimelineTooltip: React.FC<TimelineTooltipProps> = ({
  timeline,
  primaryColor,
  secondaryColor,
  min,
  max,
  onHideTooltip,
  onSetTrigger,
  onSave,
}) => {
  const [addShift, setAddShift] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const title = getTitle(timeline.type);
  const endOrNow = timeline.end
    ? moment(timeline.end).startOf("minute")
    : moment().startOf("minute");
  const startTime = moment(timeline.start).format("HH:mm");
  const endTime = endOrNow.format("HH:mm");
  const diff = endOrNow.diff(moment(timeline.start).startOf("minute"));

  const handleAdd = (type: TimelineType) => {
    setAddShift(true);
    // set tooltip trigger from hover to click
    // to avoid unexceped close when use clicks on time picker
    if (onSetTrigger) {
      onSetTrigger("click");
    }
  };

  // hide tooltip manually
  const hideTooltip = () => {
    if (onHideTooltip) {
      onHideTooltip();
    }
  };

  const handleStatusSave = async (start: Moment, end: Moment) => {
    if (onSave) {
      await onSave(start, end);
    }
    hideTooltip();
  };

  const handleStatusDelete = async () => {
    try {
      if (onSave) {
        setDeleting(true);
        await onSave(
          moment(timeline.start).startOf("minute"),
          moment(timeline.end).startOf("minute")
        );
        setDeleting(false);
        hideTooltip();
      }
    } catch (e) {
      setDeleting(false);
    }
  };
  return (
    <div>
      {addShift ? (
        <div className="TimelineTooltip__Popover">
          <ShiftPopover
            type={getAddShiftType(timeline.type)}
            defaultStart={moment(timeline.start).startOf("minute")}
            defaultEnd={
              timeline.end ? moment(timeline.end).startOf("minute") : null
            }
            min={min}
            max={max}
            onSave={handleStatusSave}
          />
        </div>
      ) : (
        <div className="TimelineTooltip">
          <div
            className="TimelineTooltip__Badge"
            style={{ backgroundColor: primaryColor }}
          />
          <div className="TimelineTooltip__Content">
            <div className="TimelineTooltip__Info">
              <div
                className="TimelineTooltip__Title"
                style={{ color: primaryColor }}
              >
                {title}
              </div>
              <div>
                {startTime} - {endTime} (
                {getTotalDuration(moment.duration(diff))})
              </div>
            </div>
            {timeline.type === "manual" && (
              <div className="TimelineTooltip__Note">{timeline.note}</div>
            )}
            {(timeline.type === "work" ||
              timeline.type === "break" ||
              timeline.type === "manual") && (
              <div className="TimelineTooltip__Extra">
                <Button
                  className="TimelineTooltip__Extra__Add"
                  size="small"
                  icon={
                    timeline.type !== "manual" && (
                      <AddIcon style={{ color: secondaryColor }} />
                    )
                  }
                  ghost={true}
                  onClick={() => handleAdd(timeline.type)}
                >
                  {getButtonTitle(timeline.type)}
                </Button>
                {deleting ? (
                  <LoadingIcon className="TimelineTooltip__Extra__Del TimelineTooltip__Extra__Loading" />
                ) : (
                  <DeleteIcon
                    className="TimelineTooltip__Extra__Del"
                    onClick={handleStatusDelete}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TimelineTooltip;
