import React from "react";
import { Moment } from "moment";
import cn from "classnames";

import CheckoutPanel from "../CheckoutPanel/CheckoutPanel";
import Modal from "../../General/Modal/Modal";

export interface CheckoutModalProps {
  checkoutUser?: UserObject;
  date?: Moment;
  displayModal: boolean;
  mode: CheckoutPanelMode;
  setDisplayModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const CheckoutModal: React.FC<CheckoutModalProps> = ({
  checkoutUser,
  date,
  displayModal,
  mode,
  setDisplayModal,
}) => {
  const handleOk = () => {
    setDisplayModal(false);
  };

  const handleCancel = () => {
    setDisplayModal(false);
  };

  return (
    <Modal
      className={cn("CheckoutModal", {
        "CheckoutModal--LeaveTheOffice": mode === "leave_the_office",
        "CheckoutModal--History": mode === "history",
      })}
      visible={displayModal}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      zIndex={1050}
    >
      <CheckoutPanel
        date={date}
        mode={mode}
        setDisplayModal={setDisplayModal}
        user={checkoutUser}
      />
    </Modal>
  );
};

export default CheckoutModal;
