import { Dispatch } from "redux";

import { getExternalTimeTrackerListService } from "../services/third-party-tracking.service";
import { dispatchError } from "../utils/error.util";

export const requestFetchThirdPartyTrackingList = () => ({
  type: "REQUEST_FETCH_THIRD_PARTY_TRACKING_LIST",
});
export const requestFetchThirdPartyTrackingListSuccess = (
  thirdPartyTrackingList: ExternalTimeTrackerArray
) => ({
  type: "REQUEST_FETCH_THIRD_PARTY_TRACKING_LIST_SUCCESS",
  thirdPartyTrackingList,
});
export const requestFetchThirdPartyTrackingListFailed = (error: string) => ({
  type: "REQUEST_FETCH_THIRD_PARTY_TRACKING_LIST_FAILED",
  error,
});

export const startPopulateThirdPartyTrackingList = () => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(requestFetchThirdPartyTrackingList());
      const response = await getExternalTimeTrackerListService();
      if (response.status === 200) {
        const thirdPartyTrackingList: ExternalTimeTrackerArray = response.data;
        dispatch(
          requestFetchThirdPartyTrackingListSuccess(thirdPartyTrackingList)
        );

        // temporarily fixed to set third party tracker to record with "worksnaps" title
        const thirdPartyTracker: ExternalTimeTrackerObject =
          thirdPartyTrackingList.find((item) => (item.title = "worksnaps")) ||
          ({} as ExternalTimeTrackerObject);
        dispatch(setThirdPartyTracker(thirdPartyTracker));
      } else {
        const error = "getExternalTimeTrackerListService status is not 200";
        dispatch(requestFetchThirdPartyTrackingListFailed(error));
        throw new Error();
      }
    } catch (e) {
      const error = (e as Error).message;
      dispatch(requestFetchThirdPartyTrackingListFailed(error));
      dispatchError({
        e,
        title: "Populate third party tracking list error",
      });
    }
  };
};

export const setThirdPartyTracker = (
  thirdPartyTracker: ExternalTimeTrackerObject
) => ({
  type: "SET_THIRD_PARTY_TRACKER",
  thirdPartyTracker,
});
