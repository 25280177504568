import unionBy from "lodash/unionBy";
import { RootState } from "../store/configureStore";

// THe initial state for unread activities reducer includes projects and activities,
// and they are all set to empty array by default.
export interface UnreadProjectsState extends BaseReducerState {
  data: UnreadProject[];
}
export interface UnreadActivitiesState extends BaseReducerState {
  data: UnreadActivity[];
}
export interface UnreadState {
  projects: UnreadProjectsState;
  activities: UnreadActivitiesState;
}
export const selectUnreadState = (rootState: RootState) => rootState.unread;
export const unreadReducerDefaultState: UnreadState = {
  projects: {
    data: [],
    error: "",
    loading: false,
  },
  activities: {
    data: [],
    error: "",
    loading: false,
  },
};

const unreadReducer = (
  state = unreadReducerDefaultState,
  action: {
    type: string;
    payload: UnreadProject[] | UnreadActivity[];
    error: string;
  }
) => {
  switch (action.type) {
    case "REQUEST_FETCH_UNREAD_PROJECTS":
      return {
        ...state,
        projects: {
          data: state.projects.data,
          error: "",
          loading: true,
        },
      };
    case "REQUEST_FETCH_UNREAD_PROJECTS_SUCCESS":
      // filter out projects / lists / sections / tasks if empty
      const projects: UnreadProject[] = (action.payload as UnreadProject[])
        .map((project) => {
          const { lists } = project;
          return {
            ...project,
            lists: lists
              .map((list) => {
                const { sections } = list;
                return {
                  ...list,
                  sections: sections
                    .map((section) => {
                      const { tasks } = section;
                      return {
                        ...section,
                        tasks: tasks.filter((task) => task.activity_count > 0),
                      };
                    })
                    .filter((section) => section.tasks.length > 0),
                };
              })
              .filter((list) => list.sections.length > 0),
          };
        })
        .filter((project) => project.lists.length > 0);
      return {
        ...state,
        projects: {
          data: projects,
          error: "",
          loading: false,
        },
      };
    case "REQUEST_FETCH_UNREAD_PROJECTS_FAILED":
      return {
        ...state,
        projects: {
          data: state.projects.data,
          error: action.error,
          loading: false,
        },
      };
    case "REQUEST_FETCH_UNREAD_ACTIVITIES_IN_PROJECT":
    case "REQUEST_MARK_UNREAD_ACTIVITIES":
      return {
        ...state,
        activities: {
          data: state.activities.data,
          error: "",
          loading: true,
        },
      };
    case "REQUEST_FETCH_UNREAD_ACTIVITIES_IN_PROJECT_SUCCESS":
      // Here we union all the new activities and old activiteis together
      // to form into a new activities state.
      const activities: UnreadActivity[] = unionBy(
        state.activities.data,
        action.payload as UnreadActivity[],
        (activity) => activity.id
      );
      return {
        ...state,
        activities: {
          data: activities,
          error: "",
          loading: false,
        },
      };
    case "REQUEST_FETCH_UNREAD_ACTIVITIES_IN_PROJECT_FAILED":
    case "REQUEST_MARK_UNREAD_ACTIVITIES_FAILED":
      return {
        ...state,
        activities: {
          data: state.activities.data,
          error: action.error,
          loading: false,
        },
      };
    case "REQUEST_MARK_UNREAD_ACTIVITIES_SUCCESS": {
      const markedActivities = action.payload as UnreadActivity[];
      const activities: UnreadActivity[] = state.activities.data.filter(
        (item) => !markedActivities.find((ac) => ac.id === item.id)
      );
      const projects = state.projects.data
        .map((project) => {
          const { lists } = project;
          return {
            ...project,
            lists: lists
              .map((list) => {
                const { sections } = list;
                return {
                  ...list,
                  sections: sections
                    .map((section) => {
                      const { tasks } = section;
                      return {
                        ...section,
                        tasks: tasks
                          .map((task) => {
                            // get the activities count for a task that has been marked as read.
                            const count = markedActivities.filter(
                              (ac) => ac.task === task.id
                            ).length;
                            if (count > 0) {
                              return {
                                ...task,
                                activity_count: task.activity_count - count,
                              };
                            }
                            return task;
                          })
                          .filter((task) => task.activity_count > 0),
                      };
                    })
                    .filter((section) => section.tasks.length > 0),
                };
              })
              .filter((list) => list.sections.length > 0),
          };
        })
        .filter((project) => project.lists.length > 0);
      return {
        ...state,
        projects: {
          data: projects,
          error: "",
          loading: false,
        },
        activities: {
          data: activities,
          error: "",
          loading: false,
        },
      };
    }
    default:
      return state;
  }
};

export { unreadReducer as default };
