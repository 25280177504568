import React from "react";
import { useSelector } from "react-redux";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { Badge, Menu, SubMenuProps, Tooltip } from "antd";
import cn from "classnames";
import { Feature } from "flagged";

import useAsyncMyDeskTasksCount from "../../hooks/useAsyncMyDeskTasksCount.hook";
import useRedirect from "../../hooks/useRedirect.hook";

import {
  OfficeIcon,
  ReportsIcon,
  CheckoutIcon,
  TodayIcon,
  NextShiftIcon,
  StarFilledIcon,
  TeamIcon,
  CollapseIcon,
  ExpandIcon,
  CaretDownIcon,
  HistoryIcon,
  LoadingIcon,
  InfoCircleOutlinedIcon,
} from "../CustomIcons/CustomIcons.component";
import CheckoutStatusIcon from "../Checkout/CheckoutStatusIcon/CheckoutStatusIcon";
import ListUnreadActivitiesCount from "../List/ListUnreadActivitiesCount/ListUnreadActivitiesCount";
import ProjectInitial from "../Projects/ProjectInitial/ProjectInitial";
import TrackingTag from "../TaskTimeTracking/TrackingTag/TrackingTag";
import VerticalMenuHeader from "./VerticalMenuHeader/VerticalMenuHeader";

import {
  menuStaticKeys,
  personalTasksProjectObject,
  projectsDefaultColorInMenu,
} from "../../constants/constants";
import { selectProjectsState } from "../../reducers/projects.reducer";
import { selectNextCalendarScheduleState } from "../../reducers/third-party-calendar.reducer";
import { selectUserDetailsState } from "../../reducers/user-details.reducer";
import { selectListsInProject } from "../../selectors/list.selector";
import { selectListsState } from "../../reducers/list.reducer";
import { formatUrlTitle } from "../../utils/url.util";
import { getUserTaskGroupsCountService } from "../../services/tasks.service";

const { SubMenu } = Menu;

/**
 * Main menu of [In The Office View]
 *
 * Default Display
 * Displays menu items and projects
 *
 * Functionality
 * Allows user to set nav global state depending on key of menu item selected and navigate different panel content
 */

const VerticalMenu: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const redirect = useRedirect();

  const { pathname, search } = useLocation();
  const { projectId, listId } = useParams<{
    projectId?: string;
    listId?: string;
  }>();

  const urlTaskPage = new URLSearchParams(search).get("p");

  const { data: projectsData, loading: projectsLoading } = useSelector(
    selectProjectsState
  );
  const { data: lists } = useSelector(selectListsState);
  const { data: userDetails } = useSelector(selectUserDetailsState);

  const [openedProjects, setOpenedProjects] = React.useState<string[]>([]);
  const [
    myDeskTasksCount,
    myDeskTasksCountLoading,
  ] = useAsyncMyDeskTasksCount();

  const { todayTasksCount, nextWorkDayTasksCount, starredTasksCount } =
    myDeskTasksCount || {};

  const unarchivedProjects = React.useMemo(
    () => projectsData.filter(({ archived_yn }) => !archived_yn),
    [projectsData]
  );
  const unarchivedLists = React.useMemo(
    () => lists.filter(({ archived_yn }) => !archived_yn),
    [lists]
  );
  const hasProjects = unarchivedProjects.length > 0;
  const { team_page_access_granted_yn: hasTeamPageAccess } = userDetails;

  React.useEffect(() => {
    if (listId) {
      const list = unarchivedLists.find((l) => l.id === listId);
      if (list && list.parent_project) {
        setOpenedProjects([list.parent_project]);
      }
    }
  }, [listId, unarchivedLists]);

  React.useEffect(() => {
    if (projectId)
      setOpenedProjects((curr) => (curr.length > 0 ? curr : [projectId]));
  }, [projectId]);

  const handleSelectMenu = ({ key }: { key: string }) => {
    const keys = Object.entries(menuStaticKeys).map(([_, value]) => value);
    if (keys.includes(key)) {
      redirect({
        path: key,
      });
    }
  };

  const handleClickCollapse = () => {
    setOpenedProjects([]);
  };

  const handleClickExpand = () => {
    setOpenedProjects(unarchivedProjects.map(({ id }) => `${id}`));
  };

  const handleSubMenuTitleClick = (project: ProjectObject) => {
    redirect({
      path: `/p/${project.id}/${formatUrlTitle(project.title)}`,
    });
  };

  const handleProjectToggle = React.useCallback(
    (key: string) => {
      setOpenedProjects((prev) => {
        if (prev.includes(key) && projectId === key) {
          return prev.filter((item) => item !== key);
        }
        return [...prev, key];
      });
    },
    [projectId]
  );

  const handleListTitleClick = (list: ListObject) => {
    redirect({
      path: `/l/${list.id}/${formatUrlTitle(list.title)}`,
    });
  };

  const handleClickStableVersion = () => {
    if (searchParams.has("beta")) {
      const beta = searchParams.get("beta");
      if (beta) {
        searchParams.delete("beta");
        setSearchParams(searchParams);
      }
    }
  };

  const projectsJsx = unarchivedProjects.map((project: ProjectObject) => {
    const listsInProject = selectListsInProject(
      project,
      unarchivedLists
    ).filter((list) => list.show_list_in_sidebar_yn);
    const navIsProject = projectId === (project.id || "").toString();
    const navIsList = listsInProject.map(({ id }) => id).includes(listId || "");
    const projectColor =
      project.title === personalTasksProjectObject.title
        ? projectsDefaultColorInMenu
        : project.color.value;

    return (
      <SubMenu
        className={cn("vertical-menu__sub-menu", {
          "vertical-menu__sub-menu--selected": navIsProject || navIsList,
          "vertical-menu__sub-menu--disabled": listsInProject.length === 0,
        })}
        style={{
          background: navIsProject
            ? "rgba(255, 255, 255, 0.25)"
            : navIsList
            ? "rgba(255, 255, 255, 0.15)"
            : undefined,
        }}
        onTitleClick={() => {
          handleProjectToggle(project.id.toString());
          handleSubMenuTitleClick(project);
        }}
        key={project.id}
        icon={
          <>
            {navIsProject && (
              <div
                className="vertical-menu__sub-menu--selected__border"
                style={{
                  backgroundColor: projectColor,
                }}
              ></div>
            )}
            <ProjectInitial
              project={project}
              isMenu={true}
              isActive={navIsProject}
            />
          </>
        }
        title={project.title}
      >
        {listsInProject.length > 0 &&
          listsInProject.map((list) => {
            const { title: listTitle, id } = list;
            return (
              <Menu.ItemGroup key={id}>
                <Menu.Item
                  key={id}
                  onClick={() => handleListTitleClick(list)}
                  style={
                    listId === id
                      ? {
                          background: "rgba(255, 255, 255, 0.15)",
                          borderLeft: `2px solid ${projectColor}`,
                        }
                      : undefined
                  }
                >
                  <span className="vertical-menu__sub-menu__title">
                    {listTitle}
                  </span>
                  <Feature name="under_development">
                    <ListUnreadActivitiesCount list={list} />
                  </Feature>
                </Menu.Item>
              </Menu.ItemGroup>
            );
          })}
      </SubMenu>
    );
  });

  return (
    <div className="vertical-menu">
      <VerticalMenuHeader />
      <Menu
        openKeys={openedProjects}
        mode="inline"
        theme="dark"
        className="vertical-menu__menu"
        onSelect={handleSelectMenu}
        selectedKeys={[urlTaskPage ? `/${urlTaskPage}` : pathname]}
        expandIcon={(
          props: SubMenuProps & { isSubMenu: boolean; eventKey: string }
        ) => (
          <CaretDownIcon
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              handleProjectToggle(props.eventKey);
            }}
          />
        )}
      >
        <Menu.Item
          key={menuStaticKeys.OFFICE}
          icon={<OfficeIcon />}
          className="vertical-menu__item"
        >
          Office
        </Menu.Item>

        {hasTeamPageAccess && (
          <Menu.Item
            key={menuStaticKeys.TEAM}
            icon={<TeamIcon />}
            className="vertical-menu__item"
          >
            Team
          </Menu.Item>
        )}

        <Menu.Item
          key={menuStaticKeys.UNREAD}
          icon={<ReportsIcon />}
          className="vertical-menu__item"
        >
          Unread
        </Menu.Item>

        <Menu.Item
          key={menuStaticKeys.CHECKOUT}
          icon={<CheckoutIcon />}
          className="vertical-menu__item"
        >
          Checkout
          {/*
            * Temporarily removed
            <div className="vertical-menu__item__icon">
            <CheckoutStatusIcon />
          </div>
          */}
        </Menu.Item>

        <Menu.Item key="MY_DESK" className="vertical-menu__header">
          <span>My Desk</span>
        </Menu.Item>

        <Menu.Item
          key={menuStaticKeys.TODAY}
          icon={<TodayIcon />}
          className="vertical-menu__item"
        >
          <div className="vertical-menu__item__content">
            <span>Today</span>
            {myDeskTasksCountLoading && (
              <div className="vertical-menu__item__badge">
                <LoadingIcon />
              </div>
            )}

            {!myDeskTasksCountLoading && todayTasksCount !== undefined && (
              <div className="vertical-menu__item__badge">
                {todayTasksCount}
              </div>
            )}
            <Feature name="under_development">
              <TrackingTag />
            </Feature>
          </div>
        </Menu.Item>

        <Menu.Item
          key={menuStaticKeys.NEXT_SHIFT}
          icon={<NextShiftIcon />}
          className="vertical-menu__item"
        >
          <div className="vertical-menu__item__content">
            <span>Next Workday</span>
            {myDeskTasksCountLoading && (
              <div className="vertical-menu__item__badge">
                <LoadingIcon />
              </div>
            )}

            {!myDeskTasksCountLoading &&
              nextWorkDayTasksCount !== undefined && (
                <div className="vertical-menu__item__badge">
                  {nextWorkDayTasksCount}
                </div>
              )}
          </div>
        </Menu.Item>

        <Menu.Item
          key={menuStaticKeys.STARRED}
          icon={<StarFilledIcon />}
          className="vertical-menu__item"
        >
          <div className="vertical-menu__item__content">
            <span>Starred</span>
            {myDeskTasksCountLoading && (
              <div className="vertical-menu__item__badge">
                <LoadingIcon />
              </div>
            )}

            {!myDeskTasksCountLoading && starredTasksCount !== undefined && (
              <div className="vertical-menu__item__badge">
                {starredTasksCount}
              </div>
            )}
          </div>
        </Menu.Item>

        <Menu.Item
          key={menuStaticKeys.HISTORY}
          icon={<HistoryIcon />}
          className="vertical-menu__item"
        >
          <div className="vertical-menu__item__content">
            <span>History</span>
          </div>
        </Menu.Item>

        <Menu.Item key="PROJECTS" className="vertical-menu__header">
          <span>Projects</span>
          <div className="vertical-menu__header__actions">
            <CollapseIcon onClick={handleClickCollapse} />
            <ExpandIcon onClick={handleClickExpand} />
          </div>
        </Menu.Item>

        {projectsLoading && (
          <Menu.Item
            key="PROJECTS_LOADING"
            className="vertical-menu__item vertical-menu__item--loading"
          >
            <LoadingIcon />
          </Menu.Item>
        )}
        {!projectsLoading && hasProjects && projectsJsx}
      </Menu>

      <Feature name="under_development">
        <div className="vertical-menu__beta-indicator">
          <span>Beta Version</span>
          <Tooltip
            title={
              <u
                style={{ cursor: "pointer" }}
                onClick={handleClickStableVersion}
              >
                Go to the stable version
              </u>
            }
          >
            <InfoCircleOutlinedIcon />
          </Tooltip>
        </div>
      </Feature>
    </div>
  );
};

export { VerticalMenu as default };
