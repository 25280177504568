import { createServer, Model } from "miragejs";

import { store } from "../store/configureStore";

import { teamMemberArray } from "../constants/team.constant";
import activities from "../fixtures/activities.fixture";
import activityTypes from "../fixtures/activity-types.fixture";
import { statusHistoryArray as statusHistory } from "../fixtures/status-history.fixture";
import { workShiftArray as workShifts } from "../fixtures/work-shift.fixture";
import { checkoutStatus } from "../fixtures/checkout.fixture";
import { notifications } from "../fixtures/notifications.fixture";
import { sectionArray } from "../fixtures/section.fixture";

import { selectListById } from "../selectors/list.selector";
import {
  selectSectionById,
  selectSectionsInList,
} from "../selectors/section.selector";

export default () => {
  return createServer({
    models: {
      activities: Model,
      activityChangeLogs: Model,
      activityReadLogs: Model,
      activityTypes: Model,
      checkoutStatus: Model,
      statusHistory: Model,
      workShifts: Model,
      teamMemberArray: Model,
      notifications: Model,
    },

    fixtures: {
      activities,
      activityTypes,
      checkoutStatus,
      statusHistory,
      workShifts,
      teamMemberArray,
      notifications,
    },

    routes() {
      /**
       * mocked API endpoints
       * https://docs.google.com/document/d/15oY3Ci8pkNqFOTbXFsy0otIqKeHFKMiuGzlL83Ie7EI/edit
       */
      this.logging = false;

      this.urlPrefix = `http://${process.env.REACT_APP_HOST}`;
      this.namespace = "api";

      // epic 11
      this.get("/teamMembers/", (schema, request) => {
        return schema.db.teamMemberArray;
      });
      this.patch("/teamMembers/:id", (schema, request) => {
        const teamMemberId = request.params.id;
        const newAttrs = JSON.parse(request.requestBody);
        return this.db.teamMemberArray.update(teamMemberId, newAttrs);
      });
      this.get("/teamMemberUnreadActivitiesCount/:id", (schema, request) => {
        const teamMemberId = request.params.id;
        const teamMember = this.db.teamMemberArray.find(teamMemberId);
        return teamMember.unreadActivitiesCount;
      });

      // 13.9.1
      this.get("/notifications/", (schema, request) => {
        return schema.db.notifications;
      });

      this.put("/notifications/mark_all_as_read/", (schema, request) => {
        schema.db.notifications.update({ unread: false });
        return schema.db.notifications;
      });

      this.patch("/notifications/mark_as_read/:id/", (schema, request) => {
        const notificationId = request.params.id;
        return schema.db.notifications.update(notificationId, {
          unread: false,
        });
      });

      this.patch("/notifications/mark_as_read/", (schema, request) => {
        const data = JSON.parse(request.requestBody);
        const { ids } = data;
        const filtered = schema.db.notifications.where(
          ({ id }: NotificationObject) => ids.includes(id)
        );
        filtered.forEach(({ id }: NotificationObject) =>
          schema.db.notifications.update(id, { unread: false })
        );
        return schema.db.notifications;
      });

      // 13.11.3
      this.get(
        "/projects/task_list_removed_sections/:id",
        (schema, request) => {
          const listId = request.params.id;
          const list = selectListById(listId);
          const sections = list ? selectSectionsInList(list) : [];
          return sections.length > 0 ? [sections[0]] : [];
        }
      );

      this.get(
        "/projects/task_list_sections_with_archived_tasks/:id",
        (schema, request) => {
          const listId = request.params.id;
          const list = selectListById(listId);
          const sections = list ? selectSectionsInList(list) : [];
          const returnValue = sections.length > 0 ? [sections[0]] : [];
          return returnValue;
        }
      );

      this.get("/projects/section_archived_tasks/:id", (schema, request) => {
        const lsArchivedTasksIds = localStorage.getItem("archived_tasks");
        const archivedTasksIds = lsArchivedTasksIds
          ? JSON.parse(lsArchivedTasksIds)
          : [];
        const tasks: TaskJoinedToUserAndScheduledArray = store.getState().consolidatedTasks
          .data;
        return tasks.filter(({ id }) => archivedTasksIds.includes(id));
      });

      this.put("/projects/unarchive_section_tasks/:id", (schema, request) => {
        localStorage.removeItem("archived_tasks");
        return [];
      });

      // this.get("/activities/activity/", (schema, request) => {
      //   return schema.db.activities;
      // });
      // this.post("/activities/activity/", (schema, request) => {
      //   const data = JSON.parse(request.requestBody);
      //   return schema.db.activities.insert(data);
      // });
      // this.put("/activities/activity/:id", (schema, request) => {
      //   const id = request.params.id;
      //   const data = JSON.parse(request.requestBody);
      //   return this.db.activities.update(id, data);
      // });

      // this.get("/activities/activity_change_log/", (schema, request) => {
      //   return schema.db.activityChangeLogs;
      // });
      // this.post("/activities/activity_change_log/", (schema, request) => {
      //   const data = JSON.parse(request.requestBody);
      //   return schema.db.activityChangeLogs.insert(data);
      // });
      // this.put("/activities/activity_change_log/:id", (schema, request) => {
      //   const id = request.params.id;
      //   const data = JSON.parse(request.requestBody);
      //   return schema.db.activityChangeLogs.update(id, data);
      // });

      // this.get("/activities/get_unread_activities/", (schema, request) => {
      //   const task_id = +request.queryParams.task_id;
      //   const userDetails = store.getState().userDetails;
      //   const activitiesInOtherUserTask: ActivityArray = schema.db.activities.where(
      //     (activity: ActivityObject) =>
      //       activity.user !== userDetails.id && activity.task === task_id
      //   );
      //   const activitiesWithReadLogIDs = schema
      //     .all("activityReadLogs")
      //     .models.map((model) => Number(model.attrs.activity));
      //   const unread_activities: ActivityArray = activitiesInOtherUserTask.filter(
      //     (activity) => !activitiesWithReadLogIDs.includes(+activity.id!)
      //   );
      //   return {
      //     unread_activities,
      //   };
      // });

      // this.get("/activities/check_read_status/", (schema, request) => {
      //   const activity_id = +request.queryParams.activity_id;
      //   const filtered = schema.db.activityReadLogs.where({
      //     activity: activity_id,
      //   });
      //   return {
      //     read_status: filtered.length === 1,
      //   };
      // });

      // this.get("/activities/activity_read_log/", (schema, request) => {
      //   const taskID = request.queryParams.taskID;
      //   const userID = request.queryParams.userID;
      //   if (taskID || userID) {
      //     return [{}, {}, {}, {}];
      //   }
      //   return schema.db.activityReadLogs;
      // });
      // this.post("/activities/activity_read_log/", (schema, request) => {
      //   const data = JSON.parse(request.requestBody);
      //   return schema.db.activityReadLogs.insert(data);
      // });
      // this.put("/activities/activity_read_log/:id", (schema, request) => {
      //   const id = request.params.id;
      //   const data = JSON.parse(request.requestBody);
      //   return this.db.activityReadLogs.update(id, data);
      // });

      // this.get("/activities/activity_type/", (schema, request) => {
      //   return schema.db.activityTypes;
      // });

      // this.get("/work/get_checkout_status/", (schema, request) => {
      //   const {
      //     work_shift_id,
      //     passed_yn,
      //     previous_work_shifts,
      //   } = request.queryParams;
      //   const booleanPassedYn = passed_yn === "true";
      //   const booleanPreviousWorkShifts = previous_work_shifts === "true";

      //   const filtered: CheckoutStatusArray = schema.db.checkoutStatus.where(
      //     (object: CheckoutStatusObject) => {
      //       const sameWorkShiftID = object.work_shift === Number(work_shift_id);
      //       const filteredPassedYn =
      //         passed_yn !== undefined
      //           ? object.passed_yn === booleanPassedYn
      //           : true;
      //       return sameWorkShiftID && filteredPassedYn;
      //     }
      //   );

      //   const sampleData: any = {
      //     id: 1,
      //     work_shift: Number(work_shift_id),
      //     checkout_check: 1,
      //     passed_yn: false,
      //     issue_json: {
      //       unconfirmed: 2,
      //       unread: 12,
      //       next_shift_unscheduled: true,
      //     },
      //   };

      //   const checkoutWorkShift = schema.db.workShifts.where(
      //     (workShiftObject: WorkShiftObject) =>
      //       Number(workShiftObject.id) === Number(work_shift_id)
      //   )[0];

      //   const previousWorkShifts = schema.db.workShifts.where(
      //     (workShiftObject: WorkShiftObject) => {
      //       return moment(workShiftObject.start_datetime)
      //         .startOf("day")
      //         .isBefore(
      //           moment(checkoutWorkShift.start_datetime).startOf("day")
      //         );
      //     }
      //   );

      //   const previousWorkShiftsCheckoutStatus = previousWorkShifts.map(
      //     (previousWorkShiftObject: WorkShiftObject, index: number) => {
      //       const { id } = previousWorkShiftObject;
      //       return {
      //         id: index,
      //         work_shift: id,
      //         checkout_check: 1,
      //         passed_yn: false,
      //         issue_json: {
      //           unconfirmed: 3,
      //           unread: 0,
      //           next_shift_unscheduled: false,
      //         },
      //       };
      //     }
      //   );

      //   if (booleanPreviousWorkShifts) {
      //     return previousWorkShiftsCheckoutStatus;
      //   }

      //   return sampleData;
      // });

      // this.get("/work/get_allocated_time/", (schema, request) => {
      //   const { shift_id } = request.queryParams;
      //   const sampleReturnData = {
      //     shift_id,
      //     allocated_time_per_project: [
      //       {
      //         project: 1,
      //         total_seconds: 3600,
      //         tasks: [
      //           {
      //             task: 1,
      //             total_seconds: 1800,
      //           },
      //           {
      //             task: 2,
      //             total_seconds: 1800,
      //           },
      //         ],
      //       },
      //     ],
      //   };
      //   return sampleReturnData;
      // });

      // this.get("/work/status_history/", (schema, request) => {
      //   const { start_date, end_date } = request.queryParams;
      //   return schema.db.statusHistory.where((item: StatusHistoryObject) => {
      //     const isOnOrAfterStartDate = start_date
      //       ? moment(item.start_datetime).isSameOrAfter(
      //           moment(start_date),
      //           "day"
      //         )
      //       : true;
      //     const isOnOrBeforeEndDate = end_date
      //       ? moment(item.end_datetime).isSameOrAfter(moment(end_date), "day")
      //       : true;
      //     return isOnOrAfterStartDate && isOnOrBeforeEndDate;
      //   });
      // });

      /*
      this.get("/unreadProjects/", () => {
        return currentUnreadProjects;
      });

      this.get("/unreadActivities/", (schema, request: any)  => {
        return currentUnreadActivities
          .filter(activity => activity.task === Number(request.queryParams.taskId));
      });

      this.post("/markAsRead/", (schema, request) => {
        const payload = JSON.parse(request.requestBody); 
        if (payload.activityId) {
          const taskId = unreadActivities.find(activity => activity.id === payload.activityId)?.task;
          currentUnreadActivities = currentUnreadActivities.filter(activity => activity.id !== payload.activityId);
          currentUnreadProjects = currentUnreadProjects.map(project => {
            const { lists } = project;
            return {
              ...project,
              lists: lists.map(list => {
                const { folders } = list;
                return {
                  ...list,
                  folders: folders.map(folder => {
                    const { tasks } = folder;
                    return {
                      ...folder,
                      tasks: tasks.map(task => ({
                        ...task,
                        activity_count: task.id === taskId ? task.activity_count - 1 : task.activity_count,
                      })).filter(task => task.activity_count > 0),
                    }
                  }).filter(folder => folder.tasks.length > 0),
                }
              }).filter(list => list.folders.length > 0)
            }
          }).filter(project => project.lists.length > 0);
        } else if (payload.taskId) {
          currentUnreadActivities = currentUnreadActivities.filter(activity => activity.task !== payload.taskId);
          currentUnreadProjects = currentUnreadProjects.map(project => {
            const { lists } = project;
            return {
              ...project,
              lists: lists.map(list => {
                const { folders } = list;
                return {
                  ...list,
                  folders: folders.map(folder => {
                    const { tasks } = folder;
                    return {
                      ...folder,
                      tasks: tasks.map(task => ({
                        ...task,
                        activity_count: task.id === payload.taskId ? 0 : task.activity_count,
                      })).filter(task => task.activity_count > 0),
                    }
                  }).filter(folder => folder.tasks.length > 0),
                }
              }).filter(list => list.folders.length > 0)
            }
          }).filter(project => project.lists.length > 0);
        } else if (payload.projectId) {
          currentUnreadProjects = currentUnreadProjects.filter(project => project.id !== payload.projectId);
        }
        return {
          unreadActivities: currentUnreadActivities,
          unreadProjects: currentUnreadProjects,
        };
      });
      */

      // this.get("/work/shedule_task/", (schema, request) => {
      //   const { end_datetime, start_datetime } = request.queryParams;
      //   const sampleReturnData = [
      //     {
      //       id: 0,
      //       join_task_to_user: 0,
      //       task_id: 0,
      //       user_id: 0,
      //       scheduled_datetime: "2019-08-24T14:15:22Z",
      //     },
      //   ];
      //   return {};
      // });

      this.passthrough();

      this.passthrough(`http://${process.env.REACT_APP_HOST}/**`);
    },
  });
};
