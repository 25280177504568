import { RootState } from "../store/configureStore";

export interface NotificationsState extends BaseReducerState {
  data: NotificationArray;
}
export const selectNotificationsState = (rootState: RootState) =>
  rootState.notifications;
export const notificationsReducerDefaultState: NotificationsState = {
  data: [],
  loading: false,
  error: "",
};

const notificationsReducer = (
  state = notificationsReducerDefaultState,
  action: { type: string; notifications: NotificationArray; error: string }
) => {
  switch (action.type) {
    case "REQUEST_FETCH_NOTIFICATIONS":
    case "REQUEST_MARK_ALL_NOTIFICATIONS_AS_READ":
    case "REQUEST_MARK_NOTIFICATION_AS_READ":
    case "REQUEST_MARK_MULTIPLE_NOTIFICATIONS_AS_READ":
      return {
        ...state,
        error: "",
        loading: true,
      };
    case "REQUEST_FETCH_NOTIFICATIONS_SUCCESS":
    case "REQUEST_MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS":
    case "REQUEST_MARK_NOTIFICATION_AS_READ_SUCCESS":
    case "REQUEST_MARK_MULTIPLE_NOTIFICATIONS_AS_READ_SUCCESS":
      return {
        data: action.notifications,
        error: "",
        loading: false,
      };
    case "REQUEST_FETCH_NOTIFICATIONS_FAILED":
    case "REQUEST_MARK_ALL_NOTIFICATIONS_AS_READ_FAILED":
    case "REQUEST_MARK_NOTIFICATION_AS_READ_FAILED":
    case "REQUEST_MARK_MULTIPLE_NOTIFICATIONS_AS_READ_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export { notificationsReducer as default };
