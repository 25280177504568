import React from "react";

import { ActivityTabContentContext } from "../ActivityTabContent/ActivityTabContent";

import ActivityCardItem from "../../Activities/ActivityCardItem/ActivityCardItem";

import { groupActivitiesByDate } from "../../../utils/activities.util";

/**
 * Default display
 * Displays list of confirmed activites grouped by submitted_datetime
 */
const ConfirmedActivities: React.FC = () => {
  const {
    confirmedActivities: {
      data: confirmedActivitiesData,
      loading: confirmedActivitiesLoading,
      creating: confirmedActivitiesCreating,
    },
  } = React.useContext(ActivityTabContentContext);

  const [
    groupedConfirmedActivities,
    setGroupedConfirmedActivities,
  ] = React.useState<ReturnType<typeof groupActivitiesByDate> | undefined>(
    undefined
  );

  const hasActivities =
    groupedConfirmedActivities &&
    Object.keys(groupedConfirmedActivities).length > 0;

  React.useEffect(() => {
    if (!confirmedActivitiesLoading && confirmedActivitiesData.length > 0) {
      setGroupedConfirmedActivities(
        groupActivitiesByDate(confirmedActivitiesData)
      );
    } else {
      setGroupedConfirmedActivities(undefined);
    }
  }, [confirmedActivitiesData, confirmedActivitiesLoading]);

  return (
    <div className="ConfirmedActivities">
      {hasActivities &&
        Object.keys(groupedConfirmedActivities!).map((key) => {
          return (
            <div className="ConfirmedActivities__Item" key={key}>
              <div className="ConfirmedActivities__Item__Header">
                <div className="ConfirmedActivities__Item__Header__Title">
                  <span>{key}</span>
                </div>
              </div>
              <div className="ConfirmedActivities__Item__Body">
                {groupedConfirmedActivities![key].map(
                  (confirmedActivity, index) => {
                    return (
                      <ActivityCardItem
                        activityObject={confirmedActivity}
                        key={index}
                      />
                    );
                  }
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default ConfirmedActivities;
