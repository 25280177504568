import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Divider, Empty } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import groupBy from "lodash/groupBy";
import moment from "moment";
import cn from "classnames";

import Activity from "../Activity/Activity.component";

import {
  startPopulateUnreadActivities,
  startMarkAsRead,
} from "../../../actions/unread.action";
import { selectUnreadState } from "../../../reducers/unread.reducer";
import { dateTimeToWeekMonth } from "../../../utils/calendar.util";

/**
 * TaskPanel represents all unread activities within one task.
 * @param taskId the id for this task, will be use to fetch all unread activities.
 */
const TaskPanel = ({ taskId }: TaskPanelProps) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch() as any;

  const { data: activities, loading: activitiesLoading } = useSelector(
    selectUnreadState
  ).activities;
  const activitiesForTask = activities.filter(
    (activity: UnreadActivity) => activity.task === taskId
  );

  const isLoading = loading || activitiesLoading;

  useEffect(() => {
    setLoading(true);
    dispatch(startPopulateUnreadActivities(taskId)).then(() =>
      setLoading(false)
    );
  }, [taskId, dispatch]);

  const handleMarkAsRead = (activityId: string) => {
    dispatch(startMarkAsRead({ activityId }));
  };

  // group unread activities by date
  const activityGroups = groupBy(
    activitiesForTask,
    (activity: UnreadActivity) =>
      moment(activity.created_datetime).format("YYYY-MM-DD")
  );
  const activitySections = Object.keys(activityGroups)
    .sort(
      (date1, date2) => new Date(date2).getTime() - new Date(date1).getTime()
    )
    .map((date) => {
      const activityList = activityGroups[date]
        .sort(
          (a, b) =>
            new Date(b.created_datetime).getTime() -
            new Date(a.created_datetime).getTime()
        )
        .map((activity) => (
          <Activity
            key={activity.id}
            activity={activity}
            onMarkAdRead={() => handleMarkAsRead(activity.id)}
          />
        ));
      return (
        <div key={date}>
          <Divider className="UnreadTaskPanel__divider">
            <div className="UnreadTaskPanel__date">
              {dateTimeToWeekMonth(date)}
            </div>
          </Divider>
          {activityList}
        </div>
      );
    });
  const isEmpty = activitySections.length <= 0;

  return (
    <section
      className={cn("UnreadTaskPanel", {
        "UnreadTaskPanel--Empty": isEmpty,
      })}
    >
      {!isLoading && !isEmpty && activitySections}
      {!isLoading && isEmpty && <span>No unread activities</span>}
      {isLoading && <LoadingOutlined className="UnreadTaskPanel__loading" />}
    </section>
  );
};

export default TaskPanel;
