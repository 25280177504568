import React from "react";
import moment from "moment";
import cn from "classnames";
import { sum } from "lodash";

import {
  CaretDownIcon,
  LoadingIcon,
} from "../../CustomIcons/CustomIcons.component";
import ManageTaskTime from "../../WorkTime/ManageTaskTime/ManageTaskTime";
import MarkActivitiesAsRead from "../../Activities/MarkActivitiesAsRead/MarkActivitiesAsRead";
import RemoveTaskFromWorkDay from "../RemoveTaskFromWorkDay/RemoveTaskFromWorkDay";
import UnreadActivitiesCount from "../../Activities/UnreadActivitiesCount/UnreadActivitiesCount";
import { SummaryOfTheDayContext } from "../../Checkout/SummaryOfTheDay/SummaryOfTheDay";

import {
  getAllocatedTimeService,
  GetAllocatedTimeServiceResponse,
} from "../../../services/task-time.service";
import { getTotalDuration } from "../../../utils/time-conversion.util";

import ws from "../../../sockets/websockets";

// Place inside own component instead of using ternary operator to keep main component clean
const TaskTotalTimeWidget = ({
  loadingState,
  taskTotalTaskTime,
  type,
}: {
  loadingState: LoadingState;
  type: ActivitiesSummaryType;
  taskTotalTaskTime: number;
}) => {
  if ((type === "history" || type === "team") && loadingState === "true") {
    return <LoadingIcon />;
  } else if (
    (type === "history" || type === "team") &&
    loadingState !== "false" &&
    loadingState !== "true"
  ) {
    return (
      <div className="TaskCollapsePanelHeader__Right__TotalTaskTimeSeconds">
        {getTotalDuration(moment.duration(taskTotalTaskTime, "seconds"))}
      </div>
    );
  } else {
    return <></>;
  }
};

const ManageTaskTimeWidget = ({
  disabled,
  loadingState,
  taskId,
  taskTotalTaskTime,
  type,
}: {
  disabled: boolean;
  loadingState: LoadingState;
  taskId: TaskObject["id"];
  taskTotalTaskTime: number;
  type: ActivitiesSummaryType;
}) => {
  if (type === "checkout" && loadingState === "true") {
    return <LoadingIcon />;
  } else if (
    type === "checkout" &&
    loadingState !== "false" &&
    loadingState !== "true"
  ) {
    return (
      <ManageTaskTime
        disabled={disabled}
        hovered={true}
        taskId={taskId}
        taskTimeProp={taskTotalTaskTime}
        isCheckout={true}
      />
    );
  } else {
    return <></>;
  }
};

/**
 * Default Display
 * TaskCollapsePanelHeader displays the [list, section, task] titles and CaretDownIcon
 * If time is recorded to task ManageTaskTime component is displayed
 *
 * Functionality
 * Clicking the CaretDownIcon will toggle the TaskCollapsePanelContent's collapsible panel
 */
export interface TaskCollapsePanelHeaderProps {
  date: string[];
  listTitle: string;
  onToggle: Function;
  open: boolean;
  sectionTitle: string;
  task: TaskJoinedToUserAndScheduledObject;
  taskActivitySummaryArray: ActivitySummaryArray;
  type: ActivitiesSummaryType;
  users: UserArray;
}
const TaskCollapsePanelHeader: React.FC<TaskCollapsePanelHeaderProps> = ({
  date,
  listTitle,
  onToggle,
  open = false,
  sectionTitle,
  task,
  taskActivitySummaryArray,
  type,
  users,
}) => {
  const { editingAllowed } = React.useContext(SummaryOfTheDayContext);

  const [hover, setHover] = React.useState(false);
  const [taskTotalTaskTime, setTaskTotalTaskTime] = React.useState(0);
  const [loadingState, setLoadingState] = React.useState<LoadingState>("false");

  const { id: taskId, title: taskTitle } = task;
  const taskUnreadActivitiesCount = taskActivitySummaryArray.filter(
    ({ read }) => !read
  ).length;

  const displayUnread = type === "team";
  const stringifiedDate = JSON.stringify(date);
  const stringifiedUsers = JSON.stringify(users);

  React.useEffect(() => {
    const getTaskTotalTaskTime = async () => {
      const parsedDate: TaskCollapsePanelHeaderProps["date"] = JSON.parse(
        stringifiedDate
      );
      const parsedUsers: TaskCollapsePanelHeaderProps["users"] = JSON.parse(
        stringifiedUsers
      );
      try {
        setLoadingState("true");
        const allocatedTimeArray = await Promise.all(
          parsedUsers.map(async ({ id: user_id }) => {
            try {
              const from_date = parsedDate[0];
              const to_date = parsedDate[parsedDate.length - 1];
              const response = await getAllocatedTimeService({
                user_id,
                from_date,
                to_date,
              });
              if (response.status === 200) {
                const responseData: GetAllocatedTimeServiceResponse =
                  response.data;
                if (responseData.allocated_time.length > 0) {
                  return sum(
                    responseData.allocated_time.flatMap(({ tasks }) =>
                      tasks
                        .filter(({ task }) => task === taskId)
                        .map(({ total_seconds }) => total_seconds)
                    )
                  );
                }
              }
              return 0;
            } catch (e) {
              return 0;
            }
          })
        );
        setLoadingState("success");
        setTaskTotalTaskTime(sum(allocatedTimeArray));
      } catch (e) {
        setLoadingState("error");
      }
    };

    const wsOnMessage = async (event: MessageEvent) => {
      try {
        const messageEventData = JSON.parse(event.data);
        const { event: eventType } = messageEventData;
        switch (eventType) {
          case "new_task_time":
          case "removed_task_from_workday":
          case "removed_project_from_workday":
            if (type !== "next-workday") getTaskTotalTaskTime(); // re-run the checkout checks when the work time gets update for unallocated work time
            break;
          default:
        }
      } catch (e) {}
    };

    if (type !== "next-workday") getTaskTotalTaskTime();
    ws.addEventListener("message", wsOnMessage);

    return () => {
      ws.removeEventListener("message", wsOnMessage);
    };
  }, [stringifiedDate, stringifiedUsers, taskId, type]);

  return (
    <div
      className="TaskCollapsePanelHeader"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className="TaskCollapsePanelHeader__Left">
        <div className="TaskCollapsePanelHeader__Left__Title">
          <span className="TaskCollapsePanelHeader__Left__Title__Text">
            {taskTitle}
          </span>
          {type !== "next-workday" && (
            <CaretDownIcon
              className={cn("TaskCollapsePanelHeader__Left__Title__Icon", {
                "TaskCollapsePanelHeader__Left__Title__Icon--Open": open,
              })}
              onClick={onToggle}
            />
          )}
        </div>
        <div className="TaskCollapsePanelHeader__Left__Tree">
          <span className="TaskCollapsePanelHeader__Left__Tree__Node">
            {listTitle}
          </span>
          <span className="TaskCollapsePanelHeader__Left__Tree__Node">
            {sectionTitle}
          </span>
        </div>
      </div>

      <div className="TaskCollapsePanelHeader__Right">
        {displayUnread && (
          <>
            <UnreadActivitiesCount count={taskUnreadActivitiesCount} />
            {taskUnreadActivitiesCount > 0 && (
              <MarkActivitiesAsRead
                activityIdArray={taskActivitySummaryArray.map(({ id }) => id)}
              />
            )}
          </>
        )}
        {type === "checkout" && hover && <RemoveTaskFromWorkDay task={task} />}
        <ManageTaskTimeWidget
          disabled={!editingAllowed}
          loadingState={loadingState}
          taskId={taskId}
          taskTotalTaskTime={taskTotalTaskTime}
          type={type}
        />
        <TaskTotalTimeWidget
          loadingState={loadingState}
          type={type}
          taskTotalTaskTime={taskTotalTaskTime}
        />
      </div>
    </div>
  );
};

export default TaskCollapsePanelHeader;
