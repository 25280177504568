import React from "react";
import { Checkbox, Input, Modal } from "antd";
import cn from "classnames";

import Button from "../../Button/Button";
import {
  CloseIcon,
  DeleteIcon,
  ArchiveIcon,
  UnarchiveIcon,
  LoadingIcon,
} from "../../CustomIcons/CustomIcons.component";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

export interface DeleteProjectTriggerProps {
  project: ProjectObject;
}

export const matchingText = "DELETE PROJECT FOREVER";

const DeleteProjectTrigger: React.FC<DeleteProjectTriggerProps> = ({
  project,
}) => {
  const [showModal, setShowModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [text, setText] = React.useState("");
  const [checked, setChecked] = React.useState(false);

  const { archived_yn: archived } = project;
  const validated = text === matchingText && checked;

  const handleAction = async () => {
    setLoading(true);
    await new Promise((resolve) => setTimeout(() => resolve(true), 2000));
    /**
     * TODO 13.15.6: add call to delete project
     */
    setLoading(false);
    setShowModal(false);
  };

  return (
    <div className="DeleteProjectTrigger">
      <div
        className={cn("DeleteProjectTrigger__Button", {
          "DeleteProjectTrigger__Button--Disabled": !archived,
        })}
        onClick={() => setShowModal(true)}
      >
        <DeleteIcon />
        <span>Delete</span>
      </div>

      <Modal
        className="ProjectArchiveToggler__Modal"
        centered={true}
        visible={showModal}
        onOk={() => {}}
        onCancel={() => setShowModal(false)}
        closeIcon={<CloseIcon />}
        footer={null}
      >
        <div className="ProjectArchiveToggler__Modal__Container">
          <div className="ProjectArchiveToggler__Modal__Container__Message">
            <span className="ProjectArchiveToggler__Modal__Container__Message__Title">
              Delete project
            </span>
            <div className="ProjectArchiveToggler__Modal__Container__Message__Text">
              <span>
                Deleting project cannot be undone. All tasks will be deleted
                forever. Project information in existing checkouts will remain
                available including today's checkout.
              </span>
              <span>
                Type 'DELETE PROJECT FOREVER' into the below text box.
              </span>
              <Input
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setText(e.target.value)
                }
              />
              <Checkbox
                checked={checked}
                onChange={(e: CheckboxChangeEvent) =>
                  setChecked(e.target.checked)
                }
              >
                I understand that all of the project's data will be deleted.
              </Checkbox>
            </div>
          </div>
          <div className="ProjectArchiveToggler__Modal__Container__Action">
            <Button onClick={() => setShowModal(false)}>Cancel</Button>
            <Button type="primary" disabled={!validated} onClick={handleAction}>
              {loading ? <LoadingIcon /> : "Delete forever"}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteProjectTrigger;
