import { AxiosResponse } from "axios";

import axios from "../apis/django.instance";

/**
 * Fetch unread projects object including all of lists / sections / tasks.
 * the activities object will be fetch from a separate api called 'getUnreadActivitiesService'.
 * @returns unread projects
 */
export const getUnreadProjectsService = () => {
  return axios.get("api/activities/get_unread_activities_in_projects/");
};

/**
 * Fetch unread activities object belong to certained task.
 * @param taskId the task id of these activities
 * @param limit the pagination, default is 100
 * @param offset the pagination offset, default is 0
 * @returns unread activities
 */
export const getUnreadActivitiesService: GetUnreadActivitiesService = (
  taskId,
  limit = 100,
  offset = 0
) => {
  return axios.get(
    `api/activities/unread_activities/?task_id=${taskId}&limit=${limit}&offset=${offset}`
  );
};

export interface GetUnreadActivitiesCountServiceData {
  project_id?: ProjectObject["id"];
  task_list_id?: ListObject["id"];
  task_id?: TaskObject["id"];
  users?: string;
  from_date?: string;
  to_date?: string;
}
export type GetUnreadActivitiesCountService = (
  data: GetUnreadActivitiesCountServiceData
) => Promise<AxiosResponse<any>>;
export const getUnreadActivitiesCountService: GetUnreadActivitiesCountService = async ({
  project_id,
  task_list_id,
  task_id,
  users,
  from_date,
  to_date,
}) => {
  const params = {
    project_id,
    task_list_id,
    task_id,
    users,
    from_date,
    to_date,
  };
  /**
   * If users is supplied in query parameters, an array containing unread activity count per user is returned.
   * If users is not supplied in query parameters, an object containing the current user's unread activity count is returned.
   */
  return axios.get(`/api/activities/unread_activity_count/`, { params });
};

/**
 * Mark the sepecified object as read.
 * The mark payload can be a activity, or a task, or a project.
 * If the payload is a activity, this activity will be marked as read,
 * If the payload is a task, all activities belong to this task will be marked as read,
 * If the payload is a project, all activities belong to this project will be marked as read.
 * @param payload the object to be marked
 * @returns unread projects and unread activities object
 */
export const markAsReadService = (payload: MarkAsReadParams) => {
  if (payload.projectId) {
    return axios.post("api/activities/mark_all_unread_as_read/", {
      project_id: payload.projectId,
    });
  }
  if (payload.taskId) {
    return axios.post("api/activities/mark_all_unread_as_read/", {
      task_id: payload.taskId,
    });
  }
  return axios.post("api/activities/activity_read_log/", {
    activity: payload.activityId,
  });
};

export const markActivitiesAsReadService = (
  activity_ids: ActivityObject["id"][]
) => {
  return axios.post(
    `api/activities/activity_read_log/mark_activities_read_by_ids/`,
    {
      activity_ids,
    }
  );
};
