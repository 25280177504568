const activityTypes: ActivityTypeArray = [
  {
    id: "1",
    parent_workspace: "1",
    title: "Daily Report",
    text_template_json: [
      {
        section_title: "Priorities",
        display_title_in_edit_yn: true,
        display_title_in_read_yn: true,
        display_section_in_read_if_empty_yn: false,
        input_required_yn: false,
        section_text: "",
      },
      {
        section_title: "Report",
        display_title_in_edit_yn: true,
        display_title_in_read_yn: true,
        display_section_in_read_if_empty_yn: true,
        input_required_yn: true,
        section_text: "",
      },
      {
        section_title: "Next steps",
        display_title_in_edit_yn: true,
        display_title_in_read_yn: true,
        display_section_in_read_if_empty_yn: false,
        input_required_yn: false,
        section_text: "",
      },
    ],
    workspace_default_yn: false,
  },
  {
    id: "2",
    parent_workspace: "1",
    title: "Comment",
    text_template_json: [
      {
        section_title: "Comment",
        display_title_in_edit_yn: true,
        display_title_in_read_yn: true,
        display_section_in_read_if_empty_yn: true,
        input_required_yn: true,
        section_text: "",
      },
    ],
    workspace_default_yn: false,
  },
];

export { activityTypes as default };
