import React from "react";

import CustomAvatar from "../../../CustomAvatar/CustomAvatar";
import { getInitials } from "../../../../utils/string.util";

// TaskAssigneesPopoverContent is displayed when TaskAssignees is hovered
const TaskAssigneesPopoverContent: React.FC<TaskAssigneesPopoverContentProps> = ({
  contributors,
  ownerId,
}) => {
  const owner = contributors.filter((c) => c.id === ownerId);
  const members = contributors.filter((c) => c.id !== ownerId);
  const contributorsJxs = [...owner, ...members].map((c) => (
    <div key={c.id} className="TaskAssigneesPopoverContent__Contributor">
      <CustomAvatar
        className="TaskAssigneesPopoverContent__Avatar"
        fallbackText={getInitials(c.display_name)}
        size={20}
        src={c.avatar}
      />
      <div>{c.display_name}</div>
      {c.id === ownerId && (
        <div className="TaskAssigneesPopoverContent__Tag">Owner</div>
      )}
    </div>
  ));

  return <div className="TaskAssigneesPopoverContent">{contributorsJxs}</div>;
};

export default TaskAssigneesPopoverContent;
