import React from "react";
import {
  EditorState,
  RichUtils,
  DraftInlineStyleType,
  DraftBlockType,
  ContentState,
} from "draft-js";

import {
  TextIcon,
  BoldIcon,
  ItalicIcon,
  UnderlineIcon,
  StrikethroughIcon,
  LinkIcon,
  BlockQuoteIcon,
  ImageIcon,
  AttachFileIcon,
} from "../../CustomIcons/CustomIcons.component";

/**
 * WorkShiftNotesToolbar contains all the formatting actions for WorkShiftNotes
 * Currently stopped implementation as per Greg
 */
const WorkShiftNotesToolbar: React.FC<WorkShiftNotesToolbarProps> = (props: {
  editorState: EditorState;
  setEditorState: React.Dispatch<React.SetStateAction<EditorState>>;
  contentState: ContentState;
}) => {
  const { editorState, setEditorState, contentState } = props;
  const onToggleInlineStyle = (inlineStyleType: DraftInlineStyleType) => (
    e: React.MouseEvent
  ) => {
    setEditorState((currentValue) =>
      RichUtils.toggleInlineStyle(currentValue, inlineStyleType)
    );
  };

  const onToggleBlockType = (blockType: DraftBlockType) => (
    e: React.MouseEvent
  ) => {
    setEditorState((currentValue) =>
      RichUtils.toggleBlockType(currentValue, blockType)
    );
  };

  const promptForLink = (e: React.MouseEvent) => {
    e.preventDefault();
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const startKey = selection.getStartKey();
      const startOffset = selection.getStartOffset();
      const blockWithLinkAtBeginning = contentState?.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning?.getEntityAt(startOffset);

      let url: string = "";

      if (linkKey) {
        const linkInstance = contentState!.getEntity(linkKey);
        url = linkInstance.getData().url;
      }

      url = String(prompt("Enter URL", url));

      if (url !== "" && url !== null && url !== undefined) {
        confirmLink(url);
      } else {
        removeLink();
      }
    }
  };

  const confirmLink = (url: string) => {
    if (url && contentState) {
      const contentStateWithEntity = contentState!.createEntity(
        "LINK",
        "MUTABLE",
        { url }
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const newEditorState = EditorState.set(editorState, {
        currentContent: contentStateWithEntity,
      });
      setEditorState(
        RichUtils.toggleLink(
          newEditorState,
          newEditorState.getSelection(),
          entityKey
        )
      );
    }
  };

  const removeLink = () => {
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      setEditorState((currentValue) =>
        RichUtils.toggleLink(currentValue, selection, null)
      );
    }
  };

  return (
    <div className="WorkShiftNotesToolbar">
      <BoldIcon
        className="WorkShiftNotesToolbar__Item"
        onMouseDown={onToggleInlineStyle("BOLD")}
      />
      <ItalicIcon
        className="WorkShiftNotesToolbar__Item"
        onMouseDown={onToggleInlineStyle("ITALIC")}
      />
      <UnderlineIcon
        className="WorkShiftNotesToolbar__Item"
        onMouseDown={onToggleInlineStyle("UNDERLINE")}
      />
      <StrikethroughIcon
        className="WorkShiftNotesToolbar__Item"
        onMouseDown={onToggleInlineStyle("STRIKETHROUGH")}
      />
      <LinkIcon
        className="WorkShiftNotesToolbar__Item"
        onMouseDown={promptForLink}
      />
      <BlockQuoteIcon
        className="WorkShiftNotesToolbar__Item"
        onMouseDown={onToggleBlockType("blockquote")}
      />
    </div>
  );
};

export default WorkShiftNotesToolbar;
