import moment from "moment";
import axios from "../apis/django.instance";
import { workDayDateFormat } from "../constants/constants";
import { store } from "../store/configureStore";

const getSessionCount = async (): Promise<number> => {
  const {
    currentWorkDay: {
      data: { work_date: currentWorkDayDate },
    },
  } = store.getState();
  const currentDate = moment(currentWorkDayDate, workDayDateFormat).format(
    "YYYYMMDD"
  );
  try {
    const response = await axios.post("/api/work/calendar_schedule/", {
      start_date: currentDate,
      end_date: currentDate,
    });
    if (response.status === 201) {
      return response.data.length;
    }
    return 0;
  } catch (e) {
    return 0;
  }
};

const getNextShiftDate = async () => {
  const { data: nextCalendarSchedule } = store.getState().nextCalendarSchedule;
  const {
    currentWorkDay: {
      data: { work_date: currentWorkDayDate },
    },
  } = store.getState();
  const currentDate = moment(currentWorkDayDate, workDayDateFormat).format(
    "YYYYMMDD"
  );
  const now = {
    start: currentDate,
    end: currentDate,
  };

  if (!!nextCalendarSchedule) {
    return {
      start: moment(nextCalendarSchedule.start_datetime).format("YYYYMMDD"),
      end: moment(nextCalendarSchedule.end_datetime).format("YYYYMMDD"),
    };
  } else {
    return now;
  }
};

const getNextShift = async ({ start, end }: { start: string; end: string }) => {
  let nextShift = {
    lastUpdated: "",
    dateTime: "",
    taskTime: 0,
    sessions: 0,
  };
  try {
    const response = await axios.post("/api/work/calendar_schedule/", {
      start_date: start,
      end_date: end,
    });
    const { status, data } = response;
    if (status === 201) {
      return data.reduce((acc: any, cur: any) => {
        return {
          lastUpdated: cur.last_updated_at,
          dateTime: cur.start_datetime,
          taskTime: cur.work_time_seconds + acc.taskTime,
          sessions: acc.sessions + 1,
        };
      }, nextShift);
    }
    return nextShift;
  } catch (e) {
    return nextShift;
  }
};

export const getOfficeService: GetDeskService = async () => {
  const sessionCount = await getSessionCount();
  const nextShiftDate = await getNextShiftDate();
  const nextShift = await getNextShift(nextShiftDate);
  return {
    todaySessions: sessionCount,
    nextWorkdaySessions: nextShift.sessions,
    nextWorkdayWorkTime: nextShift.taskTime,
    nextWorkdayDate: nextShift.dateTime,
    lastUpdated: nextShift.lastUpdated,
  };
};
