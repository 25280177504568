import React from "react";
import cn from "classnames";

import Button from "../../../Button/Button";
import {
  LoadingIcon,
  TeamIcon,
} from "../../../CustomIcons/CustomIcons.component";
import { ActivityTabContentContext } from "../ActivityTabContent";

import { ACTIVITY_FILTERS } from "../../../../constants/activity.constant";

/**
 * Default display
 * Displays sub tabs [New, Team] for navigation
 * Updates filter using setFilter prop depending on sub tab clicked
 * Updates filter using setFilter prop to "Default" when clicking active sub tab
 */
const ActivityTabHeader: React.FC = () => {
  const {
    teamDrafts,
    unreadActivities,
    submittedUnconfirmedActivities: {
      data: submittedUnconfirmedActivitiesData,
      loading: submittedUnconfirmedActivitiesLoading,
    },
    filter,
    setFilter,
  } = React.useContext(ActivityTabContentContext);

  const handleClickFilter = (param: string) => (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setFilter((currentValue) =>
      currentValue === param ? ACTIVITY_FILTERS.DEFAULT : param
    );
  };

  return (
    <div className="ActivityTabHeader">
      <div className="ActivityTabHeader__Left">
        <Button
          className={cn(
            "ActivityTabHeader__Subtab ActivityTabHeader__Subtab--New",
            {
              "ActivityTabHeader__Subtab--Active":
                filter === ACTIVITY_FILTERS.NEW,
            }
          )}
          onClick={handleClickFilter(ACTIVITY_FILTERS.NEW)}
        >
          <span>{ACTIVITY_FILTERS.NEW}</span>
          <span className="ActivityTabHeader__Subtab__Count">
            {unreadActivities === undefined ? (
              <LoadingIcon />
            ) : (
              unreadActivities.length
            )}
          </span>
        </Button>
        <Button
          className={cn(
            "ActivityTabHeader__Subtab ActivityTabHeader__Subtab--Unconfirmed",
            {
              "ActivityTabHeader__Subtab--Active":
                filter === ACTIVITY_FILTERS.UNCONFIRMED,
            }
          )}
          onClick={handleClickFilter(ACTIVITY_FILTERS.UNCONFIRMED)}
        >
          <span>{ACTIVITY_FILTERS.UNCONFIRMED}</span>
          <span className="ActivityTabHeader__Subtab__Count">
            {submittedUnconfirmedActivitiesLoading ? (
              <LoadingIcon />
            ) : (
              submittedUnconfirmedActivitiesData.length
            )}
          </span>
        </Button>
      </div>
      <div className="ActivityTabHeader__Right">
        <Button
          className={cn(
            "ActivityTabHeader__Subtab ActivityTabHeader__Subtab--Team",
            {
              "ActivityTabHeader__Subtab--Active":
                filter === ACTIVITY_FILTERS.TEAM,
            }
          )}
          onClick={handleClickFilter(ACTIVITY_FILTERS.TEAM)}
        >
          <TeamIcon />
          <span className="ActivityTabHeader__Subtab__Count">
            {teamDrafts === undefined ? <LoadingIcon /> : teamDrafts.length}
          </span>
        </Button>
      </div>
    </div>
  );
};

export default ActivityTabHeader;
