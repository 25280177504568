import React from "react";
import { Table } from "antd";
import moment from "moment";

import { viewArray } from "../../../WorkTime/WorkTimeRecordTable/WorkTimeRecordTable";

import { getTotalDuration } from "../../../../utils/time-conversion.util";

const WorkTimeRecordTableSummary: React.FC<WorkTimeRecordTableSummaryProps> = ({
  tableData,
  view,
}) => {
  const {
    total_allocated_time_in_seconds,
    total_office_hours_in_seconds,
    total_scheduled_in_seconds,
    total_tracked_in_seconds,
    total_tracked_level_percentage,
    total_work_hours_in_seconds,
  } = tableData;

  const totalManuallyTrackedSeconds = tableData.records
    .flatMap(({ shifts }) => {
      return shifts
        ? shifts.map(({ tracked }) => tracked.total_seconds_manually)
        : 0;
    })
    .reduce((prev, current) => prev + current, 0);

  return (
    <Table.Summary>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0}></Table.Summary.Cell>
        <Table.Summary.Cell index={1}>
          <span>
            {getTotalDuration(
              moment.duration(total_work_hours_in_seconds, "seconds")
            )}
          </span>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2}></Table.Summary.Cell>
        <Table.Summary.Cell index={3}>
          <span>
            {getTotalDuration(
              moment.duration(total_office_hours_in_seconds, "seconds")
            )}
          </span>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={4}></Table.Summary.Cell>
        <Table.Summary.Cell index={5}>
          <span>
            {getTotalDuration(
              moment.duration(total_allocated_time_in_seconds, "seconds")
            )}
          </span>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={6}>
          <span>
            {getTotalDuration(
              moment.duration(total_tracked_in_seconds, "seconds")
            )}
          </span>
        </Table.Summary.Cell>
        {view === viewArray[1] && (
          <Table.Summary.Cell index={7}>
            {getTotalDuration(
              moment.duration(totalManuallyTrackedSeconds, "seconds")
            )}
          </Table.Summary.Cell>
        )}
        <Table.Summary.Cell index={8}>
          {`${(total_tracked_level_percentage * 100).toFixed(2)}%`}
        </Table.Summary.Cell>
        {view === viewArray[1] && (
          <Table.Summary.Cell index={9}></Table.Summary.Cell>
        )}
        <Table.Summary.Cell index={10}>
          <span>
            {getTotalDuration(
              moment.duration(total_scheduled_in_seconds, "seconds")
            )}
          </span>
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </Table.Summary>
  );
};

export default WorkTimeRecordTableSummary;
