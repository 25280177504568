/**
 * Filters priorities passed by title passed
 * Returns single priority object (first record in the filtered priorities)...
 * ...if filtered priorities length is greater than 0
 * Throws a warning in the console if filtered priorities length is greater than 1
 * Returns undefined if filtered priorities length is 0
 */

type getPriorityByTitleUtil = (
  priorities: PriorityArray,
  title: PriorityObject["title"]
) => PriorityObject | undefined;

export const getPriorityByTitle: getPriorityByTitleUtil = (
  priorities,
  title
) => {
  const priorityByTitle = priorities.find(
    (activity) => activity.title === title
  );
  return priorityByTitle;
};
