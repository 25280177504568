import React from "react";

import AvatarGroup from "../../AvatarGroup/AvatarGroup.component";

import { getBulkTaskListMembershipService } from "../../../services/users.service";
import { getInitials } from "../../../utils/string.util";

const ListMembers: React.FC<ListMembersProps> = ({ list }) => {
  const [listMembers, setListMembers] = React.useState<ListUser[]>([]);

  const { id } = list;
  const hasMember = listMembers.length > 0;

  React.useEffect(() => {
    const fetchData = async () => {
      const users: ListUser[] = await getBulkTaskListMembershipService([id]);
      setListMembers(users);
    };
    fetchData();
  }, [id]);

  return (
    <>
      {hasMember && (
        <div className="ListMembers">
          <AvatarGroup
            avatars={listMembers.map((c) => c.avatar)}
            avatarsFallBackText={listMembers.map((c) =>
              getInitials(c.displayName)
            )}
            maxCount={3}
            size={20}
          />
        </div>
      )}
    </>
  );
};

export default ListMembers;
