import React from "react";
import { useSelector } from "react-redux";
import { Collapse, Tooltip } from "antd";

import useAsyncUserActivitySummaryArray from "../../../hooks/useAsyncUserActivitySummaryArray.hook";
import useAsyncTasksAddedToWorkDay from "../../../hooks/useAsyncTasksAddedToWorkDay.hook";

import {
  ExclamationCircleFilledIcon,
  LoadingIcon,
} from "../../CustomIcons/CustomIcons.component";
import SummaryOfTheDayHeader from "../SummaryOfTheDayHeader/SummaryOfTheDayHeader";
import ProjectCollapsePanel from "../../Projects/ProjectCollapsePanel/ProjectCollapsePanel";
import { CheckoutPanelContext } from "../CheckoutPanel/CheckoutPanel";

import { selectProjectsState } from "../../../reducers/projects.reducer";
import { getTasksListService } from "../../../services/tasks.service";
import { selectProjectOfTask } from "../../../selectors/project.selector";
import { isEqual } from "lodash";

const { Panel } = Collapse;
const SUMMARY_OF_THE_DAY_PANEL_KEY = "summary-of-the-day"; // Key of the active panel
const defaultUsersProp: UserArray = [];

const mapConsolidatedTasksToProjectIds = (
  tasks: TaskJoinedToUserAndScheduledArray
) => {
  return tasks.map(({ task_list }) => {
    const { parent_project = "" } = task_list || {};
    return parent_project;
  });
};

const mapProjectsAndTasks = (
  projects: ProjectArray,
  tasks: TaskJoinedToUserAndScheduledArray
) => {
  return projects
    .map((project) => {
      const { id: projectId } = project;

      return {
        project,
        tasks: tasks
          .filter(({ task_list }) => {
            const { parent_project: parentProject } = task_list || {};
            return parentProject === projectId;
          })
          .map(({ id }) => id),
      };
    })
    .filter(({ tasks }) => tasks.length > 0);
};

export const SummaryOfTheDayContext = React.createContext<SummaryOfTheDayContext>(
  {} as SummaryOfTheDayContext
);

/**
 * Default Display
 * SummaryOfTheDay displays all of the projects the current user is authorized for under a collapsible panel
 * Each project will also be a collapsible panel containing all the task under that project's lists and under those lists' folders
 * Each task will also be a collapsible panel containing all the current user's activities for the current date selected
 */
const SummaryOfTheDay: React.FC<SummaryOfTheDayProps> = ({
  collapseState = "super-expand",
  date,
  editingAllowed = true,
  tasksInNextWorkDay = [],
  type,
  users = defaultUsersProp,
}) => {
  const { checkoutWorkDay } = React.useContext(CheckoutPanelContext);

  const { data: projects, loading: projectsLoading } = useSelector(
    selectProjectsState
  );

  // collapse has 4 string literal types: "super-expand" | "expand" | "collapse" | "normal"
  // collapse state will have a value of "collapse" if collapse icon is clicked in SummaryOfTheDayHeader
  // collapse state will have a value of "expand" if expand icon is clicked in SummaryOfTheDayHeader
  // collapse state will have a value of "expand" if superexpand icon is clicked in SummaryOfTheDayHeader
  // collapse state will have a value of "normal" if a user toggles an individual project/task's collapse
  const [collapse, setCollapse] = React.useState<CollapseState>(collapseState);
  // filter has 2 string literal types: "unread" | "all"
  const [
    activitiesFilter,
    setActivitiesFilter,
  ] = React.useState<ActivitiesFilter>("all");
  const [projectsWithTasks, setProjectsWithTasks] = React.useState<
    {
      project: ProjectObject;
      tasks: TaskObject["id"][];
    }[]
  >();

  const { id: checkoutWorkDayId = "" } = checkoutWorkDay || {};
  const fromDate = date && date[0];
  const toDate = date && date[date.length - 1];

  const [
    tasksAddedToWorkDay,
    tasksAddedToWorkDayLoading,
  ] = useAsyncTasksAddedToWorkDay(checkoutWorkDayId);

  const [
    activitySummaryArray,
    activitySummaryArrayLoading,
  ] = useAsyncUserActivitySummaryArray({
    fromDate,
    toDate,
    users,
  });

  const filteredActivitySummaryArray = React.useMemo(() => {
    // only show confirmed
    return !!activitySummaryArray
      ? activitySummaryArray.filter(({ confirmed_yn, read }) => {
          if (activitiesFilter === "unread") {
            return confirmed_yn && !read;
          } else {
            return confirmed_yn;
          }
        })
      : [];
  }, [activitiesFilter, activitySummaryArray]);

  React.useEffect(() => {
    const fetchProjectsWithTasks = async () => {
      if (type === "checkout" && !!tasksAddedToWorkDay) {
        setProjectsWithTasks(
          mapProjectsAndTasks(projects, tasksAddedToWorkDay)
        );
      } else if (type === "next-workday") {
        setProjectsWithTasks(mapProjectsAndTasks(projects, tasksInNextWorkDay));
      } else if (
        (type === "history" || type === "team") &&
        filteredActivitySummaryArray.length > 0
      ) {
        const taskIds = filteredActivitySummaryArray.map(({ task }) => task);
        try {
          const response = await getTasksListService({
            task_ids: taskIds,
          });
          if (response.status === 200) {
            const tasks = response.data.results as TaskArray;
            setProjectsWithTasks(
              projects
                .map((project) => {
                  return {
                    project,
                    tasks: tasks
                      .filter((task) => {
                        const projectOfTask = selectProjectOfTask(task);
                        return (
                          !!projectOfTask && isEqual(projectOfTask, project)
                        );
                      })
                      .map(({ id }) => id),
                  };
                })
                .filter(({ tasks }) => tasks.length > 0)
            );
          }
        } catch (e) {}
      } else {
        setProjectsWithTasks([]);
      }
    };
    fetchProjectsWithTasks();
  }, [
    JSON.stringify(filteredActivitySummaryArray),
    JSON.stringify(projects),
    JSON.stringify(tasksAddedToWorkDay),
    JSON.stringify(tasksInNextWorkDay),
    type,
  ]);

  const isLoading =
    (activitySummaryArray === undefined && activitySummaryArrayLoading) ||
    (checkoutWorkDayId
      ? tasksAddedToWorkDay === undefined && tasksAddedToWorkDayLoading
      : false) ||
    ((type === "history" || type === "team") &&
      projectsWithTasks === undefined) ||
    projectsLoading;

  return (
    <SummaryOfTheDayContext.Provider
      value={{
        editingAllowed,
        summaryType: type,
        tasksAddedToWorkDay,
        tasksInNextWorkDay,
      }}
    >
      <div className="SummaryOfTheDay">
        <Collapse activeKey={SUMMARY_OF_THE_DAY_PANEL_KEY}>
          <Panel
            header={
              <SummaryOfTheDayHeader
                activitiesFilter={activitiesFilter}
                editingAllowed={editingAllowed}
                onCollapse={() => setCollapse("collapse")}
                onExpand={() => setCollapse("expand")}
                onFilterClick={(filter: ActivitiesFilter) =>
                  setActivitiesFilter(filter)
                }
                onSuperExpand={() => setCollapse("super-expand")}
                type={type}
              />
            }
            key={SUMMARY_OF_THE_DAY_PANEL_KEY}
            showArrow={false}
          >
            {isLoading && <LoadingIcon />}
            {!isLoading &&
              !!projectsWithTasks &&
              projectsWithTasks.length === 0 && (
                <div className="SummaryOfTheDay__EmptyState">
                  {type === "next-workday" && (
                    <Tooltip title="No tasks have been added to Next Workday.">
                      <ExclamationCircleFilledIcon />
                    </Tooltip>
                  )}
                  <span>There are no tasks</span>
                </div>
              )}
            {!isLoading &&
              !!projectsWithTasks &&
              projectsWithTasks.length > 0 && (
                <ProjectCollapsePanel
                  activitiesFilter={activitiesFilter}
                  activitySummaryArray={filteredActivitySummaryArray}
                  collapse={collapse}
                  date={date}
                  projectsWithTasks={projectsWithTasks}
                  setCollapse={setCollapse}
                  type={type}
                  users={users}
                />
              )}
          </Panel>
        </Collapse>
      </div>
    </SummaryOfTheDayContext.Provider>
  );
};

export default SummaryOfTheDay;
