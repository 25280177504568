import React from "react";

import { DeleteConfirmationModalIcon } from "../../CustomIcons/CustomIcons.component";
import Modal from "../../General/Modal/Modal";

/**
 * Default Display
 * Confirmation modal displayed before proceeding with deleting a record
 * Displays prop objectName in DeleteConfirmationModal__Message__Title
 * Displays prop text in DeleteConfirmationModal__Message__Text
 */
const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({
  closeCallback,
  confirmCallback,
  displayModal,
  objectName,
  setDisplayModal,
  text,
}) => {
  const handleClickDeleteCancel = () => {
    setDisplayModal(false);
    closeCallback();
  };

  const handleClickDeleteConfirm = () => {
    setDisplayModal(false);
    confirmCallback();
  };

  return (
    <Modal
      className="DeleteConfirmationModal"
      visible={displayModal}
      okText="Delete forever"
      okButtonProps={{
        type: "primary",
        danger: true,
        style: {
          backgroundColor: "#ff2900",
        },
      }}
      onCancel={handleClickDeleteCancel}
      onOk={handleClickDeleteConfirm}
    >
      <>
        <DeleteConfirmationModalIcon className="DeleteConfirmationModal__Icon" />
        <div className="DeleteConfirmationModal__Message">
          <span className="DeleteConfirmationModal__Message__Title">
            Delete this [{objectName}]?
          </span>
          <span className="DeleteConfirmationModal__Message__Text">{text}</span>
        </div>
      </>
    </Modal>
  );
};

export default DeleteConfirmationModal;
