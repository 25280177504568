import React from "react";
import { useSelector } from "react-redux";

import {
  selectScheduledTasksState,
  selectUserTasksState,
} from "../reducers/tasks.reducer";
import {
  joinTaskArrayToUserTaskArray,
  scheduleTaskJoinedToUser,
} from "../utils/tasks-consolidate.util";

export default function useTaskArrayWithUserTaskAndScheduleTask(
  taskArray: TaskArray
) {
  const { data: userTasks } = useSelector(selectUserTasksState); // TODO: update to not use global user tasks state
  const { data: scheduledTasks } = useSelector(selectScheduledTasksState);

  const sortedTaskArray = React.useMemo(() => {
    return taskArray.sort((a, b) => {
      return a.title.localeCompare(b.title);
    });
  }, [taskArray]);

  const taskArrayJoinedToUserArray: TaskJoinedToUserArray = React.useMemo(() => {
    return joinTaskArrayToUserTaskArray(sortedTaskArray, userTasks);
  }, [sortedTaskArray, userTasks]);

  const taskJoinedToUserAndScheduledArray: TaskJoinedToUserAndScheduledArray = React.useMemo(() => {
    return scheduleTaskJoinedToUser(taskArrayJoinedToUserArray, scheduledTasks);
  }, [taskArrayJoinedToUserArray, scheduledTasks]);

  return taskJoinedToUserAndScheduledArray;
}
