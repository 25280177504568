import React from "react";
import { Layout } from "antd";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { individualWorkspacePrivateUrls } from "../../routers/AppRouter";

import useInitApp from "../../hooks/useInitApp.hook";

import GlobalSystemMessage from "../../modules/General/GlobalSystemMessage/GlobalSystemMessage";
import WorkspaceMembersPanel from "../../modules/Members/WorkspaceMembersPanel/WorkspaceMembersPanel";
import WorkspaceViewHeader from "./WorkspaceViewHeader/WorkspaceViewHeader";
import WorkspaceSideMenu from "./WorkspaceSideMenu/WorkspaceSideMenu";
import WorkspaceProjects from "../../modules/Projects/WorkspaceProjects/WorkspaceProjects";

import { selectErrorState } from "../../reducers/error.reducer";
import { selectUserDetailsState } from "../../reducers/user-details.reducer";
import { selectCurrentWorkDayState } from "../../reducers/work-day.reducer";

const { Header, Sider, Content } = Layout;

const WorkspaceView = () => {
  useInitApp();

  const { pathname } = useLocation();

  const error = useSelector(selectErrorState);
  const {
    data: { timezone: userTimezone },
  } = useSelector(selectUserDetailsState);
  const {
    data: { work_date: currentWorkDayDate },
  } = useSelector(selectCurrentWorkDayState);

  return (
    <div className="View">
      {userTimezone && currentWorkDayDate && (
        <Layout className="WorkspaceView">
          <Header>
            {error && <GlobalSystemMessage />}
            <WorkspaceViewHeader />
          </Header>
          <Layout>
            <Sider>
              <WorkspaceSideMenu />
            </Sider>
            <Content>
              {(() => {
                switch (pathname) {
                  case individualWorkspacePrivateUrls[0]:
                    return <WorkspaceProjects />;
                  case individualWorkspacePrivateUrls[1]:
                    return <WorkspaceMembersPanel />;
                  default:
                    return;
                }
              })()}
            </Content>
          </Layout>
        </Layout>
      )}
    </div>
  );
};

export default WorkspaceView;
