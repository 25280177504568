import React from "react";
import { Dropdown } from "antd";

import { CaretDownIcon } from "../../CustomIcons/CustomIcons.component";
import ProjectInitial from "../../Projects/ProjectInitial/ProjectInitial";
import ProjectSelectionDropdownMenu from "./ProjectSelectionDropdownMenu/ProjectSelectionDropdownMenu";

/**
 * ProjectSelection displays ProjectSelectionDropdownMenu
 * and the title of selected project
 * or place holder if no project is selected
 */
export interface ProjectSelectionProps {
  handleSelectProject: Function;
  selectedProject?: ProjectObject;
  projects: ProjectArray;
  projectsLoading: boolean;
  showDropdown: boolean;
  setShowDropdown: React.Dispatch<React.SetStateAction<boolean>>;
  handleToggleDropdown: Function;
}
const ProjectSelection: React.FC<ProjectSelectionProps> = ({
  handleSelectProject,
  handleToggleDropdown,
  projects,
  projectsLoading,
  selectedProject,
  showDropdown,
}) => {
  return (
    <div className="ProjectSelection">
      <div className="ProjectSelection__Item">
        <div className="ProjectSelection__Item__Title">Project</div>
        <Dropdown
          overlay={
            <ProjectSelectionDropdownMenu
              handleSelectProject={(projectObject: ProjectObject) => {
                handleSelectProject(projectObject);
              }}
              projects={projects}
              projectsLoading={projectsLoading}
            />
          }
          visible={showDropdown}
        >
          <div
            className="ProjectSelection__Item__Dropdown"
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              handleToggleDropdown();
            }}
          >
            <div className="ProjectSelection__Item__Dropdown__Selected">
              {selectedProject && <ProjectInitial project={selectedProject} />}
              <span className="ProjectSelection__Item__Dropdown__Selected__Title">
                {selectedProject ? selectedProject.title : "All projects"}
              </span>
            </div>
            <CaretDownIcon />
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

export default ProjectSelection;
