import React from "react";
import { useSelector, useDispatch } from "react-redux";

import CustomAvatar from "../../../../CustomAvatar/CustomAvatar";
import Button from "../../../../Button/Button";
import { getInitials } from "../../../../../utils/string.util";
import { getPriorityByTitle } from "../../../../../utils/priorities.util";
import { startCreateJoinTaskToUserRecord } from "../../../../../actions/tasks.action";
import { selectPrioritiesState } from "../../../../../reducers/priorities.reducer";

const ContributorItem: React.FC<ContributorItemProps> = ({
  task: { id: taskId, loading: taskLoading },
  contributor,
  type,
  ownerId,
}) => {
  const dispatch = useDispatch();

  const { data: priorities } = useSelector(selectPrioritiesState);

  const [hovered, setHovered] = React.useState(false);

  const isOwner = type === "task" && contributor.id === ownerId;

  const nonePriority = getPriorityByTitle(priorities, "None");

  const handleClickAddMember = React.useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      if (e) e.stopPropagation();
      if (nonePriority) {
        dispatch(
          startCreateJoinTaskToUserRecord({
            priority: nonePriority.id,
            task: taskId,
            user: contributor.id,
          })
        );
      } else {
        throw new Error(`nonePriority does not exist`);
      }
    },
    [dispatch, contributor.id, nonePriority, taskId]
  );

  return (
    <div
      className="ContributorItem"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className="ContributorItem__Info">
        <CustomAvatar
          size={24}
          src={contributor.avatar}
          work_status={contributor.current_status.status_enum_identifier}
          fallbackText={getInitials(contributor.display_name)}
        />
        <div className="ContributorItem__Name">{contributor.display_name}</div>
        {isOwner && <div className="ContributorItem__Tag">Owner</div>}
      </div>
      {/*
      * Bug 20220209-2
      * Temporarily removed until integrated with BE
      {hovered && type === "task" && (
        <div className="ContributorItem__Actions">
          <Button className="ContributorItem__Btn" size="small" type="default">
            {isOwner ? "Make member" : "Make owner"}
          </Button>
          <CloseIcon className="ContributorItem__Close" />
        </div>
      )}
      */}
      {hovered && type === "list" && (
        <div className="ContributorItem__Actions">
          <Button
            className="ContributorItem__Btn"
            size="small"
            type="default"
            onClick={handleClickAddMember}
            loading={taskLoading}
          >
            Add member
          </Button>
        </div>
      )}
    </div>
  );
};

export default ContributorItem;
