import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

import AppRouter from "./routers/AppRouter";
import { store } from "./store/configureStore";
import { requestFetchLoginSuccess } from "./actions/auth.action";
import { startPopulateStatusTypes } from "./actions/status-types.action";
import { startPopulateUserDetails } from "./actions/user-details.action";

import "normalize.css/normalize.css";
import "antd/dist/antd.css";
import "draft-js/dist/Draft.css";
import "./styles/styles.scss";

import createMirageMockServer from "./mirage/mirageMockServer";

import * as serviceWorker from "./serviceWorker";

// initiate mirage mock server when app loaded
createMirageMockServer();

/**
 * Get token from local storage and verify if valid
 * No endpoint to verify if token is valid, so just check if present for the meantime
 */
const token = localStorage.getItem("token");
const email = localStorage.getItem("email");
if (token && email) {
  store.dispatch(requestFetchLoginSuccess(token, email));
  store.dispatch(startPopulateStatusTypes()).then(() => {
    store.dispatch(startPopulateUserDetails());
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AppRouter />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
