import React from "react";
import { useSelector } from "react-redux";
import { Popover } from "antd";
import moment from "moment";
import { sumBy } from "lodash";
import cn from "classnames";

import useOutsideClickCallback from "../../../hooks/useOutsideClickCallback.hook";
import useWorkTimesByDate from "../../../hooks/useWorkTimesByDate.hook";

import ManageTaskTimePopoverContent from "./ManageTaskTimePopoverContent/ManageTaskTimePopoverContent";
import { CheckoutPanelContext } from "../../Checkout/CheckoutPanel/CheckoutPanel";

import { selectTaskTimeState } from "../../../reducers/task-time.reducer";
import { selectCurrentWorkDayState } from "../../../reducers/work-day.reducer";
import { getTotalDuration } from "../../../utils/time-conversion.util";

export interface ManageTaskTimeProps {
  disabled?: boolean;
  hovered: boolean;
  isCheckout?: boolean;
  taskId: TaskObject["id"];
  taskTimeProp?: number;
}
const ManageTaskTime: React.FC<ManageTaskTimeProps> = ({
  disabled = false,
  hovered,
  taskId,
  taskTimeProp,
  isCheckout = false,
}) => {
  const { checkoutWorkDay } = React.useContext(CheckoutPanelContext);

  const { data: currentWorkDay } = useSelector(selectCurrentWorkDayState);
  const { data: taskTime } = useSelector(selectTaskTimeState);

  const [visible, setVisible] = React.useState(false);
  const [totalTaskTime, setTotalTaskTime] = React.useState(0);
  const [hasError, setHasError] = React.useState(false);

  const manageTaskTimeRef = React.useRef<HTMLDivElement>(null);

  const { id: workDayId = "", work_date: workDayDate = "" } = isCheckout
    ? checkoutWorkDay || {}
    : currentWorkDay;
  const [
    workDayDateWorkTimes,
    workDayDateWorkTimesLoading,
  ] = useWorkTimesByDate(workDayDate);
  const unallocatedTime = Math.floor(
    workDayDateWorkTimes.work_time - workDayDateWorkTimes.tasks_allocated_time
  );

  const handleVisibleChange = (visible: boolean) => {
    setVisible(visible);
  };

  React.useEffect(() => {
    if (taskTimeProp !== undefined) {
      setTotalTaskTime(taskTimeProp);
    } else if (taskId && workDayId && taskTime.length > 0 && !taskTimeProp) {
      setTotalTaskTime(
        sumBy(
          taskTime.filter(
            ({ task, work_day }) => task === taskId && work_day === workDayId
          ),
          "seconds_worked"
        )
      );
    }
  }, [taskId, taskTime, workDayId, taskTimeProp]);

  React.useEffect(() => {
    const handleScroll = () => {
      setVisible(false);
    };

    // Remove visibility if scrolling from parent element(s)
    const elem = document.getElementsByClassName("ProjectCardList");
    const element = document.getElementsByClassName("CheckoutPanel__Body");
    if (elem.length > 0) elem[0].addEventListener("scroll", handleScroll);
    if (element.length > 0) element[0].addEventListener("scroll", handleScroll);
    return () => {
      if (elem.length > 0) elem[0].removeEventListener("scroll", handleScroll);
      if (element.length > 0)
        element[0].removeEventListener("scroll", handleScroll);
    };
  }, []);

  useOutsideClickCallback(
    () => {
      setHasError(false);
    },
    [],
    manageTaskTimeRef
  );

  return (
    <Popover
      placement="bottom"
      content={
        taskId &&
        workDayId && (
          <ManageTaskTimePopoverContent
            handleClickDone={() => {
              setVisible(false);
            }}
            setHasError={setHasError}
            taskTimeLengthSeconds={totalTaskTime}
            taskId={taskId}
            unallocatedTime={unallocatedTime}
            workDayId={workDayId}
          />
        )
      }
      trigger="click"
      overlayClassName="ManageTaskTime__Popover"
      visible={visible}
      onVisibleChange={handleVisibleChange}
    >
      <Popover
        placement="top"
        content={
          <div>Tasks total hours can not be more than Office Work hours.</div>
        }
        overlayClassName="ManageTaskTime__ErrorPopover"
        visible={hasError}
      >
        <div
          ref={manageTaskTimeRef}
          className={cn("ManageTaskTime", {
            "ManageTaskTime--hovered": hovered,
            "ManageTaskTime--hasError": hasError,
          })}
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            if (e) e.stopPropagation();
          }}
        >
          <span className="ManageTaskTime__Display">
            {getTotalDuration(moment.duration(totalTaskTime, "seconds"))}
          </span>
        </div>
      </Popover>
    </Popover>
  );
};

export { ManageTaskTime as default };
