import React from "react";
import { DragDropContext } from "react-beautiful-dnd";

import useSectionsUnderList from "../../hooks/useSectionsUnderList.hook";

import CardEmptyState from "../EmptyState/CardEmptyState/CardEmptyState";
import Section from "../Section/Section";
import Button from "../Button/Button";
import { ArchiveIcon } from "../CustomIcons/CustomIcons.component";

import {
  getListRemovedSectionsService,
  getListSectionsWithArchivedTasksService,
} from "../../services/list.service";

const queryAttr = "data-rbd-drag-handle-draggable-id";

// TODO 13.11.7 - remove context used for mocking reactivity
export interface ListContext {
  counterToReload: number;
  setCounterToReload: React.Dispatch<React.SetStateAction<number>>;
}
export const ListContext = React.createContext<ListContext>({} as ListContext);
// END TODO
export interface ListProps extends MyDeskTaskPanelProps {
  list: ListObject;
  project: ProjectObject;
  isUserProject: boolean;
  panelType: PanelType;
  collapse?: CollapseState;
  onClickNormalMode?: Function;
  onTaskSelect?: (task: TaskObject) => void;
  onTaskCopy?: (task: TaskObject) => void;
  showArchive?: boolean;
}

const List: React.FC<ListProps> = ({
  collapse = "expand",
  list,
  project,
  panelType,
  taskPage,
  onClickNormalMode,
  onTaskSelect,
  onTaskCopy,
  showArchive = false,
}) => {
  const [sectionsInList, setSectionsInList] = React.useState<SectionArray>([]);
  const [openedSections, setOpenedSections] = React.useState<
    SectionObject["id"][]
  >([]);

  const [sourceSection, setSourceSection] = React.useState<SectionObject>();
  const [
    destinationSection,
    setDestinationSection,
  ] = React.useState<SectionObject>();
  const [sourceIndex, setSourceIndex] = React.useState<number>();
  const [destinationIndex, setDestinationIndex] = React.useState<number>();
  const [
    draggablePlaceholderProps,
    setDraggablePlaceholderProps,
  ] = React.useState<DraggablePlaceholderProps>(
    {} as DraggablePlaceholderProps
  );

  // TODO 13.11.7 - remove state used for mocking reactivity of parent on child action
  const [counterToReload, setCounterToReload] = React.useState(0);

  const sectionsUnderList = useSectionsUnderList(list);

  const hasSection = sectionsInList.length > 0;
  const { id: listId } = list;

  const handleSectionToggle = (key: SectionObject["id"]) => {
    setOpenedSections((prev) => {
      if (prev.includes(key)) {
        return prev.filter((item) => item !== key);
      }
      return [...prev, key];
    });
  };

  const handleDragEnd = (result: any) => {
    setDraggablePlaceholderProps({} as DraggablePlaceholderProps);

    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    setSourceSection(sectionsInList[sInd]);
    setDestinationSection(sectionsInList[dInd]);
    setSourceIndex(source.index);
    setDestinationIndex(destination.index);

    // TODO 13.11.1 - update logic when drag ends
    // const draggedToSameSection = sInd === dInd;
    // if (draggedToSameSection) {
    // } else {
    // }
  };

  const getDraggedDom = (draggableId: any) => {
    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    return draggedDOM;
  };

  const handleDragUpdate = (event: any) => {
    if (!event.destination) {
      return;
    }

    const draggedDOM: any = getDraggedDom(event.draggableId);

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const destinationIndex = event.destination.index;
    const sourceIndex = event.source.index;

    const childrenArray = [...draggedDOM.parentNode.children];
    const movedItem = childrenArray[sourceIndex];
    childrenArray.splice(sourceIndex, 1);

    const updatedArray = [
      ...childrenArray.slice(0, destinationIndex),
      movedItem,
      ...childrenArray.slice(destinationIndex + 1),
    ];

    var clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
      updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom;
      }, 0);

    setDraggablePlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode).paddingLeft
      ),
    });
  };

  React.useEffect(() => {
    const getListRemovedSections = async () => {
      try {
        const response = await getListRemovedSectionsService(listId);
        if (response.status === 200) {
          const sections: SectionArray = response.data;
          return sections;
        } else {
          return [] as SectionArray;
        }
      } catch (e) {
        const error = (e as Error).message;
        return [] as SectionArray;
      }
    };
    const getListSectionsWithArchivedTasks = async () => {
      try {
        const response = await getListSectionsWithArchivedTasksService(listId);
        if (response.status === 200) {
          const sections: SectionArray = response.data;
          return sections;
        } else {
          return [] as SectionArray;
        }
      } catch (e) {
        const error = (e as Error).message;
        return [] as SectionArray;
      }
    };

    const getArchiveSections = async () => {
      const removedSections = await getListRemovedSections();
      const sectionsWithArchivedTasks = await getListSectionsWithArchivedTasks();

      setSectionsInList([...removedSections, ...sectionsWithArchivedTasks]);
    };

    if (!showArchive) {
      setSectionsInList(sectionsUnderList);
    } else {
      getArchiveSections();
    }
  }, [sectionsUnderList, listId, showArchive]);

  React.useEffect(() => {
    const sectionIds = sectionsInList ? sectionsInList.map(({ id }) => id) : [];
    if (collapse === "collapse") {
      setOpenedSections([]);
    } else if (collapse === "expand") {
      setOpenedSections(sectionIds);
    }
  }, [collapse, sectionsInList]);

  const sectionsJsx = sectionsInList.map((section, index) => {
    return (
      <Section
        index={index}
        draggablePlaceholderProps={draggablePlaceholderProps}
        archiveMode={showArchive}
        section={section}
        project={project}
        panelType={panelType}
        openedSections={openedSections}
        handleSectionToggle={handleSectionToggle}
        key={index}
        onTaskSelect={onTaskSelect}
        onTaskCopy={onTaskCopy}
        sourceSection={sourceSection}
        destinationSection={destinationSection}
        sourceIndex={sourceIndex}
        destinationIndex={destinationIndex}
      />
    );
  });

  return (
    // TODO 13.11.7 - remove context used for mocking reactivity
    <ListContext.Provider
      value={{
        counterToReload,
        setCounterToReload,
      }}
    >
      <div className="List">
        {showArchive && onClickNormalMode && (
          <div className="List__ArchivedNotice">
            <div className="List__ArchivedNotice__Message">
              <ArchiveIcon />
              <span>Only archived tasks are shown</span>
            </div>
            <div className="List__ArchivedNotice__Actions">
              <Button
                className="List__ArchivedNotice__Actions__BackToNormalBtn"
                onClick={() => onClickNormalMode()}
              >
                Back to task list
              </Button>
            </div>
          </div>
        )}

        {hasSection ? (
          <DragDropContext
            onDragEnd={handleDragEnd}
            onDragUpdate={handleDragUpdate}
          >
            {sectionsJsx}
          </DragDropContext>
        ) : (
          <CardEmptyState
            message="No sections in list."
            panelType={panelType}
          />
        )}
      </div>
    </ListContext.Provider>
  );
};

export default List;
