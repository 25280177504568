import React from "react";
import { useSelector } from "react-redux";
import { Popover, Table } from "antd";

import {
  EditAccountIcon,
  DraftIcon,
  MoreActionsIcon,
  ArchiveIcon,
  DeleteIcon,
  LoadingIcon,
} from "../../CustomIcons/CustomIcons.component";
import ProjectInitial from "../ProjectInitial/ProjectInitial";
import ProjectModal from "../../ProjectModal/ProjectModal.component";
import ProjectArchiveToggler from "../ProjectArchiveToggler/ProjectArchiveToggler";

import { selectListsInProject } from "../../../selectors/list.selector";
import { getUsersByProjectService } from "../../../services/users.service";
import { selectActivityTypesState } from "../../../reducers/activity-types.reducer";
import DeleteProjectTrigger from "../DeleteProjectTrigger/DeleteProjectTrigger";

const { Column } = Table;

export interface ProjectTableProps {
  loading: boolean;
  projects: ProjectArray;
}
const ProjectTable: React.FC<ProjectTableProps> = ({ loading, projects }) => {
  const activityTypes = useSelector(selectActivityTypesState).data;

  const [dataSource, setDataSource] = React.useState<any[]>([]);
  const [loadingSource, setLoadingSource] = React.useState(false);
  const [showProjectModal, setShowProjectModal] = React.useState(false);
  const [editProject, setEditProject] = React.useState<ProjectObject>();

  const isLoading = loading || loadingSource;
  const stringifiedProjects = JSON.stringify(projects);
  const stringifiedActivityTypes = JSON.stringify(activityTypes);

  const getDataSource = React.useCallback(async () => {
    const parsedProjects: ProjectArray = JSON.parse(stringifiedProjects);
    const parsedActivityTypes: ActivityTypeArray = JSON.parse(
      stringifiedActivityTypes
    );

    setLoadingSource(true);
    const dataSource = await Promise.all(
      parsedProjects.map(async (project) => {
        const { id, description, workspace } = project;
        const lists = selectListsInProject(project);
        const users: ProjectUser[] = await getUsersByProjectService(id);
        const members = users.filter(
          ({ accessType }) => accessType === "member"
        );
        const defaultActivity = parsedActivityTypes.filter(
          ({ parent_workspace, workspace_default_yn }) =>
            parent_workspace === workspace && workspace_default_yn
        );

        return {
          project,
          description,
          task_lists: lists.length,
          members: members.length,
          autoActivity: defaultActivity,
          action: project,
        };
      })
    );
    setLoadingSource(false);
    setDataSource(dataSource);
  }, [stringifiedProjects, stringifiedActivityTypes]);

  const handleProjectModalClose = () => {
    setEditProject(undefined);
    setShowProjectModal(false);
  };

  React.useEffect(() => {
    getDataSource();
  }, [getDataSource]);

  return (
    <>
      <ProjectModal
        open={showProjectModal}
        onOk={handleProjectModalClose}
        onClose={handleProjectModalClose}
        project={editProject}
      />
      <Table
        className="ProjectTable"
        dataSource={dataSource}
        loading={{
          spinning: isLoading,
          indicator: <LoadingIcon />,
        }}
        pagination={{ hideOnSinglePage: true }}
      >
        <Column
          title="Project"
          dataIndex="project"
          key="project"
          render={(project: ProjectObject) => {
            const { title } = project;
            return (
              <div className="ProjectTable__Project">
                <ProjectInitial project={project} />
                <div className="ProjectTable__Project__Title">{title}</div>
              </div>
            );
          }}
        />
        <Column
          title="Description"
          dataIndex="description"
          key="description"
          render={(description: ProjectObject["description"]) => (
            <div className="ProjectTable__Description">{description}</div>
          )}
        />
        <Column title="Task lists" dataIndex="task_lists" key="task_lists" />
        <Column title="Members" dataIndex="members" key="members" />
        <Column
          title="Auto activity"
          dataIndex="autoActivity"
          key="autoActivity"
          render={(autoActivity: string) => (
            <div className="ProjectTable__AutoActivity">Daily Report</div>
          )}
        />
        <Column
          title=""
          key="action"
          render={(text: any, record: any) => (
            <Popover
              placement="bottomLeft"
              destroyTooltipOnHide={true}
              content={
                <div className="WorkspaceMembersTable__MoreActions__Popover__Content">
                  {/* <div
                  className="WorkspaceMembersTable__MoreActions__Popover__Action"
                  onClick={() => {
                    if (onRowClick) onRowClick(record, index);
                  }}
                >
                  <EditAccountIcon />
                  <span>Project page</span>
                </div> */}
                  <div
                    className="WorkspaceMembersTable__MoreActions__Popover__Action"
                    onClick={() => {
                      setEditProject(record.project);
                      setTimeout(() => {
                        setShowProjectModal(true);
                      }, 500);
                    }}
                  >
                    <DraftIcon />
                    <span>Edit</span>
                  </div>
                  <ProjectArchiveToggler project={record.project} />
                  <DeleteProjectTrigger project={record.project} />
                  {/* <div className="WorkspaceMembersTable__MoreActions__Popover__Action">
                  <DeleteIcon />
                  <span>Delete</span>
                </div> */}
                </div>
              }
              trigger="click"
              overlayClassName="WorkspaceMembersTable__MoreActions__Popover"
              getPopupContainer={(trigger: any) => trigger.parentElement}
            >
              <MoreActionsIcon onClick={(e: any) => e.stopPropagation()} />
            </Popover>
          )}
        />
      </Table>
    </>
  );
};

export default ProjectTable;
