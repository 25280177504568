import React from "react";
import { Avatar, Popover, Table, Tooltip } from "antd";

import AvatarGroup from "../../AvatarGroup/AvatarGroup.component";
import CustomAvatar from "../../CustomAvatar/CustomAvatar";
import {
  AccountCircleIcon,
  BlockIcon,
  EditAccountIcon,
  InfoCircleOutlinedIcon,
  LoadingIcon,
  MailIcon,
  MoreActionsIcon,
  UserIcon,
} from "../../CustomIcons/CustomIcons.component";
import WorkspaceMembersPopoverContent from "../WorkspaceMembersPopoverContent/WorkspaceMembersPopoverContent";

import { getObservableUsersService } from "../../../services/observable_users.service";
import { getInitials } from "../../../utils/string.util";

const { Column } = Table;

export interface WorkspaceMembersTableProps {
  members: AccountUser[];
  onRowClick?: (record: any, rowIndex: any) => void;
}
const WorkspaceMembersTable: React.FC<WorkspaceMembersTableProps> = ({
  members,
  onRowClick,
}) => {
  const [dataSource, setDataSource] = React.useState<any[]>([]);
  const [loadingSource, setLoadingSource] = React.useState(false);

  const getDataSource = React.useCallback(async () => {
    setLoadingSource(true);
    const dataSource = await Promise.all(
      members.map(
        async ({
          id,
          avatar,
          display_name: displayName,
          full_name: fullName,
          workspace_role: userType,
          country: location,
          current_status,
          timezone,
        }) => {
          const { data: teamPages }: { data: ObservableUserArray } =
            (await getObservableUsersService()) || {};
          const { status_enum_identifier: status } = current_status || {};
          const member = {
            id,
            avatar,
            displayName,
            status,
            timezone,
          };
          return {
            member,
            fullName,
            userType,
            location,
            lastActive: "1h ago",
            teamPages,
          };
        }
      )
    );
    setLoadingSource(false);
    setDataSource(dataSource);
  }, [members]);

  React.useEffect(() => {
    getDataSource();
  }, [getDataSource]);

  const disablePropagation = (e: any) => e.stopPropagation();

  return (
    <Table
      className="WorkspaceMembersTable"
      dataSource={dataSource}
      loading={{
        spinning: loadingSource,
        indicator: <LoadingIcon />,
      }}
      pagination={false}
      scroll={{
        y: "55rem",
      }}
      onRow={(record: any, rowIndex: any) => {
        return {
          onClick: (event: React.MouseEvent) => {
            if (onRowClick) onRowClick(record, rowIndex);
          },
        };
      }}
    >
      <Column
        title="Member"
        dataIndex="member"
        key="member"
        render={({ avatar, displayName, status }: any) => {
          return (
            <div className="WorkspaceMembersTable__Member">
              <CustomAvatar src={avatar} work_status={status} size={40} />
              <div className="WorkspaceMembersTable__Member__Details">
                <span>{displayName}</span>
                <sub>Front-end developer</sub>
              </div>
            </div>
          );
        }}
      />
      <Column
        title="Full name"
        dataIndex="fullName"
        key="fullName"
        render={(fullName: AccountUser["full_name"]) => (
          <div className="WorkspaceMembersTable__FullName">
            <span>{fullName}</span>
          </div>
        )}
      />
      <Column
        title="User type"
        dataIndex="userType"
        key="userType"
        render={(userType: AccountUser["workspace_role"]) => (
          <div className="WorkspaceMembersTable__UserType">{userType}</div>
        )}
      />
      <Column title="Location" dataIndex="location" key="location" />
      <Column title="Last active" dataIndex="lastActive" key="lastActive" />
      <Column
        title={
          <Tooltip
            title={`Team page owners can see\nmembers’ complete worktime and\nactivities information.`}
            trigger="hover"
            placement="top"
            overlayClassName="WorkspaceMembersTable__TeamPages__Tooltip"
          >
            <span>Team pages</span>
            <InfoCircleOutlinedIcon />
          </Tooltip>
        }
        dataIndex="teamPages"
        key="teamPages"
        render={(
          teamPages: ObservableUserArray,
          record: any,
          index: number
        ) => {
          if (teamPages && index !== 1) {
            const avatars = teamPages
              ? teamPages.map(({ observed_user }) =>
                  observed_user.avatar ? observed_user.avatar : ""
                )
              : [];
            const avatarsFallBackText = teamPages
              ? teamPages.map(({ observed_user }) =>
                  getInitials(observed_user.display_name)
                )
              : [];

            return (
              <Tooltip
                title={<WorkspaceMembersPopoverContent members={teamPages} />}
                trigger="hover"
                placement="top"
                overlayClassName="WorkspaceMembersTable__TeamPages__Popover"
              >
                <div className="WorkspaceMembersTable__TeamPages">
                  <AvatarGroup
                    avatars={avatars}
                    avatarsFallBackText={avatarsFallBackText}
                    size={20}
                  />
                </div>
              </Tooltip>
            );
          } else {
            return (
              <Tooltip
                title="Edit members"
                trigger="hover"
                placement="top"
                overlayClassName=""
                getPopupContainer={(trigger: any) => trigger.parentElement}
              >
                <div className="WorkspaceMembersTable__TeamPages">
                  <Avatar icon={<UserIcon />} size={20} />
                </div>
              </Tooltip>
            );
          }
        }}
      />
      <Column
        title=""
        key="action"
        render={(text: any, record: any, index: number) => (
          <Popover
            placement="bottomLeft"
            destroyTooltipOnHide={true}
            content={
              <div className="WorkspaceMembersTable__MoreActions__Popover__Content">
                <div
                  className="WorkspaceMembersTable__MoreActions__Popover__Action"
                  onClick={() => {
                    if (onRowClick) onRowClick(record, index);
                  }}
                >
                  <EditAccountIcon />
                  <span>Edit Account</span>
                </div>
                <div className="WorkspaceMembersTable__MoreActions__Popover__Action">
                  <MailIcon />
                  <span>Invite</span>
                </div>
                <div className="WorkspaceMembersTable__MoreActions__Popover__Action">
                  <AccountCircleIcon />
                  <span>Edit profile</span>
                </div>
                <div className="WorkspaceMembersTable__MoreActions__Popover__Action">
                  <BlockIcon />
                  <span>Deactivate</span>
                </div>
              </div>
            }
            trigger="click"
            overlayClassName="WorkspaceMembersTable__MoreActions__Popover"
            getPopupContainer={(trigger: any) => trigger.parentElement}
          >
            <MoreActionsIcon onClick={disablePropagation} />
          </Popover>
        )}
      />
    </Table>
  );
};

export default WorkspaceMembersTable;
