import React from "react";
import { useSelector, useDispatch } from "react-redux";
import cn from "classnames";

import { SummaryOfTheDayContext } from "../../Checkout/SummaryOfTheDay/SummaryOfTheDay";

import {
  LoadingIcon,
  TodayIcon,
} from "../../CustomIcons/CustomIcons.component";
import { CheckoutPanelContext } from "../../Checkout/CheckoutPanel/CheckoutPanel";

import { selectUserDetailsState } from "../../../reducers/user-details.reducer";
import { getPriorityByTitle } from "../../../utils/priorities.util";
import { selectPrioritiesState } from "../../../reducers/priorities.reducer";
import {
  startCreateJoinTaskToUserRecord,
  startPartialUpdateJoinTaskToUserRecord,
} from "../../../actions/tasks.action";

export interface AddRemoveToFromWorkDayProps {
  task: TaskJoinedToUserAndScheduledObject;
}
const AddRemoveToFromWorkDay: React.FC<AddRemoveToFromWorkDayProps> = ({
  task,
}) => {
  const dispatch = useDispatch();

  const { tasksAddedToWorkDay } = React.useContext(SummaryOfTheDayContext);
  const { checkoutWorkDay } = React.useContext(CheckoutPanelContext);

  const {
    data: { id: userId },
  } = useSelector(selectUserDetailsState);
  const { data: priorities } = useSelector(selectPrioritiesState);

  const { id: taskId, join_task_to_user_id: userTaskId, today_yn } = task;
  const { loading: todayYnLoading, value: todayYn } = today_yn || {};

  const isAddedToWorkDay =
    !!tasksAddedToWorkDay &&
    tasksAddedToWorkDay.map(({ id }) => id).includes(taskId);
  const text = isAddedToWorkDay ? "Remove from Workday" : "Add to Workday";
  const { id: checkoutWorkDayId = "" } = checkoutWorkDay || {};

  const nonePriority = getPriorityByTitle(priorities, "None");

  const handleClick = async (e: React.MouseEvent<HTMLElement>) => {
    if (e) e.stopPropagation();
    if (!!userId && !!checkoutWorkDayId) {
      if (userTaskId) {
        // add title if removing today_yn to determine if system feedback message will be displayed
        const data = {
          id: userTaskId,
          today_yn: !todayYn,
          taskId,
        };
        dispatch(startPartialUpdateJoinTaskToUserRecord(data));
      } else if (nonePriority) {
        dispatch(
          startCreateJoinTaskToUserRecord({
            today_yn: true,
            priority: nonePriority.id,
            task: taskId,
            user: userId,
          })
        );
      } else {
        throw new Error(`nonePriority does not exist`);
      }
    }
  };

  return (
    <div className="AddRemoveToFromWorkDay" onClick={handleClick}>
      {todayYnLoading ? (
        <LoadingIcon />
      ) : (
        <>
          <TodayIcon
            className={cn("AddRemoveToFromWorkDay__Icon", {
              "AddRemoveToFromWorkDay__Icon--Active": isAddedToWorkDay,
            })}
          />
          <span className="AddRemoveToFromWorkDay__Text">{text}</span>
        </>
      )}
    </div>
  );
};

export default AddRemoveToFromWorkDay;
