import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import cn from "classnames";

import { LoadingIcon } from "../../../CustomIcons/CustomIcons.component";
import TimeDisplay from "../../../TimeDisplay/TimeDisplay.component";

import { startAddTaskTime } from "../../../../actions/task-time.action";
import { selectTaskTimeState } from "../../../../reducers/task-time.reducer";
import { selectWorkTimesState } from "../../../../reducers/work-times.reducer";
import {
  hoursToSeconds,
  minutesToSeconds,
  secondsToHours,
  secondsToMinutes,
  secondsToMs,
} from "../../../../utils/time-conversion.util";

export interface ManageTaskTimePopoverContentProps {
  disabled?: boolean;
  handleClickDone: React.MouseEventHandler<HTMLDivElement>;
  setHasError: React.Dispatch<React.SetStateAction<boolean>>;
  taskId: TaskObject["id"];
  taskTimeLengthSeconds: TaskTimeObject["seconds_worked"];
  unallocatedTime: number;
  workDayId: WorkDayObject["id"];
}
const ManageTaskTimePopoverContent: React.FC<ManageTaskTimePopoverContentProps> = ({
  disabled,
  handleClickDone,
  setHasError,
  taskId,
  taskTimeLengthSeconds = 0,
  unallocatedTime,
  workDayId,
}) => {
  const dispatch = useDispatch();

  const { loading: workTimesLoading } = useSelector(selectWorkTimesState);
  const { loading: taskTimeLoading } = useSelector(selectTaskTimeState);

  const loading = workTimesLoading || taskTimeLoading;

  const handleClickManuallyAddManageTaskTime = (
    seconds: number,
    increment: boolean
  ) => (e: React.MouseEvent) => {
    const negativeOrPositiveSeconds = increment ? seconds : -seconds;
    const data = {
      record_start_datetime: moment().toISOString(),
      recording_yn: false,
      task: taskId,
      work_day: workDayId,
      seconds_worked: negativeOrPositiveSeconds,
    };

    dispatch(startAddTaskTime(data));
  };

  const handleClickNotEnoughTime = (e: React.MouseEvent) => {
    setHasError(true);
  };

  const handleClickAddUnallocatedTime = () => {
    const data = {
      id: null,
      record_start_datetime: moment().toISOString(),
      recording_yn: false,
      seconds_worked: unallocatedTime,
      task: taskId,
      work_day: workDayId,
    };

    dispatch(startAddTaskTime(data));
  };

  return (
    <div
      className="ManageTaskTimePopoverContent"
      onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
    >
      <div className="ManageTaskTimePopoverContent__Actions">
        <div
          className={cn(
            "ManageTaskTimePopoverContent__Actions__Decrement ManageTaskTimePopoverContent__Actions__Decrement--OneHour",
            {
              "is-disabled":
                loading ||
                disabled ||
                secondsToHours(taskTimeLengthSeconds) < 1,
            }
          )}
          onClick={handleClickManuallyAddManageTaskTime(
            hoursToSeconds(1),
            false
          )}
        >
          {loading ? <LoadingIcon /> : <span>-1h</span>}
        </div>
        <div
          className={cn(
            "ManageTaskTimePopoverContent__Actions__Decrement ManageTaskTimePopoverContent__Actions__Decrement--TenMinutes",
            {
              "is-disabled":
                loading ||
                disabled ||
                secondsToMinutes(taskTimeLengthSeconds) < 10,
            }
          )}
          onClick={handleClickManuallyAddManageTaskTime(
            minutesToSeconds(10),
            false
          )}
        >
          {loading ? <LoadingIcon /> : <span>-10m</span>}
        </div>
        <div
          className={cn(
            "ManageTaskTimePopoverContent__Actions__Increment ManageTaskTimePopoverContent__Actions__Increment--TenMinutes",
            {
              "is-disabled": loading || disabled,
            }
          )}
          onClick={
            secondsToMinutes(unallocatedTime) < 10
              ? handleClickNotEnoughTime
              : handleClickManuallyAddManageTaskTime(minutesToSeconds(10), true)
          }
        >
          {loading ? <LoadingIcon /> : <span>+ 10m</span>}
        </div>
        <div
          className={cn(
            "ManageTaskTimePopoverContent__Actions__Increment ManageTaskTimePopoverContent__Actions__Increment--OneHour",
            {
              "is-disabled": loading || disabled,
            }
          )}
          onClick={
            secondsToHours(unallocatedTime) < 1
              ? handleClickNotEnoughTime
              : handleClickManuallyAddManageTaskTime(hoursToSeconds(1), true)
          }
        >
          {loading ? <LoadingIcon /> : <span>+1h</span>}
        </div>
      </div>
      <div
        className={cn("ManageTaskTimePopoverContent__AddAllUnallocatedTime", {
          "is-disabled": loading || disabled,
        })}
        onClick={
          secondsToMinutes(unallocatedTime) === 0
            ? handleClickNotEnoughTime
            : handleClickAddUnallocatedTime
        }
      >
        <span>
          Add all unallocated time:&nbsp;
          <TimeDisplay
            time={secondsToMs(unallocatedTime)}
            active={false}
            mouseOver={false}
            lastUnit="m"
          />
        </span>
      </div>
      <div
        className="ManageTaskTimePopoverContent__Done"
        onClick={handleClickDone}
      >
        <span>Done</span>
      </div>
    </div>
  );
};

export default ManageTaskTimePopoverContent;
