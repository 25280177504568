import React from "react";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";

import {
  ExclamationCircleFilledIcon,
  CloseIcon,
} from "../../CustomIcons/CustomIcons.component";
import ErrorModal from "../../../modules/General/ErrorModal/ErrorModal";
import { selectErrorState } from "../../../reducers/error.reducer";
import { setError } from "../../../actions/error.action";

const GlobalSystemMessage = () => {
  const dispatch = useDispatch();
  const error = useSelector(selectErrorState);
  const { title = "", text = "" } = error || {};

  const [displayErrorModal, setDisplayErrorModal] = React.useState(false);

  const handleOk = () => {
    dispatch(setError(null));
    setDisplayErrorModal(false);
  };

  const handleCancel = () => {
    dispatch(setError(null));
    setDisplayErrorModal(false);
  };

  React.useEffect(() => {
    if (error === null) setDisplayErrorModal(false);
  }, [error]);

  return (
    <div
      className={classNames("GlobalSystemMessage", {
        "GlobalSystemMessage--Error": error,
      })}
    >
      <ErrorModal
        handleCancel={handleCancel}
        handleOk={handleOk}
        title={title}
        text={text}
        visible={displayErrorModal}
      />
      {error && (
        <>
          <ExclamationCircleFilledIcon className="GlobalSystemMessage__ErrorIcon" />
          <span>{title}.</span>
          <span
            className="GlobalSystemMessage__MoreInfo"
            onClick={() => {
              setDisplayErrorModal(true);
            }}
          >
            More info
          </span>
        </>
      )}
      <CloseIcon
        className="GlobalSystemMessage__CloseIcon"
        onClick={() => dispatch(setError(null))}
      />
    </div>
  );
};

export default GlobalSystemMessage;
