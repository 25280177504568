import {
  joinTaskArrayToUserTaskArray,
  scheduleTaskJoinedToUser,
} from "../utils/tasks-consolidate.util";

import { nextCalendarSchedule } from "../fixtures/third-party-calendar.fixture";
import userDetails from "../fixtures/user-details.fixture";

export const taskArray: TaskArray = [
  {
    id: "233M5RE2EE",
    owner_user: "9LBX5JWFS1",
    section: {
      id: "B058T4CZDA",
      parent_list: "NOWVC6LH1X",
      title: "Sample Section A",
    },
    title: "Sample Task 2021-08-09 09:38",
    url: "Sample URL",
    description: "",
    tasklist_members: [],
    loading: false,
  },
  {
    id: "ILDMXEBO5W",
    owner_user: "9LBX5JWFS1",
    section: {
      id: "BJSB3UUVVP",
      parent_list: "7U86C77U5R",
      title: "Sample Section A",
    },
    title: "Sample Task 2021-08-10 12:45",
    url: "Sample URL",
    description: "",
    tasklist_members: [],
    loading: false,
  },
  {
    id: "4II3DV7OAX",
    owner_user: "EGUN7PV8KA",
    section: {
      id: "B058T4CZDA",
      parent_list: "NOWVC6LH1X",
      title: "Sample Section A",
    },
    title: "Sample Task 2021-08-10 9:46",
    url: "Sample URL",
    description: "",
    tasklist_members: [],
    loading: false,
  },
  {
    id: "2706XNA27N",
    owner_user: "9LBX5JWFS1",
    section: {
      id: "B058T4CZDA",
      parent_list: "NOWVC6LH1X",
      title: "Sample Section A",
    },
    title: "Task Not Joined To User 2021-08-10 11:26",
    url: "Sample URL",
    description: "",
    tasklist_members: [],
    loading: false,
  },
];

export const joinTaskToUserArray: JoinTaskToUserArray = [
  {
    id: "6QJKHE7F3P",
    user: {
      id: "9LBX5JWFS1",
      avatar: null,
      full_name: "Briggs Victoria",
      workspace: "6HDCHAGWWA",
      workspace_role: "ADMIN",
      current_status: {
        deleted_yn: false,
        id: "7V7WHQN7LQ",
        status_enum_identifier: "working",
        start_datetime: "2021-08-10T00:10:48.729498Z",
        end_datetime: null,
      },
      display_name: "Briggss",
      phone_number: "",
      country: "Philippines",
      city: "",
      team_page_access_granted_yn: true,
      workspace_name: "Workspace 1",
      what_i_do: "DEV",
      has_beta_version_access_yn: true,
      using_beta_version_yn: true,
    },
    task: {
      id: "233M5RE2EE",
      owner_user: "9LBX5JWFS1",
      section: "B058T4CZDA",
      title: "Sample Task 2021-08-09 09:38",
      url: "Sample URL",
      description: "",
      tasklist_members: [],
      loading: false,
    },
    priority: {
      loading: false,
      value: "W54892NLQN",
    },
    today_yn: {
      loading: false,
      value: true,
    },
    starred_yn: {
      loading: false,
      value: true,
    },
    next_shift_yn: {
      loading: false,
      value: false,
    },
    role_enum: "contributor",
    draft_activities_count: 0,
    members: [],
    task_list: {} as ListObject,
    tasklist_members: [],
    unread_activities_count: 0,
  },
  {
    id: "J79OIKRUCZ",
    user: {
      id: "9LBX5JWFS1",
      avatar: null,
      full_name: "Briggs Victoria",
      workspace: "6HDCHAGWWA",
      workspace_role: "ADMIN",
      current_status: {
        deleted_yn: false,
        id: "7V7WHQN7LQ",
        status_enum_identifier: "working",
        start_datetime: "2021-08-10T00:10:48.729498Z",
        end_datetime: null,
      },
      display_name: "Briggss",
      phone_number: "",
      country: "Philippines",
      city: "",
      team_page_access_granted_yn: true,
      workspace_name: "Workspace 1",
      what_i_do: "DEV",
      has_beta_version_access_yn: true,
      using_beta_version_yn: true,
    },
    task: {
      id: "ILDMXEBO5W",
      owner_user: "9LBX5JWFS1",
      section: "BJSB3UUVVP",
      title: "Sample Task 2021-08-10 12:45",
      url: "Sample URL",
      description: "",
      tasklist_members: [],
      loading: false,
    },
    priority: {
      loading: false,
      value: "W54892NLQN",
    },
    today_yn: {
      loading: false,
      value: false,
    },
    starred_yn: {
      loading: false,
      value: true,
    },
    next_shift_yn: {
      loading: false,
      value: false,
    },
    role_enum: "contributor",
    draft_activities_count: 0,
    members: [],
    task_list: {} as ListObject,
    tasklist_members: [],
    unread_activities_count: 0,
  },
  {
    id: "8V6E4A2C6Y",
    user: {
      id: "EGUN7PV8KA",
      avatar: null,
      full_name: "Admin",
      workspace: "6HDCHAGWWA",
      workspace_role: "MEMBER",
      current_status: {
        deleted_yn: false,
        id: "1VECBFLNCI",
        status_enum_identifier: "working",
        start_datetime: "2021-08-10T00:19:18.101565Z",
        end_datetime: null,
      },
      display_name: "Admin Example",
      phone_number: "",
      country: "",
      city: "",
      team_page_access_granted_yn: true,
      workspace_name: "Workspace 1",
      what_i_do: "DEV",
      has_beta_version_access_yn: true,
      using_beta_version_yn: true,
    },
    task: {
      id: "4II3DV7OAX",
      owner_user: "EGUN7PV8KA",
      section: "B058T4CZDA",
      title: "Sample Task 2021-08-10 9:46",
      url: "Sample URL",
      description: "",
      tasklist_members: [],
      loading: false,
    },
    priority: {
      loading: false,
      value: "W54892NLQN",
    },
    today_yn: {
      loading: false,
      value: false,
    },
    starred_yn: {
      loading: false,
      value: true,
    },
    next_shift_yn: {
      loading: false,
      value: false,
    },
    role_enum: "contributor",
    draft_activities_count: 0,
    members: [],
    task_list: {} as ListObject,
    tasklist_members: [],
    unread_activities_count: 0,
  },
];

export const scheduleTaskArray: ScheduleTaskArray = [
  {
    id: "MW3156K5UA",
    join_task_to_user: "6QJKHE7F3P",
    task_id: "233M5RE2EE",
    user_id: "9LBX5JWFS1",
    scheduled_datetime: "2021-08-10T01:44:13Z",
  },
];

export const taskJoinedToUserArray: TaskJoinedToUserArray = joinTaskArrayToUserTaskArray(
  taskArray,
  joinTaskToUserArray
);
export const taskJoinedToUserObject = taskJoinedToUserArray[0];

export const taskJoinedToUserAndScheduledArray: TaskJoinedToUserAndScheduledArray = scheduleTaskJoinedToUser(
  taskJoinedToUserArray,
  scheduleTaskArray
);

export const taskJoinedToUserAndScheduledObject: TaskJoinedToUserAndScheduledObject =
  taskJoinedToUserAndScheduledArray[0];

export const taskJoinedToUserAndScheduled = taskJoinedToUserAndScheduledArray.filter(
  ({ join_task_to_user_id, scheduled_task_id }) =>
    join_task_to_user_id && scheduled_task_id
)[0];

export const taskJoinedToUserAndNotScheduled = taskJoinedToUserAndScheduledArray.filter(
  ({ join_task_to_user_id, scheduled_task_id }) =>
    join_task_to_user_id && !scheduled_task_id
)[0];

export const taskJoinedToUserNotScheduledToNextShift: TaskJoinedToUserAndScheduledObject = {
  ...taskJoinedToUserAndScheduled,
  next_shift_yn: {
    loading: false,
    value: false,
  },
};

export const taskJoinedToUserScheduledToNextShift: TaskJoinedToUserAndScheduledObject = {
  ...taskJoinedToUserAndScheduled,
  scheduled_datetime: nextCalendarSchedule[0].start_datetime,
  next_shift_yn: {
    loading: false,
    value: true,
  },
};

export const taskNotJoinedToUserAndNotScheduled = taskJoinedToUserAndScheduledArray.filter(
  ({ join_task_to_user_id, scheduled_task_id }) =>
    !join_task_to_user_id && !scheduled_task_id
)[0];

export const sortedTasks = taskJoinedToUserAndScheduledArray.sort((a, b) => {
  const textA = a.title.toLowerCase();
  const textB = b.title.toLowerCase();
  return textA < textB ? -1 : textA > textB ? 1 : 0;
});

export const taskJoinedToUserObjectID = joinTaskToUserArray
  .filter((joinTaskToUserObject) => !!joinTaskToUserObject.task)
  .map((joinTaskToUserObject) => joinTaskToUserObject.task)[0]?.id;

export const taskJoinedToUser = taskJoinedToUserAndScheduledArray.filter(
  (task) => task.id === taskJoinedToUserObjectID
)[0];

export const joinTaskToUserTaskIds = joinTaskToUserArray.map(
  (joinTaskToUserObject) => joinTaskToUserObject.task.id
);

export const taskNotJoinedToUserArray = taskArray.filter((taskObject) => {
  return !joinTaskToUserTaskIds.includes(taskObject.id);
});

export const taskNotJoinedToUserObjectID =
  taskNotJoinedToUserArray.length > 0
    ? taskNotJoinedToUserArray[0].id
    : undefined;

export const taskNotJoinedToUser = taskJoinedToUserAndScheduledArray.filter(
  (task) => task.id === taskNotJoinedToUserObjectID
)[0];

export const taskUserIsOwnerAndStarred = taskJoinedToUserAndScheduledArray.filter(
  ({ owner_user, starred_yn }) => owner_user === userDetails.id && starred_yn
)[0];

export const taskUserIsNotOwnerAndStarred = taskJoinedToUserAndScheduledArray.filter(
  ({ owner_user, starred_yn }) => owner_user !== userDetails.id && starred_yn
)[0];
