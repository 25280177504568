import React from "react";
import { Dropdown } from "antd";

import { CaretDownIcon } from "../../CustomIcons/CustomIcons.component";
import SectionSelectionDropdownMenu from "./SectionSelectionDropdownMenu/SectionSelectionDropdownMenu";
import SectionPositionSelectionDropdownMenu from "./SectionPositionSelectionDropdownMenu/SectionPositionSelectionDropdownMenu";

/**
 * SectionSelection displays SectionSelectionDropdownMenu,
 * the title of selected section,
 * and the SectionPositionSelectionDropdownMenu
 * or place holder if no section is selected
 */
export interface SectionSelectionProps {
  handleSelectSection: Function;
  handleSelectPosition: Function;
  handleToggleSectionDropdown: Function;
  handleTogglePositionDropdown: Function;
  sectionArray: SectionArray;
  selectedList?: ListObject;
  selectedPosition?: number;
  selectedSection?: SectionObject;
  showSectionDropdown: boolean;
  showPositionDropdown: boolean;
}
const SectionSelection: React.FC<SectionSelectionProps> = ({
  handleSelectSection,
  handleSelectPosition,
  handleToggleSectionDropdown,
  handleTogglePositionDropdown,
  sectionArray,
  selectedList,
  selectedPosition,
  selectedSection,
  showSectionDropdown,
  showPositionDropdown,
}) => {
  return (
    <div className="SectionSelection">
      <div className="SectionSelection__Item">
        <div className="SectionSelection__Item__Title">Section</div>
        <Dropdown
          overlay={
            <SectionSelectionDropdownMenu
              handleSelectSection={(sectionObject: SectionObject) => {
                handleSelectSection(sectionObject);
              }}
              sectionArray={sectionArray}
              selectedList={selectedList}
            />
          }
          visible={showSectionDropdown}
          // onClick={handleToggleSectionDropdown}
          getPopupContainer={(trigger: any) => trigger}
        >
          <div
            className="SectionSelection__Item__Dropdown"
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              handleToggleSectionDropdown();
            }}
          >
            <div className="SectionSelection__Item__Dropdown__Selected">
              <span className="SectionSelection__Item__Dropdown__Selected__Title">
                {selectedSection ? selectedSection.title : "Section"}
              </span>
            </div>
            <CaretDownIcon />
          </div>
        </Dropdown>
      </div>
      <div className="SectionSelection__Item">
        <div className="SectionSelection__Item__Title">Position</div>
        <Dropdown
          overlay={
            <SectionPositionSelectionDropdownMenu
              handleSelectPosition={handleSelectPosition}
              selectedPosition={selectedPosition}
              selectedSection={selectedSection}
            />
          }
          visible={showPositionDropdown}
          // onClick={handleTogglePositionDropdown}
          getPopupContainer={(trigger: any) => trigger}
        >
          <div
            className="SectionSelection__Item__Dropdown"
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              handleTogglePositionDropdown();
            }}
          >
            <span className="SectionSelection__Item__Dropdown__Selected">
              <span className="SectionSelection__Item__Dropdown__Selected__Title">
                {selectedPosition ? selectedPosition : "Position"}
              </span>
            </span>
            <CaretDownIcon />
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

export default SectionSelection;
