import React from "react";
import { Droppable } from "react-beautiful-dnd";
import cn from "classnames";

export interface DroppableItemProps {
  droppableId: string;
  children: React.ReactNode;
  draggablePlaceholderProps?: DraggablePlaceholderProps;
}
const DroppableItem: React.FC<DroppableItemProps> = ({
  droppableId,
  children,
  draggablePlaceholderProps,
}) => {
  return (
    <Droppable key={droppableId} droppableId={droppableId}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className={cn("DroppableItem", {
            "DroppableItem--DraggingOver": snapshot.isDraggingOver,
          })}
        >
          {children}

          {provided.placeholder}

          {snapshot.isDraggingOver && draggablePlaceholderProps && (
            <div
              className="Section__DroppablePlaceholder"
              style={{
                top: draggablePlaceholderProps.clientY,
                left: draggablePlaceholderProps.clientX,
                height: draggablePlaceholderProps.clientHeight,
                width: draggablePlaceholderProps.clientWidth,
              }}
            />
          )}
        </div>
      )}
    </Droppable>
  );
};

export default DroppableItem;
