import React from "react";
import { Popover } from "antd";

import {
  ClockIcon,
  ExclamationCircleFilledIcon,
  SuccessCircleFilledIcon,
} from "../../CustomIcons/CustomIcons.component";
import WorkDayCheckoutStatusPopoverContent from "./WorkDayCheckoutStatusPopoverContent/WorkDayCheckoutStatusPopoverContent";

import { CHECKOUT_STATUS } from "../../../constants/constants";

interface WorkDayCheckoutStatusProps {
  checkoutStatus: CheckoutStatus;
  checkoutStatusArray: CheckoutStatusArray;
}

export const renderIcon = (checkoutStatus: CheckoutStatus) => {
  switch (checkoutStatus) {
    case CHECKOUT_STATUS.CRITICAL:
      return (
        <ExclamationCircleFilledIcon className="WorkDayCheckoutStatus__Critical" />
      );
    case CHECKOUT_STATUS.NON_CRITICAL:
      return (
        <ExclamationCircleFilledIcon className="WorkDayCheckoutStatus__NonCritical" />
      );
    case CHECKOUT_STATUS.PASSED:
      return (
        <SuccessCircleFilledIcon className="WorkDayCheckoutStatus__Passed" />
      );
    case CHECKOUT_STATUS.STILL_IN_OFFICE:
      return <ClockIcon className="WorkDayCheckoutStatus__StillInOffice" />;
    default:
      return <></>;
  }
};

const WorkDayCheckoutStatus: React.FC<WorkDayCheckoutStatusProps> = ({
  checkoutStatus,
  checkoutStatusArray,
}) => {
  return (
    <div className="WorkDayCheckoutStatus">
      <Popover
        content={
          <WorkDayCheckoutStatusPopoverContent
            checkoutStatus={checkoutStatus}
            checkoutStatusArray={checkoutStatusArray}
          />
        }
        placement="top"
        getPopupContainer={(trigger: any) => trigger}
      >
        {renderIcon(checkoutStatus)}
      </Popover>
    </div>
  );
};

export default WorkDayCheckoutStatus;
