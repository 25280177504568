import { RootState } from "../store/configureStore";

export interface ProjectColorsState extends BaseReducerState {
  data: ProjectColorArray;
}
export const selectProjectsColorsState = (rootState: RootState) =>
  rootState.projectsColors;
export const projectsColorsReducerDefaultState: ProjectColorsState = {
  data: [],
  error: "",
  loading: false,
};
const projectsColorsReducer = (
  state = projectsColorsReducerDefaultState,
  action: {
    type: string;
    projectsColors: ProjectColorArray;
    projectsColor: ProjectColorObject;
    error: string;
  }
) => {
  switch (action.type) {
    case "REQUEST_FETCH_PROJECT_COLORS":
    case "REQUEST_CREATE_PROJECT_COLOR":
      return {
        ...state,
        error: "",
        loading: true,
      };
    case "REQUEST_FETCH_PROJECT_COLORS_SUCCESS":
      return {
        data: action.projectsColors,
        error: "",
        loading: false,
      };
    case "REQUEST_CREATE_PROJECT_COLOR_SUCCESS":
      return {
        data: [...state.data, action.projectsColor],
        error: "",
        loading: false,
      };
    case "REQUEST_FETCH_PROJECT_COLORS_FAILED":
    case "REQUEST_CREATE_PROJECT_COLOR_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export { projectsColorsReducer as default };
