import React from "react";
import { useSelector } from "react-redux";
import { DatePicker } from "antd";
import moment, { Moment } from "moment";

import CustomButton from "../../CustomButton/CustomButton";
import {
  CaretLeftIcon,
  CaretRightIcon,
} from "../../CustomIcons/CustomIcons.component";
import { CheckoutPanelContext } from "../CheckoutPanel/CheckoutPanel";

import { dateFormat, workDayDateFormat } from "../../../constants/constants";
import {
  selectCurrentWorkDayState,
  selectWorkDaysState,
} from "../../../reducers/work-day.reducer";

/**
 * Default Display
 * Displays [previous button, today button, next button]
 *
 * Functionality
 * Sets global state checkoutDateWorkShift to previous record when previous button is clicked
 * Sets global state checkoutDateWorkShift to next record when next button is clicked
 * Disables next button if global state checkoutDateWorkShift is the latest record in global state workShifts
 * Disables previous button if global state checkoutDateWorkShift is the last record in global state workShifts
 * Displays DatePicker when today button is clicked
 * Provides ActivityCardItemContext to all child components
 */

export const CheckoutDateNavigationContext = React.createContext<
  CheckoutDateNavigationContext
>({} as CheckoutDateNavigationContext);

const CheckoutDateNavigation = () => {
  const { checkoutDate, setCheckoutDate } = React.useContext(
    CheckoutPanelContext
  );

  const { data: workDays } = useSelector(selectWorkDaysState);
  const {
    data: { work_date: currentWorkDayDate },
  } = useSelector(selectCurrentWorkDayState);

  const checkoutDateIndex = workDays.findIndex(
    ({ work_date }) => work_date === checkoutDate
  );
  const prevArrowDisabled =
    !checkoutDate || checkoutDateIndex === workDays.length - 1;
  const nextArrowDisabled = !checkoutDate || checkoutDateIndex === 0;

  const handleClickPrevArrow = () => {
    setCheckoutDate(workDays[checkoutDateIndex + 1].work_date);
  };

  const handleClickNextArrow = () => {
    setCheckoutDate(workDays[checkoutDateIndex - 1].work_date);
  };

  const handleNavigateToToday = () => {
    setCheckoutDate(currentWorkDayDate);
  };

  const handleDatePick = (value: Moment | null, dateString: string) => {
    if (value) setCheckoutDate(value.format(workDayDateFormat));
  };

  return (
    <CheckoutDateNavigationContext.Provider
      value={{
        handleClickNextArrow,
        handleClickPrevArrow,
        nextArrowDisabled,
        prevArrowDisabled,
      }}
    >
      <div className="CheckoutDateNavigation">
        <div
          className="CheckoutDateNavigation__Today"
          onClick={handleNavigateToToday}
        >
          Today
        </div>
        <CustomButton
          icon={<CaretLeftIcon />}
          className="CheckoutDateNavigation__Arrow CheckoutDateNavigation__Arrow--Prev"
          onClick={handleClickPrevArrow}
          disabled={prevArrowDisabled}
        />
        <DatePicker
          className="CheckoutDateNavigation__DatePicker"
          dropdownClassName="CheckoutDateNavigation__DateDropdown"
          value={
            checkoutDate ? moment(checkoutDate, workDayDateFormat) : undefined
          }
          suffixIcon={false}
          allowClear={false}
          showToday={false}
          onChange={handleDatePick}
          disabledDate={(current: Moment) => {
            return current && current > moment().endOf("day");
          }}
          dateRender={(current: Moment) => {
            const style: any = {};
            // const dates = Object.keys(dateGroupedWorkShifts);
            const dates = workDays.map(({ work_date }) =>
              moment(work_date, workDayDateFormat).format(dateFormat)
            );
            if (dates.includes(current.format(dateFormat))) {
              style.color = "#0079F2";
            }
            return (
              <div className="ant-picker-cell-inner" style={style}>
                {current.date()}
              </div>
            );
          }}
          disabled={!checkoutDate}
        />
        <CustomButton
          icon={<CaretRightIcon />}
          className="CheckoutDateNavigation__Arrow CheckoutDateNavigation__Arrow--Next"
          onClick={handleClickNextArrow}
          disabled={nextArrowDisabled}
        />
      </div>
    </CheckoutDateNavigationContext.Provider>
  );
};

export default CheckoutDateNavigation;
