import React from "react";
import cn from "classnames";
import { sumBy } from "lodash";

import {
  LoadingIcon,
  ReportIcon,
} from "../../CustomIcons/CustomIcons.component";

import { getUnreadActivitiesCountService } from "../../../services/unread-activities.service";

import ws from "../../../sockets/websockets";

export interface UnreadActivitiesCountProps {
  count?: number;
  projectId?: ProjectObject["id"];
  listId?: ListObject["id"];
  taskId?: TaskObject["id"];
  userArray?: UserArray;
  date?: string[];
  hasIcon?: boolean;
}
const UnreadActivitiesCount: React.FC<UnreadActivitiesCountProps> = ({
  count,
  projectId,
  listId,
  taskId,
  userArray,
  date,
  hasIcon = false,
}) => {
  const [
    unreadActivitiesCount,
    setUnreadActivitiesCount,
  ] = React.useState<number>();

  const users = React.useMemo(
    () => (userArray ? userArray.map(({ id }) => id).join() : undefined),
    [userArray]
  );
  const from_date = date && date[0];
  const to_date = date && date[date.length - 1];

  const memoizedData = React.useMemo(
    () => ({
      project_id: projectId,
      task_list_id: listId,
      task_id: taskId,
      users,
      from_date,
      to_date,
    }),
    [projectId, listId, taskId, users, from_date, to_date]
  );

  const fetchUnreadActivitiesCount = React.useCallback(async () => {
    if (memoizedData && (projectId || listId || taskId)) {
      try {
        const response = await getUnreadActivitiesCountService(memoizedData);
        if (response.status === 200) {
          /**
           * If users is supplied in query parameters, an array containing unread activity count per user is returned.
           * If users is not supplied in query parameters, an object containing the current user's unread activity count is returned.
           */
          if (Array.isArray(response.data)) {
            setUnreadActivitiesCount(sumBy(response.data, "activity_count"));
          } else if (response.data.hasOwnProperty("activity_count")) {
            setUnreadActivitiesCount(response.data.activity_count);
          }
        } else {
          throw new Error();
        }
      } catch (e) {
        return setUnreadActivitiesCount(0);
      }
    }
  }, [memoizedData, projectId, listId, taskId]);

  React.useEffect(() => {
    fetchUnreadActivitiesCount();
  }, [fetchUnreadActivitiesCount]);

  React.useEffect(() => {
    const wsOnMessage = async (event: MessageEvent) => {
      try {
        const messageEventData = JSON.parse(event.data);
        const { event: eventType } = messageEventData;
        if (eventType === "new_activity") {
          const { task_id: eventTaskId } = messageEventData.meta;
          if (eventTaskId === taskId) {
            fetchUnreadActivitiesCount();
          }
        }
      } catch (e) {}
    };

    ws.addEventListener("message", wsOnMessage);

    return () => {
      ws.removeEventListener("message", wsOnMessage);
    };
  }, [taskId, fetchUnreadActivitiesCount]);

  return (
    <div
      className={cn("UnreadActivitiesCount", {
        "UnreadActivitiesCount--Icon": hasIcon,
        "UnreadActivitiesCount--NoIcon": !hasIcon,
      })}
    >
      <div className="UnreadActivitiesCount__Count">
        {hasIcon && <ReportIcon />}
        {(() => {
          if (count !== undefined) {
            return <span>{count}</span>;
          } else if (unreadActivitiesCount === undefined) {
            return <LoadingIcon />;
          } else if (!!unreadActivitiesCount) {
            return <span>{unreadActivitiesCount}</span>;
          }
        })()}
      </div>
    </div>
  );
};

export default UnreadActivitiesCount;
