import React from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";

import TimeDisplay from "../../TimeDisplay/TimeDisplay.component";

import { startCreateStatus } from "../../../actions/status.action";
import { workStatuses } from "../../../constants/constants";
import { getCurrentStatusDurationService } from "../../../services/status-history.service";
import { selectStatusState } from "../../../reducers/status.reducer";
import { secondsToMs, minutesToMs } from "../../../utils/time-conversion.util";
import { getStatusFromListStatusTypesByIdentifier } from "../../../utils/work-status.util";

/**
 * Default Display
 * Displays work status of user [Working, On a break]
 * Displays duration of current break time if work status is 'On a break'
 *
 * Functionality
 * Local variable onBreak is set to undefined if global state status object is an empty object
 * Local variable onBreak is set to true if global state status object identifier is 'taking_break'
 * Local variable onBreak is set to true if global state status object identifier is not 'taking_break'
 * Creates a new 'working' work status record when 'Working' button is clicked
 * Creates a new 'taking_break' work status record when 'On a break' button is clicked
 * Displays the duration of the current break time if work status is taking_break
 * Updates the duration of the current break time at an interval of one minute
 */

const ToggleWorkStatusBtn: React.FC = () => {
  const dispatch = useDispatch();

  const currentStatusState = useSelector(selectStatusState);
  const {
    data: currentStatus,
    loading: currentStatusLoading,
  } = currentStatusState;

  const [currentBreakTime, setCurrentBreakTime] = React.useState(0);

  let interval = React.useRef<number>(0);
  const { id: currentStatusDataId } = currentStatus || {};
  const onBreak = currentStatusDataId
    ? currentStatusDataId === workStatuses.taking_break
    : undefined;

  const getCurrentBreakTime = async () => {
    await getCurrentStatusDurationService().then((response) => {
      if (response.status === 200) {
        const onBreakStatusType = getStatusFromListStatusTypesByIdentifier(
          workStatuses.taking_break
        );

        const durationStatusIsOnBreak =
          response.data.status_id === onBreakStatusType.id;

        if (durationStatusIsOnBreak) {
          setCurrentBreakTime(secondsToMs(response.data.duration));
        }
      }
    });
  };

  const handleClickWorking = () => {
    const workingStatusType = getStatusFromListStatusTypesByIdentifier(
      workStatuses.working
    );
    dispatch(startCreateStatus(workingStatusType));
  };

  const handleClickOnBreak = () => {
    const onBreakStatusType = getStatusFromListStatusTypesByIdentifier(
      workStatuses.taking_break
    );
    dispatch(startCreateStatus(onBreakStatusType));
  };

  React.useEffect(() => {
    if (onBreak) {
      getCurrentBreakTime();
      interval.current = window.setInterval(() => {
        setCurrentBreakTime((currentValue) => currentValue + minutesToMs(1));
      }, minutesToMs(1));
    } else {
      window.clearInterval(interval.current);
      setCurrentBreakTime(0);
    }

    return () => {
      window.clearInterval(interval.current);
    };
  }, [onBreak]);

  return (
    <div className="ToggleWorkStatusBtn">
      <div
        className={classNames("ToggleWorkStatusBtn__WorkingBtn", {
          "ToggleWorkStatusBtn__WorkingBtn--Active": !onBreak,
          "ToggleWorkStatusBtn__WorkingBtn--Loading": currentStatusLoading,
        })}
        onClick={handleClickWorking}
      >
        <span>Working</span>
      </div>
      <div
        className={classNames("ToggleWorkStatusBtn__BreakBtn", {
          "ToggleWorkStatusBtn__BreakBtn--Active": onBreak,
          "ToggleWorkStatusBtn__BreakBtn--Loading": currentStatusLoading,
        })}
        onClick={handleClickOnBreak}
      >
        <span>On a break</span>
        {onBreak && (
          <div className="ToggleWorkStatusBtn__BreakBtn__Span">
            <TimeDisplay
              time={currentBreakTime}
              active={false}
              mouseOver={false}
              lastUnit="m"
              addZeroToSingleDigit={false}
              displayZeroes={false}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { ToggleWorkStatusBtn as default };
