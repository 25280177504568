import React from "react";
import { Droppable } from "react-beautiful-dnd";

const DroppableContainer: React.FC<DroppableContainerProps> = (props) => {
  return (
    <Droppable droppableId={props.id}>
      {(provided) => (
        <>
          <tbody
            {...(props as any)}
            ref={provided.innerRef}
            {...provided.droppableProps}
          />
          {provided.placeholder}
        </>
      )}
    </Droppable>
  );
};

export default DroppableContainer;
