import { Tooltip } from "antd";
import React from "react";
import cn from "classnames";

import useAsyncTasksInSection from "../../../hooks/useAsyncTasksInSection.hook";

import Button from "../../Button/Button";
import {
  DeleteIcon,
  LoadingIcon,
} from "../../CustomIcons/CustomIcons.component";

export interface DeleteSectionButtonProps {
  section: SectionObject;
}
const DeleteSectionButton: React.FC<DeleteSectionButtonProps> = ({
  section,
}) => {
  const [tasksUnderSection, tasksUnderSectionLoading] = useAsyncTasksInSection({
    sectionId: section.id,
  });

  const hasTasks = !!tasksUnderSection && tasksUnderSection.length > 0;
  const tooltipText = hasTasks
    ? "Only empty sections can be deleted."
    : undefined;

  const handleClick = () => {
    if (!hasTasks)
      alert("Delete section logic to be applied after actual API integration");
  };

  return (
    <Tooltip title={tooltipText} placement="top">
      <Button
        className={cn("DeleteSectionButton", {
          "DeleteSectionButton--Disabled": hasTasks,
        })}
        icon={tasksUnderSectionLoading ? <LoadingIcon /> : <DeleteIcon />}
        size="small"
        onClick={handleClick}
        disabled={tasksUnderSectionLoading}
      ></Button>
    </Tooltip>
  );
};

export default DeleteSectionButton;
