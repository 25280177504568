import { RootState } from "../store/configureStore";

export interface ActivitiesState extends BaseReducerState {
  data: ActivityArray;
}
export const selectActivitiesState = (rootState: RootState) =>
  rootState.activities;
export const activitiesReducerDefaultState: ActivitiesState = {
  data: [],
  loading: false,
  error: "",
};
const activitiesReducer = (
  state = activitiesReducerDefaultState,
  action: { type: string; activities: ActivityArray; error: string }
) => {
  switch (action.type) {
    case "REQUEST_FETCH_ACTIVITIES":
    case "REQUEST_CREATE_ACTIVITY":
    case "REQUEST_UPDATE_ACTIVITY":
    case "REQUEST_DELETE_ACTIVITY":
      return {
        ...state,
        error: "",
        loading: true,
      };
    case "REQUEST_FETCH_ACTIVITIES_SUCCESS":
    case "REQUEST_CREATE_ACTIVITY_SUCCESS":
    case "REQUEST_UPDATE_ACTIVITY_SUCCESS":
    case "REQUEST_DELETE_ACTIVITY_SUCCESS":
      return {
        data: action.activities,
        error: "",
        loading: false,
      };
    case "REQUEST_FETCH_ACTIVITIES_FAILED":
    case "REQUEST_CREATE_ACTIVITY_FAILED":
    case "REQUEST_UPDATE_ACTIVITY_FAILED":
    case "REQUEST_DELETE_ACTIVITY_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};

export { activitiesReducer as default };
