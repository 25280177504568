import moment from "moment";
import { RootState, store } from "../store/configureStore";

export interface WorkTimesState extends BaseReducerState {
  data: WorkTimes[];
}
export const selectWorkTimesState = (rootState: RootState) =>
  rootState.workTimes;
export const selectCurrentWorkTimesState = (rootState: RootState) => {
  const {
    currentWorkDay: {
      data: { work_date: currentWorkDayDate },
    },
  } = store.getState();
  return rootState.workTimes.data.find(
    (item) => item.date === currentWorkDayDate
  );
};
export const workTimesReducerDefaultState: WorkTimesState = {
  data: [],
  error: "",
  loading: true,
};
const workTimesReducer = (
  state = workTimesReducerDefaultState,
  action: {
    type: string;
    payload: WorkTimes;
    duration?: number;
    error: string;
  }
) => {
  switch (action.type) {
    case "REQUEST_WORK_TIMES":
      return {
        ...state,
        error: "",
        loading: true,
      };
    case "REQUEST_WORK_TIMES_SUCCESS": {
      const exist = state.data.find(
        (item) => item.date === action.payload.date
      );
      return {
        data: exist
          ? state.data.map((item) => {
              if (item.date === action.payload.date) {
                return action.payload;
              }
              return item;
            })
          : [...state.data, action.payload],
        error: "",
        loading: false,
      };
    }
    case "REQUEST_WORK_TIMES_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case "UPDATE_CURRENT_WORK_TIME":
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.date === moment().format("YYYY-MM-DD")) {
            return {
              ...item,
              work_time: action.duration || 0,
            };
          }
          return item;
        }),
      };
    case "UPDATE_CURRENT_BREAK_TIME":
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.date === moment().format("YYYY-MM-DD")) {
            return {
              ...item,
              break_time: action.duration || 0,
            };
          }
          return item;
        }),
      };

    default:
      return state;
  }
};

export default workTimesReducer;
