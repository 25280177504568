import React from "react";
import cn from "classnames";

import { ActivityTabContentContext } from "../ActivityTabContent";

import MyDrafts from "../../MyDrafts/MyDrafts";
import ConfirmedActivities from "../../ConfirmedActivities/ConfirmedActivities";
import { LoadingIcon } from "../../../CustomIcons/CustomIcons.component";
import SubmittedUnconfirmedActivities from "../../SubmittedUnconfirmedActivities/SubmittedUnconfirmedActivities";
import TeamDrafts from "../../TeamDrafts/TeamDrafts";
import UnreadTaskActivities from "../../UnreadTaskActivities/UnreadTaskActivities";

import { ACTIVITY_FILTERS } from "../../../../constants/activity.constant";

const ActivityTabBody: React.FC = () => {
  const {
    filter,
    userDrafts: { loading: userDraftsLoading },
    confirmedActivities,
    unreadActivities,
    submittedUnconfirmedActivities,
    teamDrafts,
  } = React.useContext(ActivityTabContentContext);

  const isLoading =
    (filter === ACTIVITY_FILTERS.DEFAULT &&
      (userDraftsLoading || confirmedActivities === undefined)) ||
    (filter === ACTIVITY_FILTERS.NEW && unreadActivities === undefined) ||
    (filter === ACTIVITY_FILTERS.UNCONFIRMED &&
      submittedUnconfirmedActivities === undefined) ||
    (filter === ACTIVITY_FILTERS.TEAM && teamDrafts === undefined);

  return (
    <div className="ActivityTabBody">
      {isLoading && (
        <div className="ActivityTabBody__Loading">
          <LoadingIcon />
        </div>
      )}

      {!isLoading && filter === ACTIVITY_FILTERS.DEFAULT && (
        <>
          <MyDrafts />
          <ConfirmedActivities />
        </>
      )}
      {!isLoading && filter === ACTIVITY_FILTERS.NEW && (
        <UnreadTaskActivities />
      )}

      {!isLoading && filter === ACTIVITY_FILTERS.UNCONFIRMED && (
        <SubmittedUnconfirmedActivities />
      )}

      {!isLoading && filter === ACTIVITY_FILTERS.TEAM && <TeamDrafts />}
    </div>
  );
};

export default ActivityTabBody;
