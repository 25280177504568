import React from "react";
import { useDispatch } from "react-redux";

import Button from "../../Button/Button";

import {
  startMarkAllNotificationsAsRead,
  startMarkNotificationAsRead,
} from "../../../actions/notifications.action";

export interface MarkNotificationAsReadProps {
  all?: boolean;
  id?: NotificationObject["id"];
}
const MarkNotificationAsRead: React.FC<MarkNotificationAsReadProps> = ({
  all,
  id,
}) => {
  const dispatch = useDispatch();

  const handleClick = async () => {
    if (all) {
      dispatch(startMarkAllNotificationsAsRead());
    } else if (!all && id) {
      dispatch(startMarkNotificationAsRead(id));
    }
  };

  return (
    <div className="MarkNotificationAsRead">
      <Button className="MarkNotificationAsRead__Btn" onClick={handleClick}>
        {all ? "Mark all as read" : "Mark as read"}
      </Button>
    </div>
  );
};

export default MarkNotificationAsRead;
