import moment, { Duration } from "moment";

export const msToSeconds: (ms: number) => number = (ms) => {
  return Math.floor(ms / 1000);
};
export const msToMinutes: (ms: number) => number = (ms) => {
  return Math.floor(ms / 1000 / 60);
};
export const msToHours: (ms: number) => number = (ms) => {
  return Math.floor(ms / 1000 / 60 / 60);
};

export const secondsToMs: (s: number) => number = (s) => {
  return Math.floor(s * 1000);
};
export const secondsToMinutes: (s: number) => number = (s) => {
  return Math.floor(s / 60);
};
export const secondsToHours: (s: number) => number = (s) => {
  return Math.floor(s / 60 / 60);
};

export const minutesToMs: (m: number) => number = (m) => {
  return Math.floor(m * 60 * 1000);
};
export const minutesToSeconds: (m: number) => number = (m) => {
  return Math.floor(m * 60);
};
export const minutesToHours: (m: number) => number = (m) => {
  return Math.floor(m / 60);
};

export const hoursToMs: (h: number) => number = (h) => {
  return Math.floor(h * 60 * 60 * 1000);
};
export const hoursToSeconds: (h: number) => number = (h) => {
  return Math.floor(h * 60 * 60);
};
export const hoursToMinutes: (h: number) => number = (h) => {
  return Math.floor(h * 60);
};

// only valid until 24 hours
export const formatDuration = (
  duration: number,
  unit: "milliseconds" | "seconds" | "minutes" = "seconds",
  format: string = "H[h] mm[m]"
): string => {
  return moment
    .utc(moment.duration(duration, unit).asMilliseconds())
    .format(format);
};

// get duration more than 24 hours
export const getTotalDuration = (duration: Duration) => {
  const hours = Math.floor(duration.asHours());
  const mins = Math.floor(duration.asMinutes()) - hours * 60;

  const hoursString = `${+hours}h`;
  const minsString = hours && mins < 10 ? `0${+mins}m` : `${+mins}m`;

  if (!hours && !mins) {
    return "0m";
  } else if (!hours && mins) {
    return minsString;
  } else {
    return `${hoursString} ${minsString}`;
  }
};

export const getDurationFormat = (seconds: number, lastUnit: string = "m") => {
  if (lastUnit === "h") {
    return "H[h]";
  } else if (seconds >= 3600 && lastUnit === "m") {
    return "H[h] mm[m]";
  } else if (seconds >= 3600 && lastUnit === "s") {
    return "H[h] mm[m] ss[s]";
  } else if (seconds >= 600 && lastUnit === "m") {
    return "m[m]";
  } else if (seconds >= 600 && lastUnit === "s") {
    return "m[m] ss[s]";
  } else if (lastUnit === "m") {
    return "m[m]";
  } else {
    return "s[s]";
  }
};

export const roundedDownMinutesFromNow = (ISOString: string) => {
  const seconds = Math.floor(
    moment.duration(moment().diff(moment(ISOString))).asSeconds()
  );
  // return 1 when 59 seconds have passed because when used with setinterval
  // sometimes only 59 seconds and n milliseconds have passed
  return seconds === 59
    ? 1
    : Math.floor(moment.duration(moment().diff(moment(ISOString))).asMinutes());
};

export const getTimeAgoString = (dateISOString: string) => {
  const timeAgo = !!dateISOString
    ? roundedDownMinutesFromNow(dateISOString)
    : undefined;
  if (timeAgo !== undefined && timeAgo < 60) {
    const substring = timeAgo > 0 ? `${timeAgo}m` : "a few seconds";
    return `${substring} ago`;
  } else {
    return `${moment(dateISOString!).fromNow()}`;
  }
};
