import React from "react";

import AddSectionsModal from "../AddSectionsModal/AddSectionsModal";
import ManageSectionsModal from "../ManageSectionsModal/ManageSectionsModal";

const AddSectionBtn = () => {
  const [showModal, setShowModal] = React.useState(false);

  return (
    <>
      <div className="AddSectionBtn" onClick={() => setShowModal(true)}>
        Add sections
      </div>
      <AddSectionsModal
        visible={showModal}
        handleOk={() => setShowModal(false)}
        handleCancel={() => setShowModal(false)}
      />
      {/* <ManageSectionsModal
        open={showModal}
        onClose={() => setShowModal(false)}
      /> */}
    </>
  );
};

export default AddSectionBtn;
