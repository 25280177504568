import { RootState } from "../store/configureStore";

export interface NextCalendarScheduleState extends BaseReducerState {
  data: CalendarScheduleArray;
}
export const selectNextCalendarScheduleState = (rootState: RootState) =>
  rootState.nextCalendarSchedule;
export const nextCalendarScheduleReducerDefaultState: NextCalendarScheduleState = {
  data: [],
  error: "",
  loading: false,
};
export const nextCalendarScheduleReducer = (
  state = nextCalendarScheduleReducerDefaultState,
  action: {
    type: string;
    nextCalendarSchedule: CalendarScheduleArray;
    error: string;
  }
) => {
  switch (action.type) {
    case "REQUEST_FETCH_NEXT_CALENDAR_SCHEDULE":
      return {
        ...state,
        error: "",
        loading: true,
      };
    case "REQUEST_FETCH_NEXT_CALENDAR_SCHEDULE_SUCCESS":
    case "SET_NEXT_CALENDAR_SCHEDULE":
      return {
        data: action.nextCalendarSchedule,
        error: "",
        loading: false,
      };
    case "REQUEST_FETCH_NEXT_CALENDAR_SCHEDULE_FAILED":
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
};
