import React from "react";
import { Modal as AntModal, ModalProps as AntModalProps } from "antd";
import cn from "classnames";

import { CloseIcon } from "../../CustomIcons/CustomIcons.component";

export interface ModalProps extends AntModalProps {
  fullScreen?: boolean;
  prefixText?: React.ReactNode;
  suffixText?: React.ReactNode;
}
const Modal: React.FC<ModalProps> = ({
  centered = true,
  className,
  closeIcon = <CloseIcon />,
  children,
  fullScreen = false,
  prefixText,
  suffixText,
  ...rest
}) => {
  return (
    <AntModal
      className={cn("Modal", className, {
        "Modal--Fullscreen": fullScreen,
      })}
      centered={centered}
      closeIcon={closeIcon}
      {...rest}
    >
      {prefixText && <span className="Modal__PrefixText">{prefixText}</span>}
      {children}
      {suffixText && <span className="Modal__SuffixText">{suffixText}</span>}
    </AntModal>
  );
};

export default Modal;
