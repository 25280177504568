import React from "react";
import { find, findIndex, isMatch } from "lodash";

import TeamHistoryDateItem from "../TeamHistoryDateItem/TeamHistoryDateItem";

export interface TeamHistoryDateListProps {
  shownObservableUserWithTableDataArray: ShownObservableUserWithTableDataArray;
}

export const getDateWithTableDataArray: (
  shownObservableUserWithTableDataArray: ShownObservableUserWithTableDataArray
) => DateWithTableDataArray = (shownObservableUserWithTableDataArray) => {
  /**
   * Map array with type ShownObservableUserWithTableDataArray
   * and return array with type DateWithTableDataArray
   * to be able to parse table data in Date View
   */
  return shownObservableUserWithTableDataArray
    .filter(({ tableData }) => {
      const { records } = tableData || {};
      return records && tableData;
    })
    .flatMap((shownObservableUserWithTableDataObject) => {
      const {
        shownObservableUser,
        tableData,
      } = shownObservableUserWithTableDataObject;
      const { records } = tableData!;
      const newRecords = records!
        .map((record) => {
          return {
            shownObservableUser,
            ...record,
          };
        })
        .map(({ date }, i, self) => {
          const entry = {
            date,
            tableData: {
              records: self.filter(({ date: selfDate }) => selfDate === date),
            },
          };
          return entry;
        });
      return newRecords;
    })
    .reduce((filtered, option) => {
      // reduce to consolidate records with same dates into one record
      const dates = filtered.map(({ date }) => date);
      if (!dates.includes(option.date)) {
        filtered = [...filtered, option] as DateWithTableDataArray;
      } else {
        const index = findIndex(filtered, ({ date }) => date === option.date);
        filtered[index].tableData.records = [
          ...filtered[index].tableData.records,
          ...option.tableData.records,
        ];
      }
      return filtered;
    }, [] as DateWithTableDataArray);
};

const TeamHistoryDateList: React.FC<TeamHistoryDateListProps> = ({
  shownObservableUserWithTableDataArray,
}) => {
  const dateWithTableDataArray: DateWithTableDataArray =
    shownObservableUserWithTableDataArray.length > 0
      ? getDateWithTableDataArray(shownObservableUserWithTableDataArray)
      : [];

  return (
    <div className="TeamHistoryDateList">
      {dateWithTableDataArray.map((dateWithTableDataObject, index) => (
        <TeamHistoryDateItem
          key={index}
          dateWithTableDataObject={dateWithTableDataObject}
        />
      ))}
    </div>
  );
};

export default TeamHistoryDateList;
