import React from "react";
import { Popover } from "antd";

import { MoveIcon } from "../../CustomIcons/CustomIcons.component";
import SelectTaskDesinationPopoverContent, {
  ActionClickCallbackParams,
} from "../SelectTaskDesinationPopoverContent/SelectTaskDesinationPopoverContent";

export interface MoveTaskProps {
  panelType: PanelType;
}
const MoveTask: React.FC<MoveTaskProps> = ({ panelType }) => {
  const [displayPopover, setDisplayPopover] = React.useState(false);

  const moveTask = (data: ActionClickCallbackParams) => {
    /**
     * TODO 13.5.3 - integrate endpoint for moving task
     */
    alert("Move task logic to be applied after actual API integration");
  };

  const cancelClickCallback = () => {
    setDisplayPopover(false);
  };

  return (
    <div className="MoveTask" onClick={() => setDisplayPopover((c) => !c)}>
      <Popover
        content={
          <SelectTaskDesinationPopoverContent
            action={"move"}
            actionClickCallback={moveTask}
            cancelClickCallback={cancelClickCallback}
            panelType={panelType}
          />
        }
        placement="bottom"
        overlayClassName="MoveTask__Popover"
        visible={displayPopover}
      >
        <MoveIcon className="MoveTask__Icon" />
        <span>Move to</span>
      </Popover>
    </div>
  );
};

export default MoveTask;
