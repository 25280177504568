import { store } from "../store/configureStore";

import {
  startSetTrackingTask,
  startSetTrackingStatus,
  startSetLastTrackedTask,
} from "../actions/task-time-tracking.action";

export const startTrackingTask = async (
  task: TaskJoinedToUserAndScheduledObject
) => {
  await new Promise((resolve) =>
    setTimeout(() => {
      localStorage.setItem("trackingTask", JSON.stringify(task));
      localStorage.setItem("trackingStatus", "tracking");
      localStorage.setItem("lastTrackedTask", JSON.stringify(task));
      resolve(undefined);
    }, 1000)
  );
  store.dispatch(startSetTrackingTask());
  store.dispatch(startSetTrackingStatus());
  store.dispatch(startSetLastTrackedTask());
};

export const stopTrackingTask = async () => {
  /**
   * TODO 13.2.1 add endpoint call to stop tracking to task
   */
  await new Promise((resolve) =>
    setTimeout(() => {
      localStorage.removeItem("trackingTask");
      localStorage.setItem("trackingStatus", "stopped");
      resolve(undefined);
    }, 1000)
  );
  store.dispatch(startSetTrackingTask());
  store.dispatch(startSetTrackingStatus());
};

export const pauseTrackingTask = async () => {
  /**
   * TODO 13.2.1 add endpoint call to pause tracking to task
   */
  await new Promise((resolve) =>
    setTimeout(() => {
      localStorage.setItem("trackingStatus", "paused");
      resolve(undefined);
    }, 1000)
  );
  store.dispatch(startSetTrackingStatus());
};

export const resumeTrackingTask = async () => {
  /**
   * TODO 13.2.1 add endpoint call to resume tracking to last task tracked
   */
  const lastTrackedTask = store.getState().lastTrackedTask;
  await new Promise((resolve) =>
    setTimeout(() => {
      localStorage.setItem("trackingTask", JSON.stringify(lastTrackedTask));
      localStorage.setItem("trackingStatus", "tracking");
      resolve(undefined);
    }, 1000)
  );
  store.dispatch(startSetTrackingTask());
  store.dispatch(startSetTrackingStatus());
  store.dispatch(startSetLastTrackedTask());
};

export const removeLastTrackedTask = async () => {
  /**
   * TODO 13.2.1 add endpoint call to remove last tracked task
   */
  await new Promise((resolve) =>
    setTimeout(() => {
      localStorage.removeItem("lastTrackedTask");
      resolve(undefined);
    }, 1000)
  );
  store.dispatch(startSetLastTrackedTask());
};
