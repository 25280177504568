import React from "react";
import { Empty } from "antd";

import ActivityCardItem from "../../Activities/ActivityCardItem/ActivityCardItem";

interface UserTaskActivityListProps {
  taskActivitySummaryArray: ActivitySummaryArray;
}

const UserTaskActivityList: React.FC<UserTaskActivityListProps> = ({
  taskActivitySummaryArray,
}) => {
  const activitiesJsx = taskActivitySummaryArray.length ? (
    taskActivitySummaryArray.map((taskActivitySummaryObject) => {
      const { id } = taskActivitySummaryObject;
      return (
        <ActivityCardItem
          key={id}
          activityObject={taskActivitySummaryObject}
          displayAvatar={false}
          displayHeader={true}
        />
      );
    })
  ) : (
    <Empty />
  );

  return <div className="UserTaskActivityList">{activitiesJsx}</div>;
};

export default UserTaskActivityList;
