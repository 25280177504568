/**
 * https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
 */
import React, { useEffect } from "react";

const useOutsideClickCallback = (
  callback: Function,
  dependencies: any[],
  refObject?: React.MutableRefObject<any>,
  refArray?: React.MutableRefObject<any>[]
) => {
  useEffect(() => {
    /**
     * Run callback if clicked outside of reference(s)
     */
    function handleClickOutside(event: Event) {
      const refObjectNotClicked =
        refObject &&
        refObject.current &&
        !refObject.current.contains(event.target);
      const refArrayNotClicked =
        refArray &&
        refArray.length > 0 &&
        refArray.every(
          (refObject) => !refObject.current.contains(event.target)
        );
      if (refObjectNotClicked || refArrayNotClicked) {
        callback();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [refObject, refArray, callback, ...dependencies]);
};

export { useOutsideClickCallback as default };
