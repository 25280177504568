import React, { useState, useRef, useEffect, useContext } from "react";
import { Form, Input, Select } from "antd";
import { EditableContext } from "./EditableRow.component";

const { Option } = Select;
interface Item {
  key: string;
  projectRole: string;
  accessType: "owner" | "member";
}

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  selector?: boolean;
  children: React.ReactNode;
  dataIndex: keyof Item;
  record: Item;
  onSave: (record: Item) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  selector = false,
  children,
  dataIndex,
  record,
  onSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<Input>(null);
  const form = useContext(EditableContext)!;
  useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing((c) => !c);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    const values = await form.validateFields();
    await onSave({ ...record, ...values });
    toggleEdit();
  };

  const isRequired = title !== "Project Role";
  let childNode = children;

  if (editable) {
    childNode = editing ? (
      selector ? (
        <Form.Item style={{ margin: 0 }} name={dataIndex}>
          <Select ref={inputRef} onBlur={save}>
            <Option value="owner">Project Owner</Option>
            <Option value="member" disabled={record.accessType === "owner"}>
              Project Member
            </Option>
          </Select>
        </Form.Item>
      ) : (
        <Form.Item
          style={{ margin: 0 }}
          name={dataIndex}
          rules={[
            {
              required: isRequired,
              message: `${title} is required.`,
            },
          ]}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      )
    ) : (
      <div
        className="EditableCell__wrap"
        style={{ paddingRight: 26 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return (
    <td {...restProps} className="EditableCell">
      {childNode}
    </td>
  );
};

export default EditableCell;
