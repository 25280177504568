import React from "react";

import { CloseIcon, CaretLeftIcon } from "../CustomIcons/CustomIcons.component";
import Button from "../Button/Button";
import LabelList from "./LabelList/LabelList";
import LabelEditor from "./LabelEditor/LabelEditor";

type LabelPickerMode = "default" | "edit" | "import" | "create";
const getTitle = (mode: LabelPickerMode) => {
  switch (mode) {
    case "default":
      return "Select labels";
    case "edit":
      return "Edit label";
    case "create":
      return "Create label";
    case "import":
      return "Import labels from ...";
  }
};

/**
 * The LabelPicker is used to select one or multiple labels by a user.
 * It has 4 modes and different modes will render different UI.
 * 1. when user clicks on the label picker icon, the "default mode" is popovered.
 *    user can select one or multiple labels from this mode.
 * 2. when user clicks on the existing labels, it will change to "edit mode".
 * 3. when user clicks on the "create new" button, it will change to "create mode".
 * 4. when user clicks on the "import from" button, it will change to "import mode",
 *    this mode is disable currently.
 */
const LabelPicker: React.FC<LabelPickerProps> = ({
  labels,
  defaultKeys,
  onChange,
  onClose,
}) => {
  const [mode, setMode] = React.useState<LabelPickerMode>("default");
  const [selectedKey, setSelectedKey] = React.useState("");
  const selectedLabel = labels.find((label) => label.id === selectedKey);

  const handleEdit = (key: string) => {
    setMode("edit");
    setSelectedKey(key);
  };
  const handleBack = () => {
    setMode("default");
  };
  const handleLabelSave = (label: Label) => {
    setMode("default");
  };

  const handleLabelDelete = (key: string) => {
    setMode("default");
  };

  const handleCreate = () => {
    setMode("create");
  };
  return (
    <div className="LabelPicker" onClick={(e: any) => e.stopPropagation()}>
      <div className="LabelPicker__Header">
        {(mode === "edit" || mode === "create") && (
          <CaretLeftIcon className="LabelPicker__Back" onClick={handleBack} />
        )}
        <div className="LabelPicker__Title">{getTitle(mode)}</div>
        <CloseIcon className="LabelPicker__Close" onClick={() => onClose()} />
      </div>
      <div className="LabelPicker__Content">
        {mode === "default" && (
          <>
            <LabelList
              labels={labels}
              defaultKeys={defaultKeys}
              onChange={onChange}
              onEdit={handleEdit}
            />
            <Button
              className="LabelPicker__Button"
              type="default"
              size="large"
              onClick={handleCreate}
            >
              Create new
            </Button>
            <Button
              className="LabelPicker__Button"
              type="default"
              size="large"
              disabled={true}
            >
              Import labels from ...
            </Button>
          </>
        )}
        {mode === "edit" && (
          <LabelEditor
            label={selectedLabel!}
            onSave={handleLabelSave}
            onDelete={handleLabelDelete}
          />
        )}
        {mode === "create" && (
          <LabelEditor
            isCreate={true}
            onSave={handleLabelSave}
            onDelete={handleLabelDelete}
          />
        )}
      </div>
    </div>
  );
};

export default LabelPicker;
