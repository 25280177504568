import { Button } from "antd";
import React from "react";
import { getWorkShiftLogsService } from "../../../services/work-shift.service";

const EditHistory: React.FC<EditHistoryProps> = ({ date }) => {
  const [collapsed, setCollapsed] = React.useState(true);
  const [workShiftLogs, setWorkShiftLogs] = React.useState<WorkShiftLog[]>([]);

  React.useEffect(() => {
    getWorkShiftLogsService(date).then((response) => {
      if (response.status === 200) {
        setWorkShiftLogs(response.data);
      }
    });
  }, [date]);
  const histories = [
    {
      date: "",
      log: "Admin changed break from 0m to 25m.",
    },
    {
      date: "",
      log: "John Williams changed Offline hours from 0m to 10m.",
    },
    {
      date: "",
      log: "Admin2 changed break from 0m to 25m.",
    },
  ];

  const expandedHistories = histories.slice(0, 10);
  const collapsedHistories = histories.slice(10, histories.length);

  const expandedJxs = expandedHistories.map((item, index) => {
    return (
      <div key={index} className="EditHistory__Item">
        <span className="EditHistory__Item-Date">2021-05-30</span>
        <span className="EditHistory__Item-Time">17:21</span>
        <span>{item.log}</span>
      </div>
    );
  });
  const collapsedJsx = collapsedHistories.map((item, index) => {
    return (
      <div key={index} className="EditHistory__Item">
        <span className="EditHistory__Item-Date">2021-05-30</span>
        <span className="EditHistory__Item-Time">17:21</span>
        <span>{item.log}</span>
      </div>
    );
  });

  const handleToggle = () => setCollapsed((prev) => !prev);
  return (
    <div className="EditHistory">
      <div className="EditHistory__Header">Edit history</div>
      <div className="EditHistory__Content">
        {expandedJxs}
        {collapsedHistories.length > 0 && !collapsed && collapsedJsx}
        {collapsedHistories.length > 0 && (
          <Button
            className="EditHistory__Btn"
            size="small"
            onClick={handleToggle}
          >
            {collapsed ? "Show x more" : "Hide"}
          </Button>
        )}
      </div>
    </div>
  );
};

export default EditHistory;
