import React from "react";
import { Form, Input, Select } from "antd";
import cn from "classnames";

import RadioGroup from "../../RadioGroup/RadioGroup.component";
import {
  getSortedTimezones,
  getBrowserTimezone,
  formatTimezone,
} from "../../../utils/dates.util";
import { getCountries } from "../../../utils/country.util";

const { Option } = Select;
const timezoneTypeOptions = [
  { title: "Auto", value: "auto" },
  { title: "Manual", value: "manual" },
];
type TimezoneType = "auto" | "manual";

const now = new Date();

const timezoneOptions = getSortedTimezones(now).map((item) => (
  <Option key={item.timezone} value={item.timezone}>
    {formatTimezone(item.timezone, now)}
  </Option>
));

const countryOptions = getCountries().map((c) => (
  <Option key={c} value={c}>
    {c}
  </Option>
));

export interface PersonalFormProps {
  userDetails: AccountUser;
  form: any;
  onValuesChange?: (changedValues: any, allValues: any) => void;
  readOnly?: boolean;
}

const PersonalForm: React.FC<PersonalFormProps> = ({
  userDetails,
  form,
  onValuesChange,
  readOnly = false,
}: PersonalFormProps) => {
  const [timezoneType, setTimezoneType] = React.useState<TimezoneType>("auto");
  const {
    full_name: fullName,
    display_name: displayName,
    what_i_do: role,
    country,
    city,
    timezone,
  } = userDetails;

  React.useEffect(() => {
    const {
      full_name: fullName,
      display_name: displayName,
      what_i_do: role,
      country,
      city,
      timezone,
    } = userDetails;
    form.setFieldsValue({
      fullName,
      displayName,
      role,
      country,
      city,
      timezone,
    });
  }, [userDetails, form]);

  React.useEffect(() => {
    const zone = getBrowserTimezone();
    if (zone !== timezone) {
      setTimezoneType("manual");
    }
  }, [timezone]);

  const handleTimezoneTypeChange = (value: string) => {
    if (value === "auto" || value === "manual") {
      setTimezoneType(value);
      if (value === "auto") {
        const zone = getBrowserTimezone();
        form.setFieldsValue({ timezone: zone });
      }
    }
  };

  return (
    <div
      className={cn("PersonalForm", {
        "PersonalForm--ReadOnly": readOnly,
      })}
    >
      <Form
        name="PersonalForm"
        form={form}
        initialValues={{
          fullName,
          displayName,
          role,
          country,
          city,
          timezone,
        }}
        onValuesChange={(changedValues: any, allValues: any) => {
          if (onValuesChange) onValuesChange(changedValues, allValues);
        }}
      >
        <div className="PersonalForm__Item">
          <p className="PersonalForm__Item-name">Full name</p>
          {readOnly ? (
            <p className="PersonalForm__Item-value">{fullName}</p>
          ) : (
            <Form.Item
              className="PersonalForm__Item-value"
              name="fullName"
              rules={[{ required: true, message: "Full name is required" }]}
            >
              <Input />
            </Form.Item>
          )}
        </div>

        {!readOnly && (
          <div className="PersonalForm__Item">
            <p className="PersonalForm__Item-name">Display name</p>
            <Form.Item
              className="PersonalForm__Item-value"
              name="displayName"
              rules={[{ required: true, message: "Display name is required" }]}
            >
              <Input />
            </Form.Item>
          </div>
        )}

        <div className="PersonalForm__Item">
          <p className="PersonalForm__Item-name">Role</p>
          {readOnly ? (
            <p className="PersonalForm__Item-value">{role}</p>
          ) : (
            <Form.Item
              className="PersonalForm__Item-value"
              name="role"
            >
              <Input />
            </Form.Item>
          )}
        </div>

        {
          /**
           * TODO 13.16.3: update read only values with correct dynamic values
           */
          readOnly && (
            <div className="PersonalForm__Item">
              <p className="PersonalForm__Item-name">Location</p>
              <p className="PersonalForm__Item-value">11:25, New York, USA</p>
            </div>
          )
        }

        {!readOnly && (
          <>
            <div className="PersonalForm__Item">
              <p className="PersonalForm__Item-name">Country</p>
              <Form.Item name="country" className="PersonalForm__Item-value">
                <Select
                  showSearch={true}
                  placeholder="Select Country"
                  notFoundContent="No country found"
                >
                  {countryOptions}
                </Select>
              </Form.Item>
            </div>

            <div className="PersonalForm__Item">
              <p className="PersonalForm__Item-name">City</p>
              <Form.Item className="PersonalForm__Item-value" name="city">
                <Input placeholder="Please enter city name" />
              </Form.Item>
            </div>

            <div className="PersonalForm__Item">
              <p className="PersonalForm__Item-name">Timezone</p>
              <div className="PersonalForm__Item-input">
                <RadioGroup
                  options={timezoneTypeOptions}
                  value={timezoneType}
                  onChange={handleTimezoneTypeChange}
                  className="PersonalForm__Radio"
                />
                <Form.Item
                  name="timezone"
                  style={{ width: "100%", marginLeft: "16px" }}
                >
                  <Select
                    showSearch={true}
                    disabled={timezoneType === "auto"}
                    style={{ width: "100%" }}
                    filterOption={(input: string, option: any) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    notFoundContent="No timezone found"
                  >
                    {timezoneOptions}
                  </Select>
                </Form.Item>
              </div>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

export default PersonalForm;
