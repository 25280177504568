import React from "react";
import { Form } from "antd";

const AccountForm = ({ userDetails, form }: AccountFormProps) => {
  React.useEffect(() => {
    form.setFieldsValue({
      email: userDetails.email,
    });
  }, [userDetails.email, form]);

  return (
    <div className="AccountForm">
      <Form
        form={form}
        name="AccountForm"
        initialValues={{
          email: userDetails.email,
        }}
      >
        <div className="AccountForm__Item">
          <p className="AccountForm__Item-name">Email</p>
          <div className="AccountForm__Item-input">
            <span className="AccountForm__Item-value">{userDetails.email}</span>
            <span className="AccountForm__Item-textButton disabled">Edit</span>
          </div>
        </div>
        <div className="AccountForm__Item">
          <p className="AccountForm__Item-name">Password</p>
          <div className="AccountForm__Item-input">
            <span className="AccountForm__Item-value">******</span>
            <span className="AccountForm__Item-textButton disabled">Edit</span>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default AccountForm;
